import { Button } from "react-bootstrap";

export const SeparatorButtons = (props) => {
    
    const setPoint = () =>{
        props.onSeparatorChange('.');
    }

    const setComma = () => {
        props.onSeparatorChange(',');
    }
    
    return (
        <>
            <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={setPoint} type="submit">.</Button>
            <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={setComma} type="submit">,</Button> 
        </>
    );
}