import { calculateAttributionMatrix, calculateMausMatrix, calculateDataPoints, calculateDataPointsV2, calculateMausV2, calculateAttributionV2, } from "./CalculatePrices";

const regionDiscount = (region) => {
    if(region === 'Japan'){return 1.10;}
    if(region === 'Korea'){return 1.10;}
    if(region === 'UKI'){return 1;}
    if(region === 'Israel'){return 1;}
    if(region === 'DACH'){return 1;}
    //if(region === 'France'){return 1;}
    if(region === 'North America'){return 0.90;}
    if(region === 'MENA'){return 0.90;}
    if(region === 'Eastern Europe'){return 0.90;}
    if(region === 'INSEA'){return 0.90;}
    if(region === 'China'){return 0.80;}
    if(region === 'LATAM (PT)'){return 0.80;}
    if(region === 'LATAM (ES)'){return 0.80;}
    return 1;
}
// calculation 

// attr
export const rateCardBasePriceAttr = (attr,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionMatrix(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * regionDiscount(region);
}

export const recommendedCardBasePriceAttr  = (attr,paymentDiscount,contractLengthDiscount,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionMatrix(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceAttr  = (attr,paymentDiscount,contractLengthDiscount,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionMatrix(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}

export const rateCardBasePriceAttrV2 = (attr,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionV2(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * regionDiscount(region);
}

export const recommendedCardBasePriceAttrV2  = (attr,paymentDiscount,contractLengthDiscount,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionV2(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceAttrV2  = (attr,paymentDiscount,contractLengthDiscount,region) => {
    if(attr === undefined){return 0;}
    var attributionMatrix = calculateAttributionV2(attr);
    var attributionPrice = attributionMatrix[1];
    return attributionPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}

// mau 

export const rateCardBasePriceMau = (mau,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausMatrix(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * regionDiscount(region);
}

export const recommendedCardBasePriceMau  = (mau,paymentDiscount,contractLengthDiscount,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausMatrix(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceMau  = (mau,paymentDiscount,contractLengthDiscount,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausMatrix(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}

export const rateCardBasePriceMauV2 = (mau,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausV2(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * regionDiscount(region);
}

export const recommendedCardBasePriceMauV2  = (mau,paymentDiscount,contractLengthDiscount,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausV2(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceMauV2  = (mau,paymentDiscount,contractLengthDiscount,region) => {
    if(mau === undefined){return 0;}
    var mauMatrix = calculateMausV2(mau);
    var mauPrice = mauMatrix[1];
    return mauPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}
// dp calculations 
export const rateCardBasePriceDp = (dp,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPoints(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * regionDiscount(region);
}

export const recommendedCardBasePriceDp  = (dp,paymentDiscount,contractLengthDiscount,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPoints(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceDp  = (dp,paymentDiscount,contractLengthDiscount,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPoints(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}

export const rateCardBasePriceDpV2 = (dp,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPointsV2(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * regionDiscount(region);
}

export const recommendedCardBasePriceDpV2  = (dp,paymentDiscount,contractLengthDiscount,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPointsV2(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region);
}

export const lowerCardBasePriceDpV2 = (dp,paymentDiscount,contractLengthDiscount,region) => {
    if(dp === undefined){return 0;}
    var dpMatrix = calculateDataPointsV2(dp);
    var dpPrice = dpMatrix[1];
    return dpPrice * contractLengthDiscount * paymentDiscount * regionDiscount(region) * 0.7;
}