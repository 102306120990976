import React, { useState } from "react";
import Card from 'react-bootstrap/Card'
import {Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import NumberFormat from'react-number-format';
import FeatureOutput from "./FeatureOutput";
import { calculateDpPriceMatrix, calculateDataPoints100m, calculateDataPoints} from "../MatrixCalculation/CalculatePrices";
import {getCurrency, getPrefix} from "../GeneralValues/Currency";
import { lowerCardBasePriceDpV2, rateCardBasePriceDpV2, recommendedCardBasePriceDpV2 } from "./CostBreakdownCalculations/BasePriceCalculation";
import { featurePrice } from "./CostBreakdownCalculations/FeatureCalculation";
import { packagePricingNextGen } from "./CostBreakdownCalculations/PackageCalculation";
import { supportRates } from "./CostBreakdownCalculations/SupportCalculation";
import { saveInputs } from "./saveInputs";
import { calculateDataPointsV2 } from "./CostBreakdownCalculations/CalculatePrices";

function roundNearest50(num) {
  return Math.round(num / 50) * 50;
}

function ceilNearest50(num) {
  return Math.ceil(num / 50) * 50;
}

export default function OutputBreakdown(props) {
  const [showExtras, setExtras] = useState(false);
  const [extraText, setExtraText] = useState("Show Monthly Details");

  function hideExtras() {
    if(showExtras){
      setExtraText("Show Monthly Details");
    }
    else{
      setExtraText("Hide Monthly Details");
    }
    setExtras(!showExtras);
  }

  let currencyPrefix = getPrefix(props.currency)
  let currencyMultiplier = getCurrency(props.currency)
  let dataManagement = props.dataManagementSelected;
  var contractLength = parseInt(props.contractLength);
  
  var contractLengthDiscount = 0;
  if(contractLength === 1){contractLengthDiscount = 1.15;}
  if(contractLength === 3){contractLengthDiscount = 1.10;}
  if(contractLength === 6){contractLengthDiscount = 1.05;}
  if(contractLength === 12){contractLengthDiscount = 1;}
  if(contractLength === 18){contractLengthDiscount = 0.95;}
  if(contractLength === 24){contractLengthDiscount = 0.90;}
  if(contractLength === 36){contractLengthDiscount = 0.80;}
  var paymentTerm = props.paymentTerm;
  var paymentTermDiscount = 0;
  if(paymentTerm === 'Monthly'){paymentTermDiscount = 1.05;}
  if(paymentTerm === 'Quarterly'){paymentTermDiscount = 1;}
  if(paymentTerm === 'Bi-Annual'){paymentTermDiscount = 0.95;}
  if(paymentTerm === 'Annual'){paymentTermDiscount = 0.9;}
  var region = props.region;

  const [basePriceQuo,setBasePriceQuote] = useState(0);
  const [totalMonthlyCostQuo,setTotalMonthlyCostQuo] = useState(0);
  const [featurePriceQuo,setFeaturePriceQuo] = useState(0); 
  const [quoteDPs,setQuoteDps] = useState(0);
  const [featurePriceQuote,setFeaturePriceQuote] = useState({feature1price:0,feature2price:0,feature3price:0,feature4price:0,feature5price:0,feature6price:0,feature7price:0,feature8price:0,feature9price:0,feature10price:0,feature11price:0,feature12price:0,feature13price:0})

  let mauKeyStats = props.keyStats[1];

  let thousandSeparator = ',';
  let decimalSeparator = '.'
  if(props.separator === '.'){
    thousandSeparator = '.';
    decimalSeparator = ',';
  }
  else{
    thousandSeparator = ',';
    decimalSeparator = '.';
  }

var attr = props.attribution;
attr = attr * 1000000000;
var includedMaus = 0;
var additionalMauPrice = 0;

var basePriceRate = roundNearest50(rateCardBasePriceDpV2(attr,region));
var basePriceRecommended = roundNearest50(recommendedCardBasePriceDpV2(attr,paymentTermDiscount,contractLengthDiscount,region));
var basePriceLower = roundNearest50(lowerCardBasePriceDpV2(attr,paymentTermDiscount,contractLengthDiscount,region));

var dpMatrix = calculateDataPointsV2(attr);
includedMaus = dpMatrix[3];
additionalMauPrice = basePriceRecommended/includedMaus * 1.5;


var featureDiscount = (props.completeFeatureDiscount - props.featureDiscount) / 100;
var featurePricesRate = featurePrice(0,basePriceRate,0,props.feature1,props.feature2,props.feature3,props.feature4,props.feature5,props.feature6,props.feature7,props.feature8,props.feature9,props.feature10,props.feature11,props.feature12,props.feature13,props.feature14,props.nextGen);
var featurePricesRecommended = featurePrice(0,basePriceRecommended,featureDiscount,props.feature1,props.feature2,props.feature3,props.feature4,props.feature5,props.feature6,props.feature7,props.feature8,props.feature9,props.feature10,props.feature11,props.feature12,props.feature13,props.feature14,props.nextGen);
var featurePricesLower = featurePrice(1,basePriceLower,featureDiscount,props.feature1,props.feature2,props.feature3,props.feature4,props.feature5,props.feature6,props.feature7,props.feature8,props.feature9,props.feature10,props.feature11,props.feature12,props.feature13,props.feature14,props.nextGen);
var packagePricingAll = packagePricingNextGen(props.dataManagementSelected,props.pulse,props.pulseCount,props.spendWorks,props.incrementality,props.nextGen,basePriceRecommended,basePriceLower);

var totalMonthlyCostRate = basePriceRate + featurePricesRate.completeFeaturePrice + packagePricingAll.completePackagePrice;
var totalMonthlyCostRecommended = basePriceRecommended + featurePricesRecommended.completeFeaturePrice + packagePricingAll.completePackagePrice;
var totalMonthlyCostLower = basePriceLower + featurePricesLower.completeFeaturePrice + packagePricingAll.completePackagePriceMin;
var tcvRate = 0;
var tcvRecommended = 0;
var tcvLower = 0;

if(props.region !== 'Japan'){
    if((totalMonthlyCostRate * 12) < 4800){
      let multiplierRate = 4800 / (totalMonthlyCostRate * 12);
      basePriceRate = basePriceRate * multiplierRate;
      totalMonthlyCostRate = totalMonthlyCostRate * multiplierRate;
    }
    if((totalMonthlyCostRecommended * 12) < 4800){
      let multiplierRec = 4800 / (totalMonthlyCostRecommended * 12);
      basePriceRecommended = basePriceRecommended * multiplierRec;
      totalMonthlyCostRecommended = totalMonthlyCostRecommended * multiplierRec;
    }
    if((totalMonthlyCostLower * 12) < 4800){
      let multiplierLower = 4800 / (totalMonthlyCostLower * 12);
      basePriceLower = basePriceLower * multiplierLower;
      totalMonthlyCostLower = totalMonthlyCostLower * multiplierLower;
    }
  }
  else{
    if((totalMonthlyCostRate * 12) < 3500){
      let multiplierRate = 3500 / (totalMonthlyCostRate * 12);
      basePriceRate = basePriceRate * multiplierRate;
      totalMonthlyCostRate = totalMonthlyCostRate * multiplierRate;
    }
    if((totalMonthlyCostRecommended * 12) < 3500){
      let multiplierRec = 3500 / (totalMonthlyCostRecommended * 12);
      basePriceRecommended = basePriceRecommended * multiplierRec;
      totalMonthlyCostRecommended = totalMonthlyCostRecommended * multiplierRec;
    }
    if((totalMonthlyCostLower * 12) < 3500){
      let multiplierLower = 3500 / (totalMonthlyCostLower * 12);
      basePriceLower = basePriceLower * multiplierLower;
      totalMonthlyCostLower = totalMonthlyCostLower * multiplierLower;
    }
  }

if(contractLength >= 12){
  tcvRate = (basePriceRate + featurePricesRate.completeFeaturePrice + packagePricingAll.completePackagePrice) * contractLength;
  tcvRecommended = (basePriceRecommended + featurePricesRecommended.completeFeaturePrice + packagePricingAll.completePackagePrice) * contractLength;
  tcvLower = (basePriceLower + featurePricesLower.completeFeaturePrice + packagePricingAll.completePackagePriceMin) * contractLength;
}
else{
  tcvRate = ((basePriceRate + featurePricesRate.completeFeaturePrice + packagePricingAll.dataManagementCost + packagePricingAll.spendWorksPrice) * contractLength) + packagePricingAll.pulsePrice + packagePricingAll.incrementalityPrice;
  tcvRecommended = ((basePriceRecommended + featurePricesRecommended.completeFeaturePrice + packagePricingAll.dataManagementCost + packagePricingAll.spendWorksPrice) * contractLength) + packagePricingAll.pulsePrice + packagePricingAll.incrementalityPrice;
  tcvLower = ((basePriceLower + featurePricesLower.completeFeaturePrice + packagePricingAll.dataManagementMin + packagePricingAll.spendWorksPriceMin) * contractLength) + packagePricingAll.pulsePriceMin + packagePricingAll.incrementalityPriceMin;
}

const [acvQuote,setAcvQuotes] = useState(0);

function setAcvQuote(value){
  setAcvQuotes(value);
  props.acvChange(value)
  var acvQuoteValue = parseInt(value / currencyMultiplier - (packagePricingAll.completePackagePriceMin * contractLength));
  var totalMonthlyCost = roundNearest50(acvQuoteValue/contractLength);
  var basePriceQuote = roundNearest50((basePriceLower/(basePriceLower + featurePricesLower.completeFeaturePrice)) * totalMonthlyCost);
  var featurePriceQuote = roundNearest50(totalMonthlyCost - basePriceQuote);
  totalMonthlyCost = (basePriceQuote + featurePriceQuote + packagePricingAll.completePackagePriceMin);
  setBasePriceQuote(basePriceQuote);
  setTotalMonthlyCostQuo(totalMonthlyCost);
  setFeaturePriceQuo(featurePriceQuote);
  let quoteACV = acvQuoteValue / contractLength ; 
  setQuoteDps(calculateDpPriceMatrix(quoteACV));
  var featurePricesQuote = featurePrice(1,basePriceQuote,featureDiscount,props.feature1,props.feature2,props.feature3,props.feature4,props.feature5,props.feature6,props.feature7,props.feature8,props.feature9,props.feature10,props.feature11,props.feature12,props.feature13,props.feature14,props.nextGen);
  setFeaturePriceQuote(featurePricesQuote);

  if(props.region !== 'Japan'){
    if((totalMonthlyCost * 12) < 4850){
      let multiplierQuo = 4800 / (totalMonthlyCostQuo * 12);
      setBasePriceQuote(basePriceQuo * multiplierQuo);
      setTotalMonthlyCostQuo(totalMonthlyCostQuo * multiplierQuo);

    }
  }
  else{
    if((totalMonthlyCost * 12) < 3550){
      let multiplierQuo = 3500 / (totalMonthlyCostQuo * 12);
      setBasePriceQuote(basePriceQuo * multiplierQuo);
      setTotalMonthlyCostQuo(totalMonthlyCostQuo * multiplierQuo);

    }
  }
}
var supportRatesAll = supportRates(props.supportList,basePriceRate*12,basePriceRecommended*12,basePriceLower*12,basePriceQuo*12);

const NumberRow = (numberValue) => {
  return(
      <NumberFormat value={roundNearest50(numberValue * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/>
  );
}

const basePrices = { 
  rate: basePriceRate,
  recommended: basePriceRecommended,
  lower: basePriceLower,
  quote: basePriceQuo
}
const featurePrices ={ 
  rate: featurePricesRate.completeFeaturePrice,
  recommended: featurePricesRecommended.completeFeaturePrice,
  lower: featurePricesLower.completeFeaturePrice,
  quote: featurePriceQuote.completeFeaturePrice
}
const packagePrices ={
  rate: packagePricingAll.completePackagePrice,
  recommended: packagePricingAll.completePackagePrice,
  lower: packagePricingAll.completePackagePriceMin,
  quote: packagePricingAll.completePackagePriceMin
}
const totalMonthlyCosts ={ 
  rate: totalMonthlyCostRate,
  recommended: totalMonthlyCostRecommended,
  lower: totalMonthlyCostLower,
  quote: totalMonthlyCostQuo
}
const discountCurrencys ={
  rate: 0,
  recommended: totalMonthlyCostRecommended - totalMonthlyCostRate,
  lower: totalMonthlyCostLower - totalMonthlyCostRate,
  quote: totalMonthlyCostRate - totalMonthlyCostRate
}
const discountPercents ={
  rate: 0,
  recommended: Math.round((totalMonthlyCostRecommended - totalMonthlyCostRate) / totalMonthlyCostRate * 100),
  lower: Math.round((totalMonthlyCostLower - totalMonthlyCostRate) / totalMonthlyCostRate * 100),
  quote: Math.round((totalMonthlyCostQuo - totalMonthlyCostRate) / totalMonthlyCostRate * 100)
}
saveInputs(
            props,
            acvQuote,
            basePrices,
            featurePrices,
            packagePrices,
            totalMonthlyCosts,
            discountCurrencys,
            discountPercents,
            "data"
)

return (
  <Card>
    <br/>
    <Row style={{width:'100%'}}>
        <Col>
          <Card style={{background:"lightgrey"}}>
            <div>Included MAUs (monthly)</div>
          </Card>
        </Col>

        <Col xs={9}>
          <Card>
            {mauKeyStats > includedMaus &&
              <div style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center', background:'tomato'}}>
              <NumberFormat value={includedMaus} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
            </div>
            }
            {mauKeyStats < includedMaus &&
            <div style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>
              <NumberFormat value={includedMaus} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
            </div>
            }
          </Card>
        </Col>
    </Row>

    <Row style={{width:'100%'}}>
        <Col>
          <Card style={{background:"lightgrey"}}>
            <div>Additional MAUs Rate</div>
          </Card>
        </Col>

        <Col xs={9}>
          <Card>
            <div style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>
              {currencyPrefix}
              <NumberFormat value={Math.round(additionalMauPrice * currencyMultiplier * 10000)/10000} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
            </div>
          </Card>
        </Col>
    </Row>
    
            
    <br/>

    <Row style={{width:'100%'}}>
        <Col>
          <Card style={{background:"lightgrey"}}>
            Incl. Support Level
          </Card>
        </Col>

        <Col>
          <Card style={{background:"lightblue"}}>
            {supportRatesAll.supportPackageRate}
          </Card>
        </Col>

        <Col>
          <Card style={{background:"lightgreen"}}>
            {supportRatesAll.supportPackageRecommended}
          </Card>
        </Col>

        <Col>
          <Card style={{background:"tomato"}}>
            {supportRatesAll.supportPackageLower}
          </Card>
        </Col>

        <Col>
          <Card style={{background:"yellow"}}>
            {supportRatesAll.supportPackageQuote}
          </Card>
        </Col>
      </Row>
      <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey"}}>
              <div>Additional Data Points Rate per 100 Million</div>
            </Card>
          </Col>

          <Col xs={9}>
            <Card>
              <div style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>
                {currencyPrefix}
                <NumberFormat value={calculateDataPoints100m(props.currency)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />
              </div>
            </Card>
          </Col>
        </Row>


      <Card style={{backgroundColor:"#FFCC00"}}>
          <Card style={{marginLeft: '1%',marginTop:'1%',border:'0px'}}>
        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"#FFCC00",textAlign:'right',border:'0px',borderRadius:'0'}}>
              Cost Breakdown (Monthly)
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightblue",borderRadius:'0'}}>
              Rate Card
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightgreen",borderRadius:'0'}}>
              Recommended
            </Card>
          </Col>

          <Col>
            <Card style={{background:"tomato",borderRadius:'0'}}>
              Lower Limit
            </Card>
          </Col>

          <Col>
            <Card style={{background:"yellow",borderRadius:'0'}}>
              Quote
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
              Base Price
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',borderRadius:'0'}}>
              {NumberRow(basePriceRate)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(basePriceRecommended)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(basePriceLower)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="basePriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(basePriceQuo)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
            Features (details below)
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesRate.completeFeaturePrice)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesRecommended.completeFeaturePrice)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesLower.completeFeaturePrice)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="addOnPriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(featurePriceQuo)}
              </div>
            </Card>
          </Col>
        </Row>


        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
            Packages (details below)
            </Card>
          </Col>

          <Col>
            <Card> 
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePrice)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePrice)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePriceMin)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="addOnPriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePriceMin)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightblue",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
            Total Monthly Cost
            </Card>
          </Col>

          <Col>
            <Card style={{borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right',fontWeight: 'bold'}}>
              {NumberRow(totalMonthlyCostRate)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right',fontWeight: 'bold'}}>
              {NumberRow(totalMonthlyCostRecommended)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right',fontWeight: 'bold'}}>
              {NumberRow(totalMonthlyCostLower)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div id="totalMoCostQuo" style={{textAlign: 'right',fontWeight: 'bold'}}>
              {NumberRow(basePriceQuo + featurePriceQuo + packagePricingAll.completePackagePriceMin)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{textAlign: 'right',fontStyle: 'italic',borderRadius:'0'}}>
            Discount {currencyPrefix}
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow(0)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow(totalMonthlyCostRecommended - totalMonthlyCostRate)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow(totalMonthlyCostLower - totalMonthlyCostRate)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow(totalMonthlyCostQuo - totalMonthlyCostRate)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{textAlign: 'right',fontStyle: 'italic',borderRadius:'0'}}>
            Discount %
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>0%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostRecommended - totalMonthlyCostRate) / totalMonthlyCostRate * 100)}%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostLower - totalMonthlyCostRate) / totalMonthlyCostRate * 100)}%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div  style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostQuo - totalMonthlyCostRate) / totalMonthlyCostRate * 100)} %</div>
            </Card>
          </Col>
        </Row>
        </Card>
        </Card>

        <br/>
        <Card style={{backgroundColor:"#FFCC00"}}>
          <Card style={{marginLeft: '1%',marginTop:'1%',border:'0px',borderRadius:'0'}}>
        <Row style={{width:'100%'}}>
          <Col>
           <Card style={{background:"#FFCC00",textAlign:'right',border:'0px',borderRadius:'0'}}>
              Cost Breakdown (Annually)
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightblue",borderRadius:'0'}}>
              Rate Card
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightgreen",borderRadius:'0'}}>
              Recommended
            </Card>
          </Col>

          <Col>
            <Card style={{background:"tomato",borderRadius:'0'}}>
              Lower Limit
            </Card>
          </Col>

          <Col>
            <Card style={{background:"yellow",borderRadius:'0'}}>
              Quote
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
              Base Price
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
                {NumberRow(basePriceRate * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(basePriceRecommended * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(basePriceLower * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="basePriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(basePriceQuo * 12)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
            Features
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesRate.completeFeaturePrice * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesRecommended.completeFeaturePrice * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(featurePricesLower.completeFeaturePrice * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="addOnPriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(featurePriceQuo * 12)}
              </div>
            </Card>
          </Col>
        </Row>


        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightgrey",borderRadius:'0'}}>
            Packages
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePrice * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePrice * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePriceMin * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div id="addOnPriceQuo" style={{textAlign: 'right'}}>
              {NumberRow(packagePricingAll.completePackagePriceMin * 12)}
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightblue",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
            Total ACV
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightblue",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow((totalMonthlyCostRate) * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightgreen",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow(totalMonthlyCostRecommended * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{background:"tomato",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow(totalMonthlyCostLower * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{background:"#ffffcc",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
            {NumberRow((basePriceQuo + featurePriceQuo + packagePricingAll.completePackagePriceMin) * 12)}
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{textAlign: 'right',fontStyle: 'italic',borderRadius:'0'}}>
            Discount {currencyPrefix}
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow(0)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow((totalMonthlyCostRecommended - totalMonthlyCostRate) * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow((totalMonthlyCostLower - totalMonthlyCostRate) * 12)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>
              {NumberRow((totalMonthlyCostQuo - totalMonthlyCostRate) * 12)}
              </div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{textAlign: 'right',fontStyle: 'italic',borderRadius:'0'}}>
            Discount %
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>0%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostRecommended - totalMonthlyCostRate) / totalMonthlyCostRate * 100)}%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostLower - totalMonthlyCostRate) / totalMonthlyCostRate * 100)}%</div>
            </Card>
          </Col>

          <Col>
            <Card>
              <div  style={{textAlign: 'right',fontStyle: 'italic'}}>{Math.round((totalMonthlyCostQuo - totalMonthlyCostRate) / totalMonthlyCostRate * 100)} %</div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
          <Col>
            <Card style={{background:"lightblue",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
            TCV
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightblue",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow(tcvRate)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{background:"lightgreen",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow(tcvRecommended)}
              </div>
            </Card>
          </Col>

          <Col>
            <Card style={{background:"tomato",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <div style={{textAlign: 'right'}}>
              {NumberRow(tcvLower)}
              </div>
            </Card>
          </Col>


          <Col>
            <Card style={{background:"#ffffcc",fontWeight: 'bold',borderTopWidth:'4px',borderTopColor:'black',borderRadius:'0'}}>
              <Form.Control style = {{ width: '96%',marginLeft: '2%',background: '#ffffcc' }} type="number" placeholder="e.g 10000" id="acvQuote" onChange={e => setAcvQuote(e.target.value)}/>
            </Card>
          </Col>
        </Row>
        </Card>
        </Card>
        <br/>
        <Button style = {{ marginLeft: '20px',marginRight: '20px',marginTop: '20px',marginBottom: '20px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={hideExtras} type="submit">{extraText}</Button>

        {showExtras &&
        <div>
          <Row style={{width:'20%'}}>
            <Col>
              <Card style={{background:"lightgrey"}}>
              Add-on Features:
              </Card>
            </Col>
          </Row>

          <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px',background:"#FFCC00"}}>Add-On Name</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'lightblue'}}>Rate Card</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'lightgreen'}}>Recommended</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'tomato'}}>Lower Limit</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'yellow'}}>Quote</div>
              </Card>
            </Col>
          </Row>

         <FeatureOutput
          featureNumber={1}
          featureName={props.feature1}
          priceRate={featurePricesRate.feature1price}
          priceRec={featurePricesRecommended.feature1price}
          priceLow={featurePricesLower.feature1price}
          priceQuo={featurePriceQuote.feature1price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={2}
          featureName={props.feature2}
          priceRate={featurePricesRate.feature2price}
          priceRec={featurePricesRecommended.feature2price}
          priceLow={featurePricesLower.feature2price}
          priceQuo={featurePriceQuote.feature2price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={3}
          featureName={props.feature3}
          priceRate={featurePricesRate.feature3price}
          priceRec={featurePricesRecommended.feature3price}
          priceLow={featurePricesLower.feature3price}
          priceQuo={featurePriceQuote.feature3price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={4}
          featureName={props.feature4}
          priceRate={featurePricesRate.feature4price}
          priceRec={featurePricesRecommended.feature4price}
          priceLow={featurePricesLower.feature4price}
          priceQuo={featurePriceQuote.feature4price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={5}
          featureName={props.feature5}
          priceRate={featurePricesRate.feature5price}
          priceRec={featurePricesRecommended.feature5price}
          priceLow={featurePricesLower.feature5price}
          priceQuo={featurePriceQuote.feature5price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={6}
          featureName={props.feature6}
          priceRate={featurePricesRate.feature6price}
          priceRec={featurePricesRecommended.feature6price}
          priceLow={featurePricesLower.feature6price}
          priceQuo={featurePriceQuote.feature6price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={7}
          featureName={props.feature7}
          priceRate={featurePricesRate.feature7price}
          priceRec={featurePricesRecommended.feature7price}
          priceLow={featurePricesLower.feature7price}
          priceQuo={featurePriceQuote.feature7price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={8}
          featureName={props.feature8}
          priceRate={featurePricesRate.feature8price}
          priceRec={featurePricesRecommended.feature8price}
          priceLow={featurePricesLower.feature8price}
          priceQuo={featurePriceQuote.feature8price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={9}
          featureName={props.feature9}
          priceRate={featurePricesRate.feature9price}
          priceRec={featurePricesRecommended.feature9price}
          priceLow={featurePricesLower.feature9price}
          priceQuo={featurePriceQuote.feature9price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
          <FeatureOutput
          featureNumber={10}
          featureName={props.feature10}
          priceRate={featurePricesRate.feature10price}
          priceRec={featurePricesRecommended.feature10price}
          priceLow={featurePricesLower.feature10price}
          priceQuo={featurePriceQuote.feature10price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
        <FeatureOutput
          featureNumber={11}
          featureName={props.feature11}
          priceRate={featurePricesRate.feature11price}
          priceRec={featurePricesRecommended.feature11price}
          priceLow={featurePricesLower.feature11price}
          priceQuo={featurePriceQuote.feature11price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
        <FeatureOutput
          featureNumber={12}
          featureName={props.feature12}
          priceRate={featurePricesRate.feature12price}
          priceRec={featurePricesRecommended.feature12price}
          priceLow={featurePricesLower.feature12price}
          priceQuo={featurePriceQuote.feature12price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />
        <FeatureOutput
          featureNumber={13}
          featureName={props.feature13}
          priceRate={featurePricesRate.feature13price}
          priceRec={featurePricesRecommended.feature13price}
          priceLow={featurePricesLower.feature13price}
          priceQuo={featurePriceQuote.feature13price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
        />
        <FeatureOutput
          featureNumber={14}
          featureName={props.feature14}
          priceRate={featurePricesRate.feature14price}
          priceRec={featurePricesRecommended.feature14price}
          priceLow={featurePricesLower.feature14price}
          priceQuo={featurePriceQuote.feature14price}
          currencyPrefix={currencyPrefix}
          thousandSeparator = {thousandSeparator}
          decimalSeparator = {decimalSeparator}
          currencyMultiplier ={currencyMultiplier}
         />

        <br/>

        <Row style={{width:'20%'}}>
          <Col>
            <Card style={{background:"lightgrey"}}>
            Additional Packages:
            </Card>
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px',background:"#FFCC00"}}>Package Name</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'lightblue'}}>Rate Card</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'lightgreen'}}>Recommended</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'tomato'}}>Lower Limit</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',background:'yellow'}}>Quote</div>
              </Card>
            </Col>
        </Row>
        {dataManagement === 'true' &&
        <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px'}}>Custom Dimension / Metrics</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(2000 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(2000 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(500 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(500 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>
        </Row>
        }
        {props.pulse !== 'false' &&
        <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px'}}>Pulse</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.pulsePrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.pulsePrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.pulsePriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.pulsePriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>
        </Row>
        }

        {props.spendWorks !== 'false' &&
        <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px'}}>Spend Works</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>
        </Row>
        }
        {props.incrementality !== 'false' &&
        <Row style={{width:'100%'}}>
            <Col>
              <Card>
                <div style={{fontSize:'14px'}}>InSight</div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
              </Card>
            </Col>
        </Row>
        }
        {props.nextGen !== 'false' &&
        <>
          <Row style={{width:'100%'}}>
              <Col>
                <Card>
                  <div style={{fontSize:'14px'}}>Pulse (Alerts+Campaign Automation)</div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(packagePricingAll.pulsePrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(packagePricingAll.pulsePrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(packagePricingAll.pulsePriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={roundNearest50(packagePricingAll.pulsePriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>
          </Row>
          {props.nextGen !== 'pulse' &&
          <>
            {props.incrementality === 'false' &&
            <Row style={{width:'100%'}}>
              <Col>
                <Card>
                  <div style={{fontSize:'14px'}}>InSight </div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPrice / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.incrementalityPriceMin / 12 * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>
          </Row>
          }
          <Row style={{width:'100%'}}>
              <Col>
                <Card>
                  <div style={{fontSize:'14px'}}>Assist </div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.assistRec * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.assistRec * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.assistMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.assistMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>
          </Row>
          <Row style={{width:'100%'}}>
              <Col>
                <Card>
                  <div style={{fontSize:'14px'}}>SpendWorks Enterprise </div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPrice * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>

              <Col>
                <Card>
                  <div style={{fontSize:'14px',textAlign: 'center'}}><NumberFormat value={ceilNearest50(packagePricingAll.spendWorksPriceMin * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
                </Card>
              </Col>
          </Row>
        </>
        }
        </>
        }
      </div>
      }

    </Card>
  );
}