import {Row, Col, Card } from 'react-bootstrap';
import NumberFormat from'react-number-format';

export const KeyStatsTable = (props) => {
    let attributions = 0;
    let maus = 0;
    let dataPoints = 0;

    let limitAttributions = 0;
    let limitMaus = 0;
    let limitDataPoints = 0;

    let usedAttributions = 0;

    if(props.data[4] > 0){
        attributions = props.data[4];
    }
    if(props.data[1] > 0){
        maus = props.data[1];
    }
    if(props.data[2] > 0){
        dataPoints = props.data[2];
    }

    if(props.data[3] > 0){
        limitAttributions = props.data[3];
        usedAttributions = Math.round( (attributions/limitAttributions) * 100) / 100;
    }
        

    return (
        <div>
            <Row style={{width:'100%'}}>
                <Col>
                    <Card>TYPE</Card>
                </Col>
                <Col>
                    <Card>LIMIT</Card>
                </Col>
                <Col>
                    <Card>USED</Card>
                </Col>
                <Col>
                    <Card>USED IN %</Card>
                </Col>
          </Row>

          <Row style={{width:'100%'}}>
                <Col>
                    <Card>Attribution</Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={limitAttributions} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={attributions} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    {usedAttributions > 1 && 
                        <Card style={{background: "tomato"}}>{usedAttributions}</Card>
                    }
                    {usedAttributions < 1 && 
                        <Card>{usedAttributions}</Card>
                    }
                </Col>
          </Row>

          <Row style={{width:'100%'}}>
                <Col>
                    <Card>Maus</Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={limitMaus} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={maus} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    <Card>N/A</Card>
                </Col>
          </Row>

          <Row style={{width:'100%'}}>
                <Col>
                    <Card>Data Points</Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={limitDataPoints} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    <Card><NumberFormat value={dataPoints} displayType={'text'} thousandSeparator={true}/></Card>
                </Col>
                <Col>
                    <Card>N/A</Card>
                </Col>
          </Row>
        </div>
    );
}