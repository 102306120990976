function roundNearest50(num) {
  return Math.round(num / 50) * 50;
}

export const featureCostCalc = (basePrice,featureDiscount,featureName,isLower,nextGen) => {
    if(featureName === 'Fraud Prevention Suite'){return basePrice * 0.2 * (1 - featureDiscount);}
    if(featureName === 'Campaign Automation'){
      if(nextGen !== 'false'){
        return 0;
      }
      return basePrice * 0.2 * (1 - featureDiscount);}
    if(featureName === 'Data Residency'){return basePrice * 0.2 * (1 - featureDiscount);}
    if(featureName === 'Audience Builder'){return basePrice * 0.1 * (1 - featureDiscount);}
    if(featureName === 'Subscription Tracking'){return basePrice * 0.1 * (1 - featureDiscount);}
    if(featureName === 'Ad Revenue Tracking'){return basePrice  * 0.15 * (1 - featureDiscount);}
    if(featureName === 'Web SDK'){return basePrice * 0.05 * (1 - featureDiscount);}
    if(featureName === 'Multi Touch Attribution'){return basePrice * 0.05 * (1 - featureDiscount);}
    if(featureName === 'AdVision (CTV)'){return basePrice * 0.05 * (1 - featureDiscount);}
    
    if(featureName === 'SSO'){return basePrice * 0.05 * (1 - featureDiscount);}
    if(featureName === 'PC / Console Tracking'){
      let price = basePrice  * 0.15 * (1 - featureDiscount);
      if(price < (5000/12)){
        price = (5000/12);
      }
      return price;
    }
    if(featureName === 'Subscriptions'){
      let price = basePrice  * 0.20 * (1 - featureDiscount);
      if(price < (3000/12)){
        price = (3000/12);
      }
      return price;
    }
    if(featureName === 'Automation API'){
      let price = basePrice  * 0.10  * (1 - featureDiscount);
      if(price < (3000/12)){
        price = (3000/12);
      }
      return price;
    }
    if(featureName === 'Assist Dashboard'){
      if(nextGen !== 'false' && nextGen !== 'pulse'){
        return 0;
      }
      let price = basePrice * 0.15  * (1 - featureDiscount);
      if(price < 200){
        price = 200;
      }
      return price;
    }
    if(featureName === 'Smart Banners'){
      let price = basePrice  * 0.20 * (1 - featureDiscount);
      if(price < (1000)){
        price = 1000;
      }
      return price;
    }
    if(featureName === 'Purchase Verification 2.0'){
      let price = 0 ;
      if(isLower === 0){
        price = basePrice  * 0.10 * (1 - featureDiscount);
      }
      else{
        price = basePrice  * 0.05 * (1 - featureDiscount);
      }
      return price;
    }
    return 0;
  }

export const featurePrice = (isLower,basePrice,featureDiscount,feature1,feature2,feature3,feature4,feature5,feature6,feature7,feature8,feature9,feature10,feature11,feature12,feature13,feature14,nextGen) => {
  var feature1price = featureCostCalc (basePrice,featureDiscount,feature1,isLower,nextGen)
  var feature2price = featureCostCalc (basePrice,featureDiscount,feature2,isLower,nextGen)
  var feature3price = featureCostCalc (basePrice,featureDiscount,feature3,isLower,nextGen)
  var feature4price = featureCostCalc (basePrice,featureDiscount,feature4,isLower,nextGen)
  var feature5price = featureCostCalc (basePrice,featureDiscount,feature5,isLower,nextGen)
  var feature6price = featureCostCalc (basePrice,featureDiscount,feature6,isLower,nextGen)
  var feature7price = featureCostCalc (basePrice,featureDiscount,feature7,isLower,nextGen)
  var feature8price = featureCostCalc (basePrice,featureDiscount,feature8,isLower,nextGen)
  var feature9price = featureCostCalc (basePrice,featureDiscount,feature9,isLower,nextGen)
  var feature10price = featureCostCalc (basePrice,featureDiscount,feature10,isLower,nextGen)
  var feature11price = featureCostCalc (basePrice,featureDiscount,feature11,isLower,nextGen)
  var feature12price = featureCostCalc (basePrice,featureDiscount,feature12,isLower,nextGen)
  var feature13price = featureCostCalc (basePrice,featureDiscount,feature13,isLower,nextGen)
  var feature14price = featureCostCalc (basePrice,featureDiscount,feature14,isLower,nextGen)
  var completeFeaturePrice = roundNearest50(feature1price)+roundNearest50(feature2price)+roundNearest50(feature3price)+roundNearest50(feature4price)+
  roundNearest50(feature5price)+roundNearest50(feature6price)+roundNearest50(feature7price)+roundNearest50(feature8price)+roundNearest50(feature9price)+
  roundNearest50(feature10price)+roundNearest50(feature11price)+roundNearest50(feature12price)+roundNearest50(feature13price)+roundNearest50(feature14price);
  return {feature1price,feature2price,feature3price,feature4price,feature5price,feature6price,feature7price,feature8price,feature9price,feature10price,feature11price,feature12price,feature13price,feature14price,completeFeaturePrice}
}
