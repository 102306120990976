import React from "react";
import BuilderAttribution from './builderAttribution.js';
import BuilderMau from './builderMau.js';
import BuilderData from './builderData.js';
import { Updates } from "./Updates.jsx";
import { MyAccounts } from "./MyAccounts.jsx";
import BuilderAttributionTest from "./builderAttributionTest.js";
import { Accounts } from "./Account.jsx";
import { ManagerAccounts } from "./ManagerAccounts.jsx";
import { Manager } from "./Manager.jsx";
import BuilderMauTest from "./builderMauTest.js";
import BuilderDataTest from "./builderDpTest.js";
import { Creator } from "./Creator.jsx";
import { Statistics } from "./Statistics.js";


export default function BuilderLoader() {
  function getSiteName(siteName){
    if (siteName === '/'){
      return <BuilderAttribution/>;
    }
    if (siteName === '/mau'){
      return <BuilderMau/>;
    }
    if (siteName === '/data'){
      return <BuilderData/>;
    }
    if (siteName === '/updates'){
      return <Updates />;
    }
    if (siteName === '/myaccounts'){
      return <MyAccounts />;
    }
    if (siteName === '/accounts'){
      return <Accounts />;
    }
    if (siteName === '/manager'){
      return <Manager />;
    }
    if (siteName === '/manageraccounts'){
      return <ManagerAccounts />;
    }
    if (siteName === '/attributiontest'){
      return <BuilderAttributionTest />;
    }
    if (siteName === '/mautest'){
      return <BuilderMauTest />;
    }
    if (siteName === '/dptest'){
      return <BuilderDataTest />;
    }
    if (siteName === '/creator'){
      return <Creator />;
    }
    if (siteName === '/statistics'){
      return <Statistics />;
    }
    return <BuilderAttribution/>;
  }
  const siteName = getSiteName(window.location.pathname);

return (
  <div>
    {siteName}
  </div>
);
}
