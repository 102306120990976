export const packagePricingNextGen = (dataManagement,pulse,pulseCount,spendWorks,incrementality,nextGen,trueLink,basePriceRecommended,basePriceLower) =>{
    if (nextGen === 'false'){
        let dataManagementMin = 0; 
        let dataManagementCost = 0;
        if (dataManagement === 'true'){
            dataManagementCost = 2000;
            dataManagementMin = 500;
        }
        else{
            dataManagementCost = 0;
            dataManagementMin = 0;
        }

        let pulsePrice = 0;
        let pulsePriceMin = 0;
        if(pulse === 'base'){
            pulsePrice = 10000;
            pulsePriceMin = 5000;
        }
        if(pulse === 'core'){
            pulsePrice = 20000;
            pulsePriceMin = 10000;
        }
        if(pulse === 'enterprise'){
            pulsePrice = 35000;
            pulsePriceMin = 25000;
        }
        if(pulse === 'custom'){
            var extraPulsePrice = (pulseCount - 50) * 500;
            pulsePrice = 35000 + extraPulsePrice;
            pulsePriceMin = 35000 + extraPulsePrice;
        }

        let spendWorksPrice = 0;
        let spendWorksPriceMin = 0;
        if(spendWorks === 'base'){
            spendWorksPrice = 500;
            spendWorksPriceMin = 0;
        }
        if(spendWorks === 'core'){
            spendWorksPrice = 1000;
            spendWorksPriceMin = 500;
        }
        if(spendWorks === 'enterprise'){
            spendWorksPrice = 3000;
            spendWorksPriceMin = 1000;
        }
        if(spendWorks === 'custom'){
            spendWorksPrice = 9000;
            spendWorksPriceMin = 3000;
        }

        let incrementalityPriceMin = 0;
        let incrementalityPrice = 0;
        if(incrementality === 'base15'){
            incrementalityPriceMin = 10000;
            incrementalityPrice = 16500;
        }
        if(incrementality === 'base25'){
            incrementalityPriceMin = 14400;
            incrementalityPrice = 22500;
        }
        if(incrementality === 'base50'){
            incrementalityPriceMin = 17680;
            incrementalityPrice = 34000;
        }
        if(incrementality === 'base100'){
            incrementalityPriceMin = 26112;
            incrementalityPrice = 51200;
        }
        if(incrementality === 'ignite'){
            incrementalityPriceMin = 6000;
            incrementalityPrice = 6000;
        }
        let trueLinkPriceMin = 0;
        let trueLinkPrice = 0;
        if(trueLink === 'base'){
            trueLinkPriceMin = 0;
            trueLinkPrice = 0;
        }
        if(trueLink === 'corestarter'){
            trueLinkPriceMin = 0;
            trueLinkPrice = basePriceRecommended * 0.1;
        }
        if(trueLink === 'corestandard'){
            trueLinkPriceMin = basePriceRecommended * 0.1;
            trueLinkPrice = basePriceRecommended * 0.2;
        }
        if(trueLink === 'enterprise'){
            trueLinkPriceMin = 8000;
            trueLinkPrice = basePriceRecommended * 0.3;
            if(trueLinkPrice < 8000){
                trueLinkPrice = 8000;
            }
        }
        let completePackagePrice = dataManagementCost + (pulsePrice/12) + spendWorksPrice + (incrementalityPrice/12) + (trueLinkPrice/12);
        let completePackagePriceMin = dataManagementMin + (pulsePriceMin/12) + spendWorksPriceMin + (incrementalityPriceMin/12) + (trueLinkPriceMin/12);
        return{dataManagementCost,dataManagementMin,pulsePrice,pulsePriceMin,spendWorksPrice,spendWorksPriceMin,completePackagePrice,completePackagePriceMin,incrementalityPrice,incrementalityPriceMin,trueLinkPrice,trueLinkPriceMin}
    }
    if (nextGen === 'pulse'){
        let dataManagementMin = 0; 
        let dataManagementCost = 0;
        if (dataManagement === 'true'){
            dataManagementCost = 2000;
            dataManagementMin = 500;
        }
        else{
            dataManagementCost = 0;
            dataManagementMin = 0;
        }

        let spendWorksPrice = 0;
        let spendWorksPriceMin = 0;
        if(spendWorks === 'base'){
            spendWorksPrice = 500;
            spendWorksPriceMin = 0;
        }
        if(spendWorks === 'core'){
            spendWorksPrice = 1000;
            spendWorksPriceMin = 500;
        }
        if(spendWorks === 'enterprise'){
            spendWorksPrice = 3000;
            spendWorksPriceMin = 1000;
        }
        if(spendWorks === 'custom'){
            spendWorksPrice = 9000;
            spendWorksPriceMin = 3000;
        }

        let incrementalityPriceMin = 0;
        let incrementalityPrice = 0;
        if(incrementality === 'base15'){
            incrementalityPriceMin = 10000;
            incrementalityPrice = 16500;
        }
        if(incrementality === 'base25'){
            incrementalityPriceMin = 14400;
            incrementalityPrice = 22500;
        }
        if(incrementality === 'base50'){
            incrementalityPriceMin = 17680;
            incrementalityPrice = 34000;
        }
        if(incrementality === 'base100'){
            incrementalityPriceMin = 26112;
            incrementalityPrice = 51200;
        }
        if(incrementality === 'ignite'){
            incrementalityPriceMin = 6000;
            incrementalityPrice = 6000;
        }
        let pulsePriceMin = (basePriceLower * 0.3) * 0.7;
        let pulsePrice = basePriceRecommended * 0.3;
        if(pulsePriceMin < 416){
            pulsePriceMin = 416;
        }
        if(pulsePrice < 416){
            pulsePrice = 416;
        }
        let trueLinkPriceMin = 0;
        let trueLinkPrice = 0;
        if(trueLink === 'base'){
            trueLinkPriceMin = 0;
            trueLinkPrice = 0;
        }
        if(trueLink === 'corestarter'){
            trueLinkPriceMin = 0;
            trueLinkPrice = basePriceRecommended * 0.1;
        }
        if(trueLink === 'corestandard'){
            trueLinkPriceMin = basePriceRecommended * 0.1;
            trueLinkPrice = basePriceRecommended * 0.2;
        }
        if(trueLink === 'enterprise'){
            trueLinkPriceMin = basePriceRecommended * 0.21;
            trueLinkPrice = basePriceRecommended * 0.3;
        }
        let completePackagePrice = dataManagementCost + spendWorksPrice + (incrementalityPrice/12) + pulsePrice + trueLinkPrice;
        let completePackagePriceMin = dataManagementMin + spendWorksPriceMin + (incrementalityPriceMin/12) + pulsePriceMin + trueLinkPriceMin; 
        return{dataManagementCost,dataManagementMin,pulsePrice,pulsePriceMin,spendWorksPrice,spendWorksPriceMin,completePackagePrice,completePackagePriceMin,incrementalityPrice,incrementalityPriceMin,trueLinkPrice,trueLinkPriceMin}
    }
    let dataManagementMin = 0; 
    let dataManagementCost = 0;
    if (dataManagement === 'true'){
        dataManagementCost = 2000;
        dataManagementMin = 500;
    }
    else{
        dataManagementCost = 0;
        dataManagementMin = 0;
    }

    let spendWorksPrice = 500;
    let spendWorksPriceMin = 500;
    let incrementalityPriceMin = 6000;
    let incrementalityPrice = 6000;
    if(nextGen === 'existingbiz'){
        incrementalityPriceMin = 10000;
        incrementalityPrice = 10000;
    }
    if(incrementality === 'base15'){
        incrementalityPriceMin = 10000;
        incrementalityPrice = 16500;
    }
    if(incrementality === 'base25'){
        incrementalityPriceMin = 14400;
        incrementalityPrice = 22500;
    }
    if(incrementality === 'base50'){
        incrementalityPriceMin = 17680;
        incrementalityPrice = 34000;
    }
    if(incrementality === 'base100'){
        incrementalityPriceMin = 26112;
        incrementalityPrice = 51200;
    }
    if(incrementality === 'ignite'){
        incrementalityPriceMin = 6000;
        incrementalityPrice = 6000;
    }
    let pulsePriceMin = (basePriceLower * 0.3) * 0.7;
    let pulsePrice = basePriceRecommended * 0.3;
    if(pulsePrice < 416){
        pulsePrice = 416;
    }
    if(pulsePriceMin < 416){
        pulsePriceMin = 416;
    }
    let assistMin = (basePriceLower * 0.15) * 0.7;
    let assistRec = basePriceRecommended * 0.15;
    let trueLinkPriceMin = 0;
    let trueLinkPrice = 0;
    if(trueLink === 'base'){
        trueLinkPriceMin = 0;
        trueLinkPrice = 0;
    }
    if(trueLink === 'corestarter'){
        trueLinkPriceMin = 0;
        trueLinkPrice = basePriceRecommended * 0.1;
    }
    if(trueLink === 'corestandard'){
        trueLinkPriceMin = basePriceRecommended * 0.1;
        trueLinkPrice = basePriceRecommended * 0.2;
    }
    if(trueLink === 'enterprise'){
        trueLinkPriceMin = basePriceRecommended * 0.21;
        trueLinkPrice = basePriceRecommended * 0.3;
    }
    let completePackagePrice = dataManagementCost + spendWorksPrice + (incrementalityPrice/12) + pulsePrice + assistRec + trueLinkPrice;
    let completePackagePriceMin = dataManagementMin + spendWorksPriceMin + (incrementalityPriceMin/12) + pulsePriceMin + assistMin + trueLinkPriceMin; 
    return{dataManagementCost,dataManagementMin,pulsePrice,pulsePriceMin,spendWorksPrice,spendWorksPriceMin,completePackagePrice,completePackagePriceMin,incrementalityPrice,incrementalityPriceMin,assistRec,assistMin,trueLinkPrice,trueLinkPriceMin}
}
    