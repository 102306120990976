import React, { useState, useEffect } from "react";
import NumberFormat from'react-number-format';
import { SeparatorButtons } from "../components/separatorButtons.js";
import {getCurrency, getPrefix} from "../GeneralValues/Currency";
import { AccountCurrencyButtons } from "../components/AcountCurrencyButtons.js";
import { DataGrid } from '@mui/x-data-grid';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

export const Manager = () => {
    const [accounts,setAccounts] = useState([]);
    const [active,setActive] = useState(false);
    const [thousandSeparator,setSeparator] = useState(',');
    const [decimalSeparator,setDecimalSeparator] = useState('.');
    const [currency, setCurrency] = useState('');

    const hanleSeparatorChange = (value) => {
        if(value === ','){
            setSeparator(',');
            setDecimalSeparator('.');
        }
        else{
            setSeparator('.');
            setDecimalSeparator(',');
        }
    }
    const hanleCurrencyChange = (value) => {
        setCurrency(value);
    }

    useEffect(() => {
            fetch('/api/manageraccounts').then(res => res.json()).then(data => {
                setAccounts(data);
                setActive(true);
            });
      },[accounts]);

    const attributionsLimitReached = (attributions, attributionsLimit) => {
        if(attributions > attributionsLimit && attributionsLimit !== -1){
            return <div style={{background:'tomato',width:'100%',height:'100%',lineHeight:'3rem'}}>
                <NumberFormat value={attributions} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />        
            </div>;
        }
        return <NumberFormat value={attributions} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
    }

    const dpLimitReached = (dataPoints, acv) => {
        let calculatedAcv = dataPoints / 500000;
        if(calculatedAcv > acv){
            return <div style={{background:'tomato',width:'100%',height:'100%',lineHeight:'3rem'}}> <NumberFormat value={dataPoints} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} /> </div>;
        }
        return <NumberFormat value={dataPoints} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
    }

    const columns = [
        { field: 'mail', headerName: 'User Mail',flex: 1, minWidth: 250},
        { field: 'accountId', headerName: 'Account Id',flex: 1, maxWidth: 100},
        { field: 'accountName', headerName: 'Account Name',flex: 1},
        { field: 'status', headerName: 'Status',flex: 1},
        { field: 'month', headerName: 'Last Updated',flex: 1},
        { field: 'acv', headerName: 'ACV in ' + getPrefix(currency),flex: 1,
            renderCell: (params) => {
            return <NumberFormat value={Math.round(params.row.acv * getCurrency(currency))} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
        }},
        { field: 'sumMaus', headerName: 'MAUs',flex: 1,
            renderCell: (params) => {
            return <NumberFormat value={Math.round(params.row.sumMaus)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
        }},
        { field: 'dpContract', headerName: 'Data Points Cap',flex: 1,
            renderCell: (params) => {
            return <NumberFormat value={Math.round(params.row.dpContract * 1000000000)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
        }},
        { field: 'sumDataPoints', headerName: 'Data Points',flex: 1,
            renderCell: (params) => {
            return <>{dpLimitReached(params.row.sumDataPoints, params.row.acv)}</>;
        }},

        { field: 'includedAttributions', headerName: 'Attributions Limit (from Invoice)',flex: 1,
            renderCell: (params) => {
            return <NumberFormat value={Math.round(params.row.includedAttributions)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} />;
        }},
        { field: 'sumAttributions', headerName: 'Attributions',flex: 1,
            renderCell: (params) => {
            return <>{attributionsLimitReached(params.row.sumAttributions,params.row.includedAttributions)}</>;
        }}
      ];

    
    
    function createData(
        id,
        accountId,
        accountName,
        acv,
        includedAttributions,
        month,
        status,
        sumAttributions,
        sumDataPoints,
        sumMaus,
        mail,
        dpContract
      ) {
        return { id,accountId,accountName,acv,includedAttributions,month,status,sumAttributions,sumDataPoints,sumMaus,mail,dpContract};
      }

    const rows = [];
    var id = 0;
    for (var i = 0; i < accounts.length; i++){
        for (var j = 0; j < accounts[i]["accounts"].length; j++){
            rows.push(
                createData(
                    id,
                    accounts[i]["accounts"][j]["accountId"],
                    accounts[i]["accounts"][j]["accountName"],
                    accounts[i]["accounts"][j]["acv"],
                    accounts[i]["accounts"][j]["includedAttributions"],
                    accounts[i]["accounts"][j]["month"],
                    accounts[i]["accounts"][j]["status"],
                    accounts[i]["accounts"][j]["sumAttributions"],
                    accounts[i]["accounts"][j]["sumDataPoints"],
                    accounts[i]["accounts"][j]["sumMaus"],
                    accounts[i]["accounts"][j]["mail"],
                    accounts[i]["accounts"][j]["dpContract"],
                )
            );
            id = id + 1;
        }
    }
    return (
        <div style={{marginLeft:'2%','marginRight':'2%'}}>
            <SeparatorButtons onSeparatorChange = {hanleSeparatorChange} />
            <AccountCurrencyButtons onCurrencyChange = {hanleCurrencyChange} />
            {!active &&
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Loading...
                </Button>
            }
            {active &&
            <div style={{ height: 700, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                />
            </div>
            }
        </div>
    );
            
}