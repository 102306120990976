import React from 'react';
import Navbar from 'react-bootstrap/Navbar';


function NavBars() {

    return (
      <div id="nav" class="shadow-lg">
        <Navbar  expand="lg" style={{background:"#04182b"}}>
          <Navbar.Brand href="/updates" style={{width:'25%'}}>
            <img id="logo-adjust" src={"https://a.storyblok.com/f/47007/x/2e8164e221/adjust-logo-white.svg"} style={{marginLeft:'3%'}} width="200" height="50" alt="adjust.com" />
          </Navbar.Brand>
        </Navbar>
      </div>
    );

}
export default NavBars;
