export const supportRates = (supportList,totalAcvRate,totalAcvRecommended,totalAcvLower,totalAcvQuote) => {
    var supportPackageRate = "Global";
  for (var i = 0; i < supportList.length; i++) {
    if(totalAcvRate > supportList[i][2]){
      supportPackageRate = supportList[i][1];
    }
  }
  var supportPackageRecommended = "Global";
  for (i = 0; i < supportList.length; i++) {
    if((totalAcvRecommended) > supportList[i][2]){
        supportPackageRecommended = supportList[i][1];
    }
  }
  var supportPackageLower = "Global";
  for (i = 0; i < supportList.length; i++) {
    if((totalAcvLower) > supportList[i][2]){
      supportPackageLower = supportList[i][1];
    }
  }
  var supportPackageQuote = "Global";
  for (i = 0; i < supportList.length; i++) {
    if((totalAcvQuote) > supportList[i][2]){
        supportPackageQuote = supportList[i][1];
    }
  }
  return {supportPackageRate,supportPackageRecommended,supportPackageLower,supportPackageQuote}
}