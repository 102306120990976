import React, { useState, useEffect } from "react";
import NavBars from './components/NavBar.js';
import NavBarFull from './components/NavBarFull.js'
import BuilderLoader from './sites/BuilderLoader.js';
import Login from './components/loginButton.js';

export default function App() {
  const [logged, setlogged] = useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_ID;
  function handleSsoChange(value) {
    if(value === 'logout'){
      setlogged(false);
    }
    else{
      setlogged(value);
    }
  }

  useEffect(() => {
    if(!logged){
      let siteName = window.location.pathname;
      if(siteName === "/"){
        siteName = "attribution";
      }
      else{
        siteName = siteName.replace("/", "")
      }
      fetch('/api/authentification/'+siteName).then(res => res.json()).then(data => {
        setlogged(data.login);
      });
    }
  }, [logged]);

    return (
      <div>
        {logged === true &&
        <div>
          <NavBarFull onSsoChange={handleSsoChange} clientId={clientId}/>
          <br/>
          <BuilderLoader/>
        </div>
        }

      {logged !== true &&
      <div>
        <NavBars/>
        <div style = {{ width: '20%',marginLeft: '45%', marginRight: '40%' }}>
          <br/>
          <br/>
          <Login onSsoChange={handleSsoChange} clientId={clientId}/>
        </div>
      </div>
      }
      </div>
    );

}
