export const matrix = [
[0,0,0,0],
[1,50,1000,500],
[2,100,2000,1500],
[3,150,4000,2000],
[4,200,6000,2500],
[5,250,7000,3000],
[6,300,8000,8000],
[7,350,10000,12000],
[8,400,12000,16000],
[9,450,14000,20000],
[10,500,15000,25000],
[11,550,16000,29000],
[12,600,18000,33000],
[13,650,20000,38000],
[14,700,22000,42000],
[15,750,24000,47000],
[16,800,26000,51000],
[17,850,27000,56000],
[18,900,28000,60000],
[19,950,30000,65000],
[20,1000,32000,70000],
[21,1050,34000,74000],
[22,1100,36000,79000],
[23,1150,38000,84000],
[24,1200,40000,89000],
[25,1250,42000,94000],
[26,1300,44000,99000],
[27,1350,46000,104000],
[28,1400,47000,109000],
[29,1450,48000,114000],
[30,1500,50000,119000],
[31,1550,52000,124000],
[32,1600,54000,130000],
[33,1650,56000,135000],
[34,1700,58000,140000],
[35,1750,60000,146000],
[36,1800,62000,151000],
[37,1850,64000,157000],
[38,1900,66000,163000],
[39,1950,68000,168000],
[40,2000,70000,174000],
[41,2050,74000,180000],
[42,2100,76000,186000],
[43,2150,78000,192000],
[44,2200,80000,198000],
[45,2250,82000,204000],
[46,2300,84000,210000],
[47,2350,86000,216000],
[48,2400,88000,223000],
[49,2450,90000,229000],
[50,2500,92000,235000],
[51,2550,96000,242000],
[52,2600,98000,249000],
[53,2650,100000,255000],
[54,2700,102000,262000],
[55,2750,104000,269000],
[56,2800,108000,276000],
[57,2850,110000,283000],
[58,2900,112000,291000],
[59,2950,114000,298000],
[60,3000,118000,305000],
[61,3050,120000,313000],
[62,3100,122000,321000],
[63,3150,126000,328000],
[64,3200,128000,336000],
[65,3250,130000,344000],
[66,3300,134000,353000],
[67,3350,136000,361000],
[68,3400,138000,369000],
[69,3450,142000,378000],
[70,3500,144000,386000],
[71,3550,148000,395000],
[72,3600,150000,404000],
[73,3650,154000,413000],
[74,3700,156000,423000],
[75,3750,160000,432000],
[76,3800,162000,442000],
[77,3850,166000,451000],
[78,3900,170000,461000],
[79,3950,172000,471000],
[80,4000,176000,482000],
[81,4050,180000,492000],
[82,4100,182000,503000],
[83,4150,186000,514000],
[84,4200,190000,524000],
[85,4250,194000,536000],
[86,4300,196000,547000],
[87,4350,200000,558000],
[88,4400,204000,570000],
[89,4450,208000,582000],
[90,4500,212000,594000],
[91,4550,216000,606000],
[92,4600,220000,618000],
[93,4650,224000,631000],
[94,4700,228000,643000],
[95,4750,234000,656000],
[96,4800,238000,669000],
[97,4850,242000,682000],
[98,4900,246000,695000],
[99,4950,252000,708000],
[100,5000,256000,722000],
[101,5050,262000,735000],
[102,5100,266000,748000],
[103,5150,272000,762000],
[104,5200,278000,775000],
[105,5250,282000,788000],
[106,5300,288000,802000],
[107,5350,294000,815000],
[108,5400,300000,828000],
[109,5450,306000,841000],
[110,5500,312000,854000],
[111,5550,320000,867000],
[112,5600,326000,880000],
[113,5650,334000,893000],
[114,5700,340000,906000],
[115,5750,348000,918000],
[116,5800,356000,931000],
[117,5850,364000,943000],
[118,5900,372000,955000],
[119,5950,380000,967000],
[120,6000,388000,978000],
[121,6050,398000,990000],
[122,6100,408000,1001000],
[123,6150,418000,1013000],
[124,6200,428000,1024000],
[125,6250,438000,1035000],
[126,6300,448000,1045000],
[127,6350,460000,1056000],
[128,6400,472000,1066000],
[129,6450,484000,1077000],
[130,6500,496000,1087000],
[131,6550,508000,1097000],
[132,6600,520000,1106000],
[133,6650,534000,1116000],
[134,6700,546000,1126000],
[135,6750,560000,1135000],
[136,6800,572000,1144000],
[137,6850,586000,1153000],
[138,6900,598000,1162000],
[139,6950,612000,1171000],
[140,7000,624000,1180000],
[141,7050,636000,1188000],
[142,7100,650000,1197000],
[143,7150,662000,1205000],
[144,7200,674000,1213000],
[145,7250,686000,1221000],
[146,7300,698000,1229000],
[147,7350,710000,1237000],
[148,7400,722000,1245000],
[149,7450,734000,1252000],
[150,7500,746000,1260000],
[151,7550,756000,1267000],
[152,7600,768000,1275000],
[153,7650,780000,1282000],
[154,7700,790000,1289000],
[155,7750,802000,1296000],
[156,7800,812000,1305000],
[157,7850,824000,1315000],
[158,7900,834000,1325000],
[159,7950,846000,1335000],
[160,8000,858000,1355000],
[161,8050,868000,1375000],
[162,8100,880000,1405000],
[163,8150,892000,1447000],
[164,8200,904000,1541000],
[165,8250,916000,1635000],
[166,8300,928000,1729000],
[167,8350,940000,1823000],
[168,8400,952000,1917000],
[169,8450,964000,2011000],
[170,8500,976000,2106000],
[171,8550,988000,2200000],
[172,8600,1000000,2295000],
[173,8650,1014000,2389000],
[174,8700,1028000,2484000],
[175,8750,1042000,2578000],
[176,8800,1056000,2673000],
[177,8850,1070000,2768000],
[178,8900,1084000,2863000],
[179,8950,1098000,2958000],
[180,9000,1112000,3053000],
[181,9050,1128000,3148000],
[182,9100,1142000,3243000],
[183,9150,1156000,3339000],
[184,9200,1170000,3434000],
[185,9250,1186000,3530000],
[186,9300,1200000,3625000],
[187,9350,1214000,3721000],
[188,9400,1228000,3816000],
[189,9450,1242000,3912000],
[190,9500,1258000,4008000],
[191,9550,1272000,4104000],
[192,9600,1286000,4200000],
[193,9650,1300000,4296000],
[194,9700,1316000,4392000],
[195,9750,1330000,4488000],
[196,9800,1344000,4584000],
[197,9850,1360000,4681000],
[198,9900,1374000,4777000],
[199,9950,1388000,4874000],
[200,10000,1402000,4970000],
[201,10050,1418000,5067000],
[202,10100,1432000,5164000],
[203,10150,1446000,5261000],
[204,10200,1462000,5357000],
[205,10250,1476000,5454000],
[206,10300,1490000,5551000],
[207,10350,1506000,5649000],
[208,10400,1520000,5746000],
[209,10450,1534000,5843000],
[210,10500,1550000,5940000],
[211,10550,1564000,6038000],
[212,10600,1578000,6135000],
[213,10650,1594000,6233000],
[214,10700,1608000,6331000],
[215,10750,1622000,6428000],
[216,10800,1638000,6526000],
[217,10850,1652000,6624000],
[218,10900,1666000,6722000],
[219,10950,1682000,6820000],
[220,11000,1696000,6918000],
[221,11050,1712000,7017000],
[222,11100,1726000,7115000],
[223,11150,1740000,7213000],
[224,11200,1756000,7312000],
[225,11250,1770000,7410000],
[226,11300,1786000,7509000],
[227,11350,1800000,7608000],
[228,11400,1814000,7706000],
[229,11450,1830000,7805000],
[230,11500,1844000,7904000],
[231,11550,1860000,8003000],
[232,11600,1874000,8102000],
[233,11650,1890000,8202000],
[234,11700,1904000,8301000],
[235,11750,1918000,8400000],
[236,11800,1934000,8500000],
[237,11850,1948000,8599000],
[238,11900,1964000,8699000],
[239,11950,1978000,8798000],
[240,12000,1994000,8898000],
[241,12050,2008000,8998000],
[242,12100,2024000,9098000],
[243,12150,2038000,9198000],
[244,12200,2054000,9298000],
[245,12250,2068000,9398000],
[246,12300,2084000,9499000],
[247,12350,2098000,9599000],
[248,12400,2114000,9699000],
[249,12450,2128000,9800000],
[250,12500,2144000,9900000],
[251,12550,2158000,10001000],
[252,12600,2174000,10102000],
[253,12650,2188000,10203000],
[254,12700,2204000,10304000],
[255,12750,2218000,10405000],
[256,12800,2234000,10506000],
[257,12850,2248000,10607000],
[258,12900,2264000,10708000],
[259,12950,2280000,10810000],
[260,13000,2294000,10911000],
[261,13050,2310000,11013000],
[262,13100,2324000,11114000],
[263,13150,2340000,11216000],
[264,13200,2354000,11318000],
[265,13250,2370000,11420000],
[266,13300,2386000,11522000],
[267,13350,2400000,11624000],
[268,13400,2416000,11726000],
[269,13450,2430000,11828000],
[270,13500,2446000,11930000],
[271,13550,2462000,12033000],
[272,13600,2476000,12135000],
[273,13650,2492000,12238000],
[274,13700,2508000,12340000],
[275,13750,2522000,12443000],
[276,13800,2538000,12546000],
[277,13850,2552000,12649000],
[278,13900,2568000,12752000],
[279,13950,2584000,12855000],
[280,14000,2598000,12958000],
[281,14050,2614000,13062000],
[282,14100,2630000,13165000],
[283,14150,2644000,13268000],
[284,14200,2660000,13372000],
[285,14250,2676000,13476000],
[286,14300,2692000,13579000],
[287,14350,2706000,13683000],
[288,14400,2722000,13787000],
[289,14450,2738000,13891000],
[290,14500,2752000,13995000],
[291,14550,2768000,14099000],
[292,14600,2784000,14204000],
[293,14650,2800000,14308000],
[294,14700,2814000,14412000],
[295,14750,2830000,14517000],
[296,14800,2846000,14621000],
[297,14850,2860000,14726000],
[298,14900,2876000,14831000],
[299,14950,2892000,14936000],
[300,15000,2908000,15041000],
[301,15050,2924000,15146000],
[302,15100,2938000,15251000],
[303,15150,2954000,15357000],
[304,15200,2970000,15462000],
[305,15250,2986000,15567000],
[306,15300,3000000,15673000],
[307,15350,3016000,15779000],
[308,15400,3032000,15884000],
[309,15450,3048000,15990000],
[310,15500,3064000,16096000],
[311,15550,3078000,16202000],
[312,15600,3094000,16308000],
[313,15650,3110000,16414000],
[314,15700,3126000,16521000],
[315,15750,3142000,16627000],
[316,15800,3158000,16734000],
[317,15850,3172000,16840000],
[318,15900,3188000,16947000],
[319,15950,3204000,17054000],
[320,16000,3220000,17161000],
[321,16050,3236000,17268000],
[322,16100,3252000,17375000],
[323,16150,3268000,17482000],
[324,16200,3284000,17589000],
[325,16250,3298000,17696000],
[326,16300,3314000,17804000],
[327,16350,3330000,17912000],
[328,16400,3346000,18019000],
[329,16450,3362000,18127000],
[330,16500,3378000,18235000],
[331,16550,3394000,18343000],
[332,16600,3410000,18451000],
[333,16650,3426000,18559000],
[334,16700,3442000,18667000],
[335,16750,3458000,18775000],
[336,16800,3474000,18884000],
[337,16850,3490000,18992000],
[338,16900,3504000,19101000],
[339,16950,3520000,19210000],
[340,17000,3536000,19319000],
[341,17050,3552000,19428000],
[342,17100,3568000,19537000],
[343,17150,3584000,19646000],
[344,17200,3600000,19755000],
[345,17250,3616000,19864000],
[346,17300,3632000,19974000],
[347,17350,3648000,20083000],
[348,17400,3664000,20193000],
[349,17450,3680000,20303000],
[350,17500,3696000,20413000],
[351,17550,3712000,20523000],
[352,17600,3728000,20633000],
[353,17650,3744000,20743000],
[354,17700,3762000,20853000],
[355,17750,3778000,20963000],
[356,17800,3794000,21074000],
[357,17850,3810000,21184000],
[358,17900,3826000,21295000],
[359,17950,3842000,21406000],
[360,18000,3858000,21517000],
[361,18050,3874000,21628000],
[362,18100,3890000,21739000],
[363,18150,3906000,21850000],
[364,18200,3922000,21961000],
[365,18250,3938000,22073000],
[366,18300,3954000,22184000],
[367,18350,3972000,22296000],
[368,18400,3988000,22408000],
[369,18450,4004000,22519000],
[370,18500,4020000,22631000],
[371,18550,4036000,22743000],
[372,18600,4052000,22855000],
[373,18650,4068000,22968000],
[374,18700,4086000,23080000],
[375,18750,4102000,23193000],
[376,18800,4118000,23305000],
[377,18850,4134000,23418000],
[378,18900,4150000,23531000],
[379,18950,4166000,23643000],
[380,19000,4184000,23756000],
[381,19050,4200000,23870000],
[382,19100,4216000,23983000],
[383,19150,4232000,24096000],
[384,19200,4248000,24210000],
[385,19250,4266000,24323000],
[386,19300,4282000,24437000],
[387,19350,4298000,24551000],
[388,19400,4314000,24664000],
[389,19450,4332000,24778000],
[390,19500,4348000,24892000],
[391,19550,4364000,25007000],
[392,19600,4380000,25121000],
[393,19650,4398000,25235000],
[394,19700,4414000,25350000],
[395,19750,4430000,25465000],
[396,19800,4446000,25579000],
[397,19850,4464000,25694000],
[398,19900,4480000,25809000],
[399,19950,4496000,25924000],
[400,20000,4514000,26040000],
[401,20050,4530000,26155000],
[402,20100,4546000,26270000],
[403,20150,4564000,26386000],
[404,20200,4580000,26502000],
[405,20250,4596000,26617000],
[406,20300,4614000,26733000],
[407,20350,4630000,26849000],
[408,20400,4646000,26965000],
[409,20450,4664000,27082000],
[410,20500,4680000,27198000],
[411,20550,4696000,27314000],
[412,20600,4714000,27431000],
[413,20650,4730000,27548000],
[414,20700,4746000,27665000],
[415,20750,4764000,27781000],
[416,20800,4780000,27898000],
[417,20850,4798000,28016000],
[418,20900,4814000,28133000],
[419,20950,4832000,28250000],
[420,21000,4848000,28368000],
[421,21050,4864000,28486000],
[422,21100,4882000,28603000],
[423,21150,4898000,28721000],
[424,21200,4916000,28839000],
[425,21250,4932000,28957000],
[426,21300,4950000,29075000],
[427,21350,4966000,29194000],
[428,21400,4984000,29312000],
[429,21450,5000000,29431000],
[430,21500,5016000,29550000],
[431,21550,5034000,29668000],
[432,21600,5050000,29787000],
[433,21650,5068000,29906000],
[434,21700,5084000,30026000],
[435,21750,5102000,30145000],
[436,21800,5120000,30264000],
[437,21850,5136000,30384000],
[438,21900,5154000,30504000],
[439,21950,5170000,30624000],
[440,22000,5188000,30743000],
[441,22050,5204000,30864000],
[442,22100,5222000,30984000],
[443,22150,5238000,31104000],
[444,22200,5256000,31224000],
[445,22250,5274000,31345000],
[446,22300,5290000,31466000],
[447,22350,5308000,31586000],
[448,22400,5324000,31707000],
[449,22450,5342000,31828000],
[450,22500,5358000,31950000],
[451,22550,5376000,32071000],
[452,22600,5394000,32192000],
[453,22650,5410000,32314000],
[454,22700,5428000,32436000],
[455,22750,5446000,32557000],
[456,22800,5462000,32679000],
[457,22850,5480000,32801000],
[458,22900,5498000,32924000],
[459,22950,5514000,33046000],
[460,23000,5532000,33168000],
[461,23050,5550000,33291000],
[462,23100,5566000,33414000],
[463,23150,5584000,33537000],
[464,23200,5602000,33660000],
[465,23250,5618000,33783000],
[466,23300,5636000,33906000],
[467,23350,5654000,34029000],
[468,23400,5672000,34153000],
[469,23450,5688000,34276000],
[470,23500,5706000,34400000],
[471,23550,5724000,34524000],
[472,23600,5742000,34648000],
[473,23650,5758000,34772000],
[474,23700,5776000,34896000],
[475,23750,5794000,35021000],
[476,23800,5812000,35145000],
[477,23850,5828000,35270000],
[478,23900,5846000,35395000],
[479,23950,5864000,35520000],
[480,24000,5882000,35645000],
[481,24050,5900000,35770000],
[482,24100,5916000,35896000],
[483,24150,5934000,36021000],
[484,24200,5952000,36147000],
[485,24250,5970000,36273000],
[486,24300,5988000,36398000],
[487,24350,6006000,36524000],
[488,24400,6022000,36651000],
[489,24450,6040000,36777000],
[490,24500,6058000,36903000],
[491,24550,6076000,37030000],
[492,24600,6094000,37157000],
[493,24650,6112000,37284000],
[494,24700,6130000,37411000],
[495,24750,6148000,37538000],
[496,24800,6166000,37665000],
[497,24850,6182000,37793000],
[498,24900,6200000,37920000],
[499,24950,6218000,38048000],
[500,25000,6236000,38176000],
[501,25050,6254000,38304000],
[502,25100,6272000,38432000],
[503,25150,6290000,38560000],
[504,25200,6308000,38689000],
[505,25250,6326000,38817000],
[506,25300,6344000,38946000],
[507,25350,6362000,39075000],
[508,25400,6380000,39204000],
[509,25450,6398000,39333000],
[510,25500,6416000,39462000],
[511,25550,6434000,39592000],
[512,25600,6452000,39721000],
[513,25650,6470000,39851000],
[514,25700,6488000,39981000],
[515,25750,6506000,40111000],
[516,25800,6524000,40241000],
[517,25850,6542000,40371000],
[518,25900,6560000,40502000],
[519,25950,6580000,40632000],
[520,26000,6598000,40763000],
[521,26050,6616000,40894000],
[522,26100,6634000,41025000],
[523,26150,6652000,41156000],
[524,26200,6670000,41288000],
[525,26250,6688000,41419000],
[526,26300,6706000,41551000],
[527,26350,6724000,41683000],
[528,26400,6742000,41815000],
[529,26450,6762000,41947000],
[530,26500,6780000,42079000],
[531,26550,6798000,42211000],
[532,26600,6816000,42344000],
[533,26650,6834000,42477000],
[534,26700,6852000,42610000],
[535,26750,6872000,42743000],
[536,26800,6890000,42876000],
[537,26850,6908000,43009000],
[538,26900,6926000,43143000],
[539,26950,6946000,43276000],
[540,27000,6964000,43410000],
[541,27050,6982000,43544000],
[542,27100,7000000,43678000],
[543,27150,7018000,43812000],
[544,27200,7038000,43947000],
[545,27250,7056000,44081000],
[546,27300,7074000,44216000],
[547,27350,7094000,44351000],
[548,27400,7112000,44486000],
[549,27450,7130000,44621000],
[550,27500,7148000,44757000],
[551,27550,7168000,44892000],
[552,27600,7186000,45028000],
[553,27650,7204000,45164000],
[554,27700,7224000,45300000],
[555,27750,7242000,45436000],
[556,27800,7260000,45572000],
[557,27850,7280000,45709000],
[558,27900,7298000,45846000],
[559,27950,7316000,45982000],
[560,28000,7336000,46119000],
[561,28050,7354000,46257000],
[562,28100,7374000,46394000],
[563,28150,7392000,46531000],
[564,28200,7410000,46669000],
[565,28250,7430000,46807000],
[566,28300,7448000,46945000],
[567,28350,7468000,47083000],
[568,28400,7486000,47221000],
[569,28450,7506000,47360000],
[570,28500,7524000,47499000],
[571,28550,7544000,47637000],
[572,28600,7562000,47776000],
[573,28650,7580000,47916000],
[574,28700,7600000,48055000],
[575,28750,7618000,48194000],
[576,28800,7638000,48334000],
[577,28850,7656000,48474000],
[578,28900,7676000,48614000],
[579,28950,7696000,48754000],
[580,29000,7714000,48895000],
[581,29050,7734000,49035000],
[582,29100,7752000,49176000],
[583,29150,7772000,49317000],
[584,29200,7790000,49458000],
[585,29250,7810000,49599000],
[586,29300,7828000,49740000],
[587,29350,7848000,49882000],
[588,29400,7868000,50024000],
[589,29450,7886000,50166000],
[590,29500,7906000,50308000],
[591,29550,7924000,50450000],
[592,29600,7944000,50593000],
[593,29650,7964000,50735000],
[594,29700,7982000,50878000],
[595,29750,8002000,51021000],
[596,29800,8022000,51164000],
[597,29850,8040000,51308000],
[598,29900,8060000,51451000],
[599,29950,8080000,51595000],
[600,30000,8098000,51739000],
[601,30050,8118000,51883000],
[602,30100,8138000,52027000],
[603,30150,8158000,52172000],
[604,30200,8176000,52316000],
[605,30250,8196000,52461000],
[606,30300,8216000,52606000],
[607,30350,8236000,52751000],
[608,30400,8254000,52897000],
[609,30450,8274000,53042000],
[610,30500,8294000,53188000],
[611,30550,8314000,53334000],
[612,30600,8332000,53480000],
[613,30650,8352000,53627000],
[614,30700,8372000,53773000],
[615,30750,8392000,53920000],
[616,30800,8412000,54067000],
[617,30850,8432000,54214000],
[618,30900,8450000,54361000],
[619,30950,8470000,54509000],
[620,31000,8490000,54656000],
[621,31050,8510000,54804000],
[622,31100,8530000,54952000],
[623,31150,8550000,55100000],
[624,31200,8570000,55249000],
[625,31250,8590000,55397000],
[626,31300,8608000,55546000],
[627,31350,8628000,55695000],
[628,31400,8648000,55844000],
[629,31450,8668000,55994000],
[630,31500,8688000,56143000],
[631,31550,8708000,56293000],
[632,31600,8728000,56443000],
[633,31650,8748000,56593000],
[634,31700,8768000,56744000],
[635,31750,8788000,56895000],
[636,31800,8808000,57045000],
[637,31850,8828000,57196000],
[638,31900,8848000,57348000],
[639,31950,8868000,57499000],
[640,32000,8888000,57651000],
[641,32050,8908000,57802000],
[642,32100,8928000,57954000],
[643,32150,8948000,58107000],
[644,32200,8970000,58259000],
[645,32250,8990000,58412000],
[646,32300,9010000,58565000],
[647,32350,9030000,58718000],
[648,32400,9050000,58871000],
[649,32450,9070000,59024000],
[650,32500,9090000,59178000],
[651,32550,9110000,59332000],
[652,32600,9130000,59486000],
[653,32650,9152000,59640000],
[654,32700,9172000,59795000],
[655,32750,9192000,59950000],
[656,32800,9212000,60105000],
[657,32850,9232000,60260000],
[658,32900,9254000,60415000],
[659,32950,9274000,60571000],
[660,33000,9294000,60726000],
[661,33050,9314000,60882000],
[662,33100,9334000,61039000],
[663,33150,9356000,61195000],
[664,33200,9376000,61352000],
[665,33250,9396000,61509000],
[666,33300,9418000,61666000],
[667,33350,9438000,61823000],
[668,33400,9458000,61981000],
[669,33450,9478000,62138000],
[670,33500,9500000,62296000],
[671,33550,9520000,62455000],
[672,33600,9540000,62613000],
[673,33650,9562000,62772000],
[674,33700,9582000,62931000],
[675,33750,9602000,63090000],
[676,33800,9624000,63249000],
[677,33850,9644000,63409000],
[678,33900,9666000,63568000],
[679,33950,9686000,63728000],
[680,34000,9706000,63889000],
[681,34050,9728000,64049000],
[682,34100,9748000,64210000],
[683,34150,9770000,64371000],
[684,34200,9790000,64532000],
[685,34250,9812000,64693000],
[686,34300,9832000,64855000],
[687,34350,9854000,65017000],
[688,34400,9874000,65179000],
[689,34450,9896000,65341000],
[690,34500,9916000,65504000],
[691,34550,9938000,65666000],
[692,34600,9958000,65829000],
[693,34650,9980000,65993000],
[694,34700,10000000,66156000],
[695,34750,10022000,66320000],
[696,34800,10042000,66484000],
[697,34850,10064000,66648000],
[698,34900,10086000,66813000],
[699,34950,10106000,66977000],
[700,35000,10128000,67142000],
[701,35050,10148000,67307000],
[702,35100,10170000,67473000],
[703,35150,10192000,67639000],
[704,35200,10212000,67804000],
[705,35250,10234000,67971000],
[706,35300,10256000,68137000],
[707,35350,10276000,68304000],
[708,35400,10298000,68471000],
[709,35450,10320000,68638000],
[710,35500,10340000,68805000],
[711,35550,10362000,68973000],
[712,35600,10384000,69141000],
[713,35650,10406000,69309000],
[714,35700,10426000,69477000],
[715,35750,10448000,69646000],
[716,35800,10470000,69815000],
[717,35850,10492000,69984000],
[718,35900,10512000,70153000],
[719,35950,10534000,70323000],
[720,36000,10556000,70493000],
[721,36050,10578000,70663000],
[722,36100,10600000,70834000],
[723,36150,10622000,71004000],
[724,36200,10642000,71175000],
[725,36250,10664000,71347000],
[726,36300,10686000,71518000],
[727,36350,10708000,71690000],
[728,36400,10730000,71862000],
[729,36450,10752000,72034000],
[730,36500,10774000,72207000],
[731,36550,10796000,72380000],
[732,36600,10818000,72553000],
[733,36650,10840000,72726000],
[734,36700,10862000,72900000],
[735,36750,10884000,73074000],
[736,36800,10906000,73248000],
[737,36850,10928000,73422000],
[738,36900,10950000,73597000],
[739,36950,10972000,73772000],
[740,37000,10994000,73947000],
[741,37050,11016000,74123000],
[742,37100,11038000,74298000],
[743,37150,11060000,74475000],
[744,37200,11082000,74651000],
[745,37250,11104000,74828000],
[746,37300,11126000,75005000],
[747,37350,11148000,75182000],
[748,37400,11170000,75359000],
[749,37450,11192000,75537000],
[750,37500,11216000,75715000],
[751,37550,11238000,75893000],
[752,37600,11260000,76072000],
[753,37650,11282000,76251000],
[754,37700,11304000,76430000],
[755,37750,11326000,76610000],
[756,37800,11350000,76789000],
[757,37850,11372000,76969000],
[758,37900,11394000,77150000],
[759,37950,11416000,77330000],
[760,38000,11440000,77511000],
[761,38050,11462000,77693000],
[762,38100,11484000,77874000],
[763,38150,11506000,78056000],
[764,38200,11530000,78238000],
[765,38250,11552000,78420000],
[766,38300,11574000,78603000],
[767,38350,11598000,78786000],
[768,38400,11620000,78970000],
[769,38450,11642000,79153000],
[770,38500,11666000,79337000],
[771,38550,11688000,79521000],
[772,38600,11712000,79706000],
[773,38650,11734000,79891000],
[774,38700,11756000,80076000],
[775,38750,11780000,80261000],
[776,38800,11802000,80447000],
[777,38850,11826000,80633000],
[778,38900,11848000,80819000],
[779,38950,11872000,81006000],
[780,39000,11894000,81193000],
[781,39050,11918000,81380000],
[782,39100,11940000,81568000],
[783,39150,11964000,81756000],
[784,39200,11986000,81944000],
[785,39250,12010000,82133000],
[786,39300,12032000,82322000],
[787,39350,12056000,82511000],
[788,39400,12080000,82701000],
[789,39450,12102000,82890000],
[790,39500,12126000,83081000],
[791,39550,12148000,83271000],
[792,39600,12172000,83462000],
[793,39650,12196000,83653000],
[794,39700,12218000,83845000],
[795,39750,12242000,84036000],
[796,39800,12266000,84229000],
[797,39850,12288000,84421000],
[798,39900,12312000,84614000],
[799,39950,12336000,84807000],
[800,40000,12360000,85000000],
[801,40050,12382000,85194000],
[802,40100,12406000,85388000],
[803,40150,12430000,85583000],
[804,40200,12454000,85778000],
[805,40250,12476000,85973000],
[806,40300,12500000,86168000],
[807,40350,12524000,86364000],
[808,40400,12548000,86561000],
[809,40450,12572000,86757000],
[810,40500,12596000,86954000],
[811,40550,12620000,87151000],
[812,40600,12642000,87349000],
[813,40650,12666000,87547000],
[814,40700,12690000,87745000],
[815,40750,12714000,87944000],
[816,40800,12738000,88143000],
[817,40850,12762000,88342000],
[818,40900,12786000,88542000],
[819,40950,12810000,88742000],
[820,41000,12834000,88942000],
[821,41050,12858000,89143000],
[822,41100,12882000,89344000],
[823,41150,12906000,89546000],
[824,41200,12930000,89748000],
[825,41250,12954000,89950000],
[826,41300,12978000,90153000],
[827,41350,13002000,90356000],
[828,41400,13026000,90559000],
[829,41450,13052000,90763000],
[830,41500,13076000,90967000],
[831,41550,13100000,91171000],
[832,41600,13124000,91376000],
[833,41650,13148000,91581000],
[834,41700,13172000,91787000],
[835,41750,13198000,91993000],
[836,41800,13222000,92199000],
[837,41850,13246000,92406000],
[838,41900,13270000,92613000],
[839,41950,13294000,92821000],
[840,42000,13320000,93029000],
[841,42050,13344000,93237000],
[842,42100,13368000,93446000],
[843,42150,13394000,93655000],
[844,42200,13418000,93864000],
[845,42250,13442000,94074000],
[846,42300,13468000,94284000],
[847,42350,13492000,94495000],
[848,42400,13516000,94706000],
[849,42450,13542000,94917000],
[850,42500,13566000,95129000],
[851,42550,13592000,95341000],
[852,42600,13616000,95554000],
[853,42650,13640000,95767000],
[854,42700,13666000,95981000],
[855,42750,13690000,96195000],
[856,42800,13716000,96409000],
[857,42850,13740000,96623000],
[858,42900,13766000,96839000],
[859,42950,13790000,97054000],
[860,43000,13816000,97270000],
[861,43050,13842000,97486000],
[862,43100,13866000,97703000],
[863,43150,13892000,97920000],
[864,43200,13916000,98138000],
[865,43250,13942000,98356000],
[866,43300,13968000,98574000],
[867,43350,13992000,98793000],
[868,43400,14018000,99013000],
[869,43450,14044000,99232000],
[870,43500,14068000,99453000],
[871,43550,14094000,99673000],
[872,43600,14120000,99894000],
[873,43650,14146000,100116000],
[874,43700,14170000,100338000],
[875,43750,14196000,100560000],
[876,43800,14222000,100783000],
[877,43850,14248000,101006000],
[878,43900,14272000,101230000],
[879,43950,14298000,101454000],
[880,44000,14324000,101678000],
[881,44050,14350000,101904000],
[882,44100,14376000,102129000],
[883,44150,14402000,102355000],
[884,44200,14428000,102581000],
[885,44250,14454000,102808000],
[886,44300,14480000,103036000],
[887,44350,14504000,103263000],
[888,44400,14530000,103492000],
[889,44450,14556000,103720000],
[890,44500,14582000,103949000],
[891,44550,14608000,104179000],
[892,44600,14634000,104409000],
[893,44650,14662000,104640000],
[894,44700,14688000,104871000],
[895,44750,14714000,105102000],
[896,44800,14740000,105334000],
[897,44850,14766000,105567000],
[898,44900,14792000,105800000],
[899,44950,14818000,106033000],
[900,45000,14844000,106267000],
[901,45050,14872000,106501000],
[902,45100,14898000,106736000],
[903,45150,14924000,106972000],
[904,45200,14950000,107208000],
[905,45250,14976000,107444000],
[906,45300,15004000,107681000],
[907,45350,15030000,107918000],
[908,45400,15056000,108156000],
[909,45450,15084000,108394000],
[910,45500,15110000,108633000],
[911,45550,15136000,108873000],
[912,45600,15164000,109113000],
[913,45650,15190000,109353000],
[914,45700,15216000,109594000],
[915,45750,15244000,109835000],
[916,45800,15270000,110077000],
[917,45850,15298000,110320000],
[918,45900,15324000,110563000],
[919,45950,15352000,110806000],
[920,46000,15378000,111050000],
[921,46050,15406000,111295000],
[922,46100,15432000,111540000],
[923,46150,15460000,111785000],
[924,46200,15486000,112032000],
[925,46250,15514000,112278000],
[926,46300,15540000,112525000],
[927,46350,15568000,112773000],
[928,46400,15596000,113022000],
[929,46450,15622000,113270000],
[930,46500,15650000,113520000],
[931,46550,15678000,113770000],
[932,46600,15704000,114020000],
[933,46650,15732000,114271000],
[934,46700,15760000,114523000],
[935,46750,15788000,114775000],
[936,46800,15814000,115028000],
[937,46850,15842000,115281000],
[938,46900,15870000,115535000],
[939,46950,15898000,115789000],
[940,47000,15926000,116044000],
[941,47050,15952000,116299000],
[942,47100,15980000,116556000],
[943,47150,16008000,116812000],
[944,47200,16036000,117069000],
[945,47250,16064000,117327000],
[946,47300,16092000,117586000],
[947,47350,16120000,117845000],
[948,47400,16148000,118104000],
[949,47450,16176000,118364000],
[950,47500,16204000,118625000],
[951,47550,16232000,118887000],
[952,47600,16260000,119148000],
[953,47650,16288000,119411000],
[954,47700,16316000,119674000],
[955,47750,16344000,119938000],
[956,47800,16374000,120202000],
[957,47850,16402000,120467000],
[958,47900,16430000,120733000],
[959,47950,16458000,120999000],
[960,48000,16486000,121266000],
[961,48050,16514000,121533000],
[962,48100,16544000,121801000],
[963,48150,16572000,122070000],
[964,48200,16600000,122339000],
[965,48250,16630000,122609000],
[966,48300,16658000,122880000],
[967,48350,16686000,123151000],
[968,48400,16716000,123422000],
[969,48450,16744000,123695000],
[970,48500,16772000,123968000],
[971,48550,16802000,124242000],
[972,48600,16830000,124516000],
[973,48650,16860000,124791000],
[974,48700,16888000,125067000],
[975,48750,16918000,125343000],
[976,48800,16946000,125620000],
[977,48850,16976000,125898000],
[978,48900,17004000,126176000],
[979,48950,17034000,126455000],
[980,49000,17064000,126735000],
[981,49050,17092000,127015000],
[982,49100,17122000,127296000],
[983,49150,17152000,127578000],
[984,49200,17180000,127860000],
[985,49250,17210000,128143000],
[986,49300,17240000,128427000],
[987,49350,17268000,128711000],
[988,49400,17298000,128996000],
[989,49450,17328000,129282000],
[990,49500,17358000,129568000],
[991,49550,17388000,129855000],
[992,49600,17416000,130143000],
[993,49650,17446000,130432000],
[994,49700,17476000,130721000],
[995,49750,17506000,131011000],
[996,49800,17536000,131302000],
[997,49850,17566000,131593000],
[998,49900,17596000,131885000],
[999,49950,17626000,132178000],
[1000,50000,17656000,132472000],
[1001,50050,17686000,132766000],
[1002,50100,17716000,133061000],
[1003,50150,17746000,133357000],
[1004,50200,17776000,133653000],
[1005,50250,17806000,133950000],
[1006,50300,17838000,134248000],
[1007,50350,17868000,134547000],
[1008,50400,17898000,134846000],
[1009,50450,17928000,135147000],
[1010,50500,17958000,135447000],
[1011,50550,17990000,135749000],
[1012,50600,18020000,136052000],
[1013,50650,18050000,136355000],
[1014,50700,18082000,136659000],
[1015,50750,18112000,136964000],
[1016,50800,18142000,137269000],
[1017,50850,18174000,137575000],
[1018,50900,18204000,137883000],
[1019,50950,18234000,138190000],
[1020,51000,18266000,138499000],
[1021,51050,18296000,138808000],
[1022,51100,18328000,139119000],
[1023,51150,18358000,139430000],
[1024,51200,18390000,139741000],
[1025,51250,18422000,140054000],
[1026,51300,18452000,140367000],
[1027,51350,18484000,140682000],
[1028,51400,18514000,140997000],
[1029,51450,18546000,141312000],
[1030,51500,18578000,141629000],
[1031,51550,18610000,141947000],
[1032,51600,18640000,142265000],
[1033,51650,18672000,142584000],
[1034,51700,18704000,142904000],
[1035,51750,18736000,143225000],
[1036,51800,18766000,143546000],
[1037,51850,18798000,143869000],
[1038,51900,18830000,144192000],
[1039,51950,18862000,144516000],
[1040,52000,18894000,144841000],
[1041,52050,18926000,145167000],
[1042,52100,18958000,145493000],
[1043,52150,18990000,145821000],
[1044,52200,19022000,146149000],
[1045,52250,19054000,146479000],
[1046,52300,19086000,146809000],
[1047,52350,19118000,147140000],
[1048,52400,19150000,147471000],
[1049,52450,19184000,147804000],
[1050,52500,19216000,148138000],
[1051,52550,19248000,148472000],
[1052,52600,19280000,148807000],
[1053,52650,19312000,149144000],
[1054,52700,19346000,149481000],
[1055,52750,19378000,149819000],
[1056,52800,19410000,150158000],
[1057,52850,19444000,150498000],
[1058,52900,19476000,150838000],
[1059,52950,19508000,151180000],
[1060,53000,19542000,151522000],
[1061,53050,19574000,151866000],
[1062,53100,19608000,152210000],
[1063,53150,19640000,152556000],
[1064,53200,19674000,152902000],
[1065,53250,19706000,153249000],
[1066,53300,19740000,153597000],
[1067,53350,19774000,153946000],
[1068,53400,19806000,154296000],
[1069,53450,19840000,154647000],
[1070,53500,19874000,154999000],
[1071,53550,19906000,155351000],
[1072,53600,19940000,155705000],
[1073,53650,19974000,156060000],
[1074,53700,20008000,156415000],
[1075,53750,20040000,156772000],
[1076,53800,20074000,157129000],
[1077,53850,20108000,157488000],
[1078,53900,20142000,157847000],
[1079,53950,20176000,158208000],
[1080,54000,20210000,158569000],
[1081,54050,20244000,158931000],
[1082,54100,20278000,159295000],
[1083,54150,20312000,159659000],
[1084,54200,20346000,160024000],
[1085,54250,20380000,160391000],
[1086,54300,20414000,160758000],
[1087,54350,20448000,161126000],
[1088,54400,20484000,161496000],
[1089,54450,20518000,161866000],
[1090,54500,20552000,162237000],
[1091,54550,20586000,162609000],
[1092,54600,20622000,162983000],
[1093,54650,20656000,163357000],
[1094,54700,20690000,163732000],
[1095,54750,20726000,164109000],
[1096,54800,20760000,164486000],
[1097,54850,20796000,164864000],
[1098,54900,20830000,165244000],
[1099,54950,20866000,165624000],
[1100,55000,20900000,166005000],
[1101,55050,20936000,166388000],
[1102,55100,20970000,166771000],
[1103,55150,21006000,167156000],
[1104,55200,21042000,167542000],
[1105,55250,21076000,167928000],
[1106,55300,21112000,168316000],
[1107,55350,21148000,168705000],
[1108,55400,21184000,169094000],
[1109,55450,21218000,169485000],
[1110,55500,21254000,169877000],
[1111,55550,21290000,170270000],
[1112,55600,21326000,170664000],
[1113,55650,21362000,171059000],
[1114,55700,21398000,171455000],
[1115,55750,21434000,171852000],
[1116,55800,21470000,172250000],
[1117,55850,21506000,172650000],
[1118,55900,21542000,173050000],
[1119,55950,21578000,173452000],
[1120,56000,21614000,173854000],
[1121,56050,21650000,174258000],
[1122,56100,21688000,174662000],
[1123,56150,21724000,175068000],
[1124,56200,21760000,175475000],
[1125,56250,21796000,175883000],
[1126,56300,21834000,176292000],
[1127,56350,21870000,176702000],
[1128,56400,21908000,177113000],
[1129,56450,21944000,177525000],
[1130,56500,21980000,177939000],
[1131,56550,22018000,178353000],
[1132,56600,22056000,178769000],
[1133,56650,22092000,179185000],
[1134,56700,22130000,179603000],
[1135,56750,22166000,180022000],
[1136,56800,22204000,180442000],
[1137,56850,22242000,180863000],
[1138,56900,22278000,181285000],
[1139,56950,22316000,181708000],
[1140,57000,22354000,182133000],
[1141,57050,22392000,182558000],
[1142,57100,22430000,182985000],
[1143,57150,22468000,183412000],
[1144,57200,22506000,183841000],
[1145,57250,22544000,184271000],
[1146,57300,22582000,184702000],
[1147,57350,22620000,185134000],
[1148,57400,22658000,185567000],
[1149,57450,22696000,186002000],
[1150,57500,22734000,186437000],
[1151,57550,22772000,186873000],
[1152,57600,22810000,187311000],
[1153,57650,22850000,187750000],
[1154,57700,22888000,188190000],
[1155,57750,22926000,188630000],
[1156,57800,22966000,189072000],
[1157,57850,23004000,189516000],
[1158,57900,23044000,189960000],
[1159,57950,23082000,190405000],
[1160,58000,23122000,190852000],
[1161,58050,23160000,191299000],
[1162,58100,23200000,191748000],
[1163,58150,23238000,192198000],
[1164,58200,23278000,192648000],
[1165,58250,23318000,193100000],
[1166,58300,23356000,193553000],
[1167,58350,23396000,194007000],
[1168,58400,23436000,194463000],
[1169,58450,23476000,194919000],
[1170,58500,23516000,195376000],
[1171,58550,23556000,195835000],
[1172,58600,23596000,196294000],
[1173,58650,23636000,196755000],
[1174,58700,23676000,197217000],
[1175,58750,23716000,197680000],
[1176,58800,23756000,198144000],
[1177,58850,23796000,198609000],
[1178,58900,23836000,199075000],
[1179,58950,23876000,199542000],
[1180,59000,23918000,200010000],
[1181,59050,23958000,200479000],
[1182,59100,23998000,200949000],
[1183,59150,24040000,201421000],
[1184,59200,24080000,201893000],
[1185,59250,24122000,202367000],
[1186,59300,24162000,202841000],
[1187,59350,24204000,203317000],
[1188,59400,24244000,203793000],
[1189,59450,24286000,204271000],
[1190,59500,24328000,204750000],
[1191,59550,24368000,205229000],
[1192,59600,24410000,205710000],
[1193,59650,24452000,206192000],
[1194,59700,24494000,206675000],
[1195,59750,24536000,207158000],
[1196,59800,24578000,207643000],
[1197,59850,24620000,208129000],
[1198,59900,24662000,208616000],
[1199,59950,24704000,209104000],
[1200,60000,24746000,209592000],
[1201,60050,24788000,210082000],
[1202,60100,24830000,210573000],
[1203,60150,24872000,211065000],
[1204,60200,24916000,211557000],
[1205,60250,24958000,212051000],
[1206,60300,25000000,212545000],
[1207,60350,25044000,213041000],
[1208,60400,25086000,213538000],
[1209,60450,25130000,214035000],
[1210,60500,25172000,214533000],
[1211,60550,25216000,215033000],
[1212,60600,25260000,215533000],
[1213,60650,25302000,216034000],
[1214,60700,25346000,216536000],
[1215,60750,25390000,217039000],
[1216,60800,25434000,217543000],
[1217,60850,25476000,218048000],
[1218,60900,25520000,218553000],
[1219,60950,25564000,219060000],
[1220,61000,25608000,219567000],
[1221,61050,25652000,220075000],
[1222,61100,25696000,220584000],
[1223,61150,25742000,221094000],
[1224,61200,25786000,221605000],
[1225,61250,25830000,222116000],
[1226,61300,25874000,222629000],
[1227,61350,25920000,223142000],
[1228,61400,25964000,223656000],
[1229,61450,26008000,224171000],
[1230,61500,26054000,224686000],
[1231,61550,26100000,225203000],
[1232,61600,26144000,225720000],
[1233,61650,26190000,226238000],
[1234,61700,26234000,226756000],
[1235,61750,26280000,227276000],
[1236,61800,26326000,227796000],
[1237,61850,26372000,228317000],
[1238,61900,26418000,228839000],
[1239,61950,26464000,229361000],
[1240,62000,26508000,229884000],
[1241,62050,26556000,230408000],
[1242,62100,26602000,230932000],
[1243,62150,26648000,231457000],
[1244,62200,26694000,231983000],
[1245,62250,26740000,232509000],
[1246,62300,26786000,233036000],
[1247,62350,26834000,233564000],
[1248,62400,26880000,234092000],
[1249,62450,26928000,234621000],
[1250,62500,26974000,235151000],
[1251,62550,27022000,235681000],
[1252,62600,27068000,236212000],
[1253,62650,27116000,236743000],
[1254,62700,27164000,237275000],
[1255,62750,27210000,237807000],
[1256,62800,27258000,238341000],
[1257,62850,27306000,238874000],
[1258,62900,27354000,239408000],
[1259,62950,27402000,239943000],
[1260,63000,27450000,240478000],
[1261,63050,27498000,241014000],
[1262,63100,27546000,241550000],
[1263,63150,27594000,242086000],
[1264,63200,27644000,242623000],
[1265,63250,27692000,243161000],
[1266,63300,27740000,243699000],
[1267,63350,27790000,244238000],
[1268,63400,27838000,244776000],
[1269,63450,27888000,245316000],
[1270,63500,27938000,245855000],
[1271,63550,27986000,246396000],
[1272,63600,28036000,246936000],
[1273,63650,28086000,247477000],
[1274,63700,28136000,248018000],
[1275,63750,28184000,248560000],
[1276,63800,28234000,249102000],
[1277,63850,28284000,249644000],
[1278,63900,28334000,250187000],
[1279,63950,28386000,250730000],
[1280,64000,28436000,251273000],
[1281,64050,28486000,251817000],
[1282,64100,28536000,252361000],
[1283,64150,28588000,252905000],
[1284,64200,28638000,253450000],
[1285,64250,28690000,253994000],
[1286,64300,28740000,254539000],
[1287,64350,28792000,255085000],
[1288,64400,28844000,255630000],
[1289,64450,28894000,256176000],
[1290,64500,28946000,256722000],
[1291,64550,28998000,257268000],
[1292,64600,29050000,257814000],
[1293,64650,29102000,258361000],
[1294,64700,29154000,258908000],
[1295,64750,29206000,259454000],
[1296,64800,29258000,260001000],
[1297,64850,29312000,260549000],
[1298,64900,29364000,261096000],
[1299,64950,29416000,261643000],
[1300,65000,29470000,262191000],
[1301,65050,29522000,262739000],
[1302,65100,29576000,263287000],
[1303,65150,29630000,263835000],
[1304,65200,29682000,264383000],
[1305,65250,29736000,264931000],
[1306,65300,29790000,265479000],
[1307,65350,29844000,266027000],
[1308,65400,29898000,266575000],
[1309,65450,29952000,267124000],
[1310,65500,30006000,267672000],
[1311,65550,30060000,268220000],
[1312,65600,30116000,268769000],
[1313,65650,30170000,269317000],
[1314,65700,30224000,269866000],
[1315,65750,30280000,270414000],
[1316,65800,30334000,270962000],
[1317,65850,30390000,271511000],
[1318,65900,30446000,272059000],
[1319,65950,30500000,272608000],
[1320,66000,30556000,273156000],
[1321,66050,30612000,273704000],
[1322,66100,30668000,274252000],
[1323,66150,30724000,274801000],
[1324,66200,30780000,275349000],
[1325,66250,30838000,275897000],
[1326,66300,30894000,276445000],
[1327,66350,30950000,276992000],
[1328,66400,31008000,277540000],
[1329,66450,31064000,278088000],
[1330,66500,31122000,278635000],
[1331,66550,31178000,279183000],
[1332,66600,31236000,279730000],
[1333,66650,31294000,280277000],
[1334,66700,31352000,280824000],
[1335,66750,31410000,281371000],
[1336,66800,31468000,281918000],
[1337,66850,31526000,282465000],
[1338,66900,31584000,283011000],
[1339,66950,31642000,283557000],
[1340,67000,31702000,284104000],
[1341,67050,31760000,284650000],
[1342,67100,31820000,285195000],
[1343,67150,31878000,285741000],
[1344,67200,31938000,286286000],
[1345,67250,31998000,286832000],
[1346,67300,32058000,287377000],
[1347,67350,32118000,287922000],
[1348,67400,32178000,288466000],
[1349,67450,32238000,289011000],
[1350,67500,32298000,289555000],
[1351,67550,32358000,290099000],
[1352,67600,32418000,290643000],
[1353,67650,32480000,291186000],
[1354,67700,32540000,291730000],
[1355,67750,32602000,292273000],
[1356,67800,32664000,292816000],
[1357,67850,32724000,293358000],
[1358,67900,32786000,293900000],
[1359,67950,32848000,294443000],
[1360,68000,32910000,294984000],
[1361,68050,32972000,295526000],
[1362,68100,33036000,296067000],
[1363,68150,33098000,296609000],
[1364,68200,33160000,297149000],
[1365,68250,33224000,297690000],
[1366,68300,33286000,298230000],
[1367,68350,33350000,298770000],
[1368,68400,33414000,299310000],
[1369,68450,33476000,299849000],
[1370,68500,33540000,300389000],
[1371,68550,33604000,300928000],
[1372,68600,33668000,301466000],
[1373,68650,33734000,302005000],
[1374,68700,33798000,302543000],
[1375,68750,33862000,303080000],
[1376,68800,33928000,303618000],
[1377,68850,33992000,304155000],
[1378,68900,34058000,304692000],
[1379,68950,34124000,305229000],
[1380,69000,34188000,305765000],
[1381,69050,34254000,306301000],
[1382,69100,34320000,306837000],
[1383,69150,34386000,307372000],
[1384,69200,34454000,307907000],
[1385,69250,34520000,308442000],
[1386,69300,34586000,308976000],
[1387,69350,34654000,309510000],
[1388,69400,34722000,310044000],
[1389,69450,34788000,310578000],
[1390,69500,34856000,311111000],
[1391,69550,34924000,311644000],
[1392,69600,34992000,312177000],
[1393,69650,35060000,312709000],
[1394,69700,35128000,313241000],
[1395,69750,35198000,313773000],
[1396,69800,35266000,314304000],
[1397,69850,35336000,314835000],
[1398,69900,35404000,315366000],
[1399,69950,35474000,315897000],
[1400,70000,35544000,316427000],
[1401,70050,35614000,316957000],
[1402,70100,35684000,317486000],
[1403,70150,35754000,318015000],
[1404,70200,35824000,318544000],
[1405,70250,35894000,319073000],
[1406,70300,35966000,319601000],
[1407,70350,36036000,320129000],
[1408,70400,36108000,320657000],
[1409,70450,36180000,321185000],
[1410,70500,36252000,321712000],
[1411,70550,36324000,322238000],
[1412,70600,36396000,322765000],
[1413,70650,36468000,323291000],
[1414,70700,36540000,323817000],
[1415,70750,36614000,324343000],
[1416,70800,36686000,324868000],
[1417,70850,36760000,325393000],
[1418,70900,36832000,325918000],
[1419,70950,36906000,326442000],
[1420,71000,36980000,326966000],
[1421,71050,37054000,327490000],
[1422,71100,37130000,328014000],
[1423,71150,37204000,328537000],
[1424,71200,37278000,329060000],
[1425,71250,37354000,329583000],
[1426,71300,37428000,330105000],
[1427,71350,37504000,330627000],
[1428,71400,37580000,331149000],
[1429,71450,37656000,331671000],
[1430,71500,37732000,332192000],
[1431,71550,37808000,332713000],
[1432,71600,37886000,333234000],
[1433,71650,37962000,333755000],
[1434,71700,38040000,334275000],
[1435,71750,38116000,334795000],
[1436,71800,38194000,335315000],
[1437,71850,38272000,335834000],
[1438,71900,38350000,336354000],
[1439,71950,38428000,336873000],
[1440,72000,38508000,337391000],
[1441,72050,38586000,337910000],
[1442,72100,38666000,338428000],
[1443,72150,38744000,338946000],
[1444,72200,38824000,339464000],
[1445,72250,38904000,339982000],
[1446,72300,38984000,340499000],
[1447,72350,39064000,341016000],
[1448,72400,39144000,341533000],
[1449,72450,39226000,342050000],
[1450,72500,39306000,342566000],
[1451,72550,39388000,343083000],
[1452,72600,39468000,343599000],
[1453,72650,39550000,344114000],
[1454,72700,39632000,344630000],
[1455,72750,39714000,345146000],
[1456,72800,39798000,345661000],
[1457,72850,39880000,346176000],
[1458,72900,39964000,346691000],
[1459,72950,40046000,347206000],
[1460,73000,40130000,347720000],
[1461,73050,40214000,348234000],
[1462,73100,40298000,348749000],
[1463,73150,40382000,349263000],
[1464,73200,40466000,349776000],
[1465,73250,40550000,350290000],
[1466,73300,40636000,350804000],
[1467,73350,40722000,351317000],
[1468,73400,40806000,351830000],
[1469,73450,40892000,352343000],
[1470,73500,40978000,352856000],
[1471,73550,41064000,353369000],
[1472,73600,41152000,353882000],
[1473,73650,41238000,354394000],
[1474,73700,41326000,354907000],
[1475,73750,41412000,355419000],
[1476,73800,41500000,355931000],
[1477,73850,41588000,356443000],
[1478,73900,41676000,356955000],
[1479,73950,41764000,357467000],
[1480,74000,41852000,357979000],
[1481,74050,41942000,358491000],
[1482,74100,42030000,359002000],
[1483,74150,42120000,359514000],
[1484,74200,42210000,360025000],
[1485,74250,42300000,360537000],
[1486,74300,42390000,361048000],
[1487,74350,42480000,361559000],
[1488,74400,42570000,362071000],
[1489,74450,42662000,362582000],
[1490,74500,42752000,363093000],
[1491,74550,42844000,363604000],
[1492,74600,42936000,364115000],
[1493,74650,43028000,364626000],
[1494,74700,43120000,365137000],
[1495,74750,43212000,365648000],
[1496,74800,43306000,366159000],
[1497,74850,43398000,366670000],
[1498,74900,43492000,367181000],
[1499,74950,43586000,367693000],
[1500,75000,43678000,368204000],
[1501,75050,43774000,368715000],
[1502,75100,43868000,369226000],
[1503,75150,43962000,369737000],
[1504,75200,44056000,370248000],
[1505,75250,44152000,370759000],
[1506,75300,44248000,371271000],
[1507,75350,44342000,371782000],
[1508,75400,44438000,372293000],
[1509,75450,44534000,372805000],
[1510,75500,44630000,373316000],
[1511,75550,44728000,373828000],
[1512,75600,44824000,374340000],
[1513,75650,44922000,374852000],
[1514,75700,45018000,375363000],
[1515,75750,45116000,375876000],
[1516,75800,45214000,376388000],
[1517,75850,45312000,376900000],
[1518,75900,45410000,377412000],
[1519,75950,45510000,377925000],
[1520,76000,45608000,378438000],
[1521,76050,45708000,378951000],
[1522,76100,45806000,379464000],
[1523,76150,45906000,379977000],
[1524,76200,46006000,380490000],
[1525,76250,46106000,381004000],
[1526,76300,46206000,381518000],
[1527,76350,46306000,382032000],
[1528,76400,46408000,382546000],
[1529,76450,46508000,383060000],
[1530,76500,46610000,383575000],
[1531,76550,46712000,384090000],
[1532,76600,46812000,384605000],
[1533,76650,46914000,385121000],
[1534,76700,47018000,385636000],
[1535,76750,47120000,386152000],
[1536,76800,47222000,386668000],
[1537,76850,47326000,387185000],
[1538,76900,47428000,387702000],
[1539,76950,47532000,388219000],
[1540,77000,47634000,388736000],
[1541,77050,47738000,389254000],
[1542,77100,47842000,389772000],
[1543,77150,47946000,390291000],
[1544,77200,48052000,390810000],
[1545,77250,48156000,391329000],
[1546,77300,48260000,391848000],
[1547,77350,48366000,392368000],
[1548,77400,48472000,392889000],
[1549,77450,48576000,393409000],
[1550,77500,48682000,393931000],
[1551,77550,48788000,394452000],
[1552,77600,48894000,394974000],
[1553,77650,49000000,395497000],
[1554,77700,49106000,396020000],
[1555,77750,49214000,396543000],
[1556,77800,49320000,397067000],
[1557,77850,49428000,397592000],
[1558,77900,49534000,398117000],
[1559,77950,49642000,398642000],
[1560,78000,49750000,399168000],
[1561,78050,49858000,399695000],
[1562,78100,49966000,400222000],
[1563,78150,50074000,400750000],
[1564,78200,50182000,401278000],
[1565,78250,50290000,401807000],
[1566,78300,50398000,402337000],
[1567,78350,50508000,402867000],
[1568,78400,50616000,403398000],
[1569,78450,50726000,403929000],
[1570,78500,50836000,404461000],
[1571,78550,50944000,404994000],
[1572,78600,51054000,405528000],
[1573,78650,51164000,406062000],
[1574,78700,51274000,406597000],
[1575,78750,51384000,407133000],
[1576,78800,51494000,407669000],
[1577,78850,51604000,408207000],
[1578,78900,51716000,408745000],
[1579,78950,51826000,409284000],
[1580,79000,51936000,409823000],
[1581,79050,52048000,410364000],
[1582,79100,52158000,410906000],
[1583,79150,52270000,411448000],
[1584,79200,52382000,411991000],
[1585,79250,52492000,412536000],
[1586,79300,52604000,413081000],
[1587,79350,52716000,413627000],
[1588,79400,52828000,414174000],
[1589,79450,52940000,414722000],
[1590,79500,53052000,415271000],
[1591,79550,53164000,415822000],
[1592,79600,53276000,416373000],
[1593,79650,53388000,416925000],
[1594,79700,53500000,417479000],
[1595,79750,53614000,418034000],
[1596,79800,53726000,418589000],
[1597,79850,53838000,419147000],
[1598,79900,53952000,419705000],
[1599,79950,54064000,420264000],
[1600,80000,54178000,420825000],
[1601,80050,54290000,421387000],
[1602,80100,54404000,421951000],
[1603,80150,54516000,422515000],
[1604,80200,54630000,423081000],
[1605,80250,54744000,423649000],
[1606,80300,54856000,424218000],
[1607,80350,54970000,424788000],
[1608,80400,55084000,425360000],
[1609,80450,55198000,425934000],
[1610,80500,55310000,426509000],
[1611,80550,55424000,427086000],
[1612,80600,55538000,427664000],
[1613,80650,55652000,428244000],
[1614,80700,55766000,428826000],
[1615,80750,55880000,429409000],
[1616,80800,55994000,429994000],
[1617,80850,56108000,430581000],
[1618,80900,56222000,431170000],
[1619,80950,56336000,431761000],
[1620,81000,56450000,432354000],
[1621,81050,56564000,432949000],
[1622,81100,56678000,433546000],
[1623,81150,56792000,434145000],
[1624,81200,56906000,434746000],
[1625,81250,57020000,435349000],
[1626,81300,57134000,435955000],
[1627,81350,57250000,436562000],
[1628,81400,57364000,437173000],
[1629,81450,57478000,437785000],
[1630,81500,57592000,438400000],
[1631,81550,57706000,439018000],
[1632,81600,57820000,439638000],
[1633,81650,57934000,440261000],
[1634,81700,58050000,440887000],
[1635,81750,58164000,441515000],
[1636,81800,58278000,442146000],
[1637,81850,58392000,442780000],
[1638,81900,58506000,443418000],
[1639,81950,58620000,444058000],
[1640,82000,58734000,444702000],
[1641,82050,58848000,445348000],
[1642,82100,58964000,445999000],
[1643,82150,59078000,446652000],
[1644,82200,59192000,447309000],
[1645,82250,59306000,447970000],
[1646,82300,59420000,448635000],
[1647,82350,59534000,449304000],
[1648,82400,59648000,449976000],
[1649,82450,59762000,450653000],
[1650,82500,59876000,451334000],
[1651,82550,59990000,452019000],
[1652,82600,60104000,452709000],
[1653,82650,60218000,453403000],
[1654,82700,60332000,454102000],
[1655,82750,60446000,454807000],
[1656,82800,60560000,455516000],
[1657,82850,60674000,456231000],
[1658,82900,60786000,456951000],
[1659,82950,60900000,457677000],
[1660,83000,61014000,458409000],
[1661,83050,61128000,459147000],
[1662,83100,61242000,459891000],
[1663,83150,61354000,460642000],
[1664,83200,61468000,461400000],
[1665,83250,61582000,462165000],
[1666,83300,61696000,462938000],
[1667,83350,61808000,463718000],
[1668,83400,61922000,464506000],
[1669,83450,62034000,465303000],
[1670,83500,62148000,466109000],
[1671,83550,62260000,466924000],
[1672,83600,62374000,467749000],
[1673,83650,62486000,468584000],
[1674,83700,62600000,469429000],
[1675,83750,62712000,470286000],
[1676,83800,62826000,471155000],
[1677,83850,62938000,472037000],
[1678,83900,63050000,472932000],
[1679,83950,63164000,473841000],
[1680,84000,63276000,474765000],
[1681,84050,63388000,475705000],
[1682,84100,63500000,476662000],
[1683,84150,63612000,477638000],
[1684,84200,63724000,478633000],
[1685,84250,63836000,479650000],
[1686,84300,63950000,480689000],
[1687,84350,64062000,481754000],
[1688,84400,64172000,482846000],
[1689,84450,64284000,483967000],
[1690,84500,64396000,485122000],
[1691,84550,64508000,486314000],
[1692,84600,64620000,487547000],
[1693,84650,64732000,488826000],
[1694,84700,64844000,490159000],
[1695,84750,64954000,491553000],
[1696,84800,65066000,493020000],
[1697,84850,65178000,494573000],
[1698,84900,65288000,496233000],
[1699,84950,65400000,498027000],
[1700,85000,65510000,500000000]
]