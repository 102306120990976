import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export const formatDate = (dateString) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

function createData(
    id,
    visits,
    mail,
    oppId,
  ) {
    return { id,visits,mail,oppId};
  }


export const ModalTable = (props) =>{
  const pageVisit = props.pageVisit;
  const columns = [
      { field: 'visits', headerName: 'Visits',flex: 1, minWidth: 150},
      { field: 'mail', headerName: 'Mail',flex: 1, minWidth: 150},
      { field: 'oppId', headerName: 'Opp Id',flex: 1, minWidth: 150},    
  ];

  const rows = [];
  if(pageVisit !== undefined){
    for (var i = 0; i < pageVisit.length; i++){
        rows.push(
            createData(
                i,
                pageVisit[i][0],
                pageVisit[i][1],
                pageVisit[i][2],
            )
        );
      }
    }

    return (
      <>
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                
            />
        </div>
      </>
    );
}