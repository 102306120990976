import React from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

export default function Features(props) {
  var featureProcent = 0;
  var featureCount = 0;
  var featureCombo = 0;
  var test = props.test;
  const featuresTest = [
    'Ad Revenue Tracking',
    'AdVision (CTV)',
    'Assist Dashboard',
    'Audience Builder',
    'Campaign Automation',
    'Automation API',
    'Data Residency',
    'Fraud Prevention Suite',
    'Multi Touch Attribution',
    'PC / Console Tracking',
    'Purchase Verification 2.0',
    'Smart Banners',
    'SSO',
    'Web SDK'
  ];

  const features = [
    'Ad Revenue Tracking',
    'AdVision (CTV)',
    'Assist Dashboard',
    'Audience Builder',
    'Campaign Automation',
    'Automation API',
    'Data Residency',
    'Fraud Prevention Suite',
    'Multi Touch Attribution',
    'PC / Console Tracking',
    'Purchase Verification 2.0',
    'Smart Banners',
    'SSO',
    'Subscriptions',
    'Web SDK'
  ];

  if(props.feature1 !== '' && props.feature1 !== '-' && typeof(props.feature1) != "undefined"){setFeatureLoad("feature1",props.feature1);}
  if(props.feature2 !== '' && props.feature2 !== '-' && typeof(props.feature2) != "undefined"){setFeatureLoad("feature2",props.feature2);}
  if(props.feature3 !== '' && props.feature3 !== '-' && typeof(props.feature3) != "undefined"){setFeatureLoad("feature3",props.feature3);}
  if(props.feature4 !== '' && props.feature4 !== '-' && typeof(props.feature4) != "undefined"){setFeatureLoad("feature4",props.feature4);}
  if(props.feature5 !== '' && props.feature5 !== '-' && typeof(props.feature5) != "undefined"){setFeatureLoad("feature5",props.feature5);}
  if(props.feature6 !== '' && props.feature6 !== '-' && typeof(props.feature6) != "undefined"){setFeatureLoad("feature6",props.feature6);}
  if(props.feature7 !== '' && props.feature7 !== '-' && typeof(props.feature7) != "undefined"){setFeatureLoad("feature7",props.feature7);}
  if(props.feature8 !== '' && props.feature8 !== '-' && typeof(props.feature8) != "undefined"){setFeatureLoad("feature8",props.feature8);}
  if(props.feature9 !== '' && props.feature9 !== '-' && typeof(props.feature9) != "undefined"){setFeatureLoad("feature9",props.feature9);}
  if(props.feature10 !== '' && props.feature10 !== '-' && typeof(props.feature10) != "undefined"){setFeatureLoad("feature10",props.feature10);}
  if(props.feature11 !== '' && props.feature11 !== '-' && typeof(props.feature11) != "undefined"){setFeatureLoad("feature11",props.feature11);}
  if(props.feature12 !== '' && props.feature12 !== '-' && typeof(props.feature12) != "undefined"){setFeatureLoad("feature12",props.feature12);}
  if(props.feature13 !== '' && props.feature13 !== '-' && typeof(props.feature13) != "undefined"){setFeatureLoad("feature13",props.feature13);}
  if(props.feature14 !== '' && props.feature14 !== '-' && typeof(props.feature14) != "undefined"){setFeatureLoad("feature14",props.feature14);}

  function isDisabled(feature,number){
    var featureList = [];
    var dropdownFeature = ''
    if (number === 1){
      dropdownFeature=props.feature1;
    }
    else{
      featureList.push(props.feature1);
    }
    if (number === 2){
      dropdownFeature=props.feature2;
    }
    else{
      featureList.push(props.feature2);
    }
    if (number === 3){
      dropdownFeature=props.feature3;
    }
    else{
      featureList.push(props.feature3);
    }
    if (number === 4){
      dropdownFeature=props.feature4;
    }
    else{
      featureList.push(props.feature4);
    }
    if (number === 5){
      dropdownFeature=props.feature5;
    }
    else{
      featureList.push(props.feature5);
    }
    if (number === 6){
      dropdownFeature=props.feature6;
    }
    else{
      featureList.push(props.feature6);
    }
    if (number === 7){
      dropdownFeature=props.feature7;
    }
    else{
      featureList.push(props.feature7);
    }
    if (number === 8){
      dropdownFeature=props.feature8;
    }
    else{
      featureList.push(props.feature8);
    }
    if (number === 9){
      dropdownFeature=props.feature9;
    }
    else{
      featureList.push(props.feature9);
    }
    if (number === 10){
      dropdownFeature=props.feature10;
    }
    else{
      featureList.push(props.feature10);
    }
    if (number === 11){
      dropdownFeature=props.feature11;
    }
    else{
      featureList.push(props.feature11);
    }
    if (number === 12){
      dropdownFeature=props.feature12;
    }
    else{
      featureList.push(props.feature12);
    }
    if (number === 13){
      dropdownFeature=props.feature13;
    }
    else{
      featureList.push(props.feature13);
    }
    if (number === 14){
      dropdownFeature=props.feature14;
    }
    else{
      featureList.push(props.feature14);
    }
    if(dropdownFeature === feature){
      return false;
    }

    var index = featureList.indexOf(feature);
    if (index > -1) {
      return true;
    }
    else{
      return false;
    }
  }

  function setFeatures(number){
    var featureList = [] 
    if (test){featureList = [...featuresTest];}
    else{featureList = [...features];}
    var featureArray = [];
    for (var i = 0; i < featureList.length; i++) {
      var disabled = false;
      disabled = isDisabled(featureList[i],number);
      featureArray.push([featureList[i],disabled]);
    }
    return featureArray;
  }

  function setTotalFeatures(){
      featureCount = 0;
      if (parseInt(document.getElementById('feature1Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature2Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature3Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature4Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature5Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature6Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature7Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature8Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature9Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature10Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature11Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature12Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature13Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      if (parseInt(document.getElementById('feature14Field').innerHTML.replace('%','')) > 0){
        featureCount++;
      }
      featureProcent =
        parseInt(document.getElementById('feature1Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature2Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature3Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature4Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature5Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature6Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature7Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature8Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature9Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature10Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature11Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature12Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature13Field').innerHTML.replace('%','')) +
        parseInt(document.getElementById('feature14Field').innerHTML.replace('%',''));
      document.getElementById('featureProcent').innerHTML = featureProcent +'%';
      if (featureCount === 2){
        featureCombo = -3;
      }
      if (featureCount === 3){
        featureCombo = -6;
      }
      if (featureCount === 4){
        featureCombo = -10;
      }
      if (featureCount === 5){
        featureCombo = -14;
      }
      if (featureCount === 6){
        featureCombo = -18;
      }
      if (featureCount === 7){
        featureCombo = -23;
      }
      if (featureCount === 8){
        featureCombo = -28;
      }
      if (featureCount === 9){
        featureCombo = -33;
      }
      if (featureCount > 9){
        featureCombo = -40;
      }
      document.getElementById('featureCombo').innerHTML = featureCombo +'.0%';
      var featureCost = featureProcent + featureCombo;
      document.getElementById('featureCost').innerHTML = featureCost +'.0%';
      props.completeFeatureDiscount(featureProcent);
      props.featureDiscount(featureCost);
      document.getElementById('featureCount').innerHTML = featureCount;
  }

  function setFeatureValue(id,percent,name){
    if (id.indexOf('1') > -1 && id.indexOf('10') === -1 && id.indexOf('11') === -1 && id.indexOf('12') === -1  && id.indexOf('13') === -1 && id.indexOf('14') === -1){
      props.onFeature1Change(name);
    }
    if (id.indexOf("2") > -1 && id.indexOf('12') === -1){
      props.onFeature2Change(name);
    }
    if (id.indexOf("3") > -1 && id.indexOf('13') === -1){
      props.onFeature3Change(name);
    }
    if (id.indexOf("4") > -1 && id.indexOf('14') === -1){
      props.onFeature4Change(name);
    }
    if (id.indexOf("5") > -1){
      props.onFeature5Change(name);
    }
    if (id.indexOf("6") > -1){
      props.onFeature6Change(name);
    }
    if (id.indexOf("7") > -1){
      props.onFeature7Change(name);
    }
    if (id.indexOf("8") > -1){
      props.onFeature8Change(name);
    }
    if (id.indexOf("9") > -1){
      props.onFeature9Change(name);
    }
    if (id.indexOf("10") > -1){
      props.onFeature10Change(name);
    }
    if (id.indexOf("11") > -1){
      props.onFeature11Change(name);
    }
    if (id.indexOf("12") > -1){
      props.onFeature12Change(name);
    }
    if (id.indexOf("13") > -1){
      props.onFeature13Change(name);
    }
    if (id.indexOf("14") > -1){
      props.onFeature14Change(name);
    }
    setTotalFeatures();
  }

  function setFeature(event){
    var id = event.target.id;
    var value = document.getElementById(id).value;
    if(value === 'Fraud Prevention Suite'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Fraud Prevention Suite');
    }
    if(value === 'Campaign Automation'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Campaign Automation');
    }
    if(value === 'Data Residency'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Data Residency');
    }
    if(value === 'Assist Dashboard'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'Assist Dashboard');
      props.onAssistDashboardChange(true);
    }
    if(value === 'Audience Builder'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Audience Builder');
    }
    if(value === 'Subscriptions'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Subscriptions');
    }
    if(value === 'Ad Revenue Tracking'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'Ad Revenue Tracking');
    }
    if(value === 'Web SDK'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'Web SDK');
    }
    if(value === 'Multi Touch Attribution'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'Multi Touch Attribution');
    }
    if(value === 'AdVision (CTV)'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'AdVision (CTV)');
    }
    if(value === 'Smart Banners'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Smart Banners');
    }
    if(value === 'SSO'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'SSO');
    }
    if(value === 'Automation API'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Automation API');
    }
    if(value === 'PC / Console Tracking'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'PC / Console Tracking');
    }
    if(value === 'Purchase Verification 2.0'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Purchase Verification 2.0');
    }
    if(value === 'None'){
      document.getElementById(id+'Field').innerHTML = '0.0%';
      setFeatureValue(id,0,'-');
    }
    if(id === 'feature1'){
      document.getElementById('row2').style.display = "block";
    }
    if(id === 'feature2'){
      document.getElementById('row3').style.display = "block";
    }
    if(id === 'feature3'){
      document.getElementById('row4').style.display = "block";
    }
    if(id === 'feature4'){
      document.getElementById('row5').style.display = "block";
    }
    if(id === 'feature5'){
      document.getElementById('row6').style.display = "block";
    }
    if(id === 'feature6'){
      document.getElementById('row7').style.display = "block";
    }
    if(id === 'feature7'){
      document.getElementById('row8').style.display = "block";
    }
    if(id === 'feature8'){
      document.getElementById('row9').style.display = "block";
    }
    if(id === 'feature9'){
      document.getElementById('row10').style.display = "block";
    }
    if(id === 'feature10'){
      document.getElementById('row11').style.display = "block";
    }
    if(id === 'feature11'){
      document.getElementById('row12').style.display = "block";
    }
    if(id === 'feature12'){
      document.getElementById('row13').style.display = "block";
    }
    if(id === 'feature13'){
      document.getElementById('row14').style.display = "block";
    }
  }

  function setFeatureLoad(id,value){
    if(value === 'Fraud Prevention Suite'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Fraud Prevention Suite');
    }
    if(value === 'Campaign Automation'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Campaign Automation');
    }
    if(value === 'Data Residency'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Data Residency');
    }
      if(value === 'Assist Dashboard'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'Assist Dashboard');
      props.onAssistDashboardChange(true);
    }
    if(value === 'Audience Builder'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Audience Builder');
    }
    if(value === 'Subscriptions'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Subscriptions');
    }
    if(value === 'Ad Revenue Tracking'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'Ad Revenue Tracking');
    }
    if(value === 'Web SDK'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'Web SDK');
    }
    if(value === 'Multi Touch Attribution'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'Multi Touch Attribution');
    }
    if(value === 'AdVision (CTV)'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'AdVision (CTV)');
    }
    if(value === 'Smart Banners'){
      document.getElementById(id+'Field').innerHTML = '20.0%';
      setFeatureValue(id,20,'Smart Banners');
    }
    if(value === 'SSO'){
      document.getElementById(id+'Field').innerHTML = '5.0%';
      setFeatureValue(id,5,'SSO');
    }
    if(value === 'Automation API'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Automation API');
    }
    if(value === 'PC / Console Tracking'){
      document.getElementById(id+'Field').innerHTML = '15.0%';
      setFeatureValue(id,15,'PC / Console Tracking');
    }
    if(value === 'Purchase Verification 2.0'){
      document.getElementById(id+'Field').innerHTML = '10.0%';
      setFeatureValue(id,10,'Purchase Verification 2.0');
    }
    if(value === 'None'){
      document.getElementById(id+'Field').innerHTML = '0.0%';
      setFeatureValue(id,0,'-');
    }
    if(id === 'feature1'){
      document.getElementById('row2').style.display = "block";
    }
    if(id === 'feature2'){
      document.getElementById('row3').style.display = "block";
    }
    if(id === 'feature3'){
      document.getElementById('row4').style.display = "block";
    }
    if(id === 'feature4'){
      document.getElementById('row5').style.display = "block";
    }
    if(id === 'feature5'){
      document.getElementById('row6').style.display = "block";
    }
    if(id === 'feature6'){
      document.getElementById('row7').style.display = "block";
    }
    if(id === 'feature7'){
      document.getElementById('row8').style.display = "block";
    }
    if(id === 'feature8'){
      document.getElementById('row9').style.display = "block";
    }
    if(id === 'feature9'){
      document.getElementById('row10').style.display = "block";
    }
    if(id === 'feature10'){
      document.getElementById('row11').style.display = "block";
    }
    if(id === 'feature11'){
      document.getElementById('row12').style.display = "block";
    }
    if(id === 'feature12'){
      document.getElementById('row13').style.display = "block";
    }
    if(id === 'feature13'){
      document.getElementById('row14').style.display = "block";
    }
  }
  return (
    <div>
        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px',textAlign: 'center'}}>
          <Col >
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Required Features</div>
            </Badge>
          </Col>

          <Col >
              <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature1" onChange={setFeature.bind(this)}>
              <option key="def1" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
              {setFeatures(1).map((feature) =>
                <option key={feature[0]+"1"}  disabled={feature[1] ? true : null} selected={props.feature1 === feature[0]} >{feature[0]} </option>
              )}
              <option>None</option>
              </Form.Control>
          </Col>
          <Col >
            <Card style={{height:'30px'}}>
              <div id='feature1Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>0.0%</div>
            </Card>
          </Col>
        </Row>
        <div id="row2" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature2" onChange={setFeature.bind(this)}>
                <option key="def2" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(2).map((feature) =>
                  <option key={feature[0]+"2"} disabled={feature[1] ? true : null} selected={props.feature2 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>
            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature2Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row3" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature3" onChange={setFeature.bind(this)}>
                <option key="def3" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(3).map((feature) =>
                  <option key={feature[0]+"3"} disabled={feature[1] ? true : null} selected={props.feature3 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature3Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row4" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature4" onChange={setFeature.bind(this)}>
                <option key="def4" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(4).map((feature) =>
                  <option key={feature[0]+"4"} disabled={feature[1] ? true : null} selected={props.feature4 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature4Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row5" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature5" onChange={setFeature.bind(this)}>
                <option key="def5" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(5).map((feature) =>
                  <option key={feature[0]+"5"} disabled={feature[1] ? true : null} selected={props.feature5 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature5Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row6" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature6" onChange={setFeature.bind(this)}>
                <option key="def6" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(6).map((feature) =>
                  <option key={feature[0]+"6"} disabled={feature[1] ? true : null} selected={props.feature6 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature6Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row7" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature7" onChange={setFeature.bind(this)}>
                <option key="def7" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(7).map((feature) =>
                  <option key={feature[0]+"7"} disabled={feature[1] ? true : null} selected={props.feature7 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature7Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="row8" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature8" onChange={setFeature.bind(this)}>
                <option key="def8" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(8).map((feature) =>
                  <option key={feature[0]+"8"} disabled={feature[1] ? true : null} selected={props.feature8 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature8Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
            </div>

            <div id="row9" style={{display:'none'}}>
              <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
              <Col >
                <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature9" onChange={setFeature.bind(this)}>
                <option key="def9" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(9).map((feature) =>
                  <option key={feature[0]+"9"} disabled={feature[1] ? true : null} selected={props.feature9 === feature[0]}>{feature[0]}</option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature9Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
        </div>

        <div id="row10" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature10" onChange={setFeature.bind(this)}>
                <option key="def10" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(10).map((feature) =>
                  <option key={feature[0]+"10"} disabled={feature[1] ? true : null} selected={props.feature10 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature10Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
          </div>

          <div id="row11" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature11" onChange={setFeature.bind(this)}>
                <option key="def11" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(11).map((feature) =>
                  <option key={feature[0]+"11"} disabled={feature[1] ? true : null} selected={props.feature11 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature11Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
          </div>

          <div id="row12" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature12" onChange={setFeature.bind(this)}>
                <option key="def12" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(12).map((feature) =>
                  <option key={feature[0]+"12"} disabled={feature[1] ? true : null} selected={props.feature12 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature12Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
          </div>

          <div id="row13" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature13" onChange={setFeature.bind(this)}>
                <option key="def13" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(13).map((feature) =>
                  <option key={feature[0]+"13"} disabled={feature[1] ? true : null} selected={props.feature13 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature13Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
          </div>
          
          <div id="row14" style={{display:'none'}}>
          <Row style={{width:'99%',marginLeft:'1%',textAlign: 'center',marginTop:'4px'}}>
            <Col >
              <div/>
            </Col>

            <Col >
                <Form.Control defaultValue={'DEFAULT'} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm"  id="feature14" onChange={setFeature.bind(this)}>
                <option key="def14" value="DEFAULT" disabled="disabled"> -- Select a feature -- </option>
                {setFeatures(14).map((feature) =>
                  <option key={feature[0]+"14"} disabled={feature[1] ? true : null} selected={props.feature14 === feature[0]}>{feature[0]} </option>
                )}
                <option>None</option>
                </Form.Control>
            </Col>

            <Col >
              <Card style={{height:'30px'}}>
                <div id='feature14Field' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
              </Col>
              </Row>
          </div>

          <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
            <Col>
              <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Total Add-ons</div>
              </Badge>
            </Col>

            <Col>
              <Card style={{height:'30px'}}>
                <div id='featureCount' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0</div>
              </Card>
            </Col>

            <Col>
              <Card style={{height:'30px'}}>
                <div id='featureProcent' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>

          <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
            <Col>
              <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Add-on Discount</div>
              </Badge>
            </Col>

            <Col>
                <div></div>
            </Col>

            <Col>
              <Card style={{height:'30px'}}>
                <div id='featureCombo' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>

          <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
            <Col>
              <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Final Add-on Cost %</div>
              </Badge>
                
            </Col>

            <Col>
              <div></div>
            </Col>

            <Col>
              <Card style={{height:'30px'}}>
              <div id='featureCost' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>0.0%</div>
              </Card>
            </Col>
          </Row>
  </div>

  );
}
