import { Link } from "@mui/material";
import { useState } from "react"
import { Button, Card } from "react-bootstrap"
import Form from 'react-bootstrap/Form';

export const OpportunitySaveButton = (props) => {
    const [oppId,setOppId] = useState('');
    const [error,setError] = useState('');
    const [accountId, setAccountId] = useState('');
    const createOffer = () =>{
            const inputData = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({

                type: props.type,
                accountSalesforceId: accountId,
                volume: props.volume,
                contractLength: props.contractLength,
                paymentTerm: props.paymentTerm,
                currency: props.currency,
                acv: props.acv,
                region: props.region,

                feature1: props.feature1,
                feature2: props.feature2,
                feature3: props.feature3,
                feature4: props.feature4,
                feature5: props.feature5,
                feature6: props.feature6,
                feature7: props.feature7,
                feature8: props.feature8,
                feature9: props.feature9,
                feature10: props.feature10,
                feature11: props.feature11,
                feature12: props.feature12,
                feature13: props.feature13,
                feature14: props.feature14,

                dataManagementSelected: props.dataManagementSelected,
                assistDashboard: props.assistDashboard,
                pulse: props.pulse,
                pulseCount: props.pulseCount,
                spendWorks: props.spendWorks,
                incrementality: props.incrementality,
                incrementalityCount: props.incrementalityCount,
                nextGen: props.nextGen,
                trueLink: props.trueLink,
                entity: props.entity,
                separator: props.separator,
                lowerLimit: props.lowerLimit,
                quoteDiscount: props.quoteDiscount
              })
            }
        
            if(props.contractLength > 0 && props.volume !== 0 && props.paymentTerm !== 0 && accountId !== '' && props.acv !== 0){
              setError('');
              fetch('/api/save_opportunity', inputData).then(response => response.json()).then(function(result) {
                setOppId(result["oppId"]);
              });
            }
            else{
              if(props.contractLength === 0 || props.contractLength == null){
                setError('Contract Length');
              }
              else if(props.volume === 0){
                setError('Volume');
              }
              else if(props.paymentTerm === 'DEFAULT'){
                setError('Payment Term');
              }
              else if(accountId === ''){
                setError('Account Id');
              }
              else if(props.acv === 0){
                setError('ACV');
              }
            }
    }

    return(
        <>
          <p style={{marginLeft:'2%'}} >Please choose an <b>Account Id</b> or a <b>Salesforce Id</b> (for new clients)</p>
          <Form.Control style = {{ width: '50%',marginLeft: '2%',background: '#ffffcc' }} id="account_salesforce_id" placeholder="e.g 65" onChange={e => setAccountId(e.target.value)}/>
          <Button style = {{ marginLeft: '20px',marginRight: '20px',marginTop: '10px',marginBottom: '20px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => createOffer()} type="submit">Create Offer</Button>
          {(error !== '') &&
            <p style={{color:'red',marginLeft:'2%'}} >Please choose a {error}</p>
          }
          {(oppId !== '') &&
          <Card style={{marginLeft:'2%', marginRight:'2%'}}>
            <Card.Text style={{marginLeft:'2%', marginTop:'2%', marginBottom:'2%'}}>
              <p>You can build your offer now under:</p>
              <Link href="/creator">Creator</Link>
            </Card.Text>
          </Card>
          }
        </>

    )
}