import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Logout } from './logoutButton';
import Nav from 'react-bootstrap/Nav';

function NavBarFull(props) {
  const attributionClass = window.location.pathname === "/" ? "active" : "";
  const mauClass = window.location.pathname === "/mau" ? "active" : "";
  const dataClass = window.location.pathname === "/data" ? "active" : "";
  const creatorClass = window.location.pathname === "/creator" ? "active" : "";
  //<Nav.Item style={{height:'60px',marginTop:'10px'}}>
  //<Nav.Link className={creatorClass} style={{color: 'white'}} href="/creator">Creator</Nav.Link>
  //</Nav.Item>
  function getSiteName(siteName){
    if (siteName === '/'){
      return 'Conversions'
    }
    if (siteName === '/mau'){
      return 'Maus'
    }
    if (siteName === '/data'){
      return 'Data Points'
    }
    if (siteName === '/updates'){
      return 'Updates'
    }
    if (siteName === '/creator'){
      return 'Creator'
    }
    if (siteName === '/statistics'){
      return 'Statistics'
    }
    if (siteName === '/myaccounts'){
      return 'My Accounts'
    }
  }
  const siteName = getSiteName(window.location.pathname);

    return (
      <div id="nav" class="shadow-lg">
        <Navbar expand="lg" style={{background:"#04182b"}} >

        <Navbar.Brand href="/updates" style={{width:'25%'}}>
            <img id="logo-adjust" style={{marginLeft:'3%'}} src={"https://a.storyblok.com/f/47007/x/2e8164e221/adjust-logo-white.svg"} width="200" height="50" alt="adjust.com" />
        </Navbar.Brand>

        <h2 style={{width:'45%', color:'white'}} class="text-center">{siteName}</h2>

          <Nav className="justify-content-center" variant="pills">
            <Nav.Item style={{height:'60px',marginTop:'10px'}}>
              <Nav.Link className={attributionClass} style={{color: 'white'}} href="/">Conversions </Nav.Link>
            </Nav.Item>
            <Nav.Item style={{height:'60px',marginTop:'10px'}}>
              <Nav.Link className={mauClass} style={{color: 'white'}} href="/mau">MAUs</Nav.Link>
            </Nav.Item>
            <Nav.Item style={{height:'60px',marginTop:'10px'}}>
              <Nav.Link className={dataClass} style={{color: 'white'}} href="/data">Data Points</Nav.Link>
            </Nav.Item>
            
            <Nav.Item>
              <Nav.Link><Logout clientId={props.clientId}/></Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>
    );

}
export default NavBarFull;
