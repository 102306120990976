import { Grid, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Goals } from "../components/Creator/goals";
import { ClientInformation } from "../components/Creator/clientInformation";
import { Packages } from "../components/Creator/packages";
import { Share } from "../components/Creator/share";
import { AdjustInformation } from "../components/Creator/adjustInformation";
import { ClientInformationNew } from "../components/Creator/clientInfromationNew";

export const Creator = () => {
    const [accountSalesforceId,setAccountSalesforceId] = useState('');
    const [conversions,setConversions] = useState([]);
    const [maus,setMaus] = useState([]);
    const [datas,setDatas] = useState([]);
    const [accountInfo,setAccountInfo] = useState(['','']);
    const [clientInfo,setClientInfo] = useState({clientName:'',clientLogo:''});
    function handleClientInfoChange(value) {setClientInfo(value);}
    function handleAccountInfoChange(value) {setAccountInfo(value);}
    const [goals,setGoals] = useState({goal1:'',goal2:'',goal3:'',goal4:'',goal5:''});
    function handleGoalChange(value) {setGoals(value);}
    const [package1,setPackage1] = useState([]);
    const [package2,setPackage2] = useState([]);
    const [package3,setPackage3] = useState([]);
    function handlePackage1Change(value) {setPackage1(value);}
    function handlePackage2Change(value) {setPackage2(value);}
    function handlePackage3Change(value) {setPackage3(value);}
    const [accountExecutive,setAccountExecutive] = useState('');
    function handleAccountExecutiveChange(value) {setAccountExecutive(value);}
    const [solutionsConsultant,setSolutionsConsultant] = useState('');
    function handleSolutionsConsultantChange(value) {setSolutionsConsultant(value);}
    const [loaded,setLoaded] = useState(false);
    function getData(){
        const accountData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({accountSalesforceId}),
          }
        fetch('/api/getopportunity', accountData).then(response => response.json()).then(function(result) {
            setAccountInfo(result.data.accountInfo);
            setConversions(result.data.conversion);
            setMaus(result.data.mau);
            setDatas(result.data.data);
            setLoaded(true);
        });
      }
      
    return (
        <div style={{marginLeft:'2%','marginRight':'2%'}}>
             <h1 style={{textAlign:'center'}}>Your Proposal Creator v0.1</h1>

            <InputLabel >
                Enter <b>Account Id</b> or <b>Salesforce Id</b> to continue
            </InputLabel>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item> 
                    <TextField
                        onChange={(e) => setAccountSalesforceId(e.target.value)} label="Account Id" 
                        onKeyDown={(e) => (
                            e.code === "Enter" ? getData() : null
                        )}
                    />
                </Grid>
                <Grid item xs={9}>
                    <Button style = {{background: '#08d1ff', color: 'white', borderRadius: '100px'}} variant="info" onClick={getData} type="submit">Load</Button>
                </Grid>
                <Grid item>
                    <Button style = {{background: '#08d1ff', color: 'white', borderRadius: '100px'}} variant="info" href='/statistics' type="submit">Show Statistics</Button>
                </Grid>
            </Grid>
            <hr class="hr" />
            {(!isNaN(accountSalesforceId)) && 
            <>
                {(datas.length > 0 || maus.length > 0 || conversions.length > 0) &&
                <>
                    <ClientInformation companyName={accountInfo[0]} vertical={accountInfo[1]} onClientInfoChange={handleClientInfoChange} />
                    <hr class="hr" />
                    <AdjustInformation onAccountExecutiveChange={handleAccountExecutiveChange} onSolutionsConsultantChange={handleSolutionsConsultantChange} />
                    <hr class="hr" />
                    <Goals onGoalChange={handleGoalChange} />
                    <hr class="hr" />
                    <h2>Packages</h2>
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'1'} onPackageChange={handlePackage1Change} />
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'2'} onPackageChange={handlePackage2Change} />
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'3'} onPackageChange={handlePackage3Change} />
                    <hr class="hr" />
                    <Share accountSalesforceId={accountSalesforceId} accountInfo={accountInfo} clientInfo={clientInfo} goals={goals} package1={package1} package2={package2} package3={package3} 
                    accountExecutive={accountExecutive} solutionsConsultant={solutionsConsultant} /> 
                </>
                }
            </>
            }

            {(isNaN(accountSalesforceId)) && 
            <>
                {(datas.length > 0 || maus.length > 0 || conversions.length > 0) &&
                <>
                    <ClientInformationNew companyName={accountInfo[0]} vertical={accountInfo[1]} onClientInfoChange={handleClientInfoChange} onAccountInfoChange={handleAccountInfoChange}/>
                    <hr class="hr" />
                    <AdjustInformation onAccountExecutiveChange={handleAccountExecutiveChange} onSolutionsConsultantChange={handleSolutionsConsultantChange} />
                    <hr class="hr" />
                    <Goals onGoalChange={handleGoalChange} />
                    <hr class="hr" />
                    <h2>Packages</h2>
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'1'} onPackageChange={handlePackage1Change} />
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'2'} onPackageChange={handlePackage2Change} />
                    <Packages conversions={conversions} maus={maus} datas={datas} number={'3'} onPackageChange={handlePackage3Change} />
                    <hr class="hr" />
                    <Share accountSalesforceId={accountSalesforceId} accountInfo={accountInfo} clientInfo={clientInfo} goals={goals} package1={package1} package2={package2} package3={package3} 
                    accountExecutive={accountExecutive} solutionsConsultant={solutionsConsultant} /> 
                </>
                }
            </>
            }

            {(datas.length === 0 && maus.length === 0 && conversions.length === 0 && loaded) &&
            <p style={{color:'red'}} >Please create an <b>Opportunity</b> first</p>
            }
        </div>
    );
            
}