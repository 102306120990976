import { FormControl, Grid, InputLabel, Select } from "@mui/material"
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

export const Packages = (props) => {
    const [packageId,setPackageId] = useState('');
    const [packaging,setPackage] = useState([]);

    useEffect(() => {
        for (let i = 0; i < props.conversions.length; i++) {
            const opp = props.conversions[i];
            if(packageId === opp[0]){
                setPackage(opp);
                props.onPackageChange(opp);
            }
        }
        for (let i = 0; i < props.maus.length; i++) {
            const opp = props.maus[i];
            if(packageId === opp[0]){
                setPackage(opp);
                props.onPackageChange(opp);
            }
        }
        for (let i = 0; i < props.datas.length; i++) {
            const opp = props.datas[i];
            if(packageId === opp[0]){
                setPackage(opp);
                props.onPackageChange(opp);
            }
        }
    }, [packageId]);

    return(
        <>
                <Grid container direction="row">
                    <Grid xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel >Package {props.number}</InputLabel>
                            <Select native defaultValue="" id="package1Select" label="Package1" onChange={e => setPackageId(e.target.value)}>
                                <option aria-label="None" value="" />
                                <optgroup label="Conversion">
                                    {props.conversions.map((conversion) =>
                                    <option key={conversion[0]}>{conversion[0]}</option>
                                    )}
                                </optgroup>
                                <optgroup label="MAU">
                                    {props.maus.map((mau) =>
                                    <option key={mau[0]}>{mau[0]}</option>
                                    )}
                                </optgroup>
                                <optgroup label="Data">
                                    {props.datas.map((data) =>
                                    <option key={data[0]}>{data[0]}</option>
                                    )}
                                </optgroup>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    {(packaging.length > 0) &&
                    <Grid xs={8}>
                        <Card>
                            <Grid container direction="row" style={{marginLeft:'10px', marginRight:'10px', marginTop:'5px'}}>
                                <Grid xs={4}>
                                    <h4>Input Terms</h4>
                                    <p>Type: {packaging[3]}</p>
                                    <p>Volume: {packaging[4]}</p>
                                    <p>Contract Length: {packaging[5]}</p>
                                    <p>Payment Terms: {packaging[6]}</p>
                                    <p>ACV: {packaging[7]}</p>
                                    <p>Region: {packaging[8]}</p>
                                    <p>Entity: {packaging[32]}</p>
                                    <p>Currency: {packaging[9]}</p>
                                </Grid>
                                <Grid xs={4}>
                                    <h4>Features</h4>
                                    {packaging[10] !== '-' &&
                                    <p>Feature 1: {packaging[10]}</p>
                                    }
                                    {packaging[11] !== '-' &&
                                    <p>Feature 2: {packaging[11]}</p>
                                    }
                                    {packaging[12] !== '-' &&
                                    <p>Feature 3: {packaging[12]}</p>
                                    }
                                    {packaging[13] !== '-' &&
                                    <p>Feature 4: {packaging[13]}</p>
                                    }
                                    {packaging[14] !== '-' &&
                                    <p>Feature 5: {packaging[14]}</p>
                                    }
                                    {packaging[15] !== '-' &&
                                    <p>Feature 6: {packaging[15]}</p>
                                    }
                                    {packaging[16] !== '-' &&
                                    <p>Feature 7: {packaging[16]}</p>
                                    }
                                    {packaging[17] !== '-' &&
                                    <p>Feature 8: {packaging[17]}</p>
                                    }
                                    {packaging[18] !== '-' &&
                                    <p>Feature 9: {packaging[18]}</p>
                                    }
                                    {packaging[19] !== '-' &&
                                    <p>Feature 10: {packaging[19]}</p>
                                    }
                                    {packaging[20] !== '-' &&
                                    <p>Feature 11: {packaging[20]}</p>
                                    }
                                    {packaging[21] !== '-' &&
                                    <p>Feature 12: {packaging[21]}</p>
                                    }
                                    {packaging[22] !== '-' &&
                                    <p>Feature 13: {packaging[22]}</p>
                                    }
                                    {packaging[23] !== '-' &&
                                    <p>Feature 14: {packaging[23]}</p>
                                    }
                                </Grid>
                                <Grid xs={4}>
                                    <h4> Extra Packages</h4>
                                    {packaging[24] !== 'false' &&
                                    <p>Custom Dimensions </p>
                                    }
                                    {packaging[26] !== 'false' &&
                                    <>
                                        <p>Pulse </p>
                                        <p>Pulse Count: {packaging[27]}</p>
                                    </>
                                    }
                                    {packaging[28] !== 'false' &&
                                    <p>Spend Works </p>
                                    }
                                    {packaging[29] !== null &&
                                    <>
                                        <p>Incrementality </p>
                                        <p>Incrementality Count: {packaging[30]}</p>
                                    </>
                                    }
                                    {packaging[31] !== 'false' &&
                                    <p>Next Gen: {packaging[31]}</p>
                                    }
                                    {packaging[36] !== 'false' &&
                                    <p>True Link: {packaging[36]}</p>
                                    }
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                     }
                </Grid>
        </>
    );
}