import React from 'react';
import {GoogleLogout} from 'react-google-login';
export const Logout = (props) =>{

  function logout(props) {
    fetch('/api/logout').then(response => response.json()).then(function(result) {
      if(result.login === false){
        window.location.href = "/";
      }
    });
  }


  return (
      <GoogleLogout
        clientId={props.clientId}
        buttonText="Logout"
        onLogoutSuccess={logout}
      >
      </GoogleLogout>
  );
};
