import { Grid, TextField } from "@mui/material"
import { useState } from "react";

export const ClientInformation = (props) => {
    const [logo,setLogo] = useState('');
    const [clientName,setClientName] = useState('');

    const onClientChange = (client) => {
        props.onClientInfoChange({clientName: client, clientLogo: logo});
        setClientName(client);
    }

    const onLogoChange = (logoUrl) => {
        props.onClientInfoChange({clientName: clientName, clientLogo: logoUrl});
        setLogo(logoUrl);
    }
    
    return(
        <>
            <h2>Client Information</h2>
                <Grid container direction="row">
                    <Grid item xs={6} >
                        <Grid container direction="row" rowSpacing={2} justifyContent="flex-start" alignItems="center">
                            <Grid item xs={12} >
                                <TextField
                                    id="clientName"
                                    label="Client Name"
                                    style={{minWidth:'500px'}}
                                    onChange={e => onClientChange(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="companyName"
                                    label="Company Name"
                                    value={props.companyName}
                                    style={{minWidth:'500px'}}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="vertical"
                                    label="Vertical"
                                    value={props.vertical}
                                    style={{minWidth:'500px'}}
                                    disabled
                                />
                            </Grid>
                            <Grid item >
                                <TextField
                                    id="companyLogo"
                                    label="Company Logo"
                                    style={{minWidth:'500px'}}
                                    onChange={e => onLogoChange(e.target.value)}
                                />
                                
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} >
                            <img src={logo} alt=""/>
                    </Grid>
                </Grid>
        </>
    );
}