import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import {Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import NumberFormat from'react-number-format';

function getPrefix(currency){
  if (currency === 'JPY') {
    return '¥';
  }
  if (currency === 'USD') {
    return '$';
  }
  if (currency === 'GBP') {
    return '£';
  }
  if (currency === 'CNY') {
    return '¥';
  }
  if (currency === 'BRL') {
    return 'R$';
  }
  return '€';
}

function getCurrency(currency){
  if (currency === 'JPY') {
    return 136.0719;
  }
  if (currency === 'USD') {
    return 1.0710;
  }
  if (currency === 'GBP') {
    return 0.8498;
  }
  if (currency === 'CNY') {
    return 7.1887;
  }
  if (currency === 'BRL') {
    return 5.1086;
  }
  return 1.0;
}

export default function Support(props) {
  let currencyPrefix = getPrefix(props.currency)
  let currencyMultiplier = getCurrency(props.currency)
  const [supportCost, setSupportCost] = useState(0);
  var acvDedicated = true;
  var supportPackage = "Global";
  if(props.acv > 0){
    if (props.acv > (10000 * currencyMultiplier)){
      acvDedicated = false;
    }
    else{
      acvDedicated = true;
    }

    for (var i = 0; i < props.supportList.length; i++) {
      if(props.acv > (props.supportList[i][2] * currencyMultiplier)){
        supportPackage = props.supportList[i][1];
      }
    }
  }

  function setImplementation(value){
    if(value === 'Dedicated'){
      props.implementationChange(1);
    }
    else{
      props.implementationChange(0);
    }
  }

  function setSupport(support){
    if(support === 'None' || support === 'DEFAULT'){
      setSupportCost(0);
      document.getElementById('supportWarning').style.display = 'none';
    }

    if(support === 'Regional'){
      setSupportCost(50);
      if(supportPackage === 'VIP' || supportPackage === 'Enterprise' || supportPackage === 'Professional' || supportPackage === 'Regional'){
        document.getElementById('supportWarning').style.display = 'block';
      }
      else{
        document.getElementById('supportWarning').style.display = 'none';
      }
    }
    if(support === 'Professional'){
      setSupportCost(700);
      if(supportPackage === 'VIP' || supportPackage === 'Enterprise' || supportPackage === 'Professional'){
        document.getElementById('supportWarning').style.display = 'block';
      }
      else{
        document.getElementById('supportWarning').style.display = 'none';
      }
    }
    if(support === 'Enterprise'){
      setSupportCost(1300);
      if(supportPackage === 'VIP' || supportPackage === 'Enterprise'){
        document.getElementById('supportWarning').style.display = 'block';
      }
      else{
        document.getElementById('supportWarning').style.display = 'none';
      }
    }
    if(support === 'VIP'){
      setSupportCost(2500);
      if(supportPackage === 'VIP'){
        document.getElementById('supportWarning').style.display = 'block';
      }
      else{
        document.getElementById('supportWarning').style.display = 'none';
      }
    }
  }

  return(
  <Card>
    <Row style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>
    <Col>
      <Card style={{background:"lightgrey"}}>
        Implementation Package
      </Card>
    </Col>

    <Col>
      <Card>
        <Form.Select defaultValue={'DEFAULT'} style = {{ width: '96%',marginLeft: '2%',background: '#ffffcc' }} size="sm" id="implementation" onChange={e => setImplementation(e.target.value)}>
        <option value="DEFAULT">None</option>
        <option disabled={acvDedicated} selected={props.implementation === 1}>Dedicated</option>
        </Form.Select>
      </Card>
    </Col>

    <Col>
      <Card>
        <div id='implementationField' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>
        {props.implementation === 1 &&
          <NumberFormat value={2000 * currencyMultiplier} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix}/>
        }
        {props.implementation !== 1 &&
          <NumberFormat value={0 * currencyMultiplier} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix}/>
        }
        </div>
      </Card>
    </Col>
  </Row>

  <Row style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>
    <Col>
      <Card style={{background:"lightgrey"}}>
        Additional Support Level
      </Card>
    </Col>

    <Col>
      <Card>
        <Form.Select defaultValue={'DEFAULT'} style = {{ width: '96%',marginLeft: '2%',background: '#ffffcc' }} size="sm" id="support" onChange={e => setSupport(e.target.value)}>
        <option value="DEFAULT">None</option>
        <option>Regional</option>
        <option>Professional</option>
        <option>Enterprise</option>
        <option>VIP</option>
        </Form.Select>
      </Card>
    </Col>

    <Col>
      <Card>
        <div id='supportField' style={{width:'96%',marginLeft:'2%',marginRight:'2%',textAlign: 'center'}}>
          <NumberFormat value={Math.round(supportCost * currencyMultiplier)} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix} onChange={props.supportChange(supportCost)}/>
        </div>
      </Card>
    </Col>
  </Row>

  <Row id="supportWarning" style={{width:'96%',marginLeft:'2%',marginRight:'2%',display:'none'}}>
    <div style={{color:'red'}}>Warning: Selected Support below Included Level. Are you sure ?	</div>
  </Row>

</Card>
);
}
