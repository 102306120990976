import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Share = (props) => {
    const [emails,setEmails] = useState('');
    let accountSalesforceId = props.accountSalesforceId;
    let accountInfo = props.accountInfo;
    let clientInfo = props.clientInfo;
    let goals = props.goals;
    let package1 = props.package1;
    let package2 = props.package2;
    let package3 = props.package3;
    let accountExecutive = props.accountExecutive;
    let solutionsConsultant = props.solutionsConsultant;
    const [error,setError] = useState('');
    const [id,setId] = useState('');
    const [expirationDate,setExpirationDate] = useState('');
    
    function shareData(){
        if(accountSalesforceId !== '' && package1.length > 0 && emails !== ''){
            const accountData = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({accountSalesforceId,emails,accountInfo,clientInfo,goals,package1,package2,package3,accountExecutive,solutionsConsultant,expirationDate}),
            }
            fetch('/api/sharedata', accountData).then(response => response.json()).then(function(result) {
                setError(result.error);
                setId(result.id);
            });
        }
        else{
            setError("Missing information");
        }
      }

    return(
        <>
            <TextField
                id="emailTextField"
                label="Emails"
                value= {emails}
                onChange={(e) => setEmails(e.target.value)}
                multiline
                rows={4}
                style={{width:'100%'}}
            />
            <br/><br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    label="Expiration date"
                    onChange={(newValue) => setExpirationDate(String(newValue))}
                />
            </LocalizationProvider>
            <br/><br/>
            <Button style={{background: '#08d1ff', color: 'white', borderRadius: '100px'}} onClick={shareData} >Share</Button>
            {(error !== '') &&
                <>
                    <br/>
                    {error}
                </>
            }
            {(id !== '') &&
                <p>See your opportunity under www.adjust.com/{id}</p>
            }
            <br/><br/>
        </>
    )

}