export const matrixDp = [
[8,420,2000,8500,30000000],
[9,450,2000,10500,33750000],
[10,500,4000,13000,37500000],
[11,550,4000,15500,41250000],
[12,600,6000,18000,45000000],
[13,650,6000,21000,48750000],
[14,700,8000,24500,52500000],
[15,750,8000,27500,56250000],
[16,800,10000,31000,60000000],
[17,850,10000,35000,63750000],
[18,900,12000,39000,67500000],
[19,950,14000,43000,71250000],
[20,1000,14000,47500,75000000],
[21,1050,16000,52000,78750000],
[22,1100,18000,56000,82500000],
[23,1150,20000,62000,86250000],
[24,1200,22000,66000,90000000],
[25,1250,22000,72000,93750000],
[26,1300,24000,78000,97500000],
[27,1350,26000,84000,101250000],
[28,1400,28000,90000,105000000],
[29,1450,30000,96000,108750000],
[30,1500,32000,100000,112500000],
[31,1550,34000,105000,116250000],
[32,1600,36000,115000,120000000],
[33,1650,38000,120000,123750000],
[34,1700,40000,130000,127500000],
[35,1750,42000,135000,131250000],
[36,1800,44000,145000,135000000],
[37,1850,46000,150000,138750000],
[38,1900,50000,160000,142500000],
[39,1950,52000,170000,146250000],
[40,2000,54000,175000,150000000],
[41,2050,56000,185000,153750000],
[42,2100,60000,195000,157500000],
[43,2150,62000,205000,161250000],
[44,2200,64000,210000,165000000],
[45,2250,68000,220000,168750000],
[46,2300,70000,230000,172500000],
[47,2350,72000,240000,176250000],
[48,2400,76000,250000,180000000],
[49,2450,78000,260000,183750000],
[50,2500,82000,270000,187500000],
[51,2550,84000,280000,191250000],
[52,2600,88000,295000,195000000],
[53,2650,90000,305000,198750000],
[54,2700,94000,315000,202500000],
[55,2750,96000,325000,206250000],
[56,2800,100000,340000,210000000],
[57,2850,100000,350000,213750000],
[58,2900,105000,360000,217500000],
[59,2950,110000,375000,221250000],
[60,3000,110000,385000,225000000],
[61,3050,115000,400000,228750000],
[62,3100,120000,410000,232500000],
[63,3150,120000,425000,236250000],
[64,3200,125000,435000,240000000],
[65,3250,130000,450000,243750000],
[66,3300,135000,465000,247500000],
[67,3350,135000,480000,251250000],
[68,3400,140000,490000,255000000],
[69,3450,145000,500000,258750000],
[70,3500,150000,520000,262500000],
[71,3550,150000,530000,266250000],
[72,3600,155000,550000,270000000],
[73,3650,160000,560000,273750000],
[74,3700,165000,580000,277500000],
[75,3750,170000,590000,281250000],
[76,3800,170000,610000,285000000],
[77,3850,175000,620000,288750000],
[78,3900,180000,640000,292500000],
[79,3950,185000,650000,296250000],
[80,4000,190000,670000,300000000],
[81,4050,195000,690000,303750000],
[82,4100,200000,700000,307500000],
[83,4150,205000,720000,311250000],
[84,4200,205000,740000,315000000],
[85,4250,210000,750000,318750000],
[86,4300,215000,770000,322500000],
[87,4350,220000,790000,326250000],
[88,4400,225000,810000,330000000],
[89,4450,230000,820000,333750000],
[90,4500,235000,840000,337500000],
[91,4550,240000,860000,341250000],
[92,4600,245000,880000,345000000],
[93,4650,250000,900000,348750000],
[94,4700,255000,920000,352500000],
[95,4750,260000,930000,356250000],
[96,4800,265000,950000,360000000],
[97,4850,270000,970000,363750000],
[98,4900,275000,990000,367500000],
[99,4950,280000,1000000,371250000],
[100,5000,285000,1000000,375000000],
[101,5050,290000,1050000,378750000],
[102,5100,295000,1050000,382500000],
[103,5150,300000,1050000,386250000],
[104,5200,305000,1100000,390000000],
[105,5250,310000,1100000,393750000],
[106,5300,315000,1150000,397500000],
[107,5350,320000,1150000,401250000],
[108,5400,330000,1200000,405000000],
[109,5450,335000,1200000,408750000],
[110,5500,340000,1200000,412500000],
[111,5550,345000,1250000,416250000],
[112,5600,350000,1250000,420000000],
[113,5650,355000,1300000,423750000],
[114,5700,360000,1300000,427500000],
[115,5750,370000,1350000,431250000],
[116,5800,375000,1350000,435000000],
[117,5850,380000,1400000,438750000],
[118,5900,385000,1400000,442500000],
[119,5950,390000,1400000,446250000],
[120,6000,395000,1450000,450000000],
[121,6050,405000,1450000,453750000],
[122,6100,410000,1500000,457500000],
[123,6150,415000,1500000,461250000],
[124,6200,420000,1550000,465000000],
[125,6250,430000,1550000,468750000],
[126,6300,435000,1600000,472500000],
[127,6350,440000,1600000,476250000],
[128,6400,445000,1650000,480000000],
[129,6450,455000,1650000,483750000],
[130,6500,460000,1700000,487500000],
[131,6550,465000,1700000,491250000],
[132,6600,475000,1750000,495000000],
[133,6650,480000,1750000,498750000],
[134,6700,485000,1800000,502500000],
[135,6750,490000,1800000,506250000],
[136,6800,500000,1850000,510000000],
[137,6850,500000,1900000,513750000],
[138,6900,510000,1900000,517500000],
[139,6950,520000,1950000,521250000],
[140,7000,520000,1950000,525000000],
[141,7050,530000,2000000,528750000],
[142,7100,540000,2000000,532500000],
[143,7150,540000,2050000,536250000],
[144,7200,550000,2050000,540000000],
[145,7250,560000,2100000,543750000],
[146,7300,570000,2100000,547500000],
[147,7350,570000,2150000,551250000],
[148,7400,580000,2200000,555000000],
[149,7450,590000,2200000,558750000],
[150,7500,590000,2250000,562500000],
[151,7550,600000,2250000,566250000],
[152,7600,610000,2300000,570000000],
[153,7650,620000,2350000,573750000],
[154,7700,620000,2350000,577500000],
[155,7750,630000,2400000,581250000],
[156,7800,640000,2400000,585000000],
[157,7850,650000,2450000,588750000],
[158,7900,650000,2500000,592500000],
[159,7950,660000,2500000,596250000],
[160,8000,670000,2550000,600000000],
[161,8050,680000,2550000,603750000],
[162,8100,680000,2600000,607500000],
[163,8150,690000,2650000,611250000],
[164,8200,700000,2650000,615000000],
[165,8250,710000,2700000,618750000],
[166,8300,710000,2750000,622500000],
[167,8350,720000,2750000,626250000],
[168,8400,730000,2800000,630000000],
[169,8450,740000,2800000,633750000],
[170,8500,750000,2850000,637500000],
[171,8550,750000,2900000,641250000],
[172,8600,760000,2900000,645000000],
[173,8650,770000,2950000,648750000],
[174,8700,780000,3000000,652500000],
[175,8750,790000,3000000,656250000],
[176,8800,800000,3050000,660000000],
[177,8850,800000,3100000,663750000],
[178,8900,810000,3100000,667500000],
[179,8950,820000,3150000,671250000],
[180,9000,830000,3200000,675000000],
[181,9050,840000,3250000,678750000],
[182,9100,850000,3250000,682500000],
[183,9150,850000,3300000,686250000],
[184,9200,860000,3350000,690000000],
[185,9250,870000,3350000,693750000],
[186,9300,880000,3400000,697500000],
[187,9350,890000,3450000,701250000],
[188,9400,900000,3450000,705000000],
[189,9450,910000,3500000,708750000],
[190,9500,910000,3550000,712500000],
[191,9550,920000,3600000,716250000],
[192,9600,930000,3600000,720000000],
[193,9650,940000,3650000,723750000],
[194,9700,950000,3700000,727500000],
[195,9750,960000,3750000,731250000],
[196,9800,970000,3750000,735000000],
[197,9850,980000,3800000,738750000],
[198,9900,990000,3850000,742500000],
[199,9950,990000,3900000,746250000],
[200,10000,1000000,3900000,750000000],
[201,10050,1000000,3950000,753750000],
[202,10100,1000000,4000000,757500000],
[203,10150,1000000,4050000,761250000],
[204,10200,1000000,4050000,765000000],
[205,10250,1050000,4100000,768750000],
[206,10300,1050000,4150000,772500000],
[207,10350,1050000,4200000,776250000],
[208,10400,1050000,4250000,780000000],
[209,10450,1050000,4250000,783750000],
[210,10500,1100000,4300000,787500000],
[211,10550,1100000,4350000,791250000],
[212,10600,1100000,4400000,795000000],
[213,10650,1100000,4450000,798750000],
[214,10700,1100000,4450000,802500000],
[215,10750,1150000,4500000,806250000],
[216,10800,1150000,4550000,810000000],
[217,10850,1150000,4600000,813750000],
[218,10900,1150000,4650000,817500000],
[219,10950,1150000,4650000,821250000],
[220,11000,1150000,4700000,825000000],
[221,11050,1200000,4750000,828750000],
[222,11100,1200000,4800000,832500000],
[223,11150,1200000,4850000,836250000],
[224,11200,1200000,4900000,840000000],
[225,11250,1200000,4900000,843750000],
[226,11300,1250000,4950000,847500000],
[227,11350,1250000,5000000,851250000],
[228,11400,1250000,5050000,855000000],
[229,11450,1250000,5100000,858750000],
[230,11500,1300000,5150000,862500000],
[231,11550,1300000,5200000,866250000],
[232,11600,1300000,5250000,870000000],
[233,11650,1300000,5250000,873750000],
[234,11700,1300000,5300000,877500000],
[235,11750,1350000,5350000,881250000],
[236,11800,1350000,5400000,885000000],
[237,11850,1350000,5450000,888750000],
[238,11900,1350000,5500000,892500000],
[239,11950,1350000,5550000,896250000],
[240,12000,1400000,5600000,900000000],
[241,12050,1400000,5650000,903750000],
[242,12100,1400000,5650000,907500000],
[243,12150,1400000,5700000,911250000],
[244,12200,1400000,5750000,915000000],
[245,12250,1450000,5800000,918750000],
[246,12300,1450000,5850000,922500000],
[247,12350,1450000,5900000,926250000],
[248,12400,1450000,5950000,930000000],
[249,12450,1500000,6000000,933750000],
[250,12500,1500000,6050000,937500000],
[251,12550,1500000,6100000,941250000],
[252,12600,1500000,6150000,945000000],
[253,12650,1500000,6200000,948750000],
[254,12700,1550000,6250000,952500000],
[255,12750,1550000,6300000,956250000],
[256,12800,1550000,6300000,960000000],
[257,12850,1550000,6350000,963750000],
[258,12900,1600000,6400000,967500000],
[259,12950,1600000,6450000,971250000],
[260,13000,1600000,6500000,975000000],
[261,13050,1600000,6550000,978750000],
[262,13100,1600000,6600000,982500000],
[263,13150,1650000,6650000,986250000],
[264,13200,1650000,6700000,990000000],
[265,13250,1650000,6750000,993750000],
[266,13300,1650000,6800000,997500000],
[267,13350,1700000,6850000,1001250000],
[268,13400,1700000,6900000,1005000000],
[269,13450,1700000,6950000,1008750000],
[270,13500,1700000,7000000,1012500000],
[271,13550,1750000,7050000,1016250000],
[272,13600,1750000,7100000,1020000000],
[273,13650,1750000,7150000,1023750000],
[274,13700,1750000,7200000,1027500000],
[275,13750,1750000,7250000,1031250000],
[276,13800,1800000,7300000,1035000000],
[277,13850,1800000,7350000,1038750000],
[278,13900,1800000,7400000,1042500000],
[279,13950,1800000,7450000,1046250000],
[280,14000,1850000,7500000,1050000000],
[281,14050,1850000,7600000,1053750000],
[282,14100,1850000,7650000,1057500000],
[283,14150,1850000,7700000,1061250000],
[284,14200,1900000,7750000,1065000000],
[285,14250,1900000,7800000,1068750000],
[286,14300,1900000,7850000,1072500000],
[287,14350,1900000,7900000,1076250000],
[288,14400,1950000,7950000,1080000000],
[289,14450,1950000,8000000,1083750000],
[290,14500,1950000,8050000,1087500000],
[291,14550,1950000,8100000,1091250000],
[292,14600,2000000,8150000,1095000000],
[293,14650,2000000,8200000,1098750000],
[294,14700,2000000,8250000,1102500000],
[295,14750,2000000,8300000,1106250000],
[296,14800,2050000,8400000,1110000000],
[297,14850,2050000,8450000,1113750000],
[298,14900,2050000,8500000,1117500000],
[299,14950,2050000,8550000,1121250000],
[300,15000,2100000,8600000,1125000000],
[301,15050,2100000,8650000,1128750000],
[302,15100,2100000,8700000,1132500000],
[303,15150,2100000,8750000,1136250000],
[304,15200,2150000,8800000,1140000000],
[305,15250,2150000,8900000,1143750000],
[306,15300,2150000,8950000,1147500000],
[307,15350,2150000,9000000,1151250000],
[308,15400,2200000,9050000,1155000000],
[309,15450,2200000,9100000,1158750000],
[310,15500,2200000,9150000,1162500000],
[311,15550,2200000,9200000,1166250000],
[312,15600,2250000,9300000,1170000000],
[313,15650,2250000,9350000,1173750000],
[314,15700,2250000,9400000,1177500000],
[315,15750,2300000,9450000,1181250000],
[316,15800,2300000,9500000,1185000000],
[317,15850,2300000,9550000,1188750000],
[318,15900,2300000,9650000,1192500000],
[319,15950,2350000,9700000,1196250000],
[320,16000,2350000,9750000,1200000000],
[321,16050,2350000,9800000,1203750000],
[322,16100,2350000,9850000,1207500000],
[323,16150,2400000,9900000,1211250000],
[324,16200,2400000,10000000,1215000000],
[325,16250,2400000,10000000,1218750000],
[326,16300,2400000,10100000,1222500000],
[327,16350,2450000,10100000,1226250000],
[328,16400,2450000,10200000,1230000000],
[329,16450,2450000,10300000,1233750000],
[330,16500,2500000,10300000,1237500000],
[331,16550,2500000,10400000,1241250000],
[332,16600,2500000,10400000,1245000000],
[333,16650,2500000,10500000,1248750000],
[334,16700,2550000,10600000,1252500000],
[335,16750,2550000,10600000,1256250000],
[336,16800,2550000,10700000,1260000000],
[337,16850,2600000,10700000,1263750000],
[338,16900,2600000,10800000,1267500000],
[339,16950,2600000,10900000,1271250000],
[340,17000,2600000,10900000,1275000000],
[341,17050,2650000,11000000,1278750000],
[342,17100,2650000,11100000,1282500000],
[343,17150,2650000,11100000,1286250000],
[344,17200,2650000,11200000,1290000000],
[345,17250,2700000,11200000,1293750000],
[346,17300,2700000,11300000,1297500000],
[347,17350,2700000,11400000,1301250000],
[348,17400,2750000,11400000,1305000000],
[349,17450,2750000,11500000,1308750000],
[350,17500,2750000,11600000,1312500000],
[351,17550,2750000,11600000,1316250000],
[352,17600,2800000,11700000,1320000000],
[353,17650,2800000,11800000,1323750000],
[354,17700,2800000,11800000,1327500000],
[355,17750,2850000,11900000,1331250000],
[356,17800,2850000,12000000,1335000000],
[357,17850,2850000,12000000,1338750000],
[358,17900,2900000,12100000,1342500000],
[359,17950,2900000,12100000,1346250000],
[360,18000,2900000,12200000,1350000000],
[361,18050,2900000,12300000,1353750000],
[362,18100,2950000,12300000,1357500000],
[363,18150,2950000,12400000,1361250000],
[364,18200,2950000,12500000,1365000000],
[365,18250,3000000,12500000,1368750000],
[366,18300,3000000,12600000,1372500000],
[367,18350,3000000,12700000,1376250000],
[368,18400,3050000,12700000,1380000000],
[369,18450,3050000,12800000,1383750000],
[370,18500,3050000,12900000,1387500000],
[371,18550,3050000,12900000,1391250000],
[372,18600,3100000,13000000,1395000000],
[373,18650,3100000,13100000,1398750000],
[374,18700,3100000,13200000,1402500000],
[375,18750,3150000,13200000,1406250000],
[376,18800,3150000,13300000,1410000000],
[377,18850,3150000,13400000,1413750000],
[378,18900,3200000,13400000,1417500000],
[379,18950,3200000,13500000,1421250000],
[380,19000,3200000,13600000,1425000000],
[381,19050,3200000,13600000,1428750000],
[382,19100,3250000,13700000,1432500000],
[383,19150,3250000,13800000,1436250000],
[384,19200,3250000,13800000,1440000000],
[385,19250,3300000,13900000,1443750000],
[386,19300,3300000,14000000,1447500000],
[387,19350,3300000,14100000,1451250000],
[388,19400,3350000,14100000,1455000000],
[389,19450,3350000,14200000,1458750000],
[390,19500,3350000,14300000,1462500000],
[391,19550,3400000,14300000,1466250000],
[392,19600,3400000,14400000,1470000000],
[393,19650,3400000,14500000,1473750000],
[394,19700,3450000,14500000,1477500000],
[395,19750,3450000,14600000,1481250000],
[396,19800,3450000,14700000,1485000000],
[397,19850,3500000,14800000,1488750000],
[398,19900,3500000,14800000,1492500000],
[399,19950,3500000,14900000,1496250000],
[400,20000,3500000,15000000,1500000000],
[401,20050,3550000,15100000,1503750000],
[402,20100,3550000,15100000,1507500000],
[403,20150,3550000,15200000,1511250000],
[404,20200,3600000,15300000,1515000000],
[405,20250,3600000,15300000,1518750000],
[406,20300,3600000,15400000,1522500000],
[407,20350,3650000,15500000,1526250000],
[408,20400,3650000,15600000,1530000000],
[409,20450,3650000,15600000,1533750000],
[410,20500,3700000,15700000,1537500000],
[411,20550,3700000,15800000,1541250000],
[412,20600,3700000,15900000,1545000000],
[413,20650,3750000,15900000,1548750000],
[414,20700,3750000,16000000,1552500000],
[415,20750,3750000,16100000,1556250000],
[416,20800,3800000,16200000,1560000000],
[417,20850,3800000,16200000,1563750000],
[418,20900,3800000,16300000,1567500000],
[419,20950,3850000,16400000,1571250000],
[420,21000,3850000,16500000,1575000000],
[421,21050,3850000,16500000,1578750000],
[422,21100,3900000,16600000,1582500000],
[423,21150,3900000,16700000,1586250000],
[424,21200,3900000,16800000,1590000000],
[425,21250,3950000,16800000,1593750000],
[426,21300,3950000,16900000,1597500000],
[427,21350,3950000,17000000,1601250000],
[428,21400,4000000,17100000,1605000000],
[429,21450,4000000,17200000,1608750000],
[430,21500,4000000,17200000,1612500000],
[431,21550,4050000,17300000,1616250000],
[432,21600,4050000,17400000,1620000000],
[433,21650,4050000,17500000,1623750000],
[434,21700,4100000,17500000,1627500000],
[435,21750,4100000,17600000,1631250000],
[436,21800,4150000,17700000,1635000000],
[437,21850,4150000,17800000,1638750000],
[438,21900,4150000,17900000,1642500000],
[439,21950,4200000,17900000,1646250000],
[440,22000,4200000,18000000,1650000000],
[441,22050,4200000,18100000,1653750000],
[442,22100,4250000,18200000,1657500000],
[443,22150,4250000,18300000,1661250000],
[444,22200,4250000,18300000,1665000000],
[445,22250,4300000,18400000,1668750000],
[446,22300,4300000,18500000,1672500000],
[447,22350,4300000,18600000,1676250000],
[448,22400,4350000,18700000,1680000000],
[449,22450,4350000,18700000,1683750000],
[450,22500,4350000,18800000,1687500000],
[451,22550,4400000,18900000,1691250000],
[452,22600,4400000,19000000,1695000000],
[453,22650,4400000,19100000,1698750000],
[454,22700,4450000,19100000,1702500000],
[455,22750,4450000,19200000,1706250000],
[456,22800,4500000,19300000,1710000000],
[457,22850,4500000,19400000,1713750000],
[458,22900,4500000,19500000,1717500000],
[459,22950,4550000,19600000,1721250000],
[460,23000,4550000,19600000,1725000000],
[461,23050,4550000,19700000,1728750000],
[462,23100,4600000,19800000,1732500000],
[463,23150,4600000,19900000,1736250000],
[464,23200,4600000,20000000,1740000000],
[465,23250,4650000,20000000,1743750000],
[466,23300,4650000,20000000,1747500000],
[467,23350,4700000,20000000,1751250000],
[468,23400,4700000,20000000,1755000000],
[469,23450,4700000,20000000,1758750000],
[470,23500,4750000,20500000,1762500000],
[471,23550,4750000,20500000,1766250000],
[472,23600,4750000,20500000,1770000000],
[473,23650,4800000,20500000,1773750000],
[474,23700,4800000,20500000,1777500000],
[475,23750,4800000,20500000,1781250000],
[476,23800,4850000,21000000,1785000000],
[477,23850,4850000,21000000,1788750000],
[478,23900,4900000,21000000,1792500000],
[479,23950,4900000,21000000,1796250000],
[480,24000,4900000,21000000,1800000000],
[481,24050,4950000,21000000,1803750000],
[482,24100,4950000,21500000,1807500000],
[483,24150,4950000,21500000,1811250000],
[484,24200,5000000,21500000,1815000000],
[485,24250,5000000,21500000,1818750000],
[486,24300,5050000,21500000,1822500000],
[487,24350,5050000,21500000,1826250000],
[488,24400,5050000,22000000,1830000000],
[489,24450,5100000,22000000,1833750000],
[490,24500,5100000,22000000,1837500000],
[491,24550,5150000,22000000,1841250000],
[492,24600,5150000,22000000,1845000000],
[493,24650,5150000,22500000,1848750000],
[494,24700,5200000,22500000,1852500000],
[495,24750,5200000,22500000,1856250000],
[496,24800,5200000,22500000,1860000000],
[497,24850,5250000,22500000,1863750000],
[498,24900,5250000,22500000,1867500000],
[499,24950,5300000,23000000,1871250000],
[500,25000,5300000,23000000,1875000000],
[501,25050,5300000,23000000,1878750000],
[502,25100,5350000,23000000,1882500000],
[503,25150,5350000,23000000,1886250000],
[504,25200,5400000,23000000,1890000000],
[505,25250,5400000,23500000,1893750000],
[506,25300,5400000,23500000,1897500000],
[507,25350,5450000,23500000,1901250000],
[508,25400,5450000,23500000,1905000000],
[509,25450,5450000,23500000,1908750000],
[510,25500,5500000,24000000,1912500000],
[511,25550,5500000,24000000,1916250000],
[512,25600,5550000,24000000,1920000000],
[513,25650,5550000,24000000,1923750000],
[514,25700,5550000,24000000,1927500000],
[515,25750,5600000,24000000,1931250000],
[516,25800,5600000,24500000,1935000000],
[517,25850,5650000,24500000,1938750000],
[518,25900,5650000,24500000,1942500000],
[519,25950,5650000,24500000,1946250000],
[520,26000,5700000,24500000,1950000000],
[521,26050,5700000,25000000,1953750000],
[522,26100,5750000,25000000,1957500000],
[523,26150,5750000,25000000,1961250000],
[524,26200,5750000,25000000,1965000000],
[525,26250,5800000,25000000,1968750000],
[526,26300,5800000,25500000,1972500000],
[527,26350,5850000,25500000,1976250000],
[528,26400,5850000,25500000,1980000000],
[529,26450,5850000,25500000,1983750000],
[530,26500,5900000,25500000,1987500000],
[531,26550,5900000,25500000,1991250000],
[532,26600,5950000,26000000,1995000000],
[533,26650,5950000,26000000,1998750000],
[534,26700,5950000,26000000,2002500000],
[535,26750,6000000,26000000,2006250000],
[536,26800,6000000,26000000,2010000000],
[537,26850,6050000,26500000,2013750000],
[538,26900,6050000,26500000,2017500000],
[539,26950,6100000,26500000,2021250000],
[540,27000,6100000,26500000,2025000000],
[541,27050,6100000,26500000,2028750000],
[542,27100,6150000,27000000,2032500000],
[543,27150,6150000,27000000,2036250000],
[544,27200,6200000,27000000,2040000000],
[545,27250,6200000,27000000,2043750000],
[546,27300,6200000,27000000,2047500000],
[547,27350,6250000,27500000,2051250000],
[548,27400,6250000,27500000,2055000000],
[549,27450,6300000,27500000,2058750000],
[550,27500,6300000,27500000,2062500000],
[551,27550,6350000,27500000,2066250000],
[552,27600,6350000,28000000,2070000000],
[553,27650,6350000,28000000,2073750000],
[554,27700,6400000,28000000,2077500000],
[555,27750,6400000,28000000,2081250000],
[556,27800,6450000,28000000,2085000000],
[557,27850,6450000,28000000,2088750000],
[558,27900,6450000,28500000,2092500000],
[559,27950,6500000,28500000,2096250000],
[560,28000,6500000,28500000,2100000000],
[561,28050,6550000,28500000,2103750000],
[562,28100,6550000,28500000,2107500000],
[563,28150,6600000,29000000,2111250000],
[564,28200,6600000,29000000,2115000000],
[565,28250,6600000,29000000,2118750000],
[566,28300,6650000,29000000,2122500000],
[567,28350,6650000,29000000,2126250000],
[568,28400,6700000,29500000,2130000000],
[569,28450,6700000,29500000,2133750000],
[570,28500,6750000,29500000,2137500000],
[571,28550,6750000,29500000,2141250000],
[572,28600,6750000,30000000,2145000000],
[573,28650,6800000,30000000,2148750000],
[574,28700,6800000,30000000,2152500000],
[575,28750,6850000,30000000,2156250000],
[576,28800,6850000,30000000,2160000000],
[577,28850,6900000,30500000,2163750000],
[578,28900,6900000,30500000,2167500000],
[579,28950,6900000,30500000,2171250000],
[580,29000,6950000,30500000,2175000000],
[581,29050,6950000,30500000,2178750000],
[582,29100,7000000,31000000,2182500000],
[583,29150,7000000,31000000,2186250000],
[584,29200,7050000,31000000,2190000000],
[585,29250,7050000,31000000,2193750000],
[586,29300,7100000,31000000,2197500000],
[587,29350,7100000,31500000,2201250000],
[588,29400,7100000,31500000,2205000000],
[589,29450,7150000,31500000,2208750000],
[590,29500,7150000,31500000,2212500000],
[591,29550,7200000,31500000,2216250000],
[592,29600,7200000,32000000,2220000000],
[593,29650,7250000,32000000,2223750000],
[594,29700,7250000,32000000,2227500000],
[595,29750,7300000,32000000,2231250000],
[596,29800,7300000,32000000,2235000000],
[597,29850,7300000,32500000,2238750000],
[598,29900,7350000,32500000,2242500000],
[599,29950,7350000,32500000,2246250000],
[600,30000,7400000,32500000,2250000000],
[601,30050,7400000,33000000,2253750000],
[602,30100,7450000,33000000,2257500000],
[603,30150,7450000,33000000,2261250000],
[604,30200,7500000,33000000,2265000000],
[605,30250,7500000,33000000,2268750000],
[606,30300,7500000,33500000,2272500000],
[607,30350,7550000,33500000,2276250000],
[608,30400,7550000,33500000,2280000000],
[609,30450,7600000,33500000,2283750000],
[610,30500,7600000,33500000,2287500000],
[611,30550,7650000,34000000,2291250000],
[612,30600,7650000,34000000,2295000000],
[613,30650,7700000,34000000,2298750000],
[614,30700,7700000,34000000,2302500000],
[615,30750,7750000,34500000,2306250000],
[616,30800,7750000,34500000,2310000000],
[617,30850,7750000,34500000,2313750000],
[618,30900,7800000,34500000,2317500000],
[619,30950,7800000,34500000,2321250000],
[620,31000,7850000,35000000,2325000000],
[621,31050,7850000,35000000,2328750000],
[622,31100,7900000,35000000,2332500000],
[623,31150,7900000,35000000,2336250000],
[624,31200,7950000,35000000,2340000000],
[625,31250,7950000,35500000,2343750000],
[626,31300,8000000,35500000,2347500000],
[627,31350,8000000,35500000,2351250000],
[628,31400,8050000,35500000,2355000000],
[629,31450,8050000,36000000,2358750000],
[630,31500,8100000,36000000,2362500000],
[631,31550,8100000,36000000,2366250000],
[632,31600,8100000,36000000,2370000000],
[633,31650,8150000,36000000,2373750000],
[634,31700,8150000,36500000,2377500000],
[635,31750,8200000,36500000,2381250000],
[636,31800,8200000,36500000,2385000000],
[637,31850,8250000,36500000,2388750000],
[638,31900,8250000,37000000,2392500000],
[639,31950,8300000,37000000,2396250000],
[640,32000,8300000,37000000,2400000000],
[641,32050,8350000,37000000,2403750000],
[642,32100,8350000,37500000,2407500000],
[643,32150,8400000,37500000,2411250000],
[644,32200,8400000,37500000,2415000000],
[645,32250,8450000,37500000,2418750000],
[646,32300,8450000,37500000,2422500000],
[647,32350,8500000,38000000,2426250000],
[648,32400,8500000,38000000,2430000000],
[649,32450,8500000,38000000,2433750000],
[650,32500,8550000,38000000,2437500000],
[651,32550,8550000,38500000,2441250000],
[652,32600,8600000,38500000,2445000000],
[653,32650,8600000,38500000,2448750000],
[654,32700,8650000,38500000,2452500000],
[655,32750,8650000,38500000,2456250000],
[656,32800,8700000,39000000,2460000000],
[657,32850,8700000,39000000,2463750000],
[658,32900,8750000,39000000,2467500000],
[659,32950,8750000,39000000,2471250000],
[660,33000,8800000,39500000,2475000000],
[661,33050,8800000,39500000,2478750000],
[662,33100,8850000,39500000,2482500000],
[663,33150,8850000,39500000,2486250000],
[664,33200,8900000,40000000,2490000000],
[665,33250,8900000,40000000,2493750000],
[666,33300,8950000,40000000,2497500000],
[667,33350,8950000,40000000,2501250000],
[668,33400,9000000,40000000,2505000000],
[669,33450,9000000,40500000,2508750000],
[670,33500,9050000,40500000,2512500000],
[671,33550,9050000,40500000,2516250000],
[672,33600,9100000,40500000,2520000000],
[673,33650,9100000,41000000,2523750000],
[674,33700,9150000,41000000,2527500000],
[675,33750,9150000,41000000,2531250000],
[676,33800,9200000,41000000,2535000000],
[677,33850,9200000,41500000,2538750000],
[678,33900,9250000,41500000,2542500000],
[679,33950,9250000,41500000,2546250000],
[680,34000,9300000,41500000,2550000000],
[681,34050,9300000,42000000,2553750000],
[682,34100,9350000,42000000,2557500000],
[683,34150,9350000,42000000,2561250000],
[684,34200,9400000,42000000,2565000000],
[685,34250,9400000,42500000,2568750000],
[686,34300,9450000,42500000,2572500000],
[687,34350,9450000,42500000,2576250000],
[688,34400,9500000,42500000,2580000000],
[689,34450,9500000,42500000,2583750000],
[690,34500,9550000,43000000,2587500000],
[691,34550,9550000,43000000,2591250000],
[692,34600,9600000,43000000,2595000000],
[693,34650,9600000,43000000,2598750000],
[694,34700,9650000,43500000,2602500000],
[695,34750,9650000,43500000,2606250000],
[696,34800,9700000,43500000,2610000000],
[697,34850,9700000,43500000,2613750000],
[698,34900,9750000,44000000,2617500000],
[699,34950,9750000,44000000,2621250000],
[700,35000,9800000,44000000,2625000000],
[701,35050,9800000,44000000,2628750000],
[702,35100,9850000,44500000,2632500000],
[703,35150,9850000,44500000,2636250000],
[704,35200,9900000,44500000,2640000000],
[705,35250,9900000,44500000,2643750000],
[706,35300,9950000,45000000,2647500000],
[707,35350,9950000,45000000,2651250000],
[708,35400,10000000,45000000,2655000000],
[709,35450,10000000,45000000,2658750000],
[710,35500,10000000,45500000,2662500000],
[711,35550,10000000,45500000,2666250000],
[712,35600,10000000,45500000,2670000000],
[713,35650,10000000,45500000,2673750000],
[714,35700,10000000,46000000,2677500000],
[715,35750,10000000,46000000,2681250000],
[716,35800,10000000,46000000,2685000000],
[717,35850,10000000,46000000,2688750000],
[718,35900,10000000,46500000,2692500000],
[719,35950,10000000,46500000,2696250000],
[720,36000,10000000,46500000,2700000000],
[721,36050,10000000,46500000,2703750000],
[722,36100,10000000,47000000,2707500000],
[723,36150,10000000,47000000,2711250000],
[724,36200,10000000,47000000,2715000000],
[725,36250,10000000,47000000,2718750000],
[726,36300,10000000,47500000,2722500000],
[727,36350,10500000,47500000,2726250000],
[728,36400,10500000,47500000,2730000000],
[729,36450,10500000,47500000,2733750000],
[730,36500,10500000,48000000,2737500000],
[731,36550,10500000,48000000,2741250000],
[732,36600,10500000,48000000,2745000000],
[733,36650,10500000,48000000,2748750000],
[734,36700,10500000,48500000,2752500000],
[735,36750,10500000,48500000,2756250000],
[736,36800,10500000,48500000,2760000000],
[737,36850,10500000,48500000,2763750000],
[738,36900,10500000,49000000,2767500000],
[739,36950,10500000,49000000,2771250000],
[740,37000,10500000,49000000,2775000000],
[741,37050,10500000,49000000,2778750000],
[742,37100,10500000,49500000,2782500000],
[743,37150,10500000,49500000,2786250000],
[744,37200,10500000,49500000,2790000000],
[745,37250,10500000,49500000,2793750000],
[746,37300,11000000,50000000,2797500000],
[747,37350,11000000,50000000,2801250000],
[748,37400,11000000,50000000,2805000000],
[749,37450,11000000,50500000,2808750000],
[750,37500,11000000,50500000,2812500000],
[751,37550,11000000,50500000,2816250000],
[752,37600,11000000,50500000,2820000000],
[753,37650,11000000,51000000,2823750000],
[754,37700,11000000,51000000,2827500000],
[755,37750,11000000,51000000,2831250000],
[756,37800,11000000,51000000,2835000000],
[757,37850,11000000,51500000,2838750000],
[758,37900,11000000,51500000,2842500000],
[759,37950,11000000,51500000,2846250000],
[760,38000,11000000,51500000,2850000000],
[761,38050,11000000,52000000,2853750000],
[762,38100,11000000,52000000,2857500000],
[763,38150,11000000,52000000,2861250000],
[764,38200,11000000,52000000,2865000000],
[765,38250,11500000,52500000,2868750000],
[766,38300,11500000,52500000,2872500000],
[767,38350,11500000,52500000,2876250000],
[768,38400,11500000,53000000,2880000000],
[769,38450,11500000,53000000,2883750000],
[770,38500,11500000,53000000,2887500000],
[771,38550,11500000,53000000,2891250000],
[772,38600,11500000,53500000,2895000000],
[773,38650,11500000,53500000,2898750000],
[774,38700,11500000,53500000,2902500000],
[775,38750,11500000,53500000,2906250000],
[776,38800,11500000,54000000,2910000000],
[777,38850,11500000,54000000,2913750000],
[778,38900,11500000,54000000,2917500000],
[779,38950,11500000,54500000,2921250000],
[780,39000,11500000,54500000,2925000000],
[781,39050,11500000,54500000,2928750000],
[782,39100,11500000,54500000,2932500000],
[783,39150,12000000,55000000,2936250000],
[784,39200,12000000,55000000,2940000000],
[785,39250,12000000,55000000,2943750000],
[786,39300,12000000,55000000,2947500000],
[787,39350,12000000,55500000,2951250000],
[788,39400,12000000,55500000,2955000000],
[789,39450,12000000,55500000,2958750000],
[790,39500,12000000,55500000,2962500000],
[791,39550,12000000,56000000,2966250000],
[792,39600,12000000,56000000,2970000000],
[793,39650,12000000,56000000,2973750000],
[794,39700,12000000,56500000,2977500000],
[795,39750,12000000,56500000,2981250000],
[796,39800,12000000,56500000,2985000000],
[797,39850,12000000,56500000,2988750000],
[798,39900,12000000,57000000,2992500000],
[799,39950,12000000,57000000,2996250000],
[800,40000,12500000,57000000,3000000000],
[801,40050,12500000,57500000,3003750000],
[802,40100,12500000,57500000,3007500000],
[803,40150,12500000,57500000,3011250000],
[804,40200,12500000,57500000,3015000000],
[805,40250,12500000,58000000,3018750000],
[806,40300,12500000,58000000,3022500000],
[807,40350,12500000,58000000,3026250000],
[808,40400,12500000,58000000,3030000000],
[809,40450,12500000,58500000,3033750000],
[810,40500,12500000,58500000,3037500000],
[811,40550,12500000,58500000,3041250000],
[812,40600,12500000,59000000,3045000000],
[813,40650,12500000,59000000,3048750000],
[814,40700,12500000,59000000,3052500000],
[815,40750,12500000,59000000,3056250000],
[816,40800,12500000,59500000,3060000000],
[817,40850,12500000,59500000,3063750000],
[818,40900,13000000,59500000,3067500000],
[819,40950,13000000,60000000,3071250000],
[820,41000,13000000,60000000,3075000000],
[821,41050,13000000,60000000,3078750000],
[822,41100,13000000,60000000,3082500000],
[823,41150,13000000,60500000,3086250000],
[824,41200,13000000,60500000,3090000000],
[825,41250,13000000,60500000,3093750000],
[826,41300,13000000,61000000,3097500000],
[827,41350,13000000,61000000,3101250000],
[828,41400,13000000,61000000,3105000000],
[829,41450,13000000,61000000,3108750000],
[830,41500,13000000,61500000,3112500000],
[831,41550,13000000,61500000,3116250000],
[832,41600,13000000,61500000,3120000000],
[833,41650,13000000,62000000,3123750000],
[834,41700,13000000,62000000,3127500000],
[835,41750,13500000,62000000,3131250000],
[836,41800,13500000,62000000,3135000000],
[837,41850,13500000,62500000,3138750000],
[838,41900,13500000,62500000,3142500000],
[839,41950,13500000,62500000,3146250000],
[840,42000,13500000,63000000,3150000000],
[841,42050,13500000,63000000,3153750000],
[842,42100,13500000,63000000,3157500000],
[843,42150,13500000,63000000,3161250000],
[844,42200,13500000,63500000,3165000000],
[845,42250,13500000,63500000,3168750000],
[846,42300,13500000,63500000,3172500000],
[847,42350,13500000,64000000,3176250000],
[848,42400,13500000,64000000,3180000000],
[849,42450,13500000,64000000,3183750000],
[850,42500,13500000,64500000,3187500000],
[851,42550,13500000,64500000,3191250000],
[852,42600,14000000,64500000,3195000000],
[853,42650,14000000,64500000,3198750000],
[854,42700,14000000,65000000,3202500000],
[855,42750,14000000,65000000,3206250000],
[856,42800,14000000,65000000,3210000000],
[857,42850,14000000,65500000,3213750000],
[858,42900,14000000,65500000,3217500000],
[859,42950,14000000,65500000,3221250000],
[860,43000,14000000,65500000,3225000000],
[861,43050,14000000,66000000,3228750000],
[862,43100,14000000,66000000,3232500000],
[863,43150,14000000,66000000,3236250000],
[864,43200,14000000,66500000,3240000000],
[865,43250,14000000,66500000,3243750000],
[866,43300,14000000,66500000,3247500000],
[867,43350,14000000,67000000,3251250000],
[868,43400,14500000,67000000,3255000000],
[869,43450,14500000,67000000,3258750000],
[870,43500,14500000,67000000,3262500000],
[871,43550,14500000,67500000,3266250000],
[872,43600,14500000,67500000,3270000000],
[873,43650,14500000,67500000,3273750000],
[874,43700,14500000,68000000,3277500000],
[875,43750,14500000,68000000,3281250000],
[876,43800,14500000,68000000,3285000000],
[877,43850,14500000,68500000,3288750000],
[878,43900,14500000,68500000,3292500000],
[879,43950,14500000,68500000,3296250000],
[880,44000,14500000,68500000,3300000000],
[881,44050,14500000,69000000,3303750000],
[882,44100,14500000,69000000,3307500000],
[883,44150,14500000,69000000,3311250000],
[884,44200,14500000,69500000,3315000000],
[885,44250,15000000,69500000,3318750000],
[886,44300,15000000,69500000,3322500000],
[887,44350,15000000,70000000,3326250000],
[888,44400,15000000,70000000,3330000000],
[889,44450,15000000,70000000,3333750000],
[890,44500,15000000,70500000,3337500000],
[891,44550,15000000,70500000,3341250000],
[892,44600,15000000,70500000,3345000000],
[893,44650,15000000,70500000,3348750000],
[894,44700,15000000,71000000,3352500000],
[895,44750,15000000,71000000,3356250000],
[896,44800,15000000,71000000,3360000000],
[897,44850,15000000,71500000,3363750000],
[898,44900,15000000,71500000,3367500000],
[899,44950,15000000,71500000,3371250000],
[900,45000,15000000,72000000,3375000000],
[901,45050,15500000,72000000,3378750000],
[902,45100,15500000,72000000,3382500000],
[903,45150,15500000,72500000,3386250000],
[904,45200,15500000,72500000,3390000000],
[905,45250,15500000,72500000,3393750000],
[906,45300,15500000,72500000,3397500000],
[907,45350,15500000,73000000,3401250000],
[908,45400,15500000,73000000,3405000000],
[909,45450,15500000,73000000,3408750000],
[910,45500,15500000,73500000,3412500000],
[911,45550,15500000,73500000,3416250000],
[912,45600,15500000,73500000,3420000000],
[913,45650,15500000,74000000,3423750000],
[914,45700,15500000,74000000,3427500000],
[915,45750,15500000,74000000,3431250000],
[916,45800,15500000,74500000,3435000000],
[917,45850,16000000,74500000,3438750000],
[918,45900,16000000,74500000,3442500000],
[919,45950,16000000,75000000,3446250000],
[920,46000,16000000,75000000,3450000000],
[921,46050,16000000,75000000,3453750000],
[922,46100,16000000,75000000,3457500000],
[923,46150,16000000,75500000,3461250000],
[924,46200,16000000,75500000,3465000000],
[925,46250,16000000,75500000,3468750000],
[926,46300,16000000,76000000,3472500000],
[927,46350,16000000,76000000,3476250000],
[928,46400,16000000,76000000,3480000000],
[929,46450,16000000,76500000,3483750000],
[930,46500,16000000,76500000,3487500000],
[931,46550,16000000,76500000,3491250000],
[932,46600,16500000,77000000,3495000000],
[933,46650,16500000,77000000,3498750000],
[934,46700,16500000,77000000,3502500000],
[935,46750,16500000,77500000,3506250000],
[936,46800,16500000,77500000,3510000000],
[937,46850,16500000,77500000,3513750000],
[938,46900,16500000,78000000,3517500000],
[939,46950,16500000,78000000,3521250000],
[940,47000,16500000,78000000,3525000000],
[941,47050,16500000,78500000,3528750000],
[942,47100,16500000,78500000,3532500000],
[943,47150,16500000,78500000,3536250000],
[944,47200,16500000,79000000,3540000000],
[945,47250,16500000,79000000,3543750000],
[946,47300,16500000,79000000,3547500000],
[947,47350,16500000,79000000,3551250000],
[948,47400,17000000,79500000,3555000000],
[949,47450,17000000,79500000,3558750000],
[950,47500,17000000,79500000,3562500000],
[951,47550,17000000,80000000,3566250000],
[952,47600,17000000,80000000,3570000000],
[953,47650,17000000,80000000,3573750000],
[954,47700,17000000,80500000,3577500000],
[955,47750,17000000,80500000,3581250000],
[956,47800,17000000,80500000,3585000000],
[957,47850,17000000,81000000,3588750000],
[958,47900,17000000,81000000,3592500000],
[959,47950,17000000,81000000,3596250000],
[960,48000,17000000,81500000,3600000000],
[961,48050,17000000,81500000,3603750000],
[962,48100,17000000,81500000,3607500000],
[963,48150,17500000,82000000,3611250000],
[964,48200,17500000,82000000,3615000000],
[965,48250,17500000,82000000,3618750000],
[966,48300,17500000,82500000,3622500000],
[967,48350,17500000,82500000,3626250000],
[968,48400,17500000,82500000,3630000000],
[969,48450,17500000,83000000,3633750000],
[970,48500,17500000,83000000,3637500000],
[971,48550,17500000,83000000,3641250000],
[972,48600,17500000,83500000,3645000000],
[973,48650,17500000,83500000,3648750000],
[974,48700,17500000,83500000,3652500000],
[975,48750,17500000,84000000,3656250000],
[976,48800,17500000,84000000,3660000000],
[977,48850,17500000,84000000,3663750000],
[978,48900,18000000,84500000,3667500000],
[979,48950,18000000,84500000,3671250000],
[980,49000,18000000,84500000,3675000000],
[981,49050,18000000,85000000,3678750000],
[982,49100,18000000,85000000,3682500000],
[983,49150,18000000,85000000,3686250000],
[984,49200,18000000,85500000,3690000000],
[985,49250,18000000,85500000,3693750000],
[986,49300,18000000,85500000,3697500000],
[987,49350,18000000,86000000,3701250000],
[988,49400,18000000,86000000,3705000000],
[989,49450,18000000,86000000,3708750000],
[990,49500,18000000,86500000,3712500000],
[991,49550,18000000,86500000,3716250000],
[992,49600,18000000,86500000,3720000000],
[993,49650,18500000,87000000,3723750000],
[994,49700,18500000,87000000,3727500000],
[995,49750,18500000,87000000,3731250000],
[996,49800,18500000,87500000,3735000000],
[997,49850,18500000,87500000,3738750000],
[998,49900,18500000,87500000,3742500000],
[999,49950,18500000,88000000,3746250000],
[1000,50000,18500000,88000000,3750000000],
[1001,50050,18500000,88000000,3753750000],
[1002,50100,18500000,88500000,3757500000],
[1003,50150,18500000,88500000,3761250000],
[1004,50200,18500000,89000000,3765000000],
[1005,50250,18500000,89000000,3768750000],
[1006,50300,18500000,89000000,3772500000],
[1007,50350,18500000,89500000,3776250000],
[1008,50400,19000000,89500000,3780000000],
[1009,50450,19000000,89500000,3783750000],
[1010,50500,19000000,90000000,3787500000],
[1011,50550,19000000,90000000,3791250000],
[1012,50600,19000000,90000000,3795000000],
[1013,50650,19000000,90500000,3798750000],
[1014,50700,19000000,90500000,3802500000],
[1015,50750,19000000,90500000,3806250000],
[1016,50800,19000000,91000000,3810000000],
[1017,50850,19000000,91000000,3813750000],
[1018,50900,19000000,91000000,3817500000],
[1019,50950,19000000,91500000,3821250000],
[1020,51000,19000000,91500000,3825000000],
[1021,51050,19000000,91500000,3828750000],
[1022,51100,19500000,92000000,3832500000],
[1023,51150,19500000,92000000,3836250000],
[1024,51200,19500000,92000000,3840000000],
[1025,51250,19500000,92500000,3843750000],
[1026,51300,19500000,92500000,3847500000],
[1027,51350,19500000,92500000,3851250000],
[1028,51400,19500000,93000000,3855000000],
[1029,51450,19500000,93000000,3858750000],
[1030,51500,19500000,93500000,3862500000],
[1031,51550,19500000,93500000,3866250000],
[1032,51600,19500000,93500000,3870000000],
[1033,51650,19500000,94000000,3873750000],
[1034,51700,19500000,94000000,3877500000],
[1035,51750,19500000,94000000,3881250000],
[1036,51800,20000000,94500000,3885000000],
[1037,51850,20000000,94500000,3888750000],
[1038,51900,20000000,94500000,3892500000],
[1039,51950,20000000,95000000,3896250000],
[1040,52000,20000000,95000000,3900000000],
[1041,52050,20000000,95000000,3903750000],
[1042,52100,20000000,95500000,3907500000],
[1043,52150,20000000,95500000,3911250000],
[1044,52200,20000000,95500000,3915000000],
[1045,52250,20000000,96000000,3918750000],
[1046,52300,20000000,96000000,3922500000],
[1047,52350,20000000,96500000,3926250000],
[1048,52400,20000000,96500000,3930000000],
[1049,52450,20000000,96500000,3933750000],
[1050,52500,20000000,97000000,3937500000],
[1051,52550,20500000,97000000,3941250000],
[1052,52600,20500000,97000000,3945000000],
[1053,52650,20500000,97500000,3948750000],
[1054,52700,20500000,97500000,3952500000],
[1055,52750,20500000,97500000,3956250000],
[1056,52800,20500000,98000000,3960000000],
[1057,52850,20500000,98000000,3963750000],
[1058,52900,20500000,98000000,3967500000],
[1059,52950,20500000,98500000,3971250000],
[1060,53000,20500000,98500000,3975000000],
[1061,53050,20500000,99000000,3978750000],
[1062,53100,20500000,99000000,3982500000],
[1063,53150,20500000,99000000,3986250000],
[1064,53200,20500000,99500000,3990000000],
[1065,53250,21000000,99500000,3993750000],
[1066,53300,21000000,99500000,3997500000],
[1067,53350,21000000,100000000,4001250000],
[1068,53400,21000000,100000000,4005000000],
[1069,53450,21000000,100000000,4008750000],
[1070,53500,21000000,100000000,4012500000],
[1071,53550,21000000,100000000,4016250000],
[1072,53600,21000000,101000000,4020000000],
[1073,53650,21000000,101000000,4023750000],
[1074,53700,21000000,101000000,4027500000],
[1075,53750,21000000,101000000,4031250000],
[1076,53800,21000000,101000000,4035000000],
[1077,53850,21000000,101000000,4038750000],
[1078,53900,21000000,102000000,4042500000],
[1079,53950,21500000,102000000,4046250000],
[1080,54000,21500000,102000000,4050000000],
[1081,54050,21500000,102000000,4053750000],
[1082,54100,21500000,102000000,4057500000],
[1083,54150,21500000,103000000,4061250000],
[1084,54200,21500000,103000000,4065000000],
[1085,54250,21500000,103000000,4068750000],
[1086,54300,21500000,103000000,4072500000],
[1087,54350,21500000,103000000,4076250000],
[1088,54400,21500000,103000000,4080000000],
[1089,54450,21500000,104000000,4083750000],
[1090,54500,21500000,104000000,4087500000],
[1091,54550,21500000,104000000,4091250000],
[1092,54600,22000000,104000000,4095000000],
[1093,54650,22000000,104000000,4098750000],
[1094,54700,22000000,105000000,4102500000],
[1095,54750,22000000,105000000,4106250000],
[1096,54800,22000000,105000000,4110000000],
[1097,54850,22000000,105000000,4113750000],
[1098,54900,22000000,105000000,4117500000],
[1099,54950,22000000,106000000,4121250000],
[1100,55000,22000000,106000000,4125000000],
[1101,55050,22000000,106000000,4128750000],
[1102,55100,22000000,106000000,4132500000],
[1103,55150,22000000,106000000,4136250000],
[1104,55200,22000000,106000000,4140000000],
[1105,55250,22000000,107000000,4143750000],
[1106,55300,22500000,107000000,4147500000],
[1107,55350,22500000,107000000,4151250000],
[1108,55400,22500000,107000000,4155000000],
[1109,55450,22500000,107000000,4158750000],
[1110,55500,22500000,108000000,4162500000],
[1111,55550,22500000,108000000,4166250000],
[1112,55600,22500000,108000000,4170000000],
[1113,55650,22500000,108000000,4173750000],
[1114,55700,22500000,108000000,4177500000],
[1115,55750,22500000,109000000,4181250000],
[1116,55800,22500000,109000000,4185000000],
[1117,55850,22500000,109000000,4188750000],
[1118,55900,22500000,109000000,4192500000],
[1119,55950,23000000,109000000,4196250000],
[1120,56000,23000000,109000000,4200000000],
[1121,56050,23000000,110000000,4203750000],
[1122,56100,23000000,110000000,4207500000],
[1123,56150,23000000,110000000,4211250000],
[1124,56200,23000000,110000000,4215000000],
[1125,56250,23000000,110000000,4218750000],
[1126,56300,23000000,111000000,4222500000],
[1127,56350,23000000,111000000,4226250000],
[1128,56400,23000000,111000000,4230000000],
[1129,56450,23000000,111000000,4233750000],
[1130,56500,23000000,111000000,4237500000],
[1131,56550,23000000,112000000,4241250000],
[1132,56600,23000000,112000000,4245000000],
[1133,56650,23500000,112000000,4248750000],
[1134,56700,23500000,112000000,4252500000],
[1135,56750,23500000,112000000,4256250000],
[1136,56800,23500000,113000000,4260000000],
[1137,56850,23500000,113000000,4263750000],
[1138,56900,23500000,113000000,4267500000],
[1139,56950,23500000,113000000,4271250000],
[1140,57000,23500000,113000000,4275000000],
[1141,57050,23500000,113000000,4278750000],
[1142,57100,23500000,114000000,4282500000],
[1143,57150,23500000,114000000,4286250000],
[1144,57200,23500000,114000000,4290000000],
[1145,57250,23500000,114000000,4293750000],
[1146,57300,24000000,114000000,4297500000],
[1147,57350,24000000,115000000,4301250000],
[1148,57400,24000000,115000000,4305000000],
[1149,57450,24000000,115000000,4308750000],
[1150,57500,24000000,115000000,4312500000],
[1151,57550,24000000,115000000,4316250000],
[1152,57600,24000000,116000000,4320000000],
[1153,57650,24000000,116000000,4323750000],
[1154,57700,24000000,116000000,4327500000],
[1155,57750,24000000,116000000,4331250000],
[1156,57800,24000000,116000000,4335000000],
[1157,57850,24000000,117000000,4338750000],
[1158,57900,24000000,117000000,4342500000],
[1159,57950,24500000,117000000,4346250000],
[1160,58000,24500000,117000000,4350000000],
[1161,58050,24500000,117000000,4353750000],
[1162,58100,24500000,118000000,4357500000],
[1163,58150,24500000,118000000,4361250000],
[1164,58200,24500000,118000000,4365000000],
[1165,58250,24500000,118000000,4368750000],
[1166,58300,24500000,118000000,4372500000],
[1167,58350,24500000,119000000,4376250000],
[1168,58400,24500000,119000000,4380000000],
[1169,58450,24500000,119000000,4383750000],
[1170,58500,24500000,119000000,4387500000],
[1171,58550,24500000,119000000,4391250000],
[1172,58600,25000000,120000000,4395000000],
[1173,58650,25000000,120000000,4398750000],
[1174,58700,25000000,120000000,4402500000],
[1175,58750,25000000,120000000,4406250000],
[1176,58800,25000000,120000000,4410000000],
[1177,58850,25000000,121000000,4413750000],
[1178,58900,25000000,121000000,4417500000],
[1179,58950,25000000,121000000,4421250000],
[1180,59000,25000000,121000000,4425000000],
[1181,59050,25000000,121000000,4428750000],
[1182,59100,25000000,122000000,4432500000],
[1183,59150,25000000,122000000,4436250000],
[1184,59200,25000000,122000000,4440000000],
[1185,59250,25500000,122000000,4443750000],
[1186,59300,25500000,122000000,4447500000],
[1187,59350,25500000,123000000,4451250000],
[1188,59400,25500000,123000000,4455000000],
[1189,59450,25500000,123000000,4458750000],
[1190,59500,25500000,123000000,4462500000],
[1191,59550,25500000,123000000,4466250000],
[1192,59600,25500000,124000000,4470000000],
[1193,59650,25500000,124000000,4473750000],
[1194,59700,25500000,124000000,4477500000],
[1195,59750,25500000,124000000,4481250000],
[1196,59800,25500000,124000000,4485000000],
[1197,59850,26000000,125000000,4488750000],
[1198,59900,26000000,125000000,4492500000],
[1199,59950,26000000,125000000,4496250000],
[1200,60000,26000000,125000000,4500000000],
[1201,60050,26000000,125000000,4503750000],
[1202,60100,26000000,126000000,4507500000],
[1203,60150,26000000,126000000,4511250000],
[1204,60200,26000000,126000000,4515000000],
[1205,60250,26000000,126000000,4518750000],
[1206,60300,26000000,126000000,4522500000],
[1207,60350,26000000,127000000,4526250000],
[1208,60400,26000000,127000000,4530000000],
[1209,60450,26000000,127000000,4533750000],
[1210,60500,26500000,127000000,4537500000],
[1211,60550,26500000,127000000,4541250000],
[1212,60600,26500000,128000000,4545000000],
[1213,60650,26500000,128000000,4548750000],
[1214,60700,26500000,128000000,4552500000],
[1215,60750,26500000,128000000,4556250000],
[1216,60800,26500000,128000000,4560000000],
[1217,60850,26500000,129000000,4563750000],
[1218,60900,26500000,129000000,4567500000],
[1219,60950,26500000,129000000,4571250000],
[1220,61000,26500000,129000000,4575000000],
[1221,61050,26500000,129000000,4578750000],
[1222,61100,26500000,130000000,4582500000],
[1223,61150,27000000,130000000,4586250000],
[1224,61200,27000000,130000000,4590000000],
[1225,61250,27000000,130000000,4593750000],
[1226,61300,27000000,130000000,4597500000],
[1227,61350,27000000,131000000,4601250000],
[1228,61400,27000000,131000000,4605000000],
[1229,61450,27000000,131000000,4608750000],
[1230,61500,27000000,131000000,4612500000],
[1231,61550,27000000,132000000,4616250000],
[1232,61600,27000000,132000000,4620000000],
[1233,61650,27000000,132000000,4623750000],
[1234,61700,27000000,132000000,4627500000],
[1235,61750,27500000,132000000,4631250000],
[1236,61800,27500000,133000000,4635000000],
[1237,61850,27500000,133000000,4638750000],
[1238,61900,27500000,133000000,4642500000],
[1239,61950,27500000,133000000,4646250000],
[1240,62000,27500000,133000000,4650000000],
[1241,62050,27500000,134000000,4653750000],
[1242,62100,27500000,134000000,4657500000],
[1243,62150,27500000,134000000,4661250000],
[1244,62200,27500000,134000000,4665000000],
[1245,62250,27500000,134000000,4668750000],
[1246,62300,27500000,135000000,4672500000],
[1247,62350,28000000,135000000,4676250000],
[1248,62400,28000000,135000000,4680000000],
[1249,62450,28000000,135000000,4683750000],
[1250,62500,28000000,135000000,4687500000],
[1251,62550,28000000,136000000,4691250000],
[1252,62600,28000000,136000000,4695000000],
[1253,62650,28000000,136000000,4698750000],
[1254,62700,28000000,136000000,4702500000],
[1255,62750,28000000,137000000,4706250000],
[1256,62800,28000000,137000000,4710000000],
[1257,62850,28000000,137000000,4713750000],
[1258,62900,28000000,137000000,4717500000],
[1259,62950,28500000,137000000,4721250000],
[1260,63000,28500000,138000000,4725000000],
[1261,63050,28500000,138000000,4728750000],
[1262,63100,28500000,138000000,4732500000],
[1263,63150,28500000,138000000,4736250000],
[1264,63200,28500000,138000000,4740000000],
[1265,63250,28500000,139000000,4743750000],
[1266,63300,28500000,139000000,4747500000],
[1267,63350,28500000,139000000,4751250000],
[1268,63400,28500000,139000000,4755000000],
[1269,63450,28500000,139000000,4758750000],
[1270,63500,28500000,140000000,4762500000],
[1271,63550,28500000,140000000,4766250000],
[1272,63600,29000000,140000000,4770000000],
[1273,63650,29000000,140000000,4773750000],
[1274,63700,29000000,141000000,4777500000],
[1275,63750,29000000,141000000,4781250000],
[1276,63800,29000000,141000000,4785000000],
[1277,63850,29000000,141000000,4788750000],
[1278,63900,29000000,141000000,4792500000],
[1279,63950,29000000,142000000,4796250000],
[1280,64000,29000000,142000000,4800000000],
[1281,64050,29000000,142000000,4803750000],
[1282,64100,29000000,142000000,4807500000],
[1283,64150,29000000,142000000,4811250000],
[1284,64200,29500000,143000000,4815000000],
[1285,64250,29500000,143000000,4818750000],
[1286,64300,29500000,143000000,4822500000],
[1287,64350,29500000,143000000,4826250000],
[1288,64400,29500000,144000000,4830000000],
[1289,64450,29500000,144000000,4833750000],
[1290,64500,29500000,144000000,4837500000],
[1291,64550,29500000,144000000,4841250000],
[1292,64600,29500000,144000000,4845000000],
[1293,64650,29500000,145000000,4848750000],
[1294,64700,29500000,145000000,4852500000],
[1295,64750,30000000,145000000,4856250000],
[1296,64800,30000000,145000000,4860000000],
[1297,64850,30000000,146000000,4863750000],
[1298,64900,30000000,146000000,4867500000],
[1299,64950,30000000,146000000,4871250000],
[1300,65000,30000000,146000000,4875000000],
[1301,65050,30000000,146000000,4878750000],
[1302,65100,30000000,147000000,4882500000],
[1303,65150,30000000,147000000,4886250000],
[1304,65200,30000000,147000000,4890000000],
[1305,65250,30000000,147000000,4893750000],
[1306,65300,30000000,147000000,4897500000],
[1307,65350,30500000,148000000,4901250000],
[1308,65400,30500000,148000000,4905000000],
[1309,65450,30500000,148000000,4908750000],
[1310,65500,30500000,148000000,4912500000],
[1311,65550,30500000,149000000,4916250000],
[1312,65600,30500000,149000000,4920000000],
[1313,65650,30500000,149000000,4923750000],
[1314,65700,30500000,149000000,4927500000],
[1315,65750,30500000,149000000,4931250000],
[1316,65800,30500000,150000000,4935000000],
[1317,65850,30500000,150000000,4938750000],
[1318,65900,30500000,150000000,4942500000],
[1319,65950,31000000,150000000,4946250000],
[1320,66000,31000000,151000000,4950000000],
[1321,66050,31000000,151000000,4953750000],
[1322,66100,31000000,151000000,4957500000],
[1323,66150,31000000,151000000,4961250000],
[1324,66200,31000000,151000000,4965000000],
[1325,66250,31000000,152000000,4968750000],
[1326,66300,31000000,152000000,4972500000],
[1327,66350,31000000,152000000,4976250000],
[1328,66400,31000000,152000000,4980000000],
[1329,66450,31000000,153000000,4983750000],
[1330,66500,31000000,153000000,4987500000],
[1331,66550,31500000,153000000,4991250000],
[1332,66600,31500000,153000000,4995000000],
[1333,66650,31500000,153000000,4998750000],
[1334,66700,31500000,154000000,5002500000],
[1335,66750,31500000,154000000,5006250000],
[1336,66800,31500000,154000000,5010000000],
[1337,66850,31500000,154000000,5013750000],
[1338,66900,31500000,155000000,5017500000],
[1339,66950,31500000,155000000,5021250000],
[1340,67000,31500000,155000000,5025000000],
[1341,67050,31500000,155000000,5028750000],
[1342,67100,32000000,155000000,5032500000],
[1343,67150,32000000,156000000,5036250000],
[1344,67200,32000000,156000000,5040000000],
[1345,67250,32000000,156000000,5043750000],
[1346,67300,32000000,156000000,5047500000],
[1347,67350,32000000,157000000,5051250000],
[1348,67400,32000000,157000000,5055000000],
[1349,67450,32000000,157000000,5058750000],
[1350,67500,32000000,157000000,5062500000],
[1351,67550,32000000,158000000,5066250000],
[1352,67600,32000000,158000000,5070000000],
[1353,67650,32000000,158000000,5073750000],
[1354,67700,32500000,158000000,5077500000],
[1355,67750,32500000,158000000,5081250000],
[1356,67800,32500000,159000000,5085000000],
[1357,67850,32500000,159000000,5088750000],
[1358,67900,32500000,159000000,5092500000],
[1359,67950,32500000,159000000,5096250000],
[1360,68000,32500000,160000000,5100000000],
[1361,68050,32500000,160000000,5103750000],
[1362,68100,32500000,160000000,5107500000],
[1363,68150,32500000,160000000,5111250000],
[1364,68200,32500000,160000000,5115000000],
[1365,68250,33000000,161000000,5118750000],
[1366,68300,33000000,161000000,5122500000],
[1367,68350,33000000,161000000,5126250000],
[1368,68400,33000000,161000000,5130000000],
[1369,68450,33000000,162000000,5133750000],
[1370,68500,33000000,162000000,5137500000],
[1371,68550,33000000,162000000,5141250000],
[1372,68600,33000000,162000000,5145000000],
[1373,68650,33000000,163000000,5148750000],
[1374,68700,33000000,163000000,5152500000],
[1375,68750,33000000,163000000,5156250000],
[1376,68800,33500000,163000000,5160000000],
[1377,68850,33500000,163000000,5163750000],
[1378,68900,33500000,164000000,5167500000],
[1379,68950,33500000,164000000,5171250000],
[1380,69000,33500000,164000000,5175000000],
[1381,69050,33500000,164000000,5178750000],
[1382,69100,33500000,165000000,5182500000],
[1383,69150,33500000,165000000,5186250000],
[1384,69200,33500000,165000000,5190000000],
[1385,69250,33500000,165000000,5193750000],
[1386,69300,33500000,166000000,5197500000],
[1387,69350,33500000,166000000,5201250000],
[1388,69400,34000000,166000000,5205000000],
[1389,69450,34000000,166000000,5208750000],
[1390,69500,34000000,166000000,5212500000],
[1391,69550,34000000,167000000,5216250000],
[1392,69600,34000000,167000000,5220000000],
[1393,69650,34000000,167000000,5223750000],
[1394,69700,34000000,167000000,5227500000],
[1395,69750,34000000,168000000,5231250000],
[1396,69800,34000000,168000000,5235000000],
[1397,69850,34000000,168000000,5238750000],
[1398,69900,34000000,168000000,5242500000],
[1399,69950,34500000,169000000,5246250000],
[1400,70000,34500000,169000000,5250000000],
[1401,70050,34500000,169000000,5253750000],
[1402,70100,34500000,169000000,5257500000],
[1403,70150,34500000,169000000,5261250000],
[1404,70200,34500000,170000000,5265000000],
[1405,70250,34500000,170000000,5268750000],
[1406,70300,34500000,170000000,5272500000],
[1407,70350,34500000,170000000,5276250000],
[1408,70400,34500000,171000000,5280000000],
[1409,70450,34500000,171000000,5283750000],
[1410,70500,35000000,171000000,5287500000],
[1411,70550,35000000,171000000,5291250000],
[1412,70600,35000000,172000000,5295000000],
[1413,70650,35000000,172000000,5298750000],
[1414,70700,35000000,172000000,5302500000],
[1415,70750,35000000,172000000,5306250000],
[1416,70800,35000000,173000000,5310000000],
[1417,70850,35000000,173000000,5313750000],
[1418,70900,35000000,173000000,5317500000],
[1419,70950,35000000,173000000,5321250000],
[1420,71000,35000000,173000000,5325000000],
[1421,71050,35500000,174000000,5328750000],
[1422,71100,35500000,174000000,5332500000],
[1423,71150,35500000,174000000,5336250000],
[1424,71200,35500000,174000000,5340000000],
[1425,71250,35500000,175000000,5343750000],
[1426,71300,35500000,175000000,5347500000],
[1427,71350,35500000,175000000,5351250000],
[1428,71400,35500000,175000000,5355000000],
[1429,71450,35500000,176000000,5358750000],
[1430,71500,35500000,176000000,5362500000],
[1431,71550,35500000,176000000,5366250000],
[1432,71600,36000000,176000000,5370000000],
[1433,71650,36000000,177000000,5373750000],
[1434,71700,36000000,177000000,5377500000],
[1435,71750,36000000,177000000,5381250000],
[1436,71800,36000000,177000000,5385000000],
[1437,71850,36000000,178000000,5388750000],
[1438,71900,36000000,178000000,5392500000],
[1439,71950,36000000,178000000,5396250000],
[1440,72000,36000000,178000000,5400000000],
[1441,72050,36000000,178000000,5403750000],
[1442,72100,36000000,179000000,5407500000],
[1443,72150,36500000,179000000,5411250000],
[1444,72200,36500000,179000000,5415000000],
[1445,72250,36500000,179000000,5418750000],
[1446,72300,36500000,180000000,5422500000],
[1447,72350,36500000,180000000,5426250000],
[1448,72400,36500000,180000000,5430000000],
[1449,72450,36500000,180000000,5433750000],
[1450,72500,36500000,181000000,5437500000],
[1451,72550,36500000,181000000,5441250000],
[1452,72600,36500000,181000000,5445000000],
[1453,72650,36500000,181000000,5448750000],
[1454,72700,37000000,182000000,5452500000],
[1455,72750,37000000,182000000,5456250000],
[1456,72800,37000000,182000000,5460000000],
[1457,72850,37000000,182000000,5463750000],
[1458,72900,37000000,183000000,5467500000],
[1459,72950,37000000,183000000,5471250000],
[1460,73000,37000000,183000000,5475000000],
[1461,73050,37000000,183000000,5478750000],
[1462,73100,37000000,184000000,5482500000],
[1463,73150,37000000,184000000,5486250000],
[1464,73200,37000000,184000000,5490000000],
[1465,73250,37500000,184000000,5493750000],
[1466,73300,37500000,185000000,5497500000],
[1467,73350,37500000,185000000,5501250000],
[1468,73400,37500000,185000000,5505000000],
[1469,73450,37500000,185000000,5508750000],
[1470,73500,37500000,186000000,5512500000],
[1471,73550,37500000,186000000,5516250000],
[1472,73600,37500000,186000000,5520000000],
[1473,73650,37500000,186000000,5523750000],
[1474,73700,37500000,187000000,5527500000],
[1475,73750,38000000,187000000,5531250000],
[1476,73800,38000000,187000000,5535000000],
[1477,73850,38000000,187000000,5538750000],
[1478,73900,38000000,187000000,5542500000],
[1479,73950,38000000,188000000,5546250000],
[1480,74000,38000000,188000000,5550000000],
[1481,74050,38000000,188000000,5553750000],
[1482,74100,38000000,188000000,5557500000],
[1483,74150,38000000,189000000,5561250000],
[1484,74200,38000000,189000000,5565000000],
[1485,74250,38000000,189000000,5568750000],
[1486,74300,38500000,189000000,5572500000],
[1487,74350,38500000,190000000,5576250000],
[1488,74400,38500000,190000000,5580000000],
[1489,74450,38500000,190000000,5583750000],
[1490,74500,38500000,190000000,5587500000],
[1491,74550,38500000,191000000,5591250000],
[1492,74600,38500000,191000000,5595000000],
[1493,74650,38500000,191000000,5598750000],
[1494,74700,38500000,191000000,5602500000],
[1495,74750,38500000,192000000,5606250000],
[1496,74800,38500000,192000000,5610000000],
[1497,74850,39000000,192000000,5613750000],
[1498,74900,39000000,192000000,5617500000],
[1499,74950,39000000,193000000,5621250000],
[1500,75000,39000000,193000000,5625000000],
[1501,75050,39000000,193000000,5628750000],
[1502,75100,39000000,193000000,5632500000],
[1503,75150,39000000,194000000,5636250000],
[1504,75200,39000000,194000000,5640000000],
[1505,75250,39000000,194000000,5643750000],
[1506,75300,39000000,194000000,5647500000],
[1507,75350,39500000,195000000,5651250000],
[1508,75400,39500000,195000000,5655000000],
[1509,75450,39500000,195000000,5658750000],
[1510,75500,39500000,195000000,5662500000],
[1511,75550,39500000,196000000,5666250000],
[1512,75600,39500000,196000000,5670000000],
[1513,75650,39500000,196000000,5673750000],
[1514,75700,39500000,196000000,5677500000],
[1515,75750,39500000,197000000,5681250000],
[1516,75800,39500000,197000000,5685000000],
[1517,75850,39500000,197000000,5688750000],
[1518,75900,40000000,197000000,5692500000],
[1519,75950,40000000,198000000,5696250000],
[1520,76000,40000000,198000000,5700000000],
[1521,76050,40000000,198000000,5703750000],
[1522,76100,40000000,198000000,5707500000],
[1523,76150,40000000,199000000,5711250000],
[1524,76200,40000000,199000000,5715000000],
[1525,76250,40000000,199000000,5718750000],
[1526,76300,40000000,199000000,5722500000],
[1527,76350,40000000,200000000,5726250000],
[1528,76400,40500000,200000000,5730000000],
[1529,76450,40500000,200000000,5733750000],
[1530,76500,40500000,200000000,5737500000],
[1531,76550,40500000,201000000,5741250000],
[1532,76600,40500000,201000000,5745000000],
[1533,76650,40500000,201000000,5748750000],
[1534,76700,40500000,202000000,5752500000],
[1535,76750,40500000,202000000,5756250000],
[1536,76800,40500000,202000000,5760000000],
[1537,76850,40500000,202000000,5763750000],
[1538,76900,41000000,203000000,5767500000],
[1539,76950,41000000,203000000,5771250000],
[1540,77000,41000000,203000000,5775000000],
[1541,77050,41000000,203000000,5778750000],
[1542,77100,41000000,204000000,5782500000],
[1543,77150,41000000,204000000,5786250000],
[1544,77200,41000000,204000000,5790000000],
[1545,77250,41000000,204000000,5793750000],
[1546,77300,41000000,205000000,5797500000],
[1547,77350,41000000,205000000,5801250000],
[1548,77400,41000000,205000000,5805000000],
[1549,77450,41500000,205000000,5808750000],
[1550,77500,41500000,206000000,5812500000],
[1551,77550,41500000,206000000,5816250000],
[1552,77600,41500000,206000000,5820000000],
[1553,77650,41500000,206000000,5823750000],
[1554,77700,41500000,207000000,5827500000],
[1555,77750,41500000,207000000,5831250000],
[1556,77800,41500000,207000000,5835000000],
[1557,77850,41500000,207000000,5838750000],
[1558,77900,41500000,208000000,5842500000],
[1559,77950,42000000,208000000,5846250000],
[1560,78000,42000000,208000000,5850000000],
[1561,78050,42000000,208000000,5853750000],
[1562,78100,42000000,209000000,5857500000],
[1563,78150,42000000,209000000,5861250000],
[1564,78200,42000000,209000000,5865000000],
[1565,78250,42000000,209000000,5868750000],
[1566,78300,42000000,210000000,5872500000],
[1567,78350,42000000,210000000,5876250000],
[1568,78400,42000000,210000000,5880000000],
[1569,78450,42500000,211000000,5883750000],
[1570,78500,42500000,211000000,5887500000],
[1571,78550,42500000,211000000,5891250000],
[1572,78600,42500000,211000000,5895000000],
[1573,78650,42500000,212000000,5898750000],
[1574,78700,42500000,212000000,5902500000],
[1575,78750,42500000,212000000,5906250000],
[1576,78800,42500000,212000000,5910000000],
[1577,78850,42500000,213000000,5913750000],
[1578,78900,42500000,213000000,5917500000],
[1579,78950,43000000,213000000,5921250000],
[1580,79000,43000000,213000000,5925000000],
[1581,79050,43000000,214000000,5928750000],
[1582,79100,43000000,214000000,5932500000],
[1583,79150,43000000,214000000,5936250000],
[1584,79200,43000000,214000000,5940000000],
[1585,79250,43000000,215000000,5943750000],
[1586,79300,43000000,215000000,5947500000],
[1587,79350,43000000,215000000,5951250000],
[1588,79400,43000000,215000000,5955000000],
[1589,79450,43500000,216000000,5958750000],
[1590,79500,43500000,216000000,5962500000],
[1591,79550,43500000,216000000,5966250000],
[1592,79600,43500000,217000000,5970000000],
[1593,79650,43500000,217000000,5973750000],
[1594,79700,43500000,217000000,5977500000],
[1595,79750,43500000,217000000,5981250000],
[1596,79800,43500000,218000000,5985000000],
[1597,79850,43500000,218000000,5988750000],
[1598,79900,43500000,218000000,5992500000],
[1599,79950,44000000,218000000,5996250000],
[1600,80000,44000000,219000000,6000000000],
[1601,80050,44000000,219000000,6003750000],
[1602,80100,44000000,219000000,6007500000],
[1603,80150,44000000,219000000,6011250000],
[1604,80200,44000000,220000000,6015000000],
[1605,80250,44000000,220000000,6018750000],
[1606,80300,44000000,220000000,6022500000],
[1607,80350,44000000,221000000,6026250000],
[1608,80400,44000000,221000000,6030000000],
[1609,80450,44500000,221000000,6033750000],
[1610,80500,44500000,221000000,6037500000],
[1611,80550,44500000,222000000,6041250000],
[1612,80600,44500000,222000000,6045000000],
[1613,80650,44500000,222000000,6048750000],
[1614,80700,44500000,222000000,6052500000],
[1615,80750,44500000,223000000,6056250000],
[1616,80800,44500000,223000000,6060000000],
[1617,80850,44500000,223000000,6063750000],
[1618,80900,44500000,223000000,6067500000],
[1619,80950,45000000,224000000,6071250000],
[1620,81000,45000000,224000000,6075000000],
[1621,81050,45000000,224000000,6078750000],
[1622,81100,45000000,225000000,6082500000],
[1623,81150,45000000,225000000,6086250000],
[1624,81200,45000000,225000000,6090000000],
[1625,81250,45000000,225000000,6093750000],
[1626,81300,45000000,226000000,6097500000],
[1627,81350,45000000,226000000,6101250000],
[1628,81400,45000000,226000000,6105000000],
[1629,81450,45500000,226000000,6108750000],
[1630,81500,45500000,227000000,6112500000],
[1631,81550,45500000,227000000,6116250000],
[1632,81600,45500000,227000000,6120000000],
[1633,81650,45500000,227000000,6123750000],
[1634,81700,45500000,228000000,6127500000],
[1635,81750,45500000,228000000,6131250000],
[1636,81800,45500000,228000000,6135000000],
[1637,81850,45500000,229000000,6138750000],
[1638,81900,45500000,229000000,6142500000],
[1639,81950,46000000,229000000,6146250000],
[1640,82000,46000000,229000000,6150000000],
[1641,82050,46000000,230000000,6153750000],
[1642,82100,46000000,230000000,6157500000],
[1643,82150,46000000,230000000,6161250000],
[1644,82200,46000000,230000000,6165000000],
[1645,82250,46000000,231000000,6168750000],
[1646,82300,46000000,231000000,6172500000],
[1647,82350,46000000,231000000,6176250000],
[1648,82400,46000000,232000000,6180000000],
[1649,82450,46500000,232000000,6183750000],
[1650,82500,46500000,232000000,6187500000],
[1651,82550,46500000,232000000,6191250000],
[1652,82600,46500000,233000000,6195000000],
[1653,82650,46500000,233000000,6198750000],
[1654,82700,46500000,233000000,6202500000],
[1655,82750,46500000,233000000,6206250000],
[1656,82800,46500000,234000000,6210000000],
[1657,82850,46500000,234000000,6213750000],
[1658,82900,47000000,234000000,6217500000],
[1659,82950,47000000,235000000,6221250000],
[1660,83000,47000000,235000000,6225000000],
[1661,83050,47000000,235000000,6228750000],
[1662,83100,47000000,235000000,6232500000],
[1663,83150,47000000,236000000,6236250000],
[1664,83200,47000000,236000000,6240000000],
[1665,83250,47000000,236000000,6243750000],
[1666,83300,47000000,236000000,6247500000],
[1667,83350,47000000,237000000,6251250000],
[1668,83400,47500000,237000000,6255000000],
[1669,83450,47500000,237000000,6258750000],
[1670,83500,47500000,238000000,6262500000],
[1671,83550,47500000,238000000,6266250000],
[1672,83600,47500000,238000000,6270000000],
[1673,83650,47500000,238000000,6273750000],
[1674,83700,47500000,239000000,6277500000],
[1675,83750,47500000,239000000,6281250000],
[1676,83800,47500000,239000000,6285000000],
[1677,83850,47500000,239000000,6288750000],
[1678,83900,48000000,240000000,6292500000],
[1679,83950,48000000,240000000,6296250000],
[1680,84000,48000000,240000000,6300000000],
[1681,84050,48000000,241000000,6303750000],
[1682,84100,48000000,241000000,6307500000],
[1683,84150,48000000,241000000,6311250000],
[1684,84200,48000000,241000000,6315000000],
[1685,84250,48000000,242000000,6318750000],
[1686,84300,48000000,242000000,6322500000],
[1687,84350,48500000,242000000,6326250000],
[1688,84400,48500000,243000000,6330000000],
[1689,84450,48500000,243000000,6333750000],
[1690,84500,48500000,243000000,6337500000],
[1691,84550,48500000,243000000,6341250000],
[1692,84600,48500000,244000000,6345000000],
[1693,84650,48500000,244000000,6348750000],
[1694,84700,48500000,244000000,6352500000],
[1695,84750,48500000,245000000,6356250000],
[1696,84800,48500000,245000000,6360000000],
[1697,84850,49000000,245000000,6363750000],
[1698,84900,49000000,245000000,6367500000],
[1699,84950,49000000,246000000,6371250000],
[1700,85000,49000000,246000000,6375000000],
[1701,85050,49000000,246000000,6378750000],
[1702,85100,49000000,246000000,6382500000],
[1703,85150,49000000,247000000,6386250000],
[1704,85200,49000000,247000000,6390000000],
[1705,85250,49000000,247000000,6393750000],
[1706,85300,49500000,248000000,6397500000],
[1707,85350,49500000,248000000,6401250000],
[1708,85400,49500000,248000000,6405000000],
[1709,85450,49500000,248000000,6408750000],
[1710,85500,49500000,249000000,6412500000],
[1711,85550,49500000,249000000,6416250000],
[1712,85600,49500000,249000000,6420000000],
[1713,85650,49500000,250000000,6423750000],
[1714,85700,49500000,250000000,6427500000],
[1715,85750,49500000,250000000,6431250000],
[1716,85800,50000000,250000000,6435000000],
[1717,85850,50000000,251000000,6438750000],
[1718,85900,50000000,251000000,6442500000],
[1719,85950,50000000,251000000,6446250000],
[1720,86000,50000000,252000000,6450000000],
[1721,86050,50000000,252000000,6453750000],
[1722,86100,50000000,252000000,6457500000],
[1723,86150,50000000,252000000,6461250000],
[1724,86200,50000000,253000000,6465000000],
[1725,86250,50500000,253000000,6468750000],
[1726,86300,50500000,253000000,6472500000],
[1727,86350,50500000,254000000,6476250000],
[1728,86400,50500000,254000000,6480000000],
[1729,86450,50500000,254000000,6483750000],
[1730,86500,50500000,254000000,6487500000],
[1731,86550,50500000,255000000,6491250000],
[1732,86600,50500000,255000000,6495000000],
[1733,86650,50500000,255000000,6498750000],
[1734,86700,51000000,256000000,6502500000],
[1735,86750,51000000,256000000,6506250000],
[1736,86800,51000000,256000000,6510000000],
[1737,86850,51000000,256000000,6513750000],
[1738,86900,51000000,257000000,6517500000],
[1739,86950,51000000,257000000,6521250000],
[1740,87000,51000000,257000000,6525000000],
[1741,87050,51000000,258000000,6528750000],
[1742,87100,51000000,258000000,6532500000],
[1743,87150,51000000,258000000,6536250000],
[1744,87200,51500000,258000000,6540000000],
[1745,87250,51500000,259000000,6543750000],
[1746,87300,51500000,259000000,6547500000],
[1747,87350,51500000,259000000,6551250000],
[1748,87400,51500000,260000000,6555000000],
[1749,87450,51500000,260000000,6558750000],
[1750,87500,51500000,260000000,6562500000],
[1751,87550,51500000,260000000,6566250000],
[1752,87600,51500000,261000000,6570000000],
[1753,87650,52000000,261000000,6573750000],
[1754,87700,52000000,261000000,6577500000],
[1755,87750,52000000,262000000,6581250000],
[1756,87800,52000000,262000000,6585000000],
[1757,87850,52000000,262000000,6588750000],
[1758,87900,52000000,262000000,6592500000],
[1759,87950,52000000,263000000,6596250000],
[1760,88000,52000000,263000000,6600000000],
[1761,88050,52000000,263000000,6603750000],
[1762,88100,52500000,264000000,6607500000],
[1763,88150,52500000,264000000,6611250000],
[1764,88200,52500000,264000000,6615000000],
[1765,88250,52500000,264000000,6618750000],
[1766,88300,52500000,265000000,6622500000],
[1767,88350,52500000,265000000,6626250000],
[1768,88400,52500000,265000000,6630000000],
[1769,88450,52500000,266000000,6633750000],
[1770,88500,52500000,266000000,6637500000],
[1771,88550,52500000,266000000,6641250000],
[1772,88600,53000000,266000000,6645000000],
[1773,88650,53000000,267000000,6648750000],
[1774,88700,53000000,267000000,6652500000],
[1775,88750,53000000,267000000,6656250000],
[1776,88800,53000000,268000000,6660000000],
[1777,88850,53000000,268000000,6663750000],
[1778,88900,53000000,268000000,6667500000],
[1779,88950,53000000,269000000,6671250000],
[1780,89000,53000000,269000000,6675000000],
[1781,89050,53500000,269000000,6678750000],
[1782,89100,53500000,269000000,6682500000],
[1783,89150,53500000,270000000,6686250000],
[1784,89200,53500000,270000000,6690000000],
[1785,89250,53500000,270000000,6693750000],
[1786,89300,53500000,271000000,6697500000],
[1787,89350,53500000,271000000,6701250000],
[1788,89400,53500000,271000000,6705000000],
[1789,89450,53500000,271000000,6708750000],
[1790,89500,54000000,272000000,6712500000],
[1791,89550,54000000,272000000,6716250000],
[1792,89600,54000000,272000000,6720000000],
[1793,89650,54000000,273000000,6723750000],
[1794,89700,54000000,273000000,6727500000],
[1795,89750,54000000,273000000,6731250000],
[1796,89800,54000000,274000000,6735000000],
[1797,89850,54000000,274000000,6738750000],
[1798,89900,54000000,274000000,6742500000],
[1799,89950,54500000,274000000,6746250000],
[1800,90000,54500000,275000000,6750000000],
[1801,90050,54500000,275000000,6753750000],
[1802,90100,54500000,275000000,6757500000],
[1803,90150,54500000,276000000,6761250000],
[1804,90200,54500000,276000000,6765000000],
[1805,90250,54500000,276000000,6768750000],
[1806,90300,54500000,276000000,6772500000],
[1807,90350,54500000,277000000,6776250000],
[1808,90400,55000000,277000000,6780000000],
[1809,90450,55000000,277000000,6783750000],
[1810,90500,55000000,278000000,6787500000],
[1811,90550,55000000,278000000,6791250000],
[1812,90600,55000000,278000000,6795000000],
[1813,90650,55000000,279000000,6798750000],
[1814,90700,55000000,279000000,6802500000],
[1815,90750,55000000,279000000,6806250000],
[1816,90800,55000000,279000000,6810000000],
[1817,90850,55500000,280000000,6813750000],
[1818,90900,55500000,280000000,6817500000],
[1819,90950,55500000,280000000,6821250000],
[1820,91000,55500000,281000000,6825000000],
[1821,91050,55500000,281000000,6828750000],
[1822,91100,55500000,281000000,6832500000],
[1823,91150,55500000,282000000,6836250000],
[1824,91200,55500000,282000000,6840000000],
[1825,91250,55500000,282000000,6843750000],
[1826,91300,56000000,282000000,6847500000],
[1827,91350,56000000,283000000,6851250000],
[1828,91400,56000000,283000000,6855000000],
[1829,91450,56000000,283000000,6858750000],
[1830,91500,56000000,284000000,6862500000],
[1831,91550,56000000,284000000,6866250000],
[1832,91600,56000000,284000000,6870000000],
[1833,91650,56000000,285000000,6873750000],
[1834,91700,56000000,285000000,6877500000],
[1835,91750,56500000,285000000,6881250000],
[1836,91800,56500000,285000000,6885000000],
[1837,91850,56500000,286000000,6888750000],
[1838,91900,56500000,286000000,6892500000],
[1839,91950,56500000,286000000,6896250000],
[1840,92000,56500000,287000000,6900000000],
[1841,92050,56500000,287000000,6903750000],
[1842,92100,56500000,287000000,6907500000],
[1843,92150,56500000,288000000,6911250000],
[1844,92200,57000000,288000000,6915000000],
[1845,92250,57000000,288000000,6918750000],
[1846,92300,57000000,288000000,6922500000],
[1847,92350,57000000,289000000,6926250000],
[1848,92400,57000000,289000000,6930000000],
[1849,92450,57000000,289000000,6933750000],
[1850,92500,57000000,290000000,6937500000],
[1851,92550,57000000,290000000,6941250000],
[1852,92600,57000000,290000000,6945000000],
[1853,92650,57500000,291000000,6948750000],
[1854,92700,57500000,291000000,6952500000],
[1855,92750,57500000,291000000,6956250000],
[1856,92800,57500000,291000000,6960000000],
[1857,92850,57500000,292000000,6963750000],
[1858,92900,57500000,292000000,6967500000],
[1859,92950,57500000,292000000,6971250000],
[1860,93000,57500000,293000000,6975000000],
[1861,93050,57500000,293000000,6978750000],
[1862,93100,58000000,293000000,6982500000],
[1863,93150,58000000,294000000,6986250000],
[1864,93200,58000000,294000000,6990000000],
[1865,93250,58000000,294000000,6993750000],
[1866,93300,58000000,295000000,6997500000],
[1867,93350,58000000,295000000,7001250000],
[1868,93400,58000000,295000000,7005000000],
[1869,93450,58000000,295000000,7008750000],
[1870,93500,58500000,296000000,7012500000],
[1871,93550,58500000,296000000,7016250000],
[1872,93600,58500000,296000000,7020000000],
[1873,93650,58500000,297000000,7023750000],
[1874,93700,58500000,297000000,7027500000],
[1875,93750,58500000,297000000,7031250000],
[1876,93800,58500000,298000000,7035000000],
[1877,93850,58500000,298000000,7038750000],
[1878,93900,58500000,298000000,7042500000],
[1879,93950,59000000,299000000,7046250000],
[1880,94000,59000000,299000000,7050000000],
[1881,94050,59000000,299000000,7053750000],
[1882,94100,59000000,299000000,7057500000],
[1883,94150,59000000,300000000,7061250000],
[1884,94200,59000000,300000000,7065000000],
[1885,94250,59000000,300000000,7068750000],
[1886,94300,59000000,301000000,7072500000],
[1887,94350,59000000,301000000,7076250000],
[1888,94400,59500000,301000000,7080000000],
[1889,94450,59500000,302000000,7083750000],
[1890,94500,59500000,302000000,7087500000],
[1891,94550,59500000,302000000,7091250000],
[1892,94600,59500000,303000000,7095000000],
[1893,94650,59500000,303000000,7098750000],
[1894,94700,59500000,303000000,7102500000],
[1895,94750,59500000,303000000,7106250000],
[1896,94800,59500000,304000000,7110000000],
[1897,94850,60000000,304000000,7113750000],
[1898,94900,60000000,304000000,7117500000],
[1899,94950,60000000,305000000,7121250000],
[1900,95000,60000000,305000000,7125000000],
[1901,95050,60000000,305000000,7128750000],
[1902,95100,60000000,306000000,7132500000],
[1903,95150,60000000,306000000,7136250000],
[1904,95200,60000000,306000000,7140000000],
[1905,95250,60500000,307000000,7143750000],
[1906,95300,60500000,307000000,7147500000],
[1907,95350,60500000,307000000,7151250000],
[1908,95400,60500000,308000000,7155000000],
[1909,95450,60500000,308000000,7158750000],
[1910,95500,60500000,308000000,7162500000],
[1911,95550,60500000,308000000,7166250000],
[1912,95600,60500000,309000000,7170000000],
[1913,95650,60500000,309000000,7173750000],
[1914,95700,61000000,309000000,7177500000],
[1915,95750,61000000,310000000,7181250000],
[1916,95800,61000000,310000000,7185000000],
[1917,95850,61000000,310000000,7188750000],
[1918,95900,61000000,311000000,7192500000],
[1919,95950,61000000,311000000,7196250000],
[1920,96000,61000000,311000000,7200000000],
[1921,96050,61000000,312000000,7203750000],
[1922,96100,61000000,312000000,7207500000],
[1923,96150,61500000,312000000,7211250000],
[1924,96200,61500000,313000000,7215000000],
[1925,96250,61500000,313000000,7218750000],
[1926,96300,61500000,313000000,7222500000],
[1927,96350,61500000,313000000,7226250000],
[1928,96400,61500000,314000000,7230000000],
[1929,96450,61500000,314000000,7233750000],
[1930,96500,61500000,314000000,7237500000],
[1931,96550,62000000,315000000,7241250000],
[1932,96600,62000000,315000000,7245000000],
[1933,96650,62000000,315000000,7248750000],
[1934,96700,62000000,316000000,7252500000],
[1935,96750,62000000,316000000,7256250000],
[1936,96800,62000000,316000000,7260000000],
[1937,96850,62000000,317000000,7263750000],
[1938,96900,62000000,317000000,7267500000],
[1939,96950,62000000,317000000,7271250000],
[1940,97000,62500000,318000000,7275000000],
[1941,97050,62500000,318000000,7278750000],
[1942,97100,62500000,318000000,7282500000],
[1943,97150,62500000,319000000,7286250000],
[1944,97200,62500000,319000000,7290000000],
[1945,97250,62500000,319000000,7293750000],
[1946,97300,62500000,319000000,7297500000],
[1947,97350,62500000,320000000,7301250000],
[1948,97400,63000000,320000000,7305000000],
[1949,97450,63000000,320000000,7308750000],
[1950,97500,63000000,321000000,7312500000],
[1951,97550,63000000,321000000,7316250000],
[1952,97600,63000000,321000000,7320000000],
[1953,97650,63000000,322000000,7323750000],
[1954,97700,63000000,322000000,7327500000],
[1955,97750,63000000,322000000,7331250000],
[1956,97800,63000000,323000000,7335000000],
[1957,97850,63500000,323000000,7338750000],
[1958,97900,63500000,323000000,7342500000],
[1959,97950,63500000,324000000,7346250000],
[1960,98000,63500000,324000000,7350000000],
[1961,98050,63500000,324000000,7353750000],
[1962,98100,63500000,325000000,7357500000],
[1963,98150,63500000,325000000,7361250000],
[1964,98200,63500000,325000000,7365000000],
[1965,98250,64000000,326000000,7368750000],
[1966,98300,64000000,326000000,7372500000],
[1967,98350,64000000,326000000,7376250000],
[1968,98400,64000000,327000000,7380000000],
[1969,98450,64000000,327000000,7383750000],
[1970,98500,64000000,327000000,7387500000],
[1971,98550,64000000,327000000,7391250000],
[1972,98600,64000000,328000000,7395000000],
[1973,98650,64000000,328000000,7398750000],
[1974,98700,64500000,328000000,7402500000],
[1975,98750,64500000,329000000,7406250000],
[1976,98800,64500000,329000000,7410000000],
[1977,98850,64500000,329000000,7413750000],
[1978,98900,64500000,330000000,7417500000],
[1979,98950,64500000,330000000,7421250000],
[1980,99000,64500000,330000000,7425000000],
[1981,99050,64500000,331000000,7428750000],
[1982,99100,65000000,331000000,7432500000],
[1983,99150,65000000,331000000,7436250000],
[1984,99200,65000000,332000000,7440000000],
[1985,99250,65000000,332000000,7443750000],
[1986,99300,65000000,332000000,7447500000],
[1987,99350,65000000,333000000,7451250000],
[1988,99400,65000000,333000000,7455000000],
[1989,99450,65000000,333000000,7458750000],
[1990,99500,65500000,334000000,7462500000],
[1991,99550,65500000,334000000,7466250000],
[1992,99600,65500000,334000000,7470000000],
[1993,99650,65500000,335000000,7473750000],
[1994,99700,65500000,335000000,7477500000],
[1995,99750,65500000,335000000,7481250000],
[1996,99800,65500000,336000000,7485000000],
[1997,99850,65500000,336000000,7488750000],
[1998,99900,65500000,336000000,7492500000],
[1999,99950,66000000,337000000,7496250000],
[2000,100000,66000000,337000000,7500000000],
[2001,100050,66000000,337000000,7503750000],
[2002,100100,66000000,338000000,7507500000],
[2003,100150,66000000,338000000,7511250000],
[2004,100200,66000000,338000000,7515000000],
[2005,100250,66000000,339000000,7518750000],
[2006,100300,66000000,339000000,7522500000],
[2007,100350,66500000,339000000,7526250000],
[2008,100400,66500000,340000000,7530000000],
[2009,100450,66500000,340000000,7533750000],
[2010,100500,66500000,340000000,7537500000],
[2011,100550,66500000,340000000,7541250000],
[2012,100600,66500000,341000000,7545000000],
[2013,100650,66500000,341000000,7548750000],
[2014,100700,66500000,341000000,7552500000],
[2015,100750,67000000,342000000,7556250000],
[2016,100800,67000000,342000000,7560000000],
[2017,100850,67000000,342000000,7563750000],
[2018,100900,67000000,343000000,7567500000],
[2019,100950,67000000,343000000,7571250000],
[2020,101000,67000000,343000000,7575000000],
[2021,101050,67000000,344000000,7578750000],
[2022,101100,67000000,344000000,7582500000],
[2023,101150,67000000,344000000,7586250000],
[2024,101200,67500000,345000000,7590000000],
[2025,101250,67500000,345000000,7593750000],
[2026,101300,67500000,345000000,7597500000],
[2027,101350,67500000,346000000,7601250000],
[2028,101400,67500000,346000000,7605000000],
[2029,101450,67500000,346000000,7608750000],
[2030,101500,67500000,347000000,7612500000],
[2031,101550,67500000,347000000,7616250000],
[2032,101600,68000000,347000000,7620000000],
[2033,101650,68000000,348000000,7623750000],
[2034,101700,68000000,348000000,7627500000],
[2035,101750,68000000,348000000,7631250000],
[2036,101800,68000000,349000000,7635000000],
[2037,101850,68000000,349000000,7638750000],
[2038,101900,68000000,349000000,7642500000],
[2039,101950,68000000,350000000,7646250000],
[2040,102000,68500000,350000000,7650000000],
[2041,102050,68500000,350000000,7653750000],
[2042,102100,68500000,351000000,7657500000],
[2043,102150,68500000,351000000,7661250000],
[2044,102200,68500000,351000000,7665000000],
[2045,102250,68500000,352000000,7668750000],
[2046,102300,68500000,352000000,7672500000],
[2047,102350,68500000,352000000,7676250000],
[2048,102400,69000000,353000000,7680000000],
[2049,102450,69000000,353000000,7683750000],
[2050,102500,69000000,353000000,7687500000],
[2051,102550,69000000,354000000,7691250000],
[2052,102600,69000000,354000000,7695000000],
[2053,102650,69000000,354000000,7698750000],
[2054,102700,69000000,355000000,7702500000],
[2055,102750,69000000,355000000,7706250000],
[2056,102800,69500000,355000000,7710000000],
[2057,102850,69500000,356000000,7713750000],
[2058,102900,69500000,356000000,7717500000],
[2059,102950,69500000,356000000,7721250000],
[2060,103000,69500000,357000000,7725000000],
[2061,103050,69500000,357000000,7728750000],
[2062,103100,69500000,357000000,7732500000],
[2063,103150,69500000,358000000,7736250000],
[2064,103200,70000000,358000000,7740000000],
[2065,103250,70000000,358000000,7743750000],
[2066,103300,70000000,359000000,7747500000],
[2067,103350,70000000,359000000,7751250000],
[2068,103400,70000000,359000000,7755000000],
[2069,103450,70000000,360000000,7758750000],
[2070,103500,70000000,360000000,7762500000],
[2071,103550,70000000,360000000,7766250000],
[2072,103600,70000000,361000000,7770000000],
[2073,103650,70500000,361000000,7773750000],
[2074,103700,70500000,361000000,7777500000],
[2075,103750,70500000,362000000,7781250000],
[2076,103800,70500000,362000000,7785000000],
[2077,103850,70500000,362000000,7788750000],
[2078,103900,70500000,363000000,7792500000],
[2079,103950,70500000,363000000,7796250000],
[2080,104000,70500000,363000000,7800000000],
[2081,104050,71000000,364000000,7803750000],
[2082,104100,71000000,364000000,7807500000],
[2083,104150,71000000,364000000,7811250000],
[2084,104200,71000000,365000000,7815000000],
[2085,104250,71000000,365000000,7818750000],
[2086,104300,71000000,366000000,7822500000],
[2087,104350,71000000,366000000,7826250000],
[2088,104400,71000000,366000000,7830000000],
[2089,104450,71500000,367000000,7833750000],
[2090,104500,71500000,367000000,7837500000],
[2091,104550,71500000,367000000,7841250000],
[2092,104600,71500000,368000000,7845000000],
[2093,104650,71500000,368000000,7848750000],
[2094,104700,71500000,368000000,7852500000],
[2095,104750,71500000,369000000,7856250000],
[2096,104800,71500000,369000000,7860000000],
[2097,104850,72000000,369000000,7863750000],
[2098,104900,72000000,370000000,7867500000],
[2099,104950,72000000,370000000,7871250000],
[2100,105000,72000000,370000000,7875000000],
[2101,105050,72000000,371000000,7878750000],
[2102,105100,72000000,371000000,7882500000],
[2103,105150,72000000,371000000,7886250000],
[2104,105200,72000000,372000000,7890000000],
[2105,105250,72500000,372000000,7893750000],
[2106,105300,72500000,372000000,7897500000],
[2107,105350,72500000,373000000,7901250000],
[2108,105400,72500000,373000000,7905000000],
[2109,105450,72500000,373000000,7908750000],
[2110,105500,72500000,374000000,7912500000],
[2111,105550,72500000,374000000,7916250000],
[2112,105600,72500000,374000000,7920000000],
[2113,105650,73000000,375000000,7923750000],
[2114,105700,73000000,375000000,7927500000],
[2115,105750,73000000,375000000,7931250000],
[2116,105800,73000000,376000000,7935000000],
[2117,105850,73000000,376000000,7938750000],
[2118,105900,73000000,376000000,7942500000],
[2119,105950,73000000,377000000,7946250000],
[2120,106000,73000000,377000000,7950000000],
[2121,106050,73500000,377000000,7953750000],
[2122,106100,73500000,378000000,7957500000],
[2123,106150,73500000,378000000,7961250000],
[2124,106200,73500000,379000000,7965000000],
[2125,106250,73500000,379000000,7968750000],
[2126,106300,73500000,379000000,7972500000],
[2127,106350,73500000,380000000,7976250000],
[2128,106400,73500000,380000000,7980000000],
[2129,106450,74000000,380000000,7983750000],
[2130,106500,74000000,381000000,7987500000],
[2131,106550,74000000,381000000,7991250000],
[2132,106600,74000000,381000000,7995000000],
[2133,106650,74000000,382000000,7998750000],
[2134,106700,74000000,382000000,8002500000],
[2135,106750,74000000,382000000,8006250000],
[2136,106800,74500000,383000000,8010000000],
[2137,106850,74500000,383000000,8013750000],
[2138,106900,74500000,383000000,8017500000],
[2139,106950,74500000,384000000,8021250000],
[2140,107000,74500000,384000000,8025000000],
[2141,107050,74500000,384000000,8028750000],
[2142,107100,74500000,385000000,8032500000],
[2143,107150,74500000,385000000,8036250000],
[2144,107200,75000000,385000000,8040000000],
[2145,107250,75000000,386000000,8043750000],
[2146,107300,75000000,386000000,8047500000],
[2147,107350,75000000,386000000,8051250000],
[2148,107400,75000000,387000000,8055000000],
[2149,107450,75000000,387000000,8058750000],
[2150,107500,75000000,388000000,8062500000],
[2151,107550,75000000,388000000,8066250000],
[2152,107600,75500000,388000000,8070000000],
[2153,107650,75500000,389000000,8073750000],
[2154,107700,75500000,389000000,8077500000],
[2155,107750,75500000,389000000,8081250000],
[2156,107800,75500000,390000000,8085000000],
[2157,107850,75500000,390000000,8088750000],
[2158,107900,75500000,390000000,8092500000],
[2159,107950,75500000,391000000,8096250000],
[2160,108000,76000000,391000000,8100000000],
[2161,108050,76000000,391000000,8103750000],
[2162,108100,76000000,392000000,8107500000],
[2163,108150,76000000,392000000,8111250000],
[2164,108200,76000000,392000000,8115000000],
[2165,108250,76000000,393000000,8118750000],
[2166,108300,76000000,393000000,8122500000],
[2167,108350,76000000,393000000,8126250000],
[2168,108400,76500000,394000000,8130000000],
[2169,108450,76500000,394000000,8133750000],
[2170,108500,76500000,395000000,8137500000],
[2171,108550,76500000,395000000,8141250000],
[2172,108600,76500000,395000000,8145000000],
[2173,108650,76500000,396000000,8148750000],
[2174,108700,76500000,396000000,8152500000],
[2175,108750,76500000,396000000,8156250000],
[2176,108800,77000000,397000000,8160000000],
[2177,108850,77000000,397000000,8163750000],
[2178,108900,77000000,397000000,8167500000],
[2179,108950,77000000,398000000,8171250000],
[2180,109000,77000000,398000000,8175000000],
[2181,109050,77000000,398000000,8178750000],
[2182,109100,77000000,399000000,8182500000],
[2183,109150,77500000,399000000,8186250000],
[2184,109200,77500000,399000000,8190000000],
[2185,109250,77500000,400000000,8193750000],
[2186,109300,77500000,400000000,8197500000],
[2187,109350,77500000,401000000,8201250000],
[2188,109400,77500000,401000000,8205000000],
[2189,109450,77500000,401000000,8208750000],
[2190,109500,77500000,402000000,8212500000],
[2191,109550,78000000,402000000,8216250000],
[2192,109600,78000000,402000000,8220000000],
[2193,109650,78000000,403000000,8223750000],
[2194,109700,78000000,403000000,8227500000],
[2195,109750,78000000,403000000,8231250000],
[2196,109800,78000000,404000000,8235000000],
[2197,109850,78000000,404000000,8238750000],
[2198,109900,78000000,404000000,8242500000],
[2199,109950,78500000,405000000,8246250000],
[2200,110000,78500000,405000000,8250000000],
[2201,110050,78500000,406000000,8253750000],
[2202,110100,78500000,406000000,8257500000],
[2203,110150,78500000,406000000,8261250000],
[2204,110200,78500000,407000000,8265000000],
[2205,110250,78500000,407000000,8268750000],
[2206,110300,79000000,407000000,8272500000],
[2207,110350,79000000,408000000,8276250000],
[2208,110400,79000000,408000000,8280000000],
[2209,110450,79000000,408000000,8283750000],
[2210,110500,79000000,409000000,8287500000],
[2211,110550,79000000,409000000,8291250000],
[2212,110600,79000000,409000000,8295000000],
[2213,110650,79000000,410000000,8298750000],
[2214,110700,79500000,410000000,8302500000],
[2215,110750,79500000,411000000,8306250000],
[2216,110800,79500000,411000000,8310000000],
[2217,110850,79500000,411000000,8313750000],
[2218,110900,79500000,412000000,8317500000],
[2219,110950,79500000,412000000,8321250000],
[2220,111000,79500000,412000000,8325000000],
[2221,111050,79500000,413000000,8328750000],
[2222,111100,80000000,413000000,8332500000],
[2223,111150,80000000,413000000,8336250000],
[2224,111200,80000000,414000000,8340000000],
[2225,111250,80000000,414000000,8343750000],
[2226,111300,80000000,414000000,8347500000],
[2227,111350,80000000,415000000,8351250000],
[2228,111400,80000000,415000000,8355000000],
[2229,111450,80500000,416000000,8358750000],
[2230,111500,80500000,416000000,8362500000],
[2231,111550,80500000,416000000,8366250000],
[2232,111600,80500000,417000000,8370000000],
[2233,111650,80500000,417000000,8373750000],
[2234,111700,80500000,417000000,8377500000],
[2235,111750,80500000,418000000,8381250000],
[2236,111800,80500000,418000000,8385000000],
[2237,111850,81000000,418000000,8388750000],
[2238,111900,81000000,419000000,8392500000],
[2239,111950,81000000,419000000,8396250000],
[2240,112000,81000000,420000000,8400000000],
[2241,112050,81000000,420000000,8403750000],
[2242,112100,81000000,420000000,8407500000],
[2243,112150,81000000,421000000,8411250000],
[2244,112200,81000000,421000000,8415000000],
[2245,112250,81500000,421000000,8418750000],
[2246,112300,81500000,422000000,8422500000],
[2247,112350,81500000,422000000,8426250000],
[2248,112400,81500000,422000000,8430000000],
[2249,112450,81500000,423000000,8433750000],
[2250,112500,81500000,423000000,8437500000],
[2251,112550,81500000,424000000,8441250000],
[2252,112600,82000000,424000000,8445000000],
[2253,112650,82000000,424000000,8448750000],
[2254,112700,82000000,425000000,8452500000],
[2255,112750,82000000,425000000,8456250000],
[2256,112800,82000000,425000000,8460000000],
[2257,112850,82000000,426000000,8463750000],
[2258,112900,82000000,426000000,8467500000],
[2259,112950,82000000,426000000,8471250000],
[2260,113000,82500000,427000000,8475000000],
[2261,113050,82500000,427000000,8478750000],
[2262,113100,82500000,428000000,8482500000],
[2263,113150,82500000,428000000,8486250000],
[2264,113200,82500000,428000000,8490000000],
[2265,113250,82500000,429000000,8493750000],
[2266,113300,82500000,429000000,8497500000],
[2267,113350,83000000,429000000,8501250000],
[2268,113400,83000000,430000000,8505000000],
[2269,113450,83000000,430000000,8508750000],
[2270,113500,83000000,431000000,8512500000],
[2271,113550,83000000,431000000,8516250000],
[2272,113600,83000000,431000000,8520000000],
[2273,113650,83000000,432000000,8523750000],
[2274,113700,83000000,432000000,8527500000],
[2275,113750,83500000,432000000,8531250000],
[2276,113800,83500000,433000000,8535000000],
[2277,113850,83500000,433000000,8538750000],
[2278,113900,83500000,433000000,8542500000],
[2279,113950,83500000,434000000,8546250000],
[2280,114000,83500000,434000000,8550000000],
[2281,114050,83500000,435000000,8553750000],
[2282,114100,84000000,435000000,8557500000],
[2283,114150,84000000,435000000,8561250000],
[2284,114200,84000000,436000000,8565000000],
[2285,114250,84000000,436000000,8568750000],
[2286,114300,84000000,436000000,8572500000],
[2287,114350,84000000,437000000,8576250000],
[2288,114400,84000000,437000000,8580000000],
[2289,114450,84000000,438000000,8583750000],
[2290,114500,84500000,438000000,8587500000],
[2291,114550,84500000,438000000,8591250000],
[2292,114600,84500000,439000000,8595000000],
[2293,114650,84500000,439000000,8598750000],
[2294,114700,84500000,439000000,8602500000],
[2295,114750,84500000,440000000,8606250000],
[2296,114800,84500000,440000000,8610000000],
[2297,114850,85000000,440000000,8613750000],
[2298,114900,85000000,441000000,8617500000],
[2299,114950,85000000,441000000,8621250000],
[2300,115000,85000000,442000000,8625000000],
[2301,115050,85000000,442000000,8628750000],
[2302,115100,85000000,442000000,8632500000],
[2303,115150,85000000,443000000,8636250000],
[2304,115200,85000000,443000000,8640000000],
[2305,115250,85500000,443000000,8643750000],
[2306,115300,85500000,444000000,8647500000],
[2307,115350,85500000,444000000,8651250000],
[2308,115400,85500000,445000000,8655000000],
[2309,115450,85500000,445000000,8658750000],
[2310,115500,85500000,445000000,8662500000],
[2311,115550,85500000,446000000,8666250000],
[2312,115600,86000000,446000000,8670000000],
[2313,115650,86000000,446000000,8673750000],
[2314,115700,86000000,447000000,8677500000],
[2315,115750,86000000,447000000,8681250000],
[2316,115800,86000000,448000000,8685000000],
[2317,115850,86000000,448000000,8688750000],
[2318,115900,86000000,448000000,8692500000],
[2319,115950,86500000,449000000,8696250000],
[2320,116000,86500000,449000000,8700000000],
[2321,116050,86500000,449000000,8703750000],
[2322,116100,86500000,450000000,8707500000],
[2323,116150,86500000,450000000,8711250000],
[2324,116200,86500000,451000000,8715000000],
[2325,116250,86500000,451000000,8718750000],
[2326,116300,86500000,451000000,8722500000],
[2327,116350,87000000,452000000,8726250000],
[2328,116400,87000000,452000000,8730000000],
[2329,116450,87000000,452000000,8733750000],
[2330,116500,87000000,453000000,8737500000],
[2331,116550,87000000,453000000,8741250000],
[2332,116600,87000000,454000000,8745000000],
[2333,116650,87000000,454000000,8748750000],
[2334,116700,87500000,454000000,8752500000],
[2335,116750,87500000,455000000,8756250000],
[2336,116800,87500000,455000000,8760000000],
[2337,116850,87500000,455000000,8763750000],
[2338,116900,87500000,456000000,8767500000],
[2339,116950,87500000,456000000,8771250000],
[2340,117000,87500000,457000000,8775000000],
[2341,117050,88000000,457000000,8778750000],
[2342,117100,88000000,457000000,8782500000],
[2343,117150,88000000,458000000,8786250000],
[2344,117200,88000000,458000000,8790000000],
[2345,117250,88000000,458000000,8793750000],
[2346,117300,88000000,459000000,8797500000],
[2347,117350,88000000,459000000,8801250000],
[2348,117400,88000000,460000000,8805000000],
[2349,117450,88500000,460000000,8808750000],
[2350,117500,88500000,460000000,8812500000],
[2351,117550,88500000,461000000,8816250000],
[2352,117600,88500000,461000000,8820000000],
[2353,117650,88500000,461000000,8823750000],
[2354,117700,88500000,462000000,8827500000],
[2355,117750,88500000,462000000,8831250000],
[2356,117800,89000000,463000000,8835000000],
[2357,117850,89000000,463000000,8838750000],
[2358,117900,89000000,463000000,8842500000],
[2359,117950,89000000,464000000,8846250000],
[2360,118000,89000000,464000000,8850000000],
[2361,118050,89000000,465000000,8853750000],
[2362,118100,89000000,465000000,8857500000],
[2363,118150,89500000,465000000,8861250000],
[2364,118200,89500000,466000000,8865000000],
[2365,118250,89500000,466000000,8868750000],
[2366,118300,89500000,466000000,8872500000],
[2367,118350,89500000,467000000,8876250000],
[2368,118400,89500000,467000000,8880000000],
[2369,118450,89500000,468000000,8883750000],
[2370,118500,90000000,468000000,8887500000],
[2371,118550,90000000,468000000,8891250000],
[2372,118600,90000000,469000000,8895000000],
[2373,118650,90000000,469000000,8898750000],
[2374,118700,90000000,470000000,8902500000],
[2375,118750,90000000,470000000,8906250000],
[2376,118800,90000000,470000000,8910000000],
[2377,118850,90000000,471000000,8913750000],
[2378,118900,90500000,471000000,8917500000],
[2379,118950,90500000,471000000,8921250000],
[2380,119000,90500000,472000000,8925000000],
[2381,119050,90500000,472000000,8928750000],
[2382,119100,90500000,473000000,8932500000],
[2383,119150,90500000,473000000,8936250000],
[2384,119200,90500000,473000000,8940000000],
[2385,119250,91000000,474000000,8943750000],
[2386,119300,91000000,474000000,8947500000],
[2387,119350,91000000,474000000,8951250000],
[2388,119400,91000000,475000000,8955000000],
[2389,119450,91000000,475000000,8958750000],
[2390,119500,91000000,476000000,8962500000],
[2391,119550,91000000,476000000,8966250000],
[2392,119600,91500000,476000000,8970000000],
[2393,119650,91500000,477000000,8973750000],
[2394,119700,91500000,477000000,8977500000],
[2395,119750,91500000,478000000,8981250000],
[2396,119800,91500000,478000000,8985000000],
[2397,119850,91500000,478000000,8988750000],
[2398,119900,91500000,479000000,8992500000],
[2399,119950,92000000,479000000,8996250000],
[2400,120000,92000000,480000000,9000000000],
[2401,120050,92000000,480000000,9003750000],
[2402,120100,92000000,480000000,9007500000],
[2403,120150,92000000,481000000,9011250000],
[2404,120200,92000000,481000000,9015000000],
[2405,120250,92000000,481000000,9018750000],
[2406,120300,92500000,482000000,9022500000],
[2407,120350,92500000,482000000,9026250000],
[2408,120400,92500000,483000000,9030000000],
[2409,120450,92500000,483000000,9033750000],
[2410,120500,92500000,483000000,9037500000],
[2411,120550,92500000,484000000,9041250000],
[2412,120600,92500000,484000000,9045000000],
[2413,120650,92500000,485000000,9048750000],
[2414,120700,93000000,485000000,9052500000],
[2415,120750,93000000,485000000,9056250000],
[2416,120800,93000000,486000000,9060000000],
[2417,120850,93000000,486000000,9063750000],
[2418,120900,93000000,486000000,9067500000],
[2419,120950,93000000,487000000,9071250000],
[2420,121000,93000000,487000000,9075000000],
[2421,121050,93500000,488000000,9078750000],
[2422,121100,93500000,488000000,9082500000],
[2423,121150,93500000,488000000,9086250000],
[2424,121200,93500000,489000000,9090000000],
[2425,121250,93500000,489000000,9093750000],
[2426,121300,93500000,490000000,9097500000],
[2427,121350,93500000,490000000,9101250000],
[2428,121400,94000000,490000000,9105000000],
[2429,121450,94000000,491000000,9108750000],
[2430,121500,94000000,491000000,9112500000],
[2431,121550,94000000,492000000,9116250000],
[2432,121600,94000000,492000000,9120000000],
[2433,121650,94000000,492000000,9123750000],
[2434,121700,94000000,493000000,9127500000],
[2435,121750,94500000,493000000,9131250000],
[2436,121800,94500000,494000000,9135000000],
[2437,121850,94500000,494000000,9138750000],
[2438,121900,94500000,494000000,9142500000],
[2439,121950,94500000,495000000,9146250000],
[2440,122000,94500000,495000000,9150000000],
[2441,122050,94500000,495000000,9153750000],
[2442,122100,95000000,496000000,9157500000],
[2443,122150,95000000,496000000,9161250000],
[2444,122200,95000000,497000000,9165000000],
[2445,122250,95000000,497000000,9168750000],
[2446,122300,95000000,497000000,9172500000],
[2447,122350,95000000,498000000,9176250000],
[2448,122400,95000000,498000000,9180000000],
[2449,122450,95500000,499000000,9183750000],
[2450,122500,95500000,499000000,9187500000],
[2451,122550,95500000,499000000,9191250000],
[2452,122600,95500000,500000000,9195000000],
[2453,122650,95500000,500000000,9198750000],
[2454,122700,95500000,501000000,9202500000],
[2455,122750,95500000,501000000,9206250000],
[2456,122800,96000000,501000000,9210000000],
[2457,122850,96000000,502000000,9213750000],
[2458,122900,96000000,502000000,9217500000],
[2459,122950,96000000,503000000,9221250000],
[2460,123000,96000000,503000000,9225000000],
[2461,123050,96000000,503000000,9228750000],
[2462,123100,96000000,504000000,9232500000],
[2463,123150,96500000,504000000,9236250000],
[2464,123200,96500000,505000000,9240000000],
[2465,123250,96500000,505000000,9243750000],
[2466,123300,96500000,505000000,9247500000],
[2467,123350,96500000,506000000,9251250000],
[2468,123400,96500000,506000000,9255000000],
[2469,123450,96500000,507000000,9258750000],
[2470,123500,97000000,507000000,9262500000],
[2471,123550,97000000,507000000,9266250000],
[2472,123600,97000000,508000000,9270000000],
[2473,123650,97000000,508000000,9273750000],
[2474,123700,97000000,509000000,9277500000],
[2475,123750,97000000,509000000,9281250000],
[2476,123800,97000000,509000000,9285000000],
[2477,123850,97500000,510000000,9288750000],
[2478,123900,97500000,510000000,9292500000],
[2479,123950,97500000,511000000,9296250000],
[2480,124000,97500000,511000000,9300000000],
[2481,124050,97500000,511000000,9303750000],
[2482,124100,97500000,512000000,9307500000],
[2483,124150,97500000,512000000,9311250000],
[2484,124200,98000000,513000000,9315000000],
[2485,124250,98000000,513000000,9318750000],
[2486,124300,98000000,513000000,9322500000],
[2487,124350,98000000,514000000,9326250000],
[2488,124400,98000000,514000000,9330000000],
[2489,124450,98000000,515000000,9333750000],
[2490,124500,98000000,515000000,9337500000],
[2491,124550,98500000,515000000,9341250000],
[2492,124600,98500000,516000000,9345000000],
[2493,124650,98500000,516000000,9348750000],
[2494,124700,98500000,517000000,9352500000],
[2495,124750,98500000,517000000,9356250000],
[2496,124800,98500000,517000000,9360000000],
[2497,124850,98500000,518000000,9363750000],
[2498,124900,99000000,518000000,9367500000],
[2499,124950,99000000,519000000,9371250000],
[2500,125000,99000000,519000000,9375000000],
[2501,125050,99000000,519000000,9378750000],
[2502,125100,99000000,520000000,9382500000],
[2503,125150,99000000,520000000,9386250000],
[2504,125200,99000000,521000000,9390000000],
[2505,125250,99500000,521000000,9393750000],
[2506,125300,99500000,521000000,9397500000],
[2507,125350,99500000,522000000,9401250000],
[2508,125400,99500000,522000000,9405000000],
[2509,125450,99500000,523000000,9408750000],
[2510,125500,99500000,523000000,9412500000],
[2511,125550,99500000,523000000,9416250000],
[2512,125600,100000000,524000000,9420000000],
[2513,125650,100000000,524000000,9423750000],
[2514,125700,100000000,525000000,9427500000],
[2515,125750,100000000,525000000,9431250000],
[2516,125800,100000000,525000000,9435000000],
[2517,125850,100000000,526000000,9438750000],
[2518,125900,100000000,526000000,9442500000],
[2519,125950,100500000,527000000,9446250000],
[2520,126000,100500000,527000000,9450000000],
[2521,126050,100500000,527000000,9453750000],
[2522,126100,100500000,528000000,9457500000],
[2523,126150,100500000,528000000,9461250000],
[2524,126200,100500000,529000000,9465000000],
[2525,126250,100500000,529000000,9468750000],
[2526,126300,101000000,529000000,9472500000],
[2527,126350,101000000,530000000,9476250000],
[2528,126400,101000000,530000000,9480000000],
[2529,126450,101000000,531000000,9483750000],
[2530,126500,101000000,531000000,9487500000],
[2531,126550,101000000,531000000,9491250000],
[2532,126600,101000000,532000000,9495000000],
[2533,126650,101500000,532000000,9498750000],
[2534,126700,101500000,533000000,9502500000],
[2535,126750,101500000,533000000,9506250000],
[2536,126800,101500000,534000000,9510000000],
[2537,126850,101500000,534000000,9513750000],
[2538,126900,101500000,534000000,9517500000],
[2539,126950,102000000,535000000,9521250000],
[2540,127000,102000000,535000000,9525000000],
[2541,127050,102000000,536000000,9528750000],
[2542,127100,102000000,536000000,9532500000],
[2543,127150,102000000,536000000,9536250000],
[2544,127200,102000000,537000000,9540000000],
[2545,127250,102000000,537000000,9543750000],
[2546,127300,102500000,538000000,9547500000],
[2547,127350,102500000,538000000,9551250000],
[2548,127400,102500000,538000000,9555000000],
[2549,127450,102500000,539000000,9558750000],
[2550,127500,102500000,539000000,9562500000],
[2551,127550,102500000,540000000,9566250000],
[2552,127600,102500000,540000000,9570000000],
[2553,127650,103000000,540000000,9573750000],
[2554,127700,103000000,541000000,9577500000],
[2555,127750,103000000,541000000,9581250000],
[2556,127800,103000000,542000000,9585000000],
[2557,127850,103000000,542000000,9588750000],
[2558,127900,103000000,542000000,9592500000],
[2559,127950,103000000,543000000,9596250000],
[2560,128000,103500000,543000000,9600000000],
[2561,128050,103500000,544000000,9603750000],
[2562,128100,103500000,544000000,9607500000],
[2563,128150,103500000,545000000,9611250000],
[2564,128200,103500000,545000000,9615000000],
[2565,128250,103500000,545000000,9618750000],
[2566,128300,103500000,546000000,9622500000],
[2567,128350,104000000,546000000,9626250000],
[2568,128400,104000000,547000000,9630000000],
[2569,128450,104000000,547000000,9633750000],
[2570,128500,104000000,547000000,9637500000],
[2571,128550,104000000,548000000,9641250000],
[2572,128600,104000000,548000000,9645000000],
[2573,128650,104500000,549000000,9648750000],
[2574,128700,104500000,549000000,9652500000],
[2575,128750,104500000,549000000,9656250000],
[2576,128800,104500000,550000000,9660000000],
[2577,128850,104500000,550000000,9663750000],
[2578,128900,104500000,551000000,9667500000],
[2579,128950,104500000,551000000,9671250000],
[2580,129000,105000000,552000000,9675000000],
[2581,129050,105000000,552000000,9678750000],
[2582,129100,105000000,552000000,9682500000],
[2583,129150,105000000,553000000,9686250000],
[2584,129200,105000000,553000000,9690000000],
[2585,129250,105000000,554000000,9693750000],
[2586,129300,105000000,554000000,9697500000],
[2587,129350,105500000,554000000,9701250000],
[2588,129400,105500000,555000000,9705000000],
[2589,129450,105500000,555000000,9708750000],
[2590,129500,105500000,556000000,9712500000],
[2591,129550,105500000,556000000,9716250000],
[2592,129600,105500000,557000000,9720000000],
[2593,129650,105500000,557000000,9723750000],
[2594,129700,106000000,557000000,9727500000],
[2595,129750,106000000,558000000,9731250000],
[2596,129800,106000000,558000000,9735000000],
[2597,129850,106000000,559000000,9738750000],
[2598,129900,106000000,559000000,9742500000],
[2599,129950,106000000,559000000,9746250000],
[2600,130000,106500000,560000000,9750000000],
[2601,130050,106500000,560000000,9753750000],
[2602,130100,106500000,561000000,9757500000],
[2603,130150,106500000,561000000,9761250000],
[2604,130200,106500000,562000000,9765000000],
[2605,130250,106500000,562000000,9768750000],
[2606,130300,106500000,562000000,9772500000],
[2607,130350,107000000,563000000,9776250000],
[2608,130400,107000000,563000000,9780000000],
[2609,130450,107000000,564000000,9783750000],
[2610,130500,107000000,564000000,9787500000],
[2611,130550,107000000,564000000,9791250000],
[2612,130600,107000000,565000000,9795000000],
[2613,130650,107000000,565000000,9798750000],
[2614,130700,107500000,566000000,9802500000],
[2615,130750,107500000,566000000,9806250000],
[2616,130800,107500000,567000000,9810000000],
[2617,130850,107500000,567000000,9813750000],
[2618,130900,107500000,567000000,9817500000],
[2619,130950,107500000,568000000,9821250000],
[2620,131000,108000000,568000000,9825000000],
[2621,131050,108000000,569000000,9828750000],
[2622,131100,108000000,569000000,9832500000],
[2623,131150,108000000,569000000,9836250000],
[2624,131200,108000000,570000000,9840000000],
[2625,131250,108000000,570000000,9843750000],
[2626,131300,108000000,571000000,9847500000],
[2627,131350,108500000,571000000,9851250000],
[2628,131400,108500000,572000000,9855000000],
[2629,131450,108500000,572000000,9858750000],
[2630,131500,108500000,572000000,9862500000],
[2631,131550,108500000,573000000,9866250000],
[2632,131600,108500000,573000000,9870000000],
[2633,131650,108500000,574000000,9873750000],
[2634,131700,109000000,574000000,9877500000],
[2635,131750,109000000,575000000,9881250000],
[2636,131800,109000000,575000000,9885000000],
[2637,131850,109000000,575000000,9888750000],
[2638,131900,109000000,576000000,9892500000],
[2639,131950,109000000,576000000,9896250000],
[2640,132000,109500000,577000000,9900000000],
[2641,132050,109500000,577000000,9903750000],
[2642,132100,109500000,578000000,9907500000],
[2643,132150,109500000,578000000,9911250000],
[2644,132200,109500000,578000000,9915000000],
[2645,132250,109500000,579000000,9918750000],
[2646,132300,109500000,579000000,9922500000],
[2647,132350,110000000,580000000,9926250000],
[2648,132400,110000000,580000000,9930000000],
[2649,132450,110000000,580000000,9933750000],
[2650,132500,110000000,581000000,9937500000],
[2651,132550,110000000,581000000,9941250000],
[2652,132600,110000000,582000000,9945000000],
[2653,132650,110000000,582000000,9948750000],
[2654,132700,110500000,583000000,9952500000],
[2655,132750,110500000,583000000,9956250000],
[2656,132800,110500000,583000000,9960000000],
[2657,132850,110500000,584000000,9963750000],
[2658,132900,110500000,584000000,9967500000],
[2659,132950,110500000,585000000,9971250000],
[2660,133000,111000000,585000000,9975000000],
[2661,133050,111000000,586000000,9978750000],
[2662,133100,111000000,586000000,9982500000],
[2663,133150,111000000,586000000,9986250000],
[2664,133200,111000000,587000000,9990000000],
[2665,133250,111000000,587000000,9993750000],
[2666,133300,111000000,588000000,9997500000],
[2667,133350,111500000,588000000,10001250000],
[2668,133400,111500000,589000000,10005000000],
[2669,133450,111500000,589000000,10008750000],
[2670,133500,111500000,589000000,10012500000],
[2671,133550,111500000,590000000,10016250000],
[2672,133600,111500000,590000000,10020000000],
[2673,133650,112000000,591000000,10023750000],
[2674,133700,112000000,591000000,10027500000],
[2675,133750,112000000,592000000,10031250000],
[2676,133800,112000000,592000000,10035000000],
[2677,133850,112000000,592000000,10038750000],
[2678,133900,112000000,593000000,10042500000],
[2679,133950,112000000,593000000,10046250000],
[2680,134000,112500000,594000000,10050000000],
[2681,134050,112500000,594000000,10053750000],
[2682,134100,112500000,595000000,10057500000],
[2683,134150,112500000,595000000,10061250000],
[2684,134200,112500000,595000000,10065000000],
[2685,134250,112500000,596000000,10068750000],
[2686,134300,112500000,596000000,10072500000],
[2687,134350,113000000,597000000,10076250000],
[2688,134400,113000000,597000000,10080000000],
[2689,134450,113000000,598000000,10083750000],
[2690,134500,113000000,598000000,10087500000],
[2691,134550,113000000,598000000,10091250000],
[2692,134600,113000000,599000000,10095000000],
[2693,134650,113500000,599000000,10098750000],
[2694,134700,113500000,600000000,10102500000],
[2695,134750,113500000,600000000,10106250000],
[2696,134800,113500000,601000000,10110000000],
[2697,134850,113500000,601000000,10113750000],
[2698,134900,113500000,601000000,10117500000],
[2699,134950,113500000,602000000,10121250000],
[2700,135000,114000000,602000000,10125000000],
[2701,135050,114000000,603000000,10128750000],
[2702,135100,114000000,603000000,10132500000],
[2703,135150,114000000,604000000,10136250000],
[2704,135200,114000000,604000000,10140000000],
[2705,135250,114000000,604000000,10143750000],
[2706,135300,114500000,605000000,10147500000],
[2707,135350,114500000,605000000,10151250000],
[2708,135400,114500000,606000000,10155000000],
[2709,135450,114500000,606000000,10158750000],
[2710,135500,114500000,607000000,10162500000],
[2711,135550,114500000,607000000,10166250000],
[2712,135600,114500000,607000000,10170000000],
[2713,135650,115000000,608000000,10173750000],
[2714,135700,115000000,608000000,10177500000],
[2715,135750,115000000,609000000,10181250000],
[2716,135800,115000000,609000000,10185000000],
[2717,135850,115000000,610000000,10188750000],
[2718,135900,115000000,610000000,10192500000],
[2719,135950,115500000,611000000,10196250000],
[2720,136000,115500000,611000000,10200000000],
[2721,136050,115500000,611000000,10203750000],
[2722,136100,115500000,612000000,10207500000],
[2723,136150,115500000,612000000,10211250000],
[2724,136200,115500000,613000000,10215000000],
[2725,136250,115500000,613000000,10218750000],
[2726,136300,116000000,614000000,10222500000],
[2727,136350,116000000,614000000,10226250000],
[2728,136400,116000000,614000000,10230000000],
[2729,136450,116000000,615000000,10233750000],
[2730,136500,116000000,615000000,10237500000],
[2731,136550,116000000,616000000,10241250000],
[2732,136600,116500000,616000000,10245000000],
[2733,136650,116500000,617000000,10248750000],
[2734,136700,116500000,617000000,10252500000],
[2735,136750,116500000,618000000,10256250000],
[2736,136800,116500000,618000000,10260000000],
[2737,136850,116500000,618000000,10263750000],
[2738,136900,117000000,619000000,10267500000],
[2739,136950,117000000,619000000,10271250000],
[2740,137000,117000000,620000000,10275000000],
[2741,137050,117000000,620000000,10278750000],
[2742,137100,117000000,621000000,10282500000],
[2743,137150,117000000,621000000,10286250000],
[2744,137200,117000000,621000000,10290000000],
[2745,137250,117500000,622000000,10293750000],
[2746,137300,117500000,622000000,10297500000],
[2747,137350,117500000,623000000,10301250000],
[2748,137400,117500000,623000000,10305000000],
[2749,137450,117500000,624000000,10308750000],
[2750,137500,117500000,624000000,10312500000],
[2751,137550,118000000,625000000,10316250000],
[2752,137600,118000000,625000000,10320000000],
[2753,137650,118000000,625000000,10323750000],
[2754,137700,118000000,626000000,10327500000],
[2755,137750,118000000,626000000,10331250000],
[2756,137800,118000000,627000000,10335000000],
[2757,137850,118000000,627000000,10338750000],
[2758,137900,118500000,628000000,10342500000],
[2759,137950,118500000,628000000,10346250000],
[2760,138000,118500000,628000000,10350000000],
[2761,138050,118500000,629000000,10353750000],
[2762,138100,118500000,629000000,10357500000],
[2763,138150,118500000,630000000,10361250000],
[2764,138200,119000000,630000000,10365000000],
[2765,138250,119000000,631000000,10368750000],
[2766,138300,119000000,631000000,10372500000],
[2767,138350,119000000,632000000,10376250000],
[2768,138400,119000000,632000000,10380000000],
[2769,138450,119000000,632000000,10383750000],
[2770,138500,119500000,633000000,10387500000],
[2771,138550,119500000,633000000,10391250000],
[2772,138600,119500000,634000000,10395000000],
[2773,138650,119500000,634000000,10398750000],
[2774,138700,119500000,635000000,10402500000],
[2775,138750,119500000,635000000,10406250000],
[2776,138800,119500000,636000000,10410000000],
[2777,138850,120000000,636000000,10413750000],
[2778,138900,120000000,636000000,10417500000],
[2779,138950,120000000,637000000,10421250000],
[2780,139000,120000000,637000000,10425000000],
[2781,139050,120000000,638000000,10428750000],
[2782,139100,120000000,638000000,10432500000],
[2783,139150,120500000,639000000,10436250000],
[2784,139200,120500000,639000000,10440000000],
[2785,139250,120500000,640000000,10443750000],
[2786,139300,120500000,640000000,10447500000],
[2787,139350,120500000,640000000,10451250000],
[2788,139400,120500000,641000000,10455000000],
[2789,139450,120500000,641000000,10458750000],
[2790,139500,121000000,642000000,10462500000],
[2791,139550,121000000,642000000,10466250000],
[2792,139600,121000000,643000000,10470000000],
[2793,139650,121000000,643000000,10473750000],
[2794,139700,121000000,644000000,10477500000],
[2795,139750,121000000,644000000,10481250000],
[2796,139800,121500000,644000000,10485000000],
[2797,139850,121500000,645000000,10488750000],
[2798,139900,121500000,645000000,10492500000],
[2799,139950,121500000,646000000,10496250000],
[2800,140000,121500000,646000000,10500000000],
[2801,140050,121500000,647000000,10503750000],
[2802,140100,122000000,647000000,10507500000],
[2803,140150,122000000,648000000,10511250000],
[2804,140200,122000000,648000000,10515000000],
[2805,140250,122000000,648000000,10518750000],
[2806,140300,122000000,649000000,10522500000],
[2807,140350,122000000,649000000,10526250000],
[2808,140400,122000000,650000000,10530000000],
[2809,140450,122500000,650000000,10533750000],
[2810,140500,122500000,651000000,10537500000],
[2811,140550,122500000,651000000,10541250000],
[2812,140600,122500000,652000000,10545000000],
[2813,140650,122500000,652000000,10548750000],
[2814,140700,122500000,652000000,10552500000],
[2815,140750,123000000,653000000,10556250000],
[2816,140800,123000000,653000000,10560000000],
[2817,140850,123000000,654000000,10563750000],
[2818,140900,123000000,654000000,10567500000],
[2819,140950,123000000,655000000,10571250000],
[2820,141000,123000000,655000000,10575000000],
[2821,141050,123500000,656000000,10578750000],
[2822,141100,123500000,656000000,10582500000],
[2823,141150,123500000,657000000,10586250000],
[2824,141200,123500000,657000000,10590000000],
[2825,141250,123500000,657000000,10593750000],
[2826,141300,123500000,658000000,10597500000],
[2827,141350,124000000,658000000,10601250000],
[2828,141400,124000000,659000000,10605000000],
[2829,141450,124000000,659000000,10608750000],
[2830,141500,124000000,660000000,10612500000],
[2831,141550,124000000,660000000,10616250000],
[2832,141600,124000000,661000000,10620000000],
[2833,141650,124000000,661000000,10623750000],
[2834,141700,124500000,661000000,10627500000],
[2835,141750,124500000,662000000,10631250000],
[2836,141800,124500000,662000000,10635000000],
[2837,141850,124500000,663000000,10638750000],
[2838,141900,124500000,663000000,10642500000],
[2839,141950,124500000,664000000,10646250000],
[2840,142000,125000000,664000000,10650000000],
[2841,142050,125000000,665000000,10653750000],
[2842,142100,125000000,665000000,10657500000],
[2843,142150,125000000,666000000,10661250000],
[2844,142200,125000000,666000000,10665000000],
[2845,142250,125000000,666000000,10668750000],
[2846,142300,125500000,667000000,10672500000],
[2847,142350,125500000,667000000,10676250000],
[2848,142400,125500000,668000000,10680000000],
[2849,142450,125500000,668000000,10683750000],
[2850,142500,125500000,669000000,10687500000],
[2851,142550,125500000,669000000,10691250000],
[2852,142600,126000000,670000000,10695000000],
[2853,142650,126000000,670000000,10698750000],
[2854,142700,126000000,671000000,10702500000],
[2855,142750,126000000,671000000,10706250000],
[2856,142800,126000000,671000000,10710000000],
[2857,142850,126000000,672000000,10713750000],
[2858,142900,126000000,672000000,10717500000],
[2859,142950,126500000,673000000,10721250000],
[2860,143000,126500000,673000000,10725000000],
[2861,143050,126500000,674000000,10728750000],
[2862,143100,126500000,674000000,10732500000],
[2863,143150,126500000,675000000,10736250000],
[2864,143200,126500000,675000000,10740000000],
[2865,143250,127000000,676000000,10743750000],
[2866,143300,127000000,676000000,10747500000],
[2867,143350,127000000,676000000,10751250000],
[2868,143400,127000000,677000000,10755000000],
[2869,143450,127000000,677000000,10758750000],
[2870,143500,127000000,678000000,10762500000],
[2871,143550,127500000,678000000,10766250000],
[2872,143600,127500000,679000000,10770000000],
[2873,143650,127500000,679000000,10773750000],
[2874,143700,127500000,680000000,10777500000],
[2875,143750,127500000,680000000,10781250000],
[2876,143800,127500000,681000000,10785000000],
[2877,143850,128000000,681000000,10788750000],
[2878,143900,128000000,682000000,10792500000],
[2879,143950,128000000,682000000,10796250000],
[2880,144000,128000000,682000000,10800000000],
[2881,144050,128000000,683000000,10803750000],
[2882,144100,128000000,683000000,10807500000],
[2883,144150,128500000,684000000,10811250000],
[2884,144200,128500000,684000000,10815000000],
[2885,144250,128500000,685000000,10818750000],
[2886,144300,128500000,685000000,10822500000],
[2887,144350,128500000,686000000,10826250000],
[2888,144400,128500000,686000000,10830000000],
[2889,144450,128500000,687000000,10833750000],
[2890,144500,129000000,687000000,10837500000],
[2891,144550,129000000,687000000,10841250000],
[2892,144600,129000000,688000000,10845000000],
[2893,144650,129000000,688000000,10848750000],
[2894,144700,129000000,689000000,10852500000],
[2895,144750,129000000,689000000,10856250000],
[2896,144800,129500000,690000000,10860000000],
[2897,144850,129500000,690000000,10863750000],
[2898,144900,129500000,691000000,10867500000],
[2899,144950,129500000,691000000,10871250000],
[2900,145000,129500000,692000000,10875000000],
[2901,145050,129500000,692000000,10878750000],
[2902,145100,130000000,693000000,10882500000],
[2903,145150,130000000,693000000,10886250000],
[2904,145200,130000000,693000000,10890000000],
[2905,145250,130000000,694000000,10893750000],
[2906,145300,130000000,694000000,10897500000],
[2907,145350,130000000,695000000,10901250000],
[2908,145400,130500000,695000000,10905000000],
[2909,145450,130500000,696000000,10908750000],
[2910,145500,130500000,696000000,10912500000],
[2911,145550,130500000,697000000,10916250000],
[2912,145600,130500000,697000000,10920000000],
[2913,145650,130500000,698000000,10923750000],
[2914,145700,131000000,698000000,10927500000],
[2915,145750,131000000,699000000,10931250000],
[2916,145800,131000000,699000000,10935000000],
[2917,145850,131000000,700000000,10938750000],
[2918,145900,131000000,700000000,10942500000],
[2919,145950,131000000,700000000,10946250000],
[2920,146000,131500000,701000000,10950000000],
[2921,146050,131500000,701000000,10953750000],
[2922,146100,131500000,702000000,10957500000],
[2923,146150,131500000,702000000,10961250000],
[2924,146200,131500000,703000000,10965000000],
[2925,146250,131500000,703000000,10968750000],
[2926,146300,132000000,704000000,10972500000],
[2927,146350,132000000,704000000,10976250000],
[2928,146400,132000000,705000000,10980000000],
[2929,146450,132000000,705000000,10983750000],
[2930,146500,132000000,706000000,10987500000],
[2931,146550,132000000,706000000,10991250000],
[2932,146600,132500000,706000000,10995000000],
[2933,146650,132500000,707000000,10998750000],
[2934,146700,132500000,707000000,11002500000],
[2935,146750,132500000,708000000,11006250000],
[2936,146800,132500000,708000000,11010000000],
[2937,146850,132500000,709000000,11013750000],
[2938,146900,133000000,709000000,11017500000],
[2939,146950,133000000,710000000,11021250000],
[2940,147000,133000000,710000000,11025000000],
[2941,147050,133000000,711000000,11028750000],
[2942,147100,133000000,711000000,11032500000],
[2943,147150,133000000,712000000,11036250000],
[2944,147200,133000000,712000000,11040000000],
[2945,147250,133500000,713000000,11043750000],
[2946,147300,133500000,713000000,11047500000],
[2947,147350,133500000,713000000,11051250000],
[2948,147400,133500000,714000000,11055000000],
[2949,147450,133500000,714000000,11058750000],
[2950,147500,133500000,715000000,11062500000],
[2951,147550,134000000,715000000,11066250000],
[2952,147600,134000000,716000000,11070000000],
[2953,147650,134000000,716000000,11073750000],
[2954,147700,134000000,717000000,11077500000],
[2955,147750,134000000,717000000,11081250000],
[2956,147800,134000000,718000000,11085000000],
[2957,147850,134500000,718000000,11088750000],
[2958,147900,134500000,719000000,11092500000],
[2959,147950,134500000,719000000,11096250000],
[2960,148000,134500000,720000000,11100000000],
[2961,148050,134500000,720000000,11103750000],
[2962,148100,134500000,721000000,11107500000],
[2963,148150,135000000,721000000,11111250000],
[2964,148200,135000000,721000000,11115000000],
[2965,148250,135000000,722000000,11118750000],
[2966,148300,135000000,722000000,11122500000],
[2967,148350,135000000,723000000,11126250000],
[2968,148400,135000000,723000000,11130000000],
[2969,148450,135500000,724000000,11133750000],
[2970,148500,135500000,724000000,11137500000],
[2971,148550,135500000,725000000,11141250000],
[2972,148600,135500000,725000000,11145000000],
[2973,148650,135500000,726000000,11148750000],
[2974,148700,135500000,726000000,11152500000],
[2975,148750,136000000,727000000,11156250000],
[2976,148800,136000000,727000000,11160000000],
[2977,148850,136000000,728000000,11163750000],
[2978,148900,136000000,728000000,11167500000],
[2979,148950,136000000,729000000,11171250000],
[2980,149000,136000000,729000000,11175000000],
[2981,149050,136500000,730000000,11178750000],
[2982,149100,136500000,730000000,11182500000],
[2983,149150,136500000,730000000,11186250000],
[2984,149200,136500000,731000000,11190000000],
[2985,149250,136500000,731000000,11193750000],
[2986,149300,136500000,732000000,11197500000],
[2987,149350,137000000,732000000,11201250000],
[2988,149400,137000000,733000000,11205000000],
[2989,149450,137000000,733000000,11208750000],
[2990,149500,137000000,734000000,11212500000],
[2991,149550,137000000,734000000,11216250000],
[2992,149600,137000000,735000000,11220000000],
[2993,149650,137500000,735000000,11223750000],
[2994,149700,137500000,736000000,11227500000],
[2995,149750,137500000,736000000,11231250000],
[2996,149800,137500000,737000000,11235000000],
[2997,149850,137500000,737000000,11238750000],
[2998,149900,137500000,738000000,11242500000],
[2999,149950,138000000,738000000,11246250000],
[3000,150000,138000000,739000000,11250000000],
[3001,150050,138000000,739000000,11253750000],
[3002,150100,138000000,739000000,11257500000],
[3003,150150,138000000,740000000,11261250000],
[3004,150200,138000000,740000000,11265000000],
[3005,150250,138500000,741000000,11268750000],
[3006,150300,138500000,741000000,11272500000],
[3007,150350,138500000,742000000,11276250000],
[3008,150400,138500000,742000000,11280000000],
[3009,150450,138500000,743000000,11283750000],
[3010,150500,138500000,743000000,11287500000],
[3011,150550,139000000,744000000,11291250000],
[3012,150600,139000000,744000000,11295000000],
[3013,150650,139000000,745000000,11298750000],
[3014,150700,139000000,745000000,11302500000],
[3015,150750,139000000,746000000,11306250000],
[3016,150800,139000000,746000000,11310000000],
[3017,150850,139500000,747000000,11313750000],
[3018,150900,139500000,747000000,11317500000],
[3019,150950,139500000,748000000,11321250000],
[3020,151000,139500000,748000000,11325000000],
[3021,151050,139500000,749000000,11328750000],
[3022,151100,139500000,749000000,11332500000],
[3023,151150,140000000,750000000,11336250000],
[3024,151200,140000000,750000000,11340000000],
[3025,151250,140000000,750000000,11343750000],
[3026,151300,140000000,751000000,11347500000],
[3027,151350,140000000,751000000,11351250000],
[3028,151400,140500000,752000000,11355000000],
[3029,151450,140500000,752000000,11358750000],
[3030,151500,140500000,753000000,11362500000],
[3031,151550,140500000,753000000,11366250000],
[3032,151600,140500000,754000000,11370000000],
[3033,151650,140500000,754000000,11373750000],
[3034,151700,141000000,755000000,11377500000],
[3035,151750,141000000,755000000,11381250000],
[3036,151800,141000000,756000000,11385000000],
[3037,151850,141000000,756000000,11388750000],
[3038,151900,141000000,757000000,11392500000],
[3039,151950,141000000,757000000,11396250000],
[3040,152000,141500000,758000000,11400000000],
[3041,152050,141500000,758000000,11403750000],
[3042,152100,141500000,759000000,11407500000],
[3043,152150,141500000,759000000,11411250000],
[3044,152200,141500000,760000000,11415000000],
[3045,152250,141500000,760000000,11418750000],
[3046,152300,142000000,761000000,11422500000],
[3047,152350,142000000,761000000,11426250000],
[3048,152400,142000000,762000000,11430000000],
[3049,152450,142000000,762000000,11433750000],
[3050,152500,142000000,763000000,11437500000],
[3051,152550,142000000,763000000,11441250000],
[3052,152600,142500000,764000000,11445000000],
[3053,152650,142500000,764000000,11448750000],
[3054,152700,142500000,764000000,11452500000],
[3055,152750,142500000,765000000,11456250000],
[3056,152800,142500000,765000000,11460000000],
[3057,152850,142500000,766000000,11463750000],
[3058,152900,143000000,766000000,11467500000],
[3059,152950,143000000,767000000,11471250000],
[3060,153000,143000000,767000000,11475000000],
[3061,153050,143000000,768000000,11478750000],
[3062,153100,143000000,768000000,11482500000],
[3063,153150,143000000,769000000,11486250000],
[3064,153200,143500000,769000000,11490000000],
[3065,153250,143500000,770000000,11493750000],
[3066,153300,143500000,770000000,11497500000],
[3067,153350,143500000,771000000,11501250000],
[3068,153400,143500000,771000000,11505000000],
[3069,153450,143500000,772000000,11508750000],
[3070,153500,144000000,772000000,11512500000],
[3071,153550,144000000,773000000,11516250000],
[3072,153600,144000000,773000000,11520000000],
[3073,153650,144000000,774000000,11523750000],
[3074,153700,144000000,774000000,11527500000],
[3075,153750,144000000,775000000,11531250000],
[3076,153800,144500000,775000000,11535000000],
[3077,153850,144500000,776000000,11538750000],
[3078,153900,144500000,776000000,11542500000],
[3079,153950,144500000,777000000,11546250000],
[3080,154000,144500000,777000000,11550000000],
[3081,154050,145000000,778000000,11553750000],
[3082,154100,145000000,778000000,11557500000],
[3083,154150,145000000,779000000,11561250000],
[3084,154200,145000000,779000000,11565000000],
[3085,154250,145000000,780000000,11568750000],
[3086,154300,145000000,780000000,11572500000],
[3087,154350,145500000,781000000,11576250000],
[3088,154400,145500000,781000000,11580000000],
[3089,154450,145500000,782000000,11583750000],
[3090,154500,145500000,782000000,11587500000],
[3091,154550,145500000,782000000,11591250000],
[3092,154600,145500000,783000000,11595000000],
[3093,154650,146000000,783000000,11598750000],
[3094,154700,146000000,784000000,11602500000],
[3095,154750,146000000,784000000,11606250000],
[3096,154800,146000000,785000000,11610000000],
[3097,154850,146000000,785000000,11613750000],
[3098,154900,146000000,786000000,11617500000],
[3099,154950,146500000,786000000,11621250000],
[3100,155000,146500000,787000000,11625000000],
[3101,155050,146500000,787000000,11628750000],
[3102,155100,146500000,788000000,11632500000],
[3103,155150,146500000,788000000,11636250000],
[3104,155200,146500000,789000000,11640000000],
[3105,155250,147000000,789000000,11643750000],
[3106,155300,147000000,790000000,11647500000],
[3107,155350,147000000,790000000,11651250000],
[3108,155400,147000000,791000000,11655000000],
[3109,155450,147000000,791000000,11658750000],
[3110,155500,147000000,792000000,11662500000],
[3111,155550,147500000,792000000,11666250000],
[3112,155600,147500000,793000000,11670000000],
[3113,155650,147500000,793000000,11673750000],
[3114,155700,147500000,794000000,11677500000],
[3115,155750,147500000,794000000,11681250000],
[3116,155800,148000000,795000000,11685000000],
[3117,155850,148000000,795000000,11688750000],
[3118,155900,148000000,796000000,11692500000],
[3119,155950,148000000,796000000,11696250000],
[3120,156000,148000000,797000000,11700000000],
[3121,156050,148000000,797000000,11703750000],
[3122,156100,148500000,798000000,11707500000],
[3123,156150,148500000,798000000,11711250000],
[3124,156200,148500000,799000000,11715000000],
[3125,156250,148500000,799000000,11718750000],
[3126,156300,148500000,800000000,11722500000],
[3127,156350,148500000,800000000,11726250000],
[3128,156400,149000000,801000000,11730000000],
[3129,156450,149000000,801000000,11733750000],
[3130,156500,149000000,802000000,11737500000],
[3131,156550,149000000,802000000,11741250000],
[3132,156600,149000000,803000000,11745000000],
[3133,156650,149000000,803000000,11748750000],
[3134,156700,149500000,804000000,11752500000],
[3135,156750,149500000,804000000,11756250000],
[3136,156800,149500000,805000000,11760000000],
[3137,156850,149500000,805000000,11763750000],
[3138,156900,149500000,806000000,11767500000],
[3139,156950,150000000,806000000,11771250000],
[3140,157000,150000000,807000000,11775000000],
[3141,157050,150000000,807000000,11778750000],
[3142,157100,150000000,808000000,11782500000],
[3143,157150,150000000,808000000,11786250000],
[3144,157200,150000000,809000000,11790000000],
[3145,157250,150500000,809000000,11793750000],
[3146,157300,150500000,810000000,11797500000],
[3147,157350,150500000,810000000,11801250000],
[3148,157400,150500000,811000000,11805000000],
[3149,157450,150500000,811000000,11808750000],
[3150,157500,150500000,812000000,11812500000],
[3151,157550,151000000,812000000,11816250000],
[3152,157600,151000000,813000000,11820000000],
[3153,157650,151000000,813000000,11823750000],
[3154,157700,151000000,814000000,11827500000],
[3155,157750,151000000,814000000,11831250000],
[3156,157800,151000000,815000000,11835000000],
[3157,157850,151500000,815000000,11838750000],
[3158,157900,151500000,816000000,11842500000],
[3159,157950,151500000,816000000,11846250000],
[3160,158000,151500000,817000000,11850000000],
[3161,158050,151500000,817000000,11853750000],
[3162,158100,152000000,818000000,11857500000],
[3163,158150,152000000,818000000,11861250000],
[3164,158200,152000000,819000000,11865000000],
[3165,158250,152000000,819000000,11868750000],
[3166,158300,152000000,820000000,11872500000],
[3167,158350,152000000,820000000,11876250000],
[3168,158400,152500000,821000000,11880000000],
[3169,158450,152500000,821000000,11883750000],
[3170,158500,152500000,822000000,11887500000],
[3171,158550,152500000,822000000,11891250000],
[3172,158600,152500000,823000000,11895000000],
[3173,158650,152500000,823000000,11898750000],
[3174,158700,153000000,824000000,11902500000],
[3175,158750,153000000,824000000,11906250000],
[3176,158800,153000000,825000000,11910000000],
[3177,158850,153000000,825000000,11913750000],
[3178,158900,153000000,826000000,11917500000],
[3179,158950,153000000,826000000,11921250000],
[3180,159000,153500000,827000000,11925000000],
[3181,159050,153500000,827000000,11928750000],
[3182,159100,153500000,828000000,11932500000],
[3183,159150,153500000,828000000,11936250000],
[3184,159200,153500000,829000000,11940000000],
[3185,159250,154000000,829000000,11943750000],
[3186,159300,154000000,830000000,11947500000],
[3187,159350,154000000,830000000,11951250000],
[3188,159400,154000000,831000000,11955000000],
[3189,159450,154000000,831000000,11958750000],
[3190,159500,154000000,832000000,11962500000],
[3191,159550,154500000,832000000,11966250000],
[3192,159600,154500000,833000000,11970000000],
[3193,159650,154500000,833000000,11973750000],
[3194,159700,154500000,834000000,11977500000],
[3195,159750,154500000,834000000,11981250000],
[3196,159800,154500000,835000000,11985000000],
[3197,159850,155000000,835000000,11988750000],
[3198,159900,155000000,836000000,11992500000],
[3199,159950,155000000,836000000,11996250000],
[3200,160000,155000000,837000000,12000000000],
[3201,160050,155000000,837000000,12003750000],
[3202,160100,155500000,838000000,12007500000],
[3203,160150,155500000,838000000,12011250000],
[3204,160200,155500000,839000000,12015000000],
[3205,160250,155500000,839000000,12018750000],
[3206,160300,155500000,840000000,12022500000],
[3207,160350,155500000,840000000,12026250000],
[3208,160400,156000000,841000000,12030000000],
[3209,160450,156000000,841000000,12033750000],
[3210,160500,156000000,842000000,12037500000],
[3211,160550,156000000,842000000,12041250000],
[3212,160600,156000000,843000000,12045000000],
[3213,160650,156000000,843000000,12048750000],
[3214,160700,156500000,844000000,12052500000],
[3215,160750,156500000,844000000,12056250000],
[3216,160800,156500000,845000000,12060000000],
[3217,160850,156500000,845000000,12063750000],
[3218,160900,156500000,846000000,12067500000],
[3219,160950,157000000,846000000,12071250000],
[3220,161000,157000000,847000000,12075000000],
[3221,161050,157000000,847000000,12078750000],
[3222,161100,157000000,848000000,12082500000],
[3223,161150,157000000,848000000,12086250000],
[3224,161200,157000000,849000000,12090000000],
[3225,161250,157500000,849000000,12093750000],
[3226,161300,157500000,850000000,12097500000],
[3227,161350,157500000,850000000,12101250000],
[3228,161400,157500000,851000000,12105000000],
[3229,161450,157500000,852000000,12108750000],
[3230,161500,158000000,852000000,12112500000],
[3231,161550,158000000,853000000,12116250000],
[3232,161600,158000000,853000000,12120000000],
[3233,161650,158000000,854000000,12123750000],
[3234,161700,158000000,854000000,12127500000],
[3235,161750,158000000,855000000,12131250000],
[3236,161800,158500000,855000000,12135000000],
[3237,161850,158500000,856000000,12138750000],
[3238,161900,158500000,856000000,12142500000],
[3239,161950,158500000,857000000,12146250000],
[3240,162000,158500000,857000000,12150000000],
[3241,162050,158500000,858000000,12153750000],
[3242,162100,159000000,858000000,12157500000],
[3243,162150,159000000,859000000,12161250000],
[3244,162200,159000000,859000000,12165000000],
[3245,162250,159000000,860000000,12168750000],
[3246,162300,159000000,860000000,12172500000],
[3247,162350,159500000,861000000,12176250000],
[3248,162400,159500000,861000000,12180000000],
[3249,162450,159500000,862000000,12183750000],
[3250,162500,159500000,862000000,12187500000],
[3251,162550,159500000,863000000,12191250000],
[3252,162600,159500000,863000000,12195000000],
[3253,162650,160000000,864000000,12198750000],
[3254,162700,160000000,864000000,12202500000],
[3255,162750,160000000,865000000,12206250000],
[3256,162800,160000000,865000000,12210000000],
[3257,162850,160000000,866000000,12213750000],
[3258,162900,160500000,866000000,12217500000],
[3259,162950,160500000,867000000,12221250000],
[3260,163000,160500000,867000000,12225000000],
[3261,163050,160500000,868000000,12228750000],
[3262,163100,160500000,868000000,12232500000],
[3263,163150,160500000,869000000,12236250000],
[3264,163200,161000000,869000000,12240000000],
[3265,163250,161000000,870000000,12243750000],
[3266,163300,161000000,870000000,12247500000],
[3267,163350,161000000,871000000,12251250000],
[3268,163400,161000000,872000000,12255000000],
[3269,163450,161000000,872000000,12258750000],
[3270,163500,161500000,873000000,12262500000],
[3271,163550,161500000,873000000,12266250000],
[3272,163600,161500000,874000000,12270000000],
[3273,163650,161500000,874000000,12273750000],
[3274,163700,161500000,875000000,12277500000],
[3275,163750,162000000,875000000,12281250000],
[3276,163800,162000000,876000000,12285000000],
[3277,163850,162000000,876000000,12288750000],
[3278,163900,162000000,877000000,12292500000],
[3279,163950,162000000,877000000,12296250000],
[3280,164000,162000000,878000000,12300000000],
[3281,164050,162500000,878000000,12303750000],
[3282,164100,162500000,879000000,12307500000],
[3283,164150,162500000,879000000,12311250000],
[3284,164200,162500000,880000000,12315000000],
[3285,164250,162500000,880000000,12318750000],
[3286,164300,163000000,881000000,12322500000],
[3287,164350,163000000,881000000,12326250000],
[3288,164400,163000000,882000000,12330000000],
[3289,164450,163000000,882000000,12333750000],
[3290,164500,163000000,883000000,12337500000],
[3291,164550,163000000,883000000,12341250000],
[3292,164600,163500000,884000000,12345000000],
[3293,164650,163500000,884000000,12348750000],
[3294,164700,163500000,885000000,12352500000],
[3295,164750,163500000,886000000,12356250000],
[3296,164800,163500000,886000000,12360000000],
[3297,164850,164000000,887000000,12363750000],
[3298,164900,164000000,887000000,12367500000],
[3299,164950,164000000,888000000,12371250000],
[3300,165000,164000000,888000000,12375000000],
[3301,165050,164000000,889000000,12378750000],
[3302,165100,164000000,889000000,12382500000],
[3303,165150,164500000,890000000,12386250000],
[3304,165200,164500000,890000000,12390000000],
[3305,165250,164500000,891000000,12393750000],
[3306,165300,164500000,891000000,12397500000],
[3307,165350,164500000,892000000,12401250000],
[3308,165400,165000000,892000000,12405000000],
[3309,165450,165000000,893000000,12408750000],
[3310,165500,165000000,893000000,12412500000],
[3311,165550,165000000,894000000,12416250000],
[3312,165600,165000000,894000000,12420000000],
[3313,165650,165000000,895000000,12423750000],
[3314,165700,165500000,895000000,12427500000],
[3315,165750,165500000,896000000,12431250000],
[3316,165800,165500000,896000000,12435000000],
[3317,165850,165500000,897000000,12438750000],
[3318,165900,165500000,898000000,12442500000],
[3319,165950,166000000,898000000,12446250000],
[3320,166000,166000000,899000000,12450000000],
[3321,166050,166000000,899000000,12453750000],
[3322,166100,166000000,900000000,12457500000],
[3323,166150,166000000,900000000,12461250000],
[3324,166200,166000000,901000000,12465000000],
[3325,166250,166500000,901000000,12468750000],
[3326,166300,166500000,902000000,12472500000],
[3327,166350,166500000,902000000,12476250000],
[3328,166400,166500000,903000000,12480000000],
[3329,166450,166500000,903000000,12483750000],
[3330,166500,167000000,904000000,12487500000],
[3331,166550,167000000,904000000,12491250000],
[3332,166600,167000000,905000000,12495000000],
[3333,166650,167000000,905000000,12498750000],
[3334,166700,167000000,906000000,12502500000],
[3335,166750,167000000,906000000,12506250000],
[3336,166800,167500000,907000000,12510000000],
[3337,166850,167500000,907000000,12513750000],
[3338,166900,167500000,908000000,12517500000],
[3339,166950,167500000,909000000,12521250000],
[3340,167000,167500000,909000000,12525000000],
[3341,167050,168000000,910000000,12528750000],
[3342,167100,168000000,910000000,12532500000],
[3343,167150,168000000,911000000,12536250000],
[3344,167200,168000000,911000000,12540000000],
[3345,167250,168000000,912000000,12543750000],
[3346,167300,168000000,912000000,12547500000],
[3347,167350,168500000,913000000,12551250000],
[3348,167400,168500000,913000000,12555000000],
[3349,167450,168500000,914000000,12558750000],
[3350,167500,168500000,914000000,12562500000],
[3351,167550,168500000,915000000,12566250000],
[3352,167600,169000000,915000000,12570000000],
[3353,167650,169000000,916000000,12573750000],
[3354,167700,169000000,916000000,12577500000],
[3355,167750,169000000,917000000,12581250000],
[3356,167800,169000000,918000000,12585000000],
[3357,167850,169000000,918000000,12588750000],
[3358,167900,169500000,919000000,12592500000],
[3359,167950,169500000,919000000,12596250000],
[3360,168000,169500000,920000000,12600000000],
[3361,168050,169500000,920000000,12603750000],
[3362,168100,169500000,921000000,12607500000],
[3363,168150,170000000,921000000,12611250000],
[3364,168200,170000000,922000000,12615000000],
[3365,168250,170000000,922000000,12618750000],
[3366,168300,170000000,923000000,12622500000],
[3367,168350,170000000,923000000,12626250000],
[3368,168400,170000000,924000000,12630000000],
[3369,168450,170500000,924000000,12633750000],
[3370,168500,170500000,925000000,12637500000],
[3371,168550,170500000,925000000,12641250000],
[3372,168600,170500000,926000000,12645000000],
[3373,168650,170500000,927000000,12648750000],
[3374,168700,171000000,927000000,12652500000],
[3375,168750,171000000,928000000,12656250000],
[3376,168800,171000000,928000000,12660000000],
[3377,168850,171000000,929000000,12663750000],
[3378,168900,171000000,929000000,12667500000],
[3379,168950,171500000,930000000,12671250000],
[3380,169000,171500000,930000000,12675000000],
[3381,169050,171500000,931000000,12678750000],
[3382,169100,171500000,931000000,12682500000],
[3383,169150,171500000,932000000,12686250000],
[3384,169200,171500000,932000000,12690000000],
[3385,169250,172000000,933000000,12693750000],
[3386,169300,172000000,933000000,12697500000],
[3387,169350,172000000,934000000,12701250000],
[3388,169400,172000000,935000000,12705000000],
[3389,169450,172000000,935000000,12708750000],
[3390,169500,172500000,936000000,12712500000],
[3391,169550,172500000,936000000,12716250000],
[3392,169600,172500000,937000000,12720000000],
[3393,169650,172500000,937000000,12723750000],
[3394,169700,172500000,938000000,12727500000],
[3395,169750,172500000,938000000,12731250000],
[3396,169800,173000000,939000000,12735000000],
[3397,169850,173000000,939000000,12738750000],
[3398,169900,173000000,940000000,12742500000],
[3399,169950,173000000,940000000,12746250000],
[3400,170000,173000000,941000000,12750000000],
[3401,170050,173500000,941000000,12753750000],
[3402,170100,173500000,942000000,12757500000],
[3403,170150,173500000,943000000,12761250000],
[3404,170200,173500000,943000000,12765000000],
[3405,170250,173500000,944000000,12768750000],
[3406,170300,174000000,944000000,12772500000],
[3407,170350,174000000,945000000,12776250000],
[3408,170400,174000000,945000000,12780000000],
[3409,170450,174000000,946000000,12783750000],
[3410,170500,174000000,946000000,12787500000],
[3411,170550,174000000,947000000,12791250000],
[3412,170600,174500000,947000000,12795000000],
[3413,170650,174500000,948000000,12798750000],
[3414,170700,174500000,948000000,12802500000],
[3415,170750,174500000,949000000,12806250000],
[3416,170800,174500000,950000000,12810000000],
[3417,170850,175000000,950000000,12813750000],
[3418,170900,175000000,951000000,12817500000],
[3419,170950,175000000,951000000,12821250000],
[3420,171000,175000000,952000000,12825000000],
[3421,171050,175000000,952000000,12828750000],
[3422,171100,175000000,953000000,12832500000],
[3423,171150,175500000,953000000,12836250000],
[3424,171200,175500000,954000000,12840000000],
[3425,171250,175500000,954000000,12843750000],
[3426,171300,175500000,955000000,12847500000],
[3427,171350,175500000,955000000,12851250000],
[3428,171400,176000000,956000000,12855000000],
[3429,171450,176000000,957000000,12858750000],
[3430,171500,176000000,957000000,12862500000],
[3431,171550,176000000,958000000,12866250000],
[3432,171600,176000000,958000000,12870000000],
[3433,171650,176500000,959000000,12873750000],
[3434,171700,176500000,959000000,12877500000],
[3435,171750,176500000,960000000,12881250000],
[3436,171800,176500000,960000000,12885000000],
[3437,171850,176500000,961000000,12888750000],
[3438,171900,176500000,961000000,12892500000],
[3439,171950,177000000,962000000,12896250000],
[3440,172000,177000000,962000000,12900000000],
[3441,172050,177000000,963000000,12903750000],
[3442,172100,177000000,964000000,12907500000],
[3443,172150,177000000,964000000,12911250000],
[3444,172200,177500000,965000000,12915000000],
[3445,172250,177500000,965000000,12918750000],
[3446,172300,177500000,966000000,12922500000],
[3447,172350,177500000,966000000,12926250000],
[3448,172400,177500000,967000000,12930000000],
[3449,172450,178000000,967000000,12933750000],
[3450,172500,178000000,968000000,12937500000],
[3451,172550,178000000,968000000,12941250000],
[3452,172600,178000000,969000000,12945000000],
[3453,172650,178000000,970000000,12948750000],
[3454,172700,178000000,970000000,12952500000],
[3455,172750,178500000,971000000,12956250000],
[3456,172800,178500000,971000000,12960000000],
[3457,172850,178500000,972000000,12963750000],
[3458,172900,178500000,972000000,12967500000],
[3459,172950,178500000,973000000,12971250000],
[3460,173000,179000000,973000000,12975000000],
[3461,173050,179000000,974000000,12978750000],
[3462,173100,179000000,974000000,12982500000],
[3463,173150,179000000,975000000,12986250000],
[3464,173200,179000000,976000000,12990000000],
[3465,173250,179500000,976000000,12993750000],
[3466,173300,179500000,977000000,12997500000],
[3467,173350,179500000,977000000,13001250000],
[3468,173400,179500000,978000000,13005000000],
[3469,173450,179500000,978000000,13008750000],
[3470,173500,179500000,979000000,13012500000],
[3471,173550,180000000,979000000,13016250000],
[3472,173600,180000000,980000000,13020000000],
[3473,173650,180000000,980000000,13023750000],
[3474,173700,180000000,981000000,13027500000],
[3475,173750,180000000,982000000,13031250000],
[3476,173800,180500000,982000000,13035000000],
[3477,173850,180500000,983000000,13038750000],
[3478,173900,180500000,983000000,13042500000],
[3479,173950,180500000,984000000,13046250000],
[3480,174000,180500000,984000000,13050000000],
[3481,174050,181000000,985000000,13053750000],
[3482,174100,181000000,985000000,13057500000],
[3483,174150,181000000,986000000,13061250000],
[3484,174200,181000000,986000000,13065000000],
[3485,174250,181000000,987000000,13068750000],
[3486,174300,181500000,988000000,13072500000],
[3487,174350,181500000,988000000,13076250000],
[3488,174400,181500000,989000000,13080000000],
[3489,174450,181500000,989000000,13083750000],
[3490,174500,181500000,990000000,13087500000],
[3491,174550,181500000,990000000,13091250000],
[3492,174600,182000000,991000000,13095000000],
[3493,174650,182000000,991000000,13098750000],
[3494,174700,182000000,992000000,13102500000],
[3495,174750,182000000,992000000,13106250000],
[3496,174800,182000000,993000000,13110000000],
[3497,174850,182500000,994000000,13113750000],
[3498,174900,182500000,994000000,13117500000],
[3499,174950,182500000,995000000,13121250000],
[3500,175000,182500000,995000000,13125000000],
[3501,175050,182500000,996000000,13128750000],
[3502,175100,183000000,996000000,13132500000],
[3503,175150,183000000,997000000,13136250000],
[3504,175200,183000000,997000000,13140000000],
[3505,175250,183000000,998000000,13143750000],
[3506,175300,183000000,999000000,13147500000],
[3507,175350,183000000,999000000,13151250000],
[3508,175400,183500000,1000000000,13155000000],
[3509,175450,183500000,1000000000,13158750000],
[3510,175500,183500000,1001000000,13162500000],
[3511,175550,183500000,1001000000,13166250000],
[3512,175600,183500000,1002000000,13170000000],
[3513,175650,184000000,1002000000,13173750000],
[3514,175700,184000000,1003000000,13177500000],
[3515,175750,184000000,1003000000,13181250000],
[3516,175800,184000000,1004000000,13185000000],
[3517,175850,184000000,1005000000,13188750000],
[3518,175900,184500000,1005000000,13192500000],
[3519,175950,184500000,1006000000,13196250000],
[3520,176000,184500000,1006000000,13200000000],
[3521,176050,184500000,1007000000,13203750000],
[3522,176100,184500000,1007000000,13207500000],
[3523,176150,185000000,1008000000,13211250000],
[3524,176200,185000000,1008000000,13215000000],
[3525,176250,185000000,1009000000,13218750000],
[3526,176300,185000000,1010000000,13222500000],
[3527,176350,185000000,1010000000,13226250000],
[3528,176400,185500000,1011000000,13230000000],
[3529,176450,185500000,1011000000,13233750000],
[3530,176500,185500000,1012000000,13237500000],
[3531,176550,185500000,1012000000,13241250000],
[3532,176600,185500000,1013000000,13245000000],
[3533,176650,185500000,1013000000,13248750000],
[3534,176700,186000000,1014000000,13252500000],
[3535,176750,186000000,1015000000,13256250000],
[3536,176800,186000000,1015000000,13260000000],
[3537,176850,186000000,1016000000,13263750000],
[3538,176900,186000000,1016000000,13267500000],
[3539,176950,186500000,1017000000,13271250000],
[3540,177000,186500000,1017000000,13275000000],
[3541,177050,186500000,1018000000,13278750000],
[3542,177100,186500000,1018000000,13282500000],
[3543,177150,186500000,1019000000,13286250000],
[3544,177200,187000000,1020000000,13290000000],
[3545,177250,187000000,1020000000,13293750000],
[3546,177300,187000000,1021000000,13297500000],
[3547,177350,187000000,1021000000,13301250000],
[3548,177400,187000000,1022000000,13305000000],
[3549,177450,187500000,1022000000,13308750000],
[3550,177500,187500000,1023000000,13312500000],
[3551,177550,187500000,1023000000,13316250000],
[3552,177600,187500000,1024000000,13320000000],
[3553,177650,187500000,1025000000,13323750000],
[3554,177700,187500000,1025000000,13327500000],
[3555,177750,188000000,1026000000,13331250000],
[3556,177800,188000000,1026000000,13335000000],
[3557,177850,188000000,1027000000,13338750000],
[3558,177900,188000000,1027000000,13342500000],
[3559,177950,188000000,1028000000,13346250000],
[3560,178000,188500000,1028000000,13350000000],
[3561,178050,188500000,1029000000,13353750000],
[3562,178100,188500000,1030000000,13357500000],
[3563,178150,188500000,1030000000,13361250000],
[3564,178200,188500000,1031000000,13365000000],
[3565,178250,189000000,1031000000,13368750000],
[3566,178300,189000000,1032000000,13372500000],
[3567,178350,189000000,1032000000,13376250000],
[3568,178400,189000000,1033000000,13380000000],
[3569,178450,189000000,1034000000,13383750000],
[3570,178500,189500000,1034000000,13387500000],
[3571,178550,189500000,1035000000,13391250000],
[3572,178600,189500000,1035000000,13395000000],
[3573,178650,189500000,1036000000,13398750000],
[3574,178700,189500000,1036000000,13402500000],
[3575,178750,190000000,1037000000,13406250000],
[3576,178800,190000000,1037000000,13410000000],
[3577,178850,190000000,1038000000,13413750000],
[3578,178900,190000000,1039000000,13417500000],
[3579,178950,190000000,1039000000,13421250000],
[3580,179000,190500000,1040000000,13425000000],
[3581,179050,190500000,1040000000,13428750000],
[3582,179100,190500000,1041000000,13432500000],
[3583,179150,190500000,1041000000,13436250000],
[3584,179200,190500000,1042000000,13440000000],
[3585,179250,190500000,1043000000,13443750000],
[3586,179300,191000000,1043000000,13447500000],
[3587,179350,191000000,1044000000,13451250000],
[3588,179400,191000000,1044000000,13455000000],
[3589,179450,191000000,1045000000,13458750000],
[3590,179500,191000000,1045000000,13462500000],
[3591,179550,191500000,1046000000,13466250000],
[3592,179600,191500000,1046000000,13470000000],
[3593,179650,191500000,1047000000,13473750000],
[3594,179700,191500000,1048000000,13477500000],
[3595,179750,191500000,1048000000,13481250000],
[3596,179800,192000000,1049000000,13485000000],
[3597,179850,192000000,1049000000,13488750000],
[3598,179900,192000000,1050000000,13492500000],
[3599,179950,192000000,1050000000,13496250000],
[3600,180000,192000000,1051000000,13500000000],
[3601,180050,192500000,1052000000,13503750000],
[3602,180100,192500000,1052000000,13507500000],
[3603,180150,192500000,1053000000,13511250000],
[3604,180200,192500000,1053000000,13515000000],
[3605,180250,192500000,1054000000,13518750000],
[3606,180300,193000000,1054000000,13522500000],
[3607,180350,193000000,1055000000,13526250000],
[3608,180400,193000000,1055000000,13530000000],
[3609,180450,193000000,1056000000,13533750000],
[3610,180500,193000000,1057000000,13537500000],
[3611,180550,193500000,1057000000,13541250000],
[3612,180600,193500000,1058000000,13545000000],
[3613,180650,193500000,1058000000,13548750000],
[3614,180700,193500000,1059000000,13552500000],
[3615,180750,193500000,1059000000,13556250000],
[3616,180800,193500000,1060000000,13560000000],
[3617,180850,194000000,1061000000,13563750000],
[3618,180900,194000000,1061000000,13567500000],
[3619,180950,194000000,1062000000,13571250000],
[3620,181000,194000000,1062000000,13575000000],
[3621,181050,194000000,1063000000,13578750000],
[3622,181100,194500000,1063000000,13582500000],
[3623,181150,194500000,1064000000,13586250000],
[3624,181200,194500000,1065000000,13590000000],
[3625,181250,194500000,1065000000,13593750000],
[3626,181300,194500000,1066000000,13597500000],
[3627,181350,195000000,1066000000,13601250000],
[3628,181400,195000000,1067000000,13605000000],
[3629,181450,195000000,1067000000,13608750000],
[3630,181500,195000000,1068000000,13612500000],
[3631,181550,195000000,1069000000,13616250000],
[3632,181600,195500000,1069000000,13620000000],
[3633,181650,195500000,1070000000,13623750000],
[3634,181700,195500000,1070000000,13627500000],
[3635,181750,195500000,1071000000,13631250000],
[3636,181800,195500000,1071000000,13635000000],
[3637,181850,196000000,1072000000,13638750000],
[3638,181900,196000000,1073000000,13642500000],
[3639,181950,196000000,1073000000,13646250000],
[3640,182000,196000000,1074000000,13650000000],
[3641,182050,196000000,1074000000,13653750000],
[3642,182100,196500000,1075000000,13657500000],
[3643,182150,196500000,1075000000,13661250000],
[3644,182200,196500000,1076000000,13665000000],
[3645,182250,196500000,1077000000,13668750000],
[3646,182300,196500000,1077000000,13672500000],
[3647,182350,197000000,1078000000,13676250000],
[3648,182400,197000000,1078000000,13680000000],
[3649,182450,197000000,1079000000,13683750000],
[3650,182500,197000000,1079000000,13687500000],
[3651,182550,197000000,1080000000,13691250000],
[3652,182600,197500000,1081000000,13695000000],
[3653,182650,197500000,1081000000,13698750000],
[3654,182700,197500000,1082000000,13702500000],
[3655,182750,197500000,1082000000,13706250000],
[3656,182800,197500000,1083000000,13710000000],
[3657,182850,198000000,1083000000,13713750000],
[3658,182900,198000000,1084000000,13717500000],
[3659,182950,198000000,1085000000,13721250000],
[3660,183000,198000000,1085000000,13725000000],
[3661,183050,198000000,1086000000,13728750000],
[3662,183100,198500000,1086000000,13732500000],
[3663,183150,198500000,1087000000,13736250000],
[3664,183200,198500000,1087000000,13740000000],
[3665,183250,198500000,1088000000,13743750000],
[3666,183300,198500000,1089000000,13747500000],
[3667,183350,199000000,1089000000,13751250000],
[3668,183400,199000000,1090000000,13755000000],
[3669,183450,199000000,1090000000,13758750000],
[3670,183500,199000000,1091000000,13762500000],
[3671,183550,199000000,1091000000,13766250000],
[3672,183600,199000000,1092000000,13770000000],
[3673,183650,199500000,1093000000,13773750000],
[3674,183700,199500000,1093000000,13777500000],
[3675,183750,199500000,1094000000,13781250000],
[3676,183800,199500000,1094000000,13785000000],
[3677,183850,199500000,1095000000,13788750000],
[3678,183900,200000000,1095000000,13792500000],
[3679,183950,200000000,1096000000,13796250000],
[3680,184000,200000000,1097000000,13800000000],
[3681,184050,200000000,1097000000,13803750000],
[3682,184100,200000000,1098000000,13807500000],
[3683,184150,200500000,1098000000,13811250000],
[3684,184200,200500000,1099000000,13815000000],
[3685,184250,200500000,1100000000,13818750000],
[3686,184300,200500000,1100000000,13822500000],
[3687,184350,200500000,1101000000,13826250000],
[3688,184400,201000000,1101000000,13830000000],
[3689,184450,201000000,1102000000,13833750000],
[3690,184500,201000000,1102000000,13837500000],
[3691,184550,201000000,1103000000,13841250000],
[3692,184600,201000000,1104000000,13845000000],
[3693,184650,201500000,1104000000,13848750000],
[3694,184700,201500000,1105000000,13852500000],
[3695,184750,201500000,1105000000,13856250000],
[3696,184800,201500000,1106000000,13860000000],
[3697,184850,201500000,1106000000,13863750000],
[3698,184900,202000000,1107000000,13867500000],
[3699,184950,202000000,1108000000,13871250000],
[3700,185000,202000000,1108000000,13875000000]
]
