import React from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';

export default function Output(props) {
  const siteName = window.location.pathname;
  let paymentTerm = props.paymentTerm;
  if(paymentTerm === 'DEFAULT'){
    paymentTerm = '-';
  }
  let thousandSeparator = ',';
  let decimalSeparator = '.'
  if(props.separator === '.'){
    thousandSeparator = '.';
    decimalSeparator = ',';
  }
  else{
    thousandSeparator = ',';
    decimalSeparator = '.';
  }
  var attribution = 0;
  if(props.volumePeriod === 'Quarterly'){attribution = parseFloat(props.attribution / 3);}
  else if(props.volumePeriod === 'Annual'){attribution = parseFloat(props.attribution / 12);}
  else if(props.volumePeriod === 'Bi-Annual'){attribution = parseFloat(props.attribution / 24);}
  else if(props.volumePeriod === 'Monthly'){attribution = props.attribution;}
return (
  <div >
    <Badge bg="success" style={{width:'100%', height:'30px'}}>
        <div style={{fontSize:'18px', marginTop:'2px'}}>OUTPUT: Recommended Price</div>
    </Badge>

      <Row style={{width:'100%',marginTop:'4px'}}>
        <Col>
          <Badge bg="secondary" style={{width:'100%', height:'30px'}}>
            <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Term Length</div>
          </Badge>
        </Col>

        <Col xs={6}>
          <Card style={{height:'30px'}}>
            <div style={{width:'100%',textAlign: 'center'}}>{props.contractLength}</div>
          </Card>
        </Col>
      </Row>

      <Row style={{width:'100%',marginTop:'4px'}}>
        <Col>
          <Badge bg="secondary" style={{width:'100%', height:'30px'}}>
            <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Payment Terms</div>
          </Badge>
        </Col>

        <Col xs={6}>
          <Card style={{height:'30px'}}>
            <div style={{width:'100%',textAlign: 'center'}}>{paymentTerm}</div>
          </Card>
        </Col>
      </Row>

      <Row style={{width:'100%',marginTop:'4px'}}>
        <Col xs={6}>
          <Badge bg="secondary" style={{width:'100%', height:'30px'}}>
              {siteName === '/' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Incl. Volume Allowance (monthly)</div>
              }
              {siteName === '/mau' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Incl. Volume Allowance (monthly)</div>
              }
              {siteName === '/data' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Incl. Volume Allowance in Billion (monthly)</div>
              }
          </Badge>
        </Col>

        <Col xs={6}>
          <Card style={{height:'30px'}}>
            <div style={{width:'100%',textAlign: 'center'}}>
              {siteName === '/data' &&
              <NumberFormat value={Math.round(attribution * 100) / 100} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
              }
              {siteName !== '/data' &&
              <NumberFormat value={Math.ceil(attribution)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
              }
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{width:'100%',marginTop:'4px'}}>
        <Col>
          <Badge bg="secondary" style={{width:'100%', height:'30px'}}>
              {siteName === '/' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Total Volume Amount</div>
              }
              {siteName === '/mau' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Total Volume Amount</div>
              }
              {siteName === '/data' &&
                <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>Total Volume Amount in Billion</div>
              }
          </Badge>
        </Col>

        <Col xs={6}>
          <Card style={{height:'30px'}}>
            <div style={{width:'100%',textAlign: 'center'}}>
              <NumberFormat value={Math.round(attribution * props.contractLength * 10)/ 10} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
);
}
