import { Grid, TextField } from "@mui/material"
import { useState } from "react";

export const Goals = (props) => {
    const [goals, setGoals] = useState({goal1:'',goal2:'',goal3:'',goal4:'',goal5:''});

    const onGoalChange = (text,number) => {
        if(number === '1'){
            setGoals({goal1: text, goal2: goals.goal2, goal3: goals.goal3, goal4: goals.goal4, goal5: goals.goal5});
            props.onGoalChange({goal1: text, goal2: goals.goal2, goal3: goals.goal3, goal4: goals.goal4, goal5: goals.goal5});
        }
        else if(number === '2'){
            setGoals({goal1: goals.goal1, goal2: text, goal3: goals.goal3, goal4: goals.goal4, goal5: goals.goal5});
            props.onGoalChange({goal1: goals.goal1, goal2: text, goal3: goals.goal3, goal4: goals.goal4, goal5: goals.goal5});
        }
        else if(number === '3'){
            setGoals({goal1: goals.goal1, goal2: goals.goal2, goal3: text, goal4: goals.goal4, goal5: goals.goal5});
            props.onGoalChange({goal1: goals.goal1, goal2: goals.goal2, goal3: text, goal4: goals.goal4, goal5: goals.goal5});
        }
        else if(number === '4'){
            setGoals({goal1: goals.goal1, goal2: goals.goal2, goal3: goals.goal3, goal4: text, goal5: goals.goal5});
            props.onGoalChange({goal1: goals.goal1, goal2: goals.goal2, goal3: goals.goal3, goal4: text, goal5: goals.goal5});
        }
        else if(number === '5'){
            setGoals({goal1: goals.goal1, goal2: goals.goal2, goal3: goals.goal3, goal4: goals.goal4, goal5: text});
            props.onGoalChange({goal1: goals.goal1, goal2: goals.goal2, goal3: goals.goal3, goal4: goals.goal4, goal5: text});
        }
        
    }
    
    return(
        <>
            <h2>Goals</h2>
                <Grid container direction="row">
                    <Grid xs={6}>
                        <Grid container direction="row" rowSpacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    id="goal1"
                                    label="Goal 1"
                                    style={{minWidth:'500px'}}
                                    onChange={(e) => onGoalChange(e.target.value,'1')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="goal2"
                                    label="Goal 2"
                                    style={{minWidth:'500px'}}
                                    onChange={(e) => onGoalChange(e.target.value,'2')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="goal3"
                                    label="Goal 3"
                                    style={{minWidth:'500px'}}
                                    onChange={(e) => onGoalChange(e.target.value,'3')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="goal4"
                                    label="Goal 4"
                                    style={{minWidth:'500px'}}
                                    onChange={(e) => onGoalChange(e.target.value,'4')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="goal5"
                                    label="Goal 5"
                                    style={{minWidth:'500px'}}
                                    onChange={(e) => onGoalChange(e.target.value,'5')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </>
    )
}