import React from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';
import {getCurrency, getPrefix} from "../GeneralValues/Currency";


function roundNearest50(num) {
  return Math.round(num / 50) * 50;
}

export default function DataManagementPro(props) {
  let currencyPrefix = getPrefix(props.currency)
  let currencyMultiplier = getCurrency(props.currency)
  let dataManagementSelected = props.dataManagementSelected;
  let dataManagementCost = 0;
  let thousandSeparator = ',';
  let decimalSeparator = '.'
  if(props.separator === '.'){
    thousandSeparator = '.';
    decimalSeparator = ',';
  }
  else{
    thousandSeparator = ',';
    decimalSeparator = '.';
  }
  
  const queryParameters = new URLSearchParams(window.location.search);
  const dmproParams = queryParameters.get("dmpro");
  if(dataManagementSelected === 'false'){
      if(dmproParams === 'true'){dataManagementSelected = 'true';props.onDataManagementSelectedChange('true');}
  }

  if(dataManagementSelected === 'true'){
    dataManagementCost = 2000;
  }
  else{
    dataManagementCost = 0;
  }
  

  function setDataManagement(value){
    if(value === 'true'){
      props.onDataManagementSelectedChange('true');
    }
    else{
      props.onDataManagementSelectedChange('false');
    }
  }

return (
  <div>
    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
      <Col>
        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
          <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Custom Dimensions</div>
        </Badge>
      </Col>
      <Col>
          <Form.Select defaultValue={'false'} value={dataManagementSelected} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} size="sm" id="dataManagement" onChange={e => setDataManagement(e.target.value)}>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </Form.Select>
      </Col>
      <Col>
        <Card style={{height:'30px'}}>
          <div style={{width:'99%',marginLeft:'1%',marginTop:'4px',textAlign: 'center'}}>
            <NumberFormat value={roundNearest50(dataManagementCost * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
          </div>
        </Card>
      </Col>
    </Row>
  </div>
);
}
