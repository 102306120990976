import Card from 'react-bootstrap/Card'
import {Row, Col, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';

export default function FeatureOutput(props) {
    let featureName = props.featureName;
    let priceRate = props.priceRate;
    let priceRec = props.priceRec;
    let priceLow = props.priceLow;
    let priceQuo = props.priceQuo;
    let currencyPrefix = props.currencyPrefix;
    let thousandSeparator = props.thousandSeparator;
    let decimalSeparator = props.decimalSeparator;

    let currencyMultiplier = props.currencyMultiplier;
    function roundNearest50(num) {
        return Math.round(num / 50) * 50;
    }
    let featureActive = featureName !== '-';
    return (
      <>
        {featureActive &&
        <Row style={{width:'100%',marginTop:'4px'}}>
          <Col xs={3}>
            <Badge bg="light" style={{width:'100%',color:'black',border: '2px solid lightgrey',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:"left",marginTop:'2px'}}>{featureName}</div>
            </Badge>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div style={{fontSize:'16px',textAlign: 'center',marginTop:'2px'}}><NumberFormat value={roundNearest50(priceRate * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
            </Card>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div style={{fontSize:'16px',textAlign: 'center'}}><NumberFormat value={roundNearest50(priceRec * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
            </Card>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div style={{fontSize:'16px',textAlign: 'center',marginTop:'2px'}}><NumberFormat value={roundNearest50(priceLow * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
            </Card>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div style={{fontSize:'16px',textAlign: 'center',marginTop:'2px'}}><NumberFormat value={roundNearest50(priceQuo * currencyMultiplier)} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} prefix={currencyPrefix}/></div>
            </Card>
          </Col>
        </Row>
        }
      </>
      );
    }
    