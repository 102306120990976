import React, { useState, useEffect } from "react";
import {Row, Col } from 'react-bootstrap'
import InputMaus from '../components/input_terms_mau.js'
import Features from '../components/input_addons.js'
import Support from '../components/input_support.js'
import Output from '../components/output.js'
import OutputBreakdownMau from '../components/outputBreakdown_mau.js';
import KeyStats from '../components/key_stats.js';
import DataManagementPro from "../components/data_management_pro.js";
import { SeparatorButtons } from "../components/separatorButtons.js";
import { PulseInput } from "../components/input_pulse.js";
import { SpendWorksInput } from "../components/input_spend_works.js";
import { InSightInput } from "../components/input_insight.js";
import { NextGenInput } from "../components/input_next_gen.js";
import { PresetModal } from "../components/PresetModal.js";
import { OpportunitySaveButton } from "../components/opportunity_save_button.js";
import { TrueLinkInput } from "../components/inputTrueLink.js";

export default function BuilderMau() {
  const [accountId, setAccountId] = useState(0);
  const [contractLength, setContractLength] = useState(0);
  const [paymentTerm, setPaymentTerm] = useState('DEFAULT');
  const [attribution, setAttribution] = useState(0);
  const [region, setRegion] = useState('DEFAULT');
  const [entity, setEntity] = useState('');
  const [currency, setCurrency] = useState('DEFAULT');
  const [volumePeriod, setVolumePeriod] = useState('DEFAULT');
  const [feature1, setFeature1] = useState("-");
  const [feature2, setFeature2] = useState("-");
  const [feature3, setFeature3] = useState("-");
  const [feature4, setFeature4] = useState("-");
  const [feature5, setFeature5] = useState("-");
  const [feature6, setFeature6] = useState("-");
  const [feature7, setFeature7] = useState("-");
  const [feature8, setFeature8] = useState("-");
  const [feature9, setFeature9] = useState("-");
  const [feature10, setFeature10] = useState("-");
  const [feature11, setFeature11] = useState("-");
  const [feature12, setFeature12] = useState("-");
  const [feature13, setFeature13] = useState("-");
  const [feature14, setFeature14] = useState("-");
  const maxAttribution = 500000000;
  const supportList = [
    [1,"Global",0],
    [2,"Regional",1200],
    [3,"Professional",34000],
    [4,"Enterprise",80000],
    [5,"VIP",200000]
  ]
  const [featureDiscount, setFeatureDiscount] = useState(0);
  const [completeFeatureDiscount, setCompleteFeatureDiscount] = useState(0);
  const [implementation, setImplementation] = useState(0);
  const [supportCost, setSupportCost] = useState(0);
  const [acv, setAcv] = useState(0);
  const [quoteDiscount, setQuoteDiscount] = useState(0);
  function handleAccountIdChange(value) {setAccountId(value);}
  function handleContractLengthChange(value) {setContractLength(value);}
  function handlePaymentTermChange(value) {setPaymentTerm(value);}
  function handleAttributionChange(value) {setAttribution(value);}
  function handleRegionChange(value) {
    setRegion(value);
    setEntity("");
    setCurrency("DEFAULT");
  }
  function handleEntityChange(value) {setEntity(value);}
  function handleCurrencyChange(value) {setCurrency(value);}
  function handleFeatureChange1(value) {setFeature1(value);}
  function handleFeatureChange2(value) {setFeature2(value);}
  function handleFeatureChange3(value) {setFeature3(value);}
  function handleFeatureChange4(value) {setFeature4(value);}
  function handleFeatureChange5(value) {setFeature5(value);}
  function handleFeatureChange6(value) {setFeature6(value);}
  function handleFeatureChange7(value) {setFeature7(value);}
  function handleFeatureChange8(value) {setFeature8(value);}
  function handleFeatureChange9(value) {setFeature9(value);}
  function handleFeatureChange10(value) {setFeature10(value);}
  function handleFeatureChange11(value) {setFeature11(value);}
  function handleFeatureChange12(value) {setFeature12(value);}
  function handleFeatureChange13(value) {setFeature13(value);}
  function handleFeatureChange14(value) {setFeature14(value);}
  function handleFeatureDiscount(value) {setFeatureDiscount(value);}
  function handleCompleteFeatureDiscount(value) {setCompleteFeatureDiscount(value);}
  function handleImplementationChange(value) {setImplementation(value);}
  function handleSupportChange(value) {setSupportCost(value);}
  function handleAcvChange(value) {setAcv(value);}
  function handleQuoteDiscountChange(value) {setQuoteDiscount(value);}

  const [dataManagementSelected, setDataManagementSelected] = useState('false');
  const [assistDashboard, setAssistDashboard] = useState(false);
  const [pulse, setPulse] = useState('false');
  const [pulseCount, setPulseCount] = useState(51);
  const [spendWorks, setSpendWorks] = useState('false');
  const [incrementality, setIncrementality] = useState('false');
  const [nextGen, setNextGen] = useState('false');
  const [trueLink, setTrueLink] = useState('false');
  function handleDataManagementSelectedChange(value) {setDataManagementSelected(value);}
  function handleTrueLinkChange(value) {setTrueLink(value);}

  function handleAssistDashboard(value) {setAssistDashboard(value);}
  const [keyStats,setKeyStats] = useState(['',0,0,0,0,'']);
  function handleKeyStatsChange(value) {setKeyStats(value);}

  const [separator,setSeparator] = useState(',');
  const hanleSeparatorChange = (value) => {setSeparator(value);}
  const handlePulseChange = (value) => {setPulse(value);}
  const handlePulseCountChange = (value) => {setPulseCount(value);}
  const handleSpendWorksChange = (value) => {setSpendWorks(value);}
  const handleIncrementalityChange = (value) => {setIncrementality(value);}
  const handleNextGenChange = (value) => {setNextGen(value);}
  const hanldeVolumePeriodChange = (value) => {setVolumePeriod(value);}
  const [lowerLimit,setLowerLimit] = useState(0);
  function handleLowerLimitChange(value) {setLowerLimit(value);}
  
  const setFeature = (name, id) => {
    if(id === 1){
      setFeature1(name);
    }
    else if(id === 2){
      setFeature2(name);
    }
    else if(id === 3){
      setFeature3(name);
    }
    else if(id === 4){
      setFeature4(name);
    }
    else if(id === 5){
      setFeature5(name);
    }
    else if(id === 6){
      setFeature6(name);
    }
    else if(id === 7){
      setFeature7(name);
    }
    else if(id === 8){
      setFeature8(name);
    }
    else if(id === 9){
      setFeature9(name);
    }
    else if(id === 10){
      setFeature10(name);
    }
    else if(id === 11){
      setFeature11(name);
    }
    else if(id === 12){
      setFeature12(name);
    }
    else if(id === 13){
      setFeature13(name);
    }
    else if(id === 14){
      setFeature14(name);
    }
  }

  useEffect( () => { 
    const queryParameters = new URLSearchParams(window.location.search);
    const requiredFeaturesParams = queryParameters.get("required_features");
    if(requiredFeaturesParams !== null){
      const featureArray = requiredFeaturesParams.split(",");
      for (let i = 0; i < featureArray.length; i++) {
        const element = featureArray[i];
        if(element === 'ar'){
          setFeature('Ad Revenue Tracking', i+1)
        }
        else if(element === 'ctv'){
          setFeature('AdVision (CTV)', i+1)
        }
        else if(element === 'ad'){
          setFeature('Assist Dashboard', i+1)
        }
        else if(element === 'ab'){
          setFeature('Audience Builder', i+1)
        }
        else if(element === 'ca'){
          setFeature('Campaign Automation', i+1)
        }
        else if(element === 'automation'){
          setFeature('Automation API', i+1)
        }
        else if(element === 'dr'){
          setFeature('Data Residency', i+1)
        }
        else if(element === 'fps'){
          setFeature('Fraud Prevention Suite', i+1)
        }
        else if(element === 'multitouch'){
          setFeature('Multi Touch Attribution', i+1)
        }
        else if(element === 'pcctracking'){
          setFeature('PC / Console Tracking', i+1)
        }
        else if(element === 'pv'){
          setFeature('Purchase Verification 2.0', i+1)
        }
        else if(element === 'sb'){
          setFeature('Smart Banners', i+1)
        }
        else if(element === 'sso'){
          setFeature('SSO', i+1)
        }
        else if(element === 'web'){
          setFeature('Web SDK', i+1)
        }
      }
    }
  }, []);

  return (
    <div>
      <KeyStats
        onAccountIdChange={handleAccountIdChange}
        onContractLengthChange={handleContractLengthChange}
        onPaymentTermChange={handlePaymentTermChange}
        onAttributionChange={handleAttributionChange}
        onFeature1Change={handleFeatureChange1}
        onFeature2Change={handleFeatureChange2}
        onFeature3Change={handleFeatureChange3}
        onFeature4Change={handleFeatureChange4}
        onFeature5Change={handleFeatureChange5}
        onFeature6Change={handleFeatureChange6}
        onFeature7Change={handleFeatureChange7}
        onFeature8Change={handleFeatureChange8}
        onFeature9Change={handleFeatureChange9}
        onFeature10Change={handleFeatureChange10}
        onFeature11Change={handleFeatureChange11}
        onFeature12Change={handleFeatureChange12}
        onFeature13Change={handleFeatureChange13}
        onFeature14Change={handleFeatureChange14}
        implementationChange={handleImplementationChange}
        onKeyStatsChange={handleKeyStatsChange}
      />
      <div style={{height:'40px'}}>
            <Row style={{width:'100%'}}>
              <Col xs={10}>
                <PresetModal 
                  type="mau"
                  attribution={attribution}
                  region={region}
                  currency={currency}
                  contractLength={contractLength}
                  paymentTerm = {paymentTerm}
                  entity = {entity}
                  feature1={feature1}
                  feature2={feature2}
                  feature3={feature3}
                  feature4={feature4}
                  feature5={feature5}
                  feature6={feature6}
                  feature7={feature7}
                  feature8={feature8}
                  feature9={feature9}
                  feature10={feature10}
                  feature11={feature11}
                  feature12={feature12}
                  feature13={feature13}
                  feature14={feature14}
                  supportList={supportList}
                  dataManagementSelected={dataManagementSelected}
                  assistDashboard={assistDashboard}
                  keyStats={keyStats}
                  separator={separator}
                  pulse={pulse}
                  spendWorks={spendWorks}
                  incrementality={incrementality}
                  nextGen={nextGen}
                  volumePeriod={volumePeriod}
                  trueLink={trueLink}
                  onContractLengthChange={handleContractLengthChange}
                  onPaymentTermChange={handlePaymentTermChange}
                  onAttributionChange={handleAttributionChange}
                  onRegionChange={handleRegionChange}
                  onEntityChange={handleEntityChange}
                  onVolumePeriodChange={hanldeVolumePeriodChange}
                  onCurrencyChange={handleCurrencyChange}
                  onFeature1Change={handleFeatureChange1}
                  onFeature2Change={handleFeatureChange2}
                  onFeature3Change={handleFeatureChange3}
                  onFeature4Change={handleFeatureChange4}
                  onFeature5Change={handleFeatureChange5}
                  onFeature6Change={handleFeatureChange6}
                  onFeature7Change={handleFeatureChange7}
                  onFeature8Change={handleFeatureChange8}
                  onFeature9Change={handleFeatureChange9}
                  onFeature10Change={handleFeatureChange10}
                  onFeature11Change={handleFeatureChange11}
                  onFeature12Change={handleFeatureChange12}
                  onFeature13Change={handleFeatureChange13}
                  onFeature14Change={handleFeatureChange14}
                  onDataManagementSelectedChange={handleDataManagementSelectedChange}
                  onPulseChange={handlePulseChange}
                  onSpendWorksChange={handleSpendWorksChange}
                  onIncremenatlityChange={handleIncrementalityChange}
                  onNextGenChange={handleNextGenChange}
                  onTrueLinkChange={handleTrueLinkChange}
                />
                </Col>
              <Col>
                <SeparatorButtons onSeparatorChange = {hanleSeparatorChange} />
              </Col>
            </Row>
      </div>
      <Row style={{width:'100%'}}>
        <Col>
          <InputMaus
            contractLength={contractLength}
            paymentTerm={paymentTerm}
            attribution={attribution}
            region={region}
            entity={entity}
            currency={currency}
            onContractLengthChange={handleContractLengthChange}
            onPaymentTermChange={handlePaymentTermChange}
            onAttributionChange={handleAttributionChange}
            onRegionChange={handleRegionChange}
            onEntityChange={handleEntityChange}
            onCurrencyChange={handleCurrencyChange}
            maxAttribution={maxAttribution}
            volumePeriod={volumePeriod}
            onVolumePeriodChange={hanldeVolumePeriodChange}
          />
          <hr class="hr" style={{marginLeft:'2%'}}/>
          <Features
            feature1={feature1}
            feature2={feature2}
            feature3={feature3}
            feature4={feature4}
            feature5={feature5}
            feature6={feature6}
            feature7={feature7}
            feature8={feature8}
            feature9={feature9}
            feature10={feature10}
            feature11={feature11}
            feature12={feature12}
            feature13={feature13}
            feature14={feature14}
            onFeature1Change={handleFeatureChange1}
            onFeature2Change={handleFeatureChange2}
            onFeature3Change={handleFeatureChange3}
            onFeature4Change={handleFeatureChange4}
            onFeature5Change={handleFeatureChange5}
            onFeature6Change={handleFeatureChange6}
            onFeature7Change={handleFeatureChange7}
            onFeature8Change={handleFeatureChange8}
            onFeature9Change={handleFeatureChange9}
            onFeature10Change={handleFeatureChange10}
            onFeature11Change={handleFeatureChange11}
            onFeature12Change={handleFeatureChange12}
            onFeature13Change={handleFeatureChange13}
            onFeature14Change={handleFeatureChange14}
            featureDiscount={handleFeatureDiscount}
            completeFeatureDiscount={handleCompleteFeatureDiscount}
            onAssistDashboardChange={handleAssistDashboard}
          />
          <hr class="hr" style={{marginLeft:'2%'}}/>
          {acv >= 0 &&
            <DataManagementPro
              currency={currency}
              dataManagementSelected={dataManagementSelected}
              onDataManagementSelectedChange={handleDataManagementSelectedChange}
              separator={separator}
            />
          }
          <br/>
          <PulseInput 
            currency={currency}
            separator={separator}
            pulse={pulse}
            pulseCount={pulseCount}
            onPulseChange={handlePulseChange}
            onPulseCountChange={handlePulseCountChange}
          />
          <br/>
          <SpendWorksInput 
            currency={currency}
            separator={separator}
            spendWorks={spendWorks}
            onSpendWorksChange={handleSpendWorksChange}
          />
          <br/>
          <InSightInput
            currency={currency}
            separator={separator}
            incrementality={incrementality}
            onIncremenatlityChange={handleIncrementalityChange}
          />
          <br/>
          <NextGenInput 
            currency={currency}
            separator={separator}
            nextGen={nextGen}
            onNextGenChange={handleNextGenChange}
            attribution={attribution}
            contractLength={contractLength}
            incrementality={incrementality}
          />
          <br/>
          <TrueLinkInput 
            currency={currency}
            separator={separator}
            trueLink={trueLink}
            onTrueLinkChange={handleTrueLinkChange}
            attribution={attribution}
            contractLength={contractLength}
            incrementality={incrementality}
          />
          <br/>
          <div style={{display:'none'}}>
          <Support
            implementationChange={handleImplementationChange}
            supportChange={handleSupportChange}
            supportList={supportList}
            supportCost={supportCost}
            acv={acv}
            implementation={implementation}
            currency={currency}
          />
          </div>
          <div style={{display:'none'}}>
          <OpportunitySaveButton 
            type="mau"
            accountId={accountId}
            volume={attribution}
            region={region}
            currency={currency}
            contractLength={contractLength}
            acv = {acv}
            quoteDiscountChange = {handleQuoteDiscountChange}
            paymentTerm = {paymentTerm}
            feature1={feature1}
            feature2={feature2}
            feature3={feature3}
            feature4={feature4}
            feature5={feature5}
            feature6={feature6}
            feature7={feature7}
            feature8={feature8}
            feature9={feature9}
            feature10={feature10}
            feature11={feature11}
            feature12={feature12}
            feature13={feature13}
            feature14={feature14}
            dataManagementSelected={dataManagementSelected}
            assistDashboard={assistDashboard}
            pulse={pulse}
            pulseCount={pulseCount}
            spendWorks={spendWorks}
            incrementality={incrementality}
            nextGen={nextGen}
            entity = {entity}
            separator={separator}
            lowerLimit={lowerLimit}
            trueLink={trueLink}
            quoteDiscount={quoteDiscount}
          />
        </div>
        </Col>
        <Col xs={7}>
            <Output
              contractLength={contractLength}
              paymentTerm={paymentTerm}
              attribution={attribution}
              separator={separator}
              volumePeriod={volumePeriod}
            />
            <OutputBreakdownMau
              accountId={accountId}
              acvChange = {handleAcvChange}
              lowerLimitChange = {handleLowerLimitChange}
              attribution={attribution}
              region={region}
              currency={currency}
              featureDiscount={featureDiscount}
              completeFeatureDiscount={completeFeatureDiscount}
              supportCost={supportCost}
              contractLength={contractLength}
              completeAcv = {acv}
              paymentTerm = {paymentTerm}
              implementation = {implementation}
              feature1={feature1}
              feature2={feature2}
              feature3={feature3}
              feature4={feature4}
              feature5={feature5}
              feature6={feature6}
              feature7={feature7}
              feature8={feature8}
              feature9={feature9}
              feature10={feature10}
              feature11={feature11}
              feature12={feature12}
              feature13={feature13}
              feature14={feature14}
              supportList={supportList}
              dataManagementSelected={dataManagementSelected}
              assistDashboard={assistDashboard}
              keyStats={keyStats}
              separator={separator}
              pulse={pulse}
              pulseCount={pulseCount}
              spendWorks={spendWorks}
              incrementality={incrementality}
              nextGen={nextGen}
              volumePeriod={volumePeriod}
              trueLink={trueLink}
            />
        </Col>
      </Row>
      <br/>
      <br/>
    </div>
  );
}
