import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';
import {getCurrency, getPrefix} from "../GeneralValues/Currency";

const ceilNearest50 = (num) =>{
    return Math.ceil(num / 50) * 50;
}

export const PulseInput = (props) => {
    const [pulse,setPulse] = useState(props.pulse)
    const [pulseCount,setPulseCount] = useState(51)
    let currencyPrefix = getPrefix(props.currency)
    let currencyMultiplier = getCurrency(props.currency)
    let thousandSeparator = ',';
    let decimalSeparator = '.'
    if(props.separator === '.'){
        thousandSeparator = '.';
        decimalSeparator = ',';
    }
    else{
        thousandSeparator = ',';
        decimalSeparator = '.';
    }

    const setPulseUpdate = (value) => {
        setPulse(value);
        if(value === "custom"){
            setPulseCount(51);
        }
        props.onPulseChange(value);
    }

    const setPulseCountUpdate = (value) => {
        setPulseCount(value);
        props.onPulseCountChange(value);
    }

    const getPrice = () => {
        if(props.pulse === 'base'){
            return <NumberFormat value={ceilNearest50(10000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.pulse === 'core'){
            return <NumberFormat value={ceilNearest50(20000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.pulse === 'enterprise'){
            return <NumberFormat value={ceilNearest50(35000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.pulse === 'custom'){
            var extraPulsePrice = (pulseCount - 50) * 500;
            return <NumberFormat value={ceilNearest50((35000 + extraPulsePrice) * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else{
            return <NumberFormat value={0} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
    }
    const queryParameters = new URLSearchParams(window.location.search);
    const pulseParams = queryParameters.get("pulse");
    if(pulse === 'false'){
        if(pulseParams === 'base'){setPulse('base');props.onPulseChange('base');}
        else if(pulseParams === 'core'){setPulse('core');props.onPulseChange('core');}
        else if(pulseParams === 'enterprise'){setPulse('enterprise');props.onPulseChange('enterprise');}
        else if(pulseParams === 'custom'){setPulse('custom');props.onPulseChange('custom');}
    }
    return (
        <div>
                <Row style={{width:'99%',marginLeft:'1%'}}>
                    <Col>
                        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                            <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Pulse</div>
                        </Badge>
                    </Col>
                    <Col>
                        <Form.Select defaultValue={'false'} value={props.pulse} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px'  }} size="sm" id="pulse" onChange={e => setPulseUpdate(e.target.value)} >
                            <option value="false">No</option>
                            <option value="base">Base</option>
                            <option value="core">Core</option>
                            <option value="enterprise">Enterprise</option>
                            <option value="custom">Custom</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Card style={{height:'30px'}}>
                            <div style={{width:'96%',marginLeft:'1%',marginRight:'2%',textAlign: 'center'}}>
                                <>{getPrice()}</>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {pulse === 'base' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey"}}>#Pulse included per 12 month</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center'}}>0-5</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px'}}></Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey"}}>#Overages</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center'}}>2k $ / pulse</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {pulse === 'core' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Pulse included per year</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>6-20</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Overages</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>1k $ / pulse</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {pulse === 'enterprise' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Pulse included per year</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>21-50</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Overages</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>750 $/ pulse</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {pulse === 'custom' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Pulse included per year</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>
                                <Form.Select defaultValue={'false'} value={pulseCount} style = {{ width: '96%',marginLeft: '2%',background: '#ffffcc' }} size="sm" id="pulseCount" onChange={e => setPulseCountUpdate(e.target.value)} >
                                    {
                                       [...Array(50)].map((_, i) => i + 51)
                                        .map(i => <option key={i} value={i}>{i}</option>)
                                    }
                                </Form.Select>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>#Overages</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>500 $ / pulse</Card>
                        </Col>
                        <Col>
                            <Card style={{border: ' 0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }
        </div>
    );
}