import { Grid, TextField, Select, MenuItem, InputLabel } from "@mui/material"
import { useState } from "react";

export const ClientInformationNew = (props) => {
    const [logo,setLogo] = useState('');
    const [clientName,setClientName] = useState('');
    const [companyName,setCompanyName] = useState('');
    const [vertialName,setVertical] = useState('');

    const onClientChange = (client) => {
        props.onClientInfoChange({clientName: client, clientLogo: logo});
        setClientName(client);
    }

    const onLogoChange = (logoUrl) => {
        props.onClientInfoChange({clientName: clientName, clientLogo: logoUrl});
        setLogo(logoUrl);
    }

    const onCompanyNameChange = (company) => {
        props.onAccountInfoChange([company, vertialName]);
        setCompanyName(company);
    }

    const onVerticalChange = (vertial) => {
        props.onAccountInfoChange([companyName, vertial]);
        setVertical(vertial);
    }
    
    return(
        <>
            <h2>Client Information</h2>
                <Grid container direction="row">
                    <Grid item xs={6} >
                        <Grid container direction="row" rowSpacing={2} justifyContent="flex-start" alignItems="center">
                            <Grid item xs={12} >
                                <TextField
                                    id="clientName"
                                    label="Client Name"
                                    style={{minWidth:'500px'}}
                                    onChange={e => onClientChange(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="companyName"
                                    label="Company Name"
                                    value={props.companyName}
                                    style={{minWidth:'500px'}}
                                    onChange={e => onCompanyNameChange(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="select-label">Vertical</InputLabel>
                                <Select id="vertical" label="Vertical" onChange={e => onVerticalChange(e.target.value)} value={vertialName} style={{minWidth:'500px'}}>
                                    <MenuItem value="Other">Other</MenuItem>
                                    <MenuItem value="Gaming">Gaming</MenuItem>
                                    <MenuItem value="Shopping">Shopping</MenuItem>
                                    <MenuItem value="Finance">Finance</MenuItem>
                                    <MenuItem value="Travel">Travel</MenuItem>
                                    <MenuItem value="Lifestyle">Lifestyle</MenuItem>
                                    <MenuItem value="Education">Education</MenuItem>
                                    
                                </Select>
                            </Grid>
                            <Grid item >
                                <TextField
                                    id="companyLogo"
                                    label="Company Logo"
                                    style={{minWidth:'500px'}}
                                    onChange={e => onLogoChange(e.target.value)}
                                />
                                
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} >
                            <img src={logo} alt=""/>
                    </Grid>
                </Grid>
        </>
    );
}