export const matrix = [[0,0,0,0,0],
[1,50,1000,500,6250000],
[2,100,2000,1500,12500000],
[3,150,4000,2000,18750000],
[4,200,6000,2500,25000000],
[5,250,7000,3000,31250000],
[6,300,8000,8000,37500000],
[7,350,10000,12000,43750000],
[8,400,12000,16000,50000000],
[9,450,14000,20000,56250000],
[10,500,15000,25000,62500000],
[11,550,16000,29000,68750000],
[12,600,18000,33000,75000000],
[13,650,20000,38000,81250000],
[14,700,22000,42000,87500000],
[15,750,24000,47000,93750000],
[16,800,26000,51000,100000000],
[17,850,27000,56000,106250000],
[18,900,28000,60000,112500000],
[19,950,30000,65000,118750000],
[20,1000,32000,70000,125000000],
[21,1050,34000,74000,131250000],
[22,1100,36000,79000,137500000],
[23,1150,38000,84000,143750000],
[24,1200,40000,89000,150000000],
[25,1250,42000,94000,156250000],
[26,1300,44000,99000,162500000],
[27,1350,46000,104000,168750000],
[28,1400,47000,109000,175000000],
[29,1450,48000,114000,181250000],
[30,1500,50000,119000,187500000],
[31,1550,52000,124000,193750000],
[32,1600,54000,130000,200000000],
[33,1650,56000,135000,206250000],
[34,1700,58000,140000,212500000],
[35,1750,60000,146000,218750000],
[36,1800,62000,151000,225000000],
[37,1850,64000,157000,231250000],
[38,1900,66000,163000,237500000],
[39,1950,68000,168000,243750000],
[40,2000,70000,174000,250000000],
[41,2050,74000,180000,256250000],
[42,2100,76000,186000,262500000],
[43,2150,78000,192000,268750000],
[44,2200,80000,198000,275000000],
[45,2250,82000,204000,281250000],
[46,2300,84000,210000,287500000],
[47,2350,86000,216000,293750000],
[48,2400,88000,223000,300000000],
[49,2450,90000,229000,306250000],
[50,2500,92000,235000,312500000],
[51,2550,96000,242000,318750000],
[52,2600,98000,249000,325000000],
[53,2650,100000,255000,331250000],
[54,2700,102000,262000,337500000],
[55,2750,104000,269000,343750000],
[56,2800,108000,276000,350000000],
[57,2850,110000,283000,356250000],
[58,2900,112000,291000,362500000],
[59,2950,114000,298000,368750000],
[60,3000,118000,305000,375000000],
[61,3050,120000,313000,381250000],
[62,3100,122000,321000,387500000],
[63,3150,126000,328000,393750000],
[64,3200,128000,336000,400000000],
[65,3250,130000,344000,406250000],
[66,3300,134000,353000,412500000],
[67,3350,136000,361000,418750000],
[68,3400,138000,369000,425000000],
[69,3450,142000,378000,431250000],
[70,3500,144000,386000,437500000],
[71,3550,148000,395000,443750000],
[72,3600,150000,404000,450000000],
[73,3650,154000,413000,456250000],
[74,3700,156000,423000,462500000],
[75,3750,160000,432000,468750000],
[76,3800,162000,442000,475000000],
[77,3850,166000,451000,481250000],
[78,3900,170000,461000,487500000],
[79,3950,172000,471000,493750000],
[80,4000,176000,482000,500000000],
[81,4050,180000,492000,506250000],
[82,4100,182000,503000,512500000],
[83,4150,186000,514000,518750000],
[84,4200,190000,524000,525000000],
[85,4250,194000,536000,531250000],
[86,4300,196000,547000,537500000],
[87,4350,200000,558000,543750000],
[88,4400,204000,570000,550000000],
[89,4450,208000,582000,556250000],
[90,4500,212000,594000,562500000],
[91,4550,216000,606000,568750000],
[92,4600,220000,618000,575000000],
[93,4650,224000,631000,581250000],
[94,4700,228000,643000,587500000],
[95,4750,234000,656000,593750000],
[96,4800,238000,669000,600000000],
[97,4850,242000,682000,606250000],
[98,4900,246000,695000,612500000],
[99,4950,252000,708000,618750000],
[100,5000,256000,722000,625000000],
[101,5050,262000,735000,631250000],
[102,5100,266000,748000,637500000],
[103,5150,272000,762000,643750000],
[104,5200,278000,775000,650000000],
[105,5250,282000,788000,656250000],
[106,5300,288000,802000,662500000],
[107,5350,294000,815000,668750000],
[108,5400,300000,828000,675000000],
[109,5450,306000,841000,681250000],
[110,5500,312000,854000,687500000],
[111,5550,320000,867000,693750000],
[112,5600,326000,880000,700000000],
[113,5650,334000,893000,706250000],
[114,5700,340000,906000,712500000],
[115,5750,348000,918000,718750000],
[116,5800,356000,931000,725000000],
[117,5850,364000,943000,731250000],
[118,5900,372000,955000,737500000],
[119,5950,380000,967000,743750000],
[120,6000,388000,978000,750000000],
[121,6050,398000,990000,756250000],
[122,6100,408000,1001000,762500000],
[123,6150,418000,1013000,768750000],
[124,6200,428000,1024000,775000000],
[125,6250,438000,1035000,781250000],
[126,6300,448000,1045000,787500000],
[127,6350,460000,1056000,793750000],
[128,6400,472000,1066000,800000000],
[129,6450,484000,1077000,806250000],
[130,6500,496000,1087000,812500000],
[131,6550,508000,1097000,818750000],
[132,6600,520000,1106000,825000000],
[133,6650,534000,1116000,831250000],
[134,6700,546000,1126000,837500000],
[135,6750,560000,1135000,843750000],
[136,6800,572000,1144000,850000000],
[137,6850,586000,1153000,856250000],
[138,6900,598000,1162000,862500000],
[139,6950,612000,1171000,868750000],
[140,7000,624000,1180000,875000000],
[141,7050,636000,1188000,881250000],
[142,7100,650000,1197000,887500000],
[143,7150,662000,1205000,893750000],
[144,7200,674000,1213000,900000000],
[145,7250,686000,1221000,906250000],
[146,7300,698000,1229000,912500000],
[147,7350,710000,1237000,918750000],
[148,7400,722000,1245000,925000000],
[149,7450,734000,1252000,931250000],
[150,7500,746000,1260000,937500000],
[151,7550,756000,1267000,943750000],
[152,7600,768000,1275000,950000000],
[153,7650,780000,1282000,956250000],
[154,7700,790000,1289000,962500000],
[155,7750,802000,1296000,968750000],
[156,7800,812000,1305000,975000000],
[157,7850,824000,1315000,981250000],
[158,7900,834000,1325000,987500000],
[159,7950,846000,1335000,993750000],
[160,8000,858000,1355000,1000000000],
[161,8050,868000,1375000,1006250000],
[162,8100,880000,1405000,1012500000],
[163,8150,892000,1447000,1018750000],
[164,8200,904000,1541000,1025000000],
[165,8250,916000,1635000,1031250000],
[166,8300,928000,1729000,1037500000],
[167,8350,940000,1823000,1043750000],
[168,8400,952000,1917000,1050000000],
[169,8450,964000,2011000,1056250000],
[170,8500,976000,2106000,1062500000],
[171,8550,988000,2200000,1068750000],
[172,8600,1000000,2295000,1075000000],
[173,8650,1014000,2389000,1081250000],
[174,8700,1028000,2484000,1087500000],
[175,8750,1042000,2578000,1093750000],
[176,8800,1056000,2673000,1100000000],
[177,8850,1070000,2768000,1106250000],
[178,8900,1084000,2863000,1112500000],
[179,8950,1098000,2958000,1118750000],
[180,9000,1112000,3053000,1125000000],
[181,9050,1128000,3148000,1131250000],
[182,9100,1142000,3243000,1137500000],
[183,9150,1156000,3339000,1143750000],
[184,9200,1170000,3434000,1150000000],
[185,9250,1186000,3530000,1156250000],
[186,9300,1200000,3625000,1162500000],
[187,9350,1214000,3721000,1168750000],
[188,9400,1228000,3816000,1175000000],
[189,9450,1242000,3912000,1181250000],
[190,9500,1258000,4008000,1187500000],
[191,9550,1272000,4104000,1193750000],
[192,9600,1286000,4200000,1200000000],
[193,9650,1300000,4296000,1206250000],
[194,9700,1316000,4392000,1212500000],
[195,9750,1330000,4488000,1218750000],
[196,9800,1344000,4584000,1225000000],
[197,9850,1360000,4681000,1231250000],
[198,9900,1374000,4777000,1237500000],
[199,9950,1388000,4874000,1243750000],
[200,10000,1402000,4970000,1250000000],
[201,10050,1418000,5067000,1256250000],
[202,10100,1432000,5164000,1262500000],
[203,10150,1446000,5261000,1268750000],
[204,10200,1462000,5357000,1275000000],
[205,10250,1476000,5454000,1281250000],
[206,10300,1490000,5551000,1287500000],
[207,10350,1506000,5649000,1293750000],
[208,10400,1520000,5746000,1300000000],
[209,10450,1534000,5843000,1306250000],
[210,10500,1550000,5940000,1312500000],
[211,10550,1564000,6038000,1318750000],
[212,10600,1578000,6135000,1325000000],
[213,10650,1594000,6233000,1331250000],
[214,10700,1608000,6331000,1337500000],
[215,10750,1622000,6428000,1343750000],
[216,10800,1638000,6526000,1350000000],
[217,10850,1652000,6624000,1356250000],
[218,10900,1666000,6722000,1362500000],
[219,10950,1682000,6820000,1368750000],
[220,11000,1696000,6918000,1375000000],
[221,11050,1712000,7017000,1381250000],
[222,11100,1726000,7115000,1387500000],
[223,11150,1740000,7213000,1393750000],
[224,11200,1756000,7312000,1400000000],
[225,11250,1770000,7410000,1406250000],
[226,11300,1786000,7509000,1412500000],
[227,11350,1800000,7608000,1418750000],
[228,11400,1814000,7706000,1425000000],
[229,11450,1830000,7805000,1431250000],
[230,11500,1844000,7904000,1437500000],
[231,11550,1860000,8003000,1443750000],
[232,11600,1874000,8102000,1450000000],
[233,11650,1890000,8202000,1456250000],
[234,11700,1904000,8301000,1462500000],
[235,11750,1918000,8400000,1468750000],
[236,11800,1934000,8500000,1475000000],
[237,11850,1948000,8599000,1481250000],
[238,11900,1964000,8699000,1487500000],
[239,11950,1978000,8798000,1493750000],
[240,12000,1994000,8898000,1500000000],
[241,12050,2008000,8998000,1506250000],
[242,12100,2024000,9098000,1512500000],
[243,12150,2038000,9198000,1518750000],
[244,12200,2054000,9298000,1525000000],
[245,12250,2068000,9398000,1531250000],
[246,12300,2084000,9499000,1537500000],
[247,12350,2098000,9599000,1543750000],
[248,12400,2114000,9699000,1550000000],
[249,12450,2128000,9800000,1556250000],
[250,12500,2144000,9900000,1562500000],
[251,12550,2158000,10001000,1568750000],
[252,12600,2174000,10102000,1575000000],
[253,12650,2188000,10203000,1581250000],
[254,12700,2204000,10304000,1587500000],
[255,12750,2218000,10405000,1593750000],
[256,12800,2234000,10506000,1600000000],
[257,12850,2248000,10607000,1606250000],
[258,12900,2264000,10708000,1612500000],
[259,12950,2280000,10810000,1618750000],
[260,13000,2294000,10911000,1625000000],
[261,13050,2310000,11013000,1631250000],
[262,13100,2324000,11114000,1637500000],
[263,13150,2340000,11216000,1643750000],
[264,13200,2354000,11318000,1650000000],
[265,13250,2370000,11420000,1656250000],
[266,13300,2386000,11522000,1662500000],
[267,13350,2400000,11624000,1668750000],
[268,13400,2416000,11726000,1675000000],
[269,13450,2430000,11828000,1681250000],
[270,13500,2446000,11930000,1687500000],
[271,13550,2462000,12033000,1693750000],
[272,13600,2476000,12135000,1700000000],
[273,13650,2492000,12238000,1706250000],
[274,13700,2508000,12340000,1712500000],
[275,13750,2522000,12443000,1718750000],
[276,13800,2538000,12546000,1725000000],
[277,13850,2552000,12649000,1731250000],
[278,13900,2568000,12752000,1737500000],
[279,13950,2584000,12855000,1743750000],
[280,14000,2598000,12958000,1750000000],
[281,14050,2614000,13062000,1756250000],
[282,14100,2630000,13165000,1762500000],
[283,14150,2644000,13268000,1768750000],
[284,14200,2660000,13372000,1775000000],
[285,14250,2676000,13476000,1781250000],
[286,14300,2692000,13579000,1787500000],
[287,14350,2706000,13683000,1793750000],
[288,14400,2722000,13787000,1800000000],
[289,14450,2738000,13891000,1806250000],
[290,14500,2752000,13995000,1812500000],
[291,14550,2768000,14099000,1818750000],
[292,14600,2784000,14204000,1825000000],
[293,14650,2800000,14308000,1831250000],
[294,14700,2814000,14412000,1837500000],
[295,14750,2830000,14517000,1843750000],
[296,14800,2846000,14621000,1850000000],
[297,14850,2860000,14726000,1856250000],
[298,14900,2876000,14831000,1862500000],
[299,14950,2892000,14936000,1868750000],
[300,15000,2908000,15041000,1875000000],
[301,15050,2924000,15146000,1881250000],
[302,15100,2938000,15251000,1887500000],
[303,15150,2954000,15357000,1893750000],
[304,15200,2970000,15462000,1900000000],
[305,15250,2986000,15567000,1906250000],
[306,15300,3000000,15673000,1912500000],
[307,15350,3016000,15779000,1918750000],
[308,15400,3032000,15884000,1925000000],
[309,15450,3048000,15990000,1931250000],
[310,15500,3064000,16096000,1937500000],
[311,15550,3078000,16202000,1943750000],
[312,15600,3094000,16308000,1950000000],
[313,15650,3110000,16414000,1956250000],
[314,15700,3126000,16521000,1962500000],
[315,15750,3142000,16627000,1968750000],
[316,15800,3158000,16734000,1975000000],
[317,15850,3172000,16840000,1981250000],
[318,15900,3188000,16947000,1987500000],
[319,15950,3204000,17054000,1993750000],
[320,16000,3220000,17161000,2000000000],
[321,16050,3236000,17268000,2006250000],
[322,16100,3252000,17375000,2012500000],
[323,16150,3268000,17482000,2018750000],
[324,16200,3284000,17589000,2025000000],
[325,16250,3298000,17696000,2031250000],
[326,16300,3314000,17804000,2037500000],
[327,16350,3330000,17912000,2043750000],
[328,16400,3346000,18019000,2050000000],
[329,16450,3362000,18127000,2056250000],
[330,16500,3378000,18235000,2062500000],
[331,16550,3394000,18343000,2068750000],
[332,16600,3410000,18451000,2075000000],
[333,16650,3426000,18559000,2081250000],
[334,16700,3442000,18667000,2087500000],
[335,16750,3458000,18775000,2093750000],
[336,16800,3474000,18884000,2100000000],
[337,16850,3490000,18992000,2106250000],
[338,16900,3504000,19101000,2112500000],
[339,16950,3520000,19210000,2118750000],
[340,17000,3536000,19319000,2125000000],
[341,17050,3552000,19428000,2131250000],
[342,17100,3568000,19537000,2137500000],
[343,17150,3584000,19646000,2143750000],
[344,17200,3600000,19755000,2150000000],
[345,17250,3616000,19864000,2156250000],
[346,17300,3632000,19974000,2162500000],
[347,17350,3648000,20083000,2168750000],
[348,17400,3664000,20193000,2175000000],
[349,17450,3680000,20303000,2181250000],
[350,17500,3696000,20413000,2187500000],
[351,17550,3712000,20523000,2193750000],
[352,17600,3728000,20633000,2200000000],
[353,17650,3744000,20743000,2206250000],
[354,17700,3762000,20853000,2212500000],
[355,17750,3778000,20963000,2218750000],
[356,17800,3794000,21074000,2225000000],
[357,17850,3810000,21184000,2231250000],
[358,17900,3826000,21295000,2237500000],
[359,17950,3842000,21406000,2243750000],
[360,18000,3858000,21517000,2250000000],
[361,18050,3874000,21628000,2256250000],
[362,18100,3890000,21739000,2262500000],
[363,18150,3906000,21850000,2268750000],
[364,18200,3922000,21961000,2275000000],
[365,18250,3938000,22073000,2281250000],
[366,18300,3954000,22184000,2287500000],
[367,18350,3972000,22296000,2293750000],
[368,18400,3988000,22408000,2300000000],
[369,18450,4004000,22519000,2306250000],
[370,18500,4020000,22631000,2312500000],
[371,18550,4036000,22743000,2318750000],
[372,18600,4052000,22855000,2325000000],
[373,18650,4068000,22968000,2331250000],
[374,18700,4086000,23080000,2337500000],
[375,18750,4102000,23193000,2343750000],
[376,18800,4118000,23305000,2350000000],
[377,18850,4134000,23418000,2356250000],
[378,18900,4150000,23531000,2362500000],
[379,18950,4166000,23643000,2368750000],
[380,19000,4184000,23756000,2375000000],
[381,19050,4200000,23870000,2381250000],
[382,19100,4216000,23983000,2387500000],
[383,19150,4232000,24096000,2393750000],
[384,19200,4248000,24210000,2400000000],
[385,19250,4266000,24323000,2406250000],
[386,19300,4282000,24437000,2412500000],
[387,19350,4298000,24551000,2418750000],
[388,19400,4314000,24664000,2425000000],
[389,19450,4332000,24778000,2431250000],
[390,19500,4348000,24892000,2437500000],
[391,19550,4364000,25007000,2443750000],
[392,19600,4380000,25121000,2450000000],
[393,19650,4398000,25235000,2456250000],
[394,19700,4414000,25350000,2462500000],
[395,19750,4430000,25465000,2468750000],
[396,19800,4446000,25579000,2475000000],
[397,19850,4464000,25694000,2481250000],
[398,19900,4480000,25809000,2487500000],
[399,19950,4496000,25924000,2493750000],
[400,20000,4514000,26040000,2500000000],
[401,20050,4530000,26155000,2506250000],
[402,20100,4546000,26270000,2512500000],
[403,20150,4564000,26386000,2518750000],
[404,20200,4580000,26502000,2525000000],
[405,20250,4596000,26617000,2531250000],
[406,20300,4614000,26733000,2537500000],
[407,20350,4630000,26849000,2543750000],
[408,20400,4646000,26965000,2550000000],
[409,20450,4664000,27082000,2556250000],
[410,20500,4680000,27198000,2562500000],
[411,20550,4696000,27314000,2568750000],
[412,20600,4714000,27431000,2575000000],
[413,20650,4730000,27548000,2581250000],
[414,20700,4746000,27665000,2587500000],
[415,20750,4764000,27781000,2593750000],
[416,20800,4780000,27898000,2600000000],
[417,20850,4798000,28016000,2606250000],
[418,20900,4814000,28133000,2612500000],
[419,20950,4832000,28250000,2618750000],
[420,21000,4848000,28368000,2625000000],
[421,21050,4864000,28486000,2631250000],
[422,21100,4882000,28603000,2637500000],
[423,21150,4898000,28721000,2643750000],
[424,21200,4916000,28839000,2650000000],
[425,21250,4932000,28957000,2656250000],
[426,21300,4950000,29075000,2662500000],
[427,21350,4966000,29194000,2668750000],
[428,21400,4984000,29312000,2675000000],
[429,21450,5000000,29431000,2681250000],
[430,21500,5016000,29550000,2687500000],
[431,21550,5034000,29668000,2693750000],
[432,21600,5050000,29787000,2700000000],
[433,21650,5068000,29906000,2706250000],
[434,21700,5084000,30026000,2712500000],
[435,21750,5102000,30145000,2718750000],
[436,21800,5120000,30264000,2725000000],
[437,21850,5136000,30384000,2731250000],
[438,21900,5154000,30504000,2737500000],
[439,21950,5170000,30624000,2743750000],
[440,22000,5188000,30743000,2750000000],
[441,22050,5204000,30864000,2756250000],
[442,22100,5222000,30984000,2762500000],
[443,22150,5238000,31104000,2768750000],
[444,22200,5256000,31224000,2775000000],
[445,22250,5274000,31345000,2781250000],
[446,22300,5290000,31466000,2787500000],
[447,22350,5308000,31586000,2793750000],
[448,22400,5324000,31707000,2800000000],
[449,22450,5342000,31828000,2806250000],
[450,22500,5358000,31950000,2812500000],
[451,22550,5376000,32071000,2818750000],
[452,22600,5394000,32192000,2825000000],
[453,22650,5410000,32314000,2831250000],
[454,22700,5428000,32436000,2837500000],
[455,22750,5446000,32557000,2843750000],
[456,22800,5462000,32679000,2850000000],
[457,22850,5480000,32801000,2856250000],
[458,22900,5498000,32924000,2862500000],
[459,22950,5514000,33046000,2868750000],
[460,23000,5532000,33168000,2875000000],
[461,23050,5550000,33291000,2881250000],
[462,23100,5566000,33414000,2887500000],
[463,23150,5584000,33537000,2893750000],
[464,23200,5602000,33660000,2900000000],
[465,23250,5618000,33783000,2906250000],
[466,23300,5636000,33906000,2912500000],
[467,23350,5654000,34029000,2918750000],
[468,23400,5672000,34153000,2925000000],
[469,23450,5688000,34276000,2931250000],
[470,23500,5706000,34400000,2937500000],
[471,23550,5724000,34524000,2943750000],
[472,23600,5742000,34648000,2950000000],
[473,23650,5758000,34772000,2956250000],
[474,23700,5776000,34896000,2962500000],
[475,23750,5794000,35021000,2968750000],
[476,23800,5812000,35145000,2975000000],
[477,23850,5828000,35270000,2981250000],
[478,23900,5846000,35395000,2987500000],
[479,23950,5864000,35520000,2993750000],
[480,24000,5882000,35645000,3000000000],
[481,24050,5900000,35770000,3006250000],
[482,24100,5916000,35896000,3012500000],
[483,24150,5934000,36021000,3018750000],
[484,24200,5952000,36147000,3025000000],
[485,24250,5970000,36273000,3031250000],
[486,24300,5988000,36398000,3037500000],
[487,24350,6006000,36524000,3043750000],
[488,24400,6022000,36651000,3050000000],
[489,24450,6040000,36777000,3056250000],
[490,24500,6058000,36903000,3062500000],
[491,24550,6076000,37030000,3068750000],
[492,24600,6094000,37157000,3075000000],
[493,24650,6112000,37284000,3081250000],
[494,24700,6130000,37411000,3087500000],
[495,24750,6148000,37538000,3093750000],
[496,24800,6166000,37665000,3100000000],
[497,24850,6182000,37793000,3106250000],
[498,24900,6200000,37920000,3112500000],
[499,24950,6218000,38048000,3118750000],
[500,25000,6236000,38176000,3125000000],
[501,25050,6254000,38304000,3131250000],
[502,25100,6272000,38432000,3137500000],
[503,25150,6290000,38560000,3143750000],
[504,25200,6308000,38689000,3150000000],
[505,25250,6326000,38817000,3156250000],
[506,25300,6344000,38946000,3162500000],
[507,25350,6362000,39075000,3168750000],
[508,25400,6380000,39204000,3175000000],
[509,25450,6398000,39333000,3181250000],
[510,25500,6416000,39462000,3187500000],
[511,25550,6434000,39592000,3193750000],
[512,25600,6452000,39721000,3200000000],
[513,25650,6470000,39851000,3206250000],
[514,25700,6488000,39981000,3212500000],
[515,25750,6506000,40111000,3218750000],
[516,25800,6524000,40241000,3225000000],
[517,25850,6542000,40371000,3231250000],
[518,25900,6560000,40502000,3237500000],
[519,25950,6580000,40632000,3243750000],
[520,26000,6598000,40763000,3250000000],
[521,26050,6616000,40894000,3256250000],
[522,26100,6634000,41025000,3262500000],
[523,26150,6652000,41156000,3268750000],
[524,26200,6670000,41288000,3275000000],
[525,26250,6688000,41419000,3281250000],
[526,26300,6706000,41551000,3287500000],
[527,26350,6724000,41683000,3293750000],
[528,26400,6742000,41815000,3300000000],
[529,26450,6762000,41947000,3306250000],
[530,26500,6780000,42079000,3312500000],
[531,26550,6798000,42211000,3318750000],
[532,26600,6816000,42344000,3325000000],
[533,26650,6834000,42477000,3331250000],
[534,26700,6852000,42610000,3337500000],
[535,26750,6872000,42743000,3343750000],
[536,26800,6890000,42876000,3350000000],
[537,26850,6908000,43009000,3356250000],
[538,26900,6926000,43143000,3362500000],
[539,26950,6946000,43276000,3368750000],
[540,27000,6964000,43410000,3375000000],
[541,27050,6982000,43544000,3381250000],
[542,27100,7000000,43678000,3387500000],
[543,27150,7018000,43812000,3393750000],
[544,27200,7038000,43947000,3400000000],
[545,27250,7056000,44081000,3406250000],
[546,27300,7074000,44216000,3412500000],
[547,27350,7094000,44351000,3418750000],
[548,27400,7112000,44486000,3425000000],
[549,27450,7130000,44621000,3431250000],
[550,27500,7148000,44757000,3437500000],
[551,27550,7168000,44892000,3443750000],
[552,27600,7186000,45028000,3450000000],
[553,27650,7204000,45164000,3456250000],
[554,27700,7224000,45300000,3462500000],
[555,27750,7242000,45436000,3468750000],
[556,27800,7260000,45572000,3475000000],
[557,27850,7280000,45709000,3481250000],
[558,27900,7298000,45846000,3487500000],
[559,27950,7316000,45982000,3493750000],
[560,28000,7336000,46119000,3500000000],
[561,28050,7354000,46257000,3506250000],
[562,28100,7374000,46394000,3512500000],
[563,28150,7392000,46531000,3518750000],
[564,28200,7410000,46669000,3525000000],
[565,28250,7430000,46807000,3531250000],
[566,28300,7448000,46945000,3537500000],
[567,28350,7468000,47083000,3543750000],
[568,28400,7486000,47221000,3550000000],
[569,28450,7506000,47360000,3556250000],
[570,28500,7524000,47499000,3562500000],
[571,28550,7544000,47637000,3568750000],
[572,28600,7562000,47776000,3575000000],
[573,28650,7580000,47916000,3581250000],
[574,28700,7600000,48055000,3587500000],
[575,28750,7618000,48194000,3593750000],
[576,28800,7638000,48334000,3600000000],
[577,28850,7656000,48474000,3606250000],
[578,28900,7676000,48614000,3612500000],
[579,28950,7696000,48754000,3618750000],
[580,29000,7714000,48895000,3625000000],
[581,29050,7734000,49035000,3631250000],
[582,29100,7752000,49176000,3637500000],
[583,29150,7772000,49317000,3643750000],
[584,29200,7790000,49458000,3650000000],
[585,29250,7810000,49599000,3656250000],
[586,29300,7828000,49740000,3662500000],
[587,29350,7848000,49882000,3668750000],
[588,29400,7868000,50024000,3675000000],
[589,29450,7886000,50166000,3681250000],
[590,29500,7906000,50308000,3687500000],
[591,29550,7924000,50450000,3693750000],
[592,29600,7944000,50593000,3700000000],
[593,29650,7964000,50735000,3706250000],
[594,29700,7982000,50878000,3712500000],
[595,29750,8002000,51021000,3718750000],
[596,29800,8022000,51164000,3725000000],
[597,29850,8040000,51308000,3731250000],
[598,29900,8060000,51451000,3737500000],
[599,29950,8080000,51595000,3743750000],
[600,30000,8098000,51739000,3750000000],
[601,30050,8118000,51883000,3756250000],
[602,30100,8138000,52027000,3762500000],
[603,30150,8158000,52172000,3768750000],
[604,30200,8176000,52316000,3775000000],
[605,30250,8196000,52461000,3781250000],
[606,30300,8216000,52606000,3787500000],
[607,30350,8236000,52751000,3793750000],
[608,30400,8254000,52897000,3800000000],
[609,30450,8274000,53042000,3806250000],
[610,30500,8294000,53188000,3812500000],
[611,30550,8314000,53334000,3818750000],
[612,30600,8332000,53480000,3825000000],
[613,30650,8352000,53627000,3831250000],
[614,30700,8372000,53773000,3837500000],
[615,30750,8392000,53920000,3843750000],
[616,30800,8412000,54067000,3850000000],
[617,30850,8432000,54214000,3856250000],
[618,30900,8450000,54361000,3862500000],
[619,30950,8470000,54509000,3868750000],
[620,31000,8490000,54656000,3875000000],
[621,31050,8510000,54804000,3881250000],
[622,31100,8530000,54952000,3887500000],
[623,31150,8550000,55100000,3893750000],
[624,31200,8570000,55249000,3900000000],
[625,31250,8590000,55397000,3906250000],
[626,31300,8608000,55546000,3912500000],
[627,31350,8628000,55695000,3918750000],
[628,31400,8648000,55844000,3925000000],
[629,31450,8668000,55994000,3931250000],
[630,31500,8688000,56143000,3937500000],
[631,31550,8708000,56293000,3943750000],
[632,31600,8728000,56443000,3950000000],
[633,31650,8748000,56593000,3956250000],
[634,31700,8768000,56744000,3962500000],
[635,31750,8788000,56895000,3968750000],
[636,31800,8808000,57045000,3975000000],
[637,31850,8828000,57196000,3981250000],
[638,31900,8848000,57348000,3987500000],
[639,31950,8868000,57499000,3993750000],
[640,32000,8888000,57651000,4000000000],
[641,32050,8908000,57802000,4006250000],
[642,32100,8928000,57954000,4012500000],
[643,32150,8948000,58107000,4018750000],
[644,32200,8970000,58259000,4025000000],
[645,32250,8990000,58412000,4031250000],
[646,32300,9010000,58565000,4037500000],
[647,32350,9030000,58718000,4043750000],
[648,32400,9050000,58871000,4050000000],
[649,32450,9070000,59024000,4056250000],
[650,32500,9090000,59178000,4062500000],
[651,32550,9110000,59332000,4068750000],
[652,32600,9130000,59486000,4075000000],
[653,32650,9152000,59640000,4081250000],
[654,32700,9172000,59795000,4087500000],
[655,32750,9192000,59950000,4093750000],
[656,32800,9212000,60105000,4100000000],
[657,32850,9232000,60260000,4106250000],
[658,32900,9254000,60415000,4112500000],
[659,32950,9274000,60571000,4118750000],
[660,33000,9294000,60726000,4125000000],
[661,33050,9314000,60882000,4131250000],
[662,33100,9334000,61039000,4137500000],
[663,33150,9356000,61195000,4143750000],
[664,33200,9376000,61352000,4150000000],
[665,33250,9396000,61509000,4156250000],
[666,33300,9418000,61666000,4162500000],
[667,33350,9438000,61823000,4168750000],
[668,33400,9458000,61981000,4175000000],
[669,33450,9478000,62138000,4181250000],
[670,33500,9500000,62296000,4187500000],
[671,33550,9520000,62455000,4193750000],
[672,33600,9540000,62613000,4200000000],
[673,33650,9562000,62772000,4206250000],
[674,33700,9582000,62931000,4212500000],
[675,33750,9602000,63090000,4218750000],
[676,33800,9624000,63249000,4225000000],
[677,33850,9644000,63409000,4231250000],
[678,33900,9666000,63568000,4237500000],
[679,33950,9686000,63728000,4243750000],
[680,34000,9706000,63889000,4250000000],
[681,34050,9728000,64049000,4256250000],
[682,34100,9748000,64210000,4262500000],
[683,34150,9770000,64371000,4268750000],
[684,34200,9790000,64532000,4275000000],
[685,34250,9812000,64693000,4281250000],
[686,34300,9832000,64855000,4287500000],
[687,34350,9854000,65017000,4293750000],
[688,34400,9874000,65179000,4300000000],
[689,34450,9896000,65341000,4306250000],
[690,34500,9916000,65504000,4312500000],
[691,34550,9938000,65666000,4318750000],
[692,34600,9958000,65829000,4325000000],
[693,34650,9980000,65993000,4331250000],
[694,34700,10000000,66156000,4337500000],
[695,34750,10022000,66320000,4343750000],
[696,34800,10042000,66484000,4350000000],
[697,34850,10064000,66648000,4356250000],
[698,34900,10086000,66813000,4362500000],
[699,34950,10106000,66977000,4368750000],
[700,35000,10128000,67142000,4375000000],
[701,35050,10148000,67307000,4381250000],
[702,35100,10170000,67473000,4387500000],
[703,35150,10192000,67639000,4393750000],
[704,35200,10212000,67804000,4400000000],
[705,35250,10234000,67971000,4406250000],
[706,35300,10256000,68137000,4412500000],
[707,35350,10276000,68304000,4418750000],
[708,35400,10298000,68471000,4425000000],
[709,35450,10320000,68638000,4431250000],
[710,35500,10340000,68805000,4437500000],
[711,35550,10362000,68973000,4443750000],
[712,35600,10384000,69141000,4450000000],
[713,35650,10406000,69309000,4456250000],
[714,35700,10426000,69477000,4462500000],
[715,35750,10448000,69646000,4468750000],
[716,35800,10470000,69815000,4475000000],
[717,35850,10492000,69984000,4481250000],
[718,35900,10512000,70153000,4487500000],
[719,35950,10534000,70323000,4493750000],
[720,36000,10556000,70493000,4500000000],
[721,36050,10578000,70663000,4506250000],
[722,36100,10600000,70834000,4512500000],
[723,36150,10622000,71004000,4518750000],
[724,36200,10642000,71175000,4525000000],
[725,36250,10664000,71347000,4531250000],
[726,36300,10686000,71518000,4537500000],
[727,36350,10708000,71690000,4543750000],
[728,36400,10730000,71862000,4550000000],
[729,36450,10752000,72034000,4556250000],
[730,36500,10774000,72207000,4562500000],
[731,36550,10796000,72380000,4568750000],
[732,36600,10818000,72553000,4575000000],
[733,36650,10840000,72726000,4581250000],
[734,36700,10862000,72900000,4587500000],
[735,36750,10884000,73074000,4593750000],
[736,36800,10906000,73248000,4600000000],
[737,36850,10928000,73422000,4606250000],
[738,36900,10950000,73597000,4612500000],
[739,36950,10972000,73772000,4618750000],
[740,37000,10994000,73947000,4625000000],
[741,37050,11016000,74123000,4631250000],
[742,37100,11038000,74298000,4637500000],
[743,37150,11060000,74475000,4643750000],
[744,37200,11082000,74651000,4650000000],
[745,37250,11104000,74828000,4656250000],
[746,37300,11126000,75005000,4662500000],
[747,37350,11148000,75182000,4668750000],
[748,37400,11170000,75359000,4675000000],
[749,37450,11192000,75537000,4681250000],
[750,37500,11216000,75715000,4687500000],
[751,37550,11238000,75893000,4693750000],
[752,37600,11260000,76072000,4700000000],
[753,37650,11282000,76251000,4706250000],
[754,37700,11304000,76430000,4712500000],
[755,37750,11326000,76610000,4718750000],
[756,37800,11350000,76789000,4725000000],
[757,37850,11372000,76969000,4731250000],
[758,37900,11394000,77150000,4737500000],
[759,37950,11416000,77330000,4743750000],
[760,38000,11440000,77511000,4750000000],
[761,38050,11462000,77693000,4756250000],
[762,38100,11484000,77874000,4762500000],
[763,38150,11506000,78056000,4768750000],
[764,38200,11530000,78238000,4775000000],
[765,38250,11552000,78420000,4781250000],
[766,38300,11574000,78603000,4787500000],
[767,38350,11598000,78786000,4793750000],
[768,38400,11620000,78970000,4800000000],
[769,38450,11642000,79153000,4806250000],
[770,38500,11666000,79337000,4812500000],
[771,38550,11688000,79521000,4818750000],
[772,38600,11712000,79706000,4825000000],
[773,38650,11734000,79891000,4831250000],
[774,38700,11756000,80076000,4837500000],
[775,38750,11780000,80261000,4843750000],
[776,38800,11802000,80447000,4850000000],
[777,38850,11826000,80633000,4856250000],
[778,38900,11848000,80819000,4862500000],
[779,38950,11872000,81006000,4868750000],
[780,39000,11894000,81193000,4875000000],
[781,39050,11918000,81380000,4881250000],
[782,39100,11940000,81568000,4887500000],
[783,39150,11964000,81756000,4893750000],
[784,39200,11986000,81944000,4900000000],
[785,39250,12010000,82133000,4906250000],
[786,39300,12032000,82322000,4912500000],
[787,39350,12056000,82511000,4918750000],
[788,39400,12080000,82701000,4925000000],
[789,39450,12102000,82890000,4931250000],
[790,39500,12126000,83081000,4937500000],
[791,39550,12148000,83271000,4943750000],
[792,39600,12172000,83462000,4950000000],
[793,39650,12196000,83653000,4956250000],
[794,39700,12218000,83845000,4962500000],
[795,39750,12242000,84036000,4968750000],
[796,39800,12266000,84229000,4975000000],
[797,39850,12288000,84421000,4981250000],
[798,39900,12312000,84614000,4987500000],
[799,39950,12336000,84807000,4993750000],
[800,40000,12360000,85000000,5000000000],
[801,40050,12382000,85194000,5006250000],
[802,40100,12406000,85388000,5012500000],
[803,40150,12430000,85583000,5018750000],
[804,40200,12454000,85778000,5025000000],
[805,40250,12476000,85973000,5031250000],
[806,40300,12500000,86168000,5037500000],
[807,40350,12524000,86364000,5043750000],
[808,40400,12548000,86561000,5050000000],
[809,40450,12572000,86757000,5056250000],
[810,40500,12596000,86954000,5062500000],
[811,40550,12620000,87151000,5068750000],
[812,40600,12642000,87349000,5075000000],
[813,40650,12666000,87547000,5081250000],
[814,40700,12690000,87745000,5087500000],
[815,40750,12714000,87944000,5093750000],
[816,40800,12738000,88143000,5100000000],
[817,40850,12762000,88342000,5106250000],
[818,40900,12786000,88542000,5112500000],
[819,40950,12810000,88742000,5118750000],
[820,41000,12834000,88942000,5125000000],
[821,41050,12858000,89143000,5131250000],
[822,41100,12882000,89344000,5137500000],
[823,41150,12906000,89546000,5143750000],
[824,41200,12930000,89748000,5150000000],
[825,41250,12954000,89950000,5156250000],
[826,41300,12978000,90153000,5162500000],
[827,41350,13002000,90356000,5168750000],
[828,41400,13026000,90559000,5175000000],
[829,41450,13052000,90763000,5181250000],
[830,41500,13076000,90967000,5187500000],
[831,41550,13100000,91171000,5193750000],
[832,41600,13124000,91376000,5200000000],
[833,41650,13148000,91581000,5206250000],
[834,41700,13172000,91787000,5212500000],
[835,41750,13198000,91993000,5218750000],
[836,41800,13222000,92199000,5225000000],
[837,41850,13246000,92406000,5231250000],
[838,41900,13270000,92613000,5237500000],
[839,41950,13294000,92821000,5243750000],
[840,42000,13320000,93029000,5250000000],
[841,42050,13344000,93237000,5256250000],
[842,42100,13368000,93446000,5262500000],
[843,42150,13394000,93655000,5268750000],
[844,42200,13418000,93864000,5275000000],
[845,42250,13442000,94074000,5281250000],
[846,42300,13468000,94284000,5287500000],
[847,42350,13492000,94495000,5293750000],
[848,42400,13516000,94706000,5300000000],
[849,42450,13542000,94917000,5306250000],
[850,42500,13566000,95129000,5312500000],
[851,42550,13592000,95341000,5318750000],
[852,42600,13616000,95554000,5325000000],
[853,42650,13640000,95767000,5331250000],
[854,42700,13666000,95981000,5337500000],
[855,42750,13690000,96195000,5343750000],
[856,42800,13716000,96409000,5350000000],
[857,42850,13740000,96623000,5356250000],
[858,42900,13766000,96839000,5362500000],
[859,42950,13790000,97054000,5368750000],
[860,43000,13816000,97270000,5375000000],
[861,43050,13842000,97486000,5381250000],
[862,43100,13866000,97703000,5387500000],
[863,43150,13892000,97920000,5393750000],
[864,43200,13916000,98138000,5400000000],
[865,43250,13942000,98356000,5406250000],
[866,43300,13968000,98574000,5412500000],
[867,43350,13992000,98793000,5418750000],
[868,43400,14018000,99013000,5425000000],
[869,43450,14044000,99232000,5431250000],
[870,43500,14068000,99453000,5437500000],
[871,43550,14094000,99673000,5443750000],
[872,43600,14120000,99894000,5450000000],
[873,43650,14146000,100116000,5456250000],
[874,43700,14170000,100338000,5462500000],
[875,43750,14196000,100560000,5468750000],
[876,43800,14222000,100783000,5475000000],
[877,43850,14248000,101006000,5481250000],
[878,43900,14272000,101230000,5487500000],
[879,43950,14298000,101454000,5493750000],
[880,44000,14324000,101678000,5500000000],
[881,44050,14350000,101904000,5506250000],
[882,44100,14376000,102129000,5512500000],
[883,44150,14402000,102355000,5518750000],
[884,44200,14428000,102581000,5525000000],
[885,44250,14454000,102808000,5531250000],
[886,44300,14480000,103036000,5537500000],
[887,44350,14504000,103263000,5543750000],
[888,44400,14530000,103492000,5550000000],
[889,44450,14556000,103720000,5556250000],
[890,44500,14582000,103949000,5562500000],
[891,44550,14608000,104179000,5568750000],
[892,44600,14634000,104409000,5575000000],
[893,44650,14662000,104640000,5581250000],
[894,44700,14688000,104871000,5587500000],
[895,44750,14714000,105102000,5593750000],
[896,44800,14740000,105334000,5600000000],
[897,44850,14766000,105567000,5606250000],
[898,44900,14792000,105800000,5612500000],
[899,44950,14818000,106033000,5618750000],
[900,45000,14844000,106267000,5625000000],
[901,45050,14872000,106501000,5631250000],
[902,45100,14898000,106736000,5637500000],
[903,45150,14924000,106972000,5643750000],
[904,45200,14950000,107208000,5650000000],
[905,45250,14976000,107444000,5656250000],
[906,45300,15004000,107681000,5662500000],
[907,45350,15030000,107918000,5668750000],
[908,45400,15056000,108156000,5675000000],
[909,45450,15084000,108394000,5681250000],
[910,45500,15110000,108633000,5687500000],
[911,45550,15136000,108873000,5693750000],
[912,45600,15164000,109113000,5700000000],
[913,45650,15190000,109353000,5706250000],
[914,45700,15216000,109594000,5712500000],
[915,45750,15244000,109835000,5718750000],
[916,45800,15270000,110077000,5725000000],
[917,45850,15298000,110320000,5731250000],
[918,45900,15324000,110563000,5737500000],
[919,45950,15352000,110806000,5743750000],
[920,46000,15378000,111050000,5750000000],
[921,46050,15406000,111295000,5756250000],
[922,46100,15432000,111540000,5762500000],
[923,46150,15460000,111785000,5768750000],
[924,46200,15486000,112032000,5775000000],
[925,46250,15514000,112278000,5781250000],
[926,46300,15540000,112525000,5787500000],
[927,46350,15568000,112773000,5793750000],
[928,46400,15596000,113022000,5800000000],
[929,46450,15622000,113270000,5806250000],
[930,46500,15650000,113520000,5812500000],
[931,46550,15678000,113770000,5818750000],
[932,46600,15704000,114020000,5825000000],
[933,46650,15732000,114271000,5831250000],
[934,46700,15760000,114523000,5837500000],
[935,46750,15788000,114775000,5843750000],
[936,46800,15814000,115028000,5850000000],
[937,46850,15842000,115281000,5856250000],
[938,46900,15870000,115535000,5862500000],
[939,46950,15898000,115789000,5868750000],
[940,47000,15926000,116044000,5875000000],
[941,47050,15952000,116299000,5881250000],
[942,47100,15980000,116556000,5887500000],
[943,47150,16008000,116812000,5893750000],
[944,47200,16036000,117069000,5900000000],
[945,47250,16064000,117327000,5906250000],
[946,47300,16092000,117586000,5912500000],
[947,47350,16120000,117845000,5918750000],
[948,47400,16148000,118104000,5925000000],
[949,47450,16176000,118364000,5931250000],
[950,47500,16204000,118625000,5937500000],
[951,47550,16232000,118887000,5943750000],
[952,47600,16260000,119148000,5950000000],
[953,47650,16288000,119411000,5956250000],
[954,47700,16316000,119674000,5962500000],
[955,47750,16344000,119938000,5968750000],
[956,47800,16374000,120202000,5975000000],
[957,47850,16402000,120467000,5981250000],
[958,47900,16430000,120733000,5987500000],
[959,47950,16458000,120999000,5993750000],
[960,48000,16486000,121266000,6000000000],
[961,48050,16514000,121533000,6006250000],
[962,48100,16544000,121801000,6012500000],
[963,48150,16572000,122070000,6018750000],
[964,48200,16600000,122339000,6025000000],
[965,48250,16630000,122609000,6031250000],
[966,48300,16658000,122880000,6037500000],
[967,48350,16686000,123151000,6043750000],
[968,48400,16716000,123422000,6050000000],
[969,48450,16744000,123695000,6056250000],
[970,48500,16772000,123968000,6062500000],
[971,48550,16802000,124242000,6068750000],
[972,48600,16830000,124516000,6075000000],
[973,48650,16860000,124791000,6081250000],
[974,48700,16888000,125067000,6087500000],
[975,48750,16918000,125343000,6093750000],
[976,48800,16946000,125620000,6100000000],
[977,48850,16976000,125898000,6106250000],
[978,48900,17004000,126176000,6112500000],
[979,48950,17034000,126455000,6118750000],
[980,49000,17064000,126735000,6125000000],
[981,49050,17092000,127015000,6131250000],
[982,49100,17122000,127296000,6137500000],
[983,49150,17152000,127578000,6143750000],
[984,49200,17180000,127860000,6150000000],
[985,49250,17210000,128143000,6156250000],
[986,49300,17240000,128427000,6162500000],
[987,49350,17268000,128711000,6168750000],
[988,49400,17298000,128996000,6175000000],
[989,49450,17328000,129282000,6181250000],
[990,49500,17358000,129568000,6187500000],
[991,49550,17388000,129855000,6193750000],
[992,49600,17416000,130143000,6200000000],
[993,49650,17446000,130432000,6206250000],
[994,49700,17476000,130721000,6212500000],
[995,49750,17506000,131011000,6218750000],
[996,49800,17536000,131302000,6225000000],
[997,49850,17566000,131593000,6231250000],
[998,49900,17596000,131885000,6237500000],
[999,49950,17626000,132178000,6243750000],
[1000,50000,17656000,132472000,6250000000],
[1001,50050,17686000,132766000,6256250000],
[1002,50100,17716000,133061000,6262500000],
[1003,50150,17746000,133357000,6268750000],
[1004,50200,17776000,133653000,6275000000],
[1005,50250,17806000,133950000,6281250000],
[1006,50300,17838000,134248000,6287500000],
[1007,50350,17868000,134547000,6293750000],
[1008,50400,17898000,134846000,6300000000],
[1009,50450,17928000,135147000,6306250000],
[1010,50500,17958000,135447000,6312500000],
[1011,50550,17990000,135749000,6318750000],
[1012,50600,18020000,136052000,6325000000],
[1013,50650,18050000,136355000,6331250000],
[1014,50700,18082000,136659000,6337500000],
[1015,50750,18112000,136964000,6343750000],
[1016,50800,18142000,137269000,6350000000],
[1017,50850,18174000,137575000,6356250000],
[1018,50900,18204000,137883000,6362500000],
[1019,50950,18234000,138190000,6368750000],
[1020,51000,18266000,138499000,6375000000],
[1021,51050,18296000,138808000,6381250000],
[1022,51100,18328000,139119000,6387500000],
[1023,51150,18358000,139430000,6393750000],
[1024,51200,18390000,139741000,6400000000],
[1025,51250,18422000,140054000,6406250000],
[1026,51300,18452000,140367000,6412500000],
[1027,51350,18484000,140682000,6418750000],
[1028,51400,18514000,140997000,6425000000],
[1029,51450,18546000,141312000,6431250000],
[1030,51500,18578000,141629000,6437500000],
[1031,51550,18610000,141947000,6443750000],
[1032,51600,18640000,142265000,6450000000],
[1033,51650,18672000,142584000,6456250000],
[1034,51700,18704000,142904000,6462500000],
[1035,51750,18736000,143225000,6468750000],
[1036,51800,18766000,143546000,6475000000],
[1037,51850,18798000,143869000,6481250000],
[1038,51900,18830000,144192000,6487500000],
[1039,51950,18862000,144516000,6493750000],
[1040,52000,18894000,144841000,6500000000],
[1041,52050,18926000,145167000,6506250000],
[1042,52100,18958000,145493000,6512500000],
[1043,52150,18990000,145821000,6518750000],
[1044,52200,19022000,146149000,6525000000],
[1045,52250,19054000,146479000,6531250000],
[1046,52300,19086000,146809000,6537500000],
[1047,52350,19118000,147140000,6543750000],
[1048,52400,19150000,147471000,6550000000],
[1049,52450,19184000,147804000,6556250000],
[1050,52500,19216000,148138000,6562500000],
[1051,52550,19248000,148472000,6568750000],
[1052,52600,19280000,148807000,6575000000],
[1053,52650,19312000,149144000,6581250000],
[1054,52700,19346000,149481000,6587500000],
[1055,52750,19378000,149819000,6593750000],
[1056,52800,19410000,150158000,6600000000],
[1057,52850,19444000,150498000,6606250000],
[1058,52900,19476000,150838000,6612500000],
[1059,52950,19508000,151180000,6618750000],
[1060,53000,19542000,151522000,6625000000],
[1061,53050,19574000,151866000,6631250000],
[1062,53100,19608000,152210000,6637500000],
[1063,53150,19640000,152556000,6643750000],
[1064,53200,19674000,152902000,6650000000],
[1065,53250,19706000,153249000,6656250000],
[1066,53300,19740000,153597000,6662500000],
[1067,53350,19774000,153946000,6668750000],
[1068,53400,19806000,154296000,6675000000],
[1069,53450,19840000,154647000,6681250000],
[1070,53500,19874000,154999000,6687500000],
[1071,53550,19906000,155351000,6693750000],
[1072,53600,19940000,155705000,6700000000],
[1073,53650,19974000,156060000,6706250000],
[1074,53700,20008000,156415000,6712500000],
[1075,53750,20040000,156772000,6718750000],
[1076,53800,20074000,157129000,6725000000],
[1077,53850,20108000,157488000,6731250000],
[1078,53900,20142000,157847000,6737500000],
[1079,53950,20176000,158208000,6743750000],
[1080,54000,20210000,158569000,6750000000],
[1081,54050,20244000,158931000,6756250000],
[1082,54100,20278000,159295000,6762500000],
[1083,54150,20312000,159659000,6768750000],
[1084,54200,20346000,160024000,6775000000],
[1085,54250,20380000,160391000,6781250000],
[1086,54300,20414000,160758000,6787500000],
[1087,54350,20448000,161126000,6793750000],
[1088,54400,20484000,161496000,6800000000],
[1089,54450,20518000,161866000,6806250000],
[1090,54500,20552000,162237000,6812500000],
[1091,54550,20586000,162609000,6818750000],
[1092,54600,20622000,162983000,6825000000],
[1093,54650,20656000,163357000,6831250000],
[1094,54700,20690000,163732000,6837500000],
[1095,54750,20726000,164109000,6843750000],
[1096,54800,20760000,164486000,6850000000],
[1097,54850,20796000,164864000,6856250000],
[1098,54900,20830000,165244000,6862500000],
[1099,54950,20866000,165624000,6868750000],
[1100,55000,20900000,166005000,6875000000],
[1101,55050,20936000,166388000,6881250000],
[1102,55100,20970000,166771000,6887500000],
[1103,55150,21006000,167156000,6893750000],
[1104,55200,21042000,167542000,6900000000],
[1105,55250,21076000,167928000,6906250000],
[1106,55300,21112000,168316000,6912500000],
[1107,55350,21148000,168705000,6918750000],
[1108,55400,21184000,169094000,6925000000],
[1109,55450,21218000,169485000,6931250000],
[1110,55500,21254000,169877000,6937500000],
[1111,55550,21290000,170270000,6943750000],
[1112,55600,21326000,170664000,6950000000],
[1113,55650,21362000,171059000,6956250000],
[1114,55700,21398000,171455000,6962500000],
[1115,55750,21434000,171852000,6968750000],
[1116,55800,21470000,172250000,6975000000],
[1117,55850,21506000,172650000,6981250000],
[1118,55900,21542000,173050000,6987500000],
[1119,55950,21578000,173452000,6993750000],
[1120,56000,21614000,173854000,7000000000],
[1121,56050,21650000,174258000,7006250000],
[1122,56100,21688000,174662000,7012500000],
[1123,56150,21724000,175068000,7018750000],
[1124,56200,21760000,175475000,7025000000],
[1125,56250,21796000,175883000,7031250000],
[1126,56300,21834000,176292000,7037500000],
[1127,56350,21870000,176702000,7043750000],
[1128,56400,21908000,177113000,7050000000],
[1129,56450,21944000,177525000,7056250000],
[1130,56500,21980000,177939000,7062500000],
[1131,56550,22018000,178353000,7068750000],
[1132,56600,22056000,178769000,7075000000],
[1133,56650,22092000,179185000,7081250000],
[1134,56700,22130000,179603000,7087500000],
[1135,56750,22166000,180022000,7093750000],
[1136,56800,22204000,180442000,7100000000],
[1137,56850,22242000,180863000,7106250000],
[1138,56900,22278000,181285000,7112500000],
[1139,56950,22316000,181708000,7118750000],
[1140,57000,22354000,182133000,7125000000],
[1141,57050,22392000,182558000,7131250000],
[1142,57100,22430000,182985000,7137500000],
[1143,57150,22468000,183412000,7143750000],
[1144,57200,22506000,183841000,7150000000],
[1145,57250,22544000,184271000,7156250000],
[1146,57300,22582000,184702000,7162500000],
[1147,57350,22620000,185134000,7168750000],
[1148,57400,22658000,185567000,7175000000],
[1149,57450,22696000,186002000,7181250000],
[1150,57500,22734000,186437000,7187500000],
[1151,57550,22772000,186873000,7193750000],
[1152,57600,22810000,187311000,7200000000],
[1153,57650,22850000,187750000,7206250000],
[1154,57700,22888000,188190000,7212500000],
[1155,57750,22926000,188630000,7218750000],
[1156,57800,22966000,189072000,7225000000],
[1157,57850,23004000,189516000,7231250000],
[1158,57900,23044000,189960000,7237500000],
[1159,57950,23082000,190405000,7243750000],
[1160,58000,23122000,190852000,7250000000],
[1161,58050,23160000,191299000,7256250000],
[1162,58100,23200000,191748000,7262500000],
[1163,58150,23238000,192198000,7268750000],
[1164,58200,23278000,192648000,7275000000],
[1165,58250,23318000,193100000,7281250000],
[1166,58300,23356000,193553000,7287500000],
[1167,58350,23396000,194007000,7293750000],
[1168,58400,23436000,194463000,7300000000],
[1169,58450,23476000,194919000,7306250000],
[1170,58500,23516000,195376000,7312500000],
[1171,58550,23556000,195835000,7318750000],
[1172,58600,23596000,196294000,7325000000],
[1173,58650,23636000,196755000,7331250000],
[1174,58700,23676000,197217000,7337500000],
[1175,58750,23716000,197680000,7343750000],
[1176,58800,23756000,198144000,7350000000],
[1177,58850,23796000,198609000,7356250000],
[1178,58900,23836000,199075000,7362500000],
[1179,58950,23876000,199542000,7368750000],
[1180,59000,23918000,200010000,7375000000],
[1181,59050,23958000,200479000,7381250000],
[1182,59100,23998000,200949000,7387500000],
[1183,59150,24040000,201421000,7393750000],
[1184,59200,24080000,201893000,7400000000],
[1185,59250,24122000,202367000,7406250000],
[1186,59300,24162000,202841000,7412500000],
[1187,59350,24204000,203317000,7418750000],
[1188,59400,24244000,203793000,7425000000],
[1189,59450,24286000,204271000,7431250000],
[1190,59500,24328000,204750000,7437500000],
[1191,59550,24368000,205229000,7443750000],
[1192,59600,24410000,205710000,7450000000],
[1193,59650,24452000,206192000,7456250000],
[1194,59700,24494000,206675000,7462500000],
[1195,59750,24536000,207158000,7468750000],
[1196,59800,24578000,207643000,7475000000],
[1197,59850,24620000,208129000,7481250000],
[1198,59900,24662000,208616000,7487500000],
[1199,59950,24704000,209104000,7493750000],
[1200,60000,24746000,209592000,7500000000],
[1201,60050,24788000,210082000,7506250000],
[1202,60100,24830000,210573000,7512500000],
[1203,60150,24872000,211065000,7518750000],
[1204,60200,24916000,211557000,7525000000],
[1205,60250,24958000,212051000,7531250000],
[1206,60300,25000000,212545000,7537500000],
[1207,60350,25044000,213041000,7543750000],
[1208,60400,25086000,213538000,7550000000],
[1209,60450,25130000,214035000,7556250000],
[1210,60500,25172000,214533000,7562500000],
[1211,60550,25216000,215033000,7568750000],
[1212,60600,25260000,215533000,7575000000],
[1213,60650,25302000,216034000,7581250000],
[1214,60700,25346000,216536000,7587500000],
[1215,60750,25390000,217039000,7593750000],
[1216,60800,25434000,217543000,7600000000],
[1217,60850,25476000,218048000,7606250000],
[1218,60900,25520000,218553000,7612500000],
[1219,60950,25564000,219060000,7618750000],
[1220,61000,25608000,219567000,7625000000],
[1221,61050,25652000,220075000,7631250000],
[1222,61100,25696000,220584000,7637500000],
[1223,61150,25742000,221094000,7643750000],
[1224,61200,25786000,221605000,7650000000],
[1225,61250,25830000,222116000,7656250000],
[1226,61300,25874000,222629000,7662500000],
[1227,61350,25920000,223142000,7668750000],
[1228,61400,25964000,223656000,7675000000],
[1229,61450,26008000,224171000,7681250000],
[1230,61500,26054000,224686000,7687500000],
[1231,61550,26100000,225203000,7693750000],
[1232,61600,26144000,225720000,7700000000],
[1233,61650,26190000,226238000,7706250000],
[1234,61700,26234000,226756000,7712500000],
[1235,61750,26280000,227276000,7718750000],
[1236,61800,26326000,227796000,7725000000],
[1237,61850,26372000,228317000,7731250000],
[1238,61900,26418000,228839000,7737500000],
[1239,61950,26464000,229361000,7743750000],
[1240,62000,26508000,229884000,7750000000],
[1241,62050,26556000,230408000,7756250000],
[1242,62100,26602000,230932000,7762500000],
[1243,62150,26648000,231457000,7768750000],
[1244,62200,26694000,231983000,7775000000],
[1245,62250,26740000,232509000,7781250000],
[1246,62300,26786000,233036000,7787500000],
[1247,62350,26834000,233564000,7793750000],
[1248,62400,26880000,234092000,7800000000],
[1249,62450,26928000,234621000,7806250000],
[1250,62500,26974000,235151000,7812500000],
[1251,62550,27022000,235681000,7818750000],
[1252,62600,27068000,236212000,7825000000],
[1253,62650,27116000,236743000,7831250000],
[1254,62700,27164000,237275000,7837500000],
[1255,62750,27210000,237807000,7843750000],
[1256,62800,27258000,238341000,7850000000],
[1257,62850,27306000,238874000,7856250000],
[1258,62900,27354000,239408000,7862500000],
[1259,62950,27402000,239943000,7868750000],
[1260,63000,27450000,240478000,7875000000],
[1261,63050,27498000,241014000,7881250000],
[1262,63100,27546000,241550000,7887500000],
[1263,63150,27594000,242086000,7893750000],
[1264,63200,27644000,242623000,7900000000],
[1265,63250,27692000,243161000,7906250000],
[1266,63300,27740000,243699000,7912500000],
[1267,63350,27790000,244238000,7918750000],
[1268,63400,27838000,244776000,7925000000],
[1269,63450,27888000,245316000,7931250000],
[1270,63500,27938000,245855000,7937500000],
[1271,63550,27986000,246396000,7943750000],
[1272,63600,28036000,246936000,7950000000],
[1273,63650,28086000,247477000,7956250000],
[1274,63700,28136000,248018000,7962500000],
[1275,63750,28184000,248560000,7968750000],
[1276,63800,28234000,249102000,7975000000],
[1277,63850,28284000,249644000,7981250000],
[1278,63900,28334000,250187000,7987500000],
[1279,63950,28386000,250730000,7993750000],
[1280,64000,28436000,251273000,8000000000],
[1281,64050,28486000,251817000,8006250000],
[1282,64100,28536000,252361000,8012500000],
[1283,64150,28588000,252905000,8018750000],
[1284,64200,28638000,253450000,8025000000],
[1285,64250,28690000,253994000,8031250000],
[1286,64300,28740000,254539000,8037500000],
[1287,64350,28792000,255085000,8043750000],
[1288,64400,28844000,255630000,8050000000],
[1289,64450,28894000,256176000,8056250000],
[1290,64500,28946000,256722000,8062500000],
[1291,64550,28998000,257268000,8068750000],
[1292,64600,29050000,257814000,8075000000],
[1293,64650,29102000,258361000,8081250000],
[1294,64700,29154000,258908000,8087500000],
[1295,64750,29206000,259454000,8093750000],
[1296,64800,29258000,260001000,8100000000],
[1297,64850,29312000,260549000,8106250000],
[1298,64900,29364000,261096000,8112500000],
[1299,64950,29416000,261643000,8118750000],
[1300,65000,29470000,262191000,8125000000],
[1301,65050,29522000,262739000,8131250000],
[1302,65100,29576000,263287000,8137500000],
[1303,65150,29630000,263835000,8143750000],
[1304,65200,29682000,264383000,8150000000],
[1305,65250,29736000,264931000,8156250000],
[1306,65300,29790000,265479000,8162500000],
[1307,65350,29844000,266027000,8168750000],
[1308,65400,29898000,266575000,8175000000],
[1309,65450,29952000,267124000,8181250000],
[1310,65500,30006000,267672000,8187500000],
[1311,65550,30060000,268220000,8193750000],
[1312,65600,30116000,268769000,8200000000],
[1313,65650,30170000,269317000,8206250000],
[1314,65700,30224000,269866000,8212500000],
[1315,65750,30280000,270414000,8218750000],
[1316,65800,30334000,270962000,8225000000],
[1317,65850,30390000,271511000,8231250000],
[1318,65900,30446000,272059000,8237500000],
[1319,65950,30500000,272608000,8243750000],
[1320,66000,30556000,273156000,8250000000],
[1321,66050,30612000,273704000,8256250000],
[1322,66100,30668000,274252000,8262500000],
[1323,66150,30724000,274801000,8268750000],
[1324,66200,30780000,275349000,8275000000],
[1325,66250,30838000,275897000,8281250000],
[1326,66300,30894000,276445000,8287500000],
[1327,66350,30950000,276992000,8293750000],
[1328,66400,31008000,277540000,8300000000],
[1329,66450,31064000,278088000,8306250000],
[1330,66500,31122000,278635000,8312500000],
[1331,66550,31178000,279183000,8318750000],
[1332,66600,31236000,279730000,8325000000],
[1333,66650,31294000,280277000,8331250000],
[1334,66700,31352000,280824000,8337500000],
[1335,66750,31410000,281371000,8343750000],
[1336,66800,31468000,281918000,8350000000],
[1337,66850,31526000,282465000,8356250000],
[1338,66900,31584000,283011000,8362500000],
[1339,66950,31642000,283557000,8368750000],
[1340,67000,31702000,284104000,8375000000],
[1341,67050,31760000,284650000,8381250000],
[1342,67100,31820000,285195000,8387500000],
[1343,67150,31878000,285741000,8393750000],
[1344,67200,31938000,286286000,8400000000],
[1345,67250,31998000,286832000,8406250000],
[1346,67300,32058000,287377000,8412500000],
[1347,67350,32118000,287922000,8418750000],
[1348,67400,32178000,288466000,8425000000],
[1349,67450,32238000,289011000,8431250000],
[1350,67500,32298000,289555000,8437500000],
[1351,67550,32358000,290099000,8443750000],
[1352,67600,32418000,290643000,8450000000],
[1353,67650,32480000,291186000,8456250000],
[1354,67700,32540000,291730000,8462500000],
[1355,67750,32602000,292273000,8468750000],
[1356,67800,32664000,292816000,8475000000],
[1357,67850,32724000,293358000,8481250000],
[1358,67900,32786000,293900000,8487500000],
[1359,67950,32848000,294443000,8493750000],
[1360,68000,32910000,294984000,8500000000],
[1361,68050,32972000,295526000,8506250000],
[1362,68100,33036000,296067000,8512500000],
[1363,68150,33098000,296609000,8518750000],
[1364,68200,33160000,297149000,8525000000],
[1365,68250,33224000,297690000,8531250000],
[1366,68300,33286000,298230000,8537500000],
[1367,68350,33350000,298770000,8543750000],
[1368,68400,33414000,299310000,8550000000],
[1369,68450,33476000,299849000,8556250000],
[1370,68500,33540000,300389000,8562500000],
[1371,68550,33604000,300928000,8568750000],
[1372,68600,33668000,301466000,8575000000],
[1373,68650,33734000,302005000,8581250000],
[1374,68700,33798000,302543000,8587500000],
[1375,68750,33862000,303080000,8593750000],
[1376,68800,33928000,303618000,8600000000],
[1377,68850,33992000,304155000,8606250000],
[1378,68900,34058000,304692000,8612500000],
[1379,68950,34124000,305229000,8618750000],
[1380,69000,34188000,305765000,8625000000],
[1381,69050,34254000,306301000,8631250000],
[1382,69100,34320000,306837000,8637500000],
[1383,69150,34386000,307372000,8643750000],
[1384,69200,34454000,307907000,8650000000],
[1385,69250,34520000,308442000,8656250000],
[1386,69300,34586000,308976000,8662500000],
[1387,69350,34654000,309510000,8668750000],
[1388,69400,34722000,310044000,8675000000],
[1389,69450,34788000,310578000,8681250000],
[1390,69500,34856000,311111000,8687500000],
[1391,69550,34924000,311644000,8693750000],
[1392,69600,34992000,312177000,8700000000],
[1393,69650,35060000,312709000,8706250000],
[1394,69700,35128000,313241000,8712500000],
[1395,69750,35198000,313773000,8718750000],
[1396,69800,35266000,314304000,8725000000],
[1397,69850,35336000,314835000,8731250000],
[1398,69900,35404000,315366000,8737500000],
[1399,69950,35474000,315897000,8743750000],
[1400,70000,35544000,316427000,8750000000],
[1401,70050,35614000,316957000,8756250000],
[1402,70100,35684000,317486000,8762500000],
[1403,70150,35754000,318015000,8768750000],
[1404,70200,35824000,318544000,8775000000],
[1405,70250,35894000,319073000,8781250000],
[1406,70300,35966000,319601000,8787500000],
[1407,70350,36036000,320129000,8793750000],
[1408,70400,36108000,320657000,8800000000],
[1409,70450,36180000,321185000,8806250000],
[1410,70500,36252000,321712000,8812500000],
[1411,70550,36324000,322238000,8818750000],
[1412,70600,36396000,322765000,8825000000],
[1413,70650,36468000,323291000,8831250000],
[1414,70700,36540000,323817000,8837500000],
[1415,70750,36614000,324343000,8843750000],
[1416,70800,36686000,324868000,8850000000],
[1417,70850,36760000,325393000,8856250000],
[1418,70900,36832000,325918000,8862500000],
[1419,70950,36906000,326442000,8868750000],
[1420,71000,36980000,326966000,8875000000],
[1421,71050,37054000,327490000,8881250000],
[1422,71100,37130000,328014000,8887500000],
[1423,71150,37204000,328537000,8893750000],
[1424,71200,37278000,329060000,8900000000],
[1425,71250,37354000,329583000,8906250000],
[1426,71300,37428000,330105000,8912500000],
[1427,71350,37504000,330627000,8918750000],
[1428,71400,37580000,331149000,8925000000],
[1429,71450,37656000,331671000,8931250000],
[1430,71500,37732000,332192000,8937500000],
[1431,71550,37808000,332713000,8943750000],
[1432,71600,37886000,333234000,8950000000],
[1433,71650,37962000,333755000,8956250000],
[1434,71700,38040000,334275000,8962500000],
[1435,71750,38116000,334795000,8968750000],
[1436,71800,38194000,335315000,8975000000],
[1437,71850,38272000,335834000,8981250000],
[1438,71900,38350000,336354000,8987500000],
[1439,71950,38428000,336873000,8993750000],
[1440,72000,38508000,337391000,9000000000],
[1441,72050,38586000,337910000,9006250000],
[1442,72100,38666000,338428000,9012500000],
[1443,72150,38744000,338946000,9018750000],
[1444,72200,38824000,339464000,9025000000],
[1445,72250,38904000,339982000,9031250000],
[1446,72300,38984000,340499000,9037500000],
[1447,72350,39064000,341016000,9043750000],
[1448,72400,39144000,341533000,9050000000],
[1449,72450,39226000,342050000,9056250000],
[1450,72500,39306000,342566000,9062500000],
[1451,72550,39388000,343083000,9068750000],
[1452,72600,39468000,343599000,9075000000],
[1453,72650,39550000,344114000,9081250000],
[1454,72700,39632000,344630000,9087500000],
[1455,72750,39714000,345146000,9093750000],
[1456,72800,39798000,345661000,9100000000],
[1457,72850,39880000,346176000,9106250000],
[1458,72900,39964000,346691000,9112500000],
[1459,72950,40046000,347206000,9118750000],
[1460,73000,40130000,347720000,9125000000],
[1461,73050,40214000,348234000,9131250000],
[1462,73100,40298000,348749000,9137500000],
[1463,73150,40382000,349263000,9143750000],
[1464,73200,40466000,349776000,9150000000],
[1465,73250,40550000,350290000,9156250000],
[1466,73300,40636000,350804000,9162500000],
[1467,73350,40722000,351317000,9168750000],
[1468,73400,40806000,351830000,9175000000],
[1469,73450,40892000,352343000,9181250000],
[1470,73500,40978000,352856000,9187500000],
[1471,73550,41064000,353369000,9193750000],
[1472,73600,41152000,353882000,9200000000],
[1473,73650,41238000,354394000,9206250000],
[1474,73700,41326000,354907000,9212500000],
[1475,73750,41412000,355419000,9218750000],
[1476,73800,41500000,355931000,9225000000],
[1477,73850,41588000,356443000,9231250000],
[1478,73900,41676000,356955000,9237500000],
[1479,73950,41764000,357467000,9243750000],
[1480,74000,41852000,357979000,9250000000],
[1481,74050,41942000,358491000,9256250000],
[1482,74100,42030000,359002000,9262500000],
[1483,74150,42120000,359514000,9268750000],
[1484,74200,42210000,360025000,9275000000],
[1485,74250,42300000,360537000,9281250000],
[1486,74300,42390000,361048000,9287500000],
[1487,74350,42480000,361559000,9293750000],
[1488,74400,42570000,362071000,9300000000],
[1489,74450,42662000,362582000,9306250000],
[1490,74500,42752000,363093000,9312500000],
[1491,74550,42844000,363604000,9318750000],
[1492,74600,42936000,364115000,9325000000],
[1493,74650,43028000,364626000,9331250000],
[1494,74700,43120000,365137000,9337500000],
[1495,74750,43212000,365648000,9343750000],
[1496,74800,43306000,366159000,9350000000],
[1497,74850,43398000,366670000,9356250000],
[1498,74900,43492000,367181000,9362500000],
[1499,74950,43586000,367693000,9368750000],
[1500,75000,43678000,368204000,9375000000],
[1501,75050,43774000,368715000,9381250000],
[1502,75100,43868000,369226000,9387500000],
[1503,75150,43962000,369737000,9393750000],
[1504,75200,44056000,370248000,9400000000],
[1505,75250,44152000,370759000,9406250000],
[1506,75300,44248000,371271000,9412500000],
[1507,75350,44342000,371782000,9418750000],
[1508,75400,44438000,372293000,9425000000],
[1509,75450,44534000,372805000,9431250000],
[1510,75500,44630000,373316000,9437500000],
[1511,75550,44728000,373828000,9443750000],
[1512,75600,44824000,374340000,9450000000],
[1513,75650,44922000,374852000,9456250000],
[1514,75700,45018000,375363000,9462500000],
[1515,75750,45116000,375876000,9468750000],
[1516,75800,45214000,376388000,9475000000],
[1517,75850,45312000,376900000,9481250000],
[1518,75900,45410000,377412000,9487500000],
[1519,75950,45510000,377925000,9493750000],
[1520,76000,45608000,378438000,9500000000],
[1521,76050,45708000,378951000,9506250000],
[1522,76100,45806000,379464000,9512500000],
[1523,76150,45906000,379977000,9518750000],
[1524,76200,46006000,380490000,9525000000],
[1525,76250,46106000,381004000,9531250000],
[1526,76300,46206000,381518000,9537500000],
[1527,76350,46306000,382032000,9543750000],
[1528,76400,46408000,382546000,9550000000],
[1529,76450,46508000,383060000,9556250000],
[1530,76500,46610000,383575000,9562500000],
[1531,76550,46712000,384090000,9568750000],
[1532,76600,46812000,384605000,9575000000],
[1533,76650,46914000,385121000,9581250000],
[1534,76700,47018000,385636000,9587500000],
[1535,76750,47120000,386152000,9593750000],
[1536,76800,47222000,386668000,9600000000],
[1537,76850,47326000,387185000,9606250000],
[1538,76900,47428000,387702000,9612500000],
[1539,76950,47532000,388219000,9618750000],
[1540,77000,47634000,388736000,9625000000],
[1541,77050,47738000,389254000,9631250000],
[1542,77100,47842000,389772000,9637500000],
[1543,77150,47946000,390291000,9643750000],
[1544,77200,48052000,390810000,9650000000],
[1545,77250,48156000,391329000,9656250000],
[1546,77300,48260000,391848000,9662500000],
[1547,77350,48366000,392368000,9668750000],
[1548,77400,48472000,392889000,9675000000],
[1549,77450,48576000,393409000,9681250000],
[1550,77500,48682000,393931000,9687500000],
[1551,77550,48788000,394452000,9693750000],
[1552,77600,48894000,394974000,9700000000],
[1553,77650,49000000,395497000,9706250000],
[1554,77700,49106000,396020000,9712500000],
[1555,77750,49214000,396543000,9718750000],
[1556,77800,49320000,397067000,9725000000],
[1557,77850,49428000,397592000,9731250000],
[1558,77900,49534000,398117000,9737500000],
[1559,77950,49642000,398642000,9743750000],
[1560,78000,49750000,399168000,9750000000],
[1561,78050,49858000,399695000,9756250000],
[1562,78100,49966000,400222000,9762500000],
[1563,78150,50074000,400750000,9768750000],
[1564,78200,50182000,401278000,9775000000],
[1565,78250,50290000,401807000,9781250000],
[1566,78300,50398000,402337000,9787500000],
[1567,78350,50508000,402867000,9793750000],
[1568,78400,50616000,403398000,9800000000],
[1569,78450,50726000,403929000,9806250000],
[1570,78500,50836000,404461000,9812500000],
[1571,78550,50944000,404994000,9818750000],
[1572,78600,51054000,405528000,9825000000],
[1573,78650,51164000,406062000,9831250000],
[1574,78700,51274000,406597000,9837500000],
[1575,78750,51384000,407133000,9843750000],
[1576,78800,51494000,407669000,9850000000],
[1577,78850,51604000,408207000,9856250000],
[1578,78900,51716000,408745000,9862500000],
[1579,78950,51826000,409284000,9868750000],
[1580,79000,51936000,409823000,9875000000],
[1581,79050,52048000,410364000,9881250000],
[1582,79100,52158000,410906000,9887500000],
[1583,79150,52270000,411448000,9893750000],
[1584,79200,52382000,411991000,9900000000],
[1585,79250,52492000,412536000,9906250000],
[1586,79300,52604000,413081000,9912500000],
[1587,79350,52716000,413627000,9918750000],
[1588,79400,52828000,414174000,9925000000],
[1589,79450,52940000,414722000,9931250000],
[1590,79500,53052000,415271000,9937500000],
[1591,79550,53164000,415822000,9943750000],
[1592,79600,53276000,416373000,9950000000],
[1593,79650,53388000,416925000,9956250000],
[1594,79700,53500000,417479000,9962500000],
[1595,79750,53614000,418034000,9968750000],
[1596,79800,53726000,418589000,9975000000],
[1597,79850,53838000,419147000,9981250000],
[1598,79900,53952000,419705000,9987500000],
[1599,79950,54064000,420264000,9993750000],
[1600,80000,54178000,420825000,10000000000],
[1601,80050,54290000,421387000,10006250000],
[1602,80100,54404000,421951000,10012500000],
[1603,80150,54516000,422515000,10018750000],
[1604,80200,54630000,423081000,10025000000],
[1605,80250,54744000,423649000,10031250000],
[1606,80300,54856000,424218000,10037500000],
[1607,80350,54970000,424788000,10043750000],
[1608,80400,55084000,425360000,10050000000],
[1609,80450,55198000,425934000,10056250000],
[1610,80500,55310000,426509000,10062500000],
[1611,80550,55424000,427086000,10068750000],
[1612,80600,55538000,427664000,10075000000],
[1613,80650,55652000,428244000,10081250000],
[1614,80700,55766000,428826000,10087500000],
[1615,80750,55880000,429409000,10093750000],
[1616,80800,55994000,429994000,10100000000],
[1617,80850,56108000,430581000,10106250000],
[1618,80900,56222000,431170000,10112500000],
[1619,80950,56336000,431761000,10118750000],
[1620,81000,56450000,432354000,10125000000],
[1621,81050,56564000,432949000,10131250000],
[1622,81100,56678000,433546000,10137500000],
[1623,81150,56792000,434145000,10143750000],
[1624,81200,56906000,434746000,10150000000],
[1625,81250,57020000,435349000,10156250000],
[1626,81300,57134000,435955000,10162500000],
[1627,81350,57250000,436562000,10168750000],
[1628,81400,57364000,437173000,10175000000],
[1629,81450,57478000,437785000,10181250000],
[1630,81500,57592000,438400000,10187500000],
[1631,81550,57706000,439018000,10193750000],
[1632,81600,57820000,439638000,10200000000],
[1633,81650,57934000,440261000,10206250000],
[1634,81700,58050000,440887000,10212500000],
[1635,81750,58164000,441515000,10218750000],
[1636,81800,58278000,442146000,10225000000],
[1637,81850,58392000,442780000,10231250000],
[1638,81900,58506000,443418000,10237500000],
[1639,81950,58620000,444058000,10243750000],
[1640,82000,58734000,444702000,10250000000],
[1641,82050,58848000,445348000,10256250000],
[1642,82100,58964000,445999000,10262500000],
[1643,82150,59078000,446652000,10268750000],
[1644,82200,59192000,447309000,10275000000],
[1645,82250,59306000,447970000,10281250000],
[1646,82300,59420000,448635000,10287500000],
[1647,82350,59534000,449304000,10293750000],
[1648,82400,59648000,449976000,10300000000],
[1649,82450,59762000,450653000,10306250000],
[1650,82500,59876000,451334000,10312500000],
[1651,82550,59990000,452019000,10318750000],
[1652,82600,60104000,452709000,10325000000],
[1653,82650,60218000,453403000,10331250000],
[1654,82700,60332000,454102000,10337500000],
[1655,82750,60446000,454807000,10343750000],
[1656,82800,60560000,455516000,10350000000],
[1657,82850,60674000,456231000,10356250000],
[1658,82900,60786000,456951000,10362500000],
[1659,82950,60900000,457677000,10368750000],
[1660,83000,61014000,458409000,10375000000],
[1661,83050,61128000,459147000,10381250000],
[1662,83100,61242000,459891000,10387500000],
[1663,83150,61354000,460642000,10393750000],
[1664,83200,61468000,461400000,10400000000],
[1665,83250,61582000,462165000,10406250000],
[1666,83300,61696000,462938000,10412500000],
[1667,83350,61808000,463718000,10418750000],
[1668,83400,61922000,464506000,10425000000],
[1669,83450,62034000,465303000,10431250000],
[1670,83500,62148000,466109000,10437500000],
[1671,83550,62260000,466924000,10443750000],
[1672,83600,62374000,467749000,10450000000],
[1673,83650,62486000,468584000,10456250000],
[1674,83700,62600000,469429000,10462500000],
[1675,83750,62712000,470286000,10468750000],
[1676,83800,62826000,471155000,10475000000],
[1677,83850,62938000,472037000,10481250000],
[1678,83900,63050000,472932000,10487500000],
[1679,83950,63164000,473841000,10493750000],
[1680,84000,63276000,474765000,10500000000],
[1681,84050,63388000,475705000,10506250000],
[1682,84100,63500000,476662000,10512500000],
[1683,84150,63612000,477638000,10518750000],
[1684,84200,63724000,478633000,10525000000],
[1685,84250,63836000,479650000,10531250000],
[1686,84300,63950000,480689000,10537500000],
[1687,84350,64062000,481754000,10543750000],
[1688,84400,64172000,482846000,10550000000],
[1689,84450,64284000,483967000,10556250000],
[1690,84500,64396000,485122000,10562500000],
[1691,84550,64508000,486314000,10568750000],
[1692,84600,64620000,487547000,10575000000],
[1693,84650,64732000,488826000,10581250000],
[1694,84700,64844000,490159000,10587500000],
[1695,84750,64954000,491553000,10593750000],
[1696,84800,65066000,493020000,10600000000],
[1697,84850,65178000,494573000,10606250000],
[1698,84900,65288000,496233000,10612500000],
[1699,84950,65400000,498027000,10618750000],
[1700,85000,65510000,500000000,10625000000]]