import { matrix } from "./Matrix";
import { matrixDp } from "./MatrixDp";

export const calculateAttributionMatrix = (attribution) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][2] >= attribution){
            return matrix[i];
        }
    }
}

export const calculateAttributionPrice = (attribution) => {
    if(attribution >= 2000 && attribution < 100000){return  0.03600 * attribution + 28;}
    if(attribution >= 100000 && attribution < 200000){return  0.01800 * attribution + 1828;}
    if(attribution >= 200000 && attribution < 540000){return  0.00800 * attribution + 3828;}
    if(attribution >= 540000 && attribution < 3500000){return  0.00500 * attribution + 5448;}
    if(attribution >= 3500000 && attribution < 19000000){return  0.00220 * attribution + 15248;}
    if(attribution >= 19000000 && attribution < 32000000){return  0.00120 * attribution + 34248;}
    if(attribution >= 32000000 && attribution < 62000000){return  0.00060 * attribution + 53448;}
    if(attribution >= 62000000 && attribution < 100000000){return  0.00050 * attribution + 59648;}
    if(attribution >= 100000000 ){return  0.00020 * attribution + 89648;}
    return 0;
}

export const calculateAttributionPriceForACV = (acv) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][1] >= acv){
            return matrix[i][2];
        }
    }
}

export const calculateDpPriceMatrix = (basePrice) => {
    if(isNaN(basePrice)){
        return 0;
    }
    return basePrice * 75000;
}

export const calculateMausMatrix = (maus) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][3] >= maus){
            return matrix[i];
        }
    }
}


export const calculateDataPoints = (dps) => {
    for(let i=0; i<matrixDp.length; i++){
        if(matrixDp[i][4] >= dps){
            return matrixDp[i];
        }
    }
}

export const calculateDataPoints100m = (currency) => {
    if (currency === 'JPY') {
        return 200000;
      }
      if (currency === 'GBP') {
        return 1200;
      }
      if (currency === 'CNY') {
        return 10000;
      }
      if (currency === 'BRL') {
        return 7700;
      }
    return 1500;
}
