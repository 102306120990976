import React from 'react';
import {GoogleLogin} from 'react-google-login';

function Login(props){
  const onSuccess = (res) => {
    const loginData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(res)
    }
    fetch('/api/login', loginData).then(response => response.json()).then(function(result) {
        props.onSsoChange(result.login);
    }
  )
  .catch(function(error) {
    window.location.reload(false);
  });
  }

  return (
    <div>
      <GoogleLogin
        clientId={props.clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        cookiePolicy={'single_host_origin'}
        style={{marginTop: '100px'}}
      />
    </div>
  );
}

export default Login;
