import { Button,Row,Card,Col } from "react-bootstrap";
import {getPrefix} from "../GeneralValues/Currency";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export const AccountCurrencyButtons = (_props) => {
    
    const setCurrency = (value) => {
        _props.onCurrencyChange(value);
    }

    return (
        <Card style={{height:'40px'}}>
            <Row style={{width:'100%'}}>
                <Col xs={10}>
                <ButtonGroup aria-label="Currency Buttons">
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('EURO')} type="submit">{getPrefix('EURO')}</Button>
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('JPY')} type="submit">{getPrefix('JPY')}</Button>
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('USD')} type="submit">{getPrefix('USD')}</Button>
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('GBP')} type="submit">{getPrefix('GBP')}</Button>
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('CNY')} type="submit">{getPrefix('CNY')}</Button>
                    <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info" onClick={() => setCurrency('BRL')} type="submit">{getPrefix('BRL')}</Button>
                </ButtonGroup>
                </Col>
            </Row>
        </Card>
    );
}