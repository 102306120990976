export const saveInputs = (data,acvQuoteValue,basePrice,featurePrice,packagePrice,totalMonthlyCost,discountCurrency,discountPercent,type) => {
    const inputData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: data.accountId,
        type: type,
        contractLength: data.contractLength,
        paymentTerm: data.paymentTerm,
        attribution: data.attribution,
        feature1: data.feature1,
        feature2: data.feature2,
        feature3: data.feature3,
        feature4: data.feature4,
        feature5: data.feature5,
        feature6: data.feature6,
        feature7: data.feature7,
        feature8: data.feature8,
        feature9: data.feature9,
        feature10: data.feature10,
        feature11: data.feature11,
        feature12: data.feature12,
        feature13: data.feature13,
        feature14: data.feature14,
        implementation: data.implementation,
        dataManagementSelected: data.dataManagementSelected,
        assistDashboard: data.assistDashboard,
        pulse: data.pulse,
        pulseCount: data.pulseCount,
        spendWorks: data.spendWorks,
        incrementality: data.incrementality,
        incrementalityCount: data.incrementalityCount,
        trueLink: data.trueLink,

        acvQuoteValue : acvQuoteValue,
        basePrices : basePrice,
        featurePrices : featurePrice,
        packagePrices : packagePrice,
        totalMonthlyCosts : totalMonthlyCost,
        discountCurrencys : discountCurrency,
        discountPercents : discountPercent
      })
    }

    if(data.contractLength > 0 && data.attribution !== 0 && data.paymentTerm !== 0){
      fetch('/api/save_account_data', inputData).then(response => response.json()).then(function(result) {
      });
    }
}
