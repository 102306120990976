import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { StatisticsModal } from './statisticsModal';

const formatDate = (dateString) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

function createData(
    id,
    accountId,
    visitors,
    oppId,
    addedDate,
    companyName,
    clientName,
    lastVisit,
    pageVisit,
  ) {
    return { id,accountId,visitors,oppId,addedDate,companyName,clientName, lastVisit, pageVisit};
  }


export const StatisticsTable = (props) =>{

    const columns = [
        { field: 'pageVisit', headerName: 'Visits',flex: 1, maxWidth: 150,
          renderCell: (params) => {
            return <StatisticsModal lastVisit={params.row.lastVisit} pageVisit={params.row.pageVisit} />
          },
        },
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 100, maxWidth: 100},
        { field: 'companyName', headerName: 'Company Name',flex: 1, minWidth: 150, maxWidth: 150},
        { field: 'clientName', headerName: 'Client Name',flex: 1, minWidth: 150},
        { field: 'addedDate', headerName: 'Added Date',flex: 1, minWidth: 100},
        { field: 'visitors', headerName: 'Visitors',flex: 1, minWidth: 150, maxWidth: 150},
        { field: 'oppId', headerName: 'Opp Id',flex: 1, minWidth: 150},
        
        
    ];

    const rows = [];
    for (var i = 0; i < props.data.length; i++){
        rows.push(
            createData(
                i,
                props.data[i]["opp"][0],
                props.data[i]["visitors"],
                props.data[i]["opp"][1],
                formatDate(props.data[i]["opp"][2]),
                props.data[i]["opp"][4],
                props.data[i]["opp"][5],
                props.data[i]["lastVisit"],
                props.data[i]["pageVisits"],
            )
        );
    }
    return (
      <>
        {(props.data.length === 0) &&
          <CircularProgress />
        }
        {(props.data.length > 0) &&
        <div style={{ height: 600, width: '90%',marginLeft:'5%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                
            />
        </div>
        }
      </>
    );
}