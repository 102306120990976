export const matrixV2 = [[0,0,0,0,0],
[1,50,0,0,3400000],
[2,100,0,0,6800000],
[3,150,2000,0,10200000],
[4,200,4000,0,13600000],
[5,250,6000,0,17000000],
[6,300,6000,5000,20400000],
[7,350,8000,10000,23800000],
[8,400,10000,10000,27200000],
[9,450,12000,15000,30600000],
[10,500,14000,20000,34000000],
[11,550,14000,25000,37400000],
[12,600,16000,30000,40800000],
[13,650,18000,30000,44200000],
[14,700,20000,35000,47600000],
[15,750,20000,40000,51000000],
[16,800,22000,45000,54400000],
[17,850,24000,50000,57800000],
[18,900,26000,50000,61200000],
[19,950,26000,55000,64600000],
[20,1000,28000,60000,68000000],
[21,1050,30000,65000,71400000],
[22,1100,32000,70000,74800000],
[23,1150,32000,75000,78200000],
[24,1200,34000,80000,81600000],
[25,1250,36000,80000,85000000],
[26,1300,38000,85000,88400000],
[27,1350,40000,90000,91800000],
[28,1400,42000,95000,95200000],
[29,1450,42000,100000,98600000],
[30,1500,44000,105000,102000000],
[31,1550,46000,110000,105400000],
[32,1600,48000,115000,108800000],
[33,1650,50000,120000,112200000],
[34,1700,52000,125000,115600000],
[35,1750,54000,130000,119000000],
[36,1800,56000,135000,122400000],
[37,1850,56000,140000,125800000],
[38,1900,58000,145000,129200000],
[39,1950,60000,150000,132600000],
[40,2000,62000,155000,136000000],
[41,2050,64000,160000,139400000],
[42,2100,66000,165000,142800000],
[43,2150,68000,170000,146200000],
[44,2200,70000,175000,149600000],
[45,2250,72000,180000,153000000],
[46,2300,74000,185000,156400000],
[47,2350,76000,190000,159800000],
[48,2400,78000,195000,163200000],
[49,2450,80000,200000,166600000],
[50,2500,82000,205000,170000000],
[51,2550,84000,210000,173400000],
[52,2600,86000,220000,176800000],
[53,2650,88000,225000,180200000],
[54,2700,90000,230000,183600000],
[55,2750,92000,235000,187000000],
[56,2800,94000,240000,190400000],
[57,2850,96000,250000,193800000],
[58,2900,98000,255000,197200000],
[59,2950,100000,260000,200600000],
[60,3000,100000,265000,204000000],
[61,3050,105000,275000,207400000],
[62,3100,105000,280000,210800000],
[63,3150,110000,285000,214200000],
[64,3200,110000,295000,217600000],
[65,3250,115000,300000,221000000],
[66,3300,115000,305000,224400000],
[67,3350,115000,315000,227800000],
[68,3400,120000,320000,231200000],
[69,3450,120000,325000,234600000],
[70,3500,125000,335000,238000000],
[71,3550,125000,340000,241400000],
[72,3600,130000,350000,244800000],
[73,3650,130000,355000,248200000],
[74,3700,135000,365000,251600000],
[75,3750,135000,370000,255000000],
[76,3800,140000,380000,258400000],
[77,3850,140000,390000,261800000],
[78,3900,145000,395000,265200000],
[79,3950,150000,405000,268600000],
[80,4000,150000,410000,272000000],
[81,4050,155000,420000,275400000],
[82,4100,155000,430000,278800000],
[83,4150,160000,440000,282200000],
[84,4200,160000,445000,285600000],
[85,4250,165000,455000,289000000],
[86,4300,170000,465000,292400000],
[87,4350,170000,475000,295800000],
[88,4400,175000,485000,299200000],
[89,4450,175000,495000,302600000],
[90,4500,180000,500000,306000000],
[91,4550,185000,510000,309400000],
[92,4600,185000,520000,312800000],
[93,4650,190000,530000,316200000],
[94,4700,195000,540000,319600000],
[95,4750,195000,550000,323000000],
[96,4800,200000,560000,326400000],
[97,4850,205000,570000,329800000],
[98,4900,205000,580000,333200000],
[99,4950,210000,590000,336600000],
[100,5000,215000,610000,340000000],
[101,5050,220000,620000,343400000],
[102,5100,220000,630000,346800000],
[103,5150,225000,640000,350200000],
[104,5200,230000,650000,353600000],
[105,5250,235000,660000,357000000],
[106,5300,240000,670000,360400000],
[107,5350,240000,690000,363800000],
[108,5400,245000,700000,367200000],
[109,5450,250000,710000,370600000],
[110,5500,255000,720000,374000000],
[111,5550,260000,730000,377400000],
[112,5600,265000,750000,380800000],
[113,5650,270000,760000,384200000],
[114,5700,275000,770000,387600000],
[115,5750,280000,780000,391000000],
[116,5800,285000,800000,394400000],
[117,5850,290000,810000,397800000],
[118,5900,295000,820000,401200000],
[119,5950,300000,830000,404600000],
[120,6000,305000,840000,408000000],
[121,6050,315000,860000,411400000],
[122,6100,320000,870000,414800000],
[123,6150,325000,880000,418200000],
[124,6200,330000,890000,421600000],
[125,6250,340000,900000,425000000],
[126,6300,345000,910000,428400000],
[127,6350,350000,920000,431800000],
[128,6400,360000,940000,435200000],
[129,6450,365000,950000,438600000],
[130,6500,375000,960000,442000000],
[131,6550,380000,970000,445400000],
[132,6600,390000,980000,448800000],
[133,6650,400000,990000,452200000],
[134,6700,405000,1000000,455600000],
[135,6750,415000,1010000,459000000],
[136,6800,425000,1020000,462400000],
[137,6850,435000,1030000,465800000],
[138,6900,445000,1040000,469200000],
[139,6950,455000,1050000,472600000],
[140,7000,465000,1060000,476000000],
[141,7050,475000,1070000,479400000],
[142,7100,485000,1080000,482800000],
[143,7150,500000,1090000,486200000],
[144,7200,510000,1100000,489600000],
[145,7250,520000,1100000,493000000],
[146,7300,530000,1110000,496400000],
[147,7350,540000,1120000,499800000],
[148,7400,550000,1130000,503200000],
[149,7450,570000,1140000,506600000],
[150,7500,580000,1150000,510000000],
[151,7550,590000,1150000,513400000],
[152,7600,600000,1160000,516800000],
[153,7650,610000,1170000,520200000],
[154,7700,620000,1180000,523600000],
[155,7750,640000,1190000,527000000],
[156,7800,650000,1190000,530400000],
[157,7850,660000,1200000,533800000],
[158,7900,670000,1210000,537200000],
[159,7950,680000,1220000,540600000],
[160,8000,690000,1220000,544000000],
[161,8050,700000,1230000,547400000],
[162,8100,710000,1240000,550800000],
[163,8150,720000,1240000,554200000],
[164,8200,730000,1250000,557600000],
[165,8250,750000,1260000,561000000],
[166,8300,760000,1270000,564400000],
[167,8350,770000,1270000,567800000],
[168,8400,780000,1280000,571200000],
[169,8450,790000,1280000,574600000],
[170,8500,800000,1290000,578000000],
[171,8550,810000,1300000,581400000],
[172,8600,820000,1300000,584800000],
[173,8650,830000,1310000,588200000],
[174,8700,840000,1320000,591600000],
[175,8750,850000,1320000,595000000],
[176,8800,860000,1330000,598400000],
[177,8850,870000,1330000,601800000],
[178,8900,880000,1380000,605200000],
[179,8950,890000,1460000,608600000],
[180,9000,900000,1500000,612000000],
[181,9050,910000,1600000,615400000],
[182,9100,920000,1700000,618800000],
[183,9150,930000,1800000,622200000],
[184,9200,940000,1800000,625600000],
[185,9250,960000,1900000,629000000],
[186,9300,970000,2000000,632400000],
[187,9350,980000,2100000,635800000],
[188,9400,990000,2200000,639200000],
[189,9450,1000000,2300000,642600000],
[190,9500,1000000,2400000,646000000],
[191,9550,1000000,2400000,649400000],
[192,9600,1000000,2500000,652800000],
[193,9650,1050000,2600000,656200000],
[194,9700,1050000,2700000,659600000],
[195,9750,1050000,2800000,663000000],
[196,9800,1050000,2900000,666400000],
[197,9850,1100000,3000000,669800000],
[198,9900,1100000,3100000,673200000],
[199,9950,1100000,3100000,676600000],
[200,10000,1100000,3200000,680000000],
[201,10050,1150000,3300000,683400000],
[202,10100,1150000,3400000,686800000],
[203,10150,1150000,3500000,690200000],
[204,10200,1150000,3600000,693600000],
[205,10250,1200000,3700000,697000000],
[206,10300,1200000,3700000,700400000],
[207,10350,1200000,3800000,703800000],
[208,10400,1250000,3900000,707200000],
[209,10450,1250000,4000000,710600000],
[210,10500,1250000,4100000,714000000],
[211,10550,1250000,4200000,717400000],
[212,10600,1300000,4300000,720800000],
[213,10650,1300000,4400000,724200000],
[214,10700,1300000,4400000,727600000],
[215,10750,1300000,4500000,731000000],
[216,10800,1350000,4600000,734400000],
[217,10850,1350000,4700000,737800000],
[218,10900,1350000,4800000,741200000],
[219,10950,1350000,4900000,744600000],
[220,11000,1400000,5000000,748000000],
[221,11050,1400000,5100000,751400000],
[222,11100,1400000,5100000,754800000],
[223,11150,1400000,5200000,758200000],
[224,11200,1450000,5300000,761600000],
[225,11250,1450000,5400000,765000000],
[226,11300,1450000,5500000,768400000],
[227,11350,1500000,5600000,771800000],
[228,11400,1500000,5700000,775200000],
[229,11450,1500000,5800000,778600000],
[230,11500,1500000,5900000,782000000],
[231,11550,1550000,5900000,785400000],
[232,11600,1550000,6000000,788800000],
[233,11650,1550000,6100000,792200000],
[234,11700,1550000,6200000,795600000],
[235,11750,1600000,6300000,799000000],
[236,11800,1600000,6400000,802400000],
[237,11850,1600000,6500000,805800000],
[238,11900,1600000,6600000,809200000],
[239,11950,1650000,6600000,812600000],
[240,12000,1650000,6700000,816000000],
[241,12050,1650000,6800000,819400000],
[242,12100,1700000,6900000,822800000],
[243,12150,1700000,7000000,826200000],
[244,12200,1700000,7100000,829600000],
[245,12250,1700000,7200000,833000000],
[246,12300,1750000,7300000,836400000],
[247,12350,1750000,7400000,839800000],
[248,12400,1750000,7500000,843200000],
[249,12450,1750000,7500000,846600000],
[250,12500,1800000,7600000,850000000],
[251,12550,1800000,7700000,853400000],
[252,12600,1800000,7800000,856800000],
[253,12650,1850000,7900000,860200000],
[254,12700,1850000,8000000,863600000],
[255,12750,1850000,8100000,867000000],
[256,12800,1850000,8200000,870400000],
[257,12850,1900000,8300000,873800000],
[258,12900,1900000,8400000,877200000],
[259,12950,1900000,8400000,880600000],
[260,13000,1900000,8500000,884000000],
[261,13050,1950000,8600000,887400000],
[262,13100,1950000,8700000,890800000],
[263,13150,1950000,8800000,894200000],
[264,13200,1950000,8900000,897600000],
[265,13250,2000000,9000000,901000000],
[266,13300,2000000,9100000,904400000],
[267,13350,2000000,9200000,907800000],
[268,13400,2050000,9300000,911200000],
[269,13450,2050000,9400000,914600000],
[270,13500,2050000,9400000,918000000],
[271,13550,2050000,9500000,921400000],
[272,13600,2100000,9600000,924800000],
[273,13650,2100000,9700000,928200000],
[274,13700,2100000,9800000,931600000],
[275,13750,2100000,9900000,935000000],
[276,13800,2150000,10000000,938400000],
[277,13850,2150000,10100000,941800000],
[278,13900,2150000,10200000,945200000],
[279,13950,2200000,10300000,948600000],
[280,14000,2200000,10400000,952000000],
[281,14050,2200000,10500000,955400000],
[282,14100,2200000,10500000,958800000],
[283,14150,2250000,10600000,962200000],
[284,14200,2250000,10700000,965600000],
[285,14250,2250000,10800000,969000000],
[286,14300,2300000,10900000,972400000],
[287,14350,2300000,11000000,975800000],
[288,14400,2300000,11100000,979200000],
[289,14450,2300000,11200000,982600000],
[290,14500,2350000,11300000,986000000],
[291,14550,2350000,11400000,989400000],
[292,14600,2350000,11500000,992800000],
[293,14650,2350000,11600000,996200000],
[294,14700,2400000,11700000,999600000],
[295,14750,2400000,11700000,1003000000],
[296,14800,2400000,11800000,1006400000],
[297,14850,2450000,11900000,1009800000],
[298,14900,2450000,12000000,1013200000],
[299,14950,2450000,12100000,1016600000],
[300,15000,2450000,12200000,1020000000],
[301,15050,2500000,12300000,1023400000],
[302,15100,2500000,12400000,1026800000],
[303,15150,2500000,12500000,1030200000],
[304,15200,2500000,12600000,1033600000],
[305,15250,2550000,12700000,1037000000],
[306,15300,2550000,12800000,1040400000],
[307,15350,2550000,12900000,1043800000],
[308,15400,2600000,13000000,1047200000],
[309,15450,2600000,13100000,1050600000],
[310,15500,2600000,13100000,1054000000],
[311,15550,2600000,13200000,1057400000],
[312,15600,2650000,13300000,1060800000],
[313,15650,2650000,13400000,1064200000],
[314,15700,2650000,13500000,1067600000],
[315,15750,2700000,13600000,1071000000],
[316,15800,2700000,13700000,1074400000],
[317,15850,2700000,13800000,1077800000],
[318,15900,2700000,13900000,1081200000],
[319,15950,2750000,14000000,1084600000],
[320,16000,2750000,14100000,1088000000],
[321,16050,2750000,14200000,1091400000],
[322,16100,2800000,14300000,1094800000],
[323,16150,2800000,14400000,1098200000],
[324,16200,2800000,14500000,1101600000],
[325,16250,2800000,14600000,1105000000],
[326,16300,2850000,14700000,1108400000],
[327,16350,2850000,14800000,1111800000],
[328,16400,2850000,14900000,1115200000],
[329,16450,2900000,14900000,1118600000],
[330,16500,2900000,15000000,1122000000],
[331,16550,2900000,15100000,1125400000],
[332,16600,2900000,15200000,1128800000],
[333,16650,2950000,15300000,1132200000],
[334,16700,2950000,15400000,1135600000],
[335,16750,2950000,15500000,1139000000],
[336,16800,2950000,15600000,1142400000],
[337,16850,3000000,15700000,1145800000],
[338,16900,3000000,15800000,1149200000],
[339,16950,3000000,15900000,1152600000],
[340,17000,3050000,16000000,1156000000],
[341,17050,3050000,16100000,1159400000],
[342,17100,3050000,16200000,1162800000],
[343,17150,3050000,16300000,1166200000],
[344,17200,3100000,16400000,1169600000],
[345,17250,3100000,16500000,1173000000],
[346,17300,3100000,16600000,1176400000],
[347,17350,3150000,16700000,1179800000],
[348,17400,3150000,16800000,1183200000],
[349,17450,3150000,16900000,1186600000],
[350,17500,3150000,17000000,1190000000],
[351,17550,3200000,17100000,1193400000],
[352,17600,3200000,17200000,1196800000],
[353,17650,3200000,17300000,1200200000],
[354,17700,3250000,17400000,1203600000],
[355,17750,3250000,17500000,1207000000],
[356,17800,3250000,17600000,1210400000],
[357,17850,3250000,17700000,1213800000],
[358,17900,3300000,17700000,1217200000],
[359,17950,3300000,17800000,1220600000],
[360,18000,3300000,17900000,1224000000],
[361,18050,3350000,18000000,1227400000],
[362,18100,3350000,18100000,1230800000],
[363,18150,3350000,18200000,1234200000],
[364,18200,3350000,18300000,1237600000],
[365,18250,3400000,18400000,1241000000],
[366,18300,3400000,18500000,1244400000],
[367,18350,3400000,18600000,1247800000],
[368,18400,3450000,18700000,1251200000],
[369,18450,3450000,18800000,1254600000],
[370,18500,3450000,18900000,1258000000],
[371,18550,3500000,19000000,1261400000],
[372,18600,3500000,19100000,1264800000],
[373,18650,3500000,19200000,1268200000],
[374,18700,3500000,19300000,1271600000],
[375,18750,3550000,19400000,1275000000],
[376,18800,3550000,19500000,1278400000],
[377,18850,3550000,19600000,1281800000],
[378,18900,3600000,19700000,1285200000],
[379,18950,3600000,19800000,1288600000],
[380,19000,3600000,19900000,1292000000],
[381,19050,3600000,20000000,1295400000],
[382,19100,3650000,20100000,1298800000],
[383,19150,3650000,20200000,1302200000],
[384,19200,3650000,20300000,1305600000],
[385,19250,3700000,20400000,1309000000],
[386,19300,3700000,20500000,1312400000],
[387,19350,3700000,20600000,1315800000],
[388,19400,3700000,20700000,1319200000],
[389,19450,3750000,20800000,1322600000],
[390,19500,3750000,20900000,1326000000],
[391,19550,3750000,21000000,1329400000],
[392,19600,3800000,21100000,1332800000],
[393,19650,3800000,21200000,1336200000],
[394,19700,3800000,21300000,1339600000],
[395,19750,3850000,21400000,1343000000],
[396,19800,3850000,21500000,1346400000],
[397,19850,3850000,21600000,1349800000],
[398,19900,3850000,21700000,1353200000],
[399,19950,3900000,21800000,1356600000],
[400,20000,3900000,21900000,1360000000],
[401,20050,3900000,22000000,1363400000],
[402,20100,3950000,22100000,1366800000],
[403,20150,3950000,22200000,1370200000],
[404,20200,3950000,22300000,1373600000],
[405,20250,3950000,22400000,1377000000],
[406,20300,4000000,22500000,1380400000],
[407,20350,4000000,22600000,1383800000],
[408,20400,4000000,22700000,1387200000],
[409,20450,4050000,22800000,1390600000],
[410,20500,4050000,22900000,1394000000],
[411,20550,4050000,23000000,1397400000],
[412,20600,4100000,23100000,1400800000],
[413,20650,4100000,23200000,1404200000],
[414,20700,4100000,23400000,1407600000],
[415,20750,4100000,23500000,1411000000],
[416,20800,4150000,23600000,1414400000],
[417,20850,4150000,23700000,1417800000],
[418,20900,4150000,23800000,1421200000],
[419,20950,4200000,23900000,1424600000],
[420,21000,4200000,24000000,1428000000],
[421,21050,4200000,24100000,1431400000],
[422,21100,4250000,24200000,1434800000],
[423,21150,4250000,24300000,1438200000],
[424,21200,4250000,24400000,1441600000],
[425,21250,4250000,24500000,1445000000],
[426,21300,4300000,24600000,1448400000],
[427,21350,4300000,24700000,1451800000],
[428,21400,4300000,24800000,1455200000],
[429,21450,4350000,24900000,1458600000],
[430,21500,4350000,25000000,1462000000],
[431,21550,4350000,25100000,1465400000],
[432,21600,4350000,25200000,1468800000],
[433,21650,4400000,25300000,1472200000],
[434,21700,4400000,25400000,1475600000],
[435,21750,4400000,25500000,1479000000],
[436,21800,4450000,25600000,1482400000],
[437,21850,4450000,25700000,1485800000],
[438,21900,4450000,25800000,1489200000],
[439,21950,4500000,25900000,1492600000],
[440,22000,4500000,26000000,1496000000],
[441,22050,4500000,26200000,1499400000],
[442,22100,4550000,26300000,1502800000],
[443,22150,4550000,26400000,1506200000],
[444,22200,4550000,26500000,1509600000],
[445,22250,4550000,26600000,1513000000],
[446,22300,4600000,26700000,1516400000],
[447,22350,4600000,26800000,1519800000],
[448,22400,4600000,26900000,1523200000],
[449,22450,4650000,27000000,1526600000],
[450,22500,4650000,27100000,1530000000],
[451,22550,4650000,27200000,1533400000],
[452,22600,4700000,27300000,1536800000],
[453,22650,4700000,27400000,1540200000],
[454,22700,4700000,27500000,1543600000],
[455,22750,4700000,27600000,1547000000],
[456,22800,4750000,27700000,1550400000],
[457,22850,4750000,27800000,1553800000],
[458,22900,4750000,27900000,1557200000],
[459,22950,4800000,28100000,1560600000],
[460,23000,4800000,28200000,1564000000],
[461,23050,4800000,28300000,1567400000],
[462,23100,4850000,28400000,1570800000],
[463,23150,4850000,28500000,1574200000],
[464,23200,4850000,28600000,1577600000],
[465,23250,4900000,28700000,1581000000],
[466,23300,4900000,28800000,1584400000],
[467,23350,4900000,28900000,1587800000],
[468,23400,4900000,29000000,1591200000],
[469,23450,4950000,29100000,1594600000],
[470,23500,4950000,29200000,1598000000],
[471,23550,4950000,29300000,1601400000],
[472,23600,5000000,29500000,1604800000],
[473,23650,5000000,29600000,1608200000],
[474,23700,5000000,29700000,1611600000],
[475,23750,5050000,29800000,1615000000],
[476,23800,5050000,29900000,1618400000],
[477,23850,5050000,30000000,1621800000],
[478,23900,5100000,30100000,1625200000],
[479,23950,5100000,30200000,1628600000],
[480,24000,5100000,30300000,1632000000],
[481,24050,5100000,30400000,1635400000],
[482,24100,5150000,30500000,1638800000],
[483,24150,5150000,30600000,1642200000],
[484,24200,5150000,30800000,1645600000],
[485,24250,5200000,30900000,1649000000],
[486,24300,5200000,31000000,1652400000],
[487,24350,5200000,31100000,1655800000],
[488,24400,5250000,31200000,1659200000],
[489,24450,5250000,31300000,1662600000],
[490,24500,5250000,31400000,1666000000],
[491,24550,5300000,31500000,1669400000],
[492,24600,5300000,31600000,1672800000],
[493,24650,5300000,31700000,1676200000],
[494,24700,5350000,31800000,1679600000],
[495,24750,5350000,32000000,1683000000],
[496,24800,5350000,32100000,1686400000],
[497,24850,5350000,32200000,1689800000],
[498,24900,5400000,32300000,1693200000],
[499,24950,5400000,32400000,1696600000],
[500,25000,5400000,32500000,1700000000],
[501,25050,5450000,32600000,1703400000],
[502,25100,5450000,32700000,1706800000],
[503,25150,5450000,32800000,1710200000],
[504,25200,5500000,33000000,1713600000],
[505,25250,5500000,33100000,1717000000],
[506,25300,5500000,33200000,1720400000],
[507,25350,5550000,33300000,1723800000],
[508,25400,5550000,33400000,1727200000],
[509,25450,5550000,33500000,1730600000],
[510,25500,5600000,33600000,1734000000],
[511,25550,5600000,33700000,1737400000],
[512,25600,5600000,33800000,1740800000],
[513,25650,5650000,34000000,1744200000],
[514,25700,5650000,34100000,1747600000],
[515,25750,5650000,34200000,1751000000],
[516,25800,5650000,34300000,1754400000],
[517,25850,5700000,34400000,1757800000],
[518,25900,5700000,34500000,1761200000],
[519,25950,5700000,34600000,1764600000],
[520,26000,5750000,34700000,1768000000],
[521,26050,5750000,34900000,1771400000],
[522,26100,5750000,35000000,1774800000],
[523,26150,5800000,35100000,1778200000],
[524,26200,5800000,35200000,1781600000],
[525,26250,5800000,35300000,1785000000],
[526,26300,5850000,35400000,1788400000],
[527,26350,5850000,35500000,1791800000],
[528,26400,5850000,35700000,1795200000],
[529,26450,5900000,35800000,1798600000],
[530,26500,5900000,35900000,1802000000],
[531,26550,5900000,36000000,1805400000],
[532,26600,5950000,36100000,1808800000],
[533,26650,5950000,36200000,1812200000],
[534,26700,5950000,36300000,1815600000],
[535,26750,6000000,36500000,1819000000],
[536,26800,6000000,36600000,1822400000],
[537,26850,6000000,36700000,1825800000],
[538,26900,6050000,36800000,1829200000],
[539,26950,6050000,36900000,1832600000],
[540,27000,6050000,37000000,1836000000],
[541,27050,6050000,37100000,1839400000],
[542,27100,6100000,37300000,1842800000],
[543,27150,6100000,37400000,1846200000],
[544,27200,6100000,37500000,1849600000],
[545,27250,6150000,37600000,1853000000],
[546,27300,6150000,37700000,1856400000],
[547,27350,6150000,37800000,1859800000],
[548,27400,6200000,38000000,1863200000],
[549,27450,6200000,38100000,1866600000],
[550,27500,6200000,38200000,1870000000],
[551,27550,6250000,38300000,1873400000],
[552,27600,6250000,38400000,1876800000],
[553,27650,6250000,38500000,1880200000],
[554,27700,6300000,38700000,1883600000],
[555,27750,6300000,38800000,1887000000],
[556,27800,6300000,38900000,1890400000],
[557,27850,6350000,39000000,1893800000],
[558,27900,6350000,39100000,1897200000],
[559,27950,6350000,39200000,1900600000],
[560,28000,6400000,39400000,1904000000],
[561,28050,6400000,39500000,1907400000],
[562,28100,6400000,39600000,1910800000],
[563,28150,6450000,39700000,1914200000],
[564,28200,6450000,39800000,1917600000],
[565,28250,6450000,39900000,1921000000],
[566,28300,6500000,40100000,1924400000],
[567,28350,6500000,40200000,1927800000],
[568,28400,6500000,40300000,1931200000],
[569,28450,6550000,40400000,1934600000],
[570,28500,6550000,40500000,1938000000],
[571,28550,6550000,40700000,1941400000],
[572,28600,6600000,40800000,1944800000],
[573,28650,6600000,40900000,1948200000],
[574,28700,6600000,41000000,1951600000],
[575,28750,6650000,41100000,1955000000],
[576,28800,6650000,41300000,1958400000],
[577,28850,6650000,41400000,1961800000],
[578,28900,6700000,41500000,1965200000],
[579,28950,6700000,41600000,1968600000],
[580,29000,6700000,41700000,1972000000],
[581,29050,6750000,41900000,1975400000],
[582,29100,6750000,42000000,1978800000],
[583,29150,6750000,42100000,1982200000],
[584,29200,6800000,42200000,1985600000],
[585,29250,6800000,42300000,1989000000],
[586,29300,6800000,42500000,1992400000],
[587,29350,6850000,42600000,1995800000],
[588,29400,6850000,42700000,1999200000],
[589,29450,6850000,42800000,2002600000],
[590,29500,6900000,42900000,2006000000],
[591,29550,6900000,43100000,2009400000],
[592,29600,6900000,43200000,2012800000],
[593,29650,6950000,43300000,2016200000],
[594,29700,6950000,43400000,2019600000],
[595,29750,6950000,43500000,2023000000],
[596,29800,7000000,43700000,2026400000],
[597,29850,7000000,43800000,2029800000],
[598,29900,7000000,43900000,2033200000],
[599,29950,7050000,44000000,2036600000],
[600,30000,7050000,44200000,2040000000],
[601,30050,7050000,44300000,2043400000],
[602,30100,7100000,44400000,2046800000],
[603,30150,7100000,44500000,2050200000],
[604,30200,7100000,44700000,2053600000],
[605,30250,7150000,44800000,2057000000],
[606,30300,7150000,44900000,2060400000],
[607,30350,7150000,45000000,2063800000],
[608,30400,7200000,45100000,2067200000],
[609,30450,7200000,45300000,2070600000],
[610,30500,7200000,45400000,2074000000],
[611,30550,7250000,45500000,2077400000],
[612,30600,7250000,45600000,2080800000],
[613,30650,7250000,45800000,2084200000],
[614,30700,7300000,45900000,2087600000],
[615,30750,7300000,46000000,2091000000],
[616,30800,7300000,46100000,2094400000],
[617,30850,7350000,46300000,2097800000],
[618,30900,7350000,46400000,2101200000],
[619,30950,7350000,46500000,2104600000],
[620,31000,7400000,46600000,2108000000],
[621,31050,7400000,46800000,2111400000],
[622,31100,7400000,46900000,2114800000],
[623,31150,7450000,47000000,2118200000],
[624,31200,7450000,47100000,2121600000],
[625,31250,7450000,47300000,2125000000],
[626,31300,7500000,47400000,2128400000],
[627,31350,7500000,47500000,2131800000],
[628,31400,7500000,47600000,2135200000],
[629,31450,7550000,47800000,2138600000],
[630,31500,7550000,47900000,2142000000],
[631,31550,7600000,48000000,2145400000],
[632,31600,7600000,48200000,2148800000],
[633,31650,7600000,48300000,2152200000],
[634,31700,7650000,48400000,2155600000],
[635,31750,7650000,48500000,2159000000],
[636,31800,7650000,48700000,2162400000],
[637,31850,7700000,48800000,2165800000],
[638,31900,7700000,48900000,2169200000],
[639,31950,7700000,49000000,2172600000],
[640,32000,7750000,49200000,2176000000],
[641,32050,7750000,49300000,2179400000],
[642,32100,7750000,49400000,2182800000],
[643,32150,7800000,49600000,2186200000],
[644,32200,7800000,49700000,2189600000],
[645,32250,7800000,49800000,2193000000],
[646,32300,7850000,49900000,2196400000],
[647,32350,7850000,50100000,2199800000],
[648,32400,7850000,50200000,2203200000],
[649,32450,7900000,50300000,2206600000],
[650,32500,7900000,50500000,2210000000],
[651,32550,7900000,50600000,2213400000],
[652,32600,7950000,50700000,2216800000],
[653,32650,7950000,50800000,2220200000],
[654,32700,8000000,51000000,2223600000],
[655,32750,8000000,51100000,2227000000],
[656,32800,8000000,51200000,2230400000],
[657,32850,8050000,51400000,2233800000],
[658,32900,8050000,51500000,2237200000],
[659,32950,8050000,51600000,2240600000],
[660,33000,8100000,51800000,2244000000],
[661,33050,8100000,51900000,2247400000],
[662,33100,8100000,52000000,2250800000],
[663,33150,8150000,52200000,2254200000],
[664,33200,8150000,52300000,2257600000],
[665,33250,8150000,52400000,2261000000],
[666,33300,8200000,52500000,2264400000],
[667,33350,8200000,52700000,2267800000],
[668,33400,8200000,52800000,2271200000],
[669,33450,8250000,52900000,2274600000],
[670,33500,8250000,53100000,2278000000],
[671,33550,8300000,53200000,2281400000],
[672,33600,8300000,53300000,2284800000],
[673,33650,8300000,53500000,2288200000],
[674,33700,8350000,53600000,2291600000],
[675,33750,8350000,53700000,2295000000],
[676,33800,8350000,53900000,2298400000],
[677,33850,8400000,54000000,2301800000],
[678,33900,8400000,54100000,2305200000],
[679,33950,8400000,54300000,2308600000],
[680,34000,8450000,54400000,2312000000],
[681,34050,8450000,54500000,2315400000],
[682,34100,8450000,54700000,2318800000],
[683,34150,8500000,54800000,2322200000],
[684,34200,8500000,54900000,2325600000],
[685,34250,8550000,55100000,2329000000],
[686,34300,8550000,55200000,2332400000],
[687,34350,8550000,55400000,2335800000],
[688,34400,8600000,55500000,2339200000],
[689,34450,8600000,55600000,2342600000],
[690,34500,8600000,55800000,2346000000],
[691,34550,8650000,55900000,2349400000],
[692,34600,8650000,56000000,2352800000],
[693,34650,8650000,56200000,2356200000],
[694,34700,8700000,56300000,2359600000],
[695,34750,8700000,56400000,2363000000],
[696,34800,8750000,56600000,2366400000],
[697,34850,8750000,56700000,2369800000],
[698,34900,8750000,56900000,2373200000],
[699,34950,8800000,57000000,2376600000],
[700,35000,8800000,57100000,2380000000],
[701,35050,8800000,57300000,2383400000],
[702,35100,8850000,57400000,2386800000],
[703,35150,8850000,57500000,2390200000],
[704,35200,8850000,57700000,2393600000],
[705,35250,8900000,57800000,2397000000],
[706,35300,8900000,58000000,2400400000],
[707,35350,8950000,58100000,2403800000],
[708,35400,8950000,58200000,2407200000],
[709,35450,8950000,58400000,2410600000],
[710,35500,9000000,58500000,2414000000],
[711,35550,9000000,58600000,2417400000],
[712,35600,9000000,58800000,2420800000],
[713,35650,9050000,58900000,2424200000],
[714,35700,9050000,59100000,2427600000],
[715,35750,9050000,59200000,2431000000],
[716,35800,9100000,59300000,2434400000],
[717,35850,9100000,59500000,2437800000],
[718,35900,9150000,59600000,2441200000],
[719,35950,9150000,59800000,2444600000],
[720,36000,9150000,59900000,2448000000],
[721,36050,9200000,60000000,2451400000],
[722,36100,9200000,60200000,2454800000],
[723,36150,9200000,60300000,2458200000],
[724,36200,9250000,60500000,2461600000],
[725,36250,9250000,60600000,2465000000],
[726,36300,9300000,60800000,2468400000],
[727,36350,9300000,60900000,2471800000],
[728,36400,9300000,61000000,2475200000],
[729,36450,9350000,61200000,2478600000],
[730,36500,9350000,61300000,2482000000],
[731,36550,9350000,61500000,2485400000],
[732,36600,9400000,61600000,2488800000],
[733,36650,9400000,61800000,2492200000],
[734,36700,9450000,61900000,2495600000],
[735,36750,9450000,62000000,2499000000],
[736,36800,9450000,62200000,2502400000],
[737,36850,9500000,62300000,2505800000],
[738,36900,9500000,62500000,2509200000],
[739,36950,9500000,62600000,2512600000],
[740,37000,9550000,62800000,2516000000],
[741,37050,9550000,62900000,2519400000],
[742,37100,9600000,63000000,2522800000],
[743,37150,9600000,63200000,2526200000],
[744,37200,9600000,63300000,2529600000],
[745,37250,9650000,63500000,2533000000],
[746,37300,9650000,63600000,2536400000],
[747,37350,9650000,63800000,2539800000],
[748,37400,9700000,63900000,2543200000],
[749,37450,9700000,64100000,2546600000],
[750,37500,9750000,64200000,2550000000],
[751,37550,9750000,64400000,2553400000],
[752,37600,9750000,64500000,2556800000],
[753,37650,9800000,64700000,2560200000],
[754,37700,9800000,64800000,2563600000],
[755,37750,9800000,64900000,2567000000],
[756,37800,9850000,65100000,2570400000],
[757,37850,9850000,65200000,2573800000],
[758,37900,9900000,65400000,2577200000],
[759,37950,9900000,65500000,2580600000],
[760,38000,9900000,65700000,2584000000],
[761,38050,9950000,65800000,2587400000],
[762,38100,9950000,66000000,2590800000],
[763,38150,10000000,66100000,2594200000],
[764,38200,10000000,66300000,2597600000],
[765,38250,10000000,66400000,2601000000],
[766,38300,10000000,66600000,2604400000],
[767,38350,10000000,66700000,2607800000],
[768,38400,10000000,66900000,2611200000],
[769,38450,10000000,67000000,2614600000],
[770,38500,10000000,67200000,2618000000],
[771,38550,10000000,67300000,2621400000],
[772,38600,10000000,67500000,2624800000],
[773,38650,10000000,67600000,2628200000],
[774,38700,10000000,67800000,2631600000],
[775,38750,10000000,67900000,2635000000],
[776,38800,10000000,68100000,2638400000],
[777,38850,10000000,68200000,2641800000],
[778,38900,10000000,68400000,2645200000],
[779,38950,10000000,68500000,2648600000],
[780,39000,10000000,68700000,2652000000],
[781,39050,10000000,68800000,2655400000],
[782,39100,10000000,69000000,2658800000],
[783,39150,10000000,69100000,2662200000],
[784,39200,10000000,69300000,2665600000],
[785,39250,10000000,69400000,2669000000],
[786,39300,10000000,69600000,2672400000],
[787,39350,10000000,69800000,2675800000],
[788,39400,10000000,69900000,2679200000],
[789,39450,10500000,70100000,2682600000],
[790,39500,10500000,70200000,2686000000],
[791,39550,10500000,70400000,2689400000],
[792,39600,10500000,70500000,2692800000],
[793,39650,10500000,70700000,2696200000],
[794,39700,10500000,70800000,2699600000],
[795,39750,10500000,71000000,2703000000],
[796,39800,10500000,71100000,2706400000],
[797,39850,10500000,71300000,2709800000],
[798,39900,10500000,71500000,2713200000],
[799,39950,10500000,71600000,2716600000],
[800,40000,10500000,71800000,2720000000],
[801,40050,10500000,71900000,2723400000],
[802,40100,10500000,72100000,2726800000],
[803,40150,10500000,72200000,2730200000],
[804,40200,10500000,72400000,2733600000],
[805,40250,10500000,72600000,2737000000],
[806,40300,10500000,72700000,2740400000],
[807,40350,10500000,72900000,2743800000],
[808,40400,10500000,73000000,2747200000],
[809,40450,10500000,73200000,2750600000],
[810,40500,10500000,73300000,2754000000],
[811,40550,10500000,73500000,2757400000],
[812,40600,10500000,73700000,2760800000],
[813,40650,10500000,73800000,2764200000],
[814,40700,11000000,74000000,2767600000],
[815,40750,11000000,74100000,2771000000],
[816,40800,11000000,74300000,2774400000],
[817,40850,11000000,74500000,2777800000],
[818,40900,11000000,74600000,2781200000],
[819,40950,11000000,74800000,2784600000],
[820,41000,11000000,74900000,2788000000],
[821,41050,11000000,75100000,2791400000],
[822,41100,11000000,75300000,2794800000],
[823,41150,11000000,75400000,2798200000],
[824,41200,11000000,75600000,2801600000],
[825,41250,11000000,75800000,2805000000],
[826,41300,11000000,75900000,2808400000],
[827,41350,11000000,76100000,2811800000],
[828,41400,11000000,76200000,2815200000],
[829,41450,11000000,76400000,2818600000],
[830,41500,11000000,76600000,2822000000],
[831,41550,11000000,76700000,2825400000],
[832,41600,11000000,76900000,2828800000],
[833,41650,11000000,77100000,2832200000],
[834,41700,11000000,77200000,2835600000],
[835,41750,11000000,77400000,2839000000],
[836,41800,11000000,77600000,2842400000],
[837,41850,11000000,77700000,2845800000],
[838,41900,11000000,77900000,2849200000],
[839,41950,11500000,78000000,2852600000],
[840,42000,11500000,78200000,2856000000],
[841,42050,11500000,78400000,2859400000],
[842,42100,11500000,78500000,2862800000],
[843,42150,11500000,78700000,2866200000],
[844,42200,11500000,78900000,2869600000],
[845,42250,11500000,79000000,2873000000],
[846,42300,11500000,79200000,2876400000],
[847,42350,11500000,79400000,2879800000],
[848,42400,11500000,79500000,2883200000],
[849,42450,11500000,79700000,2886600000],
[850,42500,11500000,79900000,2890000000],
[851,42550,11500000,80000000,2893400000],
[852,42600,11500000,80200000,2896800000],
[853,42650,11500000,80400000,2900200000],
[854,42700,11500000,80600000,2903600000],
[855,42750,11500000,80600000,2907000000],
[856,42800,11500000,80800000,2910400000],
[857,42850,11500000,81000000,2913800000],
[858,42900,11500000,81200000,2917200000],
[859,42950,11500000,81400000,2920600000],
[860,43000,11500000,81600000,2924000000],
[861,43050,11500000,81600000,2927400000],
[862,43100,11500000,81800000,2930800000],
[863,43150,12000000,82000000,2934200000],
[864,43200,12000000,82200000,2937600000],
[865,43250,12000000,82400000,2941000000],
[866,43300,12000000,82600000,2944400000],
[867,43350,12000000,82800000,2947800000],
[868,43400,12000000,83000000,2951200000],
[869,43450,12000000,83000000,2954600000],
[870,43500,12000000,83200000,2958000000],
[871,43550,12000000,83400000,2961400000],
[872,43600,12000000,83600000,2964800000],
[873,43650,12000000,83800000,2968200000],
[874,43700,12000000,84000000,2971600000],
[875,43750,12000000,84200000,2975000000],
[876,43800,12000000,84200000,2978400000],
[877,43850,12000000,84400000,2981800000],
[878,43900,12000000,84600000,2985200000],
[879,43950,12000000,84800000,2988600000],
[880,44000,12000000,85000000,2992000000],
[881,44050,12000000,85200000,2995400000],
[882,44100,12000000,85400000,2998800000],
[883,44150,12000000,85600000,3002200000],
[884,44200,12000000,85800000,3005600000],
[885,44250,12000000,85800000,3009000000],
[886,44300,12000000,86000000,3012400000],
[887,44350,12500000,86200000,3015800000],
[888,44400,12500000,86400000,3019200000],
[889,44450,12500000,86600000,3022600000],
[890,44500,12500000,86800000,3026000000],
[891,44550,12500000,87000000,3029400000],
[892,44600,12500000,87200000,3032800000],
[893,44650,12500000,87400000,3036200000],
[894,44700,12500000,87400000,3039600000],
[895,44750,12500000,87600000,3043000000],
[896,44800,12500000,87800000,3046400000],
[897,44850,12500000,88000000,3049800000],
[898,44900,12500000,88200000,3053200000],
[899,44950,12500000,88400000,3056600000],
[900,45000,12500000,88600000,3060000000],
[901,45050,12500000,88800000,3063400000],
[902,45100,12500000,89000000,3066800000],
[903,45150,12500000,89200000,3070200000],
[904,45200,12500000,89400000,3073600000],
[905,45250,12500000,89400000,3077000000],
[906,45300,12500000,89600000,3080400000],
[907,45350,12500000,89800000,3083800000],
[908,45400,12500000,90000000,3087200000],
[909,45450,12500000,90200000,3090600000],
[910,45500,13000000,90400000,3094000000],
[911,45550,13000000,90600000,3097400000],
[912,45600,13000000,90800000,3100800000],
[913,45650,13000000,91000000,3104200000],
[914,45700,13000000,91200000,3107600000],
[915,45750,13000000,91400000,3111000000],
[916,45800,13000000,91600000,3114400000],
[917,45850,13000000,91800000,3117800000],
[918,45900,13000000,92000000,3121200000],
[919,45950,13000000,92000000,3124600000],
[920,46000,13000000,92200000,3128000000],
[921,46050,13000000,92400000,3131400000],
[922,46100,13000000,92600000,3134800000],
[923,46150,13000000,92800000,3138200000],
[924,46200,13000000,93000000,3141600000],
[925,46250,13000000,93200000,3145000000],
[926,46300,13000000,93400000,3148400000],
[927,46350,13000000,93600000,3151800000],
[928,46400,13000000,93800000,3155200000],
[929,46450,13000000,94000000,3158600000],
[930,46500,13000000,94200000,3162000000],
[931,46550,13000000,94400000,3165400000],
[932,46600,13500000,94600000,3168800000],
[933,46650,13500000,94800000,3172200000],
[934,46700,13500000,95000000,3175600000],
[935,46750,13500000,95200000,3179000000],
[936,46800,13500000,95400000,3182400000],
[937,46850,13500000,95600000,3185800000],
[938,46900,13500000,95800000,3189200000],
[939,46950,13500000,96000000,3192600000],
[940,47000,13500000,96200000,3196000000],
[941,47050,13500000,96200000,3199400000],
[942,47100,13500000,96400000,3202800000],
[943,47150,13500000,96600000,3206200000],
[944,47200,13500000,96800000,3209600000],
[945,47250,13500000,97000000,3213000000],
[946,47300,13500000,97200000,3216400000],
[947,47350,13500000,97400000,3219800000],
[948,47400,13500000,97600000,3223200000],
[949,47450,13500000,97800000,3226600000],
[950,47500,13500000,98000000,3230000000],
[951,47550,13500000,98200000,3233400000],
[952,47600,13500000,98400000,3236800000],
[953,47650,13500000,98600000,3240200000],
[954,47700,14000000,98800000,3243600000],
[955,47750,14000000,99000000,3247000000],
[956,47800,14000000,99200000,3250400000],
[957,47850,14000000,99400000,3253800000],
[958,47900,14000000,99600000,3257200000],
[959,47950,14000000,99800000,3260600000],
[960,48000,14000000,100000000,3264000000],
[961,48050,14000000,100200000,3267400000],
[962,48100,14000000,100400000,3270800000],
[963,48150,14000000,100600000,3274200000],
[964,48200,14000000,100800000,3277600000],
[965,48250,14000000,101000000,3281000000],
[966,48300,14000000,101200000,3284400000],
[967,48350,14000000,101400000,3287800000],
[968,48400,14000000,101600000,3291200000],
[969,48450,14000000,101800000,3294600000],
[970,48500,14000000,102200000,3298000000],
[971,48550,14000000,102400000,3301400000],
[972,48600,14000000,102600000,3304800000],
[973,48650,14000000,102800000,3308200000],
[974,48700,14000000,103000000,3311600000],
[975,48750,14500000,103200000,3315000000],
[976,48800,14500000,103400000,3318400000],
[977,48850,14500000,103600000,3321800000],
[978,48900,14500000,103800000,3325200000],
[979,48950,14500000,104000000,3328600000],
[980,49000,14500000,104200000,3332000000],
[981,49050,14500000,104400000,3335400000],
[982,49100,14500000,104600000,3338800000],
[983,49150,14500000,104800000,3342200000],
[984,49200,14500000,105000000,3345600000],
[985,49250,14500000,105200000,3349000000],
[986,49300,14500000,105400000,3352400000],
[987,49350,14500000,105600000,3355800000],
[988,49400,14500000,105800000,3359200000],
[989,49450,14500000,106000000,3362600000],
[990,49500,14500000,106200000,3366000000],
[991,49550,14500000,106400000,3369400000],
[992,49600,14500000,106800000,3372800000],
[993,49650,14500000,107000000,3376200000],
[994,49700,14500000,107200000,3379600000],
[995,49750,14500000,107400000,3383000000],
[996,49800,15000000,107600000,3386400000],
[997,49850,15000000,107800000,3389800000],
[998,49900,15000000,108000000,3393200000],
[999,49950,15000000,108200000,3396600000],
[1000,50000,15000000,108400000,3400000000],
[1001,50050,15000000,108600000,3403400000],
[1002,50100,15000000,108800000,3406800000],
[1003,50150,15000000,109000000,3410200000],
[1004,50200,15000000,109400000,3413600000],
[1005,50250,15000000,109600000,3417000000],
[1006,50300,15000000,109800000,3420400000],
[1007,50350,15000000,110000000,3423800000],
[1008,50400,15000000,110200000,3427200000],
[1009,50450,15000000,110400000,3430600000],
[1010,50500,15000000,110600000,3434000000],
[1011,50550,15000000,110800000,3437400000],
[1012,50600,15000000,111000000,3440800000],
[1013,50650,15000000,111200000,3444200000],
[1014,50700,15000000,111600000,3447600000],
[1015,50750,15000000,111800000,3451000000],
[1016,50800,15000000,112000000,3454400000],
[1017,50850,15500000,112200000,3457800000],
[1018,50900,15500000,112400000,3461200000],
[1019,50950,15500000,112600000,3464600000],
[1020,51000,15500000,112800000,3468000000],
[1021,51050,15500000,113000000,3471400000],
[1022,51100,15500000,113400000,3474800000],
[1023,51150,15500000,113600000,3478200000],
[1024,51200,15500000,113800000,3481600000],
[1025,51250,15500000,114000000,3485000000],
[1026,51300,15500000,114200000,3488400000],
[1027,51350,15500000,114400000,3491800000],
[1028,51400,15500000,114600000,3495200000],
[1029,51450,15500000,115000000,3498600000],
[1030,51500,15500000,115200000,3502000000],
[1031,51550,15500000,115400000,3505400000],
[1032,51600,15500000,115600000,3508800000],
[1033,51650,15500000,115800000,3512200000],
[1034,51700,15500000,116000000,3515600000],
[1035,51750,15500000,116400000,3519000000],
[1036,51800,15500000,116600000,3522400000],
[1037,51850,16000000,116800000,3525800000],
[1038,51900,16000000,117000000,3529200000],
[1039,51950,16000000,117200000,3532600000],
[1040,52000,16000000,117400000,3536000000],
[1041,52050,16000000,117800000,3539400000],
[1042,52100,16000000,118000000,3542800000],
[1043,52150,16000000,118200000,3546200000],
[1044,52200,16000000,118400000,3549600000],
[1045,52250,16000000,118600000,3553000000],
[1046,52300,16000000,118800000,3556400000],
[1047,52350,16000000,119200000,3559800000],
[1048,52400,16000000,119400000,3563200000],
[1049,52450,16000000,119600000,3566600000],
[1050,52500,16000000,119800000,3570000000],
[1051,52550,16000000,120000000,3573400000],
[1052,52600,16000000,120400000,3576800000],
[1053,52650,16000000,120600000,3580200000],
[1054,52700,16000000,120800000,3583600000],
[1055,52750,16000000,121000000,3587000000],
[1056,52800,16000000,121200000,3590400000],
[1057,52850,16500000,121600000,3593800000],
[1058,52900,16500000,121800000,3597200000],
[1059,52950,16500000,122000000,3600600000],
[1060,53000,16500000,122200000,3604000000],
[1061,53050,16500000,122600000,3607400000],
[1062,53100,16500000,122800000,3610800000],
[1063,53150,16500000,123000000,3614200000],
[1064,53200,16500000,123200000,3617600000],
[1065,53250,16500000,123600000,3621000000],
[1066,53300,16500000,123800000,3624400000],
[1067,53350,16500000,124000000,3627800000],
[1068,53400,16500000,124200000,3631200000],
[1069,53450,16500000,124600000,3634600000],
[1070,53500,16500000,124800000,3638000000],
[1071,53550,16500000,125000000,3641400000],
[1072,53600,16500000,125200000,3644800000],
[1073,53650,16500000,125600000,3648200000],
[1074,53700,16500000,125800000,3651600000],
[1075,53750,16500000,126000000,3655000000],
[1076,53800,17000000,126200000,3658400000],
[1077,53850,17000000,126600000,3661800000],
[1078,53900,17000000,126800000,3665200000],
[1079,53950,17000000,127000000,3668600000],
[1080,54000,17000000,127200000,3672000000],
[1081,54050,17000000,127600000,3675400000],
[1082,54100,17000000,127800000,3678800000],
[1083,54150,17000000,128000000,3682200000],
[1084,54200,17000000,128400000,3685600000],
[1085,54250,17000000,128600000,3689000000],
[1086,54300,17000000,128800000,3692400000],
[1087,54350,17000000,129000000,3695800000],
[1088,54400,17000000,129400000,3699200000],
[1089,54450,17000000,129600000,3702600000],
[1090,54500,17000000,129800000,3706000000],
[1091,54550,17000000,130200000,3709400000],
[1092,54600,17000000,130400000,3712800000],
[1093,54650,17000000,130600000,3716200000],
[1094,54700,17500000,131000000,3719600000],
[1095,54750,17500000,131200000,3723000000],
[1096,54800,17500000,131400000,3726400000],
[1097,54850,17500000,131800000,3729800000],
[1098,54900,17500000,132000000,3733200000],
[1099,54950,17500000,132200000,3736600000],
[1100,55000,17500000,132600000,3740000000],
[1101,55050,17500000,132800000,3743400000],
[1102,55100,17500000,133000000,3746800000],
[1103,55150,17500000,133400000,3750200000],
[1104,55200,17500000,133600000,3753600000],
[1105,55250,17500000,133800000,3757000000],
[1106,55300,17500000,134200000,3760400000],
[1107,55350,17500000,134400000,3763800000],
[1108,55400,17500000,134600000,3767200000],
[1109,55450,17500000,135000000,3770600000],
[1110,55500,17500000,135200000,3774000000],
[1111,55550,17500000,135400000,3777400000],
[1112,55600,18000000,135800000,3780800000],
[1113,55650,18000000,136000000,3784200000],
[1114,55700,18000000,136400000,3787600000],
[1115,55750,18000000,136600000,3791000000],
[1116,55800,18000000,136800000,3794400000],
[1117,55850,18000000,137200000,3797800000],
[1118,55900,18000000,137400000,3801200000],
[1119,55950,18000000,137800000,3804600000],
[1120,56000,18000000,138000000,3808000000],
[1121,56050,18000000,138200000,3811400000],
[1122,56100,18000000,138600000,3814800000],
[1123,56150,18000000,138800000,3818200000],
[1124,56200,18000000,139200000,3821600000],
[1125,56250,18000000,139400000,3825000000],
[1126,56300,18000000,139600000,3828400000],
[1127,56350,18000000,140000000,3831800000],
[1128,56400,18000000,140200000,3835200000],
[1129,56450,18000000,140600000,3838600000],
[1130,56500,18500000,140800000,3842000000],
[1131,56550,18500000,141200000,3845400000],
[1132,56600,18500000,141400000,3848800000],
[1133,56650,18500000,141600000,3852200000],
[1134,56700,18500000,142000000,3855600000],
[1135,56750,18500000,142200000,3859000000],
[1136,56800,18500000,142600000,3862400000],
[1137,56850,18500000,142800000,3865800000],
[1138,56900,18500000,143200000,3869200000],
[1139,56950,18500000,143400000,3872600000],
[1140,57000,18500000,143800000,3876000000],
[1141,57050,18500000,144000000,3879400000],
[1142,57100,18500000,144400000,3882800000],
[1143,57150,18500000,144600000,3886200000],
[1144,57200,18500000,145000000,3889600000],
[1145,57250,18500000,145200000,3893000000],
[1146,57300,18500000,145400000,3896400000],
[1147,57350,18500000,145800000,3899800000],
[1148,57400,19000000,146000000,3903200000],
[1149,57450,19000000,146400000,3906600000],
[1150,57500,19000000,146600000,3910000000],
[1151,57550,19000000,147000000,3913400000],
[1152,57600,19000000,147200000,3916800000],
[1153,57650,19000000,147600000,3920200000],
[1154,57700,19000000,148000000,3923600000],
[1155,57750,19000000,148200000,3927000000],
[1156,57800,19000000,148600000,3930400000],
[1157,57850,19000000,148800000,3933800000],
[1158,57900,19000000,149200000,3937200000],
[1159,57950,19000000,149400000,3940600000],
[1160,58000,19000000,149800000,3944000000],
[1161,58050,19000000,150000000,3947400000],
[1162,58100,19000000,150400000,3950800000],
[1163,58150,19000000,150600000,3954200000],
[1164,58200,19000000,151000000,3957600000],
[1165,58250,19500000,151200000,3961000000],
[1166,58300,19500000,151600000,3964400000],
[1167,58350,19500000,152000000,3967800000],
[1168,58400,19500000,152200000,3971200000],
[1169,58450,19500000,152600000,3974600000],
[1170,58500,19500000,152800000,3978000000],
[1171,58550,19500000,153200000,3981400000],
[1172,58600,19500000,153400000,3984800000],
[1173,58650,19500000,153800000,3988200000],
[1174,58700,19500000,154200000,3991600000],
[1175,58750,19500000,154400000,3995000000],
[1176,58800,19500000,154800000,3998400000],
[1177,58850,19500000,155000000,4001800000],
[1178,58900,19500000,155400000,4005200000],
[1179,58950,19500000,155800000,4008600000],
[1180,59000,19500000,156000000,4012000000],
[1181,59050,20000000,156400000,4015400000],
[1182,59100,20000000,156600000,4018800000],
[1183,59150,20000000,157000000,4022200000],
[1184,59200,20000000,157400000,4025600000],
[1185,59250,20000000,157600000,4029000000],
[1186,59300,20000000,158000000,4032400000],
[1187,59350,20000000,158400000,4035800000],
[1188,59400,20000000,158600000,4039200000],
[1189,59450,20000000,159000000,4042600000],
[1190,59500,20000000,159400000,4046000000],
[1191,59550,20000000,159600000,4049400000],
[1192,59600,20000000,160000000,4052800000],
[1193,59650,20000000,160400000,4056200000],
[1194,59700,20000000,160600000,4059600000],
[1195,59750,20000000,161000000,4063000000],
[1196,59800,20000000,161400000,4066400000],
[1197,59850,20500000,161600000,4069800000],
[1198,59900,20500000,162000000,4073200000],
[1199,59950,20500000,162400000,4076600000],
[1200,60000,20500000,162600000,4080000000],
[1201,60050,20500000,163000000,4083400000],
[1202,60100,20500000,163400000,4086800000],
[1203,60150,20500000,163600000,4090200000],
[1204,60200,20500000,164000000,4093600000],
[1205,60250,20500000,164400000,4097000000],
[1206,60300,20500000,164800000,4100400000],
[1207,60350,20500000,165000000,4103800000],
[1208,60400,20500000,165400000,4107200000],
[1209,60450,20500000,165800000,4110600000],
[1210,60500,20500000,166000000,4114000000],
[1211,60550,20500000,166400000,4117400000],
[1212,60600,20500000,166800000,4120800000],
[1213,60650,21000000,167200000,4124200000],
[1214,60700,21000000,167400000,4127600000],
[1215,60750,21000000,167800000,4131000000],
[1216,60800,21000000,168200000,4134400000],
[1217,60850,21000000,168600000,4137800000],
[1218,60900,21000000,169000000,4141200000],
[1219,60950,21000000,169200000,4144600000],
[1220,61000,21000000,169600000,4148000000],
[1221,61050,21000000,170000000,4151400000],
[1222,61100,21000000,170400000,4154800000],
[1223,61150,21000000,170600000,4158200000],
[1224,61200,21000000,171000000,4161600000],
[1225,61250,21000000,171400000,4165000000],
[1226,61300,21000000,171800000,4168400000],
[1227,61350,21000000,172200000,4171800000],
[1228,61400,21000000,172400000,4175200000],
[1229,61450,21500000,172800000,4178600000],
[1230,61500,21500000,173200000,4182000000],
[1231,61550,21500000,173600000,4185400000],
[1232,61600,21500000,174000000,4188800000],
[1233,61650,21500000,174400000,4192200000],
[1234,61700,21500000,174600000,4195600000],
[1235,61750,21500000,175000000,4199000000],
[1236,61800,21500000,175400000,4202400000],
[1237,61850,21500000,175800000,4205800000],
[1238,61900,21500000,176200000,4209200000],
[1239,61950,21500000,176600000,4212600000],
[1240,62000,21500000,177000000,4216000000],
[1241,62050,21500000,177200000,4219400000],
[1242,62100,21500000,177600000,4222800000],
[1243,62150,21500000,178000000,4226200000],
[1244,62200,22000000,178400000,4229600000],
[1245,62250,22000000,178800000,4233000000],
[1246,62300,22000000,179200000,4236400000],
[1247,62350,22000000,179600000,4239800000],
[1248,62400,22000000,180000000,4243200000],
[1249,62450,22000000,180400000,4246600000],
[1250,62500,22000000,180800000,4250000000],
[1251,62550,22000000,181000000,4253400000],
[1252,62600,22000000,181400000,4256800000],
[1253,62650,22000000,181800000,4260200000],
[1254,62700,22000000,182200000,4263600000],
[1255,62750,22000000,182600000,4267000000],
[1256,62800,22000000,183000000,4270400000],
[1257,62850,22000000,183400000,4273800000],
[1258,62900,22500000,183800000,4277200000],
[1259,62950,22500000,184200000,4280600000],
[1260,63000,22500000,184600000,4284000000],
[1261,63050,22500000,185000000,4287400000],
[1262,63100,22500000,185400000,4290800000],
[1263,63150,22500000,185800000,4294200000],
[1264,63200,22500000,186200000,4297600000],
[1265,63250,22500000,186600000,4301000000],
[1266,63300,22500000,187000000,4304400000],
[1267,63350,22500000,187400000,4307800000],
[1268,63400,22500000,187800000,4311200000],
[1269,63450,22500000,188200000,4314600000],
[1270,63500,22500000,188600000,4318000000],
[1271,63550,22500000,189000000,4321400000],
[1272,63600,22500000,189400000,4324800000],
[1273,63650,23000000,189800000,4328200000],
[1274,63700,23000000,190200000,4331600000],
[1275,63750,23000000,190600000,4335000000],
[1276,63800,23000000,191000000,4338400000],
[1277,63850,23000000,191400000,4341800000],
[1278,63900,23000000,191800000,4345200000],
[1279,63950,23000000,192200000,4348600000],
[1280,64000,23000000,192600000,4352000000],
[1281,64050,23000000,193000000,4355400000],
[1282,64100,23000000,193400000,4358800000],
[1283,64150,23000000,193800000,4362200000],
[1284,64200,23000000,194200000,4365600000],
[1285,64250,23000000,194600000,4369000000],
[1286,64300,23000000,195000000,4372400000],
[1287,64350,23500000,195600000,4375800000],
[1288,64400,23500000,196000000,4379200000],
[1289,64450,23500000,196400000,4382600000],
[1290,64500,23500000,196800000,4386000000],
[1291,64550,23500000,197200000,4389400000],
[1292,64600,23500000,197600000,4392800000],
[1293,64650,23500000,198000000,4396200000],
[1294,64700,23500000,198400000,4399600000],
[1295,64750,23500000,198800000,4403000000],
[1296,64800,23500000,199200000,4406400000],
[1297,64850,23500000,199800000,4409800000],
[1298,64900,23500000,200200000,4413200000],
[1299,64950,23500000,200600000,4416600000],
[1300,65000,24000000,201000000,4420000000],
[1301,65050,24000000,201400000,4423400000],
[1302,65100,24000000,201800000,4426800000],
[1303,65150,24000000,202200000,4430200000],
[1304,65200,24000000,202800000,4433600000],
[1305,65250,24000000,203200000,4437000000],
[1306,65300,24000000,203600000,4440400000],
[1307,65350,24000000,204000000,4443800000],
[1308,65400,24000000,204400000,4447200000],
[1309,65450,24000000,204800000,4450600000],
[1310,65500,24000000,205400000,4454000000],
[1311,65550,24000000,205800000,4457400000],
[1312,65600,24000000,206200000,4460800000],
[1313,65650,24000000,206600000,4464200000],
[1314,65700,24500000,207000000,4467600000],
[1315,65750,24500000,207600000,4471000000],
[1316,65800,24500000,208000000,4474400000],
[1317,65850,24500000,208400000,4477800000],
[1318,65900,24500000,208800000,4481200000],
[1319,65950,24500000,209200000,4484600000],
[1320,66000,24500000,209800000,4488000000],
[1321,66050,24500000,210200000,4491400000],
[1322,66100,24500000,210600000,4494800000],
[1323,66150,24500000,211000000,4498200000],
[1324,66200,24500000,211600000,4501600000],
[1325,66250,24500000,212000000,4505000000],
[1326,66300,24500000,212400000,4508400000],
[1327,66350,25000000,212800000,4511800000],
[1328,66400,25000000,213400000,4515200000],
[1329,66450,25000000,213800000,4518600000],
[1330,66500,25000000,214200000,4522000000],
[1331,66550,25000000,214600000,4525400000],
[1332,66600,25000000,215200000,4528800000],
[1333,66650,25000000,215600000,4532200000],
[1334,66700,25000000,216000000,4535600000],
[1335,66750,25000000,216600000,4539000000],
[1336,66800,25000000,217000000,4542400000],
[1337,66850,25000000,217400000,4545800000],
[1338,66900,25000000,217800000,4549200000],
[1339,66950,25500000,218400000,4552600000],
[1340,67000,25500000,218800000,4556000000],
[1341,67050,25500000,219200000,4559400000],
[1342,67100,25500000,219800000,4562800000],
[1343,67150,25500000,220200000,4566200000],
[1344,67200,25500000,220600000,4569600000],
[1345,67250,25500000,221200000,4573000000],
[1346,67300,25500000,221600000,4576400000],
[1347,67350,25500000,222000000,4579800000],
[1348,67400,25500000,222600000,4583200000],
[1349,67450,25500000,223000000,4586600000],
[1350,67500,25500000,223400000,4590000000],
[1351,67550,25500000,224000000,4593400000],
[1352,67600,26000000,224400000,4596800000],
[1353,67650,26000000,224800000,4600200000],
[1354,67700,26000000,225400000,4603600000],
[1355,67750,26000000,225800000,4607000000],
[1356,67800,26000000,226200000,4610400000],
[1357,67850,26000000,226800000,4613800000],
[1358,67900,26000000,227200000,4617200000],
[1359,67950,26000000,227600000,4620600000],
[1360,68000,26000000,228200000,4624000000],
[1361,68050,26000000,228600000,4627400000],
[1362,68100,26000000,229000000,4630800000],
[1363,68150,26000000,229600000,4634200000],
[1364,68200,26500000,230000000,4637600000],
[1365,68250,26500000,230600000,4641000000],
[1366,68300,26500000,231000000,4644400000],
[1367,68350,26500000,231400000,4647800000],
[1368,68400,26500000,232000000,4651200000],
[1369,68450,26500000,232400000,4654600000],
[1370,68500,26500000,233000000,4658000000],
[1371,68550,26500000,233400000,4661400000],
[1372,68600,26500000,233800000,4664800000],
[1373,68650,26500000,234400000,4668200000],
[1374,68700,26500000,234800000,4671600000],
[1375,68750,26500000,235400000,4675000000],
[1376,68800,27000000,235800000,4678400000],
[1377,68850,27000000,236200000,4681800000],
[1378,68900,27000000,236800000,4685200000],
[1379,68950,27000000,237200000,4688600000],
[1380,69000,27000000,237800000,4692000000],
[1381,69050,27000000,238200000,4695400000],
[1382,69100,27000000,238800000,4698800000],
[1383,69150,27000000,239200000,4702200000],
[1384,69200,27000000,239600000,4705600000],
[1385,69250,27000000,240200000,4709000000],
[1386,69300,27000000,240600000,4712400000],
[1387,69350,27500000,241200000,4715800000],
[1388,69400,27500000,241600000,4719200000],
[1389,69450,27500000,242200000,4722600000],
[1390,69500,27500000,242600000,4726000000],
[1391,69550,27500000,243000000,4729400000],
[1392,69600,27500000,243600000,4732800000],
[1393,69650,27500000,244000000,4736200000],
[1394,69700,27500000,244600000,4739600000],
[1395,69750,27500000,245000000,4743000000],
[1396,69800,27500000,245600000,4746400000],
[1397,69850,27500000,246000000,4749800000],
[1398,69900,28000000,246600000,4753200000],
[1399,69950,28000000,247000000,4756600000],
[1400,70000,28000000,247400000,4760000000],
[1401,70050,28000000,248000000,4763400000],
[1402,70100,28000000,248400000,4766800000],
[1403,70150,28000000,249000000,4770200000],
[1404,70200,28000000,249400000,4773600000],
[1405,70250,28000000,250000000,4777000000],
[1406,70300,28000000,250500000,4780400000],
[1407,70350,28000000,251000000,4783800000],
[1408,70400,28000000,251500000,4787200000],
[1409,70450,28500000,252000000,4790600000],
[1410,70500,28500000,252500000,4794000000],
[1411,70550,28500000,253000000,4797400000],
[1412,70600,28500000,253500000,4800800000],
[1413,70650,28500000,254000000,4804200000],
[1414,70700,28500000,254500000,4807600000],
[1415,70750,28500000,255000000,4811000000],
[1416,70800,28500000,255500000,4814400000],
[1417,70850,28500000,256000000,4817800000],
[1418,70900,28500000,256000000,4821200000],
[1419,70950,28500000,256500000,4824600000],
[1420,71000,29000000,257000000,4828000000],
[1421,71050,29000000,257500000,4831400000],
[1422,71100,29000000,258000000,4834800000],
[1423,71150,29000000,258500000,4838200000],
[1424,71200,29000000,259000000,4841600000],
[1425,71250,29000000,259500000,4845000000],
[1426,71300,29000000,260000000,4848400000],
[1427,71350,29000000,260500000,4851800000],
[1428,71400,29000000,261000000,4855200000],
[1429,71450,29000000,261500000,4858600000],
[1430,71500,29000000,262000000,4862000000],
[1431,71550,29500000,262500000,4865400000],
[1432,71600,29500000,263000000,4868800000],
[1433,71650,29500000,263500000,4872200000],
[1434,71700,29500000,264000000,4875600000],
[1435,71750,29500000,264500000,4879000000],
[1436,71800,29500000,265000000,4882400000],
[1437,71850,29500000,265500000,4885800000],
[1438,71900,29500000,266000000,4889200000],
[1439,71950,29500000,266500000,4892600000],
[1440,72000,29500000,267000000,4896000000],
[1441,72050,30000000,267500000,4899400000],
[1442,72100,30000000,268000000,4902800000],
[1443,72150,30000000,268500000,4906200000],
[1444,72200,30000000,269000000,4909600000],
[1445,72250,30000000,269500000,4913000000],
[1446,72300,30000000,270000000,4916400000],
[1447,72350,30000000,270500000,4919800000],
[1448,72400,30000000,271000000,4923200000],
[1449,72450,30000000,271500000,4926600000],
[1450,72500,30000000,272000000,4930000000],
[1451,72550,30500000,272500000,4933400000],
[1452,72600,30500000,273000000,4936800000],
[1453,72650,30500000,273500000,4940200000],
[1454,72700,30500000,274000000,4943600000],
[1455,72750,30500000,274500000,4947000000],
[1456,72800,30500000,275000000,4950400000],
[1457,72850,30500000,275500000,4953800000],
[1458,72900,30500000,276000000,4957200000],
[1459,72950,30500000,276500000,4960600000],
[1460,73000,30500000,277000000,4964000000],
[1461,73050,31000000,277500000,4967400000],
[1462,73100,31000000,278000000,4970800000],
[1463,73150,31000000,278500000,4974200000],
[1464,73200,31000000,279000000,4977600000],
[1465,73250,31000000,279500000,4981000000],
[1466,73300,31000000,280000000,4984400000],
[1467,73350,31000000,280500000,4987800000],
[1468,73400,31000000,281000000,4991200000],
[1469,73450,31000000,281500000,4994600000],
[1470,73500,31500000,282000000,4998000000],
[1471,73550,31500000,282500000,5001400000],
[1472,73600,31500000,283000000,5004800000],
[1473,73650,31500000,283500000,5008200000],
[1474,73700,31500000,284000000,5011600000],
[1475,73750,31500000,284500000,5015000000],
[1476,73800,31500000,285000000,5018400000],
[1477,73850,31500000,285500000,5021800000],
[1478,73900,31500000,286000000,5025200000],
[1479,73950,31500000,286500000,5028600000],
[1480,74000,32000000,287000000,5032000000],
[1481,74050,32000000,287500000,5035400000],
[1482,74100,32000000,288000000,5038800000],
[1483,74150,32000000,288500000,5042200000],
[1484,74200,32000000,289000000,5045600000],
[1485,74250,32000000,289500000,5049000000],
[1486,74300,32000000,290000000,5052400000],
[1487,74350,32000000,290500000,5055800000],
[1488,74400,32000000,291000000,5059200000],
[1489,74450,32500000,291500000,5062600000],
[1490,74500,32500000,292000000,5066000000],
[1491,74550,32500000,292500000,5069400000],
[1492,74600,32500000,293000000,5072800000],
[1493,74650,32500000,293500000,5076200000],
[1494,74700,32500000,294000000,5079600000],
[1495,74750,32500000,294500000,5083000000],
[1496,74800,32500000,295000000,5086400000],
[1497,74850,32500000,295500000,5089800000],
[1498,74900,33000000,296000000,5093200000],
[1499,74950,33000000,296500000,5096600000],
[1500,75000,33000000,297000000,5100000000],
[1501,75050,33000000,297500000,5103400000],
[1502,75100,33000000,298000000,5106800000],
[1503,75150,33000000,298500000,5110200000],
[1504,75200,33000000,299000000,5113600000],
[1505,75250,33000000,299500000,5117000000],
[1506,75300,33500000,300000000,5120400000],
[1507,75350,33500000,300500000,5123800000],
[1508,75400,33500000,301000000,5127200000],
[1509,75450,33500000,301500000,5130600000],
[1510,75500,33500000,302000000,5134000000],
[1511,75550,33500000,302500000,5137400000],
[1512,75600,33500000,303000000,5140800000],
[1513,75650,33500000,303500000,5144200000],
[1514,75700,33500000,304000000,5147600000],
[1515,75750,34000000,304500000,5151000000],
[1516,75800,34000000,305000000,5154400000],
[1517,75850,34000000,305500000,5157800000],
[1518,75900,34000000,306000000,5161200000],
[1519,75950,34000000,306500000,5164600000],
[1520,76000,34000000,307000000,5168000000],
[1521,76050,34000000,307000000,5171400000],
[1522,76100,34000000,307500000,5174800000],
[1523,76150,34500000,308000000,5178200000],
[1524,76200,34500000,308500000,5181600000],
[1525,76250,34500000,309000000,5185000000],
[1526,76300,34500000,309500000,5188400000],
[1527,76350,34500000,310000000,5191800000],
[1528,76400,34500000,310500000,5195200000],
[1529,76450,34500000,311000000,5198600000],
[1530,76500,34500000,311500000,5202000000],
[1531,76550,35000000,312000000,5205400000],
[1532,76600,35000000,312500000,5208800000],
[1533,76650,35000000,313000000,5212200000],
[1534,76700,35000000,313500000,5215600000],
[1535,76750,35000000,314000000,5219000000],
[1536,76800,35000000,314500000,5222400000],
[1537,76850,35000000,315000000,5225800000],
[1538,76900,35000000,315500000,5229200000],
[1539,76950,35500000,316000000,5232600000],
[1540,77000,35500000,316500000,5236000000],
[1541,77050,35500000,317000000,5239400000],
[1542,77100,35500000,317500000,5242800000],
[1543,77150,35500000,318000000,5246200000],
[1544,77200,35500000,318500000,5249600000],
[1545,77250,35500000,319000000,5253000000],
[1546,77300,35500000,319500000,5256400000],
[1547,77350,36000000,320000000,5259800000],
[1548,77400,36000000,320500000,5263200000],
[1549,77450,36000000,321000000,5266600000],
[1550,77500,36000000,321000000,5270000000],
[1551,77550,36000000,321500000,5273400000],
[1552,77600,36000000,322000000,5276800000],
[1553,77650,36000000,322500000,5280200000],
[1554,77700,36000000,323000000,5283600000],
[1555,77750,36500000,323500000,5287000000],
[1556,77800,36500000,324000000,5290400000],
[1557,77850,36500000,324500000,5293800000],
[1558,77900,36500000,325000000,5297200000],
[1559,77950,36500000,325500000,5300600000],
[1560,78000,36500000,326000000,5304000000],
[1561,78050,36500000,326500000,5307400000],
[1562,78100,37000000,327000000,5310800000],
[1563,78150,37000000,327500000,5314200000],
[1564,78200,37000000,328000000,5317600000],
[1565,78250,37000000,328500000,5321000000],
[1566,78300,37000000,329000000,5324400000],
[1567,78350,37000000,329500000,5327800000],
[1568,78400,37000000,330000000,5331200000],
[1569,78450,37000000,330500000,5334600000],
[1570,78500,37500000,331000000,5338000000],
[1571,78550,37500000,331500000,5341400000],
[1572,78600,37500000,331500000,5344800000],
[1573,78650,37500000,332000000,5348200000],
[1574,78700,37500000,332500000,5351600000],
[1575,78750,37500000,333000000,5355000000],
[1576,78800,37500000,333500000,5358400000],
[1577,78850,38000000,334000000,5361800000],
[1578,78900,38000000,334500000,5365200000],
[1579,78950,38000000,335000000,5368600000],
[1580,79000,38000000,335500000,5372000000],
[1581,79050,38000000,336000000,5375400000],
[1582,79100,38000000,336500000,5378800000],
[1583,79150,38000000,337000000,5382200000],
[1584,79200,38500000,337500000,5385600000],
[1585,79250,38500000,338000000,5389000000],
[1586,79300,38500000,338500000,5392400000],
[1587,79350,38500000,339000000,5395800000],
[1588,79400,38500000,339500000,5399200000],
[1589,79450,38500000,340000000,5402600000],
[1590,79500,38500000,340000000,5406000000],
[1591,79550,39000000,340500000,5409400000],
[1592,79600,39000000,341000000,5412800000],
[1593,79650,39000000,341500000,5416200000],
[1594,79700,39000000,342000000,5419600000],
[1595,79750,39000000,342500000,5423000000],
[1596,79800,39000000,343000000,5426400000],
[1597,79850,39000000,343500000,5429800000],
[1598,79900,39500000,344000000,5433200000],
[1599,79950,39500000,344500000,5436600000],
[1600,80000,39500000,345000000,5440000000],
[1601,80050,39500000,345500000,5443400000],
[1602,80100,39500000,346000000,5446800000],
[1603,80150,39500000,346500000,5450200000],
[1604,80200,40000000,347000000,5453600000],
[1605,80250,40000000,347500000,5457000000],
[1606,80300,40000000,347500000,5460400000],
[1607,80350,40000000,348000000,5463800000],
[1608,80400,40000000,348500000,5467200000],
[1609,80450,40000000,349000000,5470600000],
[1610,80500,40000000,349500000,5474000000],
[1611,80550,40500000,350000000,5477400000],
[1612,80600,40500000,350500000,5480800000],
[1613,80650,40500000,351000000,5484200000],
[1614,80700,40500000,351500000,5487600000],
[1615,80750,40500000,352000000,5491000000],
[1616,80800,40500000,352500000,5494400000],
[1617,80850,41000000,353000000,5497800000],
[1618,80900,41000000,353500000,5501200000],
[1619,80950,41000000,354000000,5504600000],
[1620,81000,41000000,354500000,5508000000],
[1621,81050,41000000,354500000,5511400000],
[1622,81100,41000000,355000000,5514800000],
[1623,81150,41000000,355500000,5518200000],
[1624,81200,41500000,356000000,5521600000],
[1625,81250,41500000,356500000,5525000000],
[1626,81300,41500000,357000000,5528400000],
[1627,81350,41500000,357500000,5531800000],
[1628,81400,41500000,358000000,5535200000],
[1629,81450,41500000,358500000,5538600000],
[1630,81500,42000000,359000000,5542000000],
[1631,81550,42000000,359500000,5545400000],
[1632,81600,42000000,360000000,5548800000],
[1633,81650,42000000,360500000,5552200000],
[1634,81700,42000000,361000000,5555600000],
[1635,81750,42000000,361000000,5559000000],
[1636,81800,42500000,361500000,5562400000],
[1637,81850,42500000,362000000,5565800000],
[1638,81900,42500000,362500000,5569200000],
[1639,81950,42500000,363000000,5572600000],
[1640,82000,42500000,363500000,5576000000],
[1641,82050,42500000,364000000,5579400000],
[1642,82100,43000000,364500000,5582800000],
[1643,82150,43000000,365000000,5586200000],
[1644,82200,43000000,365500000,5589600000],
[1645,82250,43000000,366000000,5593000000],
[1646,82300,43000000,366500000,5596400000],
[1647,82350,43000000,367000000,5599800000],
[1648,82400,43500000,367500000,5603200000],
[1649,82450,43500000,367500000,5606600000],
[1650,82500,43500000,368000000,5610000000],
[1651,82550,43500000,368500000,5613400000],
[1652,82600,43500000,369000000,5616800000],
[1653,82650,43500000,369500000,5620200000],
[1654,82700,44000000,370000000,5623600000],
[1655,82750,44000000,370500000,5627000000],
[1656,82800,44000000,371000000,5630400000],
[1657,82850,44000000,371500000,5633800000],
[1658,82900,44000000,372000000,5637200000],
[1659,82950,44500000,372500000,5640600000],
[1660,83000,44500000,373000000,5644000000],
[1661,83050,44500000,373500000,5647400000],
[1662,83100,44500000,374000000,5650800000],
[1663,83150,44500000,374500000,5654200000],
[1664,83200,44500000,374500000,5657600000],
[1665,83250,45000000,375000000,5661000000],
[1666,83300,45000000,375500000,5664400000],
[1667,83350,45000000,376000000,5667800000],
[1668,83400,45000000,376500000,5671200000],
[1669,83450,45000000,377000000,5674600000],
[1670,83500,45000000,377500000,5678000000],
[1671,83550,45500000,378000000,5681400000],
[1672,83600,45500000,378500000,5684800000],
[1673,83650,45500000,379000000,5688200000],
[1674,83700,45500000,379500000,5691600000],
[1675,83750,45500000,380000000,5695000000],
[1676,83800,46000000,380500000,5698400000],
[1677,83850,46000000,381000000,5701800000],
[1678,83900,46000000,381000000,5705200000],
[1679,83950,46000000,381500000,5708600000],
[1680,84000,46000000,382000000,5712000000],
[1681,84050,46000000,382500000,5715400000],
[1682,84100,46500000,383000000,5718800000],
[1683,84150,46500000,383500000,5722200000],
[1684,84200,46500000,384000000,5725600000],
[1685,84250,46500000,384500000,5729000000],
[1686,84300,46500000,385000000,5732400000],
[1687,84350,47000000,385500000,5735800000],
[1688,84400,47000000,386000000,5739200000],
[1689,84450,47000000,386500000,5742600000],
[1690,84500,47000000,387000000,5746000000],
[1691,84550,47000000,387500000,5749400000],
[1692,84600,47000000,388000000,5752800000],
[1693,84650,47500000,388500000,5756200000],
[1694,84700,47500000,388500000,5759600000],
[1695,84750,47500000,389000000,5763000000],
[1696,84800,47500000,389500000,5766400000],
[1697,84850,47500000,390000000,5769800000],
[1698,84900,48000000,390500000,5773200000],
[1699,84950,48000000,391000000,5776600000],
[1700,85000,48000000,391500000,5780000000],
[1701,85050,48000000,392000000,5783400000],
[1702,85100,48000000,392500000,5786800000],
[1703,85150,48500000,393000000,5790200000],
[1704,85200,48500000,393500000,5793600000],
[1705,85250,48500000,394000000,5797000000],
[1706,85300,48500000,394500000,5800400000],
[1707,85350,48500000,395000000,5803800000],
[1708,85400,49000000,395500000,5807200000],
[1709,85450,49000000,396000000,5810600000],
[1710,85500,49000000,396500000,5814000000],
[1711,85550,49000000,397000000,5817400000],
[1712,85600,49000000,397500000,5820800000],
[1713,85650,49500000,397500000,5824200000],
[1714,85700,49500000,398000000,5827600000],
[1715,85750,49500000,398500000,5831000000],
[1716,85800,49500000,399000000,5834400000],
[1717,85850,49500000,399500000,5837800000],
[1718,85900,49500000,400000000,5841200000],
[1719,85950,50000000,400500000,5844600000],
[1720,86000,50000000,401000000,5848000000],
[1721,86050,50000000,401500000,5851400000],
[1722,86100,50000000,402000000,5854800000],
[1723,86150,50000000,402500000,5858200000],
[1724,86200,50500000,403000000,5861600000],
[1725,86250,50500000,403500000,5865000000],
[1726,86300,50500000,404000000,5868400000],
[1727,86350,50500000,404500000,5871800000],
[1728,86400,50500000,404500000,5875200000],
[1729,86450,51000000,405000000,5878600000],
[1730,86500,51000000,405500000,5882000000],
[1731,86550,51000000,406000000,5885400000],
[1732,86600,51000000,406500000,5888800000],
[1733,86650,51000000,407000000,5892200000],
[1734,86700,51500000,407500000,5895600000],
[1735,86750,51500000,408000000,5899000000],
[1736,86800,51500000,408500000,5902400000],
[1737,86850,51500000,409000000,5905800000],
[1738,86900,51500000,409500000,5909200000],
[1739,86950,52000000,409500000,5912600000],
[1740,87000,52000000,410000000,5916000000],
[1741,87050,52000000,410500000,5919400000],
[1742,87100,52000000,411000000,5922800000],
[1743,87150,52000000,411500000,5926200000],
[1744,87200,52500000,412000000,5929600000],
[1745,87250,52500000,412500000,5933000000],
[1746,87300,52500000,413000000,5936400000],
[1747,87350,52500000,413500000,5939800000],
[1748,87400,53000000,414000000,5943200000],
[1749,87450,53000000,414500000,5946600000],
[1750,87500,53000000,414500000,5950000000],
[1751,87550,53000000,415000000,5953400000],
[1752,87600,53000000,415500000,5956800000],
[1753,87650,53500000,416000000,5960200000],
[1754,87700,53500000,416500000,5963600000],
[1755,87750,53500000,417000000,5967000000],
[1756,87800,53500000,417500000,5970400000],
[1757,87850,53500000,418000000,5973800000],
[1758,87900,54000000,418500000,5977200000],
[1759,87950,54000000,419000000,5980600000],
[1760,88000,54000000,419500000,5984000000],
[1761,88050,54000000,419500000,5987400000],
[1762,88100,54000000,420000000,5990800000],
[1763,88150,54500000,420500000,5994200000],
[1764,88200,54500000,421000000,5997600000],
[1765,88250,54500000,421500000,6001000000],
[1766,88300,54500000,422000000,6004400000],
[1767,88350,54500000,422500000,6007800000],
[1768,88400,55000000,423000000,6011200000],
[1769,88450,55000000,423500000,6014600000],
[1770,88500,55000000,424000000,6018000000],
[1771,88550,55000000,424500000,6021400000],
[1772,88600,55000000,424500000,6024800000],
[1773,88650,55500000,425000000,6028200000],
[1774,88700,55500000,425500000,6031600000],
[1775,88750,55500000,426000000,6035000000],
[1776,88800,55500000,426500000,6038400000],
[1777,88850,55500000,427000000,6041800000],
[1778,88900,56000000,427500000,6045200000],
[1779,88950,56000000,428000000,6048600000],
[1780,89000,56000000,428500000,6052000000],
[1781,89050,56000000,429000000,6055400000],
[1782,89100,56500000,429500000,6058800000],
[1783,89150,56500000,429500000,6062200000],
[1784,89200,56500000,430000000,6065600000],
[1785,89250,56500000,430500000,6069000000],
[1786,89300,56500000,431000000,6072400000],
[1787,89350,57000000,431500000,6075800000],
[1788,89400,57000000,432000000,6079200000],
[1789,89450,57000000,432500000,6082600000],
[1790,89500,57000000,433000000,6086000000],
[1791,89550,57000000,433500000,6089400000],
[1792,89600,57500000,434000000,6092800000],
[1793,89650,57500000,434500000,6096200000],
[1794,89700,57500000,434500000,6099600000],
[1795,89750,57500000,435000000,6103000000],
[1796,89800,57500000,435500000,6106400000],
[1797,89850,58000000,436000000,6109800000],
[1798,89900,58000000,436500000,6113200000],
[1799,89950,58000000,437000000,6116600000],
[1800,90000,58000000,437500000,6120000000],
[1801,90050,58000000,438000000,6123400000],
[1802,90100,58500000,438500000,6126800000],
[1803,90150,58500000,439000000,6130200000],
[1804,90200,58500000,439500000,6133600000],
[1805,90250,58500000,439500000,6137000000],
[1806,90300,58500000,440000000,6140400000],
[1807,90350,59000000,440500000,6143800000],
[1808,90400,59000000,441000000,6147200000],
[1809,90450,59000000,441500000,6150600000],
[1810,90500,59000000,442000000,6154000000],
[1811,90550,59500000,442500000,6157400000],
[1812,90600,59500000,443000000,6160800000],
[1813,90650,59500000,443500000,6164200000],
[1814,90700,59500000,444000000,6167600000],
[1815,90750,59500000,444500000,6171000000],
[1816,90800,60000000,444500000,6174400000],
[1817,90850,60000000,445000000,6177800000],
[1818,90900,60000000,445500000,6181200000],
[1819,90950,60000000,446000000,6184600000],
[1820,91000,60000000,446500000,6188000000],
[1821,91050,60500000,447000000,6191400000],
[1822,91100,60500000,447500000,6194800000],
[1823,91150,60500000,448000000,6198200000],
[1824,91200,60500000,448500000,6201600000],
[1825,91250,60500000,449000000,6205000000],
[1826,91300,61000000,449500000,6208400000],
[1827,91350,61000000,449500000,6211800000],
[1828,91400,61000000,450000000,6215200000],
[1829,91450,61000000,450500000,6218600000],
[1830,91500,61000000,451000000,6222000000],
[1831,91550,61500000,451500000,6225400000],
[1832,91600,61500000,452000000,6228800000],
[1833,91650,61500000,452500000,6232200000],
[1834,91700,61500000,453000000,6235600000],
[1835,91750,61500000,453500000,6239000000],
[1836,91800,62000000,454000000,6242400000],
[1837,91850,62000000,454500000,6245800000],
[1838,91900,62000000,454500000,6249200000],
[1839,91950,62000000,455000000,6252600000],
[1840,92000,62500000,455500000,6256000000],
[1841,92050,62500000,456000000,6259400000],
[1842,92100,62500000,456500000,6262800000],
[1843,92150,62500000,457000000,6266200000],
[1844,92200,62500000,457500000,6269600000],
[1845,92250,63000000,458000000,6273000000],
[1846,92300,63000000,458500000,6276400000],
[1847,92350,63000000,459000000,6279800000],
[1848,92400,63000000,459500000,6283200000],
[1849,92450,63000000,459500000,6286600000],
[1850,92500,63500000,460000000,6290000000],
[1851,92550,63500000,460500000,6293400000],
[1852,92600,63500000,461000000,6296800000],
[1853,92650,63500000,461500000,6300200000],
[1854,92700,63500000,462000000,6303600000],
[1855,92750,64000000,462500000,6307000000],
[1856,92800,64000000,463000000,6310400000],
[1857,92850,64000000,463500000,6313800000],
[1858,92900,64000000,464000000,6317200000],
[1859,92950,64000000,464500000,6320600000],
[1860,93000,64500000,464500000,6324000000],
[1861,93050,64500000,465000000,6327400000],
[1862,93100,64500000,465500000,6330800000],
[1863,93150,64500000,466000000,6334200000],
[1864,93200,64500000,466500000,6337600000],
[1865,93250,65000000,467000000,6341000000],
[1866,93300,65000000,467500000,6344400000],
[1867,93350,65000000,468000000,6347800000],
[1868,93400,65000000,468500000,6351200000],
[1869,93450,65000000,469000000,6354600000],
[1870,93500,65500000,469500000,6358000000],
[1871,93550,65500000,469500000,6361400000],
[1872,93600,65500000,470000000,6364800000],
[1873,93650,65500000,470500000,6368200000],
[1874,93700,65500000,471000000,6371600000],
[1875,93750,66000000,471500000,6375000000],
[1876,93800,66000000,472000000,6378400000],
[1877,93850,66000000,472500000,6381800000],
[1878,93900,66000000,473000000,6385200000],
[1879,93950,66000000,473500000,6388600000],
[1880,94000,66500000,474000000,6392000000],
[1881,94050,66500000,474500000,6395400000],
[1882,94100,66500000,474500000,6398800000],
[1883,94150,66500000,475000000,6402200000],
[1884,94200,66500000,475500000,6405600000],
[1885,94250,67000000,476000000,6409000000],
[1886,94300,67000000,476500000,6412400000],
[1887,94350,67000000,477000000,6415800000],
[1888,94400,67000000,477500000,6419200000],
[1889,94450,67000000,478000000,6422600000],
[1890,94500,67500000,478500000,6426000000],
[1891,94550,67500000,479000000,6429400000],
[1892,94600,67500000,479500000,6432800000],
[1893,94650,67500000,479500000,6436200000],
[1894,94700,67500000,480000000,6439600000],
[1895,94750,68000000,480500000,6443000000],
[1896,94800,68000000,481000000,6446400000],
[1897,94850,68000000,481500000,6449800000],
[1898,94900,68000000,482000000,6453200000],
[1899,94950,68000000,482500000,6456600000],
[1900,95000,68500000,483000000,6460000000],
[1901,95050,68500000,483500000,6463400000],
[1902,95100,68500000,484000000,6466800000],
[1903,95150,68500000,484500000,6470200000],
[1904,95200,68500000,484500000,6473600000],
[1905,95250,69000000,485000000,6477000000],
[1906,95300,69000000,485500000,6480400000],
[1907,95350,69000000,486000000,6483800000],
[1908,95400,69000000,486500000,6487200000],
[1909,95450,69000000,487000000,6490600000],
[1910,95500,69500000,487500000,6494000000],
[1911,95550,69500000,488000000,6497400000],
[1912,95600,69500000,488500000,6500800000],
[1913,95650,69500000,489000000,6504200000],
[1914,95700,69500000,489500000,6507600000],
[1915,95750,70000000,489500000,6511000000],
[1916,95800,70000000,490000000,6514400000],
[1917,95850,70000000,490500000,6517800000],
[1918,95900,70000000,491000000,6521200000],
[1919,95950,70000000,491500000,6524600000],
[1920,96000,70500000,492000000,6528000000],
[1921,96050,70500000,492500000,6531400000],
[1922,96100,70500000,493000000,6534800000],
[1923,96150,70500000,493500000,6538200000],
[1924,96200,70500000,494000000,6541600000],
[1925,96250,71000000,494500000,6545000000],
[1926,96300,71000000,494500000,6548400000],
[1927,96350,71000000,495000000,6551800000],
[1928,96400,71000000,495500000,6555200000],
[1929,96450,71000000,496000000,6558600000],
[1930,96500,71500000,496500000,6562000000],
[1931,96550,71500000,497000000,6565400000],
[1932,96600,71500000,497500000,6568800000],
[1933,96650,71500000,498000000,6572200000],
[1934,96700,71500000,498500000,6575600000],
[1935,96750,71500000,499000000,6579000000],
[1936,96800,72000000,499500000,6582400000],
[1937,96850,72000000,499500000,6585800000],
[1938,96900,72000000,500000000,6589200000],
[1939,96950,72000000,500500000,6592600000],
[1940,97000,72000000,501000000,6596000000],
[1941,97050,72500000,501500000,6599400000],
[1942,97100,72500000,502000000,6602800000],
[1943,97150,72500000,502500000,6606200000],
[1944,97200,72500000,503000000,6609600000],
[1945,97250,72500000,503500000,6613000000],
[1946,97300,73000000,504000000,6616400000],
[1947,97350,73000000,504500000,6619800000],
[1948,97400,73000000,504500000,6623200000],
[1949,97450,73000000,505000000,6626600000],
[1950,97500,73000000,505500000,6630000000],
[1951,97550,73500000,506000000,6633400000],
[1952,97600,73500000,506500000,6636800000],
[1953,97650,73500000,507000000,6640200000],
[1954,97700,73500000,507500000,6643600000],
[1955,97750,73500000,508000000,6647000000],
[1956,97800,74000000,508500000,6650400000],
[1957,97850,74000000,509000000,6653800000],
[1958,97900,74000000,509500000,6657200000],
[1959,97950,74000000,509500000,6660600000],
[1960,98000,74000000,510000000,6664000000],
[1961,98050,74500000,510500000,6667400000],
[1962,98100,74500000,511000000,6670800000],
[1963,98150,74500000,511500000,6674200000],
[1964,98200,74500000,512000000,6677600000],
[1965,98250,74500000,512500000,6681000000],
[1966,98300,74500000,513000000,6684400000],
[1967,98350,75000000,513500000,6687800000],
[1968,98400,75000000,514000000,6691200000],
[1969,98450,75000000,514500000,6694600000],
[1970,98500,75000000,514500000,6698000000],
[1971,98550,75000000,515000000,6701400000],
[1972,98600,75500000,515500000,6704800000],
[1973,98650,75500000,516000000,6708200000],
[1974,98700,75500000,516500000,6711600000],
[1975,98750,75500000,517000000,6715000000],
[1976,98800,75500000,517500000,6718400000],
[1977,98850,76000000,518000000,6721800000],
[1978,98900,76000000,518500000,6725200000],
[1979,98950,76000000,519000000,6728600000],
[1980,99000,76000000,519500000,6732000000],
[1981,99050,76000000,519500000,6735400000],
[1982,99100,76500000,520000000,6738800000],
[1983,99150,76500000,520500000,6742200000],
[1984,99200,76500000,521000000,6745600000],
[1985,99250,76500000,521500000,6749000000],
[1986,99300,76500000,522000000,6752400000],
[1987,99350,77000000,522500000,6755800000],
[1988,99400,77000000,523000000,6759200000],
[1989,99450,77000000,523500000,6762600000],
[1990,99500,77000000,524000000,6766000000],
[1991,99550,77000000,524500000,6769400000],
[1992,99600,77000000,524500000,6772800000],
[1993,99650,77500000,525000000,6776200000],
[1994,99700,77500000,525500000,6779600000],
[1995,99750,77500000,526000000,6783000000],
[1996,99800,77500000,526500000,6786400000],
[1997,99850,77500000,527000000,6789800000],
[1998,99900,78000000,527500000,6793200000],
[1999,99950,78000000,528000000,6796600000],
[2000,100000,78000000,528500000,6800000000],
[2001,100050,78000000,529000000,6803400000],
[2002,100100,78000000,529500000,6806800000],
[2003,100150,78500000,529500000,6810200000],
[2004,100200,78500000,530000000,6813600000],
[2005,100250,78500000,530500000,6817000000],
[2006,100300,78500000,531000000,6820400000],
[2007,100350,78500000,531500000,6823800000],
[2008,100400,79000000,532000000,6827200000],
[2009,100450,79000000,532500000,6830600000],
[2010,100500,79000000,533000000,6834000000],
[2011,100550,79000000,533500000,6837400000],
[2012,100600,79000000,534000000,6840800000],
[2013,100650,79500000,534500000,6844200000],
[2014,100700,79500000,534500000,6847600000],
[2015,100750,79500000,535000000,6851000000],
[2016,100800,79500000,535500000,6854400000],
[2017,100850,79500000,536000000,6857800000],
[2018,100900,79500000,536500000,6861200000],
[2019,100950,80000000,537000000,6864600000],
[2020,101000,80000000,537500000,6868000000],
[2021,101050,80000000,538000000,6871400000],
[2022,101100,80000000,538500000,6874800000],
[2023,101150,80000000,539000000,6878200000],
[2024,101200,80500000,539500000,6881600000],
[2025,101250,80500000,539500000,6885000000],
[2026,101300,80500000,540000000,6888400000],
[2027,101350,80500000,540500000,6891800000],
[2028,101400,80500000,541000000,6895200000],
[2029,101450,81000000,541500000,6898600000],
[2030,101500,81000000,542000000,6902000000],
[2031,101550,81000000,542500000,6905400000],
[2032,101600,81000000,543000000,6908800000],
[2033,101650,81000000,543500000,6912200000],
[2034,101700,81500000,544000000,6915600000],
[2035,101750,81500000,544500000,6919000000],
[2036,101800,81500000,544500000,6922400000],
[2037,101850,81500000,545000000,6925800000],
[2038,101900,81500000,545500000,6929200000],
[2039,101950,82000000,546000000,6932600000],
[2040,102000,82000000,546500000,6936000000],
[2041,102050,82000000,547000000,6939400000],
[2042,102100,82000000,547500000,6942800000],
[2043,102150,82000000,548000000,6946200000],
[2044,102200,82500000,548500000,6949600000],
[2045,102250,82500000,549000000,6953000000],
[2046,102300,82500000,549500000,6956400000],
[2047,102350,82500000,549500000,6959800000],
[2048,102400,82500000,550000000,6963200000],
[2049,102450,83000000,550500000,6966600000],
[2050,102500,83000000,551000000,6970000000],
[2051,102550,83000000,551500000,6973400000],
[2052,102600,83000000,552000000,6976800000],
[2053,102650,83000000,552500000,6980200000],
[2054,102700,83500000,553000000,6983600000],
[2055,102750,83500000,553500000,6987000000],
[2056,102800,83500000,554000000,6990400000],
[2057,102850,83500000,554500000,6993800000],
[2058,102900,83500000,554500000,6997200000],
[2059,102950,84000000,555000000,7000600000],
[2060,103000,84000000,555500000,7004000000],
[2061,103050,84000000,556000000,7007400000],
[2062,103100,84000000,556500000,7010800000],
[2063,103150,84000000,557000000,7014200000],
[2064,103200,84500000,557500000,7017600000],
[2065,103250,84500000,558000000,7021000000],
[2066,103300,84500000,558500000,7024400000],
[2067,103350,84500000,559000000,7027800000],
[2068,103400,84500000,559500000,7031200000],
[2069,103450,85000000,559500000,7034600000],
[2070,103500,85000000,560000000,7038000000],
[2071,103550,85000000,560500000,7041400000],
[2072,103600,85000000,561000000,7044800000],
[2073,103650,85000000,561500000,7048200000],
[2074,103700,85500000,562000000,7051600000],
[2075,103750,85500000,562500000,7055000000],
[2076,103800,85500000,563000000,7058400000],
[2077,103850,85500000,563500000,7061800000],
[2078,103900,85500000,564000000,7065200000],
[2079,103950,86000000,564500000,7068600000],
[2080,104000,86000000,564500000,7072000000],
[2081,104050,86000000,565000000,7075400000],
[2082,104100,86000000,565500000,7078800000],
[2083,104150,86500000,566000000,7082200000],
[2084,104200,86500000,566500000,7085600000],
[2085,104250,86500000,567000000,7089000000],
[2086,104300,86500000,567500000,7092400000],
[2087,104350,86500000,568000000,7095800000],
[2088,104400,87000000,568500000,7099200000],
[2089,104450,87000000,569000000,7102600000],
[2090,104500,87000000,569500000,7106000000],
[2091,104550,87000000,569500000,7109400000],
[2092,104600,87000000,570000000,7112800000],
[2093,104650,87500000,570500000,7116200000],
[2094,104700,87500000,571000000,7119600000],
[2095,104750,87500000,571500000,7123000000],
[2096,104800,87500000,572000000,7126400000],
[2097,104850,88000000,572500000,7129800000],
[2098,104900,88000000,573000000,7133200000],
[2099,104950,88000000,573500000,7136600000],
[2100,105000,88000000,574000000,7140000000],
[2101,105050,88000000,574500000,7143400000],
[2102,105100,88500000,574500000,7146800000],
[2103,105150,88500000,575000000,7150200000],
[2104,105200,88500000,575500000,7153600000],
[2105,105250,88500000,576000000,7157000000],
[2106,105300,89000000,576500000,7160400000],
[2107,105350,89000000,577000000,7163800000],
[2108,105400,89000000,577500000,7167200000],
[2109,105450,89000000,578000000,7170600000],
[2110,105500,89000000,578500000,7174000000],
[2111,105550,89500000,579000000,7177400000],
[2112,105600,89500000,579500000,7180800000],
[2113,105650,89500000,579500000,7184200000],
[2114,105700,89500000,580000000,7187600000],
[2115,105750,90000000,580500000,7191000000],
[2116,105800,90000000,581000000,7194400000],
[2117,105850,90000000,581500000,7197800000],
[2118,105900,90000000,582000000,7201200000],
[2119,105950,90500000,582500000,7204600000],
[2120,106000,90500000,583000000,7208000000],
[2121,106050,90500000,583500000,7211400000],
[2122,106100,90500000,584000000,7214800000],
[2123,106150,90500000,584500000,7218200000],
[2124,106200,91000000,584500000,7221600000],
[2125,106250,91000000,585000000,7225000000],
[2126,106300,91000000,585500000,7228400000],
[2127,106350,91000000,586000000,7231800000],
[2128,106400,91500000,586500000,7235200000],
[2129,106450,91500000,587000000,7238600000],
[2130,106500,91500000,587500000,7242000000],
[2131,106550,91500000,588000000,7245400000],
[2132,106600,92000000,588500000,7248800000],
[2133,106650,92000000,589000000,7252200000],
[2134,106700,92000000,589500000,7255600000],
[2135,106750,92000000,589500000,7259000000],
[2136,106800,92500000,590000000,7262400000],
[2137,106850,92500000,590500000,7265800000],
[2138,106900,92500000,591000000,7269200000],
[2139,106950,93000000,591500000,7272600000],
[2140,107000,93000000,592000000,7276000000],
[2141,107050,93000000,592500000,7279400000],
[2142,107100,93000000,593000000,7282800000],
[2143,107150,93500000,593500000,7286200000],
[2144,107200,93500000,594000000,7289600000],
[2145,107250,93500000,594500000,7293000000],
[2146,107300,93500000,594500000,7296400000],
[2147,107350,94000000,595000000,7299800000],
[2148,107400,94000000,595500000,7303200000],
[2149,107450,94000000,596000000,7306600000],
[2150,107500,94500000,596500000,7310000000],
[2151,107550,94500000,597000000,7313400000],
[2152,107600,94500000,597500000,7316800000],
[2153,107650,94500000,598000000,7320200000],
[2154,107700,95000000,598500000,7323600000],
[2155,107750,95000000,599000000,7327000000],
[2156,107800,95000000,599500000,7330400000],
[2157,107850,95500000,599500000,7333800000],
[2158,107900,95500000,600000000,7337200000],
[2159,107950,95500000,600500000,7340600000],
[2160,108000,96000000,601000000,7344000000],
[2161,108050,96000000,601500000,7347400000],
[2162,108100,96000000,602000000,7350800000],
[2163,108150,96000000,602500000,7354200000],
[2164,108200,96500000,603000000,7357600000],
[2165,108250,96500000,603500000,7361000000],
[2166,108300,96500000,604000000,7364400000],
[2167,108350,97000000,604500000,7367800000],
[2168,108400,97000000,604500000,7371200000],
[2169,108450,97000000,605000000,7374600000],
[2170,108500,97500000,605500000,7378000000],
[2171,108550,97500000,606000000,7381400000],
[2172,108600,97500000,606500000,7384800000],
[2173,108650,98000000,607000000,7388200000],
[2174,108700,98000000,607500000,7391600000],
[2175,108750,98000000,608000000,7395000000],
[2176,108800,98000000,608500000,7398400000],
[2177,108850,98500000,609000000,7401800000],
[2178,108900,98500000,609500000,7405200000],
[2179,108950,98500000,609500000,7408600000],
[2180,109000,99000000,610000000,7412000000],
[2181,109050,99000000,610500000,7415400000],
[2182,109100,99000000,611000000,7418800000],
[2183,109150,99500000,611500000,7422200000],
[2184,109200,99500000,612000000,7425600000],
[2185,109250,99500000,612500000,7429000000],
[2186,109300,99500000,613000000,7432400000],
[2187,109350,100000000,613500000,7435800000],
[2188,109400,100000000,614000000,7439200000],
[2189,109450,100000000,614500000,7442600000],
[2190,109500,100500000,614500000,7446000000],
[2191,109550,100500000,615000000,7449400000],
[2192,109600,100500000,615500000,7452800000],
[2193,109650,101000000,616000000,7456200000],
[2194,109700,101000000,616500000,7459600000],
[2195,109750,101000000,617000000,7463000000],
[2196,109800,101000000,617500000,7466400000],
[2197,109850,101500000,618000000,7469800000],
[2198,109900,101500000,618500000,7473200000],
[2199,109950,101500000,619000000,7476600000],
[2200,110000,102000000,619500000,7480000000],
[2201,110050,102000000,619500000,7483400000],
[2202,110100,102000000,620000000,7486800000],
[2203,110150,102500000,620500000,7490200000],
[2204,110200,102500000,621000000,7493600000],
[2205,110250,102500000,621500000,7497000000],
[2206,110300,103000000,622000000,7500400000],
[2207,110350,103000000,622500000,7503800000],
[2208,110400,103000000,623000000,7507200000],
[2209,110450,103000000,623500000,7510600000],
[2210,110500,103500000,624000000,7514000000],
[2211,110550,103500000,624500000,7517400000],
[2212,110600,103500000,624500000,7520800000],
[2213,110650,104000000,625000000,7524200000],
[2214,110700,104000000,625500000,7527600000],
[2215,110750,104000000,626000000,7531000000],
[2216,110800,104500000,626500000,7534400000],
[2217,110850,104500000,627000000,7537800000],
[2218,110900,104500000,627500000,7541200000],
[2219,110950,104500000,628000000,7544600000],
[2220,111000,105000000,628500000,7548000000],
[2221,111050,105000000,629000000,7551400000],
[2222,111100,105000000,629500000,7554800000],
[2223,111150,105500000,629500000,7558200000],
[2224,111200,105500000,630000000,7561600000],
[2225,111250,105500000,630500000,7565000000],
[2226,111300,106000000,631000000,7568400000],
[2227,111350,106000000,631500000,7571800000],
[2228,111400,106000000,632000000,7575200000],
[2229,111450,106000000,632500000,7578600000],
[2230,111500,106500000,633000000,7582000000],
[2231,111550,106500000,633500000,7585400000],
[2232,111600,106500000,634000000,7588800000],
[2233,111650,107000000,634500000,7592200000],
[2234,111700,107000000,634500000,7595600000],
[2235,111750,107000000,635000000,7599000000],
[2236,111800,107500000,635500000,7602400000],
[2237,111850,107500000,636000000,7605800000],
[2238,111900,107500000,636500000,7609200000],
[2239,111950,108000000,637000000,7612600000],
[2240,112000,108000000,637500000,7616000000],
[2241,112050,108000000,638000000,7619400000],
[2242,112100,108000000,638500000,7622800000],
[2243,112150,108500000,639000000,7626200000],
[2244,112200,108500000,639500000,7629600000],
[2245,112250,108500000,639500000,7633000000],
[2246,112300,109000000,640000000,7636400000],
[2247,112350,109000000,640500000,7639800000],
[2248,112400,109000000,641000000,7643200000],
[2249,112450,109500000,641500000,7646600000],
[2250,112500,109500000,642000000,7650000000],
[2251,112550,109500000,642500000,7653400000],
[2252,112600,109500000,643000000,7656800000],
[2253,112650,110000000,643500000,7660200000],
[2254,112700,110000000,644000000,7663600000],
[2255,112750,110000000,644500000,7667000000],
[2256,112800,110500000,644500000,7670400000],
[2257,112850,110500000,645000000,7673800000],
[2258,112900,110500000,645500000,7677200000],
[2259,112950,111000000,646000000,7680600000],
[2260,113000,111000000,646500000,7684000000],
[2261,113050,111000000,647000000,7687400000],
[2262,113100,111000000,647500000,7690800000],
[2263,113150,111500000,648000000,7694200000],
[2264,113200,111500000,648500000,7697600000],
[2265,113250,111500000,649000000,7701000000],
[2266,113300,112000000,649500000,7704400000],
[2267,113350,112000000,649500000,7707800000],
[2268,113400,112000000,650000000,7711200000],
[2269,113450,112500000,650500000,7714600000],
[2270,113500,112500000,651000000,7718000000],
[2271,113550,112500000,651500000,7721400000],
[2272,113600,113000000,652000000,7724800000],
[2273,113650,113000000,652500000,7728200000],
[2274,113700,113000000,653000000,7731600000],
[2275,113750,113000000,653500000,7735000000],
[2276,113800,113500000,654000000,7738400000],
[2277,113850,113500000,654500000,7741800000],
[2278,113900,113500000,654500000,7745200000],
[2279,113950,114000000,655000000,7748600000],
[2280,114000,114000000,655500000,7752000000],
[2281,114050,114000000,656000000,7755400000],
[2282,114100,114500000,656500000,7758800000],
[2283,114150,114500000,657000000,7762200000],
[2284,114200,114500000,657500000,7765600000],
[2285,114250,114500000,658000000,7769000000],
[2286,114300,115000000,658500000,7772400000],
[2287,114350,115000000,659000000,7775800000],
[2288,114400,115000000,659500000,7779200000],
[2289,114450,115500000,659500000,7782600000],
[2290,114500,115500000,660000000,7786000000],
[2291,114550,115500000,660500000,7789400000],
[2292,114600,116000000,661000000,7792800000],
[2293,114650,116000000,661500000,7796200000],
[2294,114700,116000000,662000000,7799600000],
[2295,114750,116000000,662500000,7803000000],
[2296,114800,116500000,663000000,7806400000],
[2297,114850,116500000,663500000,7809800000],
[2298,114900,116500000,664000000,7813200000],
[2299,114950,117000000,664500000,7816600000],
[2300,115000,117000000,664500000,7820000000],
[2301,115050,117000000,665000000,7823400000],
[2302,115100,117500000,665500000,7826800000],
[2303,115150,117500000,666000000,7830200000],
[2304,115200,117500000,666500000,7833600000],
[2305,115250,118000000,667000000,7837000000],
[2306,115300,118000000,667500000,7840400000],
[2307,115350,118000000,668000000,7843800000],
[2308,115400,118000000,668500000,7847200000],
[2309,115450,118500000,669000000,7850600000],
[2310,115500,118500000,669500000,7854000000],
[2311,115550,118500000,669500000,7857400000],
[2312,115600,119000000,670000000,7860800000],
[2313,115650,119000000,670500000,7864200000],
[2314,115700,119000000,671000000,7867600000],
[2315,115750,119500000,671500000,7871000000],
[2316,115800,119500000,672000000,7874400000],
[2317,115850,119500000,672500000,7877800000],
[2318,115900,119500000,673000000,7881200000],
[2319,115950,120000000,673500000,7884600000],
[2320,116000,120000000,674000000,7888000000],
[2321,116050,120000000,674500000,7891400000],
[2322,116100,120500000,674500000,7894800000],
[2323,116150,120500000,675000000,7898200000],
[2324,116200,120500000,675500000,7901600000],
[2325,116250,121000000,676000000,7905000000],
[2326,116300,121000000,676500000,7908400000],
[2327,116350,121000000,677000000,7911800000],
[2328,116400,121000000,677500000,7915200000],
[2329,116450,121500000,678000000,7918600000],
[2330,116500,121500000,678500000,7922000000],
[2331,116550,121500000,679000000,7925400000],
[2332,116600,122000000,679500000,7928800000],
[2333,116650,122000000,679500000,7932200000],
[2334,116700,122000000,680000000,7935600000],
[2335,116750,122500000,680500000,7939000000],
[2336,116800,122500000,681000000,7942400000],
[2337,116850,122500000,681500000,7945800000],
[2338,116900,123000000,682000000,7949200000],
[2339,116950,123000000,682500000,7952600000],
[2340,117000,123000000,683000000,7956000000],
[2341,117050,123000000,683500000,7959400000],
[2342,117100,123500000,684000000,7962800000],
[2343,117150,123500000,684500000,7966200000],
[2344,117200,123500000,684500000,7969600000],
[2345,117250,124000000,685000000,7973000000],
[2346,117300,124000000,685500000,7976400000],
[2347,117350,124000000,686000000,7979800000],
[2348,117400,124500000,686500000,7983200000],
[2349,117450,124500000,687000000,7986600000],
[2350,117500,124500000,687500000,7990000000],
[2351,117550,124500000,688000000,7993400000],
[2352,117600,125000000,688500000,7996800000],
[2353,117650,125000000,689000000,8000200000],
[2354,117700,125000000,689500000,8003600000],
[2355,117750,125500000,689500000,8007000000],
[2356,117800,125500000,690000000,8010400000],
[2357,117850,125500000,690500000,8013800000],
[2358,117900,126000000,691000000,8017200000],
[2359,117950,126000000,691500000,8020600000],
[2360,118000,126000000,692000000,8024000000],
[2361,118050,126000000,692500000,8027400000],
[2362,118100,126500000,693000000,8030800000],
[2363,118150,126500000,693500000,8034200000],
[2364,118200,126500000,694000000,8037600000],
[2365,118250,127000000,694500000,8041000000],
[2366,118300,127000000,694500000,8044400000],
[2367,118350,127000000,695000000,8047800000],
[2368,118400,127500000,695500000,8051200000],
[2369,118450,127500000,696000000,8054600000],
[2370,118500,127500000,696500000,8058000000],
[2371,118550,128000000,697000000,8061400000],
[2372,118600,128000000,697500000,8064800000],
[2373,118650,128000000,698000000,8068200000],
[2374,118700,128000000,698500000,8071600000],
[2375,118750,128500000,699000000,8075000000],
[2376,118800,128500000,699500000,8078400000],
[2377,118850,128500000,699500000,8081800000],
[2378,118900,129000000,700000000,8085200000],
[2379,118950,129000000,700500000,8088600000],
[2380,119000,129000000,701000000,8092000000],
[2381,119050,129500000,701500000,8095400000],
[2382,119100,129500000,702000000,8098800000],
[2383,119150,129500000,702500000,8102200000],
[2384,119200,129500000,703000000,8105600000],
[2385,119250,130000000,703500000,8109000000],
[2386,119300,130000000,704000000,8112400000],
[2387,119350,130000000,704500000,8115800000],
[2388,119400,130500000,704500000,8119200000],
[2389,119450,130500000,705000000,8122600000],
[2390,119500,130500000,705500000,8126000000],
[2391,119550,131000000,706000000,8129400000],
[2392,119600,131000000,706500000,8132800000],
[2393,119650,131000000,707000000,8136200000],
[2394,119700,131000000,707500000,8139600000],
[2395,119750,131500000,708000000,8143000000],
[2396,119800,131500000,708500000,8146400000],
[2397,119850,131500000,709000000,8149800000],
[2398,119900,132000000,709500000,8153200000],
[2399,119950,132000000,709500000,8156600000],
[2400,120000,132000000,710000000,8160000000],
[2401,120050,132500000,710500000,8163400000],
[2402,120100,132500000,711000000,8166800000],
[2403,120150,132500000,711500000,8170200000],
[2404,120200,133000000,712000000,8173600000],
[2405,120250,133000000,712500000,8177000000],
[2406,120300,133000000,713000000,8180400000],
[2407,120350,133000000,713500000,8183800000],
[2408,120400,133500000,714000000,8187200000],
[2409,120450,133500000,714500000,8190600000],
[2410,120500,133500000,714500000,8194000000],
[2411,120550,134000000,715000000,8197400000],
[2412,120600,134000000,715500000,8200800000],
[2413,120650,134000000,716000000,8204200000],
[2414,120700,134500000,716500000,8207600000],
[2415,120750,134500000,717000000,8211000000],
[2416,120800,134500000,717500000,8214400000],
[2417,120850,134500000,718000000,8217800000],
[2418,120900,135000000,718500000,8221200000],
[2419,120950,135000000,719000000,8224600000],
[2420,121000,135000000,719500000,8228000000],
[2421,121050,135500000,719500000,8231400000],
[2422,121100,135500000,720000000,8234800000],
[2423,121150,135500000,720500000,8238200000],
[2424,121200,136000000,721000000,8241600000],
[2425,121250,136000000,721500000,8245000000],
[2426,121300,136000000,722000000,8248400000],
[2427,121350,136000000,722500000,8251800000],
[2428,121400,136500000,723000000,8255200000],
[2429,121450,136500000,723500000,8258600000],
[2430,121500,136500000,724000000,8262000000],
[2431,121550,137000000,724500000,8265400000],
[2432,121600,137000000,724500000,8268800000],
[2433,121650,137000000,725000000,8272200000],
[2434,121700,137500000,725500000,8275600000],
[2435,121750,137500000,726000000,8279000000],
[2436,121800,137500000,726500000,8282400000],
[2437,121850,138000000,727000000,8285800000],
[2438,121900,138000000,727500000,8289200000],
[2439,121950,138000000,728000000,8292600000],
[2440,122000,138000000,728500000,8296000000],
[2441,122050,138500000,729000000,8299400000],
[2442,122100,138500000,729500000,8302800000],
[2443,122150,138500000,729500000,8306200000],
[2444,122200,139000000,730000000,8309600000],
[2445,122250,139000000,730500000,8313000000],
[2446,122300,139000000,731000000,8316400000],
[2447,122350,139500000,731500000,8319800000],
[2448,122400,139500000,732000000,8323200000],
[2449,122450,139500000,732500000,8326600000],
[2450,122500,139500000,733000000,8330000000],
[2451,122550,140000000,733500000,8333400000],
[2452,122600,140000000,734000000,8336800000],
[2453,122650,140000000,734500000,8340200000],
[2454,122700,140500000,734500000,8343600000],
[2455,122750,140500000,735000000,8347000000],
[2456,122800,140500000,735500000,8350400000],
[2457,122850,141000000,736000000,8353800000],
[2458,122900,141000000,736500000,8357200000],
[2459,122950,141000000,737000000,8360600000],
[2460,123000,141000000,737500000,8364000000],
[2461,123050,141500000,738000000,8367400000],
[2462,123100,141500000,738500000,8370800000],
[2463,123150,141500000,739000000,8374200000],
[2464,123200,142000000,739500000,8377600000],
[2465,123250,142000000,739500000,8381000000],
[2466,123300,142000000,740000000,8384400000],
[2467,123350,142500000,740500000,8387800000],
[2468,123400,142500000,741000000,8391200000],
[2469,123450,142500000,741500000,8394600000],
[2470,123500,143000000,742000000,8398000000],
[2471,123550,143000000,742500000,8401400000],
[2472,123600,143000000,743000000,8404800000],
[2473,123650,143000000,743500000,8408200000],
[2474,123700,143500000,744000000,8411600000],
[2475,123750,143500000,744500000,8415000000],
[2476,123800,143500000,744500000,8418400000],
[2477,123850,144000000,745000000,8421800000],
[2478,123900,144000000,745500000,8425200000],
[2479,123950,144000000,746000000,8428600000],
[2480,124000,144500000,746500000,8432000000],
[2481,124050,144500000,747000000,8435400000],
[2482,124100,144500000,747500000,8438800000],
[2483,124150,144500000,748000000,8442200000],
[2484,124200,145000000,748500000,8445600000],
[2485,124250,145000000,749000000,8449000000],
[2486,124300,145000000,749500000,8452400000],
[2487,124350,145500000,749500000,8455800000],
[2488,124400,145500000,750000000,8459200000],
[2489,124450,145500000,750500000,8462600000],
[2490,124500,146000000,751000000,8466000000],
[2491,124550,146000000,751500000,8469400000],
[2492,124600,146000000,752000000,8472800000],
[2493,124650,146000000,752500000,8476200000],
[2494,124700,146500000,753000000,8479600000],
[2495,124750,146500000,753500000,8483000000],
[2496,124800,146500000,754000000,8486400000],
[2497,124850,147000000,754500000,8489800000],
[2498,124900,147000000,754500000,8493200000],
[2499,124950,147000000,755000000,8496600000],
[2500,125000,147500000,755500000,8500000000],
[2501,125050,147500000,756000000,8503400000],
[2502,125100,147500000,756500000,8506800000],
[2503,125150,148000000,757000000,8510200000],
[2504,125200,148000000,757500000,8513600000],
[2505,125250,148000000,758000000,8517000000],
[2506,125300,148000000,758500000,8520400000],
[2507,125350,148500000,759000000,8523800000],
[2508,125400,148500000,759500000,8527200000],
[2509,125450,148500000,759500000,8530600000],
[2510,125500,149000000,760000000,8534000000],
[2511,125550,149000000,760500000,8537400000],
[2512,125600,149000000,761000000,8540800000],
[2513,125650,149500000,761500000,8544200000],
[2514,125700,149500000,762000000,8547600000],
[2515,125750,149500000,762500000,8551000000],
[2516,125800,149500000,763000000,8554400000],
[2517,125850,150000000,763500000,8557800000],
[2518,125900,150000000,764000000,8561200000],
[2519,125950,150000000,764500000,8564600000],
[2520,126000,150500000,764500000,8568000000],
[2521,126050,150500000,765000000,8571400000],
[2522,126100,150500000,765500000,8574800000],
[2523,126150,151000000,766000000,8578200000],
[2524,126200,151000000,766500000,8581600000],
[2525,126250,151000000,767000000,8585000000],
[2526,126300,151000000,767500000,8588400000],
[2527,126350,151500000,768000000,8591800000],
[2528,126400,151500000,768500000,8595200000],
[2529,126450,151500000,769000000,8598600000],
[2530,126500,152000000,769500000,8602000000],
[2531,126550,152000000,769500000,8605400000],
[2532,126600,152000000,770000000,8608800000],
[2533,126650,152500000,770500000,8612200000],
[2534,126700,152500000,771000000,8615600000],
[2535,126750,152500000,771500000,8619000000],
[2536,126800,153000000,772000000,8622400000],
[2537,126850,153000000,772500000,8625800000],
[2538,126900,153000000,773000000,8629200000],
[2539,126950,153000000,773500000,8632600000],
[2540,127000,153500000,774000000,8636000000],
[2541,127050,153500000,774500000,8639400000],
[2542,127100,153500000,774500000,8642800000],
[2543,127150,154000000,775000000,8646200000],
[2544,127200,154000000,775500000,8649600000],
[2545,127250,154000000,776000000,8653000000],
[2546,127300,154500000,776500000,8656400000],
[2547,127350,154500000,777000000,8659800000],
[2548,127400,154500000,777500000,8663200000],
[2549,127450,154500000,778000000,8666600000],
[2550,127500,155000000,778500000,8670000000],
[2551,127550,155000000,779000000,8673400000],
[2552,127600,155000000,779500000,8676800000],
[2553,127650,155500000,779500000,8680200000],
[2554,127700,155500000,780000000,8683600000],
[2555,127750,155500000,780500000,8687000000],
[2556,127800,156000000,781000000,8690400000],
[2557,127850,156000000,781500000,8693800000],
[2558,127900,156000000,782000000,8697200000],
[2559,127950,156000000,782500000,8700600000],
[2560,128000,156500000,783000000,8704000000],
[2561,128050,156500000,783500000,8707400000],
[2562,128100,156500000,784000000,8710800000],
[2563,128150,157000000,784500000,8714200000],
[2564,128200,157000000,784500000,8717600000],
[2565,128250,157000000,785000000,8721000000],
[2566,128300,157500000,785500000,8724400000],
[2567,128350,157500000,786000000,8727800000],
[2568,128400,157500000,786500000,8731200000],
[2569,128450,158000000,787000000,8734600000],
[2570,128500,158000000,787500000,8738000000],
[2571,128550,158000000,788000000,8741400000],
[2572,128600,158000000,788500000,8744800000],
[2573,128650,158500000,789000000,8748200000],
[2574,128700,158500000,789500000,8751600000],
[2575,128750,158500000,789500000,8755000000],
[2576,128800,159000000,790000000,8758400000],
[2577,128850,159000000,790500000,8761800000],
[2578,128900,159000000,791000000,8765200000],
[2579,128950,159500000,791500000,8768600000],
[2580,129000,159500000,792000000,8772000000],
[2581,129050,159500000,792500000,8775400000],
[2582,129100,159500000,793000000,8778800000],
[2583,129150,160000000,793500000,8782200000],
[2584,129200,160000000,794000000,8785600000],
[2585,129250,160000000,794500000,8789000000],
[2586,129300,160500000,794500000,8792400000],
[2587,129350,160500000,795000000,8795800000],
[2588,129400,160500000,795500000,8799200000],
[2589,129450,161000000,796000000,8802600000],
[2590,129500,161000000,796500000,8806000000],
[2591,129550,161000000,797000000,8809400000],
[2592,129600,161000000,797500000,8812800000],
[2593,129650,161500000,798000000,8816200000],
[2594,129700,161500000,798500000,8819600000],
[2595,129750,161500000,799000000,8823000000],
[2596,129800,162000000,799500000,8826400000],
[2597,129850,162000000,799500000,8829800000],
[2598,129900,162000000,800000000,8833200000],
[2599,129950,162500000,800500000,8836600000],
[2600,130000,162500000,801000000,8840000000],
[2601,130050,162500000,801500000,8843400000],
[2602,130100,163000000,802000000,8846800000],
[2603,130150,163000000,802500000,8850200000],
[2604,130200,163000000,803000000,8853600000],
[2605,130250,163000000,803500000,8857000000],
[2606,130300,163500000,804000000,8860400000],
[2607,130350,163500000,804500000,8863800000],
[2608,130400,163500000,804500000,8867200000],
[2609,130450,164000000,805000000,8870600000],
[2610,130500,164000000,805500000,8874000000],
[2611,130550,164000000,806000000,8877400000],
[2612,130600,164500000,806500000,8880800000],
[2613,130650,164500000,807000000,8884200000],
[2614,130700,164500000,807500000,8887600000],
[2615,130750,164500000,808000000,8891000000],
[2616,130800,165000000,808500000,8894400000],
[2617,130850,165000000,809000000,8897800000],
[2618,130900,165000000,809500000,8901200000],
[2619,130950,165500000,809500000,8904600000],
[2620,131000,165500000,810000000,8908000000],
[2621,131050,165500000,810500000,8911400000],
[2622,131100,166000000,811000000,8914800000],
[2623,131150,166000000,811500000,8918200000],
[2624,131200,166000000,812000000,8921600000],
[2625,131250,166000000,812500000,8925000000],
[2626,131300,166500000,813000000,8928400000],
[2627,131350,166500000,813500000,8931800000],
[2628,131400,166500000,814000000,8935200000],
[2629,131450,167000000,814500000,8938600000],
[2630,131500,167000000,814500000,8942000000],
[2631,131550,167000000,815000000,8945400000],
[2632,131600,167500000,815500000,8948800000],
[2633,131650,167500000,816000000,8952200000],
[2634,131700,167500000,816500000,8955600000],
[2635,131750,168000000,817000000,8959000000],
[2636,131800,168000000,817500000,8962400000],
[2637,131850,168000000,818000000,8965800000],
[2638,131900,168000000,818500000,8969200000],
[2639,131950,168500000,819000000,8972600000],
[2640,132000,168500000,819500000,8976000000],
[2641,132050,168500000,819500000,8979400000],
[2642,132100,169000000,820000000,8982800000],
[2643,132150,169000000,820500000,8986200000],
[2644,132200,169000000,821000000,8989600000],
[2645,132250,169500000,821500000,8993000000],
[2646,132300,169500000,822000000,8996400000],
[2647,132350,169500000,822500000,8999800000],
[2648,132400,169500000,823000000,9003200000],
[2649,132450,170000000,823500000,9006600000],
[2650,132500,170000000,824000000,9010000000],
[2651,132550,170000000,824500000,9013400000],
[2652,132600,170500000,824500000,9016800000],
[2653,132650,170500000,825000000,9020200000],
[2654,132700,170500000,825500000,9023600000],
[2655,132750,171000000,826000000,9027000000],
[2656,132800,171000000,826500000,9030400000],
[2657,132850,171000000,827000000,9033800000],
[2658,132900,171000000,827500000,9037200000],
[2659,132950,171500000,828000000,9040600000],
[2660,133000,171500000,828500000,9044000000],
[2661,133050,171500000,829000000,9047400000],
[2662,133100,172000000,829500000,9050800000],
[2663,133150,172000000,829500000,9054200000],
[2664,133200,172000000,830000000,9057600000],
[2665,133250,172500000,830500000,9061000000],
[2666,133300,172500000,831000000,9064400000],
[2667,133350,172500000,831500000,9067800000],
[2668,133400,173000000,832000000,9071200000],
[2669,133450,173000000,832500000,9074600000],
[2670,133500,173000000,833000000,9078000000],
[2671,133550,173000000,833500000,9081400000],
[2672,133600,173500000,834000000,9084800000],
[2673,133650,173500000,834500000,9088200000],
[2674,133700,173500000,834500000,9091600000],
[2675,133750,174000000,835000000,9095000000],
[2676,133800,174000000,835500000,9098400000],
[2677,133850,174000000,836000000,9101800000],
[2678,133900,174500000,836500000,9105200000],
[2679,133950,174500000,837000000,9108600000],
[2680,134000,174500000,837500000,9112000000],
[2681,134050,174500000,838000000,9115400000],
[2682,134100,175000000,838500000,9118800000],
[2683,134150,175000000,839000000,9122200000],
[2684,134200,175000000,839500000,9125600000],
[2685,134250,175500000,839500000,9129000000],
[2686,134300,175500000,840000000,9132400000],
[2687,134350,175500000,840500000,9135800000],
[2688,134400,176000000,841000000,9139200000],
[2689,134450,176000000,841500000,9142600000],
[2690,134500,176000000,842000000,9146000000],
[2691,134550,176000000,842500000,9149400000],
[2692,134600,176500000,843000000,9152800000],
[2693,134650,176500000,843500000,9156200000],
[2694,134700,176500000,844000000,9159600000],
[2695,134750,177000000,844500000,9163000000],
[2696,134800,177000000,844500000,9166400000],
[2697,134850,177000000,845000000,9169800000],
[2698,134900,177500000,845500000,9173200000],
[2699,134950,177500000,846000000,9176600000],
[2700,135000,177500000,846500000,9180000000],
[2701,135050,178000000,847000000,9183400000],
[2702,135100,178000000,847500000,9186800000],
[2703,135150,178000000,848000000,9190200000],
[2704,135200,178000000,848500000,9193600000],
[2705,135250,178500000,849000000,9197000000],
[2706,135300,178500000,849500000,9200400000],
[2707,135350,178500000,849500000,9203800000],
[2708,135400,179000000,850000000,9207200000],
[2709,135450,179000000,850500000,9210600000],
[2710,135500,179000000,851000000,9214000000],
[2711,135550,179500000,851500000,9217400000],
[2712,135600,179500000,852000000,9220800000],
[2713,135650,179500000,852500000,9224200000],
[2714,135700,179500000,853000000,9227600000],
[2715,135750,180000000,853500000,9231000000],
[2716,135800,180000000,854000000,9234400000],
[2717,135850,180000000,854500000,9237800000],
[2718,135900,180500000,854500000,9241200000],
[2719,135950,180500000,855000000,9244600000],
[2720,136000,180500000,855500000,9248000000],
[2721,136050,181000000,856000000,9251400000],
[2722,136100,181000000,856500000,9254800000],
[2723,136150,181000000,857000000,9258200000],
[2724,136200,181000000,857500000,9261600000],
[2725,136250,181500000,858000000,9265000000],
[2726,136300,181500000,858500000,9268400000],
[2727,136350,181500000,859000000,9271800000],
[2728,136400,182000000,859500000,9275200000],
[2729,136450,182000000,859500000,9278600000],
[2730,136500,182000000,860000000,9282000000],
[2731,136550,182500000,860500000,9285400000],
[2732,136600,182500000,861000000,9288800000],
[2733,136650,182500000,861500000,9292200000],
[2734,136700,183000000,862000000,9295600000],
[2735,136750,183000000,862500000,9299000000],
[2736,136800,183000000,863000000,9302400000],
[2737,136850,183000000,863500000,9305800000],
[2738,136900,183500000,864000000,9309200000],
[2739,136950,183500000,864500000,9312600000],
[2740,137000,183500000,864500000,9316000000],
[2741,137050,184000000,865000000,9319400000],
[2742,137100,184000000,865500000,9322800000],
[2743,137150,184000000,866000000,9326200000],
[2744,137200,184500000,866500000,9329600000],
[2745,137250,184500000,867000000,9333000000],
[2746,137300,184500000,867500000,9336400000],
[2747,137350,184500000,868000000,9339800000],
[2748,137400,185000000,868500000,9343200000],
[2749,137450,185000000,869000000,9346600000],
[2750,137500,185000000,869500000,9350000000],
[2751,137550,185500000,869500000,9353400000],
[2752,137600,185500000,870000000,9356800000],
[2753,137650,185500000,870500000,9360200000],
[2754,137700,186000000,871000000,9363600000],
[2755,137750,186000000,871500000,9367000000],
[2756,137800,186000000,872000000,9370400000],
[2757,137850,186000000,872500000,9373800000],
[2758,137900,186500000,873000000,9377200000],
[2759,137950,186500000,873500000,9380600000],
[2760,138000,186500000,874000000,9384000000],
[2761,138050,187000000,874500000,9387400000],
[2762,138100,187000000,874500000,9390800000],
[2763,138150,187000000,875000000,9394200000],
[2764,138200,187500000,875500000,9397600000],
[2765,138250,187500000,876000000,9401000000],
[2766,138300,187500000,876500000,9404400000],
[2767,138350,188000000,877000000,9407800000],
[2768,138400,188000000,877500000,9411200000],
[2769,138450,188000000,878000000,9414600000],
[2770,138500,188000000,878500000,9418000000],
[2771,138550,188500000,879000000,9421400000],
[2772,138600,188500000,879500000,9424800000],
[2773,138650,188500000,879500000,9428200000],
[2774,138700,189000000,880000000,9431600000],
[2775,138750,189000000,880500000,9435000000],
[2776,138800,189000000,881000000,9438400000],
[2777,138850,189500000,881500000,9441800000],
[2778,138900,189500000,882000000,9445200000],
[2779,138950,189500000,882500000,9448600000],
[2780,139000,189500000,883000000,9452000000],
[2781,139050,190000000,883500000,9455400000],
[2782,139100,190000000,884000000,9458800000],
[2783,139150,190000000,884500000,9462200000],
[2784,139200,190500000,884500000,9465600000],
[2785,139250,190500000,885000000,9469000000],
[2786,139300,190500000,885500000,9472400000],
[2787,139350,191000000,886000000,9475800000],
[2788,139400,191000000,886500000,9479200000],
[2789,139450,191000000,887000000,9482600000],
[2790,139500,191000000,887500000,9486000000],
[2791,139550,191500000,888000000,9489400000],
[2792,139600,191500000,888500000,9492800000],
[2793,139650,191500000,889000000,9496200000],
[2794,139700,192000000,889500000,9499600000],
[2795,139750,192000000,889500000,9503000000],
[2796,139800,192000000,890000000,9506400000],
[2797,139850,192500000,890500000,9509800000],
[2798,139900,192500000,891000000,9513200000],
[2799,139950,192500000,891500000,9516600000],
[2800,140000,193000000,892000000,9520000000],
[2801,140050,193000000,892500000,9523400000],
[2802,140100,193000000,893000000,9526800000],
[2803,140150,193000000,893500000,9530200000],
[2804,140200,193500000,894000000,9533600000],
[2805,140250,193500000,894500000,9537000000],
[2806,140300,193500000,894500000,9540400000],
[2807,140350,194000000,895000000,9543800000],
[2808,140400,194000000,895500000,9547200000],
[2809,140450,194000000,896000000,9550600000],
[2810,140500,194500000,896500000,9554000000],
[2811,140550,194500000,897000000,9557400000],
[2812,140600,194500000,897500000,9560800000],
[2813,140650,194500000,898000000,9564200000],
[2814,140700,195000000,898500000,9567600000],
[2815,140750,195000000,899000000,9571000000],
[2816,140800,195000000,899500000,9574400000],
[2817,140850,195500000,899500000,9577800000],
[2818,140900,195500000,900000000,9581200000],
[2819,140950,195500000,900500000,9584600000],
[2820,141000,196000000,901000000,9588000000],
[2821,141050,196000000,901500000,9591400000],
[2822,141100,196000000,902000000,9594800000],
[2823,141150,196000000,902500000,9598200000],
[2824,141200,196500000,903000000,9601600000],
[2825,141250,196500000,903500000,9605000000],
[2826,141300,196500000,904000000,9608400000],
[2827,141350,197000000,904500000,9611800000],
[2828,141400,197000000,904500000,9615200000],
[2829,141450,197000000,905000000,9618600000],
[2830,141500,197500000,905500000,9622000000],
[2831,141550,197500000,906000000,9625400000],
[2832,141600,197500000,906500000,9628800000],
[2833,141650,198000000,907000000,9632200000],
[2834,141700,198000000,907500000,9635600000],
[2835,141750,198000000,908000000,9639000000],
[2836,141800,198000000,908500000,9642400000],
[2837,141850,198500000,909000000,9645800000],
[2838,141900,198500000,909500000,9649200000],
[2839,141950,198500000,909500000,9652600000],
[2840,142000,199000000,910000000,9656000000],
[2841,142050,199000000,910500000,9659400000],
[2842,142100,199000000,911000000,9662800000],
[2843,142150,199500000,911500000,9666200000],
[2844,142200,199500000,912000000,9669600000],
[2845,142250,199500000,912500000,9673000000],
[2846,142300,199500000,913000000,9676400000],
[2847,142350,200000000,913500000,9679800000],
[2848,142400,200000000,914000000,9683200000],
[2849,142450,200000000,914500000,9686600000],
[2850,142500,200500000,914500000,9690000000],
[2851,142550,200500000,915000000,9693400000],
[2852,142600,200500000,915500000,9696800000],
[2853,142650,201000000,916000000,9700200000],
[2854,142700,201000000,916500000,9703600000],
[2855,142750,201000000,917000000,9707000000],
[2856,142800,201000000,917500000,9710400000],
[2857,142850,201500000,918000000,9713800000],
[2858,142900,201500000,918500000,9717200000],
[2859,142950,201500000,919000000,9720600000],
[2860,143000,202000000,919500000,9724000000],
[2861,143050,202000000,919500000,9727400000],
[2862,143100,202000000,920000000,9730800000],
[2863,143150,202500000,920500000,9734200000],
[2864,143200,202500000,921000000,9737600000],
[2865,143250,202500000,921500000,9741000000],
[2866,143300,203000000,922000000,9744400000],
[2867,143350,203000000,922500000,9747800000],
[2868,143400,203000000,923000000,9751200000],
[2869,143450,203000000,923500000,9754600000],
[2870,143500,203500000,924000000,9758000000],
[2871,143550,203500000,924500000,9761400000],
[2872,143600,203500000,924500000,9764800000],
[2873,143650,204000000,925000000,9768200000],
[2874,143700,204000000,925500000,9771600000],
[2875,143750,204000000,926000000,9775000000],
[2876,143800,204500000,926500000,9778400000],
[2877,143850,204500000,927000000,9781800000],
[2878,143900,204500000,927500000,9785200000],
[2879,143950,204500000,928000000,9788600000],
[2880,144000,205000000,928500000,9792000000],
[2881,144050,205000000,929000000,9795400000],
[2882,144100,205000000,929500000,9798800000],
[2883,144150,205500000,929500000,9802200000],
[2884,144200,205500000,930000000,9805600000],
[2885,144250,205500000,930500000,9809000000],
[2886,144300,206000000,931000000,9812400000],
[2887,144350,206000000,931500000,9815800000],
[2888,144400,206000000,932000000,9819200000],
[2889,144450,206000000,932500000,9822600000],
[2890,144500,206500000,933000000,9826000000],
[2891,144550,206500000,933500000,9829400000],
[2892,144600,206500000,934000000,9832800000],
[2893,144650,207000000,934500000,9836200000],
[2894,144700,207000000,934500000,9839600000],
[2895,144750,207000000,935000000,9843000000],
[2896,144800,207500000,935500000,9846400000],
[2897,144850,207500000,936000000,9849800000],
[2898,144900,207500000,936500000,9853200000],
[2899,144950,208000000,937000000,9856600000],
[2900,145000,208000000,937500000,9860000000],
[2901,145050,208000000,938000000,9863400000],
[2902,145100,208000000,938500000,9866800000],
[2903,145150,208500000,939000000,9870200000],
[2904,145200,208500000,939500000,9873600000],
[2905,145250,208500000,939500000,9877000000],
[2906,145300,209000000,940000000,9880400000],
[2907,145350,209000000,940500000,9883800000],
[2908,145400,209000000,941000000,9887200000],
[2909,145450,209500000,941500000,9890600000],
[2910,145500,209500000,942000000,9894000000],
[2911,145550,209500000,942500000,9897400000],
[2912,145600,209500000,943000000,9900800000],
[2913,145650,210000000,943500000,9904200000],
[2914,145700,210000000,944000000,9907600000],
[2915,145750,210000000,944500000,9911000000],
[2916,145800,210500000,944500000,9914400000],
[2917,145850,210500000,945000000,9917800000],
[2918,145900,210500000,945500000,9921200000],
[2919,145950,211000000,946000000,9924600000],
[2920,146000,211000000,946500000,9928000000],
[2921,146050,211000000,947000000,9931400000],
[2922,146100,211000000,947500000,9934800000],
[2923,146150,211500000,948000000,9938200000],
[2924,146200,211500000,948500000,9941600000],
[2925,146250,211500000,949000000,9945000000],
[2926,146300,212000000,949500000,9948400000],
[2927,146350,212000000,949500000,9951800000],
[2928,146400,212000000,950000000,9955200000],
[2929,146450,212500000,950500000,9958600000],
[2930,146500,212500000,951000000,9962000000],
[2931,146550,212500000,951500000,9965400000],
[2932,146600,213000000,952000000,9968800000],
[2933,146650,213000000,952500000,9972200000],
[2934,146700,213000000,953000000,9975600000],
[2935,146750,213000000,953500000,9979000000],
[2936,146800,213500000,954000000,9982400000],
[2937,146850,213500000,954500000,9985800000],
[2938,146900,213500000,954500000,9989200000],
[2939,146950,214000000,955000000,9992600000],
[2940,147000,214000000,955500000,9996000000],
[2941,147050,214000000,956000000,9999400000],
[2942,147100,214500000,956500000,10002800000],
[2943,147150,214500000,957000000,10006200000],
[2944,147200,214500000,957500000,10009600000],
[2945,147250,214500000,958000000,10013000000],
[2946,147300,215000000,958500000,10016400000],
[2947,147350,215000000,959000000,10019800000],
[2948,147400,215000000,959500000,10023200000],
[2949,147450,215500000,959500000,10026600000],
[2950,147500,215500000,960000000,10030000000],
[2951,147550,215500000,960500000,10033400000],
[2952,147600,216000000,961000000,10036800000],
[2953,147650,216000000,961500000,10040200000],
[2954,147700,216000000,962000000,10043600000],
[2955,147750,216000000,962500000,10047000000],
[2956,147800,216500000,963000000,10050400000],
[2957,147850,216500000,963500000,10053800000],
[2958,147900,216500000,964000000,10057200000],
[2959,147950,217000000,964500000,10060600000],
[2960,148000,217000000,964500000,10064000000],
[2961,148050,217000000,965000000,10067400000],
[2962,148100,217500000,965500000,10070800000],
[2963,148150,217500000,966000000,10074200000],
[2964,148200,217500000,966500000,10077600000],
[2965,148250,218000000,967000000,10081000000],
[2966,148300,218000000,967500000,10084400000],
[2967,148350,218000000,968000000,10087800000],
[2968,148400,218000000,968500000,10091200000],
[2969,148450,218500000,969000000,10094600000],
[2970,148500,218500000,969500000,10098000000],
[2971,148550,218500000,969500000,10101400000],
[2972,148600,219000000,970000000,10104800000],
[2973,148650,219000000,970500000,10108200000],
[2974,148700,219000000,971000000,10111600000],
[2975,148750,219500000,971500000,10115000000],
[2976,148800,219500000,972000000,10118400000],
[2977,148850,219500000,972500000,10121800000],
[2978,148900,219500000,973000000,10125200000],
[2979,148950,220000000,973500000,10128600000],
[2980,149000,220000000,974000000,10132000000],
[2981,149050,220000000,974500000,10135400000],
[2982,149100,220500000,974500000,10138800000],
[2983,149150,220500000,975000000,10142200000],
[2984,149200,220500000,975500000,10145600000],
[2985,149250,221000000,976000000,10149000000],
[2986,149300,221000000,976500000,10152400000],
[2987,149350,221000000,977000000,10155800000],
[2988,149400,221000000,977500000,10159200000],
[2989,149450,221500000,978000000,10162600000],
[2990,149500,221500000,978500000,10166000000],
[2991,149550,221500000,979000000,10169400000],
[2992,149600,222000000,979500000,10172800000],
[2993,149650,222000000,979500000,10176200000],
[2994,149700,222000000,980000000,10179600000],
[2995,149750,222500000,980500000,10183000000],
[2996,149800,222500000,981000000,10186400000],
[2997,149850,222500000,981500000,10189800000],
[2998,149900,223000000,982000000,10193200000],
[2999,149950,223000000,982500000,10196600000],
[3000,150000,223000000,983000000,10200000000],
[3001,150050,223000000,983500000,10203400000],
[3002,150100,223500000,984000000,10206800000],
[3003,150150,223500000,984500000,10210200000],
[3004,150200,223500000,984500000,10213600000],
[3005,150250,224000000,985000000,10217000000],
[3006,150300,224000000,985500000,10220400000],
[3007,150350,224000000,986000000,10223800000],
[3008,150400,224500000,986500000,10227200000],
[3009,150450,224500000,987000000,10230600000],
[3010,150500,224500000,987500000,10234000000],
[3011,150550,224500000,988000000,10237400000],
[3012,150600,225000000,988500000,10240800000],
[3013,150650,225000000,989000000,10244200000],
[3014,150700,225000000,989500000,10247600000],
[3015,150750,225500000,989500000,10251000000],
[3016,150800,225500000,990000000,10254400000],
[3017,150850,225500000,990500000,10257800000],
[3018,150900,226000000,991000000,10261200000],
[3019,150950,226000000,991500000,10264600000],
[3020,151000,226000000,992000000,10268000000],
[3021,151050,226000000,992500000,10271400000],
[3022,151100,226500000,993000000,10274800000],
[3023,151150,226500000,993500000,10278200000],
[3024,151200,226500000,994000000,10281600000],
[3025,151250,227000000,994500000,10285000000],
[3026,151300,227000000,994500000,10288400000],
[3027,151350,227000000,995000000,10291800000],
[3028,151400,227500000,995500000,10295200000],
[3029,151450,227500000,996000000,10298600000],
[3030,151500,227500000,996500000,10302000000],
[3031,151550,228000000,997000000,10305400000],
[3032,151600,228000000,997500000,10308800000],
[3033,151650,228000000,998000000,10312200000],
[3034,151700,228000000,998500000,10315600000],
[3035,151750,228500000,999000000,10319000000],
[3036,151800,228500000,999500000,10322400000],
[3037,151850,228500000,999500000,10325800000],
[3038,151900,229000000,1000000000,10329200000],
[3039,151950,229000000,1000000000,10332600000],
[3040,152000,229000000,1000000000,10336000000],
[3041,152050,229500000,1000000000,10339400000],
[3042,152100,229500000,1000000000,10342800000],
[3043,152150,229500000,1000000000,10346200000],
[3044,152200,229500000,1000000000,10349600000],
[3045,152250,230000000,1000000000,10353000000],
[3046,152300,230000000,1000000000,10356400000],
[3047,152350,230000000,1000000000,10359800000],
[3048,152400,230500000,1000000000,10363200000],
[3049,152450,230500000,1000000000,10366600000],
[3050,152500,230500000,1000000000,10370000000],
[3051,152550,231000000,1000000000,10373400000],
[3052,152600,231000000,1000000000,10376800000],
[3053,152650,231000000,1000000000,10380200000],
[3054,152700,231000000,1000000000,10383600000],
[3055,152750,231500000,1000000000,10387000000],
[3056,152800,231500000,1000000000,10390400000],
[3057,152850,231500000,1000000000,10393800000],
[3058,152900,232000000,1000000000,10397200000],
[3059,152950,232000000,1000000000,10400600000],
[3060,153000,232000000,1000000000,10404000000],
[3061,153050,232500000,1000000000,10407400000],
[3062,153100,232500000,1000000000,10410800000],
[3063,153150,232500000,1000000000,10414200000],
[3064,153200,233000000,1000000000,10417600000],
[3065,153250,233000000,1000000000,10421000000],
[3066,153300,233000000,1000000000,10424400000],
[3067,153350,233000000,1000000000,10427800000],
[3068,153400,233500000,1000000000,10431200000],
[3069,153450,233500000,1000000000,10434600000],
[3070,153500,233500000,1000000000,10438000000],
[3071,153550,234000000,1000000000,10441400000],
[3072,153600,234000000,1000000000,10444800000],
[3073,153650,234000000,1000000000,10448200000],
[3074,153700,234500000,1000000000,10451600000],
[3075,153750,234500000,1000000000,10455000000],
[3076,153800,234500000,1000000000,10458400000],
[3077,153850,234500000,1000000000,10461800000],
[3078,153900,235000000,1000000000,10465200000],
[3079,153950,235000000,1000000000,10468600000],
[3080,154000,235000000,1000000000,10472000000],
[3081,154050,235500000,1000000000,10475400000],
[3082,154100,235500000,1000000000,10478800000],
[3083,154150,235500000,1000000000,10482200000],
[3084,154200,236000000,1000000000,10485600000],
[3085,154250,236000000,1000000000,10489000000],
[3086,154300,236000000,1000000000,10492400000],
[3087,154350,236000000,1000000000,10495800000],
[3088,154400,236500000,1000000000,10499200000],
[3089,154450,236500000,1000000000,10502600000],
[3090,154500,236500000,1000000000,10506000000],
[3091,154550,237000000,1000000000,10509400000],
[3092,154600,237000000,1000000000,10512800000],
[3093,154650,237000000,1000000000,10516200000],
[3094,154700,237500000,1000000000,10519600000],
[3095,154750,237500000,1000000000,10523000000],
[3096,154800,237500000,1000000000,10526400000],
[3097,154850,238000000,1000000000,10529800000],
[3098,154900,238000000,1000000000,10533200000],
[3099,154950,238000000,1000000000,10536600000],
[3100,155000,238000000,1000000000,10540000000],
[3101,155050,238500000,1000000000,10543400000],
[3102,155100,238500000,1000000000,10546800000],
[3103,155150,238500000,1000000000,10550200000],
[3104,155200,239000000,1000000000,10553600000],
[3105,155250,239000000,1000000000,10557000000],
[3106,155300,239000000,1000000000,10560400000],
[3107,155350,239500000,1000000000,10563800000],
[3108,155400,239500000,1000000000,10567200000],
[3109,155450,239500000,1000000000,10570600000],
[3110,155500,239500000,1000000000,10574000000],
[3111,155550,240000000,1000000000,10577400000],
[3112,155600,240000000,1000000000,10580800000],
[3113,155650,240000000,1000000000,10584200000],
[3114,155700,240500000,1000000000,10587600000],
[3115,155750,240500000,1000000000,10591000000],
[3116,155800,240500000,1000000000,10594400000],
[3117,155850,241000000,1000000000,10597800000],
[3118,155900,241000000,1000000000,10601200000],
[3119,155950,241000000,1000000000,10604600000],
[3120,156000,241000000,1000000000,10608000000],
[3121,156050,241500000,1000000000,10611400000],
[3122,156100,241500000,1000000000,10614800000],
[3123,156150,241500000,1000000000,10618200000],
[3124,156200,242000000,1000000000,10621600000],
[3125,156250,242000000,1000000000,10625000000],
[3126,156300,242000000,1000000000,10628400000],
[3127,156350,242500000,1000000000,10631800000],
[3128,156400,242500000,1000000000,10635200000],
[3129,156450,242500000,1000000000,10638600000],
[3130,156500,243000000,1000000000,10642000000],
[3131,156550,243000000,1000000000,10645400000],
[3132,156600,243000000,1000000000,10648800000],
[3133,156650,243000000,1000000000,10652200000],
[3134,156700,243500000,1000000000,10655600000],
[3135,156750,243500000,1000000000,10659000000],
[3136,156800,243500000,1000000000,10662400000],
[3137,156850,244000000,1000000000,10665800000],
[3138,156900,244000000,1000000000,10669200000],
[3139,156950,244000000,1000000000,10672600000],
[3140,157000,244500000,1000000000,10676000000],
[3141,157050,244500000,1000000000,10679400000],
[3142,157100,244500000,1000000000,10682800000],
[3143,157150,244500000,1000000000,10686200000],
[3144,157200,245000000,1000000000,10689600000],
[3145,157250,245000000,1000000000,10693000000],
[3146,157300,245000000,1000000000,10696400000],
[3147,157350,245500000,1000000000,10699800000],
[3148,157400,245500000,1000000000,10703200000],
[3149,157450,245500000,1000000000,10706600000],
[3150,157500,246000000,1000000000,10710000000],
[3151,157550,246000000,1000000000,10713400000],
[3152,157600,246000000,1000000000,10716800000],
[3153,157650,246000000,1000000000,10720200000],
[3154,157700,246500000,1000000000,10723600000],
[3155,157750,246500000,1000000000,10727000000],
[3156,157800,246500000,1000000000,10730400000],
[3157,157850,247000000,1000000000,10733800000],
[3158,157900,247000000,1000000000,10737200000],
[3159,157950,247000000,1000000000,10740600000],
[3160,158000,247500000,1000000000,10744000000],
[3161,158050,247500000,1000000000,10747400000],
[3162,158100,247500000,1000000000,10750800000],
[3163,158150,248000000,1000000000,10754200000],
[3164,158200,248000000,1000000000,10757600000],
[3165,158250,248000000,1000000000,10761000000],
[3166,158300,248000000,1000000000,10764400000],
[3167,158350,248500000,1000000000,10767800000],
[3168,158400,248500000,1000000000,10771200000],
[3169,158450,248500000,1000000000,10774600000],
[3170,158500,249000000,1000000000,10778000000],
[3171,158550,249000000,1000000000,10781400000],
[3172,158600,249000000,1000000000,10784800000],
[3173,158650,249500000,1000000000,10788200000],
[3174,158700,249500000,1000000000,10791600000],
[3175,158750,249500000,1000000000,10795000000],
[3176,158800,249500000,1000000000,10798400000],
[3177,158850,250000000,1000000000,10801800000],
[3178,158900,250000000,1000000000,10805200000],
[3179,158950,250000000,1000000000,10808600000],
[3180,159000,250500000,1000000000,10812000000],
[3181,159050,250500000,1000000000,10815400000],
[3182,159100,250500000,1000000000,10818800000],
[3183,159150,251000000,1000000000,10822200000],
[3184,159200,251000000,1000000000,10825600000],
[3185,159250,251000000,1000000000,10829000000],
[3186,159300,251000000,1000000000,10832400000],
[3187,159350,251500000,1000000000,10835800000],
[3188,159400,251500000,1000000000,10839200000],
[3189,159450,251500000,1000000000,10842600000],
[3190,159500,252000000,1000000000,10846000000],
[3191,159550,252000000,1000000000,10849400000],
[3192,159600,252000000,1000000000,10852800000],
[3193,159650,252500000,1000000000,10856200000],
[3194,159700,252500000,1000000000,10859600000],
[3195,159750,252500000,1000000000,10863000000],
[3196,159800,253000000,1000000000,10866400000],
[3197,159850,253000000,1000000000,10869800000],
[3198,159900,253000000,1000000000,10873200000],
[3199,159950,253000000,1000000000,10876600000],
[3200,160000,253500000,1000000000,10880000000],
[3201,160050,253500000,1000000000,10883400000],
[3202,160100,253500000,1000000000,10886800000],
[3203,160150,254000000,1000000000,10890200000],
[3204,160200,254000000,1000000000,10893600000],
[3205,160250,254000000,1000000000,10897000000],
[3206,160300,254500000,1000000000,10900400000],
[3207,160350,254500000,1000000000,10903800000],
[3208,160400,254500000,1000000000,10907200000],
[3209,160450,254500000,1000000000,10910600000],
[3210,160500,255000000,1000000000,10914000000],
[3211,160550,255000000,1000000000,10917400000],
[3212,160600,255000000,1000000000,10920800000],
[3213,160650,255500000,1000000000,10924200000],
[3214,160700,255500000,1000000000,10927600000],
[3215,160750,255500000,1000000000,10931000000],
[3216,160800,256000000,1000000000,10934400000],
[3217,160850,256000000,1000000000,10937800000],
[3218,160900,256000000,1000000000,10941200000],
[3219,160950,256000000,1000000000,10944600000],
[3220,161000,256500000,1000000000,10948000000],
[3221,161050,256500000,1000000000,10951400000],
[3222,161100,256500000,1000000000,10954800000],
[3223,161150,257000000,1000000000,10958200000],
[3224,161200,257000000,1000000000,10961600000],
[3225,161250,257000000,1000000000,10965000000],
[3226,161300,257500000,1000000000,10968400000],
[3227,161350,257500000,1000000000,10971800000],
[3228,161400,257500000,1000000000,10975200000],
[3229,161450,258000000,1000000000,10978600000],
[3230,161500,258000000,1000000000,10982000000],
[3231,161550,258000000,1000000000,10985400000],
[3232,161600,258000000,1000000000,10988800000],
[3233,161650,258500000,1000000000,10992200000],
[3234,161700,258500000,1000000000,10995600000],
[3235,161750,258500000,1000000000,10999000000],
[3236,161800,259000000,1000000000,11002400000],
[3237,161850,259000000,1000000000,11005800000],
[3238,161900,259000000,1000000000,11009200000],
[3239,161950,259500000,1000000000,11012600000],
[3240,162000,259500000,1000000000,11016000000],
[3241,162050,259500000,1000000000,11019400000],
[3242,162100,259500000,1000000000,11022800000],
[3243,162150,260000000,1000000000,11026200000],
[3244,162200,260000000,1000000000,11029600000],
[3245,162250,260000000,1000000000,11033000000],
[3246,162300,260500000,1000000000,11036400000],
[3247,162350,260500000,1000000000,11039800000],
[3248,162400,260500000,1000000000,11043200000],
[3249,162450,261000000,1000000000,11046600000],
[3250,162500,261000000,1000000000,11050000000],
[3251,162550,261000000,1000000000,11053400000],
[3252,162600,261000000,1000000000,11056800000],
[3253,162650,261500000,1000000000,11060200000],
[3254,162700,261500000,1000000000,11063600000],
[3255,162750,261500000,1000000000,11067000000],
[3256,162800,262000000,1000000000,11070400000],
[3257,162850,262000000,1000000000,11073800000],
[3258,162900,262000000,1000000000,11077200000],
[3259,162950,262500000,1000000000,11080600000],
[3260,163000,262500000,1000000000,11084000000],
[3261,163050,262500000,1000000000,11087400000],
[3262,163100,263000000,1000000000,11090800000],
[3263,163150,263000000,1000000000,11094200000],
[3264,163200,263000000,1000000000,11097600000],
[3265,163250,263000000,1000000000,11101000000],
[3266,163300,263500000,1000000000,11104400000],
[3267,163350,263500000,1000000000,11107800000],
[3268,163400,263500000,1000000000,11111200000],
[3269,163450,264000000,1000000000,11114600000],
[3270,163500,264000000,1000000000,11118000000],
[3271,163550,264000000,1000000000,11121400000],
[3272,163600,264500000,1000000000,11124800000],
[3273,163650,264500000,1000000000,11128200000],
[3274,163700,264500000,1000000000,11131600000],
[3275,163750,264500000,1000000000,11135000000],
[3276,163800,265000000,1000000000,11138400000],
[3277,163850,265000000,1000000000,11141800000],
[3278,163900,265000000,1000000000,11145200000],
[3279,163950,265500000,1000000000,11148600000],
[3280,164000,265500000,1000000000,11152000000],
[3281,164050,265500000,1000000000,11155400000],
[3282,164100,266000000,1000000000,11158800000],
[3283,164150,266000000,1000000000,11162200000],
[3284,164200,266000000,1000000000,11165600000],
[3285,164250,266000000,1000000000,11169000000],
[3286,164300,266500000,1000000000,11172400000],
[3287,164350,266500000,1000000000,11175800000],
[3288,164400,266500000,1000000000,11179200000],
[3289,164450,267000000,1000000000,11182600000],
[3290,164500,267000000,1000000000,11186000000],
[3291,164550,267000000,1000000000,11189400000],
[3292,164600,267500000,1000000000,11192800000],
[3293,164650,267500000,1000000000,11196200000],
[3294,164700,267500000,1000000000,11199600000],
[3295,164750,268000000,1000000000,11203000000],
[3296,164800,268000000,1000000000,11206400000],
[3297,164850,268000000,1000000000,11209800000],
[3298,164900,268000000,1000000000,11213200000],
[3299,164950,268500000,1000000000,11216600000],
[3300,165000,268500000,1000000000,11220000000],
[3301,165050,268500000,1000000000,11223400000],
[3302,165100,269000000,1000000000,11226800000],
[3303,165150,269000000,1000000000,11230200000],
[3304,165200,269000000,1000000000,11233600000],
[3305,165250,269500000,1000000000,11237000000],
[3306,165300,269500000,1000000000,11240400000],
[3307,165350,269500000,1000000000,11243800000],
[3308,165400,269500000,1000000000,11247200000],
[3309,165450,270000000,1000000000,11250600000],
[3310,165500,270000000,1000000000,11254000000],
[3311,165550,270000000,1000000000,11257400000],
[3312,165600,270500000,1000000000,11260800000],
[3313,165650,270500000,1000000000,11264200000],
[3314,165700,270500000,1000000000,11267600000],
[3315,165750,271000000,1000000000,11271000000],
[3316,165800,271000000,1000000000,11274400000],
[3317,165850,271000000,1000000000,11277800000],
[3318,165900,271000000,1000000000,11281200000],
[3319,165950,271500000,1000000000,11284600000],
[3320,166000,271500000,1000000000,11288000000],
[3321,166050,271500000,1000000000,11291400000],
[3322,166100,272000000,1000000000,11294800000],
[3323,166150,272000000,1000000000,11298200000],
[3324,166200,272000000,1000000000,11301600000],
[3325,166250,272500000,1000000000,11305000000],
[3326,166300,272500000,1000000000,11308400000],
[3327,166350,272500000,1000000000,11311800000],
[3328,166400,273000000,1000000000,11315200000],
[3329,166450,273000000,1000000000,11318600000],
[3330,166500,273000000,1000000000,11322000000],
[3331,166550,273000000,1000000000,11325400000],
[3332,166600,273500000,1000000000,11328800000],
[3333,166650,273500000,1000000000,11332200000],
[3334,166700,273500000,1000000000,11335600000],
[3335,166750,274000000,1000000000,11339000000],
[3336,166800,274000000,1000000000,11342400000],
[3337,166850,274000000,1000000000,11345800000],
[3338,166900,274500000,1000000000,11349200000],
[3339,166950,274500000,1000000000,11352600000],
[3340,167000,274500000,1000000000,11356000000],
[3341,167050,274500000,1000000000,11359400000],
[3342,167100,275000000,1000000000,11362800000],
[3343,167150,275000000,1000000000,11366200000],
[3344,167200,275000000,1000000000,11369600000],
[3345,167250,275500000,1000000000,11373000000],
[3346,167300,275500000,1000000000,11376400000],
[3347,167350,275500000,1000000000,11379800000],
[3348,167400,276000000,1000000000,11383200000],
[3349,167450,276000000,1000000000,11386600000],
[3350,167500,276000000,1000000000,11390000000],
[3351,167550,276000000,1000000000,11393400000],
[3352,167600,276500000,1000000000,11396800000],
[3353,167650,276500000,1000000000,11400200000],
[3354,167700,276500000,1000000000,11403600000],
[3355,167750,277000000,1000000000,11407000000],
[3356,167800,277000000,1000000000,11410400000],
[3357,167850,277000000,1000000000,11413800000],
[3358,167900,277500000,1000000000,11417200000],
[3359,167950,277500000,1000000000,11420600000],
[3360,168000,277500000,1000000000,11424000000],
[3361,168050,278000000,1000000000,11427400000],
[3362,168100,278000000,1000000000,11430800000],
[3363,168150,278000000,1000000000,11434200000],
[3364,168200,278000000,1000000000,11437600000],
[3365,168250,278500000,1000000000,11441000000],
[3366,168300,278500000,1000000000,11444400000],
[3367,168350,278500000,1000000000,11447800000],
[3368,168400,279000000,1000000000,11451200000],
[3369,168450,279000000,1000000000,11454600000],
[3370,168500,279000000,1000000000,11458000000],
[3371,168550,279500000,1000000000,11461400000],
[3372,168600,279500000,1000000000,11464800000],
[3373,168650,279500000,1000000000,11468200000],
[3374,168700,279500000,1000000000,11471600000],
[3375,168750,280000000,1000000000,11475000000],
[3376,168800,280000000,1000000000,11478400000],
[3377,168850,280000000,1000000000,11481800000],
[3378,168900,280500000,1000000000,11485200000],
[3379,168950,280500000,1000000000,11488600000],
[3380,169000,280500000,1000000000,11492000000],
[3381,169050,281000000,1000000000,11495400000],
[3382,169100,281000000,1000000000,11498800000],
[3383,169150,281000000,1000000000,11502200000],
[3384,169200,281000000,1000000000,11505600000],
[3385,169250,281500000,1000000000,11509000000],
[3386,169300,281500000,1000000000,11512400000],
[3387,169350,281500000,1000000000,11515800000],
[3388,169400,282000000,1000000000,11519200000],
[3389,169450,282000000,1000000000,11522600000],
[3390,169500,282000000,1000000000,11526000000],
[3391,169550,282500000,1000000000,11529400000],
[3392,169600,282500000,1000000000,11532800000],
[3393,169650,282500000,1000000000,11536200000],
[3394,169700,283000000,1000000000,11539600000],
[3395,169750,283000000,1000000000,11543000000],
[3396,169800,283000000,1000000000,11546400000],
[3397,169850,283000000,1000000000,11549800000],
[3398,169900,283500000,1000000000,11553200000],
[3399,169950,283500000,1000000000,11556600000],
[3400,170000,283500000,1000000000,11560000000],
[3401,170050,284000000,1000000000,11563400000],
[3402,170100,284000000,1000000000,11566800000],
[3403,170150,284000000,1000000000,11570200000],
[3404,170200,284500000,1000000000,11573600000],
[3405,170250,284500000,1000000000,11577000000],
[3406,170300,284500000,1000000000,11580400000],
[3407,170350,284500000,1000000000,11583800000],
[3408,170400,285000000,1000000000,11587200000],
[3409,170450,285000000,1000000000,11590600000],
[3410,170500,285000000,1000000000,11594000000],
[3411,170550,285500000,1000000000,11597400000],
[3412,170600,285500000,1000000000,11600800000],
[3413,170650,285500000,1000000000,11604200000],
[3414,170700,286000000,1000000000,11607600000],
[3415,170750,286000000,1000000000,11611000000],
[3416,170800,286000000,1000000000,11614400000],
[3417,170850,286000000,1000000000,11617800000],
[3418,170900,286500000,1000000000,11621200000],
[3419,170950,286500000,1000000000,11624600000],
[3420,171000,286500000,1000000000,11628000000],
[3421,171050,287000000,1000000000,11631400000],
[3422,171100,287000000,1000000000,11634800000],
[3423,171150,287000000,1000000000,11638200000],
[3424,171200,287500000,1000000000,11641600000],
[3425,171250,287500000,1000000000,11645000000],
[3426,171300,287500000,1000000000,11648400000],
[3427,171350,288000000,1000000000,11651800000],
[3428,171400,288000000,1000000000,11655200000],
[3429,171450,288000000,1000000000,11658600000],
[3430,171500,288000000,1000000000,11662000000],
[3431,171550,288500000,1000000000,11665400000],
[3432,171600,288500000,1000000000,11668800000],
[3433,171650,288500000,1000000000,11672200000],
[3434,171700,289000000,1000000000,11675600000],
[3435,171750,289000000,1000000000,11679000000],
[3436,171800,289000000,1000000000,11682400000],
[3437,171850,289500000,1000000000,11685800000],
[3438,171900,289500000,1000000000,11689200000],
[3439,171950,289500000,1000000000,11692600000],
[3440,172000,289500000,1000000000,11696000000],
[3441,172050,290000000,1000000000,11699400000],
[3442,172100,290000000,1000000000,11702800000],
[3443,172150,290000000,1000000000,11706200000],
[3444,172200,290500000,1000000000,11709600000],
[3445,172250,290500000,1000000000,11713000000],
[3446,172300,290500000,1000000000,11716400000],
[3447,172350,291000000,1000000000,11719800000],
[3448,172400,291000000,1000000000,11723200000],
[3449,172450,291000000,1000000000,11726600000],
[3450,172500,291000000,1000000000,11730000000],
[3451,172550,291500000,1000000000,11733400000],
[3452,172600,291500000,1000000000,11736800000],
[3453,172650,291500000,1000000000,11740200000],
[3454,172700,292000000,1000000000,11743600000],
[3455,172750,292000000,1000000000,11747000000],
[3456,172800,292000000,1000000000,11750400000],
[3457,172850,292500000,1000000000,11753800000],
[3458,172900,292500000,1000000000,11757200000],
[3459,172950,292500000,1000000000,11760600000],
[3460,173000,293000000,1000000000,11764000000],
[3461,173050,293000000,1000000000,11767400000],
[3462,173100,293000000,1000000000,11770800000],
[3463,173150,293000000,1000000000,11774200000],
[3464,173200,293500000,1000000000,11777600000],
[3465,173250,293500000,1000000000,11781000000],
[3466,173300,293500000,1000000000,11784400000],
[3467,173350,294000000,1000000000,11787800000],
[3468,173400,294000000,1000000000,11791200000],
[3469,173450,294000000,1000000000,11794600000],
[3470,173500,294500000,1000000000,11798000000],
[3471,173550,294500000,1000000000,11801400000],
[3472,173600,294500000,1000000000,11804800000],
[3473,173650,294500000,1000000000,11808200000],
[3474,173700,295000000,1000000000,11811600000],
[3475,173750,295000000,1000000000,11815000000],
[3476,173800,295000000,1000000000,11818400000],
[3477,173850,295500000,1000000000,11821800000],
[3478,173900,295500000,1000000000,11825200000],
[3479,173950,295500000,1000000000,11828600000],
[3480,174000,296000000,1000000000,11832000000],
[3481,174050,296000000,1000000000,11835400000],
[3482,174100,296000000,1000000000,11838800000],
[3483,174150,296000000,1000000000,11842200000],
[3484,174200,296500000,1000000000,11845600000],
[3485,174250,296500000,1000000000,11849000000],
[3486,174300,296500000,1000000000,11852400000],
[3487,174350,297000000,1000000000,11855800000],
[3488,174400,297000000,1000000000,11859200000],
[3489,174450,297000000,1000000000,11862600000],
[3490,174500,297500000,1000000000,11866000000],
[3491,174550,297500000,1000000000,11869400000],
[3492,174600,297500000,1000000000,11872800000],
[3493,174650,298000000,1000000000,11876200000],
[3494,174700,298000000,1000000000,11879600000],
[3495,174750,298000000,1000000000,11883000000],
[3496,174800,298000000,1000000000,11886400000],
[3497,174850,298500000,1000000000,11889800000],
[3498,174900,298500000,1000000000,11893200000],
[3499,174950,298500000,1000000000,11896600000],
[3500,175000,299000000,1000000000,11900000000],
[3501,175050,299000000,1000000000,11903400000],
[3502,175100,299000000,1000000000,11906800000],
[3503,175150,299500000,1000000000,11910200000],
[3504,175200,299500000,1000000000,11913600000],
[3505,175250,299500000,1000000000,11917000000],
[3506,175300,299500000,1000000000,11920400000],
[3507,175350,300000000,1000000000,11923800000],
[3508,175400,300000000,1000000000,11927200000],
[3509,175450,300000000,1000000000,11930600000],
[3510,175500,300500000,1000000000,11934000000],
[3511,175550,300500000,1000000000,11937400000],
[3512,175600,300500000,1000000000,11940800000],
[3513,175650,301000000,1000000000,11944200000],
[3514,175700,301000000,1000000000,11947600000],
[3515,175750,301000000,1000000000,11951000000],
[3516,175800,301000000,1000000000,11954400000],
[3517,175850,301500000,1000000000,11957800000],
[3518,175900,301500000,1000000000,11961200000],
[3519,175950,301500000,1000000000,11964600000],
[3520,176000,302000000,1000000000,11968000000],
[3521,176050,302000000,1000000000,11971400000],
[3522,176100,302000000,1000000000,11974800000],
[3523,176150,302500000,1000000000,11978200000],
[3524,176200,302500000,1000000000,11981600000],
[3525,176250,302500000,1000000000,11985000000],
[3526,176300,303000000,1000000000,11988400000],
[3527,176350,303000000,1000000000,11991800000],
[3528,176400,303000000,1000000000,11995200000],
[3529,176450,303000000,1000000000,11998600000],
[3530,176500,303500000,1000000000,12002000000],
[3531,176550,303500000,1000000000,12005400000],
[3532,176600,303500000,1000000000,12008800000],
[3533,176650,304000000,1000000000,12012200000],
[3534,176700,304000000,1000000000,12015600000],
[3535,176750,304000000,1000000000,12019000000],
[3536,176800,304500000,1000000000,12022400000],
[3537,176850,304500000,1000000000,12025800000],
[3538,176900,304500000,1000000000,12029200000],
[3539,176950,304500000,1000000000,12032600000],
[3540,177000,305000000,1000000000,12036000000],
[3541,177050,305000000,1000000000,12039400000],
[3542,177100,305000000,1000000000,12042800000],
[3543,177150,305500000,1000000000,12046200000],
[3544,177200,305500000,1000000000,12049600000],
[3545,177250,305500000,1000000000,12053000000],
[3546,177300,306000000,1000000000,12056400000],
[3547,177350,306000000,1000000000,12059800000],
[3548,177400,306000000,1000000000,12063200000],
[3549,177450,306000000,1000000000,12066600000],
[3550,177500,306500000,1000000000,12070000000],
[3551,177550,306500000,1000000000,12073400000],
[3552,177600,306500000,1000000000,12076800000],
[3553,177650,307000000,1000000000,12080200000],
[3554,177700,307000000,1000000000,12083600000],
[3555,177750,307000000,1000000000,12087000000],
[3556,177800,307500000,1000000000,12090400000],
[3557,177850,307500000,1000000000,12093800000],
[3558,177900,307500000,1000000000,12097200000],
[3559,177950,308000000,1000000000,12100600000],
[3560,178000,308000000,1000000000,12104000000],
[3561,178050,308000000,1000000000,12107400000],
[3562,178100,308000000,1000000000,12110800000],
[3563,178150,308500000,1000000000,12114200000],
[3564,178200,308500000,1000000000,12117600000],
[3565,178250,308500000,1000000000,12121000000],
[3566,178300,309000000,1000000000,12124400000],
[3567,178350,309000000,1000000000,12127800000],
[3568,178400,309000000,1000000000,12131200000],
[3569,178450,309500000,1000000000,12134600000],
[3570,178500,309500000,1000000000,12138000000],
[3571,178550,309500000,1000000000,12141400000],
[3572,178600,309500000,1000000000,12144800000],
[3573,178650,310000000,1000000000,12148200000],
[3574,178700,310000000,1000000000,12151600000],
[3575,178750,310000000,1000000000,12155000000],
[3576,178800,310500000,1000000000,12158400000],
[3577,178850,310500000,1000000000,12161800000],
[3578,178900,310500000,1000000000,12165200000],
[3579,178950,311000000,1000000000,12168600000],
[3580,179000,311000000,1000000000,12172000000],
[3581,179050,311000000,1000000000,12175400000],
[3582,179100,311000000,1000000000,12178800000],
[3583,179150,311500000,1000000000,12182200000],
[3584,179200,311500000,1000000000,12185600000],
[3585,179250,311500000,1000000000,12189000000],
[3586,179300,312000000,1000000000,12192400000],
[3587,179350,312000000,1000000000,12195800000],
[3588,179400,312000000,1000000000,12199200000],
[3589,179450,312500000,1000000000,12202600000],
[3590,179500,312500000,1000000000,12206000000],
[3591,179550,312500000,1000000000,12209400000],
[3592,179600,313000000,1000000000,12212800000],
[3593,179650,313000000,1000000000,12216200000],
[3594,179700,313000000,1000000000,12219600000],
[3595,179750,313000000,1000000000,12223000000],
[3596,179800,313500000,1000000000,12226400000],
[3597,179850,313500000,1000000000,12229800000],
[3598,179900,313500000,1000000000,12233200000],
[3599,179950,314000000,1000000000,12236600000],
[3600,180000,314000000,1000000000,12240000000],
[3601,180050,314000000,1000000000,12243400000],
[3602,180100,314500000,1000000000,12246800000],
[3603,180150,314500000,1000000000,12250200000],
[3604,180200,314500000,1000000000,12253600000],
[3605,180250,314500000,1000000000,12257000000],
[3606,180300,315000000,1000000000,12260400000],
[3607,180350,315000000,1000000000,12263800000],
[3608,180400,315000000,1000000000,12267200000],
[3609,180450,315500000,1000000000,12270600000],
[3610,180500,315500000,1000000000,12274000000],
[3611,180550,315500000,1000000000,12277400000],
[3612,180600,316000000,1000000000,12280800000],
[3613,180650,316000000,1000000000,12284200000],
[3614,180700,316000000,1000000000,12287600000],
[3615,180750,316000000,1000000000,12291000000],
[3616,180800,316500000,1000000000,12294400000],
[3617,180850,316500000,1000000000,12297800000],
[3618,180900,316500000,1000000000,12301200000],
[3619,180950,317000000,1000000000,12304600000],
[3620,181000,317000000,1000000000,12308000000],
[3621,181050,317000000,1000000000,12311400000],
[3622,181100,317500000,1000000000,12314800000],
[3623,181150,317500000,1000000000,12318200000],
[3624,181200,317500000,1000000000,12321600000],
[3625,181250,318000000,1000000000,12325000000],
[3626,181300,318000000,1000000000,12328400000],
[3627,181350,318000000,1000000000,12331800000],
[3628,181400,318000000,1000000000,12335200000],
[3629,181450,318500000,1000000000,12338600000],
[3630,181500,318500000,1000000000,12342000000],
[3631,181550,318500000,1000000000,12345400000],
[3632,181600,319000000,1000000000,12348800000],
[3633,181650,319000000,1000000000,12352200000],
[3634,181700,319000000,1000000000,12355600000],
[3635,181750,319500000,1000000000,12359000000],
[3636,181800,319500000,1000000000,12362400000],
[3637,181850,319500000,1000000000,12365800000],
[3638,181900,319500000,1000000000,12369200000],
[3639,181950,320000000,1000000000,12372600000],
[3640,182000,320000000,1000000000,12376000000],
[3641,182050,320000000,1000000000,12379400000],
[3642,182100,320500000,1000000000,12382800000],
[3643,182150,320500000,1000000000,12386200000],
[3644,182200,320500000,1000000000,12389600000],
[3645,182250,321000000,1000000000,12393000000],
[3646,182300,321000000,1000000000,12396400000],
[3647,182350,321000000,1000000000,12399800000],
[3648,182400,321000000,1000000000,12403200000],
[3649,182450,321500000,1000000000,12406600000],
[3650,182500,321500000,1000000000,12410000000],
[3651,182550,321500000,1000000000,12413400000],
[3652,182600,322000000,1000000000,12416800000],
[3653,182650,322000000,1000000000,12420200000],
[3654,182700,322000000,1000000000,12423600000],
[3655,182750,322500000,1000000000,12427000000],
[3656,182800,322500000,1000000000,12430400000],
[3657,182850,322500000,1000000000,12433800000],
[3658,182900,323000000,1000000000,12437200000],
[3659,182950,323000000,1000000000,12440600000],
[3660,183000,323000000,1000000000,12444000000],
[3661,183050,323000000,1000000000,12447400000],
[3662,183100,323500000,1000000000,12450800000],
[3663,183150,323500000,1000000000,12454200000],
[3664,183200,323500000,1000000000,12457600000],
[3665,183250,324000000,1000000000,12461000000],
[3666,183300,324000000,1000000000,12464400000],
[3667,183350,324000000,1000000000,12467800000],
[3668,183400,324500000,1000000000,12471200000],
[3669,183450,324500000,1000000000,12474600000],
[3670,183500,324500000,1000000000,12478000000],
[3671,183550,324500000,1000000000,12481400000],
[3672,183600,325000000,1000000000,12484800000],
[3673,183650,325000000,1000000000,12488200000],
[3674,183700,325000000,1000000000,12491600000],
[3675,183750,325500000,1000000000,12495000000],
[3676,183800,325500000,1000000000,12498400000],
[3677,183850,325500000,1000000000,12501800000],
[3678,183900,326000000,1000000000,12505200000],
[3679,183950,326000000,1000000000,12508600000],
[3680,184000,326000000,1000000000,12512000000],
[3681,184050,326000000,1000000000,12515400000],
[3682,184100,326500000,1000000000,12518800000],
[3683,184150,326500000,1000000000,12522200000],
[3684,184200,326500000,1000000000,12525600000],
[3685,184250,327000000,1000000000,12529000000],
[3686,184300,327000000,1000000000,12532400000],
[3687,184350,327000000,1000000000,12535800000],
[3688,184400,327500000,1000000000,12539200000],
[3689,184450,327500000,1000000000,12542600000],
[3690,184500,327500000,1000000000,12546000000],
[3691,184550,328000000,1000000000,12549400000],
[3692,184600,328000000,1000000000,12552800000],
[3693,184650,328000000,1000000000,12556200000],
[3694,184700,328000000,1000000000,12559600000],
[3695,184750,328500000,1000000000,12563000000],
[3696,184800,328500000,1000000000,12566400000],
[3697,184850,328500000,1000000000,12569800000],
[3698,184900,329000000,1000000000,12573200000],
[3699,184950,329000000,1000000000,12576600000],
[3700,185000,329000000,1000000000,12580000000],
[3701,185050,329500000,1000000000,12583400000],
[3702,185100,329500000,1000000000,12586800000],
[3703,185150,329500000,1000000000,12590200000],
[3704,185200,329500000,1000000000,12593600000],
[3705,185250,330000000,1000000000,12597000000],
[3706,185300,330000000,1000000000,12600400000],
[3707,185350,330000000,1000000000,12603800000],
[3708,185400,330500000,1000000000,12607200000],
[3709,185450,330500000,1000000000,12610600000],
[3710,185500,330500000,1000000000,12614000000],
[3711,185550,331000000,1000000000,12617400000],
[3712,185600,331000000,1000000000,12620800000],
[3713,185650,331000000,1000000000,12624200000],
[3714,185700,331000000,1000000000,12627600000],
[3715,185750,331500000,1000000000,12631000000],
[3716,185800,331500000,1000000000,12634400000],
[3717,185850,331500000,1000000000,12637800000],
[3718,185900,332000000,1000000000,12641200000],
[3719,185950,332000000,1000000000,12644600000],
[3720,186000,332000000,1000000000,12648000000],
[3721,186050,332500000,1000000000,12651400000],
[3722,186100,332500000,1000000000,12654800000],
[3723,186150,332500000,1000000000,12658200000],
[3724,186200,333000000,1000000000,12661600000],
[3725,186250,333000000,1000000000,12665000000],
[3726,186300,333000000,1000000000,12668400000],
[3727,186350,333000000,1000000000,12671800000],
[3728,186400,333500000,1000000000,12675200000],
[3729,186450,333500000,1000000000,12678600000],
[3730,186500,333500000,1000000000,12682000000],
[3731,186550,334000000,1000000000,12685400000],
[3732,186600,334000000,1000000000,12688800000],
[3733,186650,334000000,1000000000,12692200000],
[3734,186700,334500000,1000000000,12695600000],
[3735,186750,334500000,1000000000,12699000000],
[3736,186800,334500000,1000000000,12702400000],
[3737,186850,334500000,1000000000,12705800000],
[3738,186900,335000000,1000000000,12709200000],
[3739,186950,335000000,1000000000,12712600000],
[3740,187000,335000000,1000000000,12716000000],
[3741,187050,335500000,1000000000,12719400000],
[3742,187100,335500000,1000000000,12722800000],
[3743,187150,335500000,1000000000,12726200000],
[3744,187200,336000000,1000000000,12729600000],
[3745,187250,336000000,1000000000,12733000000],
[3746,187300,336000000,1000000000,12736400000],
[3747,187350,336000000,1000000000,12739800000],
[3748,187400,336500000,1000000000,12743200000],
[3749,187450,336500000,1000000000,12746600000],
[3750,187500,336500000,1000000000,12750000000],
[3751,187550,337000000,1000000000,12753400000],
[3752,187600,337000000,1000000000,12756800000],
[3753,187650,337000000,1000000000,12760200000],
[3754,187700,337500000,1000000000,12763600000],
[3755,187750,337500000,1000000000,12767000000],
[3756,187800,337500000,1000000000,12770400000],
[3757,187850,338000000,1000000000,12773800000],
[3758,187900,338000000,1000000000,12777200000],
[3759,187950,338000000,1000000000,12780600000],
[3760,188000,338000000,1000000000,12784000000],
[3761,188050,338500000,1000000000,12787400000],
[3762,188100,338500000,1000000000,12790800000],
[3763,188150,338500000,1000000000,12794200000],
[3764,188200,339000000,1000000000,12797600000],
[3765,188250,339000000,1000000000,12801000000],
[3766,188300,339000000,1000000000,12804400000],
[3767,188350,339500000,1000000000,12807800000],
[3768,188400,339500000,1000000000,12811200000],
[3769,188450,339500000,1000000000,12814600000],
[3770,188500,339500000,1000000000,12818000000],
[3771,188550,340000000,1000000000,12821400000],
[3772,188600,340000000,1000000000,12824800000],
[3773,188650,340000000,1000000000,12828200000],
[3774,188700,340500000,1000000000,12831600000],
[3775,188750,340500000,1000000000,12835000000],
[3776,188800,340500000,1000000000,12838400000],
[3777,188850,341000000,1000000000,12841800000],
[3778,188900,341000000,1000000000,12845200000],
[3779,188950,341000000,1000000000,12848600000],
[3780,189000,341000000,1000000000,12852000000],
[3781,189050,341500000,1000000000,12855400000],
[3782,189100,341500000,1000000000,12858800000],
[3783,189150,341500000,1000000000,12862200000],
[3784,189200,342000000,1000000000,12865600000],
[3785,189250,342000000,1000000000,12869000000],
[3786,189300,342000000,1000000000,12872400000],
[3787,189350,342500000,1000000000,12875800000],
[3788,189400,342500000,1000000000,12879200000],
[3789,189450,342500000,1000000000,12882600000],
[3790,189500,343000000,1000000000,12886000000],
[3791,189550,343000000,1000000000,12889400000],
[3792,189600,343000000,1000000000,12892800000],
[3793,189650,343000000,1000000000,12896200000],
[3794,189700,343500000,1000000000,12899600000],
[3795,189750,343500000,1000000000,12903000000],
[3796,189800,343500000,1000000000,12906400000],
[3797,189850,344000000,1000000000,12909800000],
[3798,189900,344000000,1000000000,12913200000],
[3799,189950,344000000,1000000000,12916600000],
[3800,190000,344500000,1000000000,12920000000],
[3801,190050,344500000,1000000000,12923400000],
[3802,190100,344500000,1000000000,12926800000],
[3803,190150,344500000,1000000000,12930200000],
[3804,190200,345000000,1000000000,12933600000],
[3805,190250,345000000,1000000000,12937000000],
[3806,190300,345000000,1000000000,12940400000],
[3807,190350,345500000,1000000000,12943800000],
[3808,190400,345500000,1000000000,12947200000],
[3809,190450,345500000,1000000000,12950600000],
[3810,190500,346000000,1000000000,12954000000],
[3811,190550,346000000,1000000000,12957400000],
[3812,190600,346000000,1000000000,12960800000],
[3813,190650,346000000,1000000000,12964200000],
[3814,190700,346500000,1000000000,12967600000],
[3815,190750,346500000,1000000000,12971000000],
[3816,190800,346500000,1000000000,12974400000],
[3817,190850,347000000,1000000000,12977800000],
[3818,190900,347000000,1000000000,12981200000],
[3819,190950,347000000,1000000000,12984600000],
[3820,191000,347500000,1000000000,12988000000],
[3821,191050,347500000,1000000000,12991400000],
[3822,191100,347500000,1000000000,12994800000],
[3823,191150,348000000,1000000000,12998200000],
[3824,191200,348000000,1000000000,13001600000],
[3825,191250,348000000,1000000000,13005000000],
[3826,191300,348000000,1000000000,13008400000],
[3827,191350,348500000,1000000000,13011800000],
[3828,191400,348500000,1000000000,13015200000],
[3829,191450,348500000,1000000000,13018600000],
[3830,191500,349000000,1000000000,13022000000],
[3831,191550,349000000,1000000000,13025400000],
[3832,191600,349000000,1000000000,13028800000],
[3833,191650,349500000,1000000000,13032200000],
[3834,191700,349500000,1000000000,13035600000],
[3835,191750,349500000,1000000000,13039000000],
[3836,191800,349500000,1000000000,13042400000],
[3837,191850,350000000,1000000000,13045800000],
[3838,191900,350000000,1000000000,13049200000],
[3839,191950,350000000,1000000000,13052600000],
[3840,192000,350500000,1000000000,13056000000],
[3841,192050,350500000,1000000000,13059400000],
[3842,192100,350500000,1000000000,13062800000],
[3843,192150,351000000,1000000000,13066200000],
[3844,192200,351000000,1000000000,13069600000],
[3845,192250,351000000,1000000000,13073000000],
[3846,192300,351000000,1000000000,13076400000],
[3847,192350,351500000,1000000000,13079800000],
[3848,192400,351500000,1000000000,13083200000],
[3849,192450,351500000,1000000000,13086600000],
[3850,192500,352000000,1000000000,13090000000],
[3851,192550,352000000,1000000000,13093400000],
[3852,192600,352000000,1000000000,13096800000],
[3853,192650,352500000,1000000000,13100200000],
[3854,192700,352500000,1000000000,13103600000],
[3855,192750,352500000,1000000000,13107000000],
[3856,192800,353000000,1000000000,13110400000],
[3857,192850,353000000,1000000000,13113800000],
[3858,192900,353000000,1000000000,13117200000],
[3859,192950,353000000,1000000000,13120600000],
[3860,193000,353500000,1000000000,13124000000],
[3861,193050,353500000,1000000000,13127400000],
[3862,193100,353500000,1000000000,13130800000],
[3863,193150,354000000,1000000000,13134200000],
[3864,193200,354000000,1000000000,13137600000],
[3865,193250,354000000,1000000000,13141000000],
[3866,193300,354500000,1000000000,13144400000],
[3867,193350,354500000,1000000000,13147800000],
[3868,193400,354500000,1000000000,13151200000],
[3869,193450,354500000,1000000000,13154600000],
[3870,193500,355000000,1000000000,13158000000],
[3871,193550,355000000,1000000000,13161400000],
[3872,193600,355000000,1000000000,13164800000],
[3873,193650,355500000,1000000000,13168200000],
[3874,193700,355500000,1000000000,13171600000],
[3875,193750,355500000,1000000000,13175000000],
[3876,193800,356000000,1000000000,13178400000],
[3877,193850,356000000,1000000000,13181800000],
[3878,193900,356000000,1000000000,13185200000],
[3879,193950,356000000,1000000000,13188600000],
[3880,194000,356500000,1000000000,13192000000],
[3881,194050,356500000,1000000000,13195400000],
[3882,194100,356500000,1000000000,13198800000],
[3883,194150,357000000,1000000000,13202200000],
[3884,194200,357000000,1000000000,13205600000],
[3885,194250,357000000,1000000000,13209000000],
[3886,194300,357500000,1000000000,13212400000],
[3887,194350,357500000,1000000000,13215800000],
[3888,194400,357500000,1000000000,13219200000],
[3889,194450,358000000,1000000000,13222600000],
[3890,194500,358000000,1000000000,13226000000],
[3891,194550,358000000,1000000000,13229400000],
[3892,194600,358000000,1000000000,13232800000],
[3893,194650,358500000,1000000000,13236200000],
[3894,194700,358500000,1000000000,13239600000],
[3895,194750,358500000,1000000000,13243000000],
[3896,194800,359000000,1000000000,13246400000],
[3897,194850,359000000,1000000000,13249800000],
[3898,194900,359000000,1000000000,13253200000],
[3899,194950,359500000,1000000000,13256600000],
[3900,195000,359500000,1000000000,13260000000],
[3901,195050,359500000,1000000000,13263400000],
[3902,195100,359500000,1000000000,13266800000],
[3903,195150,360000000,1000000000,13270200000],
[3904,195200,360000000,1000000000,13273600000],
[3905,195250,360000000,1000000000,13277000000],
[3906,195300,360500000,1000000000,13280400000],
[3907,195350,360500000,1000000000,13283800000],
[3908,195400,360500000,1000000000,13287200000],
[3909,195450,361000000,1000000000,13290600000],
[3910,195500,361000000,1000000000,13294000000],
[3911,195550,361000000,1000000000,13297400000],
[3912,195600,361000000,1000000000,13300800000],
[3913,195650,361500000,1000000000,13304200000],
[3914,195700,361500000,1000000000,13307600000],
[3915,195750,361500000,1000000000,13311000000],
[3916,195800,362000000,1000000000,13314400000],
[3917,195850,362000000,1000000000,13317800000],
[3918,195900,362000000,1000000000,13321200000],
[3919,195950,362500000,1000000000,13324600000],
[3920,196000,362500000,1000000000,13328000000],
[3921,196050,362500000,1000000000,13331400000],
[3922,196100,363000000,1000000000,13334800000],
[3923,196150,363000000,1000000000,13338200000],
[3924,196200,363000000,1000000000,13341600000],
[3925,196250,363000000,1000000000,13345000000],
[3926,196300,363500000,1000000000,13348400000],
[3927,196350,363500000,1000000000,13351800000],
[3928,196400,363500000,1000000000,13355200000],
[3929,196450,364000000,1000000000,13358600000],
[3930,196500,364000000,1000000000,13362000000],
[3931,196550,364000000,1000000000,13365400000],
[3932,196600,364500000,1000000000,13368800000],
[3933,196650,364500000,1000000000,13372200000],
[3934,196700,364500000,1000000000,13375600000],
[3935,196750,364500000,1000000000,13379000000],
[3936,196800,365000000,1000000000,13382400000],
[3937,196850,365000000,1000000000,13385800000],
[3938,196900,365000000,1000000000,13389200000],
[3939,196950,365500000,1000000000,13392600000],
[3940,197000,365500000,1000000000,13396000000],
[3941,197050,365500000,1000000000,13399400000],
[3942,197100,366000000,1000000000,13402800000],
[3943,197150,366000000,1000000000,13406200000],
[3944,197200,366000000,1000000000,13409600000],
[3945,197250,366000000,1000000000,13413000000],
[3946,197300,366500000,1000000000,13416400000],
[3947,197350,366500000,1000000000,13419800000],
[3948,197400,366500000,1000000000,13423200000],
[3949,197450,367000000,1000000000,13426600000],
[3950,197500,367000000,1000000000,13430000000],
[3951,197550,367000000,1000000000,13433400000],
[3952,197600,367500000,1000000000,13436800000],
[3953,197650,367500000,1000000000,13440200000],
[3954,197700,367500000,1000000000,13443600000],
[3955,197750,368000000,1000000000,13447000000],
[3956,197800,368000000,1000000000,13450400000],
[3957,197850,368000000,1000000000,13453800000],
[3958,197900,368000000,1000000000,13457200000],
[3959,197950,368500000,1000000000,13460600000],
[3960,198000,368500000,1000000000,13464000000],
[3961,198050,368500000,1000000000,13467400000],
[3962,198100,369000000,1000000000,13470800000],
[3963,198150,369000000,1000000000,13474200000],
[3964,198200,369000000,1000000000,13477600000],
[3965,198250,369500000,1000000000,13481000000],
[3966,198300,369500000,1000000000,13484400000],
[3967,198350,369500000,1000000000,13487800000],
[3968,198400,369500000,1000000000,13491200000],
[3969,198450,370000000,1000000000,13494600000],
[3970,198500,370000000,1000000000,13498000000],
[3971,198550,370000000,1000000000,13501400000],
[3972,198600,370500000,1000000000,13504800000],
[3973,198650,370500000,1000000000,13508200000],
[3974,198700,370500000,1000000000,13511600000],
[3975,198750,371000000,1000000000,13515000000],
[3976,198800,371000000,1000000000,13518400000],
[3977,198850,371000000,1000000000,13521800000],
[3978,198900,371000000,1000000000,13525200000],
[3979,198950,371500000,1000000000,13528600000],
[3980,199000,371500000,1000000000,13532000000],
[3981,199050,371500000,1000000000,13535400000],
[3982,199100,372000000,1000000000,13538800000],
[3983,199150,372000000,1000000000,13542200000],
[3984,199200,372000000,1000000000,13545600000],
[3985,199250,372500000,1000000000,13549000000],
[3986,199300,372500000,1000000000,13552400000],
[3987,199350,372500000,1000000000,13555800000],
[3988,199400,373000000,1000000000,13559200000],
[3989,199450,373000000,1000000000,13562600000],
[3990,199500,373000000,1000000000,13566000000],
[3991,199550,373000000,1000000000,13569400000],
[3992,199600,373500000,1000000000,13572800000],
[3993,199650,373500000,1000000000,13576200000],
[3994,199700,373500000,1000000000,13579600000],
[3995,199750,374000000,1000000000,13583000000],
[3996,199800,374000000,1000000000,13586400000],
[3997,199850,374000000,1000000000,13589800000],
[3998,199900,374500000,1000000000,13593200000],
[3999,199950,374500000,1000000000,13596600000],
[4000,200000,374500000,1000000000,13600000000],
[4001,200050,374500000,1000000000,13603400000],
[4002,200100,375000000,1000000000,13606800000],
[4003,200150,375000000,1000000000,13610200000],
[4004,200200,375000000,1000000000,13613600000],
[4005,200250,375500000,1000000000,13617000000],
[4006,200300,375500000,1000000000,13620400000],
[4007,200350,375500000,1000000000,13623800000],
[4008,200400,376000000,1000000000,13627200000],
[4009,200450,376000000,1000000000,13630600000],
[4010,200500,376000000,1000000000,13634000000],
[4011,200550,376000000,1000000000,13637400000],
[4012,200600,376500000,1000000000,13640800000],
[4013,200650,376500000,1000000000,13644200000],
[4014,200700,376500000,1000000000,13647600000],
[4015,200750,377000000,1000000000,13651000000],
[4016,200800,377000000,1000000000,13654400000],
[4017,200850,377000000,1000000000,13657800000],
[4018,200900,377500000,1000000000,13661200000],
[4019,200950,377500000,1000000000,13664600000],
[4020,201000,377500000,1000000000,13668000000],
[4021,201050,378000000,1000000000,13671400000],
[4022,201100,378000000,1000000000,13674800000],
[4023,201150,378000000,1000000000,13678200000],
[4024,201200,378000000,1000000000,13681600000],
[4025,201250,378500000,1000000000,13685000000],
[4026,201300,378500000,1000000000,13688400000],
[4027,201350,378500000,1000000000,13691800000],
[4028,201400,379000000,1000000000,13695200000],
[4029,201450,379000000,1000000000,13698600000],
[4030,201500,379000000,1000000000,13702000000],
[4031,201550,379500000,1000000000,13705400000],
[4032,201600,379500000,1000000000,13708800000],
[4033,201650,379500000,1000000000,13712200000],
[4034,201700,379500000,1000000000,13715600000],
[4035,201750,380000000,1000000000,13719000000],
[4036,201800,380000000,1000000000,13722400000],
[4037,201850,380000000,1000000000,13725800000],
[4038,201900,380500000,1000000000,13729200000],
[4039,201950,380500000,1000000000,13732600000],
[4040,202000,380500000,1000000000,13736000000],
[4041,202050,381000000,1000000000,13739400000],
[4042,202100,381000000,1000000000,13742800000],
[4043,202150,381000000,1000000000,13746200000],
[4044,202200,381000000,1000000000,13749600000],
[4045,202250,381500000,1000000000,13753000000],
[4046,202300,381500000,1000000000,13756400000],
[4047,202350,381500000,1000000000,13759800000],
[4048,202400,382000000,1000000000,13763200000],
[4049,202450,382000000,1000000000,13766600000],
[4050,202500,382000000,1000000000,13770000000],
[4051,202550,382500000,1000000000,13773400000],
[4052,202600,382500000,1000000000,13776800000],
[4053,202650,382500000,1000000000,13780200000],
[4054,202700,383000000,1000000000,13783600000],
[4055,202750,383000000,1000000000,13787000000],
[4056,202800,383000000,1000000000,13790400000],
[4057,202850,383000000,1000000000,13793800000],
[4058,202900,383500000,1000000000,13797200000],
[4059,202950,383500000,1000000000,13800600000],
[4060,203000,383500000,1000000000,13804000000],
[4061,203050,384000000,1000000000,13807400000],
[4062,203100,384000000,1000000000,13810800000],
[4063,203150,384000000,1000000000,13814200000],
[4064,203200,384500000,1000000000,13817600000],
[4065,203250,384500000,1000000000,13821000000],
[4066,203300,384500000,1000000000,13824400000],
[4067,203350,384500000,1000000000,13827800000],
[4068,203400,385000000,1000000000,13831200000],
[4069,203450,385000000,1000000000,13834600000],
[4070,203500,385000000,1000000000,13838000000],
[4071,203550,385500000,1000000000,13841400000],
[4072,203600,385500000,1000000000,13844800000],
[4073,203650,385500000,1000000000,13848200000],
[4074,203700,386000000,1000000000,13851600000],
[4075,203750,386000000,1000000000,13855000000],
[4076,203800,386000000,1000000000,13858400000],
[4077,203850,386000000,1000000000,13861800000],
[4078,203900,386500000,1000000000,13865200000],
[4079,203950,386500000,1000000000,13868600000],
[4080,204000,386500000,1000000000,13872000000],
[4081,204050,387000000,1000000000,13875400000],
[4082,204100,387000000,1000000000,13878800000],
[4083,204150,387000000,1000000000,13882200000],
[4084,204200,387500000,1000000000,13885600000],
[4085,204250,387500000,1000000000,13889000000],
[4086,204300,387500000,1000000000,13892400000],
[4087,204350,388000000,1000000000,13895800000],
[4088,204400,388000000,1000000000,13899200000],
[4089,204450,388000000,1000000000,13902600000],
[4090,204500,388000000,1000000000,13906000000],
[4091,204550,388500000,1000000000,13909400000],
[4092,204600,388500000,1000000000,13912800000],
[4093,204650,388500000,1000000000,13916200000],
[4094,204700,389000000,1000000000,13919600000],
[4095,204750,389000000,1000000000,13923000000],
[4096,204800,389000000,1000000000,13926400000],
[4097,204850,389500000,1000000000,13929800000],
[4098,204900,389500000,1000000000,13933200000],
[4099,204950,389500000,1000000000,13936600000],
[4100,205000,389500000,1000000000,13940000000],
[4101,205050,390000000,1000000000,13943400000],
[4102,205100,390000000,1000000000,13946800000],
[4103,205150,390000000,1000000000,13950200000],
[4104,205200,390500000,1000000000,13953600000],
[4105,205250,390500000,1000000000,13957000000],
[4106,205300,390500000,1000000000,13960400000],
[4107,205350,391000000,1000000000,13963800000],
[4108,205400,391000000,1000000000,13967200000],
[4109,205450,391000000,1000000000,13970600000],
[4110,205500,391000000,1000000000,13974000000],
[4111,205550,391500000,1000000000,13977400000],
[4112,205600,391500000,1000000000,13980800000],
[4113,205650,391500000,1000000000,13984200000],
[4114,205700,392000000,1000000000,13987600000],
[4115,205750,392000000,1000000000,13991000000],
[4116,205800,392000000,1000000000,13994400000],
[4117,205850,392500000,1000000000,13997800000],
[4118,205900,392500000,1000000000,14001200000],
[4119,205950,392500000,1000000000,14004600000],
[4120,206000,393000000,1000000000,14008000000],
[4121,206050,393000000,1000000000,14011400000],
[4122,206100,393000000,1000000000,14014800000],
[4123,206150,393000000,1000000000,14018200000],
[4124,206200,393500000,1000000000,14021600000],
[4125,206250,393500000,1000000000,14025000000],
[4126,206300,393500000,1000000000,14028400000],
[4127,206350,394000000,1000000000,14031800000],
[4128,206400,394000000,1000000000,14035200000],
[4129,206450,394000000,1000000000,14038600000],
[4130,206500,394500000,1000000000,14042000000],
[4131,206550,394500000,1000000000,14045400000],
[4132,206600,394500000,1000000000,14048800000],
[4133,206650,394500000,1000000000,14052200000],
[4134,206700,395000000,1000000000,14055600000],
[4135,206750,395000000,1000000000,14059000000],
[4136,206800,395000000,1000000000,14062400000],
[4137,206850,395500000,1000000000,14065800000],
[4138,206900,395500000,1000000000,14069200000],
[4139,206950,395500000,1000000000,14072600000],
[4140,207000,396000000,1000000000,14076000000],
[4141,207050,396000000,1000000000,14079400000],
[4142,207100,396000000,1000000000,14082800000],
[4143,207150,396000000,1000000000,14086200000],
[4144,207200,396500000,1000000000,14089600000],
[4145,207250,396500000,1000000000,14093000000],
[4146,207300,396500000,1000000000,14096400000],
[4147,207350,397000000,1000000000,14099800000],
[4148,207400,397000000,1000000000,14103200000],
[4149,207450,397000000,1000000000,14106600000],
[4150,207500,397500000,1000000000,14110000000],
[4151,207550,397500000,1000000000,14113400000],
[4152,207600,397500000,1000000000,14116800000],
[4153,207650,398000000,1000000000,14120200000],
[4154,207700,398000000,1000000000,14123600000],
[4155,207750,398000000,1000000000,14127000000],
[4156,207800,398000000,1000000000,14130400000],
[4157,207850,398500000,1000000000,14133800000],
[4158,207900,398500000,1000000000,14137200000],
[4159,207950,398500000,1000000000,14140600000],
[4160,208000,399000000,1000000000,14144000000],
[4161,208050,399000000,1000000000,14147400000],
[4162,208100,399000000,1000000000,14150800000],
[4163,208150,399500000,1000000000,14154200000],
[4164,208200,399500000,1000000000,14157600000],
[4165,208250,399500000,1000000000,14161000000],
[4166,208300,399500000,1000000000,14164400000],
[4167,208350,400000000,1000000000,14167800000],
[4168,208400,400000000,1000000000,14171200000],
[4169,208450,400000000,1000000000,14174600000],
[4170,208500,400500000,1000000000,14178000000],
[4171,208550,400500000,1000000000,14181400000],
[4172,208600,400500000,1000000000,14184800000],
[4173,208650,401000000,1000000000,14188200000],
[4174,208700,401000000,1000000000,14191600000],
[4175,208750,401000000,1000000000,14195000000],
[4176,208800,401000000,1000000000,14198400000],
[4177,208850,401500000,1000000000,14201800000],
[4178,208900,401500000,1000000000,14205200000],
[4179,208950,401500000,1000000000,14208600000],
[4180,209000,402000000,1000000000,14212000000],
[4181,209050,402000000,1000000000,14215400000],
[4182,209100,402000000,1000000000,14218800000],
[4183,209150,402500000,1000000000,14222200000],
[4184,209200,402500000,1000000000,14225600000],
[4185,209250,402500000,1000000000,14229000000],
[4186,209300,403000000,1000000000,14232400000],
[4187,209350,403000000,1000000000,14235800000],
[4188,209400,403000000,1000000000,14239200000],
[4189,209450,403000000,1000000000,14242600000],
[4190,209500,403500000,1000000000,14246000000],
[4191,209550,403500000,1000000000,14249400000],
[4192,209600,403500000,1000000000,14252800000],
[4193,209650,404000000,1000000000,14256200000],
[4194,209700,404000000,1000000000,14259600000],
[4195,209750,404000000,1000000000,14263000000],
[4196,209800,404500000,1000000000,14266400000],
[4197,209850,404500000,1000000000,14269800000],
[4198,209900,404500000,1000000000,14273200000],
[4199,209950,404500000,1000000000,14276600000],
[4200,210000,405000000,1000000000,14280000000],
[4201,210050,405000000,1000000000,14283400000],
[4202,210100,405000000,1000000000,14286800000],
[4203,210150,405500000,1000000000,14290200000],
[4204,210200,405500000,1000000000,14293600000],
[4205,210250,405500000,1000000000,14297000000],
[4206,210300,406000000,1000000000,14300400000],
[4207,210350,406000000,1000000000,14303800000],
[4208,210400,406000000,1000000000,14307200000],
[4209,210450,406000000,1000000000,14310600000],
[4210,210500,406500000,1000000000,14314000000],
[4211,210550,406500000,1000000000,14317400000],
[4212,210600,406500000,1000000000,14320800000],
[4213,210650,407000000,1000000000,14324200000],
[4214,210700,407000000,1000000000,14327600000],
[4215,210750,407000000,1000000000,14331000000],
[4216,210800,407500000,1000000000,14334400000],
[4217,210850,407500000,1000000000,14337800000],
[4218,210900,407500000,1000000000,14341200000],
[4219,210950,408000000,1000000000,14344600000],
[4220,211000,408000000,1000000000,14348000000],
[4221,211050,408000000,1000000000,14351400000],
[4222,211100,408000000,1000000000,14354800000],
[4223,211150,408500000,1000000000,14358200000],
[4224,211200,408500000,1000000000,14361600000],
[4225,211250,408500000,1000000000,14365000000],
[4226,211300,409000000,1000000000,14368400000],
[4227,211350,409000000,1000000000,14371800000],
[4228,211400,409000000,1000000000,14375200000],
[4229,211450,409500000,1000000000,14378600000],
[4230,211500,409500000,1000000000,14382000000],
[4231,211550,409500000,1000000000,14385400000],
[4232,211600,409500000,1000000000,14388800000],
[4233,211650,410000000,1000000000,14392200000],
[4234,211700,410000000,1000000000,14395600000],
[4235,211750,410000000,1000000000,14399000000],
[4236,211800,410500000,1000000000,14402400000],
[4237,211850,410500000,1000000000,14405800000],
[4238,211900,410500000,1000000000,14409200000],
[4239,211950,411000000,1000000000,14412600000],
[4240,212000,411000000,1000000000,14416000000],
[4241,212050,411000000,1000000000,14419400000],
[4242,212100,411000000,1000000000,14422800000],
[4243,212150,411500000,1000000000,14426200000],
[4244,212200,411500000,1000000000,14429600000],
[4245,212250,411500000,1000000000,14433000000],
[4246,212300,412000000,1000000000,14436400000],
[4247,212350,412000000,1000000000,14439800000],
[4248,212400,412000000,1000000000,14443200000],
[4249,212450,412500000,1000000000,14446600000],
[4250,212500,412500000,1000000000,14450000000],
[4251,212550,412500000,1000000000,14453400000],
[4252,212600,413000000,1000000000,14456800000],
[4253,212650,413000000,1000000000,14460200000],
[4254,212700,413000000,1000000000,14463600000],
[4255,212750,413000000,1000000000,14467000000],
[4256,212800,413500000,1000000000,14470400000],
[4257,212850,413500000,1000000000,14473800000],
[4258,212900,413500000,1000000000,14477200000],
[4259,212950,414000000,1000000000,14480600000],
[4260,213000,414000000,1000000000,14484000000],
[4261,213050,414000000,1000000000,14487400000],
[4262,213100,414500000,1000000000,14490800000],
[4263,213150,414500000,1000000000,14494200000],
[4264,213200,414500000,1000000000,14497600000],
[4265,213250,414500000,1000000000,14501000000],
[4266,213300,415000000,1000000000,14504400000],
[4267,213350,415000000,1000000000,14507800000],
[4268,213400,415000000,1000000000,14511200000],
[4269,213450,415500000,1000000000,14514600000],
[4270,213500,415500000,1000000000,14518000000],
[4271,213550,415500000,1000000000,14521400000],
[4272,213600,416000000,1000000000,14524800000],
[4273,213650,416000000,1000000000,14528200000],
[4274,213700,416000000,1000000000,14531600000],
[4275,213750,416000000,1000000000,14535000000],
[4276,213800,416500000,1000000000,14538400000],
[4277,213850,416500000,1000000000,14541800000],
[4278,213900,416500000,1000000000,14545200000],
[4279,213950,417000000,1000000000,14548600000],
[4280,214000,417000000,1000000000,14552000000],
[4281,214050,417000000,1000000000,14555400000],
[4282,214100,417500000,1000000000,14558800000],
[4283,214150,417500000,1000000000,14562200000],
[4284,214200,417500000,1000000000,14565600000],
[4285,214250,418000000,1000000000,14569000000],
[4286,214300,418000000,1000000000,14572400000],
[4287,214350,418000000,1000000000,14575800000],
[4288,214400,418000000,1000000000,14579200000],
[4289,214450,418500000,1000000000,14582600000],
[4290,214500,418500000,1000000000,14586000000],
[4291,214550,418500000,1000000000,14589400000],
[4292,214600,419000000,1000000000,14592800000],
[4293,214650,419000000,1000000000,14596200000],
[4294,214700,419000000,1000000000,14599600000],
[4295,214750,419500000,1000000000,14603000000],
[4296,214800,419500000,1000000000,14606400000],
[4297,214850,419500000,1000000000,14609800000],
[4298,214900,419500000,1000000000,14613200000],
[4299,214950,420000000,1000000000,14616600000],
[4300,215000,420000000,1000000000,14620000000],
[4301,215050,420000000,1000000000,14623400000],
[4302,215100,420500000,1000000000,14626800000],
[4303,215150,420500000,1000000000,14630200000],
[4304,215200,420500000,1000000000,14633600000],
[4305,215250,421000000,1000000000,14637000000],
[4306,215300,421000000,1000000000,14640400000],
[4307,215350,421000000,1000000000,14643800000],
[4308,215400,421000000,1000000000,14647200000],
[4309,215450,421500000,1000000000,14650600000],
[4310,215500,421500000,1000000000,14654000000],
[4311,215550,421500000,1000000000,14657400000],
[4312,215600,422000000,1000000000,14660800000],
[4313,215650,422000000,1000000000,14664200000],
[4314,215700,422000000,1000000000,14667600000],
[4315,215750,422500000,1000000000,14671000000],
[4316,215800,422500000,1000000000,14674400000],
[4317,215850,422500000,1000000000,14677800000],
[4318,215900,423000000,1000000000,14681200000],
[4319,215950,423000000,1000000000,14684600000],
[4320,216000,423000000,1000000000,14688000000],
[4321,216050,423000000,1000000000,14691400000],
[4322,216100,423500000,1000000000,14694800000],
[4323,216150,423500000,1000000000,14698200000],
[4324,216200,423500000,1000000000,14701600000],
[4325,216250,424000000,1000000000,14705000000],
[4326,216300,424000000,1000000000,14708400000],
[4327,216350,424000000,1000000000,14711800000],
[4328,216400,424500000,1000000000,14715200000],
[4329,216450,424500000,1000000000,14718600000],
[4330,216500,424500000,1000000000,14722000000],
[4331,216550,424500000,1000000000,14725400000],
[4332,216600,425000000,1000000000,14728800000],
[4333,216650,425000000,1000000000,14732200000],
[4334,216700,425000000,1000000000,14735600000],
[4335,216750,425500000,1000000000,14739000000],
[4336,216800,425500000,1000000000,14742400000],
[4337,216850,425500000,1000000000,14745800000],
[4338,216900,426000000,1000000000,14749200000],
[4339,216950,426000000,1000000000,14752600000],
[4340,217000,426000000,1000000000,14756000000],
[4341,217050,426000000,1000000000,14759400000],
[4342,217100,426500000,1000000000,14762800000],
[4343,217150,426500000,1000000000,14766200000],
[4344,217200,426500000,1000000000,14769600000],
[4345,217250,427000000,1000000000,14773000000],
[4346,217300,427000000,1000000000,14776400000],
[4347,217350,427000000,1000000000,14779800000],
[4348,217400,427500000,1000000000,14783200000],
[4349,217450,427500000,1000000000,14786600000],
[4350,217500,427500000,1000000000,14790000000],
[4351,217550,428000000,1000000000,14793400000],
[4352,217600,428000000,1000000000,14796800000],
[4353,217650,428000000,1000000000,14800200000],
[4354,217700,428000000,1000000000,14803600000],
[4355,217750,428500000,1000000000,14807000000],
[4356,217800,428500000,1000000000,14810400000],
[4357,217850,428500000,1000000000,14813800000],
[4358,217900,429000000,1000000000,14817200000],
[4359,217950,429000000,1000000000,14820600000],
[4360,218000,429000000,1000000000,14824000000],
[4361,218050,429500000,1000000000,14827400000],
[4362,218100,429500000,1000000000,14830800000],
[4363,218150,429500000,1000000000,14834200000],
[4364,218200,429500000,1000000000,14837600000],
[4365,218250,430000000,1000000000,14841000000],
[4366,218300,430000000,1000000000,14844400000],
[4367,218350,430000000,1000000000,14847800000],
[4368,218400,430500000,1000000000,14851200000],
[4369,218450,430500000,1000000000,14854600000],
[4370,218500,430500000,1000000000,14858000000],
[4371,218550,431000000,1000000000,14861400000],
[4372,218600,431000000,1000000000,14864800000],
[4373,218650,431000000,1000000000,14868200000],
[4374,218700,431000000,1000000000,14871600000],
[4375,218750,431500000,1000000000,14875000000],
[4376,218800,431500000,1000000000,14878400000],
[4377,218850,431500000,1000000000,14881800000],
[4378,218900,432000000,1000000000,14885200000],
[4379,218950,432000000,1000000000,14888600000],
[4380,219000,432000000,1000000000,14892000000],
[4381,219050,432500000,1000000000,14895400000],
[4382,219100,432500000,1000000000,14898800000],
[4383,219150,432500000,1000000000,14902200000],
[4384,219200,433000000,1000000000,14905600000],
[4385,219250,433000000,1000000000,14909000000],
[4386,219300,433000000,1000000000,14912400000],
[4387,219350,433000000,1000000000,14915800000],
[4388,219400,433500000,1000000000,14919200000],
[4389,219450,433500000,1000000000,14922600000],
[4390,219500,433500000,1000000000,14926000000],
[4391,219550,434000000,1000000000,14929400000],
[4392,219600,434000000,1000000000,14932800000],
[4393,219650,434000000,1000000000,14936200000],
[4394,219700,434500000,1000000000,14939600000],
[4395,219750,434500000,1000000000,14943000000],
[4396,219800,434500000,1000000000,14946400000],
[4397,219850,434500000,1000000000,14949800000],
[4398,219900,435000000,1000000000,14953200000],
[4399,219950,435000000,1000000000,14956600000],
[4400,220000,435000000,1000000000,14960000000],
[4401,220050,435500000,1000000000,14963400000],
[4402,220100,435500000,1000000000,14966800000],
[4403,220150,435500000,1000000000,14970200000],
[4404,220200,436000000,1000000000,14973600000],
[4405,220250,436000000,1000000000,14977000000],
[4406,220300,436000000,1000000000,14980400000],
[4407,220350,436000000,1000000000,14983800000],
[4408,220400,436500000,1000000000,14987200000],
[4409,220450,436500000,1000000000,14990600000],
[4410,220500,436500000,1000000000,14994000000],
[4411,220550,437000000,1000000000,14997400000],
[4412,220600,437000000,1000000000,15000800000],
[4413,220650,437000000,1000000000,15004200000],
[4414,220700,437500000,1000000000,15007600000],
[4415,220750,437500000,1000000000,15011000000],
[4416,220800,437500000,1000000000,15014400000],
[4417,220850,438000000,1000000000,15017800000],
[4418,220900,438000000,1000000000,15021200000],
[4419,220950,438000000,1000000000,15024600000],
[4420,221000,438000000,1000000000,15028000000],
[4421,221050,438500000,1000000000,15031400000],
[4422,221100,438500000,1000000000,15034800000],
[4423,221150,438500000,1000000000,15038200000],
[4424,221200,439000000,1000000000,15041600000],
[4425,221250,439000000,1000000000,15045000000],
[4426,221300,439000000,1000000000,15048400000],
[4427,221350,439500000,1000000000,15051800000],
[4428,221400,439500000,1000000000,15055200000],
[4429,221450,439500000,1000000000,15058600000],
[4430,221500,439500000,1000000000,15062000000],
[4431,221550,440000000,1000000000,15065400000],
[4432,221600,440000000,1000000000,15068800000],
[4433,221650,440000000,1000000000,15072200000],
[4434,221700,440500000,1000000000,15075600000],
[4435,221750,440500000,1000000000,15079000000],
[4436,221800,440500000,1000000000,15082400000],
[4437,221850,441000000,1000000000,15085800000],
[4438,221900,441000000,1000000000,15089200000],
[4439,221950,441000000,1000000000,15092600000],
[4440,222000,441000000,1000000000,15096000000],
[4441,222050,441500000,1000000000,15099400000],
[4442,222100,441500000,1000000000,15102800000],
[4443,222150,441500000,1000000000,15106200000],
[4444,222200,442000000,1000000000,15109600000],
[4445,222250,442000000,1000000000,15113000000],
[4446,222300,442000000,1000000000,15116400000],
[4447,222350,442500000,1000000000,15119800000],
[4448,222400,442500000,1000000000,15123200000],
[4449,222450,442500000,1000000000,15126600000],
[4450,222500,443000000,1000000000,15130000000],
[4451,222550,443000000,1000000000,15133400000],
[4452,222600,443000000,1000000000,15136800000],
[4453,222650,443000000,1000000000,15140200000],
[4454,222700,443500000,1000000000,15143600000],
[4455,222750,443500000,1000000000,15147000000],
[4456,222800,443500000,1000000000,15150400000],
[4457,222850,444000000,1000000000,15153800000],
[4458,222900,444000000,1000000000,15157200000],
[4459,222950,444000000,1000000000,15160600000],
[4460,223000,444500000,1000000000,15164000000],
[4461,223050,444500000,1000000000,15167400000],
[4462,223100,444500000,1000000000,15170800000],
[4463,223150,444500000,1000000000,15174200000],
[4464,223200,445000000,1000000000,15177600000],
[4465,223250,445000000,1000000000,15181000000],
[4466,223300,445000000,1000000000,15184400000],
[4467,223350,445500000,1000000000,15187800000],
[4468,223400,445500000,1000000000,15191200000],
[4469,223450,445500000,1000000000,15194600000],
[4470,223500,446000000,1000000000,15198000000],
[4471,223550,446000000,1000000000,15201400000],
[4472,223600,446000000,1000000000,15204800000],
[4473,223650,446000000,1000000000,15208200000],
[4474,223700,446500000,1000000000,15211600000],
[4475,223750,446500000,1000000000,15215000000],
[4476,223800,446500000,1000000000,15218400000],
[4477,223850,447000000,1000000000,15221800000],
[4478,223900,447000000,1000000000,15225200000],
[4479,223950,447000000,1000000000,15228600000],
[4480,224000,447500000,1000000000,15232000000],
[4481,224050,447500000,1000000000,15235400000],
[4482,224100,447500000,1000000000,15238800000],
[4483,224150,448000000,1000000000,15242200000],
[4484,224200,448000000,1000000000,15245600000],
[4485,224250,448000000,1000000000,15249000000],
[4486,224300,448000000,1000000000,15252400000],
[4487,224350,448500000,1000000000,15255800000],
[4488,224400,448500000,1000000000,15259200000],
[4489,224450,448500000,1000000000,15262600000],
[4490,224500,449000000,1000000000,15266000000],
[4491,224550,449000000,1000000000,15269400000],
[4492,224600,449000000,1000000000,15272800000],
[4493,224650,449500000,1000000000,15276200000],
[4494,224700,449500000,1000000000,15279600000],
[4495,224750,449500000,1000000000,15283000000],
[4496,224800,449500000,1000000000,15286400000],
[4497,224850,450000000,1000000000,15289800000],
[4498,224900,450000000,1000000000,15293200000],
[4499,224950,450000000,1000000000,15296600000],
[4500,225000,450500000,1000000000,15300000000],
[4501,225050,450500000,1000000000,15303400000],
[4502,225100,450500000,1000000000,15306800000],
[4503,225150,451000000,1000000000,15310200000],
[4504,225200,451000000,1000000000,15313600000],
[4505,225250,451000000,1000000000,15317000000],
[4506,225300,451000000,1000000000,15320400000],
[4507,225350,451500000,1000000000,15323800000],
[4508,225400,451500000,1000000000,15327200000],
[4509,225450,451500000,1000000000,15330600000],
[4510,225500,452000000,1000000000,15334000000],
[4511,225550,452000000,1000000000,15337400000],
[4512,225600,452000000,1000000000,15340800000],
[4513,225650,452500000,1000000000,15344200000],
[4514,225700,452500000,1000000000,15347600000],
[4515,225750,452500000,1000000000,15351000000],
[4516,225800,453000000,1000000000,15354400000],
[4517,225850,453000000,1000000000,15357800000],
[4518,225900,453000000,1000000000,15361200000],
[4519,225950,453000000,1000000000,15364600000],
[4520,226000,453500000,1000000000,15368000000],
[4521,226050,453500000,1000000000,15371400000],
[4522,226100,453500000,1000000000,15374800000],
[4523,226150,454000000,1000000000,15378200000],
[4524,226200,454000000,1000000000,15381600000],
[4525,226250,454000000,1000000000,15385000000],
[4526,226300,454500000,1000000000,15388400000],
[4527,226350,454500000,1000000000,15391800000],
[4528,226400,454500000,1000000000,15395200000],
[4529,226450,454500000,1000000000,15398600000],
[4530,226500,455000000,1000000000,15402000000],
[4531,226550,455000000,1000000000,15405400000],
[4532,226600,455000000,1000000000,15408800000],
[4533,226650,455500000,1000000000,15412200000],
[4534,226700,455500000,1000000000,15415600000],
[4535,226750,455500000,1000000000,15419000000],
[4536,226800,456000000,1000000000,15422400000],
[4537,226850,456000000,1000000000,15425800000],
[4538,226900,456000000,1000000000,15429200000],
[4539,226950,456000000,1000000000,15432600000],
[4540,227000,456500000,1000000000,15436000000],
[4541,227050,456500000,1000000000,15439400000],
[4542,227100,456500000,1000000000,15442800000],
[4543,227150,457000000,1000000000,15446200000],
[4544,227200,457000000,1000000000,15449600000],
[4545,227250,457000000,1000000000,15453000000],
[4546,227300,457500000,1000000000,15456400000],
[4547,227350,457500000,1000000000,15459800000],
[4548,227400,457500000,1000000000,15463200000],
[4549,227450,458000000,1000000000,15466600000],
[4550,227500,458000000,1000000000,15470000000],
[4551,227550,458000000,1000000000,15473400000],
[4552,227600,458000000,1000000000,15476800000],
[4553,227650,458500000,1000000000,15480200000],
[4554,227700,458500000,1000000000,15483600000],
[4555,227750,458500000,1000000000,15487000000],
[4556,227800,459000000,1000000000,15490400000],
[4557,227850,459000000,1000000000,15493800000],
[4558,227900,459000000,1000000000,15497200000],
[4559,227950,459500000,1000000000,15500600000],
[4560,228000,459500000,1000000000,15504000000],
[4561,228050,459500000,1000000000,15507400000],
[4562,228100,459500000,1000000000,15510800000],
[4563,228150,460000000,1000000000,15514200000],
[4564,228200,460000000,1000000000,15517600000],
[4565,228250,460000000,1000000000,15521000000],
[4566,228300,460500000,1000000000,15524400000],
[4567,228350,460500000,1000000000,15527800000],
[4568,228400,460500000,1000000000,15531200000],
[4569,228450,461000000,1000000000,15534600000],
[4570,228500,461000000,1000000000,15538000000],
[4571,228550,461000000,1000000000,15541400000],
[4572,228600,461000000,1000000000,15544800000],
[4573,228650,461500000,1000000000,15548200000],
[4574,228700,461500000,1000000000,15551600000],
[4575,228750,461500000,1000000000,15555000000],
[4576,228800,462000000,1000000000,15558400000],
[4577,228850,462000000,1000000000,15561800000],
[4578,228900,462000000,1000000000,15565200000],
[4579,228950,462500000,1000000000,15568600000],
[4580,229000,462500000,1000000000,15572000000],
[4581,229050,462500000,1000000000,15575400000],
[4582,229100,463000000,1000000000,15578800000],
[4583,229150,463000000,1000000000,15582200000],
[4584,229200,463000000,1000000000,15585600000],
[4585,229250,463000000,1000000000,15589000000],
[4586,229300,463500000,1000000000,15592400000],
[4587,229350,463500000,1000000000,15595800000],
[4588,229400,463500000,1000000000,15599200000],
[4589,229450,464000000,1000000000,15602600000],
[4590,229500,464000000,1000000000,15606000000],
[4591,229550,464000000,1000000000,15609400000],
[4592,229600,464500000,1000000000,15612800000],
[4593,229650,464500000,1000000000,15616200000],
[4594,229700,464500000,1000000000,15619600000],
[4595,229750,464500000,1000000000,15623000000],
[4596,229800,465000000,1000000000,15626400000],
[4597,229850,465000000,1000000000,15629800000],
[4598,229900,465000000,1000000000,15633200000],
[4599,229950,465500000,1000000000,15636600000],
[4600,230000,465500000,1000000000,15640000000],
[4601,230050,465500000,1000000000,15643400000],
[4602,230100,466000000,1000000000,15646800000],
[4603,230150,466000000,1000000000,15650200000],
[4604,230200,466000000,1000000000,15653600000],
[4605,230250,466000000,1000000000,15657000000],
[4606,230300,466500000,1000000000,15660400000],
[4607,230350,466500000,1000000000,15663800000],
[4608,230400,466500000,1000000000,15667200000],
[4609,230450,467000000,1000000000,15670600000],
[4610,230500,467000000,1000000000,15674000000],
[4611,230550,467000000,1000000000,15677400000],
[4612,230600,467500000,1000000000,15680800000],
[4613,230650,467500000,1000000000,15684200000],
[4614,230700,467500000,1000000000,15687600000],
[4615,230750,468000000,1000000000,15691000000],
[4616,230800,468000000,1000000000,15694400000],
[4617,230850,468000000,1000000000,15697800000],
[4618,230900,468000000,1000000000,15701200000],
[4619,230950,468500000,1000000000,15704600000],
[4620,231000,468500000,1000000000,15708000000],
[4621,231050,468500000,1000000000,15711400000],
[4622,231100,469000000,1000000000,15714800000],
[4623,231150,469000000,1000000000,15718200000],
[4624,231200,469000000,1000000000,15721600000],
[4625,231250,469500000,1000000000,15725000000],
[4626,231300,469500000,1000000000,15728400000],
[4627,231350,469500000,1000000000,15731800000],
[4628,231400,469500000,1000000000,15735200000],
[4629,231450,470000000,1000000000,15738600000],
[4630,231500,470000000,1000000000,15742000000],
[4631,231550,470000000,1000000000,15745400000],
[4632,231600,470500000,1000000000,15748800000],
[4633,231650,470500000,1000000000,15752200000],
[4634,231700,470500000,1000000000,15755600000],
[4635,231750,471000000,1000000000,15759000000],
[4636,231800,471000000,1000000000,15762400000],
[4637,231850,471000000,1000000000,15765800000],
[4638,231900,471000000,1000000000,15769200000],
[4639,231950,471500000,1000000000,15772600000],
[4640,232000,471500000,1000000000,15776000000],
[4641,232050,471500000,1000000000,15779400000],
[4642,232100,472000000,1000000000,15782800000],
[4643,232150,472000000,1000000000,15786200000],
[4644,232200,472000000,1000000000,15789600000],
[4645,232250,472500000,1000000000,15793000000],
[4646,232300,472500000,1000000000,15796400000],
[4647,232350,472500000,1000000000,15799800000],
[4648,232400,473000000,1000000000,15803200000],
[4649,232450,473000000,1000000000,15806600000],
[4650,232500,473000000,1000000000,15810000000],
[4651,232550,473000000,1000000000,15813400000],
[4652,232600,473500000,1000000000,15816800000],
[4653,232650,473500000,1000000000,15820200000],
[4654,232700,473500000,1000000000,15823600000],
[4655,232750,474000000,1000000000,15827000000],
[4656,232800,474000000,1000000000,15830400000],
[4657,232850,474000000,1000000000,15833800000],
[4658,232900,474500000,1000000000,15837200000],
[4659,232950,474500000,1000000000,15840600000],
[4660,233000,474500000,1000000000,15844000000],
[4661,233050,474500000,1000000000,15847400000],
[4662,233100,475000000,1000000000,15850800000],
[4663,233150,475000000,1000000000,15854200000],
[4664,233200,475000000,1000000000,15857600000],
[4665,233250,475500000,1000000000,15861000000],
[4666,233300,475500000,1000000000,15864400000],
[4667,233350,475500000,1000000000,15867800000],
[4668,233400,476000000,1000000000,15871200000],
[4669,233450,476000000,1000000000,15874600000],
[4670,233500,476000000,1000000000,15878000000],
[4671,233550,476000000,1000000000,15881400000],
[4672,233600,476500000,1000000000,15884800000],
[4673,233650,476500000,1000000000,15888200000],
[4674,233700,476500000,1000000000,15891600000],
[4675,233750,477000000,1000000000,15895000000],
[4676,233800,477000000,1000000000,15898400000],
[4677,233850,477000000,1000000000,15901800000],
[4678,233900,477500000,1000000000,15905200000],
[4679,233950,477500000,1000000000,15908600000],
[4680,234000,477500000,1000000000,15912000000],
[4681,234050,478000000,1000000000,15915400000],
[4682,234100,478000000,1000000000,15918800000],
[4683,234150,478000000,1000000000,15922200000],
[4684,234200,478000000,1000000000,15925600000],
[4685,234250,478500000,1000000000,15929000000],
[4686,234300,478500000,1000000000,15932400000],
[4687,234350,478500000,1000000000,15935800000],
[4688,234400,479000000,1000000000,15939200000],
[4689,234450,479000000,1000000000,15942600000],
[4690,234500,479000000,1000000000,15946000000],
[4691,234550,479500000,1000000000,15949400000],
[4692,234600,479500000,1000000000,15952800000],
[4693,234650,479500000,1000000000,15956200000],
[4694,234700,479500000,1000000000,15959600000],
[4695,234750,480000000,1000000000,15963000000],
[4696,234800,480000000,1000000000,15966400000],
[4697,234850,480000000,1000000000,15969800000],
[4698,234900,480500000,1000000000,15973200000],
[4699,234950,480500000,1000000000,15976600000],
[4700,235000,480500000,1000000000,15980000000],
[4701,235050,481000000,1000000000,15983400000],
[4702,235100,481000000,1000000000,15986800000],
[4703,235150,481000000,1000000000,15990200000],
[4704,235200,481000000,1000000000,15993600000],
[4705,235250,481500000,1000000000,15997000000],
[4706,235300,481500000,1000000000,16000400000],
[4707,235350,481500000,1000000000,16003800000],
[4708,235400,482000000,1000000000,16007200000],
[4709,235450,482000000,1000000000,16010600000],
[4710,235500,482000000,1000000000,16014000000],
[4711,235550,482500000,1000000000,16017400000],
[4712,235600,482500000,1000000000,16020800000],
[4713,235650,482500000,1000000000,16024200000],
[4714,235700,483000000,1000000000,16027600000],
[4715,235750,483000000,1000000000,16031000000],
[4716,235800,483000000,1000000000,16034400000],
[4717,235850,483000000,1000000000,16037800000],
[4718,235900,483500000,1000000000,16041200000],
[4719,235950,483500000,1000000000,16044600000],
[4720,236000,483500000,1000000000,16048000000],
[4721,236050,484000000,1000000000,16051400000],
[4722,236100,484000000,1000000000,16054800000],
[4723,236150,484000000,1000000000,16058200000],
[4724,236200,484500000,1000000000,16061600000],
[4725,236250,484500000,1000000000,16065000000],
[4726,236300,484500000,1000000000,16068400000],
[4727,236350,484500000,1000000000,16071800000],
[4728,236400,485000000,1000000000,16075200000],
[4729,236450,485000000,1000000000,16078600000],
[4730,236500,485000000,1000000000,16082000000],
[4731,236550,485500000,1000000000,16085400000],
[4732,236600,485500000,1000000000,16088800000],
[4733,236650,485500000,1000000000,16092200000],
[4734,236700,486000000,1000000000,16095600000],
[4735,236750,486000000,1000000000,16099000000],
[4736,236800,486000000,1000000000,16102400000],
[4737,236850,486000000,1000000000,16105800000],
[4738,236900,486500000,1000000000,16109200000],
[4739,236950,486500000,1000000000,16112600000],
[4740,237000,486500000,1000000000,16116000000],
[4741,237050,487000000,1000000000,16119400000],
[4742,237100,487000000,1000000000,16122800000],
[4743,237150,487000000,1000000000,16126200000],
[4744,237200,487500000,1000000000,16129600000],
[4745,237250,487500000,1000000000,16133000000],
[4746,237300,487500000,1000000000,16136400000],
[4747,237350,488000000,1000000000,16139800000],
[4748,237400,488000000,1000000000,16143200000],
[4749,237450,488000000,1000000000,16146600000],
[4750,237500,488000000,1000000000,16150000000],
[4751,237550,488500000,1000000000,16153400000],
[4752,237600,488500000,1000000000,16156800000],
[4753,237650,488500000,1000000000,16160200000],
[4754,237700,489000000,1000000000,16163600000],
[4755,237750,489000000,1000000000,16167000000],
[4756,237800,489000000,1000000000,16170400000],
[4757,237850,489500000,1000000000,16173800000],
[4758,237900,489500000,1000000000,16177200000],
[4759,237950,489500000,1000000000,16180600000],
[4760,238000,489500000,1000000000,16184000000],
[4761,238050,490000000,1000000000,16187400000],
[4762,238100,490000000,1000000000,16190800000],
[4763,238150,490000000,1000000000,16194200000],
[4764,238200,490500000,1000000000,16197600000],
[4765,238250,490500000,1000000000,16201000000],
[4766,238300,490500000,1000000000,16204400000],
[4767,238350,491000000,1000000000,16207800000],
[4768,238400,491000000,1000000000,16211200000],
[4769,238450,491000000,1000000000,16214600000],
[4770,238500,491000000,1000000000,16218000000],
[4771,238550,491500000,1000000000,16221400000],
[4772,238600,491500000,1000000000,16224800000],
[4773,238650,491500000,1000000000,16228200000],
[4774,238700,492000000,1000000000,16231600000],
[4775,238750,492000000,1000000000,16235000000],
[4776,238800,492000000,1000000000,16238400000],
[4777,238850,492500000,1000000000,16241800000],
[4778,238900,492500000,1000000000,16245200000],
[4779,238950,492500000,1000000000,16248600000],
[4780,239000,493000000,1000000000,16252000000],
[4781,239050,493000000,1000000000,16255400000],
[4782,239100,493000000,1000000000,16258800000],
[4783,239150,493000000,1000000000,16262200000],
[4784,239200,493500000,1000000000,16265600000],
[4785,239250,493500000,1000000000,16269000000],
[4786,239300,493500000,1000000000,16272400000],
[4787,239350,494000000,1000000000,16275800000],
[4788,239400,494000000,1000000000,16279200000],
[4789,239450,494000000,1000000000,16282600000],
[4790,239500,494500000,1000000000,16286000000],
[4791,239550,494500000,1000000000,16289400000],
[4792,239600,494500000,1000000000,16292800000],
[4793,239650,494500000,1000000000,16296200000],
[4794,239700,495000000,1000000000,16299600000],
[4795,239750,495000000,1000000000,16303000000],
[4796,239800,495000000,1000000000,16306400000],
[4797,239850,495500000,1000000000,16309800000],
[4798,239900,495500000,1000000000,16313200000],
[4799,239950,495500000,1000000000,16316600000],
[4800,240000,496000000,1000000000,16320000000],
[4801,240050,496000000,1000000000,16323400000],
[4802,240100,496000000,1000000000,16326800000],
[4803,240150,496000000,1000000000,16330200000],
[4804,240200,496500000,1000000000,16333600000],
[4805,240250,496500000,1000000000,16337000000],
[4806,240300,496500000,1000000000,16340400000],
[4807,240350,497000000,1000000000,16343800000],
[4808,240400,497000000,1000000000,16347200000],
[4809,240450,497000000,1000000000,16350600000],
[4810,240500,497500000,1000000000,16354000000],
[4811,240550,497500000,1000000000,16357400000],
[4812,240600,497500000,1000000000,16360800000],
[4813,240650,498000000,1000000000,16364200000],
[4814,240700,498000000,1000000000,16367600000],
[4815,240750,498000000,1000000000,16371000000],
[4816,240800,498000000,1000000000,16374400000],
[4817,240850,498500000,1000000000,16377800000],
[4818,240900,498500000,1000000000,16381200000],
[4819,240950,498500000,1000000000,16384600000],
[4820,241000,499000000,1000000000,16388000000],
[4821,241050,499000000,1000000000,16391400000],
[4822,241100,499000000,1000000000,16394800000],
[4823,241150,499500000,1000000000,16398200000],
[4824,241200,499500000,1000000000,16401600000],
[4825,241250,499500000,1000000000,16405000000],
[4826,241300,499500000,1000000000,16408400000],
[4827,241350,500000000,1000000000,16411800000],
[4828,241400,500000000,1000000000,16415200000],
[4829,241450,500000000,1000000000,16418600000],
[4830,241500,500500000,1000000000,16422000000],
[4831,241550,500500000,1000000000,16425400000],
[4832,241600,500500000,1000000000,16428800000],
[4833,241650,501000000,1000000000,16432200000],
[4834,241700,501000000,1000000000,16435600000],
[4835,241750,501000000,1000000000,16439000000],
[4836,241800,501000000,1000000000,16442400000],
[4837,241850,501500000,1000000000,16445800000],
[4838,241900,501500000,1000000000,16449200000],
[4839,241950,501500000,1000000000,16452600000],
[4840,242000,502000000,1000000000,16456000000],
[4841,242050,502000000,1000000000,16459400000],
[4842,242100,502000000,1000000000,16462800000],
[4843,242150,502500000,1000000000,16466200000],
[4844,242200,502500000,1000000000,16469600000],
[4845,242250,502500000,1000000000,16473000000],
[4846,242300,503000000,1000000000,16476400000],
[4847,242350,503000000,1000000000,16479800000],
[4848,242400,503000000,1000000000,16483200000],
[4849,242450,503000000,1000000000,16486600000],
[4850,242500,503500000,1000000000,16490000000],
[4851,242550,503500000,1000000000,16493400000],
[4852,242600,503500000,1000000000,16496800000],
[4853,242650,504000000,1000000000,16500200000],
[4854,242700,504000000,1000000000,16503600000],
[4855,242750,504000000,1000000000,16507000000],
[4856,242800,504500000,1000000000,16510400000],
[4857,242850,504500000,1000000000,16513800000],
[4858,242900,504500000,1000000000,16517200000],
[4859,242950,504500000,1000000000,16520600000],
[4860,243000,505000000,1000000000,16524000000],
[4861,243050,505000000,1000000000,16527400000],
[4862,243100,505000000,1000000000,16530800000],
[4863,243150,505500000,1000000000,16534200000],
[4864,243200,505500000,1000000000,16537600000],
[4865,243250,505500000,1000000000,16541000000],
[4866,243300,506000000,1000000000,16544400000],
[4867,243350,506000000,1000000000,16547800000],
[4868,243400,506000000,1000000000,16551200000],
[4869,243450,506000000,1000000000,16554600000],
[4870,243500,506500000,1000000000,16558000000],
[4871,243550,506500000,1000000000,16561400000],
[4872,243600,506500000,1000000000,16564800000],
[4873,243650,507000000,1000000000,16568200000],
[4874,243700,507000000,1000000000,16571600000],
[4875,243750,507000000,1000000000,16575000000],
[4876,243800,507500000,1000000000,16578400000],
[4877,243850,507500000,1000000000,16581800000],
[4878,243900,507500000,1000000000,16585200000],
[4879,243950,508000000,1000000000,16588600000],
[4880,244000,508000000,1000000000,16592000000],
[4881,244050,508000000,1000000000,16595400000],
[4882,244100,508000000,1000000000,16598800000],
[4883,244150,508500000,1000000000,16602200000],
[4884,244200,508500000,1000000000,16605600000],
[4885,244250,508500000,1000000000,16609000000],
[4886,244300,509000000,1000000000,16612400000],
[4887,244350,509000000,1000000000,16615800000],
[4888,244400,509000000,1000000000,16619200000],
[4889,244450,509500000,1000000000,16622600000],
[4890,244500,509500000,1000000000,16626000000],
[4891,244550,509500000,1000000000,16629400000],
[4892,244600,509500000,1000000000,16632800000],
[4893,244650,510000000,1000000000,16636200000],
[4894,244700,510000000,1000000000,16639600000],
[4895,244750,510000000,1000000000,16643000000],
[4896,244800,510500000,1000000000,16646400000],
[4897,244850,510500000,1000000000,16649800000],
[4898,244900,510500000,1000000000,16653200000],
[4899,244950,511000000,1000000000,16656600000],
[4900,245000,511000000,1000000000,16660000000],
[4901,245050,511000000,1000000000,16663400000],
[4902,245100,511000000,1000000000,16666800000],
[4903,245150,511500000,1000000000,16670200000],
[4904,245200,511500000,1000000000,16673600000],
[4905,245250,511500000,1000000000,16677000000],
[4906,245300,512000000,1000000000,16680400000],
[4907,245350,512000000,1000000000,16683800000],
[4908,245400,512000000,1000000000,16687200000],
[4909,245450,512500000,1000000000,16690600000],
[4910,245500,512500000,1000000000,16694000000],
[4911,245550,512500000,1000000000,16697400000],
[4912,245600,513000000,1000000000,16700800000],
[4913,245650,513000000,1000000000,16704200000],
[4914,245700,513000000,1000000000,16707600000],
[4915,245750,513000000,1000000000,16711000000],
[4916,245800,513500000,1000000000,16714400000],
[4917,245850,513500000,1000000000,16717800000],
[4918,245900,513500000,1000000000,16721200000],
[4919,245950,514000000,1000000000,16724600000],
[4920,246000,514000000,1000000000,16728000000],
[4921,246050,514000000,1000000000,16731400000],
[4922,246100,514500000,1000000000,16734800000],
[4923,246150,514500000,1000000000,16738200000],
[4924,246200,514500000,1000000000,16741600000],
[4925,246250,514500000,1000000000,16745000000],
[4926,246300,515000000,1000000000,16748400000],
[4927,246350,515000000,1000000000,16751800000],
[4928,246400,515000000,1000000000,16755200000],
[4929,246450,515500000,1000000000,16758600000],
[4930,246500,515500000,1000000000,16762000000],
[4931,246550,515500000,1000000000,16765400000],
[4932,246600,516000000,1000000000,16768800000],
[4933,246650,516000000,1000000000,16772200000],
[4934,246700,516000000,1000000000,16775600000],
[4935,246750,516000000,1000000000,16779000000],
[4936,246800,516500000,1000000000,16782400000],
[4937,246850,516500000,1000000000,16785800000],
[4938,246900,516500000,1000000000,16789200000],
[4939,246950,517000000,1000000000,16792600000],
[4940,247000,517000000,1000000000,16796000000],
[4941,247050,517000000,1000000000,16799400000],
[4942,247100,517500000,1000000000,16802800000],
[4943,247150,517500000,1000000000,16806200000],
[4944,247200,517500000,1000000000,16809600000],
[4945,247250,518000000,1000000000,16813000000],
[4946,247300,518000000,1000000000,16816400000],
[4947,247350,518000000,1000000000,16819800000],
[4948,247400,518000000,1000000000,16823200000],
[4949,247450,518500000,1000000000,16826600000],
[4950,247500,518500000,1000000000,16830000000],
[4951,247550,518500000,1000000000,16833400000],
[4952,247600,519000000,1000000000,16836800000],
[4953,247650,519000000,1000000000,16840200000],
[4954,247700,519000000,1000000000,16843600000],
[4955,247750,519500000,1000000000,16847000000],
[4956,247800,519500000,1000000000,16850400000],
[4957,247850,519500000,1000000000,16853800000],
[4958,247900,519500000,1000000000,16857200000],
[4959,247950,520000000,1000000000,16860600000],
[4960,248000,520000000,1000000000,16864000000],
[4961,248050,520000000,1000000000,16867400000],
[4962,248100,520500000,1000000000,16870800000],
[4963,248150,520500000,1000000000,16874200000],
[4964,248200,520500000,1000000000,16877600000],
[4965,248250,521000000,1000000000,16881000000],
[4966,248300,521000000,1000000000,16884400000],
[4967,248350,521000000,1000000000,16887800000],
[4968,248400,521000000,1000000000,16891200000],
[4969,248450,521500000,1000000000,16894600000],
[4970,248500,521500000,1000000000,16898000000],
[4971,248550,521500000,1000000000,16901400000],
[4972,248600,522000000,1000000000,16904800000],
[4973,248650,522000000,1000000000,16908200000],
[4974,248700,522000000,1000000000,16911600000],
[4975,248750,522500000,1000000000,16915000000],
[4976,248800,522500000,1000000000,16918400000],
[4977,248850,522500000,1000000000,16921800000],
[4978,248900,523000000,1000000000,16925200000],
[4979,248950,523000000,1000000000,16928600000],
[4980,249000,523000000,1000000000,16932000000],
[4981,249050,523000000,1000000000,16935400000],
[4982,249100,523500000,1000000000,16938800000],
[4983,249150,523500000,1000000000,16942200000],
[4984,249200,523500000,1000000000,16945600000],
[4985,249250,524000000,1000000000,16949000000],
[4986,249300,524000000,1000000000,16952400000],
[4987,249350,524000000,1000000000,16955800000],
[4988,249400,524500000,1000000000,16959200000],
[4989,249450,524500000,1000000000,16962600000],
[4990,249500,524500000,1000000000,16966000000],
[4991,249550,524500000,1000000000,16969400000],
[4992,249600,525000000,1000000000,16972800000],
[4993,249650,525000000,1000000000,16976200000],
[4994,249700,525000000,1000000000,16979600000],
[4995,249750,525500000,1000000000,16983000000],
[4996,249800,525500000,1000000000,16986400000],
[4997,249850,525500000,1000000000,16989800000],
[4998,249900,526000000,1000000000,16993200000],
[4999,249950,526000000,1000000000,16996600000],
[5000,250000,526000000,1000000000,17000000000],
[5001,250050,526000000,1000000000,17003400000],
[5002,250100,526500000,1000000000,17006800000],
[5003,250150,526500000,1000000000,17010200000],
[5004,250200,526500000,1000000000,17013600000],
[5005,250250,527000000,1000000000,17017000000],
[5006,250300,527000000,1000000000,17020400000],
[5007,250350,527000000,1000000000,17023800000],
[5008,250400,527500000,1000000000,17027200000],
[5009,250450,527500000,1000000000,17030600000],
[5010,250500,527500000,1000000000,17034000000],
[5011,250550,528000000,1000000000,17037400000],
[5012,250600,528000000,1000000000,17040800000],
[5013,250650,528000000,1000000000,17044200000],
[5014,250700,528000000,1000000000,17047600000],
[5015,250750,528500000,1000000000,17051000000],
[5016,250800,528500000,1000000000,17054400000],
[5017,250850,528500000,1000000000,17057800000],
[5018,250900,529000000,1000000000,17061200000],
[5019,250950,529000000,1000000000,17064600000],
[5020,251000,529000000,1000000000,17068000000],
[5021,251050,529500000,1000000000,17071400000],
[5022,251100,529500000,1000000000,17074800000],
[5023,251150,529500000,1000000000,17078200000],
[5024,251200,529500000,1000000000,17081600000],
[5025,251250,530000000,1000000000,17085000000],
[5026,251300,530000000,1000000000,17088400000],
[5027,251350,530000000,1000000000,17091800000],
[5028,251400,530500000,1000000000,17095200000],
[5029,251450,530500000,1000000000,17098600000],
[5030,251500,530500000,1000000000,17102000000],
[5031,251550,531000000,1000000000,17105400000],
[5032,251600,531000000,1000000000,17108800000],
[5033,251650,531000000,1000000000,17112200000],
[5034,251700,531000000,1000000000,17115600000],
[5035,251750,531500000,1000000000,17119000000],
[5036,251800,531500000,1000000000,17122400000],
[5037,251850,531500000,1000000000,17125800000],
[5038,251900,532000000,1000000000,17129200000],
[5039,251950,532000000,1000000000,17132600000],
[5040,252000,532000000,1000000000,17136000000],
[5041,252050,532500000,1000000000,17139400000],
[5042,252100,532500000,1000000000,17142800000],
[5043,252150,532500000,1000000000,17146200000],
[5044,252200,533000000,1000000000,17149600000],
[5045,252250,533000000,1000000000,17153000000],
[5046,252300,533000000,1000000000,17156400000],
[5047,252350,533000000,1000000000,17159800000],
[5048,252400,533500000,1000000000,17163200000],
[5049,252450,533500000,1000000000,17166600000],
[5050,252500,533500000,1000000000,17170000000],
[5051,252550,534000000,1000000000,17173400000],
[5052,252600,534000000,1000000000,17176800000],
[5053,252650,534000000,1000000000,17180200000],
[5054,252700,534500000,1000000000,17183600000],
[5055,252750,534500000,1000000000,17187000000],
[5056,252800,534500000,1000000000,17190400000],
[5057,252850,534500000,1000000000,17193800000],
[5058,252900,535000000,1000000000,17197200000],
[5059,252950,535000000,1000000000,17200600000],
[5060,253000,535000000,1000000000,17204000000],
[5061,253050,535500000,1000000000,17207400000],
[5062,253100,535500000,1000000000,17210800000],
[5063,253150,535500000,1000000000,17214200000],
[5064,253200,536000000,1000000000,17217600000],
[5065,253250,536000000,1000000000,17221000000],
[5066,253300,536000000,1000000000,17224400000],
[5067,253350,536000000,1000000000,17227800000],
[5068,253400,536500000,1000000000,17231200000],
[5069,253450,536500000,1000000000,17234600000],
[5070,253500,536500000,1000000000,17238000000],
[5071,253550,537000000,1000000000,17241400000],
[5072,253600,537000000,1000000000,17244800000],
[5073,253650,537000000,1000000000,17248200000],
[5074,253700,537500000,1000000000,17251600000],
[5075,253750,537500000,1000000000,17255000000],
[5076,253800,537500000,1000000000,17258400000],
[5077,253850,538000000,1000000000,17261800000],
[5078,253900,538000000,1000000000,17265200000],
[5079,253950,538000000,1000000000,17268600000],
[5080,254000,538000000,1000000000,17272000000],
[5081,254050,538500000,1000000000,17275400000],
[5082,254100,538500000,1000000000,17278800000],
[5083,254150,538500000,1000000000,17282200000],
[5084,254200,539000000,1000000000,17285600000],
[5085,254250,539000000,1000000000,17289000000],
[5086,254300,539000000,1000000000,17292400000],
[5087,254350,539500000,1000000000,17295800000],
[5088,254400,539500000,1000000000,17299200000],
[5089,254450,539500000,1000000000,17302600000],
[5090,254500,539500000,1000000000,17306000000],
[5091,254550,540000000,1000000000,17309400000],
[5092,254600,540000000,1000000000,17312800000],
[5093,254650,540000000,1000000000,17316200000],
[5094,254700,540500000,1000000000,17319600000],
[5095,254750,540500000,1000000000,17323000000],
[5096,254800,540500000,1000000000,17326400000],
[5097,254850,541000000,1000000000,17329800000],
[5098,254900,541000000,1000000000,17333200000],
[5099,254950,541000000,1000000000,17336600000],
[5100,255000,541000000,1000000000,17340000000],
[5101,255050,541500000,1000000000,17343400000],
[5102,255100,541500000,1000000000,17346800000],
[5103,255150,541500000,1000000000,17350200000],
[5104,255200,542000000,1000000000,17353600000],
[5105,255250,542000000,1000000000,17357000000],
[5106,255300,542000000,1000000000,17360400000],
[5107,255350,542500000,1000000000,17363800000],
[5108,255400,542500000,1000000000,17367200000],
[5109,255450,542500000,1000000000,17370600000],
[5110,255500,543000000,1000000000,17374000000],
[5111,255550,543000000,1000000000,17377400000],
[5112,255600,543000000,1000000000,17380800000],
[5113,255650,543000000,1000000000,17384200000],
[5114,255700,543500000,1000000000,17387600000],
[5115,255750,543500000,1000000000,17391000000],
[5116,255800,543500000,1000000000,17394400000],
[5117,255850,544000000,1000000000,17397800000],
[5118,255900,544000000,1000000000,17401200000],
[5119,255950,544000000,1000000000,17404600000],
[5120,256000,544500000,1000000000,17408000000],
[5121,256050,544500000,1000000000,17411400000],
[5122,256100,544500000,1000000000,17414800000],
[5123,256150,544500000,1000000000,17418200000],
[5124,256200,545000000,1000000000,17421600000],
[5125,256250,545000000,1000000000,17425000000],
[5126,256300,545000000,1000000000,17428400000],
[5127,256350,545500000,1000000000,17431800000],
[5128,256400,545500000,1000000000,17435200000],
[5129,256450,545500000,1000000000,17438600000],
[5130,256500,546000000,1000000000,17442000000],
[5131,256550,546000000,1000000000,17445400000],
[5132,256600,546000000,1000000000,17448800000],
[5133,256650,546000000,1000000000,17452200000],
[5134,256700,546500000,1000000000,17455600000],
[5135,256750,546500000,1000000000,17459000000],
[5136,256800,546500000,1000000000,17462400000],
[5137,256850,547000000,1000000000,17465800000],
[5138,256900,547000000,1000000000,17469200000],
[5139,256950,547000000,1000000000,17472600000],
[5140,257000,547500000,1000000000,17476000000],
[5141,257050,547500000,1000000000,17479400000],
[5142,257100,547500000,1000000000,17482800000],
[5143,257150,548000000,1000000000,17486200000],
[5144,257200,548000000,1000000000,17489600000],
[5145,257250,548000000,1000000000,17493000000],
[5146,257300,548000000,1000000000,17496400000],
[5147,257350,548500000,1000000000,17499800000],
[5148,257400,548500000,1000000000,17503200000],
[5149,257450,548500000,1000000000,17506600000],
[5150,257500,549000000,1000000000,17510000000],
[5151,257550,549000000,1000000000,17513400000],
[5152,257600,549000000,1000000000,17516800000],
[5153,257650,549500000,1000000000,17520200000],
[5154,257700,549500000,1000000000,17523600000],
[5155,257750,549500000,1000000000,17527000000],
[5156,257800,549500000,1000000000,17530400000],
[5157,257850,550000000,1000000000,17533800000],
[5158,257900,550000000,1000000000,17537200000],
[5159,257950,550000000,1000000000,17540600000],
[5160,258000,550500000,1000000000,17544000000],
[5161,258050,550500000,1000000000,17547400000],
[5162,258100,550500000,1000000000,17550800000],
[5163,258150,551000000,1000000000,17554200000],
[5164,258200,551000000,1000000000,17557600000],
[5165,258250,551000000,1000000000,17561000000],
[5166,258300,551000000,1000000000,17564400000],
[5167,258350,551500000,1000000000,17567800000],
[5168,258400,551500000,1000000000,17571200000],
[5169,258450,551500000,1000000000,17574600000],
[5170,258500,552000000,1000000000,17578000000],
[5171,258550,552000000,1000000000,17581400000],
[5172,258600,552000000,1000000000,17584800000],
[5173,258650,552500000,1000000000,17588200000],
[5174,258700,552500000,1000000000,17591600000],
[5175,258750,552500000,1000000000,17595000000],
[5176,258800,553000000,1000000000,17598400000],
[5177,258850,553000000,1000000000,17601800000],
[5178,258900,553000000,1000000000,17605200000],
[5179,258950,553000000,1000000000,17608600000],
[5180,259000,553500000,1000000000,17612000000],
[5181,259050,553500000,1000000000,17615400000],
[5182,259100,553500000,1000000000,17618800000],
[5183,259150,554000000,1000000000,17622200000],
[5184,259200,554000000,1000000000,17625600000],
[5185,259250,554000000,1000000000,17629000000],
[5186,259300,554500000,1000000000,17632400000],
[5187,259350,554500000,1000000000,17635800000],
[5188,259400,554500000,1000000000,17639200000],
[5189,259450,554500000,1000000000,17642600000],
[5190,259500,555000000,1000000000,17646000000],
[5191,259550,555000000,1000000000,17649400000],
[5192,259600,555000000,1000000000,17652800000],
[5193,259650,555500000,1000000000,17656200000],
[5194,259700,555500000,1000000000,17659600000],
[5195,259750,555500000,1000000000,17663000000],
[5196,259800,556000000,1000000000,17666400000],
[5197,259850,556000000,1000000000,17669800000],
[5198,259900,556000000,1000000000,17673200000],
[5199,259950,556000000,1000000000,17676600000],
[5200,260000,556500000,1000000000,17680000000],
[5201,260050,556500000,1000000000,17683400000],
[5202,260100,556500000,1000000000,17686800000],
[5203,260150,557000000,1000000000,17690200000],
[5204,260200,557000000,1000000000,17693600000],
[5205,260250,557000000,1000000000,17697000000],
[5206,260300,557500000,1000000000,17700400000],
[5207,260350,557500000,1000000000,17703800000],
[5208,260400,557500000,1000000000,17707200000],
[5209,260450,558000000,1000000000,17710600000],
[5210,260500,558000000,1000000000,17714000000],
[5211,260550,558000000,1000000000,17717400000],
[5212,260600,558000000,1000000000,17720800000],
[5213,260650,558500000,1000000000,17724200000],
[5214,260700,558500000,1000000000,17727600000],
[5215,260750,558500000,1000000000,17731000000],
[5216,260800,559000000,1000000000,17734400000],
[5217,260850,559000000,1000000000,17737800000],
[5218,260900,559000000,1000000000,17741200000],
[5219,260950,559500000,1000000000,17744600000],
[5220,261000,559500000,1000000000,17748000000],
[5221,261050,559500000,1000000000,17751400000],
[5222,261100,559500000,1000000000,17754800000],
[5223,261150,560000000,1000000000,17758200000],
[5224,261200,560000000,1000000000,17761600000],
[5225,261250,560000000,1000000000,17765000000],
[5226,261300,560500000,1000000000,17768400000],
[5227,261350,560500000,1000000000,17771800000],
[5228,261400,560500000,1000000000,17775200000],
[5229,261450,561000000,1000000000,17778600000],
[5230,261500,561000000,1000000000,17782000000],
[5231,261550,561000000,1000000000,17785400000],
[5232,261600,561000000,1000000000,17788800000],
[5233,261650,561500000,1000000000,17792200000],
[5234,261700,561500000,1000000000,17795600000],
[5235,261750,561500000,1000000000,17799000000],
[5236,261800,562000000,1000000000,17802400000],
[5237,261850,562000000,1000000000,17805800000],
[5238,261900,562000000,1000000000,17809200000],
[5239,261950,562500000,1000000000,17812600000],
[5240,262000,562500000,1000000000,17816000000],
[5241,262050,562500000,1000000000,17819400000],
[5242,262100,563000000,1000000000,17822800000],
[5243,262150,563000000,1000000000,17826200000],
[5244,262200,563000000,1000000000,17829600000],
[5245,262250,563000000,1000000000,17833000000],
[5246,262300,563500000,1000000000,17836400000],
[5247,262350,563500000,1000000000,17839800000],
[5248,262400,563500000,1000000000,17843200000],
[5249,262450,564000000,1000000000,17846600000],
[5250,262500,564000000,1000000000,17850000000],
[5251,262550,564000000,1000000000,17853400000],
[5252,262600,564500000,1000000000,17856800000],
[5253,262650,564500000,1000000000,17860200000],
[5254,262700,564500000,1000000000,17863600000],
[5255,262750,564500000,1000000000,17867000000],
[5256,262800,565000000,1000000000,17870400000],
[5257,262850,565000000,1000000000,17873800000],
[5258,262900,565000000,1000000000,17877200000],
[5259,262950,565500000,1000000000,17880600000],
[5260,263000,565500000,1000000000,17884000000],
[5261,263050,565500000,1000000000,17887400000],
[5262,263100,566000000,1000000000,17890800000],
[5263,263150,566000000,1000000000,17894200000],
[5264,263200,566000000,1000000000,17897600000],
[5265,263250,566000000,1000000000,17901000000],
[5266,263300,566500000,1000000000,17904400000],
[5267,263350,566500000,1000000000,17907800000],
[5268,263400,566500000,1000000000,17911200000],
[5269,263450,567000000,1000000000,17914600000],
[5270,263500,567000000,1000000000,17918000000],
[5271,263550,567000000,1000000000,17921400000],
[5272,263600,567500000,1000000000,17924800000],
[5273,263650,567500000,1000000000,17928200000],
[5274,263700,567500000,1000000000,17931600000],
[5275,263750,568000000,1000000000,17935000000],
[5276,263800,568000000,1000000000,17938400000],
[5277,263850,568000000,1000000000,17941800000],
[5278,263900,568000000,1000000000,17945200000],
[5279,263950,568500000,1000000000,17948600000],
[5280,264000,568500000,1000000000,17952000000],
[5281,264050,568500000,1000000000,17955400000],
[5282,264100,569000000,1000000000,17958800000],
[5283,264150,569000000,1000000000,17962200000],
[5284,264200,569000000,1000000000,17965600000],
[5285,264250,569500000,1000000000,17969000000],
[5286,264300,569500000,1000000000,17972400000],
[5287,264350,569500000,1000000000,17975800000],
[5288,264400,569500000,1000000000,17979200000],
[5289,264450,570000000,1000000000,17982600000],
[5290,264500,570000000,1000000000,17986000000],
[5291,264550,570000000,1000000000,17989400000],
[5292,264600,570500000,1000000000,17992800000],
[5293,264650,570500000,1000000000,17996200000],
[5294,264700,570500000,1000000000,17999600000],
[5295,264750,571000000,1000000000,18003000000],
[5296,264800,571000000,1000000000,18006400000],
[5297,264850,571000000,1000000000,18009800000],
[5298,264900,571000000,1000000000,18013200000],
[5299,264950,571500000,1000000000,18016600000],
[5300,265000,571500000,1000000000,18020000000],
[5301,265050,571500000,1000000000,18023400000],
[5302,265100,572000000,1000000000,18026800000],
[5303,265150,572000000,1000000000,18030200000],
[5304,265200,572000000,1000000000,18033600000],
[5305,265250,572500000,1000000000,18037000000],
[5306,265300,572500000,1000000000,18040400000],
[5307,265350,572500000,1000000000,18043800000],
[5308,265400,573000000,1000000000,18047200000],
[5309,265450,573000000,1000000000,18050600000],
[5310,265500,573000000,1000000000,18054000000],
[5311,265550,573000000,1000000000,18057400000],
[5312,265600,573500000,1000000000,18060800000],
[5313,265650,573500000,1000000000,18064200000],
[5314,265700,573500000,1000000000,18067600000],
[5315,265750,574000000,1000000000,18071000000],
[5316,265800,574000000,1000000000,18074400000],
[5317,265850,574000000,1000000000,18077800000],
[5318,265900,574500000,1000000000,18081200000],
[5319,265950,574500000,1000000000,18084600000],
[5320,266000,574500000,1000000000,18088000000],
[5321,266050,574500000,1000000000,18091400000],
[5322,266100,575000000,1000000000,18094800000],
[5323,266150,575000000,1000000000,18098200000],
[5324,266200,575000000,1000000000,18101600000],
[5325,266250,575500000,1000000000,18105000000],
[5326,266300,575500000,1000000000,18108400000],
[5327,266350,575500000,1000000000,18111800000],
[5328,266400,576000000,1000000000,18115200000],
[5329,266450,576000000,1000000000,18118600000],
[5330,266500,576000000,1000000000,18122000000],
[5331,266550,576000000,1000000000,18125400000],
[5332,266600,576500000,1000000000,18128800000],
[5333,266650,576500000,1000000000,18132200000],
[5334,266700,576500000,1000000000,18135600000],
[5335,266750,577000000,1000000000,18139000000],
[5336,266800,577000000,1000000000,18142400000],
[5337,266850,577000000,1000000000,18145800000],
[5338,266900,577500000,1000000000,18149200000],
[5339,266950,577500000,1000000000,18152600000],
[5340,267000,577500000,1000000000,18156000000],
[5341,267050,578000000,1000000000,18159400000],
[5342,267100,578000000,1000000000,18162800000],
[5343,267150,578000000,1000000000,18166200000],
[5344,267200,578000000,1000000000,18169600000],
[5345,267250,578500000,1000000000,18173000000],
[5346,267300,578500000,1000000000,18176400000],
[5347,267350,578500000,1000000000,18179800000],
[5348,267400,579000000,1000000000,18183200000],
[5349,267450,579000000,1000000000,18186600000],
[5350,267500,579000000,1000000000,18190000000],
[5351,267550,579500000,1000000000,18193400000],
[5352,267600,579500000,1000000000,18196800000],
[5353,267650,579500000,1000000000,18200200000],
[5354,267700,579500000,1000000000,18203600000],
[5355,267750,580000000,1000000000,18207000000],
[5356,267800,580000000,1000000000,18210400000],
[5357,267850,580000000,1000000000,18213800000],
[5358,267900,580500000,1000000000,18217200000],
[5359,267950,580500000,1000000000,18220600000],
[5360,268000,580500000,1000000000,18224000000],
[5361,268050,581000000,1000000000,18227400000],
[5362,268100,581000000,1000000000,18230800000],
[5363,268150,581000000,1000000000,18234200000],
[5364,268200,581000000,1000000000,18237600000],
[5365,268250,581500000,1000000000,18241000000],
[5366,268300,581500000,1000000000,18244400000],
[5367,268350,581500000,1000000000,18247800000],
[5368,268400,582000000,1000000000,18251200000],
[5369,268450,582000000,1000000000,18254600000],
[5370,268500,582000000,1000000000,18258000000],
[5371,268550,582500000,1000000000,18261400000],
[5372,268600,582500000,1000000000,18264800000],
[5373,268650,582500000,1000000000,18268200000],
[5374,268700,583000000,1000000000,18271600000],
[5375,268750,583000000,1000000000,18275000000],
[5376,268800,583000000,1000000000,18278400000],
[5377,268850,583000000,1000000000,18281800000],
[5378,268900,583500000,1000000000,18285200000],
[5379,268950,583500000,1000000000,18288600000],
[5380,269000,583500000,1000000000,18292000000],
[5381,269050,584000000,1000000000,18295400000],
[5382,269100,584000000,1000000000,18298800000],
[5383,269150,584000000,1000000000,18302200000],
[5384,269200,584500000,1000000000,18305600000],
[5385,269250,584500000,1000000000,18309000000],
[5386,269300,584500000,1000000000,18312400000],
[5387,269350,584500000,1000000000,18315800000],
[5388,269400,585000000,1000000000,18319200000],
[5389,269450,585000000,1000000000,18322600000],
[5390,269500,585000000,1000000000,18326000000],
[5391,269550,585500000,1000000000,18329400000],
[5392,269600,585500000,1000000000,18332800000],
[5393,269650,585500000,1000000000,18336200000],
[5394,269700,586000000,1000000000,18339600000],
[5395,269750,586000000,1000000000,18343000000],
[5396,269800,586000000,1000000000,18346400000],
[5397,269850,586000000,1000000000,18349800000],
[5398,269900,586500000,1000000000,18353200000],
[5399,269950,586500000,1000000000,18356600000],
[5400,270000,586500000,1000000000,18360000000],
[5401,270050,587000000,1000000000,18363400000],
[5402,270100,587000000,1000000000,18366800000],
[5403,270150,587000000,1000000000,18370200000],
[5404,270200,587500000,1000000000,18373600000],
[5405,270250,587500000,1000000000,18377000000],
[5406,270300,587500000,1000000000,18380400000],
[5407,270350,588000000,1000000000,18383800000],
[5408,270400,588000000,1000000000,18387200000],
[5409,270450,588000000,1000000000,18390600000],
[5410,270500,588000000,1000000000,18394000000],
[5411,270550,588500000,1000000000,18397400000],
[5412,270600,588500000,1000000000,18400800000],
[5413,270650,588500000,1000000000,18404200000],
[5414,270700,589000000,1000000000,18407600000],
[5415,270750,589000000,1000000000,18411000000],
[5416,270800,589000000,1000000000,18414400000],
[5417,270850,589500000,1000000000,18417800000],
[5418,270900,589500000,1000000000,18421200000],
[5419,270950,589500000,1000000000,18424600000],
[5420,271000,589500000,1000000000,18428000000],
[5421,271050,590000000,1000000000,18431400000],
[5422,271100,590000000,1000000000,18434800000],
[5423,271150,590000000,1000000000,18438200000],
[5424,271200,590500000,1000000000,18441600000],
[5425,271250,590500000,1000000000,18445000000],
[5426,271300,590500000,1000000000,18448400000],
[5427,271350,591000000,1000000000,18451800000],
[5428,271400,591000000,1000000000,18455200000],
[5429,271450,591000000,1000000000,18458600000],
[5430,271500,591000000,1000000000,18462000000],
[5431,271550,591500000,1000000000,18465400000],
[5432,271600,591500000,1000000000,18468800000],
[5433,271650,591500000,1000000000,18472200000],
[5434,271700,592000000,1000000000,18475600000],
[5435,271750,592000000,1000000000,18479000000],
[5436,271800,592000000,1000000000,18482400000],
[5437,271850,592500000,1000000000,18485800000],
[5438,271900,592500000,1000000000,18489200000],
[5439,271950,592500000,1000000000,18492600000],
[5440,272000,593000000,1000000000,18496000000],
[5441,272050,593000000,1000000000,18499400000],
[5442,272100,593000000,1000000000,18502800000],
[5443,272150,593000000,1000000000,18506200000],
[5444,272200,593500000,1000000000,18509600000],
[5445,272250,593500000,1000000000,18513000000],
[5446,272300,593500000,1000000000,18516400000],
[5447,272350,594000000,1000000000,18519800000],
[5448,272400,594000000,1000000000,18523200000],
[5449,272450,594000000,1000000000,18526600000],
[5450,272500,594500000,1000000000,18530000000],
[5451,272550,594500000,1000000000,18533400000],
[5452,272600,594500000,1000000000,18536800000],
[5453,272650,594500000,1000000000,18540200000],
[5454,272700,595000000,1000000000,18543600000],
[5455,272750,595000000,1000000000,18547000000],
[5456,272800,595000000,1000000000,18550400000],
[5457,272850,595500000,1000000000,18553800000],
[5458,272900,595500000,1000000000,18557200000],
[5459,272950,595500000,1000000000,18560600000],
[5460,273000,596000000,1000000000,18564000000],
[5461,273050,596000000,1000000000,18567400000],
[5462,273100,596000000,1000000000,18570800000],
[5463,273150,596000000,1000000000,18574200000],
[5464,273200,596500000,1000000000,18577600000],
[5465,273250,596500000,1000000000,18581000000],
[5466,273300,596500000,1000000000,18584400000],
[5467,273350,597000000,1000000000,18587800000],
[5468,273400,597000000,1000000000,18591200000],
[5469,273450,597000000,1000000000,18594600000],
[5470,273500,597500000,1000000000,18598000000],
[5471,273550,597500000,1000000000,18601400000],
[5472,273600,597500000,1000000000,18604800000],
[5473,273650,598000000,1000000000,18608200000],
[5474,273700,598000000,1000000000,18611600000],
[5475,273750,598000000,1000000000,18615000000],
[5476,273800,598000000,1000000000,18618400000],
[5477,273850,598500000,1000000000,18621800000],
[5478,273900,598500000,1000000000,18625200000],
[5479,273950,598500000,1000000000,18628600000],
[5480,274000,599000000,1000000000,18632000000],
[5481,274050,599000000,1000000000,18635400000],
[5482,274100,599000000,1000000000,18638800000],
[5483,274150,599500000,1000000000,18642200000],
[5484,274200,599500000,1000000000,18645600000],
[5485,274250,599500000,1000000000,18649000000],
[5486,274300,599500000,1000000000,18652400000],
[5487,274350,600000000,1000000000,18655800000],
[5488,274400,600000000,1000000000,18659200000],
[5489,274450,600000000,1000000000,18662600000],
[5490,274500,600500000,1000000000,18666000000],
[5491,274550,600500000,1000000000,18669400000],
[5492,274600,600500000,1000000000,18672800000],
[5493,274650,601000000,1000000000,18676200000],
[5494,274700,601000000,1000000000,18679600000],
[5495,274750,601000000,1000000000,18683000000],
[5496,274800,601000000,1000000000,18686400000],
[5497,274850,601500000,1000000000,18689800000],
[5498,274900,601500000,1000000000,18693200000],
[5499,274950,601500000,1000000000,18696600000],
[5500,275000,602000000,1000000000,18700000000],
[5501,275050,602000000,1000000000,18703400000],
[5502,275100,602000000,1000000000,18706800000],
[5503,275150,602500000,1000000000,18710200000],
[5504,275200,602500000,1000000000,18713600000],
[5505,275250,602500000,1000000000,18717000000],
[5506,275300,603000000,1000000000,18720400000],
[5507,275350,603000000,1000000000,18723800000],
[5508,275400,603000000,1000000000,18727200000],
[5509,275450,603000000,1000000000,18730600000],
[5510,275500,603500000,1000000000,18734000000],
[5511,275550,603500000,1000000000,18737400000],
[5512,275600,603500000,1000000000,18740800000],
[5513,275650,604000000,1000000000,18744200000],
[5514,275700,604000000,1000000000,18747600000],
[5515,275750,604000000,1000000000,18751000000],
[5516,275800,604500000,1000000000,18754400000],
[5517,275850,604500000,1000000000,18757800000],
[5518,275900,604500000,1000000000,18761200000],
[5519,275950,604500000,1000000000,18764600000],
[5520,276000,605000000,1000000000,18768000000],
[5521,276050,605000000,1000000000,18771400000],
[5522,276100,605000000,1000000000,18774800000],
[5523,276150,605500000,1000000000,18778200000],
[5524,276200,605500000,1000000000,18781600000],
[5525,276250,605500000,1000000000,18785000000],
[5526,276300,606000000,1000000000,18788400000],
[5527,276350,606000000,1000000000,18791800000],
[5528,276400,606000000,1000000000,18795200000],
[5529,276450,606000000,1000000000,18798600000],
[5530,276500,606500000,1000000000,18802000000],
[5531,276550,606500000,1000000000,18805400000],
[5532,276600,606500000,1000000000,18808800000],
[5533,276650,607000000,1000000000,18812200000],
[5534,276700,607000000,1000000000,18815600000],
[5535,276750,607000000,1000000000,18819000000],
[5536,276800,607500000,1000000000,18822400000],
[5537,276850,607500000,1000000000,18825800000],
[5538,276900,607500000,1000000000,18829200000],
[5539,276950,608000000,1000000000,18832600000],
[5540,277000,608000000,1000000000,18836000000],
[5541,277050,608000000,1000000000,18839400000],
[5542,277100,608000000,1000000000,18842800000],
[5543,277150,608500000,1000000000,18846200000],
[5544,277200,608500000,1000000000,18849600000],
[5545,277250,608500000,1000000000,18853000000],
[5546,277300,609000000,1000000000,18856400000],
[5547,277350,609000000,1000000000,18859800000],
[5548,277400,609000000,1000000000,18863200000],
[5549,277450,609500000,1000000000,18866600000],
[5550,277500,609500000,1000000000,18870000000],
[5551,277550,609500000,1000000000,18873400000],
[5552,277600,609500000,1000000000,18876800000],
[5553,277650,610000000,1000000000,18880200000],
[5554,277700,610000000,1000000000,18883600000],
[5555,277750,610000000,1000000000,18887000000],
[5556,277800,610500000,1000000000,18890400000],
[5557,277850,610500000,1000000000,18893800000],
[5558,277900,610500000,1000000000,18897200000],
[5559,277950,611000000,1000000000,18900600000],
[5560,278000,611000000,1000000000,18904000000],
[5561,278050,611000000,1000000000,18907400000],
[5562,278100,611000000,1000000000,18910800000],
[5563,278150,611500000,1000000000,18914200000],
[5564,278200,611500000,1000000000,18917600000],
[5565,278250,611500000,1000000000,18921000000],
[5566,278300,612000000,1000000000,18924400000],
[5567,278350,612000000,1000000000,18927800000],
[5568,278400,612000000,1000000000,18931200000],
[5569,278450,612500000,1000000000,18934600000],
[5570,278500,612500000,1000000000,18938000000],
[5571,278550,612500000,1000000000,18941400000],
[5572,278600,613000000,1000000000,18944800000],
[5573,278650,613000000,1000000000,18948200000],
[5574,278700,613000000,1000000000,18951600000],
[5575,278750,613000000,1000000000,18955000000],
[5576,278800,613500000,1000000000,18958400000],
[5577,278850,613500000,1000000000,18961800000],
[5578,278900,613500000,1000000000,18965200000],
[5579,278950,614000000,1000000000,18968600000],
[5580,279000,614000000,1000000000,18972000000],
[5581,279050,614000000,1000000000,18975400000],
[5582,279100,614500000,1000000000,18978800000],
[5583,279150,614500000,1000000000,18982200000],
[5584,279200,614500000,1000000000,18985600000],
[5585,279250,614500000,1000000000,18989000000],
[5586,279300,615000000,1000000000,18992400000],
[5587,279350,615000000,1000000000,18995800000],
[5588,279400,615000000,1000000000,18999200000],
[5589,279450,615500000,1000000000,19002600000],
[5590,279500,615500000,1000000000,19006000000],
[5591,279550,615500000,1000000000,19009400000],
[5592,279600,616000000,1000000000,19012800000],
[5593,279650,616000000,1000000000,19016200000],
[5594,279700,616000000,1000000000,19019600000],
[5595,279750,616000000,1000000000,19023000000],
[5596,279800,616500000,1000000000,19026400000],
[5597,279850,616500000,1000000000,19029800000],
[5598,279900,616500000,1000000000,19033200000],
[5599,279950,617000000,1000000000,19036600000],
[5600,280000,617000000,1000000000,19040000000],
[5601,280050,617000000,1000000000,19043400000],
[5602,280100,617500000,1000000000,19046800000],
[5603,280150,617500000,1000000000,19050200000],
[5604,280200,617500000,1000000000,19053600000],
[5605,280250,618000000,1000000000,19057000000],
[5606,280300,618000000,1000000000,19060400000],
[5607,280350,618000000,1000000000,19063800000],
[5608,280400,618000000,1000000000,19067200000],
[5609,280450,618500000,1000000000,19070600000],
[5610,280500,618500000,1000000000,19074000000],
[5611,280550,618500000,1000000000,19077400000],
[5612,280600,619000000,1000000000,19080800000],
[5613,280650,619000000,1000000000,19084200000],
[5614,280700,619000000,1000000000,19087600000],
[5615,280750,619500000,1000000000,19091000000],
[5616,280800,619500000,1000000000,19094400000],
[5617,280850,619500000,1000000000,19097800000],
[5618,280900,619500000,1000000000,19101200000],
[5619,280950,620000000,1000000000,19104600000],
[5620,281000,620000000,1000000000,19108000000],
[5621,281050,620000000,1000000000,19111400000],
[5622,281100,620500000,1000000000,19114800000],
[5623,281150,620500000,1000000000,19118200000],
[5624,281200,620500000,1000000000,19121600000],
[5625,281250,621000000,1000000000,19125000000],
[5626,281300,621000000,1000000000,19128400000],
[5627,281350,621000000,1000000000,19131800000],
[5628,281400,621000000,1000000000,19135200000],
[5629,281450,621500000,1000000000,19138600000],
[5630,281500,621500000,1000000000,19142000000],
[5631,281550,621500000,1000000000,19145400000],
[5632,281600,622000000,1000000000,19148800000],
[5633,281650,622000000,1000000000,19152200000],
[5634,281700,622000000,1000000000,19155600000],
[5635,281750,622500000,1000000000,19159000000],
[5636,281800,622500000,1000000000,19162400000],
[5637,281850,622500000,1000000000,19165800000],
[5638,281900,623000000,1000000000,19169200000],
[5639,281950,623000000,1000000000,19172600000],
[5640,282000,623000000,1000000000,19176000000],
[5641,282050,623000000,1000000000,19179400000],
[5642,282100,623500000,1000000000,19182800000],
[5643,282150,623500000,1000000000,19186200000],
[5644,282200,623500000,1000000000,19189600000],
[5645,282250,624000000,1000000000,19193000000],
[5646,282300,624000000,1000000000,19196400000],
[5647,282350,624000000,1000000000,19199800000],
[5648,282400,624500000,1000000000,19203200000],
[5649,282450,624500000,1000000000,19206600000],
[5650,282500,624500000,1000000000,19210000000],
[5651,282550,624500000,1000000000,19213400000],
[5652,282600,625000000,1000000000,19216800000],
[5653,282650,625000000,1000000000,19220200000],
[5654,282700,625000000,1000000000,19223600000],
[5655,282750,625500000,1000000000,19227000000],
[5656,282800,625500000,1000000000,19230400000],
[5657,282850,625500000,1000000000,19233800000],
[5658,282900,626000000,1000000000,19237200000],
[5659,282950,626000000,1000000000,19240600000],
[5660,283000,626000000,1000000000,19244000000],
[5661,283050,626000000,1000000000,19247400000],
[5662,283100,626500000,1000000000,19250800000],
[5663,283150,626500000,1000000000,19254200000],
[5664,283200,626500000,1000000000,19257600000],
[5665,283250,627000000,1000000000,19261000000],
[5666,283300,627000000,1000000000,19264400000],
[5667,283350,627000000,1000000000,19267800000],
[5668,283400,627500000,1000000000,19271200000],
[5669,283450,627500000,1000000000,19274600000],
[5670,283500,627500000,1000000000,19278000000],
[5671,283550,628000000,1000000000,19281400000],
[5672,283600,628000000,1000000000,19284800000],
[5673,283650,628000000,1000000000,19288200000],
[5674,283700,628000000,1000000000,19291600000],
[5675,283750,628500000,1000000000,19295000000],
[5676,283800,628500000,1000000000,19298400000],
[5677,283850,628500000,1000000000,19301800000],
[5678,283900,629000000,1000000000,19305200000],
[5679,283950,629000000,1000000000,19308600000],
[5680,284000,629000000,1000000000,19312000000],
[5681,284050,629500000,1000000000,19315400000],
[5682,284100,629500000,1000000000,19318800000],
[5683,284150,629500000,1000000000,19322200000],
[5684,284200,629500000,1000000000,19325600000],
[5685,284250,630000000,1000000000,19329000000],
[5686,284300,630000000,1000000000,19332400000],
[5687,284350,630000000,1000000000,19335800000],
[5688,284400,630500000,1000000000,19339200000],
[5689,284450,630500000,1000000000,19342600000],
[5690,284500,630500000,1000000000,19346000000],
[5691,284550,631000000,1000000000,19349400000],
[5692,284600,631000000,1000000000,19352800000],
[5693,284650,631000000,1000000000,19356200000],
[5694,284700,631000000,1000000000,19359600000],
[5695,284750,631500000,1000000000,19363000000],
[5696,284800,631500000,1000000000,19366400000],
[5697,284850,631500000,1000000000,19369800000],
[5698,284900,632000000,1000000000,19373200000],
[5699,284950,632000000,1000000000,19376600000],
[5700,285000,632000000,1000000000,19380000000],
[5701,285050,632500000,1000000000,19383400000],
[5702,285100,632500000,1000000000,19386800000],
[5703,285150,632500000,1000000000,19390200000],
[5704,285200,633000000,1000000000,19393600000],
[5705,285250,633000000,1000000000,19397000000],
[5706,285300,633000000,1000000000,19400400000],
[5707,285350,633000000,1000000000,19403800000],
[5708,285400,633500000,1000000000,19407200000],
[5709,285450,633500000,1000000000,19410600000],
[5710,285500,633500000,1000000000,19414000000],
[5711,285550,634000000,1000000000,19417400000],
[5712,285600,634000000,1000000000,19420800000],
[5713,285650,634000000,1000000000,19424200000],
[5714,285700,634500000,1000000000,19427600000],
[5715,285750,634500000,1000000000,19431000000],
[5716,285800,634500000,1000000000,19434400000],
[5717,285850,634500000,1000000000,19437800000],
[5718,285900,635000000,1000000000,19441200000],
[5719,285950,635000000,1000000000,19444600000],
[5720,286000,635000000,1000000000,19448000000],
[5721,286050,635500000,1000000000,19451400000],
[5722,286100,635500000,1000000000,19454800000],
[5723,286150,635500000,1000000000,19458200000],
[5724,286200,636000000,1000000000,19461600000],
[5725,286250,636000000,1000000000,19465000000],
[5726,286300,636000000,1000000000,19468400000],
[5727,286350,636000000,1000000000,19471800000],
[5728,286400,636500000,1000000000,19475200000],
[5729,286450,636500000,1000000000,19478600000],
[5730,286500,636500000,1000000000,19482000000],
[5731,286550,637000000,1000000000,19485400000],
[5732,286600,637000000,1000000000,19488800000],
[5733,286650,637000000,1000000000,19492200000],
[5734,286700,637500000,1000000000,19495600000],
[5735,286750,637500000,1000000000,19499000000],
[5736,286800,637500000,1000000000,19502400000],
[5737,286850,638000000,1000000000,19505800000],
[5738,286900,638000000,1000000000,19509200000],
[5739,286950,638000000,1000000000,19512600000],
[5740,287000,638000000,1000000000,19516000000],
[5741,287050,638500000,1000000000,19519400000],
[5742,287100,638500000,1000000000,19522800000],
[5743,287150,638500000,1000000000,19526200000],
[5744,287200,639000000,1000000000,19529600000],
[5745,287250,639000000,1000000000,19533000000],
[5746,287300,639000000,1000000000,19536400000],
[5747,287350,639500000,1000000000,19539800000],
[5748,287400,639500000,1000000000,19543200000],
[5749,287450,639500000,1000000000,19546600000],
[5750,287500,639500000,1000000000,19550000000],
[5751,287550,640000000,1000000000,19553400000],
[5752,287600,640000000,1000000000,19556800000],
[5753,287650,640000000,1000000000,19560200000],
[5754,287700,640500000,1000000000,19563600000],
[5755,287750,640500000,1000000000,19567000000],
[5756,287800,640500000,1000000000,19570400000],
[5757,287850,641000000,1000000000,19573800000],
[5758,287900,641000000,1000000000,19577200000],
[5759,287950,641000000,1000000000,19580600000],
[5760,288000,641000000,1000000000,19584000000],
[5761,288050,641500000,1000000000,19587400000],
[5762,288100,641500000,1000000000,19590800000],
[5763,288150,641500000,1000000000,19594200000],
[5764,288200,642000000,1000000000,19597600000],
[5765,288250,642000000,1000000000,19601000000],
[5766,288300,642000000,1000000000,19604400000],
[5767,288350,642500000,1000000000,19607800000],
[5768,288400,642500000,1000000000,19611200000],
[5769,288450,642500000,1000000000,19614600000],
[5770,288500,643000000,1000000000,19618000000],
[5771,288550,643000000,1000000000,19621400000],
[5772,288600,643000000,1000000000,19624800000],
[5773,288650,643000000,1000000000,19628200000],
[5774,288700,643500000,1000000000,19631600000],
[5775,288750,643500000,1000000000,19635000000],
[5776,288800,643500000,1000000000,19638400000],
[5777,288850,644000000,1000000000,19641800000],
[5778,288900,644000000,1000000000,19645200000],
[5779,288950,644000000,1000000000,19648600000],
[5780,289000,644500000,1000000000,19652000000],
[5781,289050,644500000,1000000000,19655400000],
[5782,289100,644500000,1000000000,19658800000],
[5783,289150,644500000,1000000000,19662200000],
[5784,289200,645000000,1000000000,19665600000],
[5785,289250,645000000,1000000000,19669000000],
[5786,289300,645000000,1000000000,19672400000],
[5787,289350,645500000,1000000000,19675800000],
[5788,289400,645500000,1000000000,19679200000],
[5789,289450,645500000,1000000000,19682600000],
[5790,289500,646000000,1000000000,19686000000],
[5791,289550,646000000,1000000000,19689400000],
[5792,289600,646000000,1000000000,19692800000],
[5793,289650,646000000,1000000000,19696200000],
[5794,289700,646500000,1000000000,19699600000],
[5795,289750,646500000,1000000000,19703000000],
[5796,289800,646500000,1000000000,19706400000],
[5797,289850,647000000,1000000000,19709800000],
[5798,289900,647000000,1000000000,19713200000],
[5799,289950,647000000,1000000000,19716600000],
[5800,290000,647500000,1000000000,19720000000],
[5801,290050,647500000,1000000000,19723400000],
[5802,290100,647500000,1000000000,19726800000],
[5803,290150,648000000,1000000000,19730200000],
[5804,290200,648000000,1000000000,19733600000],
[5805,290250,648000000,1000000000,19737000000],
[5806,290300,648000000,1000000000,19740400000],
[5807,290350,648500000,1000000000,19743800000],
[5808,290400,648500000,1000000000,19747200000],
[5809,290450,648500000,1000000000,19750600000],
[5810,290500,649000000,1000000000,19754000000],
[5811,290550,649000000,1000000000,19757400000],
[5812,290600,649000000,1000000000,19760800000],
[5813,290650,649500000,1000000000,19764200000],
[5814,290700,649500000,1000000000,19767600000],
[5815,290750,649500000,1000000000,19771000000],
[5816,290800,649500000,1000000000,19774400000],
[5817,290850,650000000,1000000000,19777800000],
[5818,290900,650000000,1000000000,19781200000],
[5819,290950,650000000,1000000000,19784600000],
[5820,291000,650500000,1000000000,19788000000],
[5821,291050,650500000,1000000000,19791400000],
[5822,291100,650500000,1000000000,19794800000],
[5823,291150,651000000,1000000000,19798200000],
[5824,291200,651000000,1000000000,19801600000],
[5825,291250,651000000,1000000000,19805000000],
[5826,291300,651000000,1000000000,19808400000],
[5827,291350,651500000,1000000000,19811800000],
[5828,291400,651500000,1000000000,19815200000],
[5829,291450,651500000,1000000000,19818600000],
[5830,291500,652000000,1000000000,19822000000],
[5831,291550,652000000,1000000000,19825400000],
[5832,291600,652000000,1000000000,19828800000],
[5833,291650,652500000,1000000000,19832200000],
[5834,291700,652500000,1000000000,19835600000],
[5835,291750,652500000,1000000000,19839000000],
[5836,291800,653000000,1000000000,19842400000],
[5837,291850,653000000,1000000000,19845800000],
[5838,291900,653000000,1000000000,19849200000],
[5839,291950,653000000,1000000000,19852600000],
[5840,292000,653500000,1000000000,19856000000],
[5841,292050,653500000,1000000000,19859400000],
[5842,292100,653500000,1000000000,19862800000],
[5843,292150,654000000,1000000000,19866200000],
[5844,292200,654000000,1000000000,19869600000],
[5845,292250,654000000,1000000000,19873000000],
[5846,292300,654500000,1000000000,19876400000],
[5847,292350,654500000,1000000000,19879800000],
[5848,292400,654500000,1000000000,19883200000],
[5849,292450,654500000,1000000000,19886600000],
[5850,292500,655000000,1000000000,19890000000],
[5851,292550,655000000,1000000000,19893400000],
[5852,292600,655000000,1000000000,19896800000],
[5853,292650,655500000,1000000000,19900200000],
[5854,292700,655500000,1000000000,19903600000],
[5855,292750,655500000,1000000000,19907000000],
[5856,292800,656000000,1000000000,19910400000],
[5857,292850,656000000,1000000000,19913800000],
[5858,292900,656000000,1000000000,19917200000],
[5859,292950,656000000,1000000000,19920600000],
[5860,293000,656500000,1000000000,19924000000],
[5861,293050,656500000,1000000000,19927400000],
[5862,293100,656500000,1000000000,19930800000],
[5863,293150,657000000,1000000000,19934200000],
[5864,293200,657000000,1000000000,19937600000],
[5865,293250,657000000,1000000000,19941000000],
[5866,293300,657500000,1000000000,19944400000],
[5867,293350,657500000,1000000000,19947800000],
[5868,293400,657500000,1000000000,19951200000],
[5869,293450,658000000,1000000000,19954600000],
[5870,293500,658000000,1000000000,19958000000],
[5871,293550,658000000,1000000000,19961400000],
[5872,293600,658000000,1000000000,19964800000],
[5873,293650,658500000,1000000000,19968200000],
[5874,293700,658500000,1000000000,19971600000],
[5875,293750,658500000,1000000000,19975000000],
[5876,293800,659000000,1000000000,19978400000],
[5877,293850,659000000,1000000000,19981800000],
[5878,293900,659000000,1000000000,19985200000],
[5879,293950,659500000,1000000000,19988600000],
[5880,294000,659500000,1000000000,19992000000],
[5881,294050,659500000,1000000000,19995400000],
[5882,294100,659500000,1000000000,19998800000],
[5883,294150,660000000,1000000000,20002200000],
[5884,294200,660000000,1000000000,20005600000],
[5885,294250,660000000,1000000000,20009000000],
[5886,294300,660500000,1000000000,20012400000],
[5887,294350,660500000,1000000000,20015800000],
[5888,294400,660500000,1000000000,20019200000],
[5889,294450,661000000,1000000000,20022600000],
[5890,294500,661000000,1000000000,20026000000],
[5891,294550,661000000,1000000000,20029400000],
[5892,294600,661000000,1000000000,20032800000],
[5893,294650,661500000,1000000000,20036200000],
[5894,294700,661500000,1000000000,20039600000],
[5895,294750,661500000,1000000000,20043000000],
[5896,294800,662000000,1000000000,20046400000],
[5897,294850,662000000,1000000000,20049800000],
[5898,294900,662000000,1000000000,20053200000],
[5899,294950,662500000,1000000000,20056600000],
[5900,295000,662500000,1000000000,20060000000],
[5901,295050,662500000,1000000000,20063400000],
[5902,295100,663000000,1000000000,20066800000],
[5903,295150,663000000,1000000000,20070200000],
[5904,295200,663000000,1000000000,20073600000],
[5905,295250,663000000,1000000000,20077000000],
[5906,295300,663500000,1000000000,20080400000],
[5907,295350,663500000,1000000000,20083800000],
[5908,295400,663500000,1000000000,20087200000],
[5909,295450,664000000,1000000000,20090600000],
[5910,295500,664000000,1000000000,20094000000],
[5911,295550,664000000,1000000000,20097400000],
[5912,295600,664500000,1000000000,20100800000],
[5913,295650,664500000,1000000000,20104200000],
[5914,295700,664500000,1000000000,20107600000],
[5915,295750,664500000,1000000000,20111000000],
[5916,295800,665000000,1000000000,20114400000],
[5917,295850,665000000,1000000000,20117800000],
[5918,295900,665000000,1000000000,20121200000],
[5919,295950,665500000,1000000000,20124600000],
[5920,296000,665500000,1000000000,20128000000],
[5921,296050,665500000,1000000000,20131400000],
[5922,296100,666000000,1000000000,20134800000],
[5923,296150,666000000,1000000000,20138200000],
[5924,296200,666000000,1000000000,20141600000],
[5925,296250,666000000,1000000000,20145000000],
[5926,296300,666500000,1000000000,20148400000],
[5927,296350,666500000,1000000000,20151800000],
[5928,296400,666500000,1000000000,20155200000],
[5929,296450,667000000,1000000000,20158600000],
[5930,296500,667000000,1000000000,20162000000],
[5931,296550,667000000,1000000000,20165400000],
[5932,296600,667500000,1000000000,20168800000],
[5933,296650,667500000,1000000000,20172200000],
[5934,296700,667500000,1000000000,20175600000],
[5935,296750,668000000,1000000000,20179000000],
[5936,296800,668000000,1000000000,20182400000],
[5937,296850,668000000,1000000000,20185800000],
[5938,296900,668000000,1000000000,20189200000],
[5939,296950,668500000,1000000000,20192600000],
[5940,297000,668500000,1000000000,20196000000],
[5941,297050,668500000,1000000000,20199400000],
[5942,297100,669000000,1000000000,20202800000],
[5943,297150,669000000,1000000000,20206200000],
[5944,297200,669000000,1000000000,20209600000],
[5945,297250,669500000,1000000000,20213000000],
[5946,297300,669500000,1000000000,20216400000],
[5947,297350,669500000,1000000000,20219800000],
[5948,297400,669500000,1000000000,20223200000],
[5949,297450,670000000,1000000000,20226600000],
[5950,297500,670000000,1000000000,20230000000],
[5951,297550,670000000,1000000000,20233400000],
[5952,297600,670500000,1000000000,20236800000],
[5953,297650,670500000,1000000000,20240200000],
[5954,297700,670500000,1000000000,20243600000],
[5955,297750,671000000,1000000000,20247000000],
[5956,297800,671000000,1000000000,20250400000],
[5957,297850,671000000,1000000000,20253800000],
[5958,297900,671000000,1000000000,20257200000],
[5959,297950,671500000,1000000000,20260600000],
[5960,298000,671500000,1000000000,20264000000],
[5961,298050,671500000,1000000000,20267400000],
[5962,298100,672000000,1000000000,20270800000],
[5963,298150,672000000,1000000000,20274200000],
[5964,298200,672000000,1000000000,20277600000],
[5965,298250,672500000,1000000000,20281000000],
[5966,298300,672500000,1000000000,20284400000],
[5967,298350,672500000,1000000000,20287800000],
[5968,298400,673000000,1000000000,20291200000],
[5969,298450,673000000,1000000000,20294600000],
[5970,298500,673000000,1000000000,20298000000],
[5971,298550,673000000,1000000000,20301400000],
[5972,298600,673500000,1000000000,20304800000],
[5973,298650,673500000,1000000000,20308200000],
[5974,298700,673500000,1000000000,20311600000],
[5975,298750,674000000,1000000000,20315000000],
[5976,298800,674000000,1000000000,20318400000],
[5977,298850,674000000,1000000000,20321800000],
[5978,298900,674500000,1000000000,20325200000],
[5979,298950,674500000,1000000000,20328600000],
[5980,299000,674500000,1000000000,20332000000],
[5981,299050,674500000,1000000000,20335400000],
[5982,299100,675000000,1000000000,20338800000],
[5983,299150,675000000,1000000000,20342200000],
[5984,299200,675000000,1000000000,20345600000],
[5985,299250,675500000,1000000000,20349000000],
[5986,299300,675500000,1000000000,20352400000],
[5987,299350,675500000,1000000000,20355800000],
[5988,299400,676000000,1000000000,20359200000],
[5989,299450,676000000,1000000000,20362600000],
[5990,299500,676000000,1000000000,20366000000],
[5991,299550,676000000,1000000000,20369400000],
[5992,299600,676500000,1000000000,20372800000],
[5993,299650,676500000,1000000000,20376200000],
[5994,299700,676500000,1000000000,20379600000],
[5995,299750,677000000,1000000000,20383000000],
[5996,299800,677000000,1000000000,20386400000],
[5997,299850,677000000,1000000000,20389800000],
[5998,299900,677500000,1000000000,20393200000],
[5999,299950,677500000,1000000000,20396600000],
[6000,300000,677500000,1000000000,20400000000],
[6001,300050,678000000,1000000000,20403400000],
[6002,300100,678000000,1000000000,20406800000],
[6003,300150,678000000,1000000000,20410200000],
[6004,300200,678000000,1000000000,20413600000],
[6005,300250,678500000,1000000000,20417000000],
[6006,300300,678500000,1000000000,20420400000],
[6007,300350,678500000,1000000000,20423800000],
[6008,300400,679000000,1000000000,20427200000],
[6009,300450,679000000,1000000000,20430600000],
[6010,300500,679000000,1000000000,20434000000],
[6011,300550,679500000,1000000000,20437400000],
[6012,300600,679500000,1000000000,20440800000],
[6013,300650,679500000,1000000000,20444200000],
[6014,300700,679500000,1000000000,20447600000],
[6015,300750,680000000,1000000000,20451000000],
[6016,300800,680000000,1000000000,20454400000],
[6017,300850,680000000,1000000000,20457800000],
[6018,300900,680500000,1000000000,20461200000],
[6019,300950,680500000,1000000000,20464600000],
[6020,301000,680500000,1000000000,20468000000],
[6021,301050,681000000,1000000000,20471400000],
[6022,301100,681000000,1000000000,20474800000],
[6023,301150,681000000,1000000000,20478200000],
[6024,301200,681000000,1000000000,20481600000],
[6025,301250,681500000,1000000000,20485000000],
[6026,301300,681500000,1000000000,20488400000],
[6027,301350,681500000,1000000000,20491800000],
[6028,301400,682000000,1000000000,20495200000],
[6029,301450,682000000,1000000000,20498600000],
[6030,301500,682000000,1000000000,20502000000],
[6031,301550,682500000,1000000000,20505400000],
[6032,301600,682500000,1000000000,20508800000],
[6033,301650,682500000,1000000000,20512200000],
[6034,301700,683000000,1000000000,20515600000],
[6035,301750,683000000,1000000000,20519000000],
[6036,301800,683000000,1000000000,20522400000],
[6037,301850,683000000,1000000000,20525800000],
[6038,301900,683500000,1000000000,20529200000],
[6039,301950,683500000,1000000000,20532600000],
[6040,302000,683500000,1000000000,20536000000],
[6041,302050,684000000,1000000000,20539400000],
[6042,302100,684000000,1000000000,20542800000],
[6043,302150,684000000,1000000000,20546200000],
[6044,302200,684500000,1000000000,20549600000],
[6045,302250,684500000,1000000000,20553000000],
[6046,302300,684500000,1000000000,20556400000],
[6047,302350,684500000,1000000000,20559800000],
[6048,302400,685000000,1000000000,20563200000],
[6049,302450,685000000,1000000000,20566600000],
[6050,302500,685000000,1000000000,20570000000],
[6051,302550,685500000,1000000000,20573400000],
[6052,302600,685500000,1000000000,20576800000],
[6053,302650,685500000,1000000000,20580200000],
[6054,302700,686000000,1000000000,20583600000],
[6055,302750,686000000,1000000000,20587000000],
[6056,302800,686000000,1000000000,20590400000],
[6057,302850,686000000,1000000000,20593800000],
[6058,302900,686500000,1000000000,20597200000],
[6059,302950,686500000,1000000000,20600600000],
[6060,303000,686500000,1000000000,20604000000],
[6061,303050,687000000,1000000000,20607400000],
[6062,303100,687000000,1000000000,20610800000],
[6063,303150,687000000,1000000000,20614200000],
[6064,303200,687500000,1000000000,20617600000],
[6065,303250,687500000,1000000000,20621000000],
[6066,303300,687500000,1000000000,20624400000],
[6067,303350,688000000,1000000000,20627800000],
[6068,303400,688000000,1000000000,20631200000],
[6069,303450,688000000,1000000000,20634600000],
[6070,303500,688000000,1000000000,20638000000],
[6071,303550,688500000,1000000000,20641400000],
[6072,303600,688500000,1000000000,20644800000],
[6073,303650,688500000,1000000000,20648200000],
[6074,303700,689000000,1000000000,20651600000],
[6075,303750,689000000,1000000000,20655000000],
[6076,303800,689000000,1000000000,20658400000],
[6077,303850,689500000,1000000000,20661800000],
[6078,303900,689500000,1000000000,20665200000],
[6079,303950,689500000,1000000000,20668600000],
[6080,304000,689500000,1000000000,20672000000],
[6081,304050,690000000,1000000000,20675400000],
[6082,304100,690000000,1000000000,20678800000],
[6083,304150,690000000,1000000000,20682200000],
[6084,304200,690500000,1000000000,20685600000],
[6085,304250,690500000,1000000000,20689000000],
[6086,304300,690500000,1000000000,20692400000],
[6087,304350,691000000,1000000000,20695800000],
[6088,304400,691000000,1000000000,20699200000],
[6089,304450,691000000,1000000000,20702600000],
[6090,304500,691000000,1000000000,20706000000],
[6091,304550,691500000,1000000000,20709400000],
[6092,304600,691500000,1000000000,20712800000],
[6093,304650,691500000,1000000000,20716200000],
[6094,304700,692000000,1000000000,20719600000],
[6095,304750,692000000,1000000000,20723000000],
[6096,304800,692000000,1000000000,20726400000],
[6097,304850,692500000,1000000000,20729800000],
[6098,304900,692500000,1000000000,20733200000],
[6099,304950,692500000,1000000000,20736600000],
[6100,305000,693000000,1000000000,20740000000],
[6101,305050,693000000,1000000000,20743400000],
[6102,305100,693000000,1000000000,20746800000],
[6103,305150,693000000,1000000000,20750200000],
[6104,305200,693500000,1000000000,20753600000],
[6105,305250,693500000,1000000000,20757000000],
[6106,305300,693500000,1000000000,20760400000],
[6107,305350,694000000,1000000000,20763800000],
[6108,305400,694000000,1000000000,20767200000],
[6109,305450,694000000,1000000000,20770600000],
[6110,305500,694500000,1000000000,20774000000],
[6111,305550,694500000,1000000000,20777400000],
[6112,305600,694500000,1000000000,20780800000],
[6113,305650,694500000,1000000000,20784200000],
[6114,305700,695000000,1000000000,20787600000],
[6115,305750,695000000,1000000000,20791000000],
[6116,305800,695000000,1000000000,20794400000],
[6117,305850,695500000,1000000000,20797800000],
[6118,305900,695500000,1000000000,20801200000],
[6119,305950,695500000,1000000000,20804600000],
[6120,306000,696000000,1000000000,20808000000],
[6121,306050,696000000,1000000000,20811400000],
[6122,306100,696000000,1000000000,20814800000],
[6123,306150,696000000,1000000000,20818200000],
[6124,306200,696500000,1000000000,20821600000],
[6125,306250,696500000,1000000000,20825000000],
[6126,306300,696500000,1000000000,20828400000],
[6127,306350,697000000,1000000000,20831800000],
[6128,306400,697000000,1000000000,20835200000],
[6129,306450,697000000,1000000000,20838600000],
[6130,306500,697500000,1000000000,20842000000],
[6131,306550,697500000,1000000000,20845400000],
[6132,306600,697500000,1000000000,20848800000],
[6133,306650,698000000,1000000000,20852200000],
[6134,306700,698000000,1000000000,20855600000],
[6135,306750,698000000,1000000000,20859000000],
[6136,306800,698000000,1000000000,20862400000],
[6137,306850,698500000,1000000000,20865800000],
[6138,306900,698500000,1000000000,20869200000],
[6139,306950,698500000,1000000000,20872600000],
[6140,307000,699000000,1000000000,20876000000],
[6141,307050,699000000,1000000000,20879400000],
[6142,307100,699000000,1000000000,20882800000],
[6143,307150,699500000,1000000000,20886200000],
[6144,307200,699500000,1000000000,20889600000],
[6145,307250,699500000,1000000000,20893000000],
[6146,307300,699500000,1000000000,20896400000],
[6147,307350,700000000,1000000000,20899800000],
[6148,307400,700000000,1000000000,20903200000],
[6149,307450,700000000,1000000000,20906600000],
[6150,307500,700500000,1000000000,20910000000],
[6151,307550,700500000,1000000000,20913400000],
[6152,307600,700500000,1000000000,20916800000],
[6153,307650,701000000,1000000000,20920200000],
[6154,307700,701000000,1000000000,20923600000],
[6155,307750,701000000,1000000000,20927000000],
[6156,307800,701000000,1000000000,20930400000],
[6157,307850,701500000,1000000000,20933800000],
[6158,307900,701500000,1000000000,20937200000],
[6159,307950,701500000,1000000000,20940600000],
[6160,308000,702000000,1000000000,20944000000],
[6161,308050,702000000,1000000000,20947400000],
[6162,308100,702000000,1000000000,20950800000],
[6163,308150,702500000,1000000000,20954200000],
[6164,308200,702500000,1000000000,20957600000],
[6165,308250,702500000,1000000000,20961000000],
[6166,308300,703000000,1000000000,20964400000],
[6167,308350,703000000,1000000000,20967800000],
[6168,308400,703000000,1000000000,20971200000],
[6169,308450,703000000,1000000000,20974600000],
[6170,308500,703500000,1000000000,20978000000],
[6171,308550,703500000,1000000000,20981400000],
[6172,308600,703500000,1000000000,20984800000],
[6173,308650,704000000,1000000000,20988200000],
[6174,308700,704000000,1000000000,20991600000],
[6175,308750,704000000,1000000000,20995000000],
[6176,308800,704500000,1000000000,20998400000],
[6177,308850,704500000,1000000000,21001800000],
[6178,308900,704500000,1000000000,21005200000],
[6179,308950,704500000,1000000000,21008600000],
[6180,309000,705000000,1000000000,21012000000],
[6181,309050,705000000,1000000000,21015400000],
[6182,309100,705000000,1000000000,21018800000],
[6183,309150,705500000,1000000000,21022200000],
[6184,309200,705500000,1000000000,21025600000],
[6185,309250,705500000,1000000000,21029000000],
[6186,309300,706000000,1000000000,21032400000],
[6187,309350,706000000,1000000000,21035800000],
[6188,309400,706000000,1000000000,21039200000],
[6189,309450,706000000,1000000000,21042600000],
[6190,309500,706500000,1000000000,21046000000],
[6191,309550,706500000,1000000000,21049400000],
[6192,309600,706500000,1000000000,21052800000],
[6193,309650,707000000,1000000000,21056200000],
[6194,309700,707000000,1000000000,21059600000],
[6195,309750,707000000,1000000000,21063000000],
[6196,309800,707500000,1000000000,21066400000],
[6197,309850,707500000,1000000000,21069800000],
[6198,309900,707500000,1000000000,21073200000],
[6199,309950,708000000,1000000000,21076600000],
[6200,310000,708000000,1000000000,21080000000],
[6201,310050,708000000,1000000000,21083400000],
[6202,310100,708000000,1000000000,21086800000],
[6203,310150,708500000,1000000000,21090200000],
[6204,310200,708500000,1000000000,21093600000],
[6205,310250,708500000,1000000000,21097000000],
[6206,310300,709000000,1000000000,21100400000],
[6207,310350,709000000,1000000000,21103800000],
[6208,310400,709000000,1000000000,21107200000],
[6209,310450,709500000,1000000000,21110600000],
[6210,310500,709500000,1000000000,21114000000],
[6211,310550,709500000,1000000000,21117400000],
[6212,310600,709500000,1000000000,21120800000],
[6213,310650,710000000,1000000000,21124200000],
[6214,310700,710000000,1000000000,21127600000],
[6215,310750,710000000,1000000000,21131000000],
[6216,310800,710500000,1000000000,21134400000],
[6217,310850,710500000,1000000000,21137800000],
[6218,310900,710500000,1000000000,21141200000],
[6219,310950,711000000,1000000000,21144600000],
[6220,311000,711000000,1000000000,21148000000],
[6221,311050,711000000,1000000000,21151400000],
[6222,311100,711000000,1000000000,21154800000],
[6223,311150,711500000,1000000000,21158200000],
[6224,311200,711500000,1000000000,21161600000],
[6225,311250,711500000,1000000000,21165000000],
[6226,311300,712000000,1000000000,21168400000],
[6227,311350,712000000,1000000000,21171800000],
[6228,311400,712000000,1000000000,21175200000],
[6229,311450,712500000,1000000000,21178600000],
[6230,311500,712500000,1000000000,21182000000],
[6231,311550,712500000,1000000000,21185400000],
[6232,311600,713000000,1000000000,21188800000],
[6233,311650,713000000,1000000000,21192200000],
[6234,311700,713000000,1000000000,21195600000],
[6235,311750,713000000,1000000000,21199000000],
[6236,311800,713500000,1000000000,21202400000],
[6237,311850,713500000,1000000000,21205800000],
[6238,311900,713500000,1000000000,21209200000],
[6239,311950,714000000,1000000000,21212600000],
[6240,312000,714000000,1000000000,21216000000],
[6241,312050,714000000,1000000000,21219400000],
[6242,312100,714500000,1000000000,21222800000],
[6243,312150,714500000,1000000000,21226200000],
[6244,312200,714500000,1000000000,21229600000],
[6245,312250,714500000,1000000000,21233000000],
[6246,312300,715000000,1000000000,21236400000],
[6247,312350,715000000,1000000000,21239800000],
[6248,312400,715000000,1000000000,21243200000],
[6249,312450,715500000,1000000000,21246600000],
[6250,312500,715500000,1000000000,21250000000],
[6251,312550,715500000,1000000000,21253400000],
[6252,312600,716000000,1000000000,21256800000],
[6253,312650,716000000,1000000000,21260200000],
[6254,312700,716000000,1000000000,21263600000],
[6255,312750,716000000,1000000000,21267000000],
[6256,312800,716500000,1000000000,21270400000],
[6257,312850,716500000,1000000000,21273800000],
[6258,312900,716500000,1000000000,21277200000],
[6259,312950,717000000,1000000000,21280600000],
[6260,313000,717000000,1000000000,21284000000],
[6261,313050,717000000,1000000000,21287400000],
[6262,313100,717500000,1000000000,21290800000],
[6263,313150,717500000,1000000000,21294200000],
[6264,313200,717500000,1000000000,21297600000],
[6265,313250,718000000,1000000000,21301000000],
[6266,313300,718000000,1000000000,21304400000],
[6267,313350,718000000,1000000000,21307800000],
[6268,313400,718000000,1000000000,21311200000],
[6269,313450,718500000,1000000000,21314600000],
[6270,313500,718500000,1000000000,21318000000],
[6271,313550,718500000,1000000000,21321400000],
[6272,313600,719000000,1000000000,21324800000],
[6273,313650,719000000,1000000000,21328200000],
[6274,313700,719000000,1000000000,21331600000],
[6275,313750,719500000,1000000000,21335000000],
[6276,313800,719500000,1000000000,21338400000],
[6277,313850,719500000,1000000000,21341800000],
[6278,313900,719500000,1000000000,21345200000],
[6279,313950,720000000,1000000000,21348600000],
[6280,314000,720000000,1000000000,21352000000],
[6281,314050,720000000,1000000000,21355400000],
[6282,314100,720500000,1000000000,21358800000],
[6283,314150,720500000,1000000000,21362200000],
[6284,314200,720500000,1000000000,21365600000],
[6285,314250,721000000,1000000000,21369000000],
[6286,314300,721000000,1000000000,21372400000],
[6287,314350,721000000,1000000000,21375800000],
[6288,314400,721000000,1000000000,21379200000],
[6289,314450,721500000,1000000000,21382600000],
[6290,314500,721500000,1000000000,21386000000],
[6291,314550,721500000,1000000000,21389400000],
[6292,314600,722000000,1000000000,21392800000],
[6293,314650,722000000,1000000000,21396200000],
[6294,314700,722000000,1000000000,21399600000],
[6295,314750,722500000,1000000000,21403000000],
[6296,314800,722500000,1000000000,21406400000],
[6297,314850,722500000,1000000000,21409800000],
[6298,314900,723000000,1000000000,21413200000],
[6299,314950,723000000,1000000000,21416600000],
[6300,315000,723000000,1000000000,21420000000],
[6301,315050,723000000,1000000000,21423400000],
[6302,315100,723500000,1000000000,21426800000],
[6303,315150,723500000,1000000000,21430200000],
[6304,315200,723500000,1000000000,21433600000],
[6305,315250,724000000,1000000000,21437000000],
[6306,315300,724000000,1000000000,21440400000],
[6307,315350,724000000,1000000000,21443800000],
[6308,315400,724500000,1000000000,21447200000],
[6309,315450,724500000,1000000000,21450600000],
[6310,315500,724500000,1000000000,21454000000],
[6311,315550,724500000,1000000000,21457400000],
[6312,315600,725000000,1000000000,21460800000],
[6313,315650,725000000,1000000000,21464200000],
[6314,315700,725000000,1000000000,21467600000],
[6315,315750,725500000,1000000000,21471000000],
[6316,315800,725500000,1000000000,21474400000],
[6317,315850,725500000,1000000000,21477800000],
[6318,315900,726000000,1000000000,21481200000],
[6319,315950,726000000,1000000000,21484600000],
[6320,316000,726000000,1000000000,21488000000],
[6321,316050,726000000,1000000000,21491400000],
[6322,316100,726500000,1000000000,21494800000],
[6323,316150,726500000,1000000000,21498200000],
[6324,316200,726500000,1000000000,21501600000],
[6325,316250,727000000,1000000000,21505000000],
[6326,316300,727000000,1000000000,21508400000],
[6327,316350,727000000,1000000000,21511800000],
[6328,316400,727500000,1000000000,21515200000],
[6329,316450,727500000,1000000000,21518600000],
[6330,316500,727500000,1000000000,21522000000],
[6331,316550,728000000,1000000000,21525400000],
[6332,316600,728000000,1000000000,21528800000],
[6333,316650,728000000,1000000000,21532200000],
[6334,316700,728000000,1000000000,21535600000],
[6335,316750,728500000,1000000000,21539000000],
[6336,316800,728500000,1000000000,21542400000],
[6337,316850,728500000,1000000000,21545800000],
[6338,316900,729000000,1000000000,21549200000],
[6339,316950,729000000,1000000000,21552600000],
[6340,317000,729000000,1000000000,21556000000],
[6341,317050,729500000,1000000000,21559400000],
[6342,317100,729500000,1000000000,21562800000],
[6343,317150,729500000,1000000000,21566200000],
[6344,317200,729500000,1000000000,21569600000],
[6345,317250,730000000,1000000000,21573000000],
[6346,317300,730000000,1000000000,21576400000],
[6347,317350,730000000,1000000000,21579800000],
[6348,317400,730500000,1000000000,21583200000],
[6349,317450,730500000,1000000000,21586600000],
[6350,317500,730500000,1000000000,21590000000],
[6351,317550,731000000,1000000000,21593400000],
[6352,317600,731000000,1000000000,21596800000],
[6353,317650,731000000,1000000000,21600200000],
[6354,317700,731000000,1000000000,21603600000],
[6355,317750,731500000,1000000000,21607000000],
[6356,317800,731500000,1000000000,21610400000],
[6357,317850,731500000,1000000000,21613800000],
[6358,317900,732000000,1000000000,21617200000],
[6359,317950,732000000,1000000000,21620600000],
[6360,318000,732000000,1000000000,21624000000],
[6361,318050,732500000,1000000000,21627400000],
[6362,318100,732500000,1000000000,21630800000],
[6363,318150,732500000,1000000000,21634200000],
[6364,318200,733000000,1000000000,21637600000],
[6365,318250,733000000,1000000000,21641000000],
[6366,318300,733000000,1000000000,21644400000],
[6367,318350,733000000,1000000000,21647800000],
[6368,318400,733500000,1000000000,21651200000],
[6369,318450,733500000,1000000000,21654600000],
[6370,318500,733500000,1000000000,21658000000],
[6371,318550,734000000,1000000000,21661400000],
[6372,318600,734000000,1000000000,21664800000],
[6373,318650,734000000,1000000000,21668200000],
[6374,318700,734500000,1000000000,21671600000],
[6375,318750,734500000,1000000000,21675000000],
[6376,318800,734500000,1000000000,21678400000],
[6377,318850,734500000,1000000000,21681800000],
[6378,318900,735000000,1000000000,21685200000],
[6379,318950,735000000,1000000000,21688600000],
[6380,319000,735000000,1000000000,21692000000],
[6381,319050,735500000,1000000000,21695400000],
[6382,319100,735500000,1000000000,21698800000],
[6383,319150,735500000,1000000000,21702200000],
[6384,319200,736000000,1000000000,21705600000],
[6385,319250,736000000,1000000000,21709000000],
[6386,319300,736000000,1000000000,21712400000],
[6387,319350,736000000,1000000000,21715800000],
[6388,319400,736500000,1000000000,21719200000],
[6389,319450,736500000,1000000000,21722600000],
[6390,319500,736500000,1000000000,21726000000],
[6391,319550,737000000,1000000000,21729400000],
[6392,319600,737000000,1000000000,21732800000],
[6393,319650,737000000,1000000000,21736200000],
[6394,319700,737500000,1000000000,21739600000],
[6395,319750,737500000,1000000000,21743000000],
[6396,319800,737500000,1000000000,21746400000],
[6397,319850,738000000,1000000000,21749800000],
[6398,319900,738000000,1000000000,21753200000],
[6399,319950,738000000,1000000000,21756600000],
[6400,320000,738000000,1000000000,21760000000],
[6401,320050,738500000,1000000000,21763400000],
[6402,320100,738500000,1000000000,21766800000],
[6403,320150,738500000,1000000000,21770200000],
[6404,320200,739000000,1000000000,21773600000],
[6405,320250,739000000,1000000000,21777000000],
[6406,320300,739000000,1000000000,21780400000],
[6407,320350,739500000,1000000000,21783800000],
[6408,320400,739500000,1000000000,21787200000],
[6409,320450,739500000,1000000000,21790600000],
[6410,320500,739500000,1000000000,21794000000],
[6411,320550,740000000,1000000000,21797400000],
[6412,320600,740000000,1000000000,21800800000],
[6413,320650,740000000,1000000000,21804200000],
[6414,320700,740500000,1000000000,21807600000],
[6415,320750,740500000,1000000000,21811000000],
[6416,320800,740500000,1000000000,21814400000],
[6417,320850,741000000,1000000000,21817800000],
[6418,320900,741000000,1000000000,21821200000],
[6419,320950,741000000,1000000000,21824600000],
[6420,321000,741000000,1000000000,21828000000],
[6421,321050,741500000,1000000000,21831400000],
[6422,321100,741500000,1000000000,21834800000],
[6423,321150,741500000,1000000000,21838200000],
[6424,321200,742000000,1000000000,21841600000],
[6425,321250,742000000,1000000000,21845000000],
[6426,321300,742000000,1000000000,21848400000],
[6427,321350,742500000,1000000000,21851800000],
[6428,321400,742500000,1000000000,21855200000],
[6429,321450,742500000,1000000000,21858600000],
[6430,321500,743000000,1000000000,21862000000],
[6431,321550,743000000,1000000000,21865400000],
[6432,321600,743000000,1000000000,21868800000],
[6433,321650,743000000,1000000000,21872200000],
[6434,321700,743500000,1000000000,21875600000],
[6435,321750,743500000,1000000000,21879000000],
[6436,321800,743500000,1000000000,21882400000],
[6437,321850,744000000,1000000000,21885800000],
[6438,321900,744000000,1000000000,21889200000],
[6439,321950,744000000,1000000000,21892600000],
[6440,322000,744500000,1000000000,21896000000],
[6441,322050,744500000,1000000000,21899400000],
[6442,322100,744500000,1000000000,21902800000],
[6443,322150,744500000,1000000000,21906200000],
[6444,322200,745000000,1000000000,21909600000],
[6445,322250,745000000,1000000000,21913000000],
[6446,322300,745000000,1000000000,21916400000],
[6447,322350,745500000,1000000000,21919800000],
[6448,322400,745500000,1000000000,21923200000],
[6449,322450,745500000,1000000000,21926600000],
[6450,322500,746000000,1000000000,21930000000],
[6451,322550,746000000,1000000000,21933400000],
[6452,322600,746000000,1000000000,21936800000],
[6453,322650,746000000,1000000000,21940200000],
[6454,322700,746500000,1000000000,21943600000],
[6455,322750,746500000,1000000000,21947000000],
[6456,322800,746500000,1000000000,21950400000],
[6457,322850,747000000,1000000000,21953800000],
[6458,322900,747000000,1000000000,21957200000],
[6459,322950,747000000,1000000000,21960600000],
[6460,323000,747500000,1000000000,21964000000],
[6461,323050,747500000,1000000000,21967400000],
[6462,323100,747500000,1000000000,21970800000],
[6463,323150,748000000,1000000000,21974200000],
[6464,323200,748000000,1000000000,21977600000],
[6465,323250,748000000,1000000000,21981000000],
[6466,323300,748000000,1000000000,21984400000],
[6467,323350,748500000,1000000000,21987800000],
[6468,323400,748500000,1000000000,21991200000],
[6469,323450,748500000,1000000000,21994600000],
[6470,323500,749000000,1000000000,21998000000],
[6471,323550,749000000,1000000000,22001400000],
[6472,323600,749000000,1000000000,22004800000],
[6473,323650,749500000,1000000000,22008200000],
[6474,323700,749500000,1000000000,22011600000],
[6475,323750,749500000,1000000000,22015000000],
[6476,323800,749500000,1000000000,22018400000],
[6477,323850,750000000,1000000000,22021800000],
[6478,323900,750000000,1000000000,22025200000],
[6479,323950,750000000,1000000000,22028600000],
[6480,324000,750500000,1000000000,22032000000],
[6481,324050,750500000,1000000000,22035400000],
[6482,324100,750500000,1000000000,22038800000],
[6483,324150,751000000,1000000000,22042200000],
[6484,324200,751000000,1000000000,22045600000],
[6485,324250,751000000,1000000000,22049000000],
[6486,324300,751000000,1000000000,22052400000],
[6487,324350,751500000,1000000000,22055800000],
[6488,324400,751500000,1000000000,22059200000],
[6489,324450,751500000,1000000000,22062600000],
[6490,324500,752000000,1000000000,22066000000],
[6491,324550,752000000,1000000000,22069400000],
[6492,324600,752000000,1000000000,22072800000],
[6493,324650,752500000,1000000000,22076200000],
[6494,324700,752500000,1000000000,22079600000],
[6495,324750,752500000,1000000000,22083000000],
[6496,324800,753000000,1000000000,22086400000],
[6497,324850,753000000,1000000000,22089800000],
[6498,324900,753000000,1000000000,22093200000],
[6499,324950,753000000,1000000000,22096600000],
[6500,325000,753500000,1000000000,22100000000],
[6501,325050,753500000,1000000000,22103400000],
[6502,325100,753500000,1000000000,22106800000],
[6503,325150,754000000,1000000000,22110200000],
[6504,325200,754000000,1000000000,22113600000],
[6505,325250,754000000,1000000000,22117000000],
[6506,325300,754500000,1000000000,22120400000],
[6507,325350,754500000,1000000000,22123800000],
[6508,325400,754500000,1000000000,22127200000],
[6509,325450,754500000,1000000000,22130600000],
[6510,325500,755000000,1000000000,22134000000],
[6511,325550,755000000,1000000000,22137400000],
[6512,325600,755000000,1000000000,22140800000],
[6513,325650,755500000,1000000000,22144200000],
[6514,325700,755500000,1000000000,22147600000],
[6515,325750,755500000,1000000000,22151000000],
[6516,325800,756000000,1000000000,22154400000],
[6517,325850,756000000,1000000000,22157800000],
[6518,325900,756000000,1000000000,22161200000],
[6519,325950,756000000,1000000000,22164600000],
[6520,326000,756500000,1000000000,22168000000],
[6521,326050,756500000,1000000000,22171400000],
[6522,326100,756500000,1000000000,22174800000],
[6523,326150,757000000,1000000000,22178200000],
[6524,326200,757000000,1000000000,22181600000],
[6525,326250,757000000,1000000000,22185000000],
[6526,326300,757500000,1000000000,22188400000],
[6527,326350,757500000,1000000000,22191800000],
[6528,326400,757500000,1000000000,22195200000],
[6529,326450,758000000,1000000000,22198600000],
[6530,326500,758000000,1000000000,22202000000],
[6531,326550,758000000,1000000000,22205400000],
[6532,326600,758000000,1000000000,22208800000],
[6533,326650,758500000,1000000000,22212200000],
[6534,326700,758500000,1000000000,22215600000],
[6535,326750,758500000,1000000000,22219000000],
[6536,326800,759000000,1000000000,22222400000],
[6537,326850,759000000,1000000000,22225800000],
[6538,326900,759000000,1000000000,22229200000],
[6539,326950,759500000,1000000000,22232600000],
[6540,327000,759500000,1000000000,22236000000],
[6541,327050,759500000,1000000000,22239400000],
[6542,327100,759500000,1000000000,22242800000],
[6543,327150,760000000,1000000000,22246200000],
[6544,327200,760000000,1000000000,22249600000],
[6545,327250,760000000,1000000000,22253000000],
[6546,327300,760500000,1000000000,22256400000],
[6547,327350,760500000,1000000000,22259800000],
[6548,327400,760500000,1000000000,22263200000],
[6549,327450,761000000,1000000000,22266600000],
[6550,327500,761000000,1000000000,22270000000],
[6551,327550,761000000,1000000000,22273400000],
[6552,327600,761000000,1000000000,22276800000],
[6553,327650,761500000,1000000000,22280200000],
[6554,327700,761500000,1000000000,22283600000],
[6555,327750,761500000,1000000000,22287000000],
[6556,327800,762000000,1000000000,22290400000],
[6557,327850,762000000,1000000000,22293800000],
[6558,327900,762000000,1000000000,22297200000],
[6559,327950,762500000,1000000000,22300600000],
[6560,328000,762500000,1000000000,22304000000],
[6561,328050,762500000,1000000000,22307400000],
[6562,328100,763000000,1000000000,22310800000],
[6563,328150,763000000,1000000000,22314200000],
[6564,328200,763000000,1000000000,22317600000],
[6565,328250,763000000,1000000000,22321000000],
[6566,328300,763500000,1000000000,22324400000],
[6567,328350,763500000,1000000000,22327800000],
[6568,328400,763500000,1000000000,22331200000],
[6569,328450,764000000,1000000000,22334600000],
[6570,328500,764000000,1000000000,22338000000],
[6571,328550,764000000,1000000000,22341400000],
[6572,328600,764500000,1000000000,22344800000],
[6573,328650,764500000,1000000000,22348200000],
[6574,328700,764500000,1000000000,22351600000],
[6575,328750,764500000,1000000000,22355000000],
[6576,328800,765000000,1000000000,22358400000],
[6577,328850,765000000,1000000000,22361800000],
[6578,328900,765000000,1000000000,22365200000],
[6579,328950,765500000,1000000000,22368600000],
[6580,329000,765500000,1000000000,22372000000],
[6581,329050,765500000,1000000000,22375400000],
[6582,329100,766000000,1000000000,22378800000],
[6583,329150,766000000,1000000000,22382200000],
[6584,329200,766000000,1000000000,22385600000],
[6585,329250,766000000,1000000000,22389000000],
[6586,329300,766500000,1000000000,22392400000],
[6587,329350,766500000,1000000000,22395800000],
[6588,329400,766500000,1000000000,22399200000],
[6589,329450,767000000,1000000000,22402600000],
[6590,329500,767000000,1000000000,22406000000],
[6591,329550,767000000,1000000000,22409400000],
[6592,329600,767500000,1000000000,22412800000],
[6593,329650,767500000,1000000000,22416200000],
[6594,329700,767500000,1000000000,22419600000],
[6595,329750,768000000,1000000000,22423000000],
[6596,329800,768000000,1000000000,22426400000],
[6597,329850,768000000,1000000000,22429800000],
[6598,329900,768000000,1000000000,22433200000],
[6599,329950,768500000,1000000000,22436600000],
[6600,330000,768500000,1000000000,22440000000],
[6601,330050,768500000,1000000000,22443400000],
[6602,330100,769000000,1000000000,22446800000],
[6603,330150,769000000,1000000000,22450200000],
[6604,330200,769000000,1000000000,22453600000],
[6605,330250,769500000,1000000000,22457000000],
[6606,330300,769500000,1000000000,22460400000],
[6607,330350,769500000,1000000000,22463800000],
[6608,330400,769500000,1000000000,22467200000],
[6609,330450,770000000,1000000000,22470600000],
[6610,330500,770000000,1000000000,22474000000],
[6611,330550,770000000,1000000000,22477400000],
[6612,330600,770500000,1000000000,22480800000],
[6613,330650,770500000,1000000000,22484200000],
[6614,330700,770500000,1000000000,22487600000],
[6615,330750,771000000,1000000000,22491000000],
[6616,330800,771000000,1000000000,22494400000],
[6617,330850,771000000,1000000000,22497800000],
[6618,330900,771000000,1000000000,22501200000],
[6619,330950,771500000,1000000000,22504600000],
[6620,331000,771500000,1000000000,22508000000],
[6621,331050,771500000,1000000000,22511400000],
[6622,331100,772000000,1000000000,22514800000],
[6623,331150,772000000,1000000000,22518200000],
[6624,331200,772000000,1000000000,22521600000],
[6625,331250,772500000,1000000000,22525000000],
[6626,331300,772500000,1000000000,22528400000],
[6627,331350,772500000,1000000000,22531800000],
[6628,331400,773000000,1000000000,22535200000],
[6629,331450,773000000,1000000000,22538600000],
[6630,331500,773000000,1000000000,22542000000],
[6631,331550,773000000,1000000000,22545400000],
[6632,331600,773500000,1000000000,22548800000],
[6633,331650,773500000,1000000000,22552200000],
[6634,331700,773500000,1000000000,22555600000],
[6635,331750,774000000,1000000000,22559000000],
[6636,331800,774000000,1000000000,22562400000],
[6637,331850,774000000,1000000000,22565800000],
[6638,331900,774500000,1000000000,22569200000],
[6639,331950,774500000,1000000000,22572600000],
[6640,332000,774500000,1000000000,22576000000],
[6641,332050,774500000,1000000000,22579400000],
[6642,332100,775000000,1000000000,22582800000],
[6643,332150,775000000,1000000000,22586200000],
[6644,332200,775000000,1000000000,22589600000],
[6645,332250,775500000,1000000000,22593000000],
[6646,332300,775500000,1000000000,22596400000],
[6647,332350,775500000,1000000000,22599800000],
[6648,332400,776000000,1000000000,22603200000],
[6649,332450,776000000,1000000000,22606600000],
[6650,332500,776000000,1000000000,22610000000],
[6651,332550,776000000,1000000000,22613400000],
[6652,332600,776500000,1000000000,22616800000],
[6653,332650,776500000,1000000000,22620200000],
[6654,332700,776500000,1000000000,22623600000],
[6655,332750,777000000,1000000000,22627000000],
[6656,332800,777000000,1000000000,22630400000],
[6657,332850,777000000,1000000000,22633800000],
[6658,332900,777500000,1000000000,22637200000],
[6659,332950,777500000,1000000000,22640600000],
[6660,333000,777500000,1000000000,22644000000],
[6661,333050,778000000,1000000000,22647400000],
[6662,333100,778000000,1000000000,22650800000],
[6663,333150,778000000,1000000000,22654200000],
[6664,333200,778000000,1000000000,22657600000],
[6665,333250,778500000,1000000000,22661000000],
[6666,333300,778500000,1000000000,22664400000],
[6667,333350,778500000,1000000000,22667800000],
[6668,333400,779000000,1000000000,22671200000],
[6669,333450,779000000,1000000000,22674600000],
[6670,333500,779000000,1000000000,22678000000],
[6671,333550,779500000,1000000000,22681400000],
[6672,333600,779500000,1000000000,22684800000],
[6673,333650,779500000,1000000000,22688200000],
[6674,333700,779500000,1000000000,22691600000],
[6675,333750,780000000,1000000000,22695000000],
[6676,333800,780000000,1000000000,22698400000],
[6677,333850,780000000,1000000000,22701800000],
[6678,333900,780500000,1000000000,22705200000],
[6679,333950,780500000,1000000000,22708600000],
[6680,334000,780500000,1000000000,22712000000],
[6681,334050,781000000,1000000000,22715400000],
[6682,334100,781000000,1000000000,22718800000],
[6683,334150,781000000,1000000000,22722200000],
[6684,334200,781000000,1000000000,22725600000],
[6685,334250,781500000,1000000000,22729000000],
[6686,334300,781500000,1000000000,22732400000],
[6687,334350,781500000,1000000000,22735800000],
[6688,334400,782000000,1000000000,22739200000],
[6689,334450,782000000,1000000000,22742600000],
[6690,334500,782000000,1000000000,22746000000],
[6691,334550,782500000,1000000000,22749400000],
[6692,334600,782500000,1000000000,22752800000],
[6693,334650,782500000,1000000000,22756200000],
[6694,334700,783000000,1000000000,22759600000],
[6695,334750,783000000,1000000000,22763000000],
[6696,334800,783000000,1000000000,22766400000],
[6697,334850,783000000,1000000000,22769800000],
[6698,334900,783500000,1000000000,22773200000],
[6699,334950,783500000,1000000000,22776600000],
[6700,335000,783500000,1000000000,22780000000],
[6701,335050,784000000,1000000000,22783400000],
[6702,335100,784000000,1000000000,22786800000],
[6703,335150,784000000,1000000000,22790200000],
[6704,335200,784500000,1000000000,22793600000],
[6705,335250,784500000,1000000000,22797000000],
[6706,335300,784500000,1000000000,22800400000],
[6707,335350,784500000,1000000000,22803800000],
[6708,335400,785000000,1000000000,22807200000],
[6709,335450,785000000,1000000000,22810600000],
[6710,335500,785000000,1000000000,22814000000],
[6711,335550,785500000,1000000000,22817400000],
[6712,335600,785500000,1000000000,22820800000],
[6713,335650,785500000,1000000000,22824200000],
[6714,335700,786000000,1000000000,22827600000],
[6715,335750,786000000,1000000000,22831000000],
[6716,335800,786000000,1000000000,22834400000],
[6717,335850,786000000,1000000000,22837800000],
[6718,335900,786500000,1000000000,22841200000],
[6719,335950,786500000,1000000000,22844600000],
[6720,336000,786500000,1000000000,22848000000],
[6721,336050,787000000,1000000000,22851400000],
[6722,336100,787000000,1000000000,22854800000],
[6723,336150,787000000,1000000000,22858200000],
[6724,336200,787500000,1000000000,22861600000],
[6725,336250,787500000,1000000000,22865000000],
[6726,336300,787500000,1000000000,22868400000],
[6727,336350,788000000,1000000000,22871800000],
[6728,336400,788000000,1000000000,22875200000],
[6729,336450,788000000,1000000000,22878600000],
[6730,336500,788000000,1000000000,22882000000],
[6731,336550,788500000,1000000000,22885400000],
[6732,336600,788500000,1000000000,22888800000],
[6733,336650,788500000,1000000000,22892200000],
[6734,336700,789000000,1000000000,22895600000],
[6735,336750,789000000,1000000000,22899000000],
[6736,336800,789000000,1000000000,22902400000],
[6737,336850,789500000,1000000000,22905800000],
[6738,336900,789500000,1000000000,22909200000],
[6739,336950,789500000,1000000000,22912600000],
[6740,337000,789500000,1000000000,22916000000],
[6741,337050,790000000,1000000000,22919400000],
[6742,337100,790000000,1000000000,22922800000],
[6743,337150,790000000,1000000000,22926200000],
[6744,337200,790500000,1000000000,22929600000],
[6745,337250,790500000,1000000000,22933000000],
[6746,337300,790500000,1000000000,22936400000],
[6747,337350,791000000,1000000000,22939800000],
[6748,337400,791000000,1000000000,22943200000],
[6749,337450,791000000,1000000000,22946600000],
[6750,337500,791000000,1000000000,22950000000],
[6751,337550,791500000,1000000000,22953400000],
[6752,337600,791500000,1000000000,22956800000],
[6753,337650,791500000,1000000000,22960200000],
[6754,337700,792000000,1000000000,22963600000],
[6755,337750,792000000,1000000000,22967000000],
[6756,337800,792000000,1000000000,22970400000],
[6757,337850,792500000,1000000000,22973800000],
[6758,337900,792500000,1000000000,22977200000],
[6759,337950,792500000,1000000000,22980600000],
[6760,338000,793000000,1000000000,22984000000],
[6761,338050,793000000,1000000000,22987400000],
[6762,338100,793000000,1000000000,22990800000],
[6763,338150,793000000,1000000000,22994200000],
[6764,338200,793500000,1000000000,22997600000],
[6765,338250,793500000,1000000000,23001000000],
[6766,338300,793500000,1000000000,23004400000],
[6767,338350,794000000,1000000000,23007800000],
[6768,338400,794000000,1000000000,23011200000],
[6769,338450,794000000,1000000000,23014600000],
[6770,338500,794500000,1000000000,23018000000],
[6771,338550,794500000,1000000000,23021400000],
[6772,338600,794500000,1000000000,23024800000],
[6773,338650,794500000,1000000000,23028200000],
[6774,338700,795000000,1000000000,23031600000],
[6775,338750,795000000,1000000000,23035000000],
[6776,338800,795000000,1000000000,23038400000],
[6777,338850,795500000,1000000000,23041800000],
[6778,338900,795500000,1000000000,23045200000],
[6779,338950,795500000,1000000000,23048600000],
[6780,339000,796000000,1000000000,23052000000],
[6781,339050,796000000,1000000000,23055400000],
[6782,339100,796000000,1000000000,23058800000],
[6783,339150,796000000,1000000000,23062200000],
[6784,339200,796500000,1000000000,23065600000],
[6785,339250,796500000,1000000000,23069000000],
[6786,339300,796500000,1000000000,23072400000],
[6787,339350,797000000,1000000000,23075800000],
[6788,339400,797000000,1000000000,23079200000],
[6789,339450,797000000,1000000000,23082600000],
[6790,339500,797500000,1000000000,23086000000],
[6791,339550,797500000,1000000000,23089400000],
[6792,339600,797500000,1000000000,23092800000],
[6793,339650,798000000,1000000000,23096200000],
[6794,339700,798000000,1000000000,23099600000],
[6795,339750,798000000,1000000000,23103000000],
[6796,339800,798000000,1000000000,23106400000],
[6797,339850,798500000,1000000000,23109800000],
[6798,339900,798500000,1000000000,23113200000],
[6799,339950,798500000,1000000000,23116600000],
[6800,340000,799000000,1000000000,23120000000],
[6801,340050,799000000,1000000000,23123400000],
[6802,340100,799000000,1000000000,23126800000],
[6803,340150,799500000,1000000000,23130200000],
[6804,340200,799500000,1000000000,23133600000],
[6805,340250,799500000,1000000000,23137000000],
[6806,340300,799500000,1000000000,23140400000],
[6807,340350,800000000,1000000000,23143800000],
[6808,340400,800000000,1000000000,23147200000],
[6809,340450,800000000,1000000000,23150600000],
[6810,340500,800500000,1000000000,23154000000],
[6811,340550,800500000,1000000000,23157400000],
[6812,340600,800500000,1000000000,23160800000],
[6813,340650,801000000,1000000000,23164200000],
[6814,340700,801000000,1000000000,23167600000],
[6815,340750,801000000,1000000000,23171000000],
[6816,340800,801000000,1000000000,23174400000],
[6817,340850,801500000,1000000000,23177800000],
[6818,340900,801500000,1000000000,23181200000],
[6819,340950,801500000,1000000000,23184600000],
[6820,341000,802000000,1000000000,23188000000],
[6821,341050,802000000,1000000000,23191400000],
[6822,341100,802000000,1000000000,23194800000],
[6823,341150,802500000,1000000000,23198200000],
[6824,341200,802500000,1000000000,23201600000],
[6825,341250,802500000,1000000000,23205000000],
[6826,341300,803000000,1000000000,23208400000],
[6827,341350,803000000,1000000000,23211800000],
[6828,341400,803000000,1000000000,23215200000],
[6829,341450,803000000,1000000000,23218600000],
[6830,341500,803500000,1000000000,23222000000],
[6831,341550,803500000,1000000000,23225400000],
[6832,341600,803500000,1000000000,23228800000],
[6833,341650,804000000,1000000000,23232200000],
[6834,341700,804000000,1000000000,23235600000],
[6835,341750,804000000,1000000000,23239000000],
[6836,341800,804500000,1000000000,23242400000],
[6837,341850,804500000,1000000000,23245800000],
[6838,341900,804500000,1000000000,23249200000],
[6839,341950,804500000,1000000000,23252600000],
[6840,342000,805000000,1000000000,23256000000],
[6841,342050,805000000,1000000000,23259400000],
[6842,342100,805000000,1000000000,23262800000],
[6843,342150,805500000,1000000000,23266200000],
[6844,342200,805500000,1000000000,23269600000],
[6845,342250,805500000,1000000000,23273000000],
[6846,342300,806000000,1000000000,23276400000],
[6847,342350,806000000,1000000000,23279800000],
[6848,342400,806000000,1000000000,23283200000],
[6849,342450,806000000,1000000000,23286600000],
[6850,342500,806500000,1000000000,23290000000],
[6851,342550,806500000,1000000000,23293400000],
[6852,342600,806500000,1000000000,23296800000],
[6853,342650,807000000,1000000000,23300200000],
[6854,342700,807000000,1000000000,23303600000],
[6855,342750,807000000,1000000000,23307000000],
[6856,342800,807500000,1000000000,23310400000],
[6857,342850,807500000,1000000000,23313800000],
[6858,342900,807500000,1000000000,23317200000],
[6859,342950,808000000,1000000000,23320600000],
[6860,343000,808000000,1000000000,23324000000],
[6861,343050,808000000,1000000000,23327400000],
[6862,343100,808000000,1000000000,23330800000],
[6863,343150,808500000,1000000000,23334200000],
[6864,343200,808500000,1000000000,23337600000],
[6865,343250,808500000,1000000000,23341000000],
[6866,343300,809000000,1000000000,23344400000],
[6867,343350,809000000,1000000000,23347800000],
[6868,343400,809000000,1000000000,23351200000],
[6869,343450,809500000,1000000000,23354600000],
[6870,343500,809500000,1000000000,23358000000],
[6871,343550,809500000,1000000000,23361400000],
[6872,343600,809500000,1000000000,23364800000],
[6873,343650,810000000,1000000000,23368200000],
[6874,343700,810000000,1000000000,23371600000],
[6875,343750,810000000,1000000000,23375000000],
[6876,343800,810500000,1000000000,23378400000],
[6877,343850,810500000,1000000000,23381800000],
[6878,343900,810500000,1000000000,23385200000],
[6879,343950,811000000,1000000000,23388600000],
[6880,344000,811000000,1000000000,23392000000],
[6881,344050,811000000,1000000000,23395400000],
[6882,344100,811000000,1000000000,23398800000],
[6883,344150,811500000,1000000000,23402200000],
[6884,344200,811500000,1000000000,23405600000],
[6885,344250,811500000,1000000000,23409000000],
[6886,344300,812000000,1000000000,23412400000],
[6887,344350,812000000,1000000000,23415800000],
[6888,344400,812000000,1000000000,23419200000],
[6889,344450,812500000,1000000000,23422600000],
[6890,344500,812500000,1000000000,23426000000],
[6891,344550,812500000,1000000000,23429400000],
[6892,344600,813000000,1000000000,23432800000],
[6893,344650,813000000,1000000000,23436200000],
[6894,344700,813000000,1000000000,23439600000],
[6895,344750,813000000,1000000000,23443000000],
[6896,344800,813500000,1000000000,23446400000],
[6897,344850,813500000,1000000000,23449800000],
[6898,344900,813500000,1000000000,23453200000],
[6899,344950,814000000,1000000000,23456600000],
[6900,345000,814000000,1000000000,23460000000],
[6901,345050,814000000,1000000000,23463400000],
[6902,345100,814500000,1000000000,23466800000],
[6903,345150,814500000,1000000000,23470200000],
[6904,345200,814500000,1000000000,23473600000],
[6905,345250,814500000,1000000000,23477000000],
[6906,345300,815000000,1000000000,23480400000],
[6907,345350,815000000,1000000000,23483800000],
[6908,345400,815000000,1000000000,23487200000],
[6909,345450,815500000,1000000000,23490600000],
[6910,345500,815500000,1000000000,23494000000],
[6911,345550,815500000,1000000000,23497400000],
[6912,345600,816000000,1000000000,23500800000],
[6913,345650,816000000,1000000000,23504200000],
[6914,345700,816000000,1000000000,23507600000],
[6915,345750,816000000,1000000000,23511000000],
[6916,345800,816500000,1000000000,23514400000],
[6917,345850,816500000,1000000000,23517800000],
[6918,345900,816500000,1000000000,23521200000],
[6919,345950,817000000,1000000000,23524600000],
[6920,346000,817000000,1000000000,23528000000],
[6921,346050,817000000,1000000000,23531400000],
[6922,346100,817500000,1000000000,23534800000],
[6923,346150,817500000,1000000000,23538200000],
[6924,346200,817500000,1000000000,23541600000],
[6925,346250,818000000,1000000000,23545000000],
[6926,346300,818000000,1000000000,23548400000],
[6927,346350,818000000,1000000000,23551800000],
[6928,346400,818000000,1000000000,23555200000],
[6929,346450,818500000,1000000000,23558600000],
[6930,346500,818500000,1000000000,23562000000],
[6931,346550,818500000,1000000000,23565400000],
[6932,346600,819000000,1000000000,23568800000],
[6933,346650,819000000,1000000000,23572200000],
[6934,346700,819000000,1000000000,23575600000],
[6935,346750,819500000,1000000000,23579000000],
[6936,346800,819500000,1000000000,23582400000],
[6937,346850,819500000,1000000000,23585800000],
[6938,346900,819500000,1000000000,23589200000],
[6939,346950,820000000,1000000000,23592600000],
[6940,347000,820000000,1000000000,23596000000],
[6941,347050,820000000,1000000000,23599400000],
[6942,347100,820500000,1000000000,23602800000],
[6943,347150,820500000,1000000000,23606200000],
[6944,347200,820500000,1000000000,23609600000],
[6945,347250,821000000,1000000000,23613000000],
[6946,347300,821000000,1000000000,23616400000],
[6947,347350,821000000,1000000000,23619800000],
[6948,347400,821000000,1000000000,23623200000],
[6949,347450,821500000,1000000000,23626600000],
[6950,347500,821500000,1000000000,23630000000],
[6951,347550,821500000,1000000000,23633400000],
[6952,347600,822000000,1000000000,23636800000],
[6953,347650,822000000,1000000000,23640200000],
[6954,347700,822000000,1000000000,23643600000],
[6955,347750,822500000,1000000000,23647000000],
[6956,347800,822500000,1000000000,23650400000],
[6957,347850,822500000,1000000000,23653800000],
[6958,347900,823000000,1000000000,23657200000],
[6959,347950,823000000,1000000000,23660600000],
[6960,348000,823000000,1000000000,23664000000],
[6961,348050,823000000,1000000000,23667400000],
[6962,348100,823500000,1000000000,23670800000],
[6963,348150,823500000,1000000000,23674200000],
[6964,348200,823500000,1000000000,23677600000],
[6965,348250,824000000,1000000000,23681000000],
[6966,348300,824000000,1000000000,23684400000],
[6967,348350,824000000,1000000000,23687800000],
[6968,348400,824500000,1000000000,23691200000],
[6969,348450,824500000,1000000000,23694600000],
[6970,348500,824500000,1000000000,23698000000],
[6971,348550,824500000,1000000000,23701400000],
[6972,348600,825000000,1000000000,23704800000],
[6973,348650,825000000,1000000000,23708200000],
[6974,348700,825000000,1000000000,23711600000],
[6975,348750,825500000,1000000000,23715000000],
[6976,348800,825500000,1000000000,23718400000],
[6977,348850,825500000,1000000000,23721800000],
[6978,348900,826000000,1000000000,23725200000],
[6979,348950,826000000,1000000000,23728600000],
[6980,349000,826000000,1000000000,23732000000],
[6981,349050,826000000,1000000000,23735400000],
[6982,349100,826500000,1000000000,23738800000],
[6983,349150,826500000,1000000000,23742200000],
[6984,349200,826500000,1000000000,23745600000],
[6985,349250,827000000,1000000000,23749000000],
[6986,349300,827000000,1000000000,23752400000],
[6987,349350,827000000,1000000000,23755800000],
[6988,349400,827500000,1000000000,23759200000],
[6989,349450,827500000,1000000000,23762600000],
[6990,349500,827500000,1000000000,23766000000],
[6991,349550,828000000,1000000000,23769400000],
[6992,349600,828000000,1000000000,23772800000],
[6993,349650,828000000,1000000000,23776200000],
[6994,349700,828000000,1000000000,23779600000],
[6995,349750,828500000,1000000000,23783000000],
[6996,349800,828500000,1000000000,23786400000],
[6997,349850,828500000,1000000000,23789800000],
[6998,349900,829000000,1000000000,23793200000],
[6999,349950,829000000,1000000000,23796600000],
[7000,350000,829000000,1000000000,23800000000],
[7001,350050,829500000,1000000000,23803400000],
[7002,350100,829500000,1000000000,23806800000],
[7003,350150,829500000,1000000000,23810200000],
[7004,350200,829500000,1000000000,23813600000],
[7005,350250,830000000,1000000000,23817000000],
[7006,350300,830000000,1000000000,23820400000],
[7007,350350,830000000,1000000000,23823800000],
[7008,350400,830500000,1000000000,23827200000],
[7009,350450,830500000,1000000000,23830600000],
[7010,350500,830500000,1000000000,23834000000],
[7011,350550,831000000,1000000000,23837400000],
[7012,350600,831000000,1000000000,23840800000],
[7013,350650,831000000,1000000000,23844200000],
[7014,350700,831000000,1000000000,23847600000],
[7015,350750,831500000,1000000000,23851000000],
[7016,350800,831500000,1000000000,23854400000],
[7017,350850,831500000,1000000000,23857800000],
[7018,350900,832000000,1000000000,23861200000],
[7019,350950,832000000,1000000000,23864600000],
[7020,351000,832000000,1000000000,23868000000],
[7021,351050,832500000,1000000000,23871400000],
[7022,351100,832500000,1000000000,23874800000],
[7023,351150,832500000,1000000000,23878200000],
[7024,351200,833000000,1000000000,23881600000],
[7025,351250,833000000,1000000000,23885000000],
[7026,351300,833000000,1000000000,23888400000],
[7027,351350,833000000,1000000000,23891800000],
[7028,351400,833500000,1000000000,23895200000],
[7029,351450,833500000,1000000000,23898600000],
[7030,351500,833500000,1000000000,23902000000],
[7031,351550,834000000,1000000000,23905400000],
[7032,351600,834000000,1000000000,23908800000],
[7033,351650,834000000,1000000000,23912200000],
[7034,351700,834500000,1000000000,23915600000],
[7035,351750,834500000,1000000000,23919000000],
[7036,351800,834500000,1000000000,23922400000],
[7037,351850,834500000,1000000000,23925800000],
[7038,351900,835000000,1000000000,23929200000],
[7039,351950,835000000,1000000000,23932600000],
[7040,352000,835000000,1000000000,23936000000],
[7041,352050,835500000,1000000000,23939400000],
[7042,352100,835500000,1000000000,23942800000],
[7043,352150,835500000,1000000000,23946200000],
[7044,352200,836000000,1000000000,23949600000],
[7045,352250,836000000,1000000000,23953000000],
[7046,352300,836000000,1000000000,23956400000],
[7047,352350,836000000,1000000000,23959800000],
[7048,352400,836500000,1000000000,23963200000],
[7049,352450,836500000,1000000000,23966600000],
[7050,352500,836500000,1000000000,23970000000],
[7051,352550,837000000,1000000000,23973400000],
[7052,352600,837000000,1000000000,23976800000],
[7053,352650,837000000,1000000000,23980200000],
[7054,352700,837500000,1000000000,23983600000],
[7055,352750,837500000,1000000000,23987000000],
[7056,352800,837500000,1000000000,23990400000],
[7057,352850,838000000,1000000000,23993800000],
[7058,352900,838000000,1000000000,23997200000],
[7059,352950,838000000,1000000000,24000600000],
[7060,353000,838000000,1000000000,24004000000],
[7061,353050,838500000,1000000000,24007400000],
[7062,353100,838500000,1000000000,24010800000],
[7063,353150,838500000,1000000000,24014200000],
[7064,353200,839000000,1000000000,24017600000],
[7065,353250,839000000,1000000000,24021000000],
[7066,353300,839000000,1000000000,24024400000],
[7067,353350,839500000,1000000000,24027800000],
[7068,353400,839500000,1000000000,24031200000],
[7069,353450,839500000,1000000000,24034600000],
[7070,353500,839500000,1000000000,24038000000],
[7071,353550,840000000,1000000000,24041400000],
[7072,353600,840000000,1000000000,24044800000],
[7073,353650,840000000,1000000000,24048200000],
[7074,353700,840500000,1000000000,24051600000],
[7075,353750,840500000,1000000000,24055000000],
[7076,353800,840500000,1000000000,24058400000],
[7077,353850,841000000,1000000000,24061800000],
[7078,353900,841000000,1000000000,24065200000],
[7079,353950,841000000,1000000000,24068600000],
[7080,354000,841000000,1000000000,24072000000],
[7081,354050,841500000,1000000000,24075400000],
[7082,354100,841500000,1000000000,24078800000],
[7083,354150,841500000,1000000000,24082200000],
[7084,354200,842000000,1000000000,24085600000],
[7085,354250,842000000,1000000000,24089000000],
[7086,354300,842000000,1000000000,24092400000],
[7087,354350,842500000,1000000000,24095800000],
[7088,354400,842500000,1000000000,24099200000],
[7089,354450,842500000,1000000000,24102600000],
[7090,354500,843000000,1000000000,24106000000],
[7091,354550,843000000,1000000000,24109400000],
[7092,354600,843000000,1000000000,24112800000],
[7093,354650,843000000,1000000000,24116200000],
[7094,354700,843500000,1000000000,24119600000],
[7095,354750,843500000,1000000000,24123000000],
[7096,354800,843500000,1000000000,24126400000],
[7097,354850,844000000,1000000000,24129800000],
[7098,354900,844000000,1000000000,24133200000],
[7099,354950,844000000,1000000000,24136600000],
[7100,355000,844500000,1000000000,24140000000],
[7101,355050,844500000,1000000000,24143400000],
[7102,355100,844500000,1000000000,24146800000],
[7103,355150,844500000,1000000000,24150200000],
[7104,355200,845000000,1000000000,24153600000],
[7105,355250,845000000,1000000000,24157000000],
[7106,355300,845000000,1000000000,24160400000],
[7107,355350,845500000,1000000000,24163800000],
[7108,355400,845500000,1000000000,24167200000],
[7109,355450,845500000,1000000000,24170600000],
[7110,355500,846000000,1000000000,24174000000],
[7111,355550,846000000,1000000000,24177400000],
[7112,355600,846000000,1000000000,24180800000],
[7113,355650,846000000,1000000000,24184200000],
[7114,355700,846500000,1000000000,24187600000],
[7115,355750,846500000,1000000000,24191000000],
[7116,355800,846500000,1000000000,24194400000],
[7117,355850,847000000,1000000000,24197800000],
[7118,355900,847000000,1000000000,24201200000],
[7119,355950,847000000,1000000000,24204600000],
[7120,356000,847500000,1000000000,24208000000],
[7121,356050,847500000,1000000000,24211400000],
[7122,356100,847500000,1000000000,24214800000],
[7123,356150,848000000,1000000000,24218200000],
[7124,356200,848000000,1000000000,24221600000],
[7125,356250,848000000,1000000000,24225000000],
[7126,356300,848000000,1000000000,24228400000],
[7127,356350,848500000,1000000000,24231800000],
[7128,356400,848500000,1000000000,24235200000],
[7129,356450,848500000,1000000000,24238600000],
[7130,356500,849000000,1000000000,24242000000],
[7131,356550,849000000,1000000000,24245400000],
[7132,356600,849000000,1000000000,24248800000],
[7133,356650,849500000,1000000000,24252200000],
[7134,356700,849500000,1000000000,24255600000],
[7135,356750,849500000,1000000000,24259000000],
[7136,356800,849500000,1000000000,24262400000],
[7137,356850,850000000,1000000000,24265800000],
[7138,356900,850000000,1000000000,24269200000],
[7139,356950,850000000,1000000000,24272600000],
[7140,357000,850500000,1000000000,24276000000],
[7141,357050,850500000,1000000000,24279400000],
[7142,357100,850500000,1000000000,24282800000],
[7143,357150,851000000,1000000000,24286200000],
[7144,357200,851000000,1000000000,24289600000],
[7145,357250,851000000,1000000000,24293000000],
[7146,357300,851000000,1000000000,24296400000],
[7147,357350,851500000,1000000000,24299800000],
[7148,357400,851500000,1000000000,24303200000],
[7149,357450,851500000,1000000000,24306600000],
[7150,357500,852000000,1000000000,24310000000],
[7151,357550,852000000,1000000000,24313400000],
[7152,357600,852000000,1000000000,24316800000],
[7153,357650,852500000,1000000000,24320200000],
[7154,357700,852500000,1000000000,24323600000],
[7155,357750,852500000,1000000000,24327000000],
[7156,357800,853000000,1000000000,24330400000],
[7157,357850,853000000,1000000000,24333800000],
[7158,357900,853000000,1000000000,24337200000],
[7159,357950,853000000,1000000000,24340600000],
[7160,358000,853500000,1000000000,24344000000],
[7161,358050,853500000,1000000000,24347400000],
[7162,358100,853500000,1000000000,24350800000],
[7163,358150,854000000,1000000000,24354200000],
[7164,358200,854000000,1000000000,24357600000],
[7165,358250,854000000,1000000000,24361000000],
[7166,358300,854500000,1000000000,24364400000],
[7167,358350,854500000,1000000000,24367800000],
[7168,358400,854500000,1000000000,24371200000],
[7169,358450,854500000,1000000000,24374600000],
[7170,358500,855000000,1000000000,24378000000],
[7171,358550,855000000,1000000000,24381400000],
[7172,358600,855000000,1000000000,24384800000],
[7173,358650,855500000,1000000000,24388200000],
[7174,358700,855500000,1000000000,24391600000],
[7175,358750,855500000,1000000000,24395000000],
[7176,358800,856000000,1000000000,24398400000],
[7177,358850,856000000,1000000000,24401800000],
[7178,358900,856000000,1000000000,24405200000],
[7179,358950,856000000,1000000000,24408600000],
[7180,359000,856500000,1000000000,24412000000],
[7181,359050,856500000,1000000000,24415400000],
[7182,359100,856500000,1000000000,24418800000],
[7183,359150,857000000,1000000000,24422200000],
[7184,359200,857000000,1000000000,24425600000],
[7185,359250,857000000,1000000000,24429000000],
[7186,359300,857500000,1000000000,24432400000],
[7187,359350,857500000,1000000000,24435800000],
[7188,359400,857500000,1000000000,24439200000],
[7189,359450,858000000,1000000000,24442600000],
[7190,359500,858000000,1000000000,24446000000],
[7191,359550,858000000,1000000000,24449400000],
[7192,359600,858000000,1000000000,24452800000],
[7193,359650,858500000,1000000000,24456200000],
[7194,359700,858500000,1000000000,24459600000],
[7195,359750,858500000,1000000000,24463000000],
[7196,359800,859000000,1000000000,24466400000],
[7197,359850,859000000,1000000000,24469800000],
[7198,359900,859000000,1000000000,24473200000],
[7199,359950,859500000,1000000000,24476600000],
[7200,360000,859500000,1000000000,24480000000],
[7201,360050,859500000,1000000000,24483400000],
[7202,360100,859500000,1000000000,24486800000],
[7203,360150,860000000,1000000000,24490200000],
[7204,360200,860000000,1000000000,24493600000],
[7205,360250,860000000,1000000000,24497000000],
[7206,360300,860500000,1000000000,24500400000],
[7207,360350,860500000,1000000000,24503800000],
[7208,360400,860500000,1000000000,24507200000],
[7209,360450,861000000,1000000000,24510600000],
[7210,360500,861000000,1000000000,24514000000],
[7211,360550,861000000,1000000000,24517400000],
[7212,360600,861000000,1000000000,24520800000],
[7213,360650,861500000,1000000000,24524200000],
[7214,360700,861500000,1000000000,24527600000],
[7215,360750,861500000,1000000000,24531000000],
[7216,360800,862000000,1000000000,24534400000],
[7217,360850,862000000,1000000000,24537800000],
[7218,360900,862000000,1000000000,24541200000],
[7219,360950,862500000,1000000000,24544600000],
[7220,361000,862500000,1000000000,24548000000],
[7221,361050,862500000,1000000000,24551400000],
[7222,361100,863000000,1000000000,24554800000],
[7223,361150,863000000,1000000000,24558200000],
[7224,361200,863000000,1000000000,24561600000],
[7225,361250,863000000,1000000000,24565000000],
[7226,361300,863500000,1000000000,24568400000],
[7227,361350,863500000,1000000000,24571800000],
[7228,361400,863500000,1000000000,24575200000],
[7229,361450,864000000,1000000000,24578600000],
[7230,361500,864000000,1000000000,24582000000],
[7231,361550,864000000,1000000000,24585400000],
[7232,361600,864500000,1000000000,24588800000],
[7233,361650,864500000,1000000000,24592200000],
[7234,361700,864500000,1000000000,24595600000],
[7235,361750,864500000,1000000000,24599000000],
[7236,361800,865000000,1000000000,24602400000],
[7237,361850,865000000,1000000000,24605800000],
[7238,361900,865000000,1000000000,24609200000],
[7239,361950,865500000,1000000000,24612600000],
[7240,362000,865500000,1000000000,24616000000],
[7241,362050,865500000,1000000000,24619400000],
[7242,362100,866000000,1000000000,24622800000],
[7243,362150,866000000,1000000000,24626200000],
[7244,362200,866000000,1000000000,24629600000],
[7245,362250,866000000,1000000000,24633000000],
[7246,362300,866500000,1000000000,24636400000],
[7247,362350,866500000,1000000000,24639800000],
[7248,362400,866500000,1000000000,24643200000],
[7249,362450,867000000,1000000000,24646600000],
[7250,362500,867000000,1000000000,24650000000],
[7251,362550,867000000,1000000000,24653400000],
[7252,362600,867500000,1000000000,24656800000],
[7253,362650,867500000,1000000000,24660200000],
[7254,362700,867500000,1000000000,24663600000],
[7255,362750,868000000,1000000000,24667000000],
[7256,362800,868000000,1000000000,24670400000],
[7257,362850,868000000,1000000000,24673800000],
[7258,362900,868000000,1000000000,24677200000],
[7259,362950,868500000,1000000000,24680600000],
[7260,363000,868500000,1000000000,24684000000],
[7261,363050,868500000,1000000000,24687400000],
[7262,363100,869000000,1000000000,24690800000],
[7263,363150,869000000,1000000000,24694200000],
[7264,363200,869000000,1000000000,24697600000],
[7265,363250,869500000,1000000000,24701000000],
[7266,363300,869500000,1000000000,24704400000],
[7267,363350,869500000,1000000000,24707800000],
[7268,363400,869500000,1000000000,24711200000],
[7269,363450,870000000,1000000000,24714600000],
[7270,363500,870000000,1000000000,24718000000],
[7271,363550,870000000,1000000000,24721400000],
[7272,363600,870500000,1000000000,24724800000],
[7273,363650,870500000,1000000000,24728200000],
[7274,363700,870500000,1000000000,24731600000],
[7275,363750,871000000,1000000000,24735000000],
[7276,363800,871000000,1000000000,24738400000],
[7277,363850,871000000,1000000000,24741800000],
[7278,363900,871000000,1000000000,24745200000],
[7279,363950,871500000,1000000000,24748600000],
[7280,364000,871500000,1000000000,24752000000],
[7281,364050,871500000,1000000000,24755400000],
[7282,364100,872000000,1000000000,24758800000],
[7283,364150,872000000,1000000000,24762200000],
[7284,364200,872000000,1000000000,24765600000],
[7285,364250,872500000,1000000000,24769000000],
[7286,364300,872500000,1000000000,24772400000],
[7287,364350,872500000,1000000000,24775800000],
[7288,364400,873000000,1000000000,24779200000],
[7289,364450,873000000,1000000000,24782600000],
[7290,364500,873000000,1000000000,24786000000],
[7291,364550,873000000,1000000000,24789400000],
[7292,364600,873500000,1000000000,24792800000],
[7293,364650,873500000,1000000000,24796200000],
[7294,364700,873500000,1000000000,24799600000],
[7295,364750,874000000,1000000000,24803000000],
[7296,364800,874000000,1000000000,24806400000],
[7297,364850,874000000,1000000000,24809800000],
[7298,364900,874500000,1000000000,24813200000],
[7299,364950,874500000,1000000000,24816600000],
[7300,365000,874500000,1000000000,24820000000],
[7301,365050,874500000,1000000000,24823400000],
[7302,365100,875000000,1000000000,24826800000],
[7303,365150,875000000,1000000000,24830200000],
[7304,365200,875000000,1000000000,24833600000],
[7305,365250,875500000,1000000000,24837000000],
[7306,365300,875500000,1000000000,24840400000],
[7307,365350,875500000,1000000000,24843800000],
[7308,365400,876000000,1000000000,24847200000],
[7309,365450,876000000,1000000000,24850600000],
[7310,365500,876000000,1000000000,24854000000],
[7311,365550,876000000,1000000000,24857400000],
[7312,365600,876500000,1000000000,24860800000],
[7313,365650,876500000,1000000000,24864200000],
[7314,365700,876500000,1000000000,24867600000],
[7315,365750,877000000,1000000000,24871000000],
[7316,365800,877000000,1000000000,24874400000],
[7317,365850,877000000,1000000000,24877800000],
[7318,365900,877500000,1000000000,24881200000],
[7319,365950,877500000,1000000000,24884600000],
[7320,366000,877500000,1000000000,24888000000],
[7321,366050,878000000,1000000000,24891400000],
[7322,366100,878000000,1000000000,24894800000],
[7323,366150,878000000,1000000000,24898200000],
[7324,366200,878000000,1000000000,24901600000],
[7325,366250,878500000,1000000000,24905000000],
[7326,366300,878500000,1000000000,24908400000],
[7327,366350,878500000,1000000000,24911800000],
[7328,366400,879000000,1000000000,24915200000],
[7329,366450,879000000,1000000000,24918600000],
[7330,366500,879000000,1000000000,24922000000],
[7331,366550,879500000,1000000000,24925400000],
[7332,366600,879500000,1000000000,24928800000],
[7333,366650,879500000,1000000000,24932200000],
[7334,366700,879500000,1000000000,24935600000],
[7335,366750,880000000,1000000000,24939000000],
[7336,366800,880000000,1000000000,24942400000],
[7337,366850,880000000,1000000000,24945800000],
[7338,366900,880500000,1000000000,24949200000],
[7339,366950,880500000,1000000000,24952600000],
[7340,367000,880500000,1000000000,24956000000],
[7341,367050,881000000,1000000000,24959400000],
[7342,367100,881000000,1000000000,24962800000],
[7343,367150,881000000,1000000000,24966200000],
[7344,367200,881000000,1000000000,24969600000],
[7345,367250,881500000,1000000000,24973000000],
[7346,367300,881500000,1000000000,24976400000],
[7347,367350,881500000,1000000000,24979800000],
[7348,367400,882000000,1000000000,24983200000],
[7349,367450,882000000,1000000000,24986600000],
[7350,367500,882000000,1000000000,24990000000],
[7351,367550,882500000,1000000000,24993400000],
[7352,367600,882500000,1000000000,24996800000],
[7353,367650,882500000,1000000000,25000200000],
[7354,367700,883000000,1000000000,25003600000],
[7355,367750,883000000,1000000000,25007000000],
[7356,367800,883000000,1000000000,25010400000],
[7357,367850,883000000,1000000000,25013800000],
[7358,367900,883500000,1000000000,25017200000],
[7359,367950,883500000,1000000000,25020600000],
[7360,368000,883500000,1000000000,25024000000],
[7361,368050,884000000,1000000000,25027400000],
[7362,368100,884000000,1000000000,25030800000],
[7363,368150,884000000,1000000000,25034200000],
[7364,368200,884500000,1000000000,25037600000],
[7365,368250,884500000,1000000000,25041000000],
[7366,368300,884500000,1000000000,25044400000],
[7367,368350,884500000,1000000000,25047800000],
[7368,368400,885000000,1000000000,25051200000],
[7369,368450,885000000,1000000000,25054600000],
[7370,368500,885000000,1000000000,25058000000],
[7371,368550,885500000,1000000000,25061400000],
[7372,368600,885500000,1000000000,25064800000],
[7373,368650,885500000,1000000000,25068200000],
[7374,368700,886000000,1000000000,25071600000],
[7375,368750,886000000,1000000000,25075000000],
[7376,368800,886000000,1000000000,25078400000],
[7377,368850,886000000,1000000000,25081800000],
[7378,368900,886500000,1000000000,25085200000],
[7379,368950,886500000,1000000000,25088600000],
[7380,369000,886500000,1000000000,25092000000],
[7381,369050,887000000,1000000000,25095400000],
[7382,369100,887000000,1000000000,25098800000],
[7383,369150,887000000,1000000000,25102200000],
[7384,369200,887500000,1000000000,25105600000],
[7385,369250,887500000,1000000000,25109000000],
[7386,369300,887500000,1000000000,25112400000],
[7387,369350,888000000,1000000000,25115800000],
[7388,369400,888000000,1000000000,25119200000],
[7389,369450,888000000,1000000000,25122600000],
[7390,369500,888000000,1000000000,25126000000],
[7391,369550,888500000,1000000000,25129400000],
[7392,369600,888500000,1000000000,25132800000],
[7393,369650,888500000,1000000000,25136200000],
[7394,369700,889000000,1000000000,25139600000],
[7395,369750,889000000,1000000000,25143000000],
[7396,369800,889000000,1000000000,25146400000],
[7397,369850,889500000,1000000000,25149800000],
[7398,369900,889500000,1000000000,25153200000],
[7399,369950,889500000,1000000000,25156600000],
[7400,370000,889500000,1000000000,25160000000],
[7401,370050,890000000,1000000000,25163400000],
[7402,370100,890000000,1000000000,25166800000],
[7403,370150,890000000,1000000000,25170200000],
[7404,370200,890500000,1000000000,25173600000],
[7405,370250,890500000,1000000000,25177000000],
[7406,370300,890500000,1000000000,25180400000],
[7407,370350,891000000,1000000000,25183800000],
[7408,370400,891000000,1000000000,25187200000],
[7409,370450,891000000,1000000000,25190600000],
[7410,370500,891000000,1000000000,25194000000],
[7411,370550,891500000,1000000000,25197400000],
[7412,370600,891500000,1000000000,25200800000],
[7413,370650,891500000,1000000000,25204200000],
[7414,370700,892000000,1000000000,25207600000],
[7415,370750,892000000,1000000000,25211000000],
[7416,370800,892000000,1000000000,25214400000],
[7417,370850,892500000,1000000000,25217800000],
[7418,370900,892500000,1000000000,25221200000],
[7419,370950,892500000,1000000000,25224600000],
[7420,371000,893000000,1000000000,25228000000],
[7421,371050,893000000,1000000000,25231400000],
[7422,371100,893000000,1000000000,25234800000],
[7423,371150,893000000,1000000000,25238200000],
[7424,371200,893500000,1000000000,25241600000],
[7425,371250,893500000,1000000000,25245000000],
[7426,371300,893500000,1000000000,25248400000],
[7427,371350,894000000,1000000000,25251800000],
[7428,371400,894000000,1000000000,25255200000],
[7429,371450,894000000,1000000000,25258600000],
[7430,371500,894500000,1000000000,25262000000],
[7431,371550,894500000,1000000000,25265400000],
[7432,371600,894500000,1000000000,25268800000],
[7433,371650,894500000,1000000000,25272200000],
[7434,371700,895000000,1000000000,25275600000],
[7435,371750,895000000,1000000000,25279000000],
[7436,371800,895000000,1000000000,25282400000],
[7437,371850,895500000,1000000000,25285800000],
[7438,371900,895500000,1000000000,25289200000],
[7439,371950,895500000,1000000000,25292600000],
[7440,372000,896000000,1000000000,25296000000],
[7441,372050,896000000,1000000000,25299400000],
[7442,372100,896000000,1000000000,25302800000],
[7443,372150,896000000,1000000000,25306200000],
[7444,372200,896500000,1000000000,25309600000],
[7445,372250,896500000,1000000000,25313000000],
[7446,372300,896500000,1000000000,25316400000],
[7447,372350,897000000,1000000000,25319800000],
[7448,372400,897000000,1000000000,25323200000],
[7449,372450,897000000,1000000000,25326600000],
[7450,372500,897500000,1000000000,25330000000],
[7451,372550,897500000,1000000000,25333400000],
[7452,372600,897500000,1000000000,25336800000],
[7453,372650,898000000,1000000000,25340200000],
[7454,372700,898000000,1000000000,25343600000],
[7455,372750,898000000,1000000000,25347000000],
[7456,372800,898000000,1000000000,25350400000],
[7457,372850,898500000,1000000000,25353800000],
[7458,372900,898500000,1000000000,25357200000],
[7459,372950,898500000,1000000000,25360600000],
[7460,373000,899000000,1000000000,25364000000],
[7461,373050,899000000,1000000000,25367400000],
[7462,373100,899000000,1000000000,25370800000],
[7463,373150,899500000,1000000000,25374200000],
[7464,373200,899500000,1000000000,25377600000],
[7465,373250,899500000,1000000000,25381000000],
[7466,373300,899500000,1000000000,25384400000],
[7467,373350,900000000,1000000000,25387800000],
[7468,373400,900000000,1000000000,25391200000],
[7469,373450,900000000,1000000000,25394600000],
[7470,373500,900500000,1000000000,25398000000],
[7471,373550,900500000,1000000000,25401400000],
[7472,373600,900500000,1000000000,25404800000],
[7473,373650,901000000,1000000000,25408200000],
[7474,373700,901000000,1000000000,25411600000],
[7475,373750,901000000,1000000000,25415000000],
[7476,373800,901000000,1000000000,25418400000],
[7477,373850,901500000,1000000000,25421800000],
[7478,373900,901500000,1000000000,25425200000],
[7479,373950,901500000,1000000000,25428600000],
[7480,374000,902000000,1000000000,25432000000],
[7481,374050,902000000,1000000000,25435400000],
[7482,374100,902000000,1000000000,25438800000],
[7483,374150,902500000,1000000000,25442200000],
[7484,374200,902500000,1000000000,25445600000],
[7485,374250,902500000,1000000000,25449000000],
[7486,374300,903000000,1000000000,25452400000],
[7487,374350,903000000,1000000000,25455800000],
[7488,374400,903000000,1000000000,25459200000],
[7489,374450,903000000,1000000000,25462600000],
[7490,374500,903500000,1000000000,25466000000],
[7491,374550,903500000,1000000000,25469400000],
[7492,374600,903500000,1000000000,25472800000],
[7493,374650,904000000,1000000000,25476200000],
[7494,374700,904000000,1000000000,25479600000],
[7495,374750,904000000,1000000000,25483000000],
[7496,374800,904500000,1000000000,25486400000],
[7497,374850,904500000,1000000000,25489800000],
[7498,374900,904500000,1000000000,25493200000],
[7499,374950,904500000,1000000000,25496600000],
[7500,375000,905000000,1000000000,25500000000],
[7501,375050,905000000,1000000000,25503400000],
[7502,375100,905000000,1000000000,25506800000],
[7503,375150,905500000,1000000000,25510200000],
[7504,375200,905500000,1000000000,25513600000],
[7505,375250,905500000,1000000000,25517000000],
[7506,375300,906000000,1000000000,25520400000],
[7507,375350,906000000,1000000000,25523800000],
[7508,375400,906000000,1000000000,25527200000],
[7509,375450,906000000,1000000000,25530600000],
[7510,375500,906500000,1000000000,25534000000],
[7511,375550,906500000,1000000000,25537400000],
[7512,375600,906500000,1000000000,25540800000],
[7513,375650,907000000,1000000000,25544200000],
[7514,375700,907000000,1000000000,25547600000],
[7515,375750,907000000,1000000000,25551000000],
[7516,375800,907500000,1000000000,25554400000],
[7517,375850,907500000,1000000000,25557800000],
[7518,375900,907500000,1000000000,25561200000],
[7519,375950,908000000,1000000000,25564600000],
[7520,376000,908000000,1000000000,25568000000],
[7521,376050,908000000,1000000000,25571400000],
[7522,376100,908000000,1000000000,25574800000],
[7523,376150,908500000,1000000000,25578200000],
[7524,376200,908500000,1000000000,25581600000],
[7525,376250,908500000,1000000000,25585000000],
[7526,376300,909000000,1000000000,25588400000],
[7527,376350,909000000,1000000000,25591800000],
[7528,376400,909000000,1000000000,25595200000],
[7529,376450,909500000,1000000000,25598600000],
[7530,376500,909500000,1000000000,25602000000],
[7531,376550,909500000,1000000000,25605400000],
[7532,376600,909500000,1000000000,25608800000],
[7533,376650,910000000,1000000000,25612200000],
[7534,376700,910000000,1000000000,25615600000],
[7535,376750,910000000,1000000000,25619000000],
[7536,376800,910500000,1000000000,25622400000],
[7537,376850,910500000,1000000000,25625800000],
[7538,376900,910500000,1000000000,25629200000],
[7539,376950,911000000,1000000000,25632600000],
[7540,377000,911000000,1000000000,25636000000],
[7541,377050,911000000,1000000000,25639400000],
[7542,377100,911000000,1000000000,25642800000],
[7543,377150,911500000,1000000000,25646200000],
[7544,377200,911500000,1000000000,25649600000],
[7545,377250,911500000,1000000000,25653000000],
[7546,377300,912000000,1000000000,25656400000],
[7547,377350,912000000,1000000000,25659800000],
[7548,377400,912000000,1000000000,25663200000],
[7549,377450,912500000,1000000000,25666600000],
[7550,377500,912500000,1000000000,25670000000],
[7551,377550,912500000,1000000000,25673400000],
[7552,377600,913000000,1000000000,25676800000],
[7553,377650,913000000,1000000000,25680200000],
[7554,377700,913000000,1000000000,25683600000],
[7555,377750,913000000,1000000000,25687000000],
[7556,377800,913500000,1000000000,25690400000],
[7557,377850,913500000,1000000000,25693800000],
[7558,377900,913500000,1000000000,25697200000],
[7559,377950,914000000,1000000000,25700600000],
[7560,378000,914000000,1000000000,25704000000],
[7561,378050,914000000,1000000000,25707400000],
[7562,378100,914500000,1000000000,25710800000],
[7563,378150,914500000,1000000000,25714200000],
[7564,378200,914500000,1000000000,25717600000],
[7565,378250,914500000,1000000000,25721000000],
[7566,378300,915000000,1000000000,25724400000],
[7567,378350,915000000,1000000000,25727800000],
[7568,378400,915000000,1000000000,25731200000],
[7569,378450,915500000,1000000000,25734600000],
[7570,378500,915500000,1000000000,25738000000],
[7571,378550,915500000,1000000000,25741400000],
[7572,378600,916000000,1000000000,25744800000],
[7573,378650,916000000,1000000000,25748200000],
[7574,378700,916000000,1000000000,25751600000],
[7575,378750,916000000,1000000000,25755000000],
[7576,378800,916500000,1000000000,25758400000],
[7577,378850,916500000,1000000000,25761800000],
[7578,378900,916500000,1000000000,25765200000],
[7579,378950,917000000,1000000000,25768600000],
[7580,379000,917000000,1000000000,25772000000],
[7581,379050,917000000,1000000000,25775400000],
[7582,379100,917500000,1000000000,25778800000],
[7583,379150,917500000,1000000000,25782200000],
[7584,379200,917500000,1000000000,25785600000],
[7585,379250,918000000,1000000000,25789000000],
[7586,379300,918000000,1000000000,25792400000],
[7587,379350,918000000,1000000000,25795800000],
[7588,379400,918000000,1000000000,25799200000],
[7589,379450,918500000,1000000000,25802600000],
[7590,379500,918500000,1000000000,25806000000],
[7591,379550,918500000,1000000000,25809400000],
[7592,379600,919000000,1000000000,25812800000],
[7593,379650,919000000,1000000000,25816200000],
[7594,379700,919000000,1000000000,25819600000],
[7595,379750,919500000,1000000000,25823000000],
[7596,379800,919500000,1000000000,25826400000],
[7597,379850,919500000,1000000000,25829800000],
[7598,379900,919500000,1000000000,25833200000],
[7599,379950,920000000,1000000000,25836600000],
[7600,380000,920000000,1000000000,25840000000],
[7601,380050,920000000,1000000000,25843400000],
[7602,380100,920500000,1000000000,25846800000],
[7603,380150,920500000,1000000000,25850200000],
[7604,380200,920500000,1000000000,25853600000],
[7605,380250,921000000,1000000000,25857000000],
[7606,380300,921000000,1000000000,25860400000],
[7607,380350,921000000,1000000000,25863800000],
[7608,380400,921000000,1000000000,25867200000],
[7609,380450,921500000,1000000000,25870600000],
[7610,380500,921500000,1000000000,25874000000],
[7611,380550,921500000,1000000000,25877400000],
[7612,380600,922000000,1000000000,25880800000],
[7613,380650,922000000,1000000000,25884200000],
[7614,380700,922000000,1000000000,25887600000],
[7615,380750,922500000,1000000000,25891000000],
[7616,380800,922500000,1000000000,25894400000],
[7617,380850,922500000,1000000000,25897800000],
[7618,380900,923000000,1000000000,25901200000],
[7619,380950,923000000,1000000000,25904600000],
[7620,381000,923000000,1000000000,25908000000],
[7621,381050,923000000,1000000000,25911400000],
[7622,381100,923500000,1000000000,25914800000],
[7623,381150,923500000,1000000000,25918200000],
[7624,381200,923500000,1000000000,25921600000],
[7625,381250,924000000,1000000000,25925000000],
[7626,381300,924000000,1000000000,25928400000],
[7627,381350,924000000,1000000000,25931800000],
[7628,381400,924500000,1000000000,25935200000],
[7629,381450,924500000,1000000000,25938600000],
[7630,381500,924500000,1000000000,25942000000],
[7631,381550,924500000,1000000000,25945400000],
[7632,381600,925000000,1000000000,25948800000],
[7633,381650,925000000,1000000000,25952200000],
[7634,381700,925000000,1000000000,25955600000],
[7635,381750,925500000,1000000000,25959000000],
[7636,381800,925500000,1000000000,25962400000],
[7637,381850,925500000,1000000000,25965800000],
[7638,381900,926000000,1000000000,25969200000],
[7639,381950,926000000,1000000000,25972600000],
[7640,382000,926000000,1000000000,25976000000],
[7641,382050,926000000,1000000000,25979400000],
[7642,382100,926500000,1000000000,25982800000],
[7643,382150,926500000,1000000000,25986200000],
[7644,382200,926500000,1000000000,25989600000],
[7645,382250,927000000,1000000000,25993000000],
[7646,382300,927000000,1000000000,25996400000],
[7647,382350,927000000,1000000000,25999800000],
[7648,382400,927500000,1000000000,26003200000],
[7649,382450,927500000,1000000000,26006600000],
[7650,382500,927500000,1000000000,26010000000],
[7651,382550,928000000,1000000000,26013400000],
[7652,382600,928000000,1000000000,26016800000],
[7653,382650,928000000,1000000000,26020200000],
[7654,382700,928000000,1000000000,26023600000],
[7655,382750,928500000,1000000000,26027000000],
[7656,382800,928500000,1000000000,26030400000],
[7657,382850,928500000,1000000000,26033800000],
[7658,382900,929000000,1000000000,26037200000],
[7659,382950,929000000,1000000000,26040600000],
[7660,383000,929000000,1000000000,26044000000],
[7661,383050,929500000,1000000000,26047400000],
[7662,383100,929500000,1000000000,26050800000],
[7663,383150,929500000,1000000000,26054200000],
[7664,383200,929500000,1000000000,26057600000],
[7665,383250,930000000,1000000000,26061000000],
[7666,383300,930000000,1000000000,26064400000],
[7667,383350,930000000,1000000000,26067800000],
[7668,383400,930500000,1000000000,26071200000],
[7669,383450,930500000,1000000000,26074600000],
[7670,383500,930500000,1000000000,26078000000],
[7671,383550,931000000,1000000000,26081400000],
[7672,383600,931000000,1000000000,26084800000],
[7673,383650,931000000,1000000000,26088200000],
[7674,383700,931000000,1000000000,26091600000],
[7675,383750,931500000,1000000000,26095000000],
[7676,383800,931500000,1000000000,26098400000],
[7677,383850,931500000,1000000000,26101800000],
[7678,383900,932000000,1000000000,26105200000],
[7679,383950,932000000,1000000000,26108600000],
[7680,384000,932000000,1000000000,26112000000],
[7681,384050,932500000,1000000000,26115400000],
[7682,384100,932500000,1000000000,26118800000],
[7683,384150,932500000,1000000000,26122200000],
[7684,384200,933000000,1000000000,26125600000],
[7685,384250,933000000,1000000000,26129000000],
[7686,384300,933000000,1000000000,26132400000],
[7687,384350,933000000,1000000000,26135800000],
[7688,384400,933500000,1000000000,26139200000],
[7689,384450,933500000,1000000000,26142600000],
[7690,384500,933500000,1000000000,26146000000],
[7691,384550,934000000,1000000000,26149400000],
[7692,384600,934000000,1000000000,26152800000],
[7693,384650,934000000,1000000000,26156200000],
[7694,384700,934500000,1000000000,26159600000],
[7695,384750,934500000,1000000000,26163000000],
[7696,384800,934500000,1000000000,26166400000],
[7697,384850,934500000,1000000000,26169800000],
[7698,384900,935000000,1000000000,26173200000],
[7699,384950,935000000,1000000000,26176600000],
[7700,385000,935000000,1000000000,26180000000],
[7701,385050,935500000,1000000000,26183400000],
[7702,385100,935500000,1000000000,26186800000],
[7703,385150,935500000,1000000000,26190200000],
[7704,385200,936000000,1000000000,26193600000],
[7705,385250,936000000,1000000000,26197000000],
[7706,385300,936000000,1000000000,26200400000],
[7707,385350,936000000,1000000000,26203800000],
[7708,385400,936500000,1000000000,26207200000],
[7709,385450,936500000,1000000000,26210600000],
[7710,385500,936500000,1000000000,26214000000],
[7711,385550,937000000,1000000000,26217400000],
[7712,385600,937000000,1000000000,26220800000],
[7713,385650,937000000,1000000000,26224200000],
[7714,385700,937500000,1000000000,26227600000],
[7715,385750,937500000,1000000000,26231000000],
[7716,385800,937500000,1000000000,26234400000],
[7717,385850,938000000,1000000000,26237800000],
[7718,385900,938000000,1000000000,26241200000],
[7719,385950,938000000,1000000000,26244600000],
[7720,386000,938000000,1000000000,26248000000],
[7721,386050,938500000,1000000000,26251400000],
[7722,386100,938500000,1000000000,26254800000],
[7723,386150,938500000,1000000000,26258200000],
[7724,386200,939000000,1000000000,26261600000],
[7725,386250,939000000,1000000000,26265000000],
[7726,386300,939000000,1000000000,26268400000],
[7727,386350,939500000,1000000000,26271800000],
[7728,386400,939500000,1000000000,26275200000],
[7729,386450,939500000,1000000000,26278600000],
[7730,386500,939500000,1000000000,26282000000],
[7731,386550,940000000,1000000000,26285400000],
[7732,386600,940000000,1000000000,26288800000],
[7733,386650,940000000,1000000000,26292200000],
[7734,386700,940500000,1000000000,26295600000],
[7735,386750,940500000,1000000000,26299000000],
[7736,386800,940500000,1000000000,26302400000],
[7737,386850,941000000,1000000000,26305800000],
[7738,386900,941000000,1000000000,26309200000],
[7739,386950,941000000,1000000000,26312600000],
[7740,387000,941000000,1000000000,26316000000],
[7741,387050,941500000,1000000000,26319400000],
[7742,387100,941500000,1000000000,26322800000],
[7743,387150,941500000,1000000000,26326200000],
[7744,387200,942000000,1000000000,26329600000],
[7745,387250,942000000,1000000000,26333000000],
[7746,387300,942000000,1000000000,26336400000],
[7747,387350,942500000,1000000000,26339800000],
[7748,387400,942500000,1000000000,26343200000],
[7749,387450,942500000,1000000000,26346600000],
[7750,387500,943000000,1000000000,26350000000],
[7751,387550,943000000,1000000000,26353400000],
[7752,387600,943000000,1000000000,26356800000],
[7753,387650,943000000,1000000000,26360200000],
[7754,387700,943500000,1000000000,26363600000],
[7755,387750,943500000,1000000000,26367000000],
[7756,387800,943500000,1000000000,26370400000],
[7757,387850,944000000,1000000000,26373800000],
[7758,387900,944000000,1000000000,26377200000],
[7759,387950,944000000,1000000000,26380600000],
[7760,388000,944500000,1000000000,26384000000],
[7761,388050,944500000,1000000000,26387400000],
[7762,388100,944500000,1000000000,26390800000],
[7763,388150,944500000,1000000000,26394200000],
[7764,388200,945000000,1000000000,26397600000],
[7765,388250,945000000,1000000000,26401000000],
[7766,388300,945000000,1000000000,26404400000],
[7767,388350,945500000,1000000000,26407800000],
[7768,388400,945500000,1000000000,26411200000],
[7769,388450,945500000,1000000000,26414600000],
[7770,388500,946000000,1000000000,26418000000],
[7771,388550,946000000,1000000000,26421400000],
[7772,388600,946000000,1000000000,26424800000],
[7773,388650,946000000,1000000000,26428200000],
[7774,388700,946500000,1000000000,26431600000],
[7775,388750,946500000,1000000000,26435000000],
[7776,388800,946500000,1000000000,26438400000],
[7777,388850,947000000,1000000000,26441800000],
[7778,388900,947000000,1000000000,26445200000],
[7779,388950,947000000,1000000000,26448600000],
[7780,389000,947500000,1000000000,26452000000],
[7781,389050,947500000,1000000000,26455400000],
[7782,389100,947500000,1000000000,26458800000],
[7783,389150,948000000,1000000000,26462200000],
[7784,389200,948000000,1000000000,26465600000],
[7785,389250,948000000,1000000000,26469000000],
[7786,389300,948000000,1000000000,26472400000],
[7787,389350,948500000,1000000000,26475800000],
[7788,389400,948500000,1000000000,26479200000],
[7789,389450,948500000,1000000000,26482600000],
[7790,389500,949000000,1000000000,26486000000],
[7791,389550,949000000,1000000000,26489400000],
[7792,389600,949000000,1000000000,26492800000],
[7793,389650,949500000,1000000000,26496200000],
[7794,389700,949500000,1000000000,26499600000],
[7795,389750,949500000,1000000000,26503000000],
[7796,389800,949500000,1000000000,26506400000],
[7797,389850,950000000,1000000000,26509800000],
[7798,389900,950000000,1000000000,26513200000],
[7799,389950,950000000,1000000000,26516600000],
[7800,390000,950500000,1000000000,26520000000],
[7801,390050,950500000,1000000000,26523400000],
[7802,390100,950500000,1000000000,26526800000],
[7803,390150,951000000,1000000000,26530200000],
[7804,390200,951000000,1000000000,26533600000],
[7805,390250,951000000,1000000000,26537000000],
[7806,390300,951000000,1000000000,26540400000],
[7807,390350,951500000,1000000000,26543800000],
[7808,390400,951500000,1000000000,26547200000],
[7809,390450,951500000,1000000000,26550600000],
[7810,390500,952000000,1000000000,26554000000],
[7811,390550,952000000,1000000000,26557400000],
[7812,390600,952000000,1000000000,26560800000],
[7813,390650,952500000,1000000000,26564200000],
[7814,390700,952500000,1000000000,26567600000],
[7815,390750,952500000,1000000000,26571000000],
[7816,390800,953000000,1000000000,26574400000],
[7817,390850,953000000,1000000000,26577800000],
[7818,390900,953000000,1000000000,26581200000],
[7819,390950,953000000,1000000000,26584600000],
[7820,391000,953500000,1000000000,26588000000],
[7821,391050,953500000,1000000000,26591400000],
[7822,391100,953500000,1000000000,26594800000],
[7823,391150,954000000,1000000000,26598200000],
[7824,391200,954000000,1000000000,26601600000],
[7825,391250,954000000,1000000000,26605000000],
[7826,391300,954500000,1000000000,26608400000],
[7827,391350,954500000,1000000000,26611800000],
[7828,391400,954500000,1000000000,26615200000],
[7829,391450,954500000,1000000000,26618600000],
[7830,391500,955000000,1000000000,26622000000],
[7831,391550,955000000,1000000000,26625400000],
[7832,391600,955000000,1000000000,26628800000],
[7833,391650,955500000,1000000000,26632200000],
[7834,391700,955500000,1000000000,26635600000],
[7835,391750,955500000,1000000000,26639000000],
[7836,391800,956000000,1000000000,26642400000],
[7837,391850,956000000,1000000000,26645800000],
[7838,391900,956000000,1000000000,26649200000],
[7839,391950,956000000,1000000000,26652600000],
[7840,392000,956500000,1000000000,26656000000],
[7841,392050,956500000,1000000000,26659400000],
[7842,392100,956500000,1000000000,26662800000],
[7843,392150,957000000,1000000000,26666200000],
[7844,392200,957000000,1000000000,26669600000],
[7845,392250,957000000,1000000000,26673000000],
[7846,392300,957500000,1000000000,26676400000],
[7847,392350,957500000,1000000000,26679800000],
[7848,392400,957500000,1000000000,26683200000],
[7849,392450,958000000,1000000000,26686600000],
[7850,392500,958000000,1000000000,26690000000],
[7851,392550,958000000,1000000000,26693400000],
[7852,392600,958000000,1000000000,26696800000],
[7853,392650,958500000,1000000000,26700200000],
[7854,392700,958500000,1000000000,26703600000],
[7855,392750,958500000,1000000000,26707000000],
[7856,392800,959000000,1000000000,26710400000],
[7857,392850,959000000,1000000000,26713800000],
[7858,392900,959000000,1000000000,26717200000],
[7859,392950,959500000,1000000000,26720600000],
[7860,393000,959500000,1000000000,26724000000],
[7861,393050,959500000,1000000000,26727400000],
[7862,393100,959500000,1000000000,26730800000],
[7863,393150,960000000,1000000000,26734200000],
[7864,393200,960000000,1000000000,26737600000],
[7865,393250,960000000,1000000000,26741000000],
[7866,393300,960500000,1000000000,26744400000],
[7867,393350,960500000,1000000000,26747800000],
[7868,393400,960500000,1000000000,26751200000],
[7869,393450,961000000,1000000000,26754600000],
[7870,393500,961000000,1000000000,26758000000],
[7871,393550,961000000,1000000000,26761400000],
[7872,393600,961000000,1000000000,26764800000],
[7873,393650,961500000,1000000000,26768200000],
[7874,393700,961500000,1000000000,26771600000],
[7875,393750,961500000,1000000000,26775000000],
[7876,393800,962000000,1000000000,26778400000],
[7877,393850,962000000,1000000000,26781800000],
[7878,393900,962000000,1000000000,26785200000],
[7879,393950,962500000,1000000000,26788600000],
[7880,394000,962500000,1000000000,26792000000],
[7881,394050,962500000,1000000000,26795400000],
[7882,394100,963000000,1000000000,26798800000],
[7883,394150,963000000,1000000000,26802200000],
[7884,394200,963000000,1000000000,26805600000],
[7885,394250,963000000,1000000000,26809000000],
[7886,394300,963500000,1000000000,26812400000],
[7887,394350,963500000,1000000000,26815800000],
[7888,394400,963500000,1000000000,26819200000],
[7889,394450,964000000,1000000000,26822600000],
[7890,394500,964000000,1000000000,26826000000],
[7891,394550,964000000,1000000000,26829400000],
[7892,394600,964500000,1000000000,26832800000],
[7893,394650,964500000,1000000000,26836200000],
[7894,394700,964500000,1000000000,26839600000],
[7895,394750,964500000,1000000000,26843000000],
[7896,394800,965000000,1000000000,26846400000],
[7897,394850,965000000,1000000000,26849800000],
[7898,394900,965000000,1000000000,26853200000],
[7899,394950,965500000,1000000000,26856600000],
[7900,395000,965500000,1000000000,26860000000],
[7901,395050,965500000,1000000000,26863400000],
[7902,395100,966000000,1000000000,26866800000],
[7903,395150,966000000,1000000000,26870200000],
[7904,395200,966000000,1000000000,26873600000],
[7905,395250,966000000,1000000000,26877000000],
[7906,395300,966500000,1000000000,26880400000],
[7907,395350,966500000,1000000000,26883800000],
[7908,395400,966500000,1000000000,26887200000],
[7909,395450,967000000,1000000000,26890600000],
[7910,395500,967000000,1000000000,26894000000],
[7911,395550,967000000,1000000000,26897400000],
[7912,395600,967500000,1000000000,26900800000],
[7913,395650,967500000,1000000000,26904200000],
[7914,395700,967500000,1000000000,26907600000],
[7915,395750,968000000,1000000000,26911000000],
[7916,395800,968000000,1000000000,26914400000],
[7917,395850,968000000,1000000000,26917800000],
[7918,395900,968000000,1000000000,26921200000],
[7919,395950,968500000,1000000000,26924600000],
[7920,396000,968500000,1000000000,26928000000],
[7921,396050,968500000,1000000000,26931400000],
[7922,396100,969000000,1000000000,26934800000],
[7923,396150,969000000,1000000000,26938200000],
[7924,396200,969000000,1000000000,26941600000],
[7925,396250,969500000,1000000000,26945000000],
[7926,396300,969500000,1000000000,26948400000],
[7927,396350,969500000,1000000000,26951800000],
[7928,396400,969500000,1000000000,26955200000],
[7929,396450,970000000,1000000000,26958600000],
[7930,396500,970000000,1000000000,26962000000],
[7931,396550,970000000,1000000000,26965400000],
[7932,396600,970500000,1000000000,26968800000],
[7933,396650,970500000,1000000000,26972200000],
[7934,396700,970500000,1000000000,26975600000],
[7935,396750,971000000,1000000000,26979000000],
[7936,396800,971000000,1000000000,26982400000],
[7937,396850,971000000,1000000000,26985800000],
[7938,396900,971000000,1000000000,26989200000],
[7939,396950,971500000,1000000000,26992600000],
[7940,397000,971500000,1000000000,26996000000],
[7941,397050,971500000,1000000000,26999400000],
[7942,397100,972000000,1000000000,27002800000],
[7943,397150,972000000,1000000000,27006200000],
[7944,397200,972000000,1000000000,27009600000],
[7945,397250,972500000,1000000000,27013000000],
[7946,397300,972500000,1000000000,27016400000],
[7947,397350,972500000,1000000000,27019800000],
[7948,397400,973000000,1000000000,27023200000],
[7949,397450,973000000,1000000000,27026600000],
[7950,397500,973000000,1000000000,27030000000],
[7951,397550,973000000,1000000000,27033400000],
[7952,397600,973500000,1000000000,27036800000],
[7953,397650,973500000,1000000000,27040200000],
[7954,397700,973500000,1000000000,27043600000],
[7955,397750,974000000,1000000000,27047000000],
[7956,397800,974000000,1000000000,27050400000],
[7957,397850,974000000,1000000000,27053800000],
[7958,397900,974500000,1000000000,27057200000],
[7959,397950,974500000,1000000000,27060600000],
[7960,398000,974500000,1000000000,27064000000],
[7961,398050,974500000,1000000000,27067400000],
[7962,398100,975000000,1000000000,27070800000],
[7963,398150,975000000,1000000000,27074200000],
[7964,398200,975000000,1000000000,27077600000],
[7965,398250,975500000,1000000000,27081000000],
[7966,398300,975500000,1000000000,27084400000],
[7967,398350,975500000,1000000000,27087800000],
[7968,398400,976000000,1000000000,27091200000],
[7969,398450,976000000,1000000000,27094600000],
[7970,398500,976000000,1000000000,27098000000],
[7971,398550,976000000,1000000000,27101400000],
[7972,398600,976500000,1000000000,27104800000],
[7973,398650,976500000,1000000000,27108200000],
[7974,398700,976500000,1000000000,27111600000],
[7975,398750,977000000,1000000000,27115000000],
[7976,398800,977000000,1000000000,27118400000],
[7977,398850,977000000,1000000000,27121800000],
[7978,398900,977500000,1000000000,27125200000],
[7979,398950,977500000,1000000000,27128600000],
[7980,399000,977500000,1000000000,27132000000],
[7981,399050,978000000,1000000000,27135400000],
[7982,399100,978000000,1000000000,27138800000],
[7983,399150,978000000,1000000000,27142200000],
[7984,399200,978000000,1000000000,27145600000],
[7985,399250,978500000,1000000000,27149000000],
[7986,399300,978500000,1000000000,27152400000],
[7987,399350,978500000,1000000000,27155800000],
[7988,399400,979000000,1000000000,27159200000],
[7989,399450,979000000,1000000000,27162600000],
[7990,399500,979000000,1000000000,27166000000],
[7991,399550,979500000,1000000000,27169400000],
[7992,399600,979500000,1000000000,27172800000],
[7993,399650,979500000,1000000000,27176200000],
[7994,399700,979500000,1000000000,27179600000],
[7995,399750,980000000,1000000000,27183000000],
[7996,399800,980000000,1000000000,27186400000],
[7997,399850,980000000,1000000000,27189800000],
[7998,399900,980500000,1000000000,27193200000],
[7999,399950,980500000,1000000000,27196600000],
[8000,400000,980500000,1000000000,27200000000],
[8001,400050,981000000,1000000000,27203400000],
[8002,400100,981000000,1000000000,27206800000],
[8003,400150,981000000,1000000000,27210200000],
[8004,400200,981000000,1000000000,27213600000],
[8005,400250,981500000,1000000000,27217000000],
[8006,400300,981500000,1000000000,27220400000],
[8007,400350,981500000,1000000000,27223800000],
[8008,400400,982000000,1000000000,27227200000],
[8009,400450,982000000,1000000000,27230600000],
[8010,400500,982000000,1000000000,27234000000],
[8011,400550,982500000,1000000000,27237400000],
[8012,400600,982500000,1000000000,27240800000],
[8013,400650,982500000,1000000000,27244200000],
[8014,400700,983000000,1000000000,27247600000],
[8015,400750,983000000,1000000000,27251000000],
[8016,400800,983000000,1000000000,27254400000],
[8017,400850,983000000,1000000000,27257800000],
[8018,400900,983500000,1000000000,27261200000],
[8019,400950,983500000,1000000000,27264600000],
[8020,401000,983500000,1000000000,27268000000],
[8021,401050,984000000,1000000000,27271400000],
[8022,401100,984000000,1000000000,27274800000],
[8023,401150,984000000,1000000000,27278200000],
[8024,401200,984500000,1000000000,27281600000],
[8025,401250,984500000,1000000000,27285000000],
[8026,401300,984500000,1000000000,27288400000],
[8027,401350,984500000,1000000000,27291800000],
[8028,401400,985000000,1000000000,27295200000],
[8029,401450,985000000,1000000000,27298600000],
[8030,401500,985000000,1000000000,27302000000],
[8031,401550,985500000,1000000000,27305400000],
[8032,401600,985500000,1000000000,27308800000],
[8033,401650,985500000,1000000000,27312200000],
[8034,401700,986000000,1000000000,27315600000],
[8035,401750,986000000,1000000000,27319000000],
[8036,401800,986000000,1000000000,27322400000],
[8037,401850,986000000,1000000000,27325800000],
[8038,401900,986500000,1000000000,27329200000],
[8039,401950,986500000,1000000000,27332600000],
[8040,402000,986500000,1000000000,27336000000],
[8041,402050,987000000,1000000000,27339400000],
[8042,402100,987000000,1000000000,27342800000],
[8043,402150,987000000,1000000000,27346200000],
[8044,402200,987500000,1000000000,27349600000],
[8045,402250,987500000,1000000000,27353000000],
[8046,402300,987500000,1000000000,27356400000],
[8047,402350,988000000,1000000000,27359800000],
[8048,402400,988000000,1000000000,27363200000],
[8049,402450,988000000,1000000000,27366600000],
[8050,402500,988000000,1000000000,27370000000],
[8051,402550,988500000,1000000000,27373400000],
[8052,402600,988500000,1000000000,27376800000],
[8053,402650,988500000,1000000000,27380200000],
[8054,402700,989000000,1000000000,27383600000],
[8055,402750,989000000,1000000000,27387000000],
[8056,402800,989000000,1000000000,27390400000],
[8057,402850,989500000,1000000000,27393800000],
[8058,402900,989500000,1000000000,27397200000],
[8059,402950,989500000,1000000000,27400600000],
[8060,403000,989500000,1000000000,27404000000],
[8061,403050,990000000,1000000000,27407400000],
[8062,403100,990000000,1000000000,27410800000],
[8063,403150,990000000,1000000000,27414200000],
[8064,403200,990500000,1000000000,27417600000],
[8065,403250,990500000,1000000000,27421000000],
[8066,403300,990500000,1000000000,27424400000],
[8067,403350,991000000,1000000000,27427800000],
[8068,403400,991000000,1000000000,27431200000],
[8069,403450,991000000,1000000000,27434600000],
[8070,403500,991000000,1000000000,27438000000],
[8071,403550,991500000,1000000000,27441400000],
[8072,403600,991500000,1000000000,27444800000],
[8073,403650,991500000,1000000000,27448200000],
[8074,403700,992000000,1000000000,27451600000],
[8075,403750,992000000,1000000000,27455000000],
[8076,403800,992000000,1000000000,27458400000],
[8077,403850,992500000,1000000000,27461800000],
[8078,403900,992500000,1000000000,27465200000],
[8079,403950,992500000,1000000000,27468600000],
[8080,404000,993000000,1000000000,27472000000],
[8081,404050,993000000,1000000000,27475400000],
[8082,404100,993000000,1000000000,27478800000],
[8083,404150,993000000,1000000000,27482200000],
[8084,404200,993500000,1000000000,27485600000],
[8085,404250,993500000,1000000000,27489000000],
[8086,404300,993500000,1000000000,27492400000],
[8087,404350,994000000,1000000000,27495800000],
[8088,404400,994000000,1000000000,27499200000],
[8089,404450,994000000,1000000000,27502600000],
[8090,404500,994500000,1000000000,27506000000],
[8091,404550,994500000,1000000000,27509400000],
[8092,404600,994500000,1000000000,27512800000],
[8093,404650,994500000,1000000000,27516200000],
[8094,404700,995000000,1000000000,27519600000],
[8095,404750,995000000,1000000000,27523000000],
[8096,404800,995000000,1000000000,27526400000],
[8097,404850,995500000,1000000000,27529800000],
[8098,404900,995500000,1000000000,27533200000],
[8099,404950,995500000,1000000000,27536600000],
[8100,405000,996000000,1000000000,27540000000],
[8101,405050,996000000,1000000000,27543400000],
[8102,405100,996000000,1000000000,27546800000],
[8103,405150,996000000,1000000000,27550200000],
[8104,405200,996500000,1000000000,27553600000],
[8105,405250,996500000,1000000000,27557000000],
[8106,405300,996500000,1000000000,27560400000],
[8107,405350,997000000,1000000000,27563800000],
[8108,405400,997000000,1000000000,27567200000],
[8109,405450,997000000,1000000000,27570600000],
[8110,405500,997500000,1000000000,27574000000],
[8111,405550,997500000,1000000000,27577400000],
[8112,405600,997500000,1000000000,27580800000],
[8113,405650,998000000,1000000000,27584200000],
[8114,405700,998000000,1000000000,27587600000],
[8115,405750,998000000,1000000000,27591000000],
[8116,405800,998000000,1000000000,27594400000],
[8117,405850,998500000,1000000000,27597800000],
[8118,405900,998500000,1000000000,27601200000],
[8119,405950,998500000,1000000000,27604600000],
[8120,406000,999000000,1000000000,27608000000],
[8121,406050,999000000,1000000000,27611400000],
[8122,406100,999000000,1000000000,27614800000],
[8123,406150,999500000,1000000000,27618200000],
[8124,406200,999500000,1000000000,27621600000],
[8125,406250,999500000,1000000000,27625000000],
[8126,406300,999500000,1000000000,27628400000],
[8127,406350,1000000000,1000000000,27631800000]
]