import { matrix } from "./Matrix";
import { matrixDp } from "./MatrixDp";
import { matrixV2 } from "./MatrixV2";
//import nerdamer from'nerdamer/nerdamer.core.js'
//import 'nerdamer/Algebra.js'
//import 'nerdamer/Calculus.js'
//import 'nerdamer/Solve.js'

export const calculateAttributionMatrix = (attribution) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][2] >= attribution){
            return matrix[i];
        }
    }
}

export const calculateAttributionV2 = (attribution) => {
    for(let i=0; i<matrixV2.length; i++){
        if(matrixV2[i][2] >= attribution){
            return matrixV2[i];
        }
    }
}

export const calculateAttributionPriceForACV = (acv) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][1] >= acv){
            return matrix[i][2];
        }
    }
}

export const calculateDpPriceMatrix = (basePrice) => {
    if(isNaN(basePrice)){
        return 0;
    }
    return basePrice * 75000;
}

export const calculateMausMatrix = (maus) => {
    for(let i=0; i<matrix.length; i++){
        if(matrix[i][3] >= maus){
            return matrix[i];
        }
    }
}

export const calculateMausV2 = (maus) => {
    for(let i=0; i<matrixV2.length; i++){
        if(matrixV2[i][3] >= maus){
            return matrixV2[i];
        }
    }
}

export const calculateDataPoints = (dps) => {
    for(let i=0; i<matrixDp.length; i++){
        if(matrixDp[i][4] >= dps){
            return matrixDp[i];
        }
    }
}

export const calculateDataPointsV2 = (dps) => {
    for(let i=0; i<matrixV2.length; i++){
        if(matrixV2[i][4] >= dps){
            return matrixV2[i];
        }
    }
}

export const calculateDataPoints100m = (currency) => {

    if (currency === 'JPY') {
        return 200000;
      }
      if (currency === 'GBP') {
        return 1200;
      }
      if (currency === 'CNY') {
        return 10000;
      }
      if (currency === 'BRL') {
        return 7700;
      }
    return 1500;
}
