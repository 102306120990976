import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';
import {getCurrency, getPrefix} from "../GeneralValues/Currency";

const ceilNearest50 = (num) =>{
    return Math.ceil(num / 50) * 50;
}

export const InSightInput = (props) => {
    const [incrementality,setIncrementality] = useState(props.incrementality);
    let currencyPrefix = getPrefix(props.currency);
    let currencyMultiplier = getCurrency(props.currency);
    let thousandSeparator = ',';
    let decimalSeparator = '.';
    if(props.separator === '.'){
        thousandSeparator = '.';
        decimalSeparator = ',';
    }
    else{
        thousandSeparator = ',';
        decimalSeparator = '.';
    }

    const setIncrementalityUpdate = (value) => {
        setIncrementality(value);
        props.onIncremenatlityChange(value);
    }

    const getPrice = () => {
        if(props.incrementality === 'base15'){
            return <NumberFormat value={ceilNearest50(16500 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.incrementality === 'base25'){
            return <NumberFormat value={ceilNearest50(22500 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.incrementality === 'base50'){
            return <NumberFormat value={ceilNearest50(34000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.incrementality === 'base100'){
            return <NumberFormat value={ceilNearest50(51200 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.incrementality === 'ignite'){
            return <NumberFormat value={ceilNearest50(6000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else{
            return <NumberFormat value={0} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const insightParams = queryParameters.get("insight");
    if(incrementality === 'false'){
        if(insightParams === 'false'){
            if(insightParams === 'base15'){setIncrementality('base15');props.onIncremenatlityChange('base15');}
            else if(insightParams === 'base25'){setIncrementality('base25');props.onIncremenatlityChange('base25');}
            else if(insightParams === 'base50'){setIncrementality('base50');props.onIncremenatlityChange('base50');}
            else if(insightParams === 'base100'){setIncrementality('base100');props.onIncremenatlityChange('base100');}
            else if(insightParams === 'ignite'){setIncrementality('ignite');props.onIncremenatlityChange('ignite');}
        }
        setIncrementality(insightParams);
        props.onIncremenatlityChange(insightParams);
    }
    return (
        <div>
                <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                    <Col>
                        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                            <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>InSight</div>
                        </Badge>
                    </Col>
                    <Col>
                        <Form.Select defaultValue={'false'} value={props.incrementality} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} size="sm" id="incrementality" onChange={e => setIncrementalityUpdate(e.target.value)} >
                            <option value="false">No</option>
                            <option value="base15">Base 15</option>
                            <option value="base25">Base 25</option>
                            <option value="base50">Base 50</option>
                            <option value="base100">Base 100</option>
                            <option value="ignite">IGNITE Special Package</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Card>
                            <div style={{width:'96%',marginLeft:'1%',marginRight:'2%',textAlign: 'center',height:'30px'}}>
                                <p>{getPrice()}</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {props.incrementality === 'base15' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Included Tests per Term</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>15</Card>
                        </Col>
                        <Col>
                            <Card style={{border: '0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {props.incrementality === 'base25' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Included Tests per Term</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>25</Card>
                        </Col>
                        <Col>
                            <Card style={{border: '0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {props.incrementality === 'base50' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Included Tests per Term</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>50</Card>
                        </Col>
                        <Col>
                            <Card style={{border: '0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {props.incrementality === 'base100' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Included Tests per Term</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>100</Card>
                        </Col>
                        <Col>
                            <Card style={{border: '0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }

                {incrementality === 'ignite' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Included Tests per Term</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center',height:'30px'}}>10</Card>
                        </Col>
                        <Col>
                            <Card style={{border: '0px',height:'30px'}}></Card>
                        </Col>
                    </Row>
                </>
                }
        </div>
    );
}