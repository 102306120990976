import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

export const TrueLinkInput = (props) => {
    const [trueLink,setTrueLink] = useState(props.trueLink)

    const setTrueLinkUpdate = (value) => {
        setTrueLink(value);
        props.onTrueLinkChange(value);
    }

    const getPrice = () => {
        if(props.trueLink === 'base'){
            return 'Free';
        }
        else if(props.trueLink === 'corestarter'){
            return '10%';
        }
        else if(props.trueLink === 'corestandard'){
            return '20%'
        }
        else if(props.trueLink === 'enterprise'){
            return '20%'
        }
        else{
            return 'Free';
        }
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const trueLinkParams = queryParameters.get("truelink");
    if(trueLink === 'false'){
        if(trueLinkParams === 'base'){setTrueLink('base');props.onTrueLinkChange('base');}
        else if(trueLinkParams === 'corestarter'){setTrueLink('corestarter');props.onTrueLinkChange('corestarter');}
        else if(trueLinkParams === 'corestandard'){setTrueLink('corestandard');props.onTrueLinkChange('corestandard');}
        else if(trueLinkParams === 'enterprise'){setTrueLink('enterprise');props.onTrueLinkChange('enterprise');}
    }

    return (
        <div>
                <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                    <Col>
                        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                            <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>True Link</div>
                        </Badge>
                    </Col>
                    <Col>
                        <Form.Select defaultValue={'false'} value={props.trueLink} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} size="sm" id="adSpend" onChange={e => setTrueLinkUpdate(e.target.value)} >
                            <option value="false">No</option>
                            <option value="base">Base</option>
                            <option value="corestarter">Core Starter</option>
                            <option value="corestandard">Core Standard</option>
                            <option value="enterprise">Enterprise</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Card style={{height:'30px'}}>
                            <div style={{width:'99%',marginLeft:'1%',marginTop:'4px',textAlign: 'center'}}>
                                <p>{getPrice()}</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
        </div>
    );
}