import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';
import {getCurrency, getPrefix} from "../GeneralValues/Currency";

const ceilNearest50 = (num) =>{
    return Math.ceil(num / 50) * 50;
}

export const SpendWorksInput = (props) => {
    const [adSpend,setAdSpend] = useState(props.spendWorks)
    let currencyPrefix = getPrefix(props.currency)
    let currencyMultiplier = getCurrency(props.currency)
    let thousandSeparator = ',';
    let decimalSeparator = '.'
    if(props.separator === '.'){
        thousandSeparator = '.';
        decimalSeparator = ',';
    }
    else{
        thousandSeparator = ',';
        decimalSeparator = '.';
    }

    const setAdSpendUpdate = (value) => {
        setAdSpend(value);
        props.onSpendWorksChange(value);
    }

    const getPrice = () => {
        if(props.spendWorks === 'base'){
            return <NumberFormat value={ceilNearest50(500 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.spendWorks === 'core'){
            return <NumberFormat value={ceilNearest50(1000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.spendWorks === 'enterprise'){
            return <NumberFormat value={ceilNearest50(3000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else if(props.spendWorks === 'custom'){
            return <NumberFormat value={ceilNearest50(9000 * currencyMultiplier)} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
        else{
            return <NumberFormat value={0} displayType={'text'} prefix={currencyPrefix} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
        }
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const spendParams = queryParameters.get("spendworks");
    if(adSpend === 'false'){
        if(spendParams === 'base'){setAdSpend('base');props.onSpendWorksChange('base');}
        else if(spendParams === 'core'){setAdSpend('core');props.onSpendWorksChange('core');}
        else if(spendParams === 'enterprise'){setAdSpend('enterprise');props.onSpendWorksChange('enterprise');}
        else if(spendParams === 'custom'){setAdSpend('custom');props.onSpendWorksChange('custom');}
    }

    return (
        <div>
                <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                    <Col>
                        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                            <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Spend Works</div>
                        </Badge>
                    </Col>
                    <Col>
                        <Form.Select defaultValue={'false'} value={props.spendWorks} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} size="sm" id="adSpend" onChange={e => setAdSpendUpdate(e.target.value)} >
                            <option value="false">No</option>
                            <option value="base">Base</option>
                            <option value="core">Core</option>
                            <option value="enterprise">Enterprise</option>
                            <option value="custom">Custom</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Card style={{height:'30px'}}>
                            <div style={{width:'99%',marginLeft:'1%',marginTop:'4px',textAlign: 'center'}}>
                                <p>{getPrice()}</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
        </div>
    );
}