import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Updates = () => {
    const [updates,setUpdates] = useState([]);
    useEffect(() => {
        if(updates.length === 0){
            fetch('/api/updates').then(res => res.json()).then(data => {
                setUpdates(data.updates);
            });
        }
      },[updates]);

    return (
        <Container>
            {updates.map((update) => (
            <Row style={{marginBottom: '2px'}}>
                <Col style={{background:'lightgrey'}}>
                    <Row>
                        <Col xs lg="2">
                            {update[0]}
                        </Col>
                        <Col>
                            {update[1]}
                        </Col>
                    </Row>
                </Col>
            </Row>
            ))}
        </Container>
    );
            
}