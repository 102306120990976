import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { PresetView } from './PresetView';

export const PresetModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [presetNum,setPresetNum] = useState('');
    const [saveError,setSaveError] = useState('');
    const [preset1,setPreset1] = useState([]);
    const [preset2,setPreset2] = useState([]);
    const [preset3,setPreset3] = useState([]);

    var type = props.type;
    useEffect(() => {
        fetch('/api/load_preset/'+type).then(response => response.json()).then(function(result) {
            var presetsList = result["presets"];
            
            for (let index = 0; index < presetsList.length; index++) {
                const element = presetsList[index];
                if(element[29] === '1'){
                    setPreset1(element);
                }
                if(element[29] === '2'){
                    setPreset2(element);
                }
                if(element[29] === '3'){
                    setPreset3(element);
                }
                
            }
        });
    }, []);

    var volume= props.attribution;
    var region=props.region;
    var currency=props.currency;
    var contractLength=props.contractLength;
    var paymentTerm = props.paymentTerm;
    var entity = props.entity;
    var volumePeriod= props.volumePeriod;
    var feature1=props.feature1;
    var feature2=props.feature2;
    var feature3=props.feature3;
    var feature4=props.feature4;
    var feature5=props.feature5;
    var feature6=props.feature6;
    var feature7=props.feature7;
    var feature8=props.feature8;
    var feature9=props.feature9;
    var feature10=props.feature10;
    var feature11=props.feature11;
    var feature12=props.feature12;
    var feature13=props.feature13;
    var feature14=props.feature14;
    var dataManagementSelected=props.dataManagementSelected;
    var assistDashboard=props.assistDashboard;
    var pulse=props.pulse;
    var spendWorks=props.spendWorks;
    var incrementality=props.incrementality;
    var nextGen=props.nextGen;
    var trueLink=props.trueLink;
    
    const loadPreset = (presetNumber) => {
        setPresetNum(presetNumber);
    }

    const loadPresetToBuilder = () => {
        var preset = [];
        if(presetNum === '1'){
            preset = preset1;
        }
        else if(presetNum === '2'){
            preset = preset2;
        }
        else if(presetNum === '3'){
            preset = preset3;
        }
        if(preset.length > 0){
            props.onContractLengthChange(preset[5]);
            props.onPaymentTermChange(preset[6]);
            props.onAttributionChange(parseInt(preset[4]));
            props.onRegionChange(preset[7]);
            props.onEntityChange(preset[30]);
            props.onCurrencyChange(preset[8]);
            props.onVolumePeriodChange(preset[31]);

            props.onFeature1Change(preset[9]);
            props.onFeature2Change(preset[10]);
            props.onFeature3Change(preset[11]);
            props.onFeature4Change(preset[12]);
            props.onFeature5Change(preset[13]);
            props.onFeature6Change(preset[14]);
            props.onFeature7Change(preset[15]);
            props.onFeature8Change(preset[16]);
            props.onFeature9Change(preset[17]);
            props.onFeature10Change(preset[18]);
            props.onFeature11Change(preset[19]);
            props.onFeature12Change(preset[20]);
            props.onFeature13Change(preset[21]);
            props.onFeature14Change(preset[22]);

            props.onDataManagementSelectedChange(preset[23]);
            props.onPulseChange(preset[25]);
            props.onSpendWorksChange(preset[26]);
            props.onIncremenatlityChange(preset[27]);
            props.onNextGenChange(preset[28]);
            props.onTrueLinkChange(preset[32]);
        }
    }

    const savePreset = (presetNumber) => {
        const inputData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({

              type: type,
              volume: volume,
              contractLength: contractLength,
              paymentTerm: paymentTerm,
              currency: currency,
              region: region,
              entity: entity,
              volumePeriod: volumePeriod,
              
              feature1: feature1,
              feature2: feature2,
              feature3: feature3,
              feature4: feature4,
              feature5: feature5,
              feature6: feature6,
              feature7: feature7,
              feature8: feature8,
              feature9: feature9,
              feature10: feature10,
              feature11: feature11,
              feature12: feature12,
              feature13: feature13,
              feature14: feature14,

              dataManagementSelected: dataManagementSelected,
              assistDashboard: assistDashboard,
              pulse: pulse,
              spendWorks: spendWorks,
              incrementality: incrementality,
              nextGen: nextGen,
              trueLink: trueLink,
              presetNumber: presetNumber
            })
          }
          if(contractLength > 0 && volume !== 0 && paymentTerm !== 0){
            fetch('/api/save_preset', inputData).then(response => response.json()).then(function(result) {
                setSaveError(result["message"]);
            });
          }
    }

    return (
        <>
        <Button style = {{ marginLeft: '20px',marginTop: '0px', marginBottom: '0px', background: '#08d1ff', color: 'white',borderRadius: '100px'}} variant="info"  onClick={handleShow}>
            Presets
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Presets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="secondary" onClick={() => loadPreset('1')}>Preset 1</Button>
                    <Button variant="secondary" onClick={() => loadPreset('2')}>Preset 2</Button>
                    <Button variant="secondary" onClick={() => loadPreset('3')}>Preset 3</Button>
                </ButtonGroup>
                <br/>
                {presetNum !== '' &&
                    <>
                        {presetNum === '1' &&
                        <PresetView presetNum={presetNum} presetData={preset1}></PresetView>
                        }
                        {presetNum === '2' &&
                        <PresetView presetNum={presetNum} presetData={preset2}></PresetView>
                        }
                        {presetNum === '3' &&
                        <PresetView presetNum={presetNum} presetData={preset3}></PresetView>
                        }
                    </>
                }
                <br/>
                {saveError !== '' &&
                    <h5>{saveError}</h5>
                }
            </Modal.Body>
            {presetNum !== '' &&
            <Modal.Footer>
            <Button variant="secondary" onClick={() => loadPresetToBuilder()}>
                Load Preset
            </Button>
            <Button variant="primary" onClick={() => savePreset(presetNum)}>
                Save Preset
            </Button>
            </Modal.Footer>
            }
        </Modal>
        </>
    );
}