export const getPrefix = (currency) => {
    if (currency === 'JPY') {
      return '¥';
    }
    if (currency === 'USD') {
      return '$';
    }
    if (currency === 'GBP') {
      return '£';
    }
    if (currency === 'CNY') {
      return '¥';
    }
    if (currency === 'BRL') {
      return 'R$';
    }
    return '€';
  }

export const getCurrency = (currency) => {
    if (currency === 'JPY') {
      return 159.421;
    }
    if (currency === 'USD') {
      return 1.11602;
    }
    if (currency === 'GBP') {
      return 0.83358;
    }
    if (currency === 'CNY') {
      return 7.82755;
    }
    if (currency === 'BRL') {
      return 6.07083;
    }
    return 1.0;
  }
