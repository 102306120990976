import React, { useState, useEffect }  from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

const regions = [
  {name:'Japan', rateBasePrice: 1.10 },
  {name:'Korea', rateBasePrice: 1.10 },
  {name:'UKI', rateBasePrice: 1.00},
  {name:'Israel', rateBasePrice: 1.00},
  {name:'DACH', rateBasePrice: 1.00},
  {name:'South Europe', rateBasePrice: 1.00},
  {name:'France', rateBasePrice: 1.00},
  {name:'North America', rateBasePrice: 1.0},
  {name:'MENA', rateBasePrice: 1.0},
  {name:'Eastern Europe', rateBasePrice: 1.0},
  {name:'INSEA', rateBasePrice: 1.0},
  {name:'China', rateBasePrice: 1.0},
  {name:'LATAM (PT)', rateBasePrice: 1.0},
  {name:'LATAM (ES)', rateBasePrice: 1.0}
]

const limitSucceded = (attribution,volumePeriod,maxAttribution) => {
  let attributionMonthly = 0;
  if(volumePeriod === 'Quarterly'){
    attributionMonthly = Math.ceil(attribution / 3);
  }
  else if(volumePeriod === 'Annual'){
    attributionMonthly = Math.ceil(attribution / 12);
  }
  else if(volumePeriod === 'Bi-Annual'){
    attributionMonthly = Math.ceil(attribution / 24);
  }
  else if(volumePeriod === 'Monthly'){
    attributionMonthly = attribution;
  }
  if(attributionMonthly > 12.9){
    return true;
  }
  return false;
}

export default function Input(props) {
  const [volumePeriod,setVolumePeriods] = useState('DEFAULT');
  var contractLength = parseInt(props.contractLength);
  var contractLengthValue = '0.0%';
  var paymentTermValue = '0.0%';
  const [attribution,setAttributions] = useState(0);
  var paymentTerm = props.paymentTerm;
  var region = props.region;
  var currency = props.currency;
  const [entity,setNewEntity] = useState(props.entity);

  useEffect(() => {
    if(props.attribution > 0){
        setAttributions(props.attribution)
    }
  },[props]);

  function setAttribution(attribution){
    if (isNaN(attribution)){
      attribution = 0;
    }
    else{
      if(volumePeriod === 'Quarterly'){
        if (attribution > 39){
          attribution = 39;
        }
      }
      else if(volumePeriod === 'Annual'){
        if (attribution > 155){
          attribution = 155;
        }
      }
      else if(volumePeriod === 'Bi-Annual'){
        if (attribution > 310){
          attribution = 310;
        }
      }
      else if(volumePeriod === 'Monthly'){
        if (attribution > 13.875){
          attribution = 13.875;
        }
      }
      if (attribution < 0.375){
        attribution = 0.375;
      }
    }
    setAttributions(attribution);
    props.onAttributionChange(attribution);
  }


  function setVolumePeriod(period){
    setVolumePeriods(period);
    props.onVolumePeriodChange(period);
  }

  function setEntity(region){
    if (region === ""){
      return <></>;
    }
    if (region === "Japan"){
      return <option key={"Adjust KK"} selected={"Adjust KK" === props.entity} >Adjust KK</option>;
    }
    if (region === "Korea"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "UKI"){
      return <option key={"Adjust Software Ltd"} selected={"Adjust Software Ltd" === props.entity} >Adjust Software Ltd</option>;
    }
    if (region === "Israel"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "DACH"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "South Europe"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "France"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "North America"){
      return <option key={"Adjust Inc."} selected={"Adjust Inc." === props.entity} >Adjust Inc.</option>;
    }
    if (region === "MENA"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "Eastern Europe"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === "INSEA"){
      return <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>;
    }
    if (region === 'China'){
      return <>
        <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>
        <option key={"Adjust Beijing"} selected={"Adjust Beijing" === props.entity} >Adjust Beijing</option>
      </>
    }
    if (region === "LATAM (PT)"){
      return <option key={"Adjust Brasil Licenciamento de Software Ltda"} selected={"Adjust Brasil Licenciamento de Software Ltda" === props.entity} >Adjust Brasil Licenciamento de Software Ltda</option>;
    }
    if (region === "LATAM (ES)"){
      return <>
        <option key={"Adjust GmbH"} selected={"Adjust GmbH" === props.entity} >Adjust GmbH</option>
        <option key={"Adjust Inc."} selected={"Adjust Inc." === props.entity} >Adjust Inc.</option>
      </>;
    }
  }

  function entityChange(newEntity){
    setNewEntity(newEntity);
    props.onEntityChange(newEntity);
  }

  function setCurrency(entity){
    if (entity === ""){
      return <></>;
    }
    if (entity === "Adjust KK"){
      return <option key={"JPY"} selected={"JPY" === props.currency} >JPY</option>;
    }
    if (entity === "Adjust GmbH"){
      return <>
        <option key={"EUR"} selected={"EUR" === props.currency} >EUR</option>
        <option key={"USD"} selected={"USD" === props.currency} >USD</option>
      </>;
    }
    if (entity === "Adjust Software Ltd"){
      return <option key={"GBP"} selected={"GBP" === props.currency} >GBP</option>;
    }
    if (entity === "Adjust Inc."){
      return <option key={"USD"} selected={"USD" === props.currency} >USD</option>;
    }
    if (entity === "Adjust Beijing"){
      return <option key={"CNY"} selected={"CNY" === props.currency} >CNY</option>;
    }
    if (entity === 'Adjust Brasil Licenciamento de Software Ltda'){
      return <option key={"BRL"} selected={"BRL" === props.currency} >BRL</option>;
    }
  }

  const queryParameters = new URLSearchParams(window.location.search);
  const contractLengthParams = queryParameters.get("contract_length");
  if(contractLength === 0){
    contractLength = parseInt(contractLengthParams);
    props.onContractLengthChange(contractLengthParams);
  }
  var billingFrequencyParams = queryParameters.get("billing_frequency");
  if(paymentTerm === "DEFAULT"){
    if(billingFrequencyParams === 'monthly'){paymentTerm="Monthly";props.onPaymentTermChange('Monthly');}
    else if(billingFrequencyParams === 'quarterly'){paymentTerm="Quarterly";props.onPaymentTermChange('Quarterly');}
    else if(billingFrequencyParams === 'bi-annual'){paymentTerm="Bi-Annual";props.onPaymentTermChange('Bi-Annual');}
    else if(billingFrequencyParams === 'annual'){paymentTerm="Annual";props.onPaymentTermChange('Annual');}
  }

  const allowancePeriodParams = queryParameters.get("allowance_period");
  if(volumePeriod === 'DEFAULT'){
    if(allowancePeriodParams === 'monthly'){setVolumePeriod("Monthly");}
    else if(allowancePeriodParams === 'quarterly'){setVolumePeriod("Quarterly");}
    else if(allowancePeriodParams === 'bi-annual'){setVolumePeriod("Bi-Annual");}
    else if(allowancePeriodParams === 'annual'){setVolumePeriod("Annual");}
  }
  
  const subRegionParams = queryParameters.get("sub_region");
  if(region === 'DEFAULT'){
    if(subRegionParams === 'japan'){region = "Japan";props.onRegionChange("Japan");}
    else if(subRegionParams === 'korea'){region = "Korea";props.onRegionChange("Korea");}
    else if(subRegionParams === 'uki'){region = "UKI";props.onRegionChange("UKI");}
    else if(subRegionParams === 'israel'){region = "Israel";props.onRegionChange("Israel");}
    else if(subRegionParams === 'dach'){region = "DACH";props.onRegionChange("DACH");}
    else if(subRegionParams === 'se'){region = "South Europe";props.onRegionChange("South Europe");}
    else if(subRegionParams === 'france'){region = "France";props.onRegionChange("France");}
    else if(subRegionParams === 'na'){region = "North America";props.onRegionChange("North America");}
    else if(subRegionParams === 'mena'){region = "MENA";props.onRegionChange("MENA");}
    else if(subRegionParams === 'ee'){region = "Eastern Europe";props.onRegionChange("Eastern Europe");}
    else if(subRegionParams === 'sea'){region = "INSEA";props.onRegionChange("INSEA");}
    else if(subRegionParams === 'china'){region = "China";props.onRegionChange("China");}
    else if(subRegionParams === 'pt'){region = "LATAM (PT)";props.onRegionChange("LATAM (PT)");}
    else if(subRegionParams === 'es'){region = "LATAM (ES)";props.onRegionChange("LATAM (ES)");}
  }

  const billingEntityParams = queryParameters.get("billing_entity");
  if(entity === ''){
    if(billingEntityParams === 'gmbh'){props.onEntityChange("Adjust GmbH");} 
    else if(billingEntityParams === 'inc'){props.onEntityChange("Adjust Inc.");}
  }

  const billingCurrencyParams = queryParameters.get("billing_currency");
  if(currency === 'DEFAULT'){
    if(billingCurrencyParams === 'usd'){currency = "USD";props.onCurrencyChange("USD");}
    else if(billingCurrencyParams === 'euro'){currency = "Eur";props.onCurrencyChange("Eur");}
  }
  const annualVolumeParams = queryParameters.get("required_annual_volume");
  if(attribution === 0 && annualVolumeParams !== null){
    var dps = parseInt(annualVolumeParams)
    if(dps > 13){
      dps = 13;
    }
    setAttributions(dps);
    props.onAttributionChange(dps);
  }
  
  if(isNaN(contractLength)){contractLength = "0"}
  if(contractLength === 1){contractLengthValue = '15.0%';}
  else if(contractLength === 3){contractLengthValue = '10.0%';}
  else if(contractLength === 6){contractLengthValue = '5.0%';}
  else if(contractLength === 12){contractLengthValue = '0.0%';}
  else if(contractLength === 18){contractLengthValue = '-5.0%';}
  else if(contractLength === 24){contractLengthValue = '-10.0%';}
  else if(contractLength === 36){contractLengthValue = '-20.0%';}

  if(paymentTerm === 'Monthly'){paymentTermValue = '5.0%';}
  else if(paymentTerm === 'Quarterly'){paymentTermValue = '0.0%';}
  else if(paymentTerm === 'Bi-Annual'){paymentTermValue = '-5.0%';}
  else if(paymentTerm === 'Annual'){paymentTermValue = '-10.0%';}


  return (
    <div>
      <Badge bg="warning" style={{width:'100%',height:'30px'}}>
        <div style={{fontSize:'18px',color:'black',marginTop:'2px'}}>INPUT: Required Information</div>
      </Badge>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="info" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',marginTop:'2px',textAlign:'left'}}>Contract Terms</div>
            </Badge>
          </Col>

          <Col>
            <Badge bg="info" style={{width:'100%',height:'30px'}}>
            <div style={{fontSize:'16px',marginTop:'2px',textAlign:'left'}}>Option</div>
            </Badge>
          </Col>

          <Col>
            <Badge bg="info" style={{width:'100%',height:'30px'}}>
            <div style={{fontSize:'16px',marginTop:'2px',textAlign:'left'}}>Base Rate +/-</div>
            </Badge>
          </Col>
        </Row>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Contract Length</div>
            </Badge>
          </Col>

          <Col>
                <Form.Control defaultValue={'0'} value={contractLength} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="contractLength" onChange={e => props.onContractLengthChange(e.target.value)}>
                <option value="0" disabled="disabled"> -- Select a length -- </option>
                <option value="1" selected={contractLength === 1}>1</option>
                <option value="3" selected={contractLength === 3}>3</option>
                <option value="6" selected={contractLength === 6}>6</option>
                <option value="12" selected={contractLength === 12}>12</option>
                <option value="18" selected={contractLength === 18}>18</option>
                <option value="24" selected={contractLength === 24}>24</option>
                <option value="36" selected={contractLength === 36}>36</option>
                </Form.Control>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div id='contractLengthField' style={{width:'100%',textAlign: 'center'}}>{contractLengthValue}</div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Billing Frequency</div>
            </Badge>
          </Col>

          <Col>
              <Form.Control defaultValue={'DEFAULT'} value={paymentTerm} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="paymentTerm" onChange={e => props.onPaymentTermChange(e.target.value)}>
              <option value="DEFAULT" disabled> -- Select a term -- </option>
              <option value="Monthly" selected={paymentTerm === "Monthly"}>Monthly</option>
              <option value="Quarterly" selected={paymentTerm === "Quarterly"}>Quarterly</option>
              <option value="Bi-Annual" selected={paymentTerm === "Bi-Annual"}>Bi-Annual</option>
              <option value="Annual" selected={paymentTerm === "Annual"}>Annual</option>
              </Form.Control>
          </Col>

          <Col>
            <Card style={{height:'30px'}}>
              <div id='paymentTermField' style={{width:'100%',textAlign: 'center'}}>{paymentTermValue}</div>
            </Card>
          </Col>
        </Row>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Allowance period</div>
            </Badge>
          </Col>

          <Col>
            <Form.Control defaultValue={'DEFAULT'} value={props.volumePeriod} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="volume" onChange={e => props.onVolumePeriodChange(e.target.value)}>
              <option value="DEFAULT" disabled> -- Select a term -- </option>
              <option value="Monthly" selected={volumePeriod === "Monthly"}>Monthly</option>
              <option value="Quarterly" selected={volumePeriod === "Quarterly"}>Quarterly</option>
              <option value="Bi-Annual" selected={volumePeriod === "Bi-Annual"}>Bi-Annual</option>
              <option value="Annual" selected={volumePeriod === "Annual"}>Annual</option>
              </Form.Control>
          </Col>

          <Col>
            <div/>
          </Col>
        </Row>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <p style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>
              {props.volumePeriod === 'Monthly' &&
                <div>Monthly Volume</div>
              }
              {props.volumePeriod === 'Quarterly' &&
                <div>Quarterly Volume</div>
              }
              {props.volumePeriod === 'Annual' &&
                <div>Annual Volume</div>
              }
              {props.volumePeriod === 'Bi-Annual' &&
                <div>Bi-Annual Volume</div>
              }
              </p>
            </Badge>
          </Col>

          <Col>
              <Form.Control style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} type="number" id="attribution"  value={attribution} placeholder="e.g 10" onChange={e => setAttribution(e.target.value)}/>
          </Col>

          <Col>
            <div />
          </Col>
        </Row>
        {limitSucceded(attribution,volumePeriod,props.maxAttribution) &&
          <h6 style={{color:'red',marginLeft:'4%'}}>You are trying to exceed the monthly volume limit for this pricing model, please contact sales.ops@adjust.com for help</h6>
        }

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Sub Region</div>
            </Badge>
          </Col>

          <Col>
                <Form.Control defaultValue={'DEFAULT'} value={region} style = {{ width: '100%', background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="regionSelect" onChange={e => props.onRegionChange(e.target.value)}>
                <option value="DEFAULT" disabled="disabled"> -- Select a Region -- </option>
                {regions.map((reg) =>
                  <option key={reg.name} selected={reg.name === props.region} >{reg.name}</option>
                )}
                </Form.Control>
          </Col>

          <Col>
              <div />
          </Col>
        </Row>

        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Billing Entity</div>
            </Badge>
          </Col>

          <Col>
                <Form.Control defaultValue={'DEFAULT'} value={props.entity} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="entitySelect" onChange={e => entityChange(e.target.value)}>
                <option value="DEFAULT"> -- Select a Billing Entity -- </option>
                {setEntity(props.region)}
                </Form.Control>
          </Col>

          <Col>
              <div />
          </Col>
        </Row>
        
        <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
          <Col>
            <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
              <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Billing Currency</div>
            </Badge>
          </Col>

          <Col>
                <Form.Control defaultValue={'DEFAULT'} value={props.currency} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} as="select" size="sm" id="currencySelect" onChange={e => props.onCurrencyChange(e.target.value)}>
                <option value="DEFAULT" disabled="disabled"> -- Select a Billing Currency -- </option>
                {setCurrency(props.entity,props.region)}
                </Form.Control>
          </Col>

          <Col>
              <div />
          </Col>
        </Row>
    </div>
  );
}
