export const PresetView = (props) => {
    let presetNum = props.presetNum;
    let presetData = props.presetData;

    return(
        <>
             <h4>Preset Number:{presetNum}</h4>
             {presetData.length > 0 &&
             <>
                Contract Length: {presetData[5]} <br/>
                Billing Frequency: {presetData[6]} <br/>
                Allowance Period: {presetData[31]} <br/>
                Volume: {presetData[4]} <br/>
                Region: {presetData[7]} <br/>
                Billing Entity: {presetData[30]} <br/>
                Billing Currency: {presetData[8]} <br/>
                <br/>
                {presetData[9] !== '-' &&
                    <>Feature 1: {presetData[9]} <br/></>
                }
                {presetData[10] !== '-' &&
                    <>Feature 2: {presetData[10]} <br/></>
                }
                {presetData[11] !== '-' &&
                    <>Feature 3: {presetData[11]} <br/></>
                }
                {presetData[12] !== '-' &&
                    <>Feature 4: {presetData[12]} <br/></>
                }
                {presetData[13] !== '-' &&
                    <>Feature 5: {presetData[13]} <br/></>
                }
                {presetData[14] !== '-' &&
                    <>Feature 6: {presetData[14]} <br/></>
                }
                {presetData[15] !== '-' &&
                    <>Feature 7: {presetData[15]} <br/></>
                }
                {presetData[16] !== '-' &&
                    <>Feature 8: {presetData[16]} <br/></>
                }
                {presetData[17] !== '-' &&
                    <>Feature 9: {presetData[17]} <br/></>
                }
                {presetData[18] !== '-' &&
                    <>Feature 10: {presetData[18]} <br/></>
                }
                {presetData[19] !== '-' &&
                    <>Feature 11: {presetData[19]} <br/></>
                }
                {presetData[20] !== '-' &&
                    <>Feature 12: {presetData[20]} <br/></>
                }
                {presetData[21] !== '-' &&
                    <>Feature 13: {presetData[21]} <br/></>
                }
                {presetData[22] !== '-' &&
                    <>Feature 14: {presetData[22]} <br/></>
                }
                <br/>
                Custom Dimension / Metrics: {presetData[23]} <br/>
                Pulse: {presetData[25]} <br/>
                Spend Works: {presetData[26]} <br/>
                InSight: {presetData[27]} <br/>
                Next Gen: {presetData[28]} <br/>
                True Link: {presetData[32]} <br/>
             </>
             }
        </>
    );
}