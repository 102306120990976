import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import {Row, Col,Form, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberFormat from'react-number-format';

const inSightNumber = (inSightString) => {
    if(inSightString === 'base15'){
        return 15;
    }
    if(inSightString === 'base25'){
        return 25;
    }
    if(inSightString === 'base50'){
        return 50;
    }
    if(inSightString === 'base100'){
        return 100;
    }
    return 10;
}
export const NextGenInput = (props) => {
    const [nextGen,setNextGen] = useState(props.nextGen);
    const siteName = window.location.pathname;
    let thousandSeparator = ',';
    let decimalSeparator = '.';
    if(props.separator === '.'){
        thousandSeparator = '.';
        decimalSeparator = ',';
    }
    else{
        thousandSeparator = ',';
        decimalSeparator = '.';
    }

    const setNextGenUpdate = (value) => {
        setNextGen(value);
        props.onNextGenChange(value);
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const nextGenParams = queryParameters.get("nextgen");
    if(nextGen === 'false'){
        if(nextGenParams === 'pulse'){setNextGen('pulse');props.onNextGenChange('pulse');}
        else if(nextGenParams === 'newbiz'){setNextGen('newbiz');props.onNextGenChange('newbiz');}
        else if(nextGenParams === 'existingbiz'){setNextGen('existingbiz');props.onNextGenChange('existingbiz');}
    }

    return (
        <div>
                <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                    <Col xs={4}>
                        <Badge bg="secondary" style={{width:'100%',height:'30px'}}>
                            <div style={{fontSize:'16px',textAlign:'left',marginTop:'2px'}}>Next Gen</div>
                        </Badge>
                    </Col>
                    <Col>
                        <Form.Select defaultValue={'false'} value={props.nextGen} style = {{ width: '100%',background: '#ffffcc',maxHeight:'28px',minHeight:'28px' }} size="sm" id="nextGen" onChange={e => setNextGenUpdate(e.target.value)} >
                            <option value="false">No</option>
                            <option value="pulse">Pulse bundle pricing (new)</option>
                            <option value="newbiz">Next gen product new biz offer (new)</option>
                            <option value="existingbiz">Next gen product existing biz offer (new)</option>
                        </Form.Select>
                    </Col>
                </Row>
                {props.nextGen === 'pulse' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>
                            {(siteName === '/' || siteName === '/attributiontest') &&
                                <div>Conversions</div>
                            }
                            {(siteName === '/mau' || siteName === '/mautest') &&
                                <div>MAUs</div>
                            }
                            {(siteName === '/data' || siteName === '/datatest') &&
                                <div>DPs in Billion</div>
                            }
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>
                                <NumberFormat value={Math.round(props.attribution * props.contractLength * 10)/ 10} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Pulse (Alerts+Campaign Automation)</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>30%</Card>
                        </Col>
                    </Row>
                </>
                }

                {props.nextGen === 'newbiz' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>
                            {(siteName === '/' || siteName === '/attributiontest') &&
                                <div>Conversions</div>
                            }
                            {(siteName === '/mau' || siteName === '/mautest') &&
                                <div>MAUs</div>
                            }
                            {(siteName === '/data' || siteName === '/datatest') &&
                                <div>DPs in Billion</div>
                            }
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>
                                <NumberFormat value={Math.round(props.attribution * props.contractLength * 10)/ 10} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>InSight </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>{inSightNumber(props.incrementality)}</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Pulse (Alerts+Campaign Automation) </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>30%</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Assist</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>15%</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>SpendWorks Enterprise</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>15,000,000</Card>
                        </Col>
                    </Row>
                </>
                }

                {props.nextGen === 'existingbiz' &&
                <>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>
                            {(siteName === '/' || siteName === '/attributiontest') &&
                                <div>Conversions</div>
                            }
                            {(siteName === '/mau' || siteName === '/mautest') &&
                                <div>MAUs</div>
                            }
                            {(siteName === '/data' || siteName === '/datatest') &&
                                <div>DPs in Billion</div>
                            }
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>
                                <NumberFormat value={Math.round(props.attribution * props.contractLength * 10)/ 10} displayType={'text'} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>InSight </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>{inSightNumber(props.incrementality)}</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Pulse (Alerts+Campaign Automation) </Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>30%</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>Assist</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>15%</Card>
                        </Col>
                    </Row>
                    <Row style={{width:'99%',marginLeft:'1%',marginTop:'4px'}}>
                        <Col>
                            <Card style={{background:"lightgrey",height:'30px'}}>SpendWorks Enterprise</Card>
                        </Col>
                        <Col>
                            <Card style={{textAlign: 'center', height:'30px'}}>15,000,000</Card>
                        </Col>
                    </Row>
                </>
                }
        </div>
    );
}