import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ModalTable } from "./ModalTable";

const formatDate = (dateString) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

export const StatisticsModal = (props) => {
    const lastVisit = props.lastVisit;
    const pageVisit = props.pageVisit;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    console.log(lastVisit)
    return (
        <div>
            <>
                <Button onClick={handleOpen} variant="outlined">Inspect</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box >
                    <Typography id="dialog-description">
                        <Grid container xs={12} >
                            {(lastVisit !== null) &&
                            <Grid item xs={12}>
                                <div style={{marginLeft:'2%',marginTop:'2%'}}>
                                    <p>Last Visit</p>
                                    <p>On: {formatDate(lastVisit[1])}</p>
                                    <p>From: {lastVisit[0]} </p>
                                </div>
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <div style={{ height: 600 }}>
                                    <ModalTable pageVisit={pageVisit} />
                                </div>
                            </Grid>
                        </Grid>
                    </Typography>
                    </Box>
                </Dialog>
            </>
        </div>
    );
}