import React, { useState } from "react";
import Card from 'react-bootstrap/Card'
import {Row, Col } from 'react-bootstrap'
import Input from '../components/input_terms_data.js'
import Features from '../components/input_addons_data.js'
import Support from '../components/input_support.js';
import Output from '../components/output.js'
import OutputBreakdown from '../components/outputBreakdownTestDp.js';
import KeyStats from '../components/key_stats.js';
import DataManagementPro from "../components/data_management_pro.js";
import { SeparatorButtons } from "../components/separatorButtons.js";
import { PulseInput } from "../components/input_pulse.js";
import { SpendWorksInput } from "../components/input_spend_works.js";
import { InSightInput } from "../components/input_insight.js";
import { NextGenInput } from "../components/input_next_gen.js";

export default function BuilderDataTest() {
  const [accountId, setAccountId] = useState(0);
  const [contractLength, setContractLength] = useState(0);
  const [paymentTerm, setPaymentTerm] = useState("-");
  const [attribution, setAttribution] = useState(0);
  const [region, setRegion] = useState('');
  const [entity, setEntity] = useState('');
  const [currency, setCurrency] = useState('');
  const [feature1, setFeature1] = useState("-");
  const [feature2, setFeature2] = useState("-");
  const [feature3, setFeature3] = useState("-");
  const [feature4, setFeature4] = useState("-");
  const [feature5, setFeature5] = useState("-");
  const [feature6, setFeature6] = useState("-");
  const [feature7, setFeature7] = useState("-");
  const [feature8, setFeature8] = useState("-");
  const [feature9, setFeature9] = useState("-");
  const [feature10, setFeature10] = useState("-");
  const [feature11, setFeature11] = useState("-");
  const [feature12, setFeature12] = useState("-");
  const [feature13, setFeature13] = useState("-");
  const [feature14, setFeature14] = useState("-");
  const maxAttribution = 13.875;
  const supportList = [
    [1,"Global",0],
    [2,"Regional",1200],
    [3,"Professional",34000],
    [4,"Enterprise",80000],
    [5,"VIP",200000]
  ]
  const [featureDiscount, setFeatureDiscount] = useState(0);
  const [completeFeatureDiscount, setCompleteFeatureDiscount] = useState(0);
  const [implementation, setImplementation] = useState(0);
  const [supportCost, setSupportCost] = useState(0);
  const [acv, setAcv] = useState(0);
  function handleAccountIdChange(value) {setAccountId(value);}
  function handleContractLengthChange(value) {setContractLength(value);}
  function handlePaymentTermChange(value) {setPaymentTerm(value);}
  function handleAttributionChange(value) {setAttribution(value);}
  function handleRegionChange(value) {
    setRegion(value);
    setEntity("");
    setCurrency("");
  }
  function handleEntityChange(value) {setEntity(value);}
  function handleCurrencyChange(value) {setCurrency(value);}
  function handleFeatureChange1(value) {setFeature1(value);}
  function handleFeatureChange2(value) {setFeature2(value);}
  function handleFeatureChange3(value) {setFeature3(value);}
  function handleFeatureChange4(value) {setFeature4(value);}
  function handleFeatureChange5(value) {setFeature5(value);}
  function handleFeatureChange6(value) {setFeature6(value);}
  function handleFeatureChange7(value) {setFeature7(value);}
  function handleFeatureChange8(value) {setFeature8(value);}
  function handleFeatureChange9(value) {setFeature9(value);}
  function handleFeatureChange10(value) {setFeature10(value);}
  function handleFeatureChange11(value) {setFeature11(value);}
  function handleFeatureChange12(value) {setFeature12(value);}
  function handleFeatureChange13(value) {setFeature13(value);}
  function handleFeatureChange14(value) {setFeature14(value);}
  function handleFeatureDiscount(value) {setFeatureDiscount(value);}
  function handleCompleteFeatureDiscount(value) {setCompleteFeatureDiscount(value);}
  function handleImplementationChange(value) {setImplementation(value);}
  function handleSupportChange(value) {setSupportCost(value);}
  function handleAcvChange(value) {setAcv(value);}

  const [dataManagementSelected, setDataManagementSelected] = useState('false');
  const [assistDashboard, setAssistDashboard] = useState(false);
  const [pulse, setPulse] = useState('false');
  const [pulseCount, setPulseCount] = useState(51);
  const [spendWorks, setSpendWorks] = useState('false');
  const [incrementality, setIncrementality] = useState('false');
  const [nextGen, setNextGen] = useState('false');

  function handleDataManagementSelectedChange(value) {setDataManagementSelected(value);}


  function handleAssistDashboard(value) {setAssistDashboard(value);}
  const [keyStats,setKeyStats] = useState(['',0,0,0,0,'']);
  function hanleKeyStatsChange(value) {setKeyStats(value);}

  const [separator,setSeparator] = useState(',');
  const hanleSeparatorChange = (value) => {setSeparator(value);}
  const handlePulseChange = (value) => {setPulse(value);}
  const handlePulseCountChange = (value) => {setPulseCount(value);}
  const handleSpendWorksChange = (value) => {setSpendWorks(value);}
  const handleIncrementalityChange = (value) => {setIncrementality(value);}
  const handleNextGenChange = (value) => {setNextGen(value);}

  return (
    <div>
      <KeyStats
        onAccountIdChange={handleAccountIdChange}
        onContractLengthChange={handleContractLengthChange}
        onPaymentTermChange={handlePaymentTermChange}
        onAttributionChange={handleAttributionChange}
        onFeature1Change={handleFeatureChange1}
        onFeature2Change={handleFeatureChange2}
        onFeature3Change={handleFeatureChange3}
        onFeature4Change={handleFeatureChange4}
        onFeature5Change={handleFeatureChange5}
        onFeature6Change={handleFeatureChange6}
        onFeature7Change={handleFeatureChange7}
        onFeature8Change={handleFeatureChange8}
        onFeature9Change={handleFeatureChange9}
        onFeature10Change={handleFeatureChange10}
        onFeature11Change={handleFeatureChange11}
        onFeature12Change={handleFeatureChange12}
        onFeature13Change={handleFeatureChange13}
        onFeature14Change={handleFeatureChange14}
        implementationChange={handleImplementationChange}
        onKeyStatsChange={hanleKeyStatsChange}
      />
      <SeparatorButtons onSeparatorChange = {hanleSeparatorChange} />
      <Row style={{width:'100%'}}>
        <Col>
          <Input
            contractLength={contractLength}
            paymentTerm={paymentTerm}
            attribution={attribution}
            region={region}
            entity={entity}
            currency={currency}
            onContractLengthChange={handleContractLengthChange}
            onPaymentTermChange={handlePaymentTermChange}
            onAttributionChange={handleAttributionChange}
            onRegionChange={handleRegionChange}
            onEntityChange={handleEntityChange}
            onCurrencyChange={handleCurrencyChange}
            maxAttribution={maxAttribution}
          />
          <br/>
          <Features
            feature1={feature1}
            feature2={feature2}
            feature3={feature3}
            feature4={feature4}
            feature5={feature5}
            feature6={feature6}
            feature7={feature7}
            feature8={feature8}
            feature9={feature9}
            feature10={feature10}
            feature11={feature11}
            feature12={feature12}
            feature13={feature13}
            feature14={feature14}
            onFeature1Change={handleFeatureChange1}
            onFeature2Change={handleFeatureChange2}
            onFeature3Change={handleFeatureChange3}
            onFeature4Change={handleFeatureChange4}
            onFeature5Change={handleFeatureChange5}
            onFeature6Change={handleFeatureChange6}
            onFeature7Change={handleFeatureChange7}
            onFeature8Change={handleFeatureChange8}
            onFeature9Change={handleFeatureChange9}
            onFeature10Change={handleFeatureChange10}
            onFeature11Change={handleFeatureChange11}
            onFeature12Change={handleFeatureChange12}
            onFeature13Change={handleFeatureChange13}
            onFeature14Change={handleFeatureChange14}
            featureDiscount={handleFeatureDiscount}
            completeFeatureDiscount={handleCompleteFeatureDiscount}
            onAssistDashboardChange={handleAssistDashboard}
          />
          <br/>
          {acv >= 0 &&
            <DataManagementPro
              currency={currency}

              dataManagementSelected={dataManagementSelected}
              onDataManagementSelectedChange={handleDataManagementSelectedChange}
              separator={separator}
            />
          }
          <br/>
          <PulseInput 
            currency={currency}
            separator={separator}
            pulse={pulse}
            pulseCount={pulseCount}
            onPulseChange={handlePulseChange}
            onPulseCountChange={handlePulseCountChange}
          />
          <br/>
          <SpendWorksInput 
            currency={currency}
            separator={separator}
            spendWorks={spendWorks}
            onSpendWorksChange={handleSpendWorksChange}
          />
          <br/>
          <InSightInput
            currency={currency}
            separator={separator}
            incrementality={incrementality}
            onIncremenatlityChange={handleIncrementalityChange}
          />
          <br/>
          <NextGenInput 
            currency={currency}
            separator={separator}
            nextGen={nextGen}
            onNextGenChange={handleNextGenChange}
            attribution={attribution}
            contractLength={contractLength}
            incrementality={incrementality}
          />
          <br/>
          <div style={{display:'none'}}>
          <Support
            implementationChange={handleImplementationChange}
            supportChange={handleSupportChange}
            supportList={supportList}
            supportCost={supportCost}
            acv={acv}
            implementation={implementation}
            currency={currency}
          />
          </div>
        </Col>
        <Col xs={7}>
          <Card>
            <Output
              contractLength={contractLength}
              paymentTerm={paymentTerm}
              attribution={attribution}
              separator={separator}
            />
            <OutputBreakdown
              accountId={accountId}
              attribution={attribution}
              region={region}
              currency={currency}
              featureDiscount={featureDiscount}
              completeFeatureDiscount={completeFeatureDiscount}
              supportCost={supportCost}
              contractLength={contractLength}
              acvChange = {handleAcvChange}
              completeAcv = {acv}
              paymentTerm = {paymentTerm}
              implementation = {implementation}
              feature1={feature1}
              feature2={feature2}
              feature3={feature3}
              feature4={feature4}
              feature5={feature5}
              feature6={feature6}
              feature7={feature7}
              feature8={feature8}
              feature9={feature9}
              feature10={feature10}
              feature11={feature11}
              feature12={feature12}
              feature13={feature13}
              feature14={feature14}
              supportList={supportList}
              dataManagementSelected={dataManagementSelected}
              assistDashboard={assistDashboard}
              keyStats={keyStats}
              separator={separator}
              pulse={pulse}
              pulseCount={pulseCount}
              spendWorks={spendWorks}
              incrementality={incrementality}
              nextGen={nextGen}
            />
          </Card>
        </Col>
      </Row>
      <br/>
      <br/>
    </div>
  );
}
