import { useEffect, useState } from "react";
import { StatisticsTable } from "../components/Statistics/statisticsTable";

export const Statistics = () =>{
    const [data,setData] = useState([]);

    useEffect(() => {
        fetch('/api/statistics').then(res => res.json()).then(data => {
            setData(data.data);
        });
      }, []);

    return (
        <>
            <StatisticsTable data={data} />
        </>
    );
}