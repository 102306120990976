import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import {Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { KeyStatsTable } from "./KeyStatsTable";

function KeyStats(props){
  const siteName = window.location.pathname;
  const [keyStats, setKeyStats] = useState(['',0,0,0,0,'']);
  const [accountId, setAccountId] = useState(0);
  const setAccountIdFlow = (accountId) => {
    setAccountId(accountId)
    props.onAccountIdChange(parseInt(accountId));
  }

  function getKeyStats(){
    let type;
    if(siteName === '/mau'){
      type ='mau'}
    else if(siteName === '/data'){type = 'data'}
    else {type = 'attribution'}
    const accountData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({accountId,type}),
    }

    fetch('/api/keystats', accountData).then(response => response.json()).then(function(result) {
        setKeyStats(result.key_stats);
        props.onKeyStatsChange(result.key_stats);
        if(result.account_data.length > 0 && result.accountData !== undefined){
          if(result.account_data[3] !== ''){props.onContractLengthChange(result.account_data[3]);}
          if(result.account_data[4] !== ''){props.onPaymentTermChange(result.account_data[4]);}
          if(result.account_data[5] !== ''){props.onAttributionChange(result.account_data[5]);}
          if(result.account_data[6] !== ''){props.onFeature1Change(result.account_data[6]);}
          if(result.account_data[7] !== ''){props.onFeature2Change(result.account_data[7]);}
          if(result.account_data[8] !== ''){props.onFeature3Change(result.account_data[8]);}
          if(result.account_data[9] !== ''){props.onFeature4Change(result.account_data[9]);}
          if(result.account_data[10] !== ''){props.onFeature5Change(result.account_data[10]);}
          if(result.account_data[11] !== ''){props.onFeature6Change(result.account_data[11]);}
          if(result.account_data[12] !== ''){props.onFeature7Change(result.account_data[12]);}
          if(result.account_data[13] !== ''){props.onFeature8Change(result.account_data[13]);}
          if(result.account_data[14] !== ''){props.onFeature9Change(result.account_data[14]);}
          if(type !== 'data'){
            if(result.account_data[15] !== ''){props.implementationChange(result.account_data[15]);}
          }

        }
    });
  }
  const keyStatsAvailable = (keyStats) => {
    if( keyStats[0] === '' || keyStats[0] === 0){
      return false;
    }
    return true;
  }
  const showKeyStats = keyStatsAvailable(keyStats)

  return (
    <div>

      <Row style={{width:'100%'}}>
        <Col xs={3}>
          <h5 style = {{ marginLeft: '5%'}}>Enter Account ID:</h5>
          <Row style={{width:'100%'}}>
            <Col>
              <Form.Control style = {{ width: '96%',marginLeft: '10%',background: '#ffffcc' }} type="number" id="account_id" placeholder="e.g 65" onChange={e => setAccountIdFlow(e.target.value)}/>
            </Col>
            <Col>
              <Button style = {{  marginLeft: '5%', marginBottom: '20px', background: '#08d1ff', color: 'white', borderRadius: '100px'}} variant="info" onClick={getKeyStats} type="submit">Load</Button>
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <h6>Account name:</h6>
          <h5>{keyStats[5]}</h5>
          <h6>Last Updated: {keyStats[0]}</h6>
        </Col>
        {showKeyStats &&
        <Col xs={6}>
          <KeyStatsTable data={keyStats}/>
        </Col>
        }
      </Row>
    </div>
  );
}

export default KeyStats;
