import { Grid, TextField } from "@mui/material"

export const AdjustInformation = (props) => {
    return(
        <>
            <h2>Adjust Information</h2>
                <Grid container direction="row">
                    <Grid item xs={6} >
                        <Grid container direction="row" rowSpacing={2} justifyContent="flex-start" alignItems="center">
                            <Grid item xs={12} >
                                <TextField
                                    id="accountExecutive"
                                    label="Account Executive"
                                    style={{minWidth:'500px'}}
                                    onChange={e => props.onAccountExecutiveChange(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="solutionsConsultant"
                                    label="Solutions Consultant"
                                    style={{minWidth:'500px'}}
                                    onChange={e => props.onSolutionsConsultantChange(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </>
    )
}